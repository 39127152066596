import { ICreateCallImportToken } from '../interface';
import {
  CREATE_S2_ADVERSUS_SETTINGS,
  CREATE_S2_ADVERSUS_SETTINGS_ERROR,
  CREATE_S2_ADVERSUS_SETTINGS_SUCCESS,
  DELETE_S2_ADVERSUS_SETTINGS,
  DELETE_S2_ADVERSUS_SETTINGS_ERROR,
  DELETE_S2_ADVERSUS_SETTINGS_SUCCESS,
  UPDATE_S2_ADVERSUS_SETTINGS,
  UPDATE_S2_ADVERSUS_SETTINGS_ERROR,
  UPDATE_S2_ADVERSUS_SETTINGS_SUCCESS,
} from './actionTypes';

import { ICallImportSettings } from './interface';

export const createS2AdversusSettings = (accountId: String, tokenSettings: ICreateCallImportToken) => {
  return {
    type: CREATE_S2_ADVERSUS_SETTINGS,
    payload: { accountId, tokenSettings },
  }
}

export const createS2AdversusSettingsSuccess = (tokenSettings: ICallImportSettings) => {
  return {
    type: CREATE_S2_ADVERSUS_SETTINGS_SUCCESS,
    payload: tokenSettings,
  }
}

export const createS2AdversusSettingsError = (error: string | Object) => {
  return {
    type: CREATE_S2_ADVERSUS_SETTINGS_ERROR,
    payload: error
  }
}

export const deleteS2AdversusSettings = (accountId: String, tokenSettingsId: string) => {
  return {
    type: DELETE_S2_ADVERSUS_SETTINGS,
    payload: { accountId, tokenSettingsId },
  }
}

export const deleteS2AdversusSettingsSuccess = () => {
  return {
    type: DELETE_S2_ADVERSUS_SETTINGS_SUCCESS,
    payload: null,
  }
}

export const deleteS2AdversusSettingsError = (error: string | Object) => {
  return {
    type: DELETE_S2_ADVERSUS_SETTINGS_ERROR,
    payload: error,
  }
}

export const updateS2AdversusSettings = (accountId: String, tokenSettings: ICreateCallImportToken) => {
  return {
    type: UPDATE_S2_ADVERSUS_SETTINGS,
    payload: { accountId, tokenSettings },
  }
}

export const updateS2AdversusSettingsSuccess = (tokenSettings: ICallImportSettings) => {
  return {
    type: UPDATE_S2_ADVERSUS_SETTINGS_SUCCESS,
    payload: tokenSettings,
  }
}

export const updateS2AdversusSettingsError = (error: string | Object) => {
  return {
    type: UPDATE_S2_ADVERSUS_SETTINGS_ERROR,
    payload: error
  }
}
