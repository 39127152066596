import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import _ from "lodash";

// Login Redux States
import { GET_USERS, GET_FILTER_USERS, SET_PASSWORD, SET_USER_ROLE} from "./actionTypes";
import {
  userListApiError,
  getUserListSuccess,
  getFilterUsersSuccess,
  setPasswordSuccess,
  setPasswordError,
  setUserRoleSuccess,
  setUserRoleError,
} from "./actions";

//services
import {
  getUserByAccount,
  getFilterUsersService,
  userSetPassword,
  userRole as userRoleService,
  ResponseGenerator,
} from "../../../services/userservice";

function* getUsersList({ payload: { id } }: any) {
  const response: ResponseGenerator = yield call(getUserByAccount, id);
  if (_.has(response, "data")) {
    yield put(getUserListSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(userListApiError(error));
    } else {
      yield put(userListApiError("An Error has occured"));
    }
  }
}

function* getFilterUsers({ payload: { id } }: any) {
  const response: ResponseGenerator = yield call(getFilterUsersService, id);
  if (_.has(response, "data")) {
    yield put(getFilterUsersSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(userListApiError(error));
    } else {
      yield put(userListApiError("An Error has occured"));
    }
  }
}

function* setPassword({ payload: { token, value, pathString } }: any) {
  const response: ResponseGenerator = yield call(
    userSetPassword,
    token,
    value,
    pathString
  );
  if (_.has(response, "data")) {
    yield put(setPasswordSuccess("Password set successfully."));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(setPasswordError(error));
    } else {
      yield put(setPasswordError("An Error has occured"));
    }
  }
}

function* userRoleHelper({ payload: { accountId, userId, params } }: any) {
  const response: ResponseGenerator = yield call(
    userRoleService,
    accountId,
    userId,
    params
  );
  if (_.has(response, "data")) {
    yield put(setUserRoleSuccess("Set user role successfully."));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(setUserRoleError(error));
    } else {
      yield put(setUserRoleError("An Error has occured"));
    }
  }
}

export function* watchUserList() {
  yield takeEvery(GET_USERS, getUsersList);
}

export function* watchFilterUsers() {
  yield takeEvery(GET_FILTER_USERS, getFilterUsers);
}

export function* watchSetPassword() {
  yield takeEvery(SET_PASSWORD, setPassword);
}

export function* watchUserRole() {
  yield takeEvery(SET_USER_ROLE, userRoleHelper);
}

function* getUserListSaga() {
  yield all([fork(watchSetPassword), fork(watchUserList), fork(watchFilterUsers), fork(watchUserRole)]);
}

export default getUserListSaga;
