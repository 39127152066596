import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  CREATE_RECEIVER,
  GET_RECEIVER,
} from './actionTypes';
import {
  createReceiverError,
  createReceiverSuccess,
  getReceiverError,
  getReceiverSuccess,
} from "./actions";

// Services
import {
  createAReceivers,
  getReceiver,
  ResponseGenerator,
} from '../../services/userservice';
import _ from 'lodash';

function* createReceiversHelper({payload: { object}}: any) {
  const response: ResponseGenerator = yield call(createAReceivers, object);
  if (_.has(response, "data")) {
    yield put(createReceiverSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(createReceiverError(error));
    } else {
      yield put(createReceiverError("An Error has occured"));
    }
  } 
}

function* getReceiverHelper({payload: { accountId, sessionId }}: any) {
  const response: ResponseGenerator = yield call(
    getReceiver,
    accountId,
    sessionId,
  );
  if (_.has(response, "data")) {
    yield put(getReceiverSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getReceiverError(error));
    } else {
      yield put(getReceiverError("An Error has occured"));
    }
  } 
}

export function* watchCreateReceiver() {
  yield takeEvery(CREATE_RECEIVER, createReceiversHelper);
}

export function* watchGetReceiver() {
  yield takeEvery(GET_RECEIVER, getReceiverHelper);
}

function* rightsSaga() {
  yield all([
    fork(watchCreateReceiver),
    fork(watchGetReceiver),
  ]);
}

export default rightsSaga;
