import {
  CREATE_CHAT_SESSION,
  CREATE_CHAT_SESSION_ERROR,
  CREATE_CHAT_SESSION_SUCCESS,
  GET_CHAT_MESSAGES,
  GET_CHAT_TOPICS,
  GET_CHAT_TOPICS_ERROR,
  GET_CHAT_TOPICS_SUCCESS,
  GET_CHAT_MESSAGES_ERROR,
  GET_CHAT_MESSAGES_SUCCESS,
  CLOSE_CHAT,
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_ERROR,
  EDIT_CHAT,
  EDIT_CHAT_ERROR,
  DELETE_CHAT,
  DELETE_CHAT_SUCCESS,
  DELETE_CHAT_ERROR,
  ADD_NEW_CHAT,
  GET_MOCK_CHAT,
} from "./actionTypes";
import { IChatInformation, IMessage } from "./interface";

export const createChatSession = (chatTitle: string) => ({
  type: CREATE_CHAT_SESSION,
  payload: chatTitle,
});

export const createChatSessionSuccess = (data: IChatInformation) => ({
  type: CREATE_CHAT_SESSION_SUCCESS,
  payload: data,
});

export const createChatSessionError = (message: string | Object) => ({
  type: CREATE_CHAT_SESSION_ERROR,
  payload: message,
});

export const getChatTopics = () => ({
  type: GET_CHAT_TOPICS,
});

export const getChatTopicsSuccess = (data: IChatInformation[]) => ({
  type: GET_CHAT_TOPICS_SUCCESS,
  payload: data,
});

export const getChatTopicsError = (message: string | Object) => ({
  type: GET_CHAT_TOPICS_ERROR,
  payload: message,
});

export const getChatMessages = (chatId: string, isForNewChat?: boolean) => ({
  type: GET_CHAT_MESSAGES,
  payload: { chatId, isForNewChat },
});

export const getChatMessagesSuccess = (
  data: IMessage[],
  chat?: IChatInformation
) => ({
  type: GET_CHAT_MESSAGES_SUCCESS,
  payload: { data, chat },
});

export const getChatMessagesError = (message: string | Object) => ({
  type: GET_CHAT_MESSAGES_ERROR,
  payload: message,
});

export const closeChat = () => ({
  type: CLOSE_CHAT,
});

export const sendMessage = (message: string, chatId: string) => ({
  type: SEND_MESSAGE,
  payload: { chatId, message },
});

export const sendMessageSuccess = (messages: IMessage[]) => ({
  type: SEND_MESSAGE_SUCCESS,
  payload: messages,
});

export const sendMessageError = (error: string | Object) => ({
  type: SEND_MESSAGE_ERROR,
  payload: error,
});

export const editChat = (chatId: string, chatTitle: string) => ({
  type: EDIT_CHAT,
  payload: { chatId, chatTitle },
});

export const editChatError = (error: string | Object) => ({
  type: EDIT_CHAT_ERROR,
  payload: error,
});

export const deleteChat = (chatId: string) => ({
  type: DELETE_CHAT,
  payload: chatId,
});

export const deleteChatSuccess = (chatId: string) => ({
  type: DELETE_CHAT_SUCCESS,
  payload: chatId,
});

export const deleteChatError = (error: string | Object) => ({
  type: DELETE_CHAT_ERROR,
  payload: error,
});

export const addNewChat = (newChatMock: IChatInformation) => ({
  type: ADD_NEW_CHAT,
  payload: newChatMock,
});

export const getMockChat = () => ({
  type: GET_MOCK_CHAT,
});
