import {
  API_ERROR,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_FILTER_USERS,
  GET_FILTER_USERS_SUCCESS,
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
  SET_USER_ROLE,
  SET_USER_ROLE_SUCCESS,
  SET_USER_ROLE_ERROR,
} from "./actionTypes";
import { IUser } from "./interface";

export const getUserList = (id: String) => {
  return {
    type: GET_USERS,
    payload: { id },
  };
};

export const getUserListSuccess = (users: IUser[]) => {
  return {
    type: GET_USERS_SUCCESS,
    payload: users,
  };
};

export const getFilterUsers = (id: String) => {
  return {
    type: GET_FILTER_USERS,
    payload: { id },
  };
};

export const getFilterUsersSuccess = (users: IUser[]) => {
  return {
    type: GET_FILTER_USERS_SUCCESS,
    payload: users,
  };
};

export const userListApiError = (error: String) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const userSetPassword = (token: String, value: Object, pathString: string) => {
  return {
    type: SET_PASSWORD,
    payload: { token, value , pathString},
  };
};

export const setPasswordSuccess = (mes: String) => {
  return {
    type: SET_PASSWORD_SUCCESS,
    payload: mes,
  };
};

export const setPasswordError = (error: String) => {
  return {
    type: SET_PASSWORD_ERROR,
    payload: error,
  };
};

export const setUserRole = (accountId: String, userId: String, params: Object) => {
  return {
    type: SET_USER_ROLE,
    payload: { accountId, userId, params },
  };
};

export const setUserRoleSuccess = (mes: String) => {
  return {
    type: SET_USER_ROLE_SUCCESS,
    payload: mes,
  };
};

export const setUserRoleError = (mes: String) => {
  return {
    type: SET_USER_ROLE_ERROR,
    payload: mes,
  };
};
