import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";
import _, { isEmpty } from "lodash";

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  LOGIN_AS_USER,
  GET_ALL_USERS_LIST,
  CHECK_GOOGLE_LOGIN_USER,
  GOOGLE_DRIVE_LOGIN,
  CHECK_GOOGLE_DRIVE_LOGIN_USER,
  LOGIN_SHARE_LINK,
  SWITCH_TO_SESSION,
  CHECK_ZOHO_LOGIN_USER,
  SALESFORCE_USER_AUTH_SUCCESS,
  CHECK_DYNAMICS_LOGIN_USER,
} from "./actionTypes";
import {
  loginSuccess,
  apiError,
  loginAsUserSuccess,
  getUserSuccess,
  loginShareLinkSuccess,
  switchToSessionError,
} from "./actions";

//services
import {
  getJWTToken,
  getLoginAsUser,
  allUsers,
  getGoogleLoginJWTToken,
  getTokenBySessionId,
  googleDriveLoginService,
  getCheckGoogleDriveLogin,
  getShareLinkLogin,
  loginToSessionUsingLink,
  saveSalesforceCode,
  getZohoCode,
  getDynamicsCode,
} from "../../../services/auth/authservice";
import {
  deleteLocalStorage,
  getLocalStorage,
  setLocalStorage,
  setTokenCookies,
} from "../../../services/utilities/utilservice";
import { ResponseGenerator } from "../../../services/userservice";
import { CHANGE_CURRENT_ACCOUNT } from "../profile/actionTypes";
import { getCurrentUserDetails } from '../../../services/newUserService';
import axios from 'axios';
import { urls } from '../../../utilities/urls';
import { endpoints } from '../../../utilities/endpoints';

function inIframe() {
  return window.location !== window.parent.location;
}

function* loginUser({ payload: { user, history, redirectURL, sessionId } }: any) {
  const response: ResponseGenerator = yield call(getJWTToken, {
    userName: user.email,
    password: user.password,
    sessionId: sessionId,
  });
  if (_.has(response, "JWT")) {
    const userDetails: ResponseGenerator = yield call(getCurrentUserDetails);

    if (!userDetails?.data?.username) {
      yield put(apiError('Access denied'));

      history.push('/login');

      return;
    }

    if (sessionId !== null) {
      yield put(loginSuccess(response));
      window.close();
    } else if (redirectURL) {
      yield put(loginSuccess(response));
      if (redirectURL.indexOf("?") !== -1) {
        window.location.href = `${redirectURL}&token=${response.JWT}`;
      } else {
        window.location.href = `${redirectURL}?token=${response.JWT}`;
      }
    } else {
      yield put(loginSuccess(response));

      if (inIframe()) {
        history.push("/documents_import");
      } else {
        history.push("/dashboard");
      }
    }
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(apiError(error));
    } else {
      yield put(apiError("An Error has occured"));
    }
  }
}

function* checkGoogleLoginUser({ payload: { code, history } }: any) {
  const response: ResponseGenerator = yield call(getGoogleLoginJWTToken, code);
  const sessionId =  new URLSearchParams(code).get("state");
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    console.log('getGoogleLoginJWTToken => error', error);
    if (error) {
      yield put(apiError(error));
    } else {
      yield put(apiError("An Error has occured"));
    }
  } else {
    if(getLocalStorage('isSessionIdExist')) {
      console.log('Got session Id =', sessionId);
      const response: ResponseGenerator = yield call(getTokenBySessionId, sessionId);
      console.log('Google Login Get Token Response:');console.log(response);
      if (_.has(response, "JWT")) {
        console.log('getTokenBySessionId got JWT =', response);
        //setLocalStorage("auth-token", response.JWT);
        yield put(loginSuccess(response));
        window.close();
        // setTimeout(() => {
        //   if (inIframe()) {
        //     history.push("/documents_import");
        //   } else {
        //     history.push("/meetings");
        //   }
        // }, 2000);
      } else {
        const error = response.error || response.errors;
        console.log('getTokenBySessionId got error =', error);
        if (error) {
          yield put(apiError(error));
        } else {
          yield put(apiError("An Error has occured"));
        }
      }
      deleteLocalStorage('isSessionIdExist')
    } else {
      if (_.has(response, "JWT")) {
        console.log('getGoogleLoginJWTToken => JWT', response);
        //setLocalStorage("auth-token", response.JWT);
        yield put(loginSuccess(response));
        setTimeout(() => {
          if (inIframe()) {
            history.push("/documents_import");
          } else {
            history.push("/dashboard");
          }
        }, 2000);
      }
    }
  }
}

function* checkZohoUser({ payload: { code, history } }: any) {
  const response: ResponseGenerator = yield call(getZohoCode, code);

  setTimeout(() => {
    history.push("/settings/integrations");
  }, 2000);
}

function* salesforceUserAuthSuccess({ payload: { code, history } }: any) {
  const response: ResponseGenerator = yield call(saveSalesforceCode, code);

  setTimeout(() => {
    history.push("/settings/integrations");
  }, 2000);
}

function* checkDynamicsUser({ payload: { code, history } }: any) {
  const response: ResponseGenerator = yield call(getDynamicsCode, code);

  setTimeout(() => {
    history.push("/settings/integrations");
  }, 3000);
}


function* checkGoogleDriveLoginUser({ payload: { code, history } }: any) {
  const response: ResponseGenerator = yield call(
    getCheckGoogleDriveLogin,
    code
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(apiError(error));
    } else {
      yield put(apiError("An Error has occured"));
    }
  } else {
    window.close();
  }
}

function* googleDriveLogin({ payload: { callback } }: any) {
  const response: ResponseGenerator = yield call(googleDriveLoginService);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(apiError(error));
    } else {
      yield put(apiError("An error has occured"));
    }
  } else {
    callback(response);
  }
}

function* loginAsUser({ payload: { userId, history } }: any) {
  const response: ResponseGenerator = yield call(getLoginAsUser, userId);
  if (_.has(response, "JWT")) {
    setTokenCookies(response.JWT, false);

    yield put(loginAsUserSuccess(response));

    if (inIframe()) {
      history.push("/documents_import");
    } else {
      history.push("/dashboard");
    }
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(apiError(error));
    } else {
      yield put(apiError("An Error has occured"));
    }
  }
}

function* switchToSessionUsingLink({ payload: { link, history } }: any) {
  const response: ResponseGenerator = yield call(loginToSessionUsingLink, link);
  const { JWT } = response;

  if (JWT && !isEmpty(JWT)) {
    setTokenCookies(response.JWT, false);
    setLocalStorage("isTempAccount", true);
    setLocalStorage("isSuperAdmin", true);

    yield put(loginAsUserSuccess(response));
    yield take(CHANGE_CURRENT_ACCOUNT);

    history.push(`/recording/${link}`);
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(switchToSessionError(error.error));
    } else {
      yield put(switchToSessionError("An Error has occured"));
    }
  }
}

function* getUsers() {
  const response: ResponseGenerator = yield call(allUsers);
  if (_.has(response, "data")) {
    yield put(getUserSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(apiError(error));
    } else {
      yield put(apiError("An Error has occured"));
    }
  }
}

function* shareLinkLogin({ payload: { linkHash, onSuccess } }: any) {
  const response: ResponseGenerator = yield call(getShareLinkLogin, linkHash);
  if (_.has(response, "JWT")) {
    yield put(loginShareLinkSuccess(response));
    onSuccess(response.data);
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(apiError(error));
    } else {
      yield put(apiError("An Error has occured"));
    }
  }
}

export function* watchGoogleDriveLogin() {
  yield takeEvery(GOOGLE_DRIVE_LOGIN, googleDriveLogin);
}

export function* watchCheckGoogleDriveloginUser() {
  yield takeEvery(CHECK_GOOGLE_DRIVE_LOGIN_USER, checkGoogleDriveLoginUser);
}

// eslint-disable-next-line require-yield
function* logoutUser({ payload: { history } }: any) {
  localStorage.removeItem("authUser");
  localStorage.clear();
  history.push("/login");
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUsers() {
  yield takeEvery(GET_ALL_USERS_LIST, getUsers);
}

export function* watchLoginAsUser() {
  yield takeEvery(LOGIN_AS_USER, loginAsUser);
}

export function* watchCheckGoogleloginUser() {
  yield takeEvery(CHECK_GOOGLE_LOGIN_USER, checkGoogleLoginUser);
}

export function* watchZohoUser() {
  yield takeEvery(CHECK_ZOHO_LOGIN_USER, checkZohoUser);
}

export function* watchSalesforceUserAuth() {
  yield takeEvery(SALESFORCE_USER_AUTH_SUCCESS, salesforceUserAuthSuccess);
}

export function* watchDynamicsUser() {
  yield takeEvery(CHECK_DYNAMICS_LOGIN_USER, checkDynamicsUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchLoginShareLink() {
  yield takeEvery(LOGIN_SHARE_LINK, shareLinkLogin);
}

export function* watchSwitchToSessionUsingLink() {
  yield takeEvery(SWITCH_TO_SESSION, switchToSessionUsingLink)
}

function* authSaga() {
  yield all([
    fork(watchUsers),
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchLoginAsUser),
    fork(watchCheckGoogleloginUser),
    fork(watchGoogleDriveLogin),
    fork(watchCheckGoogleDriveloginUser),
    fork(watchLoginShareLink),
    fork(watchSwitchToSessionUsingLink),
    fork(watchZohoUser),
    fork(watchSalesforceUserAuth),
    fork(watchDynamicsUser),
  ]);
}

export default authSaga;
