import { getAssociatedTeamsService, ResponseGenerator } from "../../../services/userservice";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import _ from "lodash";
import { associatedTeamsApiError, getAssociatedTeamsSuccess } from "./actions";
import { GET_ASSOCIATED_TEAMS } from "./actionTypes";

function* getAssociatedTeams({ payload: { id } }: any) {
  const response: ResponseGenerator = yield call(getAssociatedTeamsService, id);
  if (_.has(response, "data")) {
    yield put(getAssociatedTeamsSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(associatedTeamsApiError(error));
    } else {
      yield put(associatedTeamsApiError("An Error has occurred"))
    }
  }
}

export function* watchAssociatedTeams() {
  yield takeEvery(GET_ASSOCIATED_TEAMS, getAssociatedTeams);
}

function* getAssociatedTeamsSaga() {
  yield all([fork(watchAssociatedTeams)]);
}

export default getAssociatedTeamsSaga;