import React from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';

import {
  abbreviations,
  AGENT_DATA_EXTRACTOR_NAME,
  AGENT_PLAYBOOK_COMPONENT,
  AGENT_PLAYBOOK_NAME,
  ANALYTICS_STAT_WIDGET_NAMES,
  BINARY_WIDGET_NAMES,
  CHART_TYPE_GROUPS,
  CHART_TYPES,
  COMPARE_BY_TEAM_KEY,
  COMPARE_BY_USER_KEY,
  COMPARE_BY_VALUE_KEY,
  GROUP_DOMAIN,
  GROUP_EMAIL,
  GROUP_TEAM,
  GROUP_USER,
  GROUP_VALUE,
  IGlobalFilters,
  IGroupByMap,
  isAnalyticStatsWidget,
  isAnalyticWidgetWithValueOption,
  isBinaryWidgetType,
  isUnhandledWidgetType,
  isWidgetWithoutAbbreviation,
  PERIOD_TYPES,
  SENTENCE_OPTIONS_SPEAKER_TYPES,
  UNHANDLED_WIDGET_NAMES
} from './constants';
import { FormattedData, IAnalyticsWidgetData, Metrics } from './IWidget';
import loaderImg from '../../../assets/images/loader.gif';
import {
  ANALYTICS_STATS_WIDGET_TYPES,
  DropdownOption,
  KEYWORD_STATUSES,
  STATISTIC_WIDGET_TYPES,
} from '../../../pages/Home/utils/constants';
import { firstLetterUppercase, getDateOfWeek, } from '../../../services/utilities/utilservice';
import {
  Filters,
  IAiCategory,
  IAssociatedTeam,
  IAssociatedUser,
  ICallOutcome,
  IDealStatus,
} from '../../../store/interface';
import { IAnalyticsKeywordsData } from '../../../pages/Settings/Analytics/IAnalyticTypes';
import { IWidgetLayoutOptions, IWidgetSavingOptions } from '../WidgetLayoutItem/constants';
import { DataStructure } from '../utils/IWidget';
import { ICompareGroup } from '../Charts/StatsBarChart';
import { aiAgent } from './WidgetNames';
import { AIData, AIDataSeries } from './interfaces';

export const renderLoadingIndicator = (size: string = '64') => (
  <img src={loaderImg} alt="loader" width={size} height={size} />
);

export const roundData = (data: number) => Math.round(data * 100) / 100;

export const findGroupOption = (
  filters: Filters,
  isAnalyticStatsWidget?: boolean
) => {
  const compareGroups = isAnalyticStatsWidget
    ? CHART_TYPE_GROUPS.compare.filter((item, index) => index !== 2) // Exclude the value option
    : CHART_TYPE_GROUPS.compare;

  return (
    compareGroups.find((item) => item.value === filters.groupBy) ??
    compareGroups[0]
  );
};

export const determineCorrectFilters = (widgetType: string, filters: Filters): Filters => {
  let { chartType, groupBy } = filters;

  if (chartType === 'total') {
    if (!isAnalyticWidgetWithValueOption(widgetType)) {
      groupBy = 'value';
    } else {
      groupBy = 'total';
    }
  } else if (chartType === 'compare' && groupBy === 'total') {
    groupBy = 'user';
  }

  return {
    ...filters,
    groupBy,
  };
};

export const findIntervalOption = (filters: Filters) => {
  return (
    CHART_TYPE_GROUPS.period.find((item) => item.value === filters.period) ??
    CHART_TYPE_GROUPS.period[2]
  );
};

export const findUnitOption = (filters: Filters, isPlaybook = false) => {
  if (isPlaybook) {
    return CHART_TYPE_GROUPS.unit[1]
  }

  return (
    CHART_TYPE_GROUPS.unit.find((item) => item.value === filters.denominator) ??
    CHART_TYPE_GROUPS.unit[0]
  );
};

export const findSpeakerOption = (filters: Filters) => {
  return (
    SENTENCE_OPTIONS_SPEAKER_TYPES.find(
      (item) => item.value === filters.speakerType
    ) ?? SENTENCE_OPTIONS_SPEAKER_TYPES[0]
  );
};

export const renderGroups = (
  widgetType: string,
  isMultiWidgets?: boolean,
  isAnalyticStatsWidget?: boolean,
  areDifferentWidgets?: boolean,
) => {
  if (
    isMultiWidgets && (isAnalyticStatsWidget || areDifferentWidgets)
      || isAnalyticStatsWidget && ![ANALYTICS_STATS_WIDGET_TYPES.DEALS, ANALYTICS_STATS_WIDGET_TYPES.CALL_OUTCOMES].includes(widgetType)
  ) {
    return CHART_TYPE_GROUPS.compare
      .filter((option) => option.label === 'User' || option.label === 'Team')
      .map(({ id, value, label }) => ({ id, value, label }));
  } else {
    return CHART_TYPE_GROUPS.compare.map(({ id, value, label }) => ({ id, value, label }));
  }
};

export const findOption = (
  callOutcomes: string[] | undefined,
  callOutcomeOptions: ICallOutcome[] | IDealStatus[]
): DropdownOption[] => {
  if (isEmpty(callOutcomes)) {
    return [];
  }

  const lowerCaseCallOutcomes = callOutcomes?.map((outcome) =>
    outcome.toLowerCase()
  );

  const matchingOutcomes = callOutcomeOptions
    .filter((option) =>
      lowerCaseCallOutcomes?.includes(option.name.toLowerCase())
    )
    .map((option) => ({
      id: option.id,
      label: option.name,
      value: option.name.toLowerCase().trim(),
    }));

  return matchingOutcomes;
};

export const findChartType = (filters: Filters, isSpecificAgentWidget?: boolean) => {
  return CHART_TYPES.find((type) => type.value === filters.chartType) ?? CHART_TYPES[1];
};

export const transformData = (data: any) => {
  const outputData: { [x: string]: { [x: string]: any } } = {};

  const dates: string[] = [];
  for (const item in data) {
    for (const date in data[item]) {
      if (!dates.includes(date)) {
        dates.push(date);
      }
    }
  }

  for (const item in data) {
    outputData[item] = {};
    for (const date of dates) {
      outputData[item][date] = data[item][date] || "0";
    }
  }

  return outputData;
};

export const filterNullProperties = (obj: any): any => {
  const filteredObj = { ...obj };

  for (const key in filteredObj) {
    if (filteredObj[key] === null) delete filteredObj[key];
  }

  return filteredObj;
};

export function fillMissingDates(dataObject: {
  [key: number]: IAnalyticsWidgetData;
}): IAnalyticsWidgetData[] {
  const dataArray = Object.values(dataObject);

  const allDates = new Set<string>();
  dataArray.forEach((item) => {
    if (typeof item.metric === "object" && item.metric !== null) {
      Object.keys(item.metric).forEach((date) => allDates.add(date));
    }
  });

  const filledDataArray = dataArray.map((item) => {
    if (typeof item.metric === "object" && item.metric !== null) {
      const metricsWithAllDates: Metrics = { ...item.metric };
      allDates.forEach((date) => {
        if (metricsWithAllDates[date] === undefined) {
          metricsWithAllDates[date] = 0;
        }
      });
      return { ...item, metric: metricsWithAllDates };
    }
    return item;
  });

  return filledDataArray;
}

export function sortDataSetsByDate(series: FormattedData): void {
  series.forEach((dataSet) => {
    dataSet.data.sort((a, b) => {
      return moment(a.x).valueOf() - moment(b.x).valueOf();
    });
  });
}

export const isValidGlobalFilters = (obj: any): obj is IGlobalFilters => {
  const allowedProperties = [
    "recording_types",
    "campaign_ids",
    "global_team_ids",
    "global_user_ids",
    "global_meeting_types",
    "deals_status",
    "calls_outcome",
    "interval",
    "global_start",
    "global_end",
    "limit",
  ];

  if (obj && typeof obj === "object") {
    const properties = Object.keys(obj);
    if (properties.every((property) => allowedProperties.includes(property))) {
      return true;
    }
  }

  return false;
};

export const formatMonthlyDataForPeriodChart = (
  data: IAnalyticsWidgetData[] | AIDataSeries[],
  formatter: any
) => {
  const formattedData = Object.values(data).flatMap(
    (item: IAnalyticsWidgetData) => {
      const formattedMetrics = Object.keys(item.metric).map((date) => ({
        name: item.name,
        x: new Date(date),
        y: formatter(parseFloat((item.metric as any)[date]?.toFixed(1))),
      }));
      return formattedMetrics;
    }
  );

  if (!formattedData) {
    return [];
  }

  const result: {
    [key: string]: { name: string; data: { x: Date; y: number }[] };
  } = formattedData.reduce((acc, obj) => {
    const key = obj.name;
    if (!acc[key]) {
      acc[key] = { name: obj.name, data: [] };
    }
    acc[key].data.push({ x: obj.x, y: obj.y });
    return acc;
  }, {} as { [key: string]: { name: string; data: { x: Date; y: number }[] } });

  return Object.values(result);
};

export const formatWeeklyDataForPeriodChart = (
  data: IAnalyticsWidgetData[] | AIDataSeries[],
  formatter: any
) => {
  return (
    data &&
    Object.values(data).map((item) => {
      const resultData: { x: Date; y: number }[] = [];
      Object.keys(item.metric).forEach((key) => {
        if (key !== "name") {
          const [year, week] = key.split("-");
          const startOfWeek = getDateOfWeek(parseInt(week), parseInt(year));
          let dateString = startOfWeek.toISOString().split("T")[0];
          resultData.push({
            x: new Date(dateString),
            y: formatter(parseFloat((item.metric as any)[key]?.toFixed(1))),
          });
        }
      });
      return { name: item.name, data: resultData };
    })
  );
};

export const formatDailyDataForPeriodChart = (
  data: IAnalyticsWidgetData[] | AIDataSeries[],
  formatter: any
) => {
  return Object.values(data).map((item: IAnalyticsWidgetData) => {
    const formattedMetrics = Object.keys(item.metric).map((date) => ({
      x: new Date(date),
      y: formatter(parseFloat((item.metric as any)[date]?.toFixed(1))),
    }));
    return {
      name: item.name,
      data: formattedMetrics,
    };
  });
};

export interface DirectUpdateFields {
  [key: string]: keyof Filters;
}

export const separateIntegerFromSentence = (inputString?: string) => {
  if (!inputString) {
    return { number: 0, sentence: 'Sentence unavailable' };
  }

  const numberString = inputString.split(' ')[0];
  const number = parseFloat(numberString);
  const sentence = inputString.slice(numberString.length).trim();

  return { number, sentence };
};

export const highlightPhrases = (
  transcriptSentence: string,
  phrases?: string,
) => {
  if (!phrases) {
    return <>{transcriptSentence}</>;
  }

  const splitPhrases = phrases.split(', ');
  const filteredSearchTerms = splitPhrases.filter(
    (searchTerm: string) => searchTerm !== ''
  );

  const matchRegex = RegExp(filteredSearchTerms.join('|'), 'ig');
  const matches = Array.from(transcriptSentence.matchAll(matchRegex));

  return transcriptSentence.split(matchRegex).map((nonBoldText, index, arr) => (
    <React.Fragment key={index}>
      {nonBoldText}
      {index + 1 !== arr.length && (
        <b style={{ color: '#3C64F0' }}>{matches[index]}</b>
      )}
    </React.Fragment>
  ));
};

export const getMinMaxDatesByPeriodType = (
  period: string,
  startDate: string | null,
  endDate: string | null,
) => {
  let minDate, maxDate;
  const firstDayOfMonth = startDate ? new Date(startDate) : undefined;
  const lastDayOfMonth = endDate ? new Date(endDate) : undefined;
  if (period === PERIOD_TYPES.MONTHLY) {
    if (firstDayOfMonth) {
      firstDayOfMonth.setDate(1);
      minDate = firstDayOfMonth.getTime();
    }
    if (lastDayOfMonth) {
      maxDate = lastDayOfMonth.getTime();
    }
  } else {
    minDate = firstDayOfMonth ? firstDayOfMonth.getTime() : undefined;
    maxDate = lastDayOfMonth ? lastDayOfMonth.getTime() : undefined;
  }

  if (maxDate === undefined) {
    maxDate = new Date().getTime();
  }

  return { minDate, maxDate };
};

export const renderWidgetTitleByType = (type: string): string => {
  if (isBinaryWidgetType(type)) {
    return BINARY_WIDGET_NAMES[type];
  } else if (isUnhandledWidgetType(type)) {
    return UNHANDLED_WIDGET_NAMES[type];
  } else if (isAnalyticStatsWidget(type)) {
    return ANALYTICS_STAT_WIDGET_NAMES[type];
  } else if (type === STATISTIC_WIDGET_TYPES.AI_AGENT) {
    return aiAgent;
  } else if (type === AGENT_PLAYBOOK_COMPONENT) {
    return 'Playbook';
  } else {
    return `${firstLetterUppercase(type)}`;
  }
};

export const getNameByAbbreviation = (
  aiCategories: IAiCategory[],
  widgetType: string,
  value: string
) => {
  const findCategory = (valueToFind: string) =>
    aiCategories.find(
      (category) =>
        category.short_name.toLowerCase() === valueToFind.toLowerCase() && category.type === widgetType
    );

  const findAbbreviation = (valueToFind: string) =>
    abbreviations.find((item) => item.id === valueToFind);

  switch (widgetType) {
    case STATISTIC_WIDGET_TYPES.SELLER_QUESTION:
    case STATISTIC_WIDGET_TYPES.BUYER_QUESTION:
    case STATISTIC_WIDGET_TYPES.CUSTOMER_SENTIMENT:
    case STATISTIC_WIDGET_TYPES.BUYER_OBJECTION:
    case STATISTIC_WIDGET_TYPES.ASYNC_UNPROFESSIONAL_BEHAVIOUR:
      const category = findCategory(value);
      return category ? category.category : value;

    case STATISTIC_WIDGET_TYPES.SENTIMENT:
      const abbreviation = findAbbreviation(value);
      return abbreviation ? abbreviation.sentence_type : value;

    default:
      return value;
  }
};

export const renderModalTitleByFilter = (
  widgetType: string,
  groupBy: string,
  value: string,
  aiCategories: IAiCategory[]
) => {
  const widgetTitle = renderWidgetTitleByType(widgetType);

  let processedValue;

  if (isBinaryWidgetType(widgetType)) {
    processedValue = "";
  } else if (isWidgetWithoutAbbreviation(widgetType)) {
    processedValue = value;
  } else if (isAnalyticStatsWidget(widgetType)){
    processedValue = "";
  } else if (widgetType === STATISTIC_WIDGET_TYPES.AI_AGENT) {
    processedValue = firstLetterUppercase(value);
  } else {
    processedValue = getNameByAbbreviation(aiCategories, widgetType, value);
  }

  const GROUP_BY: IGroupByMap = {
    [GROUP_USER]: `${widgetTitle} by user: ${value}`,
    [GROUP_TEAM]: `${widgetTitle} by team: ${value}`,
    [GROUP_VALUE]: `${widgetTitle}: ${processedValue}`,
    [GROUP_EMAIL]: `${widgetTitle} by contact: ${value}`,
    [GROUP_DOMAIN]: `${widgetTitle} by domain: ${value}`,
  };

  if (
    groupBy === GROUP_USER ||
    groupBy === GROUP_TEAM ||
    groupBy === GROUP_VALUE ||
    groupBy === GROUP_EMAIL ||
    groupBy === GROUP_DOMAIN
  ) {
    return GROUP_BY[groupBy];
  } else {
    return value;
  }
};

export const getAnalyticsStatsWidgetTitle = (
  widgetType: string,
  id: string | undefined,
  groupBy: string
): string => {
  const widgetTitle = renderWidgetTitleByType(widgetType);

  const GROUP_BY: IGroupByMap = {
    [GROUP_USER]: `${widgetTitle} by user: ${id}`,
    [GROUP_TEAM]: `${widgetTitle} by team: ${id}`,
    [GROUP_VALUE]: `${widgetTitle}`,
    [GROUP_EMAIL]: `${widgetTitle} by contact: ${id}`,
    [GROUP_DOMAIN]: `${widgetTitle} by domain: ${id}`,
  };

  if (
    groupBy === GROUP_USER ||
    groupBy === GROUP_TEAM ||
    groupBy === GROUP_VALUE ||
    groupBy === GROUP_EMAIL ||
    groupBy === GROUP_DOMAIN
  ) {
    return GROUP_BY[groupBy];
  } else {
    return id ?? "";
  }
};

export const getPhrasesForEachKeyword = (
  keywords: IAnalyticsKeywordsData[]
): string[] => {
  return keywords.flatMap((keyword) =>
    keyword.phrases.split(', ').filter(Boolean)
  );
};

export const getSpecificPhrasesFromKeywords = (
  keywords: IAnalyticsKeywordsData[],
  specificStats: string[]
): string[] => {
  const specificKeywords = keywords.filter((keyword) =>
    specificStats.includes(keyword.keyword)
  );
  return specificKeywords.flatMap((keyword) =>
    keyword.phrases.split(', ').filter(Boolean)
  );
};

export const getPhrasesForKeywordGroupIds = (
  keywords: IAnalyticsKeywordsData[],
  keywordGroupIds: string[]
): string[] => {
  const filteredKeywords = keywords.filter((keyword) =>
    keyword.keyword_group_ids.some((id) => keywordGroupIds.includes(id))
  );
  return filteredKeywords.flatMap((keyword) =>
    keyword.phrases.split(', ').filter(Boolean)
  );
};

export const renderKeywordMentionedOptions = (): DropdownOption[] => {
  return [
    {
      id: 'keyword_mentioned_id',
      label: 'Mentioned',
      value: KEYWORD_STATUSES.MENTIONED,
    },
    {
      id: 'keyword_not_mentioned_id',
      label: 'Not mentioned',
      value: KEYWORD_STATUSES.NOT_MENTIONED,
    },
  ];
};

const parseDate = (dateStr: string): Date => {
  let dateObj;
  if (/^\d{4}-\d{2}$/.test(dateStr)) {
    if (parseInt(dateStr.split('-')[1], 10) <= 12) {
      dateObj = moment(`${dateStr}-01`).toDate();
    } else {
      dateObj = moment(dateStr, 'YYYY-WW').startOf('isoWeek').toDate();
    }
  } else {
    dateObj = new Date(dateStr);
  }
  return dateObj;
};

export const formattingAnalyticsData = (
  analyticData: any,
  options: IWidgetSavingOptions,
  valueType: string,
  defaultTitle: string,
  formatter: (value: number) => string
) => {
  let result: any;
  if (options.filters.chartType === 'period') {
    const metricData = analyticData.total.metric;
    const transformedData = basicTransform({ metricData });

    result = {
      type: 'multiLine',
      data: transformedData,
      name: options.widgetTitle ?? defaultTitle,
    };
  } else {
    result = analyticData.map((data: IAnalyticsWidgetData) => ({
      statType: data.name,
      value: formatter(
        typeof data.metric === 'number' ? data.metric : data.metric.value
      ),
      valueType,
    }));
  }

  return result;
};

export const formattingAgentData = (
  options: IWidgetSavingOptions,
  agentData: any,
) => {
  if (options.filters.chartType === 'period') {
    const metricData = agentData.total?.values ?? [];
    const transformedData = basicTransform({ metricData }, true);

    return {
      type: 'multiLine',
      data: transformedData,
      name: options.widgetTitle ?? agentData?.total?.name ?? 'Playbook',
    };
  }

  const title = options.filters.chartType === 'total' ? options.widgetTitle : null;

  return agentData.map((data: AIData) => ({
    statType: title ?? data.name,
    value: Number(data.value * 100).toFixed(2),
    type: 'playbook',
  }));
};

export const basicTransform = (data: DataStructure, toPercentage = false) => {
  const allDataPoints: any = [];
  Object.values(data).forEach(values => {
    Object.entries(values).forEach(([date, value]) => {
      allDataPoints.push({
        x: parseDate(date),
        y: toPercentage ? Math.round(value * 100) : Math.round(value),
      });
    });
  });
  allDataPoints.sort((a: any, b: any) => a.x - b.x);

  return allDataPoints;
};

export const filterGlobalPeriod = (filters: IGlobalFilters): IGlobalFilters => {
  const filteredEntries = Object.entries(filters).filter(([key, value]) => key !== 'global_period');
  return Object.fromEntries(filteredEntries) as IGlobalFilters;
};

export const areAllPropertiesFalsy = (obj: IGlobalFilters): boolean => {
  return Object.values(obj).every(value =>
    (Array.isArray(value) && value.length === 0) ||
    value === "" ||
    value === null ||
    value === undefined
  );
};

export const getCompareGroupSelectedOption = (
  widgetType: string,
  value: string | number,
  aiCategories: IAiCategory[],
) => {

  if (
    widgetType === STATISTIC_WIDGET_TYPES.SELLER_QUESTION ||
    widgetType === STATISTIC_WIDGET_TYPES.BUYER_QUESTION ||
    widgetType === STATISTIC_WIDGET_TYPES.CUSTOMER_SENTIMENT ||
    widgetType === STATISTIC_WIDGET_TYPES.BUYER_OBJECTION ||
    widgetType === STATISTIC_WIDGET_TYPES.ASYNC_UNPROFESSIONAL_BEHAVIOUR
  ) {
    const category = aiCategories.find(
      (category) => category.category === value && category.type === widgetType
    );

    return {
      id: category?.short_name || value,
      value: category?.short_name || value,
    };
  }

  return isUnhandledWidgetType(widgetType)
    ? { id: value, sentence_type: value }
    : abbreviations.find(
        (abbreviation) => abbreviation.sentence_type === value
    );
};


export const getCompareGroupByValue = (
  widgetComponent: string,
  group: string,
  value: string | number,
  teamIdsWithNames: IAssociatedTeam[],
  userIdsWithNames: IAssociatedUser[],
  aiCategories: IAiCategory[],
) => {

  let selectedOption: any;
  let filter: ICompareGroup = { key: '', value: '' };
  switch (group) {
    case GROUP_VALUE:
      selectedOption = getCompareGroupSelectedOption(
        widgetComponent,
        value,
        aiCategories
      );
      filter = { value: selectedOption?.id, key: COMPARE_BY_VALUE_KEY };
      break;
    case GROUP_USER:
      selectedOption =
        userIdsWithNames &&
        userIdsWithNames.find((user: any) => `${user.first_name} ${user.last_name}` === value);
      filter = { value: selectedOption?.id, key: COMPARE_BY_USER_KEY };
      break;
    case GROUP_TEAM:
      selectedOption =
        teamIdsWithNames &&
        teamIdsWithNames.find((item: any) => item.name === value);
      filter = { value: selectedOption.id, key: COMPARE_BY_TEAM_KEY };
      break;
  }

  return { selectedOption, filter };
};

export const extractAgentIdFromWidgetComponent = (widgetComponent: string): number | undefined => {
  return widgetComponent.includes('aiAgent') ? Number(widgetComponent.substring(8)) : undefined;
}

export const extractPlaybookIdFromWidgetComponent = (widgetComponent: string): number | undefined => {
  return widgetComponent.includes(AGENT_PLAYBOOK_COMPONENT)
    ? Number(widgetComponent.substring(AGENT_PLAYBOOK_COMPONENT.length + 1))
    : undefined;
}


export const getTitleForAgentWidget = (widgetComponent: string) => {
  let name: string = '';

  if (widgetComponent.toLowerCase().includes(AGENT_PLAYBOOK_COMPONENT)) {
    name = AGENT_PLAYBOOK_NAME;
  } else if (widgetComponent.includes('aiAgent')) {
    name = AGENT_DATA_EXTRACTOR_NAME
  } else {
    name = '';
  }

  return name;
}

export const hasOnlyTitleChanged = (
  obj1: IWidgetLayoutOptions,
  obj2: IWidgetLayoutOptions,
): boolean => {
  const keys1 = Object.keys(obj1) as (keyof IWidgetLayoutOptions)[];
  const keys2 = Object.keys(obj2) as (keyof IWidgetLayoutOptions)[];

  if (keys1.length !== keys2.length) {
    return false;
  }

  let titleChanged = false;

  for (let key of keys1) {
    if (key === 'title') {
      if (obj1[key] !== obj2[key]) {
        titleChanged = true;
      }
    } else {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }

  return titleChanged;
}
