import React, { Component } from "react";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { History, Location } from "history";

import { setTokenCookies } from "../../services/utilities/utilservice";

type partnerLoginProps = {
  location: Location,
  history: History,
};

type partnerLoginState = {
};

class PartnerLogin extends Component<partnerLoginProps, partnerLoginState> {
  constructor(props: partnerLoginProps) {
    super(props);
  }

    componentDidMount() {
      const search = this.props.location.search;
      if (search) {
        const token = new URLSearchParams(search).get("token");
        if (token) {
          setTokenCookies(token, false);
          this.props.history.push('/');
        }
      }
    }

  render() {
    return (
      <React.Fragment>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state: any) => {
  return {};
};

export default withRouter(
  connect(mapStatetoProps, {})(PartnerLogin)
);
