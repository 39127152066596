const FIVE_MB = 5 * 1024 * 1024;
const URLS_PAGE = 10;

export async function streamToS3Urls(stream, urls, getMoreUrls) {
  let buffer = new ArrayBuffer(FIVE_MB);
  let dataArray = new Uint8Array(buffer);
  let byteOffset = 0;
  let currentUrlIndex = 0;
  const partPromises = [];

  const appendUrls = async () => {
    const originalUrlsCount = urls.length;
    urls.length += URLS_PAGE;
    const { data: newUrls } = await getMoreUrls(originalUrlsCount + 1, URLS_PAGE);
    newUrls.forEach((url, i) => urls[originalUrlsCount + i] = url);
  };

  const sendChunk = async arrayToSend => {
    const partIndex = currentUrlIndex++;
    partPromises[partIndex] = retry(
      () => fetch(urls[partIndex], {
        method: 'PUT',
        headers: { 'Content-Type': 'multipart/form-data' },
        body: arrayToSend,
      })
    );
    return partPromises[partIndex];
  };

  return new Promise((resolve, reject) => {
    const writableStream = new WritableStream({
      write(chunk) {
        if (byteOffset + chunk.length <= FIVE_MB) {
          dataArray.set(chunk, byteOffset);
          byteOffset += chunk.length;
          return;
        }

        dataArray.set(chunk.slice(0, FIVE_MB - byteOffset), byteOffset);
        sendChunk(dataArray);

        buffer = new ArrayBuffer(FIVE_MB);
        dataArray = new Uint8Array(buffer);
        dataArray.set(chunk.slice(FIVE_MB - byteOffset), 0);
        byteOffset = byteOffset + chunk.length - FIVE_MB;

        if (urls.length - currentUrlIndex < URLS_PAGE / 2) {
          appendUrls();
        }
      },

      async close() {
        if (byteOffset > 0) {
          dataArray = dataArray.slice(0, byteOffset);
          sendChunk(dataArray);
        }
        const responses = await Promise.all(partPromises);
        const etags = responses.map(response => response.headers.get('ETag').replace(/"/g, ''));
        resolve(etags);
      },

      abort(reason) {
        reject(reason);
      },
    });

    stream.pipeTo(writableStream);
  });
};

export function detectWhenDisabled(stream, callback) {
  stream.getTracks().forEach(
    track => track.addEventListener('ended', () => {
      if (stream.getTracks().every(track => track.readyState === 'ended')) {
        callback(stream);
      }
    })
  );
}

export async function retry(asyncFunc, retryTimes = 3) {
  let attemptNum = 1;
  while (true) {
    try {
      return await asyncFunc();
    } catch (e) {
      if (++attemptNum > retryTimes) break;
    }
  }
}

// TODO Hack for live meetings, try to fix the file itself
export function hackForceVideoDuration(videoWrapperSelector, timeoutSeconds = null, start = 0) {
  let interval = null;
  let timedOut = false;

  const reveal = () => document.querySelector(videoWrapperSelector).style.visibility = 'visible';
  const cleanup = () => clearInterval(interval);

  if (timeoutSeconds) {
    setTimeout(() => {
      timedOut = true;
    }, timeoutSeconds * 1000);
  }

  return {
    recheck: (player, expectedDuration) => {
      if (!player || !expectedDuration || interval) return;

      player.seek(expectedDuration);
      interval = setInterval(() => {
        const duration = player.video?.video?.duration;
        if (timedOut || duration && duration !== Infinity) {
          player.seek(start);
          setTimeout(reveal);
          cleanup();
        }
      }, 200);
    },
    cleanup,
  };
}
