import React, { Component } from "react";
import playVideoIcon from "../../assets/images/play-video.svg"

interface AccessDeniedProps {
  title?: string,
  description?: string
}

class AccessDenied extends Component<AccessDeniedProps> {
  render() {
    const {
      title,
      description
    } = this.props;

    return (
      <div>
      <div className="snippet-message-container">
        <div className="create-snippet-text">
          <img
            src={playVideoIcon}
            alt="play movie"
            className="video-icon"
          />
          <h1 className="headline-text">{title}</h1>
          <h4 className="headline-details-text">{description}</h4>
        </div>
      </div>
    </div>
    );
  }
}

export default AccessDenied;
