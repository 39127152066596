import {
  GET_MEETING_TYPES,
  GET_MEETING_TYPES_ERROR,
  GET_MEETING_TYPES_SUCCESS,
  GET_CAMPAIGN,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_ERROR,
  CREATE_MEETING_TYPE,
  GET_CREATED_MEETING_TYPE,
  CREATE_MEETING_TYPE_SUCCESS,
  GET_DEFAULT_MEETING_TYPES,
  GET_DEFAULT_MEETING_TYPES_SUCCESS,
  GET_DEFAULT_MEETING_TYPES_ERROR,
  API_ERROR, EDIT_MEETING_TYPE, 
  EDIT_MEETING_TYPE_SUCCESS,
  EDIT_RECORDING_TYPE,
  EDIT_RECORDING_TYPE_ERROR,
} from "./actionTypes";
import { sendMeetingTypeData, meetingTypeOptions, IMeetingType } from "./interface";

export const getMeetingTypes = (accountId: String, options?: meetingTypeOptions) => {
  return {
    type: GET_MEETING_TYPES,
    payload: { accountId, options },
  };
};

export const getDefaultMeetingTypes = (accountId: String) => {
  return {
    type: GET_DEFAULT_MEETING_TYPES,
    payload: { accountId },
  };
};

export const getMeetingTypesSuccess = (meetingTypes: Object) => {
  return {
    type: GET_MEETING_TYPES_SUCCESS,
    payload: meetingTypes,
  };
};

export const getMeetingTypesError = (error: String) => {
  return {
    type: GET_MEETING_TYPES_ERROR,
    payload: error,
  };
};

export const getCampaign = (accountId: String) => {
  return {
    type: GET_CAMPAIGN,
    payload: { accountId },
  }
}

export const getCampaignSuccess = (campaigns: String[]) => {
  return {
    type: GET_CAMPAIGN_SUCCESS,
    payload: { campaigns },
  }
}

export const getCampaignError = (error: String) => {
  return {
    type: GET_CAMPAIGN_ERROR,
    payload: error,
  };
};

export const getDefaultMeetingTypesSuccess = (defaultMeetingTypes: Object) => {
  return {
    type: GET_DEFAULT_MEETING_TYPES_SUCCESS,
    payload: defaultMeetingTypes,
  };
};

export const getDefaultMeetingTypesError = (error: String) => {
  return {
    type: GET_DEFAULT_MEETING_TYPES_ERROR,
    payload: error,
  };
};

export const getCreatedMeetingType = (data: Object) => {
  return {
    type: GET_CREATED_MEETING_TYPE,
    payload: data,
  };
};

export const createMeetingType = (
  accountId: String,
  meetingType: sendMeetingTypeData,
  onSuccess?: () => void,
) => {
  return {
    type: CREATE_MEETING_TYPE,
    payload: { accountId, meetingType, onSuccess },
  };
};

export const createMeetingTypeSuccess = (newMeetingType: IMeetingType) => {
  return {
    type: CREATE_MEETING_TYPE_SUCCESS,
    payload: newMeetingType,
  };
};

export const editMeetingType = (
  accountId: String,
  meetingTypeId: String,
  meetingType: sendMeetingTypeData,
  onSuccess?: () => void,
) => {
  return {
    type: EDIT_MEETING_TYPE,
    payload: { accountId, meetingTypeId, meetingType, onSuccess },
  };
};

export const editMeetingTypeSuccess = (meetingType: IMeetingType) => {
  return {
    type: EDIT_MEETING_TYPE_SUCCESS,
    payload: meetingType,
  };
};

export const meetingTypeError = (error: String) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const editRecordingType = (accountId: String, meetingTypeId: String, sessionId: String) => {
  return {
    type: EDIT_RECORDING_TYPE,
    payload: { accountId, meetingTypeId, sessionId },
  };
}

export const editRecordingTypeError = (error: string | Object) => {
  return {
    type: EDIT_RECORDING_TYPE_ERROR,
    payload: error
  }
}