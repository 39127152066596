import React, { Component } from "react";
import { Modal } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";

import UserProfile from "../../Authentication/UserProfile";
import { createLog, getUserProfileSetupFinished } from "../../../store/Analytics/actions";
import { completeProfileSetup } from "../../../store/auth/edit-personal-profile/actions";
import { IUser } from "../../../store/interface";
import IntegrationCalendars from '../IntegrationCalendars';

interface SetupUserProfileModalProps {
  currentuser: IUser;
  userProfileSetupFinished: boolean | null;
  createLog: ({ data, onSuccess }: { data: any, onSuccess?: any }) => void;
  getUserProfileSetupFinished: (data: Object) => void;
  completeProfileSetup: () => void;
}

interface SetupUserProfileModalState {
  isOpen: Boolean;
  wasOpen: Boolean;
}

class SetupUserProfileModal extends Component<
  SetupUserProfileModalProps,
  SetupUserProfileModalState
> {
  constructor(props: SetupUserProfileModalProps) {
    super(props);

    this.state = {
      isOpen: false,
      wasOpen: false,
    }
  }

  componentDidUpdate(prevProps: SetupUserProfileModalProps) {
    const {
      userProfileSetupFinished,
      currentuser,
      getUserProfileSetupFinished: getUserProfileSetupFinishedAction,
      completeProfileSetup: completeProfileSetupAction,
    } = this.props;

    if (!_.isEmpty(currentuser) && userProfileSetupFinished === null) {
      getUserProfileSetupFinishedAction({ userId: currentuser.id });
    } else if (
      !_.isEmpty(currentuser)
      && userProfileSetupFinished !== prevProps.userProfileSetupFinished
      && userProfileSetupFinished !== null
    ) {
      const first_name = currentuser.first_name.split("-").join("");
      const last_name = currentuser.last_name.split("-").join("");

      const userProfileInfoCompleted = first_name
        && last_name
        && currentuser.email
        && currentuser.profile_picture_url;

      if (!userProfileSetupFinished && !userProfileInfoCompleted) {
        this.setState({
          isOpen: true
        });
      } else {
        completeProfileSetupAction();
      }
    }
  }

  createLogAction = () => {
    const { createLog: createLogAction, completeProfileSetup: completeProfileSetupAction } = this.props;

    createLogAction({
      data: {
        action: "profile_details_finished",
        action_type: "profile_details",
        target_id: "f7dd307f-364f-4e26-ba93-d4f511971b04"
      }
    });
    completeProfileSetupAction();
  }

  handleToggleModal = () => {
    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen,
      wasOpen: true,
    });

    this.createLogAction();
  };

  render() {
    const { isOpen, wasOpen } = this.state;

    if (window.location.pathname === "/settings/profile") {
      return <></>;
    }

    return (
      <>
        <Modal
          isOpen={isOpen}
          toggle={this.handleToggleModal}
          centered={true}
          size="lg"
          className="profile-modal"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              Setup your user profile information
            </h5>
            <button
              onClick={this.handleToggleModal}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <UserProfile
              allowPasswordChanges={false}
              showIntegration={false}
              onSubmit={this.handleToggleModal}
            />
          </div>
        </Modal>
        {wasOpen && <IntegrationCalendars />}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentuser } = state.Profile;
  const { userProfileSetupFinished } = state.SalesAnalytics;

  return { currentuser, userProfileSetupFinished };
};

export default withRouter<any, any>(
  connect(mapStateToProps, { getUserProfileSetupFinished, createLog, completeProfileSetup })(
    SetupUserProfileModal
  )
);
