import React, { Component } from "react";
import { Modal, Col, Row, Button } from "reactstrap";

interface UnshareLinkModalProps {
  isOpen: boolean;
  handleUnshare: () => void;
  handleClose: () => void;
}

class UnshareLinkModal extends Component<UnshareLinkModalProps> {
  render() {
    const { isOpen, handleUnshare, handleClose } = this.props;

    return (
      <Modal size="md" className="p-5" isOpen={isOpen} centered>
        <div
          className="modal-header user-modal"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Are you sure you want to unshare this link?
          </h5>
        </div>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Col
            md="8"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "5px",
              gap: "10px",
            }}
          >
            <Button
              color="red"
              type="submit"
              className="btn-danger"
              onClick={handleUnshare}
            >
              Yes, unshare
            </Button>
            <Button color="blue" type="submit" onClick={handleClose}>
              No, cancel
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default UnshareLinkModal;
