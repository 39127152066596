import React, { Component } from 'react';
import { renderLoadingIndicator } from '../../../../components/Widgets/utils/helperFunction';
import AIAgentPlaybookSummaryPreview from '../AIAgentPlaybookSummaryPreview';
import { isEmpty } from 'lodash';
import { AIAgentStatus, Type } from '../../utils/constants';
import { isLineReferenceColumn } from '../../utils/constants';
import { formatSummaryWithAsterisks } from '../../../../services/utilities/newUtilservice';

interface AIAgentResponsePreviewProps {
  className?: string;
  isStatusLoading: boolean;
  agentType: string;
  aiRequestStatus?: string;
  aiResponse?: string;
  playbookSummary?: string;
  playbookSummaryPosition?: 'top' | 'bottom';
  onClickTranscript: (sentence: string) => void;
}

interface AIAgentResponsePreviewState {
  tableHeaders: string[];
  data: Record<string, any>[];
  isTableFormat: boolean;
}

class AIAgentResponsePreview extends Component<
  AIAgentResponsePreviewProps,
  AIAgentResponsePreviewState
> {
  constructor(props: AIAgentResponsePreviewProps) {
    super(props);
    this.state = {
      tableHeaders: [],
      data: [],
      isTableFormat: false,
    };
  }

  componentDidMount(): void {
    const { aiResponse } = this.props;

    if (aiResponse) {
      this.parseTableData(aiResponse || '');
    }
  }

  componentDidUpdate(prevProps: AIAgentResponsePreviewProps): void {
    const { aiResponse } = this.props;

    if (aiResponse !== prevProps.aiResponse) {
      this.parseTableData(aiResponse || '');
    }
  }

  parseTableData = (response: string) => {
    if (!response.includes('|')) {
      this.setState({
        isTableFormat: false,
        data: [{ content: response }],
      });
      return;
    }

    const processedString = response.replace(/\|\s*\|/g, '|\n|');
    const rows = processedString.trim().split(/\r?\n/);
    const tableHeaders = rows[0]
      .split('|')
      .map((header) => header.trim())
      .filter((header) => header.length > 0);

    if (tableHeaders[0] === '') {
      tableHeaders.shift();
    }

    let data: Record<string, any>[] = [];
    for (let i = 2; i < rows.length; i++) {
      const fields = rows[i].split('|').map((field) => field.trim());

      if (fields[0] === '') {
        fields.shift();
      }

      let rowData: Record<string, any> = {};
      fields.forEach((field, index) => {
        if (index < tableHeaders.length) {
          rowData[tableHeaders[index]] = this.sanitizeField(field);
        }
      });

      data.push(rowData);
    }

    this.setState({
      tableHeaders: tableHeaders,
      data: data,
      isTableFormat: true,
    });
  };

  sanitizeField = (field: string) => {
    return field.replace(/<br\s*\/?>/g, '');
  };

  highlightMatches = (text: string, agentType: string) => {
    if (agentType === Type.MeetingSummary) {
      return formatSummaryWithAsterisks(text);
    }

    const doubleAsterisksPattern = /\*\*([^*]+)\*\*/gim;
    text = text.replace(doubleAsterisksPattern, '<b>$1</b>');

    return { __html: text.replace(/\n/g, '<br/>') };
  };

  getClickHandler = (header: string, row: Record<string, any>) => {
    const { onClickTranscript } = this.props;

    try {
      return () => onClickTranscript(row[header]);
    } catch (e: any) {
      console.error('Error clicking on line reference');

      return undefined;
    }
  };

  renderPlaybookSummary = (): JSX.Element => {
    const { aiResponse, agentType, playbookSummary } = this.props;

    if (agentType !== 'playbook' || isEmpty(aiResponse)) {
      return <></>;
    }

    return (
      <AIAgentPlaybookSummaryPreview playbookSummary={playbookSummary}/>
    )
  }

  render() {
    const { className, isStatusLoading, aiResponse, aiRequestStatus, playbookSummaryPosition, agentType } = this.props;
    const { tableHeaders, data, isTableFormat } = this.state;

    const highlightedResponse = aiResponse
      ? this.highlightMatches(aiResponse, agentType)
      : { __html: '' };

    if (isStatusLoading) {
      return (
        <div className="ai-agent-response">{renderLoadingIndicator()}</div>
      );
    }

    if (isTableFormat) {
      return (
        <>
          {playbookSummaryPosition === 'top' && this.renderPlaybookSummary()}
          <div className={`ai-agent-response ${className ?? ''}`}>
            <table className="ai-agent-response-table">
              <thead>
              <tr>
                {tableHeaders.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
              </thead>
              <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {tableHeaders.map((header, index) => (
                    <td key={index}>
                      {isLineReferenceColumn(header) && row[header] ? (
                        row[header].split(',').map((item: string, itemIndex: number) => (
                          <span
                            className="ai-agent-response-table__line-reference"
                            key={itemIndex}
                            onClick={this.getClickHandler(header, { ...row, [header]: item.trim() })}
                          >
                            <span>
                              {item.trim()}
                            </span>
                            {itemIndex < row[header].split(',').length - 1 && ', '}
                          </span>
                        ))) : (
                          <span onClick={this.getClickHandler(header, row)}>
                            {row[header] || ''}
                          </span>
                        )}
                    </td>
                  ))}
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          {playbookSummaryPosition === 'bottom' && this.renderPlaybookSummary()}
        </>
      );
    }

    return (
      <div className="ai-agent-response">
        {aiResponse ? (
          <div
            dangerouslySetInnerHTML={highlightedResponse}
          />
        ) : (!aiResponse && aiRequestStatus && aiRequestStatus === 'completed')
          ? (<i>No data for this request.</i>)
          : null
        }
      </div>
    );
  }
}

export default AIAgentResponsePreview;
