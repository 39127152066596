import {
  GET_RESOURCES,
  GET_RESOURCES_ERROR,
  GET_RESOURCES_SUCCESS,
  CREATE_NEW_RESOURCE,
  CREATE_RESOURCE_SUCCESS,
  EDIT_RESOURCE,
  EDIT_RESOURCE_SUCCESS,
  DELETE_RESOURCE,
  DELETE_RESOURCE_SUCCESS,
  API_ERROR,
} from "./actionTypes";
import { Resourcestate, ResourcesAction } from './interface';

const initialState: Resourcestate = {
  success: "",
  error: [],
  loader: false,
  deleteLoader: false,
  resources: null,
};

const Resources = (state = initialState, action: ResourcesAction) => {
  switch (action.type) {
    case GET_RESOURCES:
      state = {
        ...state,
        success: "",
        error: [],
        loader: true,
        resources: null,
      };
      break;
    case GET_RESOURCES_SUCCESS:
      state = {
        ...state,
        resources: action.payload,
        loader: false,
      };
      break;
    case GET_RESOURCES_ERROR:
      state = { ...state, error: action.payload, loader: false };
      break;
    case CREATE_NEW_RESOURCE:
      state = {
        ...state,
        success: "",
        error: [],
        loader: true,
      };
      break;
    case CREATE_RESOURCE_SUCCESS:
      state = { ...state, success: action.payload, loader: false };
      break;
    case EDIT_RESOURCE:
      state = {
        ...state,
        success: "",
        error: [],
        loader: true,
      };
      break;
    case EDIT_RESOURCE_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loader: false,
      };
      break;
    case DELETE_RESOURCE:
      state = {
        ...state,
        success: "",
        error: [],
        deleteLoader: true,
      };
      break;
    case DELETE_RESOURCE_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        deleteLoader: false,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        deleteLoader: false,
        loader: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Resources;
