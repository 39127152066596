import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer">
      <Container fluid={true}>
        <Row>
          <Col md={12} className="text-center">
            {new Date().getFullYear()} © Meetric.
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
