export const commonheaders = {
    headers: {
      'Content-Type': 'application/json',
    }
}

export const isAccessibleUnauthorized = (path: string) => {
  return path.startsWith('/recording-share', 0)
    || path.startsWith('/snippet-share', 0)
  ;
}
