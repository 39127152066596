import {
  GET_KEYWORDS,
  GET_KEYWORDS_ERROR,
  GET_KEYWORDS_SUCCESS,
  GET_KEYWORD_GROUPS,
  GET_KEYWORD_GROUPS_ERROR,
  GET_KEYWORD_GROUPS_SUCCESS,
  GET_KEYWORD_LANGUAGES,
  GET_KEYWORD_LANGUAGES_ERROR,
  GET_KEYWORD_LANGUAGES_SUCCESS,
} from "./actionTypes";

import { KeywordAction, KeywordState } from "./interface";

const initialState: KeywordState = {
  error: "",
  loader: false,
  keywordLanguages: [],
  keywords: [],
  keywordGroups: [],
};

const Keyword = (state = initialState, action: KeywordAction) => {
  switch (action.type) {
    case GET_KEYWORDS:
      state = {
        ...state,
        keywords: [],
        loader: true,
      }
      break;
    case GET_KEYWORDS_SUCCESS:
      state = {
        ...state,
        keywords: action.payload,
        loader: false,
      }
      break;
    case GET_KEYWORDS_ERROR:
      state = {
        ...state,
        error: action.payload,
      }
      break;
    case GET_KEYWORD_GROUPS:
      state = {
        ...state,
        keywordGroups: [],
        loader: true,
      }
      break;
    case GET_KEYWORD_GROUPS_SUCCESS:
      state = {
        ...state,
        keywordGroups: action.payload,
        loader: false,
      }
      break;
    case GET_KEYWORD_GROUPS_ERROR:
      state = {
        ...state,
        error: action.payload,
      }
      break;
    case GET_KEYWORD_LANGUAGES:
      state = {
        ...state,
        keywordLanguages: [],
        loader: true,
      }
      break;
    case GET_KEYWORD_LANGUAGES_SUCCESS:
      state = {
        ...state,
        keywordLanguages: action.payload,
        loader: false,
      }
      break;
    case GET_KEYWORD_LANGUAGES_ERROR:
      state = {
        ...state,
        error: action.payload,
      }
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
}

export default Keyword;
