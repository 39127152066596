import {
  CREATE_DESTINY_TOKEN,
  CREATE_DESTINY_TOKEN_ERROR,
  CREATE_DESTINY_TOKEN_SUCCESS,
  DELETE_DESTINY_TOKEN,
  DELETE_DESTINY_TOKEN_ERROR,
  DELETE_DESTINY_TOKEN_SUCCESS,
  EDIT_DESTINY_TOKEN,
  EDIT_DESTINY_TOKEN_ERROR,
  EDIT_DESTINY_TOKEN_SUCCESS,
  GET_DESTINY_TOKEN,
  GET_DESTINY_TOKEN_ERROR,
  GET_DESTINY_TOKEN_SUCCESS,
} from './actionTypes';

import { DestinyAction, DestinyState, } from './interface';

const initialState: DestinyState = {
  error: "",
  destinyToken: null,
  isLoading: false,
}

const DestinyIntegration = (state = initialState, action: DestinyAction) => {
  switch (action.type) {
    case GET_DESTINY_TOKEN:
    case CREATE_DESTINY_TOKEN:
    case EDIT_DESTINY_TOKEN:
    case DELETE_DESTINY_TOKEN:
      state = {
        ...state,
        isLoading: true,
      }

      break;
    case GET_DESTINY_TOKEN_SUCCESS:
    case CREATE_DESTINY_TOKEN_SUCCESS:
    case EDIT_DESTINY_TOKEN_SUCCESS:
    case DELETE_DESTINY_TOKEN_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        destinyToken: action.payload,
      }

      break;
    case GET_DESTINY_TOKEN_ERROR:
    case CREATE_DESTINY_TOKEN_ERROR:
    case EDIT_DESTINY_TOKEN_ERROR:
    case DELETE_DESTINY_TOKEN_ERROR:
      state = {
        ...state,
        isLoading: false,
        error: action.payload
      }

      break;
    default:
      state = {...state};
      break;
  }

  return state;
}

export default DestinyIntegration;
