import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter, Input, Button } from "reactstrap";
import { isEqual } from "lodash";

import CloseButton from "../../../CloseButton";

interface EditChatModalProps {
  chatTitle: string;
  isOpen: boolean;
  editTitle: (title: string) => void;
  closeModal: () => void;
}

interface EditChatModalState {
  localTitle: string;
}

class EditChatModal extends Component<EditChatModalProps, EditChatModalState> {
  constructor(props: EditChatModalProps) {
    super(props);
    this.state = {
      localTitle: props.chatTitle,
    };
  }

  componentDidUpdate(prevProps: EditChatModalProps) {
    const { chatTitle } = this.props;

    if (!isEqual(prevProps.chatTitle, chatTitle)) {
      this.setState({ localTitle: chatTitle });
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ localTitle: event.target.value });
  };

  handleSave = () => {
    const { editTitle, closeModal } = this.props;
    const { localTitle } = this.state;

    editTitle(localTitle);
    closeModal();
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.handleSave();
    }
  };

  render() {
    const { isOpen, closeModal } = this.props;
    const { localTitle } = this.state;

    return (
      <Modal isOpen={isOpen} centered size="md">
        <div className="edit-chat-modal-header">
          Edit title of the chat:
          <CloseButton handleClose={closeModal} />
        </div>
        <ModalBody>
          <Input
            value={localTitle}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.handleSave}>Save</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default EditChatModal;
