import React, { Component } from 'react';
import { Input } from 'reactstrap';

import TableLayout from '../TableLayout';

interface GptPromptItemComponentProps {
  promptData: string | null;
  onTableFormatChange: (isTableFormat: boolean) => void;
  onDataLengthChange: (dataLength: number) => void;
}

interface GptPromptItemComponentState {
  tableHeaders: string[];
  data: Record<string, any>[];
  isTableFormat: boolean;
}

class GptPromptItemComponent extends Component<
  GptPromptItemComponentProps,
  GptPromptItemComponentState
> {
  constructor(props: GptPromptItemComponentProps) {
    super(props);
    this.state = {
      tableHeaders: [],
      data: [],
      isTableFormat: true,
    };
  }

  componentDidMount(): void {
    const { promptData } = this.props;

    promptData && this.parseTableData(promptData);
  }

  componentDidUpdate(
    prevProps: GptPromptItemComponentProps,
    prevState: GptPromptItemComponentState
  ): void {
    const { promptData, onDataLengthChange } = this.props;
    const { data } = this.state;

    if (promptData && promptData !== prevProps.promptData) {
      this.parseTableData(promptData);
    }

    if (data.length !== prevState.data.length) {
      onDataLengthChange(data.length);
    }
  }

  parseTableData = (tableString: string) => {
    const { onTableFormatChange } = this.props;

    if (!tableString) return;

    if (!tableString.includes('|')) {
      this.setState(
        {
          isTableFormat: false,
          data: [{ content: tableString }],
        },
        () => onTableFormatChange(this.state.isTableFormat)
      );
      return;
    }

    const processedString = tableString.replace(/\|\s*\|/g, '|\n|');
    const rows = processedString.trim().split(/\r?\n/);
    const tableHeaders = rows[0]
      .split('|')
      .map((header) => header.trim())
      .filter((header) => header.length > 0);

    if (tableHeaders[0] === '') {
      tableHeaders.shift();
    }

    let data: Record<string, any>[] = [];
    for (let i = 2; i < rows.length; i++) {
      const fields = rows[i].split('|').map((field) => field.trim());

      if (fields[0] === '') {
        fields.shift();
      }

      let rowData: Record<string, any> = {};
      fields.forEach((field, index) => {
        if (index < tableHeaders.length) {
          rowData[tableHeaders[index]] = field;
        }
      });

      data.push(rowData);
    }

    this.setState(
      {
        tableHeaders: tableHeaders,
        data: data,
        isTableFormat: true,
      },
      () => onTableFormatChange(this.state.isTableFormat)
    );
  };

  render() {
    const { tableHeaders, data, isTableFormat } = this.state;

    if (!isTableFormat) {
      return (
        <Input
          type="textarea"
          className="response-gpt-prompt-textarea"
          value={data[0]?.content}
        />
      );
    }

    return (
      <div className="gpt-prompt-table">
        <TableLayout loading={false} tableHeaders={tableHeaders}>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>{rowIndex + 1}</td>
              {tableHeaders.map((header, index) => (
                <td key={index}>{row[header]}</td>
              ))}
            </tr>
          ))}
        </TableLayout>
      </div>
    );
  }
}

export default GptPromptItemComponent;
