import React, { Component } from "react";
import { Link } from "react-router-dom";

import { TABLE_HEADERS } from "./constants";
import TableLayout from "../../components/TableLayout";
import {
  firstLetterUppercase,
  formatDateTime,
} from "../../services/utilities/utilservice";
import { ISharedWithMeData } from "../../store/interface";

interface SharedWithMeTableProps {
  sharedWithMe: ISharedWithMeData[];
  loading: boolean;
}

class SharedWithMeTable extends Component<SharedWithMeTableProps> {
  render() {
    const { sharedWithMe, loading } = this.props;

    return (
      <div className="table-userlist light-fontweight-table snippet-table">
        <TableLayout tableHeaders={TABLE_HEADERS} loading={loading}>
          {sharedWithMe &&
            sharedWithMe.map(
              (itemSharedWithMe: ISharedWithMeData, index: number) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="underline-on-hover">
                      <Link
                        to={{
                          pathname: `/${itemSharedWithMe.type}-share/${itemSharedWithMe.linkHash}`,
                          state: { itemSharedWithMe },
                        }}
                        target="_blank"
                      >
                        {itemSharedWithMe.name}
                      </Link>
                    </td>
                    <td>{firstLetterUppercase(itemSharedWithMe.type)}</td>
                    <td>{formatDateTime(itemSharedWithMe.createdAt)}</td>
                    <td>{itemSharedWithMe.owner}</td>
                    <td>
                      <Link
                        to={{
                          pathname: `/${itemSharedWithMe.type}-share/${itemSharedWithMe.linkHash}`,
                          state: { itemSharedWithMe },
                        }}
                        target="_blank"
                        className="btn btn-primary btn-sm btn-edit"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                );
              }
            )}
        </TableLayout>
      </div>
    );
  }
}

export default SharedWithMeTable;
