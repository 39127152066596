import React, { Component } from "react";
import { Button, Label, Modal, Input } from "reactstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import {
  editPersonalProfile,
  changeProfilePassword,
} from "../../store/actions";
import loaderImg from "../../assets/images/loader.gif";
import { IUser } from "../../store/interface";

type changePasswordProps = {
  error: Object | String;
  success: String;
  loader: Boolean;
  isOpenPasswordModal: boolean;
  currentuser: IUser;
  onChangePassword: (value?: Boolean) => void;
  changeProfilePassword: (passwordData: Object) => void;
};

type changePasswordState = {
  newPassword: string;
  existingPassword: string;
};

class ChangePassword extends Component<
  changePasswordProps,
  changePasswordState
> {
  constructor(props: changePasswordProps) {
    super(props);
    this.state = {
      existingPassword: "",
      newPassword: "",
    };
  }

  componentDidUpdate(prevProps: changePasswordProps) {
    const { error, success, onChangePassword } = this.props;
    if (!isEmpty(error) && error !== prevProps.error) {
      onChangePassword(false);
    }

    if (!isEmpty(success) && success !== prevProps.success) {
      onChangePassword(false);
    }
  }

  handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState({ ...this.state, [name]: value });
  };

  handleSubmit = () => {
    const { newPassword, existingPassword } = this.state;
    const { changeProfilePassword } = this.props;

    const data = {
      new_password: newPassword,
      current_password: existingPassword,
    };

    changeProfilePassword(data);
  };

  handleToggle = (value?: Boolean) => {
    const { onChangePassword } = this.props;
    onChangePassword(value);
  };

  render() {
    const { newPassword, existingPassword } = this.state;
    const { onChangePassword, isOpenPasswordModal } = this.props;

    return (
      <React.Fragment>
        <Modal
          size="lg"
          isOpen={isOpenPasswordModal}
          toggle={this.handleToggle}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Change Password
            </h5>
            <button
              onClick={() => onChangePassword(false)}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="mt-2">
              <Label htmlFor="existingPassword">Existing Password</Label>
              <Input
                type="password"
                name="existingPassword"
                onChange={this.handlePassword}
                value={existingPassword}
              />
            </div>
            <div className="mt-3">
              <Label htmlFor="existingPassword">New Password</Label>
              <Input
                type="password"
                name="newPassword"
                onChange={this.handlePassword}
                value={newPassword}
              />
            </div>
            <div className="float-right mt-3">
              <Button
                color="primary"
                onClick={this.handleSubmit}
                className="btn-save btn-pw"
              >
                {this.props.loader ? (
                  <img src={loaderImg} alt="loader" width="20" height="20" />
                ) : (
                  "Change Password"
                )}
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { error, success, loader } = state.EditPersonalProfileDetails;
  const { currentuser } = state.Profile;
  return { error, success, currentuser, loader };
};

export default connect(mapStateToProps, {
  editPersonalProfile,
  changeProfilePassword,
})(ChangePassword);
