import axios, { AxiosResponse } from "axios";

import { ResponseGenerator } from "../userservice";
import { IAnalyticsKeywordsData } from "../../pages/Settings/Analytics/IAnalyticTypes";
import { urls } from "../../utilities/urls";
import { endpoints } from "../../utilities/endpoints";
import { commonheaders } from "../../utilities/apiConfig";

export async function getCommentDetails(
  accountId: String,
  globalFilterString: string,
): Promise<AxiosResponse<IAnalyticsKeywordsData[]>> {
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.recordingDetails}/comments?${globalFilterString}`,
      commonheaders,
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function deleteComment(
  commentId: String,
) {
  try {
    return await axios.delete(
      `${urls.baseApiURL}${endpoints.timeline}/${endpoints.comments}/${commentId}`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}
