import React, { Component } from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';
import { Container, Row } from 'reactstrap';
import _ from 'lodash';

import CreatingSnippetMessage from './CreatingSnippetMessage';
import AccessDenied from "./AccessDeniedPage";
import loaderImg from '../../assets/images/loader.gif';
import VideoPlayer from '../../components/Recording/VideoPlayer';
import {
  getSnippet,
  getSnippetRecordingTranscript,
  getSnippetTimeLine,
  getAllTagsByAccount,
  addSnippetTag,
  deleteSnippetTag,
} from '../../store/Analytics/actions';
import {
  IAnalyticsMeetingTimeline,
  IAnalyticsRecordings,
  ISnippet,
  ITranscript,
  TagType,
} from '../../store/Analytics/interface';
import { Icurrentaccountselected } from "../../store/auth/profile/interface";

const ACCESS_DENIED = {
  title: "You don't have access to this snippet",
  description: "Please ask the snippet owner for an access",
}

interface SnippetPageProps {
  match: match<{snippetUuid: string}>;
  currentaccountselected: Icurrentaccountselected;
  currentuser: any;
  snippet: ISnippet,
  snippetAccessError?: string | null,
  timeLineData: IAnalyticsMeetingTimeline[];
  recordingTranscript: ITranscript | null;
  loading: boolean,
  getSnippet: (id: String, params?: String) => void;
  getSnippetTimeLine: (accountId: String, snippetUuid: String) => void;
  getSnippetRecordingTranscript: (snippetUuid: string) => void;
  history?: {
    push: any;
  };
  snippetTags: string[];
  allTagsFromAccount: TagType[];
  getAllTagsByAccount: (accountId: String) => void;
  addSnippetTag: (name: string, snippetUuid: String, accountId: String) => void;
  deleteSnippetTag: (id: string, snippetUuid: String, accountId: String) => void;
}

class SnippetPage extends Component<SnippetPageProps, {}> {
  componentDidMount() {
    const {
      match,
      getSnippet: getSnippetAction,
      getAllTagsByAccount: getAllTagsByAccountAction,
      currentaccountselected,
    } = this.props;
    const snippetUuid = match.params.snippetUuid;

    if (snippetUuid && !_.isEmpty(currentaccountselected)) {
      getSnippetAction(snippetUuid);
      getAllTagsByAccountAction(currentaccountselected.id);
    }
  }

  componentDidUpdate(prevProps: SnippetPageProps) {
    const {
      match,
      getAllTagsByAccount: getAllTagsByAccountAction,
      getSnippet: getSnippetAction,
      currentaccountselected,
    } = this.props;
    const snippetUuid = match.params.snippetUuid;

    if (
      snippetUuid !== prevProps.match.params.snippetUuid ||
      currentaccountselected !== prevProps.currentaccountselected
    ) {
      if (snippetUuid && !_.isEmpty(currentaccountselected)) {
        getSnippetAction(snippetUuid);
        getAllTagsByAccountAction(currentaccountselected.id);
      }
    }
  }

  onAddTag = (name: string) => {
    const { match, currentaccountselected, addSnippetTag } = this.props;
    const snippetUuid = match.params.snippetUuid;

    addSnippetTag(name, snippetUuid, currentaccountselected.id);
  };

  deleteTag = (id: string) => {
    const { match, currentaccountselected, deleteSnippetTag } = this.props;
    const snippetUuid = match.params.snippetUuid;

    deleteSnippetTag(id, snippetUuid, currentaccountselected.id);
  };

  render() {
    const {
      currentaccountselected,
      currentuser,
      snippet,
      snippetAccessError,
      timeLineData,
      recordingTranscript,
      allTagsFromAccount,
      getSnippetTimeLine: getSnippetTimeLineAction,
      getSnippetRecordingTranscript: getSnippetRecordingTranscriptAction,
    } = this.props;

    const recording: IAnalyticsRecordings = {
      url: snippet?.status === "ready" ? snippet.url : "",
      screenshots: null
    }

    return snippet
      ? (
        snippet.status !== 'pending' ?
          <VideoPlayer
            sessionId={snippet.uuid}
            recordingSessionId={snippet.recording.session_id}
            accountId={currentaccountselected.id}
            currentuser={currentuser}
            recording={recording}
            recordingLoader={snippet.status === 'ready'}
            showComments={false}
            showSnippets={false}
            showAddSnippet={false}
            getTimeLine={getSnippetTimeLineAction}
            timeLineData={timeLineData}
            snippets={[]}
            snippetPostSuccess={""}
            showTranscription={currentaccountselected.transcription_enabled}
            recordingTranscript={currentaccountselected.transcription_enabled ? recordingTranscript : null}
            getRecordingTranscript={currentaccountselected.transcription_enabled ? getSnippetRecordingTranscriptAction : undefined}
            isSuperAdmin={currentuser.isSuperAdmin}
            accountTags={allTagsFromAccount}
            entityTags={snippet.tags}
            onAddTag={this.onAddTag}
            deleteTag={this.deleteTag}
          /> :
          <CreatingSnippetMessage />
      ) :
    snippetAccessError ?
      <AccessDenied title={ACCESS_DENIED.title} />
      : (
        <Row className="metting-main m-0 pt-4 pb-0">
          <Container className="text-center">
            <div className="loader-wrapper">
              <img src={loaderImg} alt="" />
            </div>
          </Container>
        </Row>
      );
  }
}


const mapStateToProps = (state: any) => {
  const { currentaccountselected, currentuser } = state.Profile;
  const {
    snippet,
    snippetAccessError,
    timeLineData,
    recordingTranscript,
    loading,
    allTagsFromAccount,
  } = state.SalesAnalytics;

  return {
    currentaccountselected,
    currentuser,
    snippet,
    snippetAccessError,
    timeLineData,
    recordingTranscript,
    loading,
    allTagsFromAccount,
  };
};

export default connect(mapStateToProps, {
  getSnippet,
  getSnippetTimeLine,
  getSnippetRecordingTranscript,
  getAllTagsByAccount,
  addSnippetTag,
  deleteSnippetTag,
})(SnippetPage);
