import { isEmpty } from 'lodash';

export function getPartnerFromLocal(): Partner|null {
  const partner = localStorage.getItem('partner');

  return isEmpty(partner) ? null : JSON.parse(partner!);
}

export function setPartnerToLocal(partner: Partner|null) {
  localStorage.setItem('partner', JSON.stringify(partner));
}

export interface PartnerData {
  id: string;
  icon?: string;
  name: string;
  logo_favicon?: string;
  logo_main?: string;
  theme?: string;
  created_at: string;
  integrations_blacklist: string[] | null;
}

export interface Partner {
  id: string;
  icon?: string;
  name: string;
  logoFavicon?: string;
  logoMain?: string;
  theme?: string;
  createdAt: string;
  integrationsBlacklist: string[] | null;
}

export const createPartnerFromData = (data: PartnerData): Partner =>
{
  return {
    id: data.id,
    icon: data.icon,
    name: data.name,
    logoFavicon: data.logo_favicon,
    logoMain: data.logo_main,
    createdAt: data.created_at,
    integrationsBlacklist: data.integrations_blacklist,
  };
}
