import React, { Component } from "react";

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";
import { GoogleLogin } from "react-google-login";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { History, Location } from "history";

// actions
import { loginUser, apiError } from "../../store/actions";
import { endpoints } from "../../utilities/endpoints";
import { urls } from "../../utilities/urls";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import loaderImg from "../../assets/images/loader.gif";

import googleImg from "../../assets/images/google-icon.svg";

import _ from "lodash";
import { setLocalStorage } from "../../services/utilities/utilservice";

const CLIENT_ID =
  "381619314610-fpo1puep9dt1ts3qonu6pu3pjjmsdtbo.apps.googleusercontent.com";

type loginProps = {
  error: Object | String;
  history: History;
  location: Location;
  loading: Boolean;
  apiError: (error: String) => void;
  loginUser: (values: Object, history: History, redirectURL: String, sessionId: null | String) => void;
};

type loginState = {
  errorMessage: String | Object;
};

class Login extends Component<loginProps, loginState> {
  popup: Window | null | undefined;
  constructor(props: loginProps) {
    super(props);
    this.state = {
      errorMessage: "",
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.login = this.login.bind(this);
    // this.handleLoginFailure = this.handleLoginFailure.bind(this);
  }

  componentDidUpdate(prevProps: loginProps) {
    const { error } = this.props;
    if (!_.isEmpty(error) && error !== prevProps.error) {
      if (typeof error === "object" && !_.isEmpty(error)) {
        this.setState({ errorMessage: Object.values(error)[0] });
      } else {
        this.setState({ errorMessage: error });
      }
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 3000);
      window.scrollTo(0, 0);
    }
  }

  // handleValidSubmit
  handleValidSubmit(event: Event, values: any) {
    let redirectURL: String = "";
    let sessionId  = null;
    const checkbox = document.getElementById('remember-me') as HTMLInputElement;

    setLocalStorage('remember-me', checkbox === null ? false : checkbox.checked);

    if (_.has(this.props.location, "search")) {
      sessionId = new URLSearchParams(this?.props?.location?.search).get("session_id");
      redirectURL = this.props.location.search.replace("?redirect_url=", "");
    }
    this.props.loginUser(values, this.props.history, redirectURL, sessionId);
  }

  login(response: any) {
    // console.log("access", response);
    if (response.accessToken) {
      // this.setState((state) => ({
      //   isLogined: true,
      //   accessToken: response.accessToken,
      // }));
    }
  }

  handleOpenPopup() {
    let url = `${urls.baseApiURL}${endpoints.loginWithGoogle}`;
    let session_id  = null;
    if (_.has(this.props.location, "search")) {
      session_id = new URLSearchParams(this?.props?.location?.search).get("session_id");
    }
    if (session_id) {
      console.log('Login=> Got session_id', session_id);
      url = url + `${"?session_id=" + session_id}`;
      setLocalStorage('isSessionIdExist', true);
    } else {
      setLocalStorage('isSessionIdExist', false);
      console.log('Login=> without session_id');
    }
    const width = 600,
      height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    return window.open(
      url,
      "_self",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, 
      scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
      height=${height}, top=${top}, left=${left}`
    );
  }

  handleLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.handleOpenPopup();
  };

  handleLoginFailure = () => {
    // alert('Failed to log in')
  };

  componentDidMount() {
    if (urls.loginUrl !== '/login') {
      window.location.assign(urls.loginUrl);
    }
    // if (this.popup) {
    //   this.popup.close();
    // }
    this.props.apiError("");
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue to Meetric.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {!_.isEmpty(this.state.errorMessage) ? (
                          <Alert color="danger">
                            {this.state.errorMessage}
                          </Alert>
                        ) : null}

                        <div className="form-group">
                          <AvField
                            name="email"
                            label="Username (email)"
                            value=""
                            className="form-control"
                            placeholder="Enter username (email)"
                            type="email"
                            required
                          />
                        </div>

                        <div className="form-group">
                          <AvField
                            name="password"
                            label="Password"
                            value=""
                            type="password"
                            required
                            placeholder="Enter Password"
                          />
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="remember-me"
                            defaultChecked={true}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="remember-me"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3">
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                          >
                            {this.props.loading ? (
                              <img
                                src={loaderImg}
                                alt="loader"
                                width="20"
                                height="20"
                              />
                            ) : (
                              "Log in"
                            )}
                          </button>
                        </div>

                        <div className="form-group mt-3 d-flex justify-content-center">
                          <button
                            className="btn btn-google mt-3 waves-effect waves-light"
                            type="button"
                            ref="googleLoginBtn"
                            onClick={(
                              event: React.MouseEvent<HTMLButtonElement>
                            ) => this.handleLogin(event)}
                          >
                            <img src={googleImg} alt="Google" />
                            <span>Log in with Google</span>
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock mr-1"></i> Forgot your
                            password?
                          </Link>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  {/* <p>
                    Don't have an account ?{" "}
                    <Link
                      to="register"
                      className="font-weight-medium text-primary"
                    >
                      {" "}
                      Signup now{" "}
                    </Link>{" "}
                  </p> */}
                  <p>© {new Date().getFullYear()} Meetric</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state: any) => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(
  connect(mapStatetoProps, { loginUser, apiError })(Login)
);
