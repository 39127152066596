import {
  GET_ASSOCIATED_USERS,
  GET_ASSOCIATED_USERS_SUCCESS,
  API_ERROR,
} from "./actionTypes";
import { IAssociatedUser } from "./interface";

export const getAssociatedUsers = (id: String) => {
  return {
    type: GET_ASSOCIATED_USERS,
    payload: { id },
  }
}

export const getAssociatedUsersSuccess = (users: IAssociatedUser[]) => {
  return {
    type: GET_ASSOCIATED_USERS_SUCCESS,
    payload: users,
  };
};

export const associatedUsersApiError = (error: String) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};