import React, { CSSProperties, useCallback, useState } from 'react';
import {
  Button,
  Col,
  Input,
  Row,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Select from 'react-select';

import EarthImg from "../../../assets/images/earth-americas-solid.svg";
import LinkImg from "../../../assets/images/link-solid.svg";
import LockImg from "../../../assets/images/lock.svg";

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = (data: any) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const OPTIONS = [{
  label: "All comments",
  value: "all",
  id: 1
}, {
  label: "None",
  value: "none",
  id: 4
}];

const GENERAL_ACCESS_OPTIONS = [{
  label: "All comments",
  value: "all",
  id: 1
}, {
  label: "Account comments",
  value: "account",
  id: 2
}, {
  label: "Own teams comments",
  value: "team",
  id: 3
}];

const PUBLIC_ACCESS_COMMENT_OPTIONS = [{
  label: "All comments",
  value: "all",
  id: 1
}, {
  label: "Account comments",
  value: "account",
  id: 2
}, {
  label: "Own teams comments",
  value: "team",
  id: 3
}];

const PUBLIC_ACCESS_ENABLE_OPTIONS = [{
  label: "Anyone with the link",
  value: "anyone",
  id: 1,
}, {
  label: "Restricted",
  value: "disabled",
  id: 2,
}];

type Props = {
  onClose: any;
  onSelectSharedTarget: any;
  onHandleGeneralAccess: any;
  groupedOptions: any
  shareToUser: { value: string, label: string; email: string, type: number, team_id: string, user_id: string, can_view: string, can_comment: boolean }[];
  generalAccess: any;
  removeShareUser: (label: string) => void;
  canViewHandler: any;
  canCommentHandler: any;
  handleShareRecording: any;
  showClipboardMessage: boolean;
  isEdit: boolean;
}

function ShareLinkModal({
  onClose,
  onSelectSharedTarget,
  onHandleGeneralAccess,
  groupedOptions,
  shareToUser,
  generalAccess,
  removeShareUser,
  canViewHandler,
  canCommentHandler,
  handleShareRecording,
  showClipboardMessage,
  isEdit
}: Props) {

  const [publicAccess, setPublicAccess] = useState('Restricted');
  const [enablePublicAccess, setEnablePublicAccess] = useState(false);

  function onChangePublicAccess(selectedValue: string) {
    setPublicAccess(selectedValue);

    if (selectedValue === 'anyone') {
      onHandleGeneralAccess('none');
      setEnablePublicAccess(true);
    } else {
      onHandleGeneralAccess('disabled');
      setEnablePublicAccess(false)
    }
  }

  const getValue = (value: string) => {
    return OPTIONS.filter(option => option.value === value);
  }

  const isAnyoneAccess = generalAccess === "anyone";

  return (
    <Modal isOpen={true} className="modal-share-snippet">
      <ModalHeader >Share recording</ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col lg='12'>
              <>
                <h5>Add access to shared link:</h5>
                <Select
                  value={null}
                  onChange={onSelectSharedTarget}
                  options={groupedOptions}
                  formatGroupLabel={formatGroupLabel}
                />
                {shareToUser.length > 0 && (
                  <div className='people-with-access-header'>
                    <div className='header-part-1'>People with access</div>
                    <div className='header-part-2'>View comments</div>
                    <div className='header-part-3'>Can comment</div>
                  </div>
                )}
                <div className='people-with-access'>
                  {
                    shareToUser.map((user, index) => (
                      <div className='user-with-access' key={index}>
                        <div className='user-picture-container'>
                          <div className='user-picture'><p>{user.label.charAt(0)}</p></div>
                        </div>
                        <div className='user-name-email'>
                          <div className='user-name'>
                            {user.label}
                          </div>
                          <div className='user-email'>
                            {user.email}
                          </div>
                        </div>
                        <Select
                          value={getValue(user.can_view)}
                          className='user-comments'
                          options={OPTIONS}
                          onChange={(option: any) => canViewHandler(user.label, option)
                          }

                        />
                        <div className='can-comment'>
                          {user.can_view === 'all' ? (
                            <Input type="checkbox"
                                   checked={user.can_comment}
                                   onChange={() => canCommentHandler(user.label)} />
                            ) : (
                            <Input type="checkbox"
                                   disabled={true}
                                   checked={!user.can_comment}
                                   onChange={() => canCommentHandler(user.label)} />
                          )}
                        </div>
                        <a
                          className='close-btn'
                          onClick={() => removeShareUser(user.label)}
                        >
                          <i className='fa fa-times'></i>
                        </a>
                      </div>
                    ))
                  }
                </div>
                <div className='people-with-access'>
                  <div className='user-with-access'>
                    <div className='general-access-text'>Public access</div>
                    <div className="link-icon user-picture-container">
                    </div>
                  </div>
                  <div className="user-with-access">
                    <div className="link-icon user-picture-container">
                      <div className='icon-container' style={{backgroundColor: isAnyoneAccess ? "#E6F4EA" : "#DFDFDF"}}>
                        {isAnyoneAccess ?
                          <img src={EarthImg} title="planet icons" className='earth-icon'></img> :
                          <img src={LockImg} title="lock" className='earth-icon'></img>
                        }
                      </div>
                    </div>
                    <div className='link-description user-name-email'>
                      <select
                        className='public-access-select'
                        onChange={(option: any) => onHandleGeneralAccess(option.target.value)}
                      >
                        {
                          PUBLIC_ACCESS_ENABLE_OPTIONS.map((option: any) => {
                            return <option key={option.id} value={option.value} selected={generalAccess === option.value}>{option.label}</option>
                          })
                        }
                      </select>
                      <div className='content-text'>{isAnyoneAccess ? "Anyone with the link" : "Only people with access"}</div>
                    </div>
                    {isAnyoneAccess ? (
                      <>
                        <Select
                          isDisabled={true}
                          value={getValue(generalAccess.can_view)}
                          className='user-comments transparent'
                          onChange={(option: any) => onHandleGeneralAccess(option.value)}
                          options={GENERAL_ACCESS_OPTIONS}
                        />
                        <div className='can-comment'>
                          <div className='can-comment'>
                            <Input
                              type="checkbox"
                              className='user-comments transparent'
                              disabled={true}
                              checked={generalAccess.can_comment}
                            />
                          </div>
                        </div></>) : (
                      <div className="public-access-anyone"></div>
                    )}
                  </div>

                </div>
              </>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className='botton-message-container'>
          <Button color="primary button-link" onClick={handleShareRecording}>
            <img src={LinkImg} className="link-icon" /> {"Copy Link"}
          </Button>{' '}
          {
            showClipboardMessage && <p className='clipboard-message'>Link copied to clipboard!</p>
          }
        </div>
        <Button color="btn btn-info" onClick={onClose}>
          Done
        </Button>

      </ModalFooter>
    </Modal>);
}

export { ShareLinkModal }
