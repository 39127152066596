import { IAdversusRule } from '../../pages/Settings/CallImport/utils/constants';
import {
  CREATE_ADVERSUS_RULE,
  CREATE_ADVERSUS_RULE_ERROR,
  CREATE_ADVERSUS_RULE_SUCCESS,
  CREATE_CALL_TOKEN_SETTINGS,
  CREATE_CALL_TOKEN_SETTINGS_ERROR,
  CREATE_CALL_TOKEN_SETTINGS_SUCCESS,
  DELETE_ADVERSUS_RULE,
  DELETE_ADVERSUS_RULE_ERROR,
  DELETE_ADVERSUS_RULE_SUCCESS,
  DELETE_CALL_TOKEN_SETTINGS,
  DELETE_CALL_TOKEN_SETTINGS_ERROR,
  DELETE_CALL_TOKEN_SETTINGS_SUCCESS,
  EDIT_ADVERSUS_RULE,
  EDIT_ADVERSUS_RULE_ERROR,
  EDIT_ADVERSUS_RULE_SUCCESS,
  GET_ADVERSUS_RULES,
  GET_ADVERSUS_RULES_ERROR,
  GET_ADVERSUS_RULES_SUCCESS,
  GET_CALL_TOKEN,
  GET_CALL_TOKEN_ERROR,
  GET_CALL_TOKEN_SETTINGS,
  GET_CALL_TOKEN_SETTINGS_ERROR,
  GET_CALL_TOKEN_SETTINGS_SUCCESS,
  GET_CALL_TOKEN_SUCCESS,
  UPDATE_CALL_TOKEN_SETTINGS,
  UPDATE_CALL_TOKEN_SETTINGS_ERROR,
  UPDATE_CALL_TOKEN_SETTINGS_SUCCESS,
} from './actionTypes';

import { CallImportAction, CallImportState } from './interface';

const initialState: CallImportState = {
  tokens: [],
  isTokensLoading: false,
  tokenError: '',

  tokenSettings: [],
  isTokenSettingsLoading: false,
  tokenSettingsError: '',

  createdTokenSettings: null,
  createTokenSettingError: '',
  isCreatingTokenLoader: false,

  deleteTokenSettingsError: '',
  isDeletingTokenLoader: false,

  updateTokenSettingsError: '',
  isUpdatingTokenLoader: false,

  adversusRules: [],
  adversusRulesLoading: false,
  adversusRulesError: '',
};

const CallImport = (state = initialState, action: CallImportAction) => {
  switch (action.type) {
    case GET_CALL_TOKEN:
      state = {
        ...state,
        tokens: [],
        isTokensLoading: true,
      }
      break;
    case GET_CALL_TOKEN_SUCCESS:
      state = {
        ...state,
        tokens: action.payload,
        isTokensLoading: false,
      }
      break;
    case GET_CALL_TOKEN_ERROR:
      state = {
        ...state,
        tokenError: action.payload,
        isTokensLoading: false,
      }
      break;
    case GET_CALL_TOKEN_SETTINGS:
      state = {
        ...state,
        tokenSettings: [],
        isTokenSettingsLoading: true,
      }
      break;
    case GET_CALL_TOKEN_SETTINGS_SUCCESS:
      state = {
        ...state,
        tokenSettings: action.payload,
        isTokenSettingsLoading: false,
      }
      break;
    case GET_CALL_TOKEN_SETTINGS_ERROR:
      state = {
        ...state,
        tokenSettingsError: action.payload,
        isTokenSettingsLoading: false,
      }
      break;
    case CREATE_CALL_TOKEN_SETTINGS:
      state = {
        ...state,
        isCreatingTokenLoader: true,
        createdTokenSettings: null,
      }
      break;
    case CREATE_CALL_TOKEN_SETTINGS_SUCCESS:
      state = {
        ...state,
        createdTokenSettings: action.payload,
        isCreatingTokenLoader: false,
      }
      break;
    case CREATE_CALL_TOKEN_SETTINGS_ERROR:
      state = {
        ...state,
        createTokenSettingError: action.payload,
        isCreatingTokenLoader: false,
      }
      break;
    case DELETE_CALL_TOKEN_SETTINGS:
      state = {
        ...state,
        isDeletingTokenLoader: true,
      }
      break;
    case DELETE_CALL_TOKEN_SETTINGS_SUCCESS:
      state = {
        ...state,
        isDeletingTokenLoader: false,
      }
      break;
    case DELETE_CALL_TOKEN_SETTINGS_ERROR:
      state = {
        ...state,
        deleteTokenSettingsError: action.payload,
        isDeletingTokenLoader: false,
      }
      break;
    case UPDATE_CALL_TOKEN_SETTINGS:
      state = {
        ...state,
        isUpdatingTokenLoader: true,
      }
      break;
    case UPDATE_CALL_TOKEN_SETTINGS_SUCCESS:
      state = {
        ...state,
        isUpdatingTokenLoader: false,
      }
      break;
    case UPDATE_CALL_TOKEN_SETTINGS_ERROR:
      state = {
        ...state,
        updateTokenSettingsError: action.payload,
        isUpdatingTokenLoader: false,
      };
      break;
    case GET_ADVERSUS_RULES:
      state = {
        ...state,
        adversusRules: [],
        adversusRulesLoading: true,
      };
      break;
    case GET_ADVERSUS_RULES_SUCCESS:
      state = {
        ...state,
        adversusRules: action.payload,
        adversusRulesLoading: false,
      };
      break;
    case GET_ADVERSUS_RULES_ERROR:
      state = {
        ...state,
        adversusRules: [],
        adversusRulesLoading: false,
        adversusRulesError: action.payload,
      };
      break;
    case CREATE_ADVERSUS_RULE:
      state = {
        ...state,
        adversusRulesLoading: true,
      };
      break;
    case CREATE_ADVERSUS_RULE_SUCCESS:
      state = {
        ...state,
        adversusRules: [...state.adversusRules, action.payload],
        adversusRulesLoading: false,
      };
      break;
    case CREATE_ADVERSUS_RULE_ERROR:
      state = {
        ...state,
        adversusRulesLoading: false,
        adversusRulesError: action.payload,
      };
      break;
    case EDIT_ADVERSUS_RULE:
      state = {
        ...state,
        adversusRulesLoading: true,
      };
      break;
    case EDIT_ADVERSUS_RULE_SUCCESS:
      return {
        ...state,
        adversusRules: state.adversusRules.map((rule: IAdversusRule) =>
          rule.id === action.payload.id ? action.payload : rule
        ),
        adversusRulesLoading: false,
      };
    case EDIT_ADVERSUS_RULE_ERROR:
      state = {
        ...state,
        adversusRulesLoading: false,
        adversusRulesError: action.payload,
      };
      break;
    case DELETE_ADVERSUS_RULE:
      state = {
        ...state,
        adversusRulesLoading: true,
      };
      break;
    case DELETE_ADVERSUS_RULE_SUCCESS:
      state = {
        ...state,
        adversusRules: state.adversusRules.filter(
          (adversusRule: IAdversusRule) => adversusRule.id !== action.payload
        ),
        adversusRulesLoading: false,
      };
      break;
    case DELETE_ADVERSUS_RULE_ERROR:
      state = {
        ...state,
        adversusRulesLoading: false,
        adversusRulesError: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default CallImport;
