import {
  GET_TEAMS,
  GET_TEAMS_ERROR,
  GET_TEAMS_SUCCESS,
  CREATE_NEW_TEAM,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_ERROR,
  API_ERROR,
  REMOVE_USER_FROM_TEAM_SUCCESS,
  REMOVE_USER_FROM_TEAM,
  ADD_MANAGER_TO_TEAM,
  ADD_MANAGER_TO_TEAM_SUCCESS,
  GET_MANAGED_TEAMS,
  GET_MANAGED_TEAMS_SUCCESS,
  EDIT_TEAM,
  EDIT_TEAM_SUCCESS, GET_FILTER_TEAMS, GET_FILTER_TEAMS_SUCCESS, GET_FILTER_TEAMS_ERROR,
} from "./actionTypes";
import { ITeams } from './interface';

export const getTeams = (id: String) => {
  return {
    type: GET_TEAMS,
    payload: { id },
  };
};

export const getTeamsSuccess = (teams: ITeams[]) => {
  return {
    type: GET_TEAMS_SUCCESS,
    payload: teams,
  };
};

export const getTeamsError = (error: String) => {
  return {
    type: GET_TEAMS_ERROR,
    payload: error,
  };
};

export const getFilterTeams = (id: String) => {
  return {
    type: GET_FILTER_TEAMS,
    payload: { id },
  };
};

export const getFilterTeamsSuccess = (teams: ITeams[]) => {
  return {
    type: GET_FILTER_TEAMS_SUCCESS,
    payload: teams,
  };
};

export const getFilterTeamsError = (error: String) => {
  return {
    type: GET_FILTER_TEAMS_ERROR,
    payload: error,
  };
};

export const createNewTeam = (team: Object) => {
  return {
    type: CREATE_NEW_TEAM,
    payload: team,
  };
};

export const createTeamSuccess = (mes: String) => {
  return {
    type: CREATE_TEAM_SUCCESS,
    payload: mes,
  };
};

export const createTeamError = (error: String) => {
  return {
    type: CREATE_TEAM_ERROR,
    payload: error,
  };
};

export const getManagedTeams = (id: String) => {
  return {
    type: GET_MANAGED_TEAMS,
    payload: { id },
  };
};

export const getManagedTeamsSuccess = (data: Object[]) => {
  return {
    type: GET_MANAGED_TEAMS_SUCCESS,
    payload: data,
  };
};

export const addManagerToTeam = (teamid: String, userid: String) => {
  return {
    type: ADD_MANAGER_TO_TEAM,
    payload: { teamid, userid },
  };
};

export const addManagerToTeamSuccess = (data: String) => {
  return {
    type: ADD_MANAGER_TO_TEAM_SUCCESS,
    payload: data,
  };
};

export const removeUserFromTeam = (teamid: String, userid: String) => {
  return {
    type: REMOVE_USER_FROM_TEAM,
    payload: { teamid, userid },
  };
};

export const removeUserFromTeamSuccess = (data: String) => {
  return {
    type: REMOVE_USER_FROM_TEAM_SUCCESS,
    payload: data,
  };
};

export const editTeam = (teamId: String, team: Object) => {
  return {
    type: EDIT_TEAM,
    payload: { teamId, team },
  };
};

export const editTeamSuccess = (mes: String) => {
  return {
    type: EDIT_TEAM_SUCCESS,
    payload: mes,
  };
};

export const teamsApiError = (error: String) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
