import {
  GET_DESTINY_TOKEN,
  GET_DESTINY_TOKEN_SUCCESS,
  GET_DESTINY_TOKEN_ERROR,
  CREATE_DESTINY_TOKEN,
  CREATE_DESTINY_TOKEN_SUCCESS,
  CREATE_DESTINY_TOKEN_ERROR,
  EDIT_DESTINY_TOKEN,
  EDIT_DESTINY_TOKEN_SUCCESS,
  EDIT_DESTINY_TOKEN_ERROR,
  DELETE_DESTINY_TOKEN,
  DELETE_DESTINY_TOKEN_SUCCESS,
  DELETE_DESTINY_TOKEN_ERROR,
} from './actionTypes';

import { IDestinyToken } from './interface';

export const getDestinyToken = () => ({
  type: GET_DESTINY_TOKEN,
  payload: null,
});

export const getDestinyTokenSuccess = (destinyToken: IDestinyToken|null) => ({
  type: GET_DESTINY_TOKEN_SUCCESS,
  payload: destinyToken,
});

export const getDestinyTokenError = (error: String | Object) => ({
  type: GET_DESTINY_TOKEN_ERROR,
  payload: error,
});

export const createDestinyToken = (destinyToken: IDestinyToken) => ({
  type: CREATE_DESTINY_TOKEN,
  payload: destinyToken,
});

export const createDestinyTokenSuccess = (destinyToken: IDestinyToken) => ({
  type: CREATE_DESTINY_TOKEN_SUCCESS,
  payload: destinyToken,
});

export const createDestinyTokenError = (error: String | Object) => ({
  type: CREATE_DESTINY_TOKEN_ERROR,
  payload: error,
});

export const editDestinyToken = (destinyToken: IDestinyToken) => ({
  type: EDIT_DESTINY_TOKEN,
  payload: destinyToken,
});

export const editDestinyTokenSuccess = (destinyToken: IDestinyToken) => ({
  type: EDIT_DESTINY_TOKEN_SUCCESS,
  payload: destinyToken,
});

export const editDestinyTokenError = (error: String | Object) => ({
  type: EDIT_DESTINY_TOKEN_ERROR,
  payload: error,
});

export const deleteDestinyToken = (id: string) => ({
  type: DELETE_DESTINY_TOKEN,
  payload: id,
});

export const deleteDestinyTokenSuccess = () => ({
  type: DELETE_DESTINY_TOKEN_SUCCESS,
  payload: null,
});

export const deleteDestinyTokenError = (error: String | Object) => ({
  type: DELETE_DESTINY_TOKEN_ERROR,
  payload: error,
});
