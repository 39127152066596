import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { has } from "lodash";

import {
  getShareLinkDetails as getShareLinkDetailsAction,
  getShareLinkDetailsError,
  getShareLinkDetailsSuccess,
  getUnshareLinkDetails as getUnshareLinkDetailsAction,
  getUnshareLinkDetailsError,
} from "./actions";
import {
  GET_SHARE_LINK_DETAILS,
  GET_UNSHARE_LINK_DETAILS,
} from "./actionTypes";
import {
  getShareLinkDetails,
  getUnshareLinkDetails,
} from "../../services/share-links";
import { ResponseGenerator } from "../../services/userservice";

function* getShareLinkDetailsHelper(
  { payload }: ReturnType<typeof getShareLinkDetailsAction>
) {
  const response: ResponseGenerator = yield call(
    getShareLinkDetails,
    payload.accountId,
    payload.globalFilterString
  );
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getShareLinkDetailsError(error));
    } else {
      yield put(getShareLinkDetailsError("An error has occurred!!"));
    }
  } else {
    yield put(getShareLinkDetailsSuccess(response.data));
  }
}

function* getUnshareLinkDetailsHelper({
  payload,
}: ReturnType<typeof getUnshareLinkDetailsAction>) {
  const response: ResponseGenerator = yield call(
    getUnshareLinkDetails,
    payload.accountId,
    payload.shareLinkId,
    payload.shareLinkType
  );

  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getUnshareLinkDetailsError(error));
    } else {
      yield put(getUnshareLinkDetailsError("An error has occurred!!"));
    }
  } else {
    yield put({
      type: GET_SHARE_LINK_DETAILS,
      payload: {
        accountId: payload.accountId,
        globalFilterString: ""
      },
    });
  }
}

export function* watchGetShareLinkDetailsHelper() {
  yield takeEvery(GET_SHARE_LINK_DETAILS, getShareLinkDetailsHelper);
}

export function* watchGetUnshareLinkDetailsHelper() {
  yield takeEvery(GET_UNSHARE_LINK_DETAILS, getUnshareLinkDetailsHelper);
}

function* ShareLinkSaga() {
  yield all([
    fork(watchGetShareLinkDetailsHelper),
    fork(watchGetUnshareLinkDetailsHelper),
  ]);
}

export default ShareLinkSaga;
