import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  CREATE_NEW_TEAM,
  GET_TEAMS,
  GET_MANAGED_TEAMS,
  EDIT_TEAM,
  ADD_MANAGER_TO_TEAM,
  REMOVE_USER_FROM_TEAM, GET_FILTER_TEAMS,
} from "./actionTypes";
import {
  getTeamsError,
  getTeamsSuccess,
  getTeams as getTeamsAction,
  createTeamSuccess,
  getManagedTeamsSuccess,
  addManagerToTeamSuccess,
  removeUserFromTeamSuccess,
  editTeamSuccess,
  teamsApiError, getFilterTeamsSuccess, getFilterTeamsError,
} from "./actions";

//services
import {
  getTeams as getTeamsService,
  getFilterTeams as getFilterTeamsService,
  getManagedTeams as getManagedTeamsService,
  addManagerToTeam as addManagerToTeamService,
  createNewTeam as createNewTeamService,
  removeUserFromTeam as removeUserFromTeamService,
  editTeam as editTeamService,
  ResponseGenerator,
} from "../../../services/userservice";
import _ from "lodash";

function* getTeams({ payload: { id } }: any) {
  const response: ResponseGenerator = yield  call(getTeamsService, id);
  if (_.has(response, "data")) {
    yield put(getTeamsSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getTeamsError(error));
    } else {
      yield put(getTeamsError("An Error has occured"));
    }
  }
}

function* getFilterTeams({ payload: { id } }: any) {
  const response: ResponseGenerator = yield  call(getFilterTeamsService, id);
  if (_.has(response, "data")) {
    yield put(getFilterTeamsSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getFilterTeamsError(error));
    } else {
      yield put(getFilterTeamsError("An Error has occured"));
    }
  }
}

function* createNewTeamHelper({ payload: data }: any) {
  const response: ResponseGenerator = yield  call(createNewTeamService, data);
  if (_.has(response, "data")) {
    yield put(getTeamsAction(data.account_id));
    yield put(createTeamSuccess("Team has been created successfully!"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getTeamsError(error));
    } else {
      yield put(getTeamsError("An Error has occured"));
    }
  }
}

function* getManagedTeams({ payload: { id } }: any) {
  const response: ResponseGenerator = yield  call(getManagedTeamsService, id);
  if (_.has(response, "data")) {
    yield put(getManagedTeamsSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(teamsApiError(error));
    } else {
      yield put(teamsApiError("An Error has occured"));
    }
  }
}

function* addManagerToTeam({ payload: { teamid, userid } }: any) {
  const response: ResponseGenerator = yield  call(addManagerToTeamService, teamid, userid);
  if (_.has(response, "data")) {
    yield put(addManagerToTeamSuccess("User added successfully to team"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(teamsApiError(error));
    } else {
      yield put(teamsApiError("An Error has occured"));
    }
  }
}

function* removeUserFromTeam({ payload: { teamid, userid } }: any) {
  const response: ResponseGenerator = yield  call(removeUserFromTeamService, teamid, userid);
  if (_.has(response, "data")) {
    yield put(removeUserFromTeamSuccess("User removed from team"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(teamsApiError(error));
    } else {
      yield put(teamsApiError("An Error has occured"));
    }
  }
}

function* editTeamHelper({ payload: { teamId, team } }: any) {
  const response: ResponseGenerator = yield  call(editTeamService, teamId, team);
  if (_.has(response, "data")) {
    yield put(editTeamSuccess("Team updated successfully"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(teamsApiError(error));
    } else {
      yield put(teamsApiError("An Error has occured"));
    }
  }
}

export function* watchGetTeams() {
  yield takeEvery(GET_TEAMS, getTeams);
}

export function* watchGetFilterTeams() {
  yield takeEvery(GET_FILTER_TEAMS, getFilterTeams);
}

export function* watchGetManagedTeams() {
  yield takeEvery(GET_MANAGED_TEAMS, getManagedTeams);
}

export function* watchAddManagerToTeam() {
  yield takeEvery(ADD_MANAGER_TO_TEAM, addManagerToTeam);
}

export function* watchRemoveUserFromTeam() {
  yield takeEvery(REMOVE_USER_FROM_TEAM, removeUserFromTeam);
}

export function* watchCreateNewTeam() {
  yield takeEvery(CREATE_NEW_TEAM, createNewTeamHelper);
}

export function* watchEditTeam() {
  yield takeEvery(EDIT_TEAM, editTeamHelper);
}

function* getTeamsSaga() {
  yield all([
    fork(watchGetTeams),
    fork(watchGetFilterTeams),
    fork(watchCreateNewTeam),
    fork(watchGetManagedTeams),
    fork(watchAddManagerToTeam),
    fork(watchRemoveUserFromTeam),
    fork(watchEditTeam),
  ]);
}

export default getTeamsSaga;
