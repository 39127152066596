import axios from "axios";
import { urls } from "../../../utilities/urls";
import { endpoints } from "../../../utilities/endpoints";
import { commonheaders } from "../../../utilities/apiConfig";
import { ICreateCallImportToken } from "../../../store/CallImports/interface";

export async function createTokenSettings(
  accountId: String,
  tokenSettings: ICreateCallImportToken,
) {
  try{
    return await axios.post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.callUserToken}`,
      {
        ...tokenSettings,
      },
      commonheaders
    );
  } catch (error: any)  {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function updateTokenSettings(
  accountId: String,
  tokenSettings: ICreateCallImportToken,
) {
  try{
    return await axios.put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.callUserToken}/${tokenSettings.id}`,
      {
        ...tokenSettings,
      },
      commonheaders
    );
  } catch (error: any)  {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function deleteS2AdversusTokenSettings(
  accountId: String,
  tokenSettingsId: String,
) {
  try{
    return await axios.delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.callUserToken}/${tokenSettingsId}`,
      commonheaders
    );
  } catch (error: any)  {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

