import React, { Component } from "react";
import { Link } from "react-router-dom";

import { TABLE_CALENDAR_HEADERS } from "./constants";
import TableLayout from "../../components/TableLayout";
import { limitStringCharacters } from "../../services/utilities/newUtilservice";
import {
  formatDateTime,
  formatTime,
} from "../../services/utilities/utilservice";
import { ISnippetDetails } from "../../store/snippets/interface";

interface SnippetsTableProps {
  snippets: ISnippetDetails[];
  loading: boolean;
  canModifySnippet: (commentOwnerId: number) => boolean;
  onDelete: (snippetId: string) => void;
  unshare: (snippetUuid: string) => void;
}

class SnippetsTable extends Component<SnippetsTableProps> {
  render() {
    const { snippets, loading, onDelete, canModifySnippet, unshare } = this.props;

    return (
      <div className="table-userlist light-fontweight-table snippet-table">
        <TableLayout tableHeaders={TABLE_CALENDAR_HEADERS} loading={loading}>
          {snippets &&
            snippets.map((snippet: ISnippetDetails, index: number) => {
              return (
                <tr key={snippet.uuid}>
                  <td>{index + 1}</td>
                  <td className="underline-on-hover">
                    <Link
                      to={{
                        pathname: "/snippet/" + snippet.uuid,
                        state: { snippet },
                      }}
                      target="_blank"
                    >
                      {snippet.name}
                    </Link>
                  </td>
                  <td className="td-wrap-text">
                    {limitStringCharacters(snippet.description, 40)}
                  </td>
                  <td>
                    <Link
                      className="btn btn-primary btn-sm btn-edit"
                      to={{
                        pathname: "/recording/" + snippet.sessionId,
                        state: { snippet },
                      }}
                      target="_blank"
                    >
                      View
                    </Link>
                  </td>
                  <td>{formatTime(snippet.startTime)}</td>
                  <td>{formatTime(snippet.endTime)}</td>
                  <td>{formatDateTime(snippet.createdAt)}</td>
                  <td>{snippet.snippetOwner}</td>
                  <td>{snippet.shared ? "Yes" : "No"}</td>
                  <td>
                    <Link
                      to={{
                        pathname: "/snippet/" + snippet.uuid,
                        state: { snippet },
                      }}
                      target="_blank"
                      className="btn btn-primary btn-sm btn-edit"
                    >
                      View
                    </Link>
                    {canModifySnippet(snippet.snippetOwnerId) && (
                      <button
                        className="btn btn-primary btn-sm btn-edit"
                        onClick={() => onDelete(snippet.uuid)}
                      >
                        Delete
                      </button>
                    )}
                    {canModifySnippet(snippet.snippetOwnerId) && snippet.shared &&(
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => unshare(snippet.uuid)}
                      >
                        Unshare
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
        </TableLayout>
      </div>
    );
  }
}

export default SnippetsTable;
