import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { has } from "lodash";

import {
  getSharedWithMe as getSharedWithMeDataAction,
  getSharedWithMeError,
  getSharedWithMeSuccess,
} from "./actions";
import { GET_SHARED_WITH_ME_DATA } from "./actionTypes";
import { ResponseGenerator } from "../../services/userservice";
import { getSharedWithMeData } from "../../services/shared-with-me";

function* getSharedWithMeDataHelper({
  payload,
}: ReturnType<typeof getSharedWithMeDataAction>) {
  const response: ResponseGenerator = yield call(
    getSharedWithMeData,
    payload.accountId,
    payload.globalFilterString
  );
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getSharedWithMeError(error));
    } else {
      yield put(getSharedWithMeError("An error has occurred!!"));
    }
  } else {
    yield put(getSharedWithMeSuccess(response.data));
  }
}

export function* watchGetSharedWithMeDataHelper() {
  yield takeEvery(GET_SHARED_WITH_ME_DATA, getSharedWithMeDataHelper);
}

function* SharedWithMeSaga() {
  yield all([fork(watchGetSharedWithMeDataHelper)]);
}

export default SharedWithMeSaga;
