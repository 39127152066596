import axios, { AxiosResponse } from 'axios';
import { ResponseGenerator } from '../../userservice';
import { urls } from '../../../utilities/urls';
import { endpoints } from '../../../utilities/endpoints';
import { commonheaders } from '../../../utilities/apiConfig';
import { IDestinyToken } from "../../../store/settings/Destiny/interface";

export async function getDestinyToken(): Promise<AxiosResponse<IDestinyToken|null>>
{
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}tokens/${endpoints.destinyToken}`,
      commonheaders
    );

    return response.data?.data ?? null;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function createDestinyToken(destinyToken: IDestinyToken): Promise<AxiosResponse<IDestinyToken>>
{
  try {
    const response: ResponseGenerator = await axios.post(
      `${urls.baseApiURL}tokens/${endpoints.destinyToken}`,
      destinyToken,
      commonheaders
    );

    return response.data?.data ?? null;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function editDestinyToken(destinyToken: IDestinyToken): Promise<AxiosResponse<IDestinyToken>>
{
  try {
    const response: ResponseGenerator = await axios.put(
      `${urls.baseApiURL}tokens/${endpoints.destinyToken}/${destinyToken.id}`,
      destinyToken,
      commonheaders
    );

    return response.data?.data ?? null;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function deleteDestinyToken(id: string): Promise<AxiosResponse<null>>
{
  try {
    const response: ResponseGenerator = await axios.delete(
      `${urls.baseApiURL}tokens/${endpoints.destinyToken}/${id}`,
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}
