import { CHANGE_PAGE } from './actionTypes';
import { PaginationActions, PaginationState } from './interface';

const initialState: PaginationState = {
  currentPage: 0,
  maxPage: 0,
  totalData: 0,
};

const Pagination = (state = initialState, action: PaginationActions) => {
  switch (action.type) {
    case CHANGE_PAGE:
      state = {
        currentPage: action.payload.currentPage,
        maxPage: action.payload.maxPage,
        totalData: action.payload.totalData,
      };
      break;
  }
  return state;
}

export default Pagination;
