import {
  GET_BRANDING,
  GET_BRANDING_ERROR,
  GET_BRANDING_SUCCESS,
  CREATE_BRANDING,
  CREATE_BRANDING_SUCCESS,
  EDIT_BRANDING,
  EDIT_BRANDING_SUCCESS,
  DELETE_BRANDING,
  DELETE_BRANDING_SUCCESS,
  GET_CREATED_BRANDING,
  API_ERROR,
} from "./actionTypes";
import { BrandingState, BrandingsAction } from "./interface";

const initialState: BrandingState = {
  success: "",
  error: [],
  loader: false,
  getloader: false,
  deleteLoader: false,
  createdResource: [],
  brands: null,
};

const Branding = (state = initialState, action: BrandingsAction) => {
  switch (action.type) {
    case GET_BRANDING:
      state = {
        ...state,
        success: "",
        error: [],
        getloader: true,
        brands: null,
      };
      break;
    case GET_BRANDING_SUCCESS:
      state = {
        ...state,
        brands: action.payload,
        getloader: false,
      };
      break;
    case GET_BRANDING_ERROR:
      state = { ...state, error: action.payload, loader: false, getloader: false };
      break;
    case GET_CREATED_BRANDING: 
      state = { ...state, createdResource: action.payload, loader: false}
    break;
    case CREATE_BRANDING:
      state = {
        ...state,
        success: "",
        error: [],
        createdResource: [],
        loader: true,
      };
      break;
    case CREATE_BRANDING_SUCCESS:
      state = { ...state, success: action.payload, loader: false };
      break;
    case EDIT_BRANDING:
      state = {
        ...state,
        success: "",
        error: [],
        createdResource: [],
        loader: true,
      };
      break;
    case EDIT_BRANDING_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loader: false,
      };
      break;
    case DELETE_BRANDING:
      state = {
        ...state,
        success: "",
        error: [],
        deleteLoader: true,
      };
      break;
    case DELETE_BRANDING_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        deleteLoader: false,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        deleteLoader: false,
        getloader: false,
        loader: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Branding;
