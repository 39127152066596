import {
  CLEAR_TEAMS,
  CLEAR_TEAMS_SUCCESS,
  CLEAR_TEAMS_ERROR,
} from './actionTypes';


export const clearTeams = (users: Object[]) => {
  return {
      type: CLEAR_TEAMS,
      payload: { userIds: users }
  }
}

export const clearTeamsSuccess = (msg: String) => {
  return {
      type: CLEAR_TEAMS_SUCCESS,
      payload: msg
  }
}

export const clearTeamsError = (error: String) => {
  return {
    type: CLEAR_TEAMS_ERROR,
    payload: error,
  };
};