import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import _ from "lodash";
import { CLEAR_TEAMS } from "./actionTypes";
import { clearTeamsSuccess, clearTeamsError } from "./actions";

//services
import { clearTeams as  clearTeamsServices, ResponseGenerator } from '../../services/newUserService';

//Dispatch redux action's are directly from here.
function* clearTeams({ payload }: any) {
  const response: ResponseGenerator = yield call(clearTeamsServices, payload);
  if (_.has(response, "data")) {
      yield put(clearTeamsSuccess(`Successfully cleared teams`));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(clearTeamsError(error));
    } else {
      yield put(clearTeamsError("An Error has occurred"));
    }
  }
}

export function* watchClearTeams() {
  yield takeEvery(CLEAR_TEAMS, clearTeams);
}

function* clearTeamsSaga() {
  yield all([fork(watchClearTeams)]);
}

export default clearTeamsSaga;
