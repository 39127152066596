import {
  GET_FONTS,
  GET_FONTS_SUCCESS,
  API_ERROR,
  UPLOAD_FONTS,
  UPLOAD_FONTS_SUCCESS,
  EDIT_FONTS,
  EDIT_FONTS_SUCCESS,
} from "./actionTypes";
import { FontState, FontAction } from './interface';

const initialState: FontState = {
  error: "",
  success: null,
  loader: false,
  uploadLoader: false,
  editLoader: false,
  FontsObj: null,
};

const fonts = (state = initialState, action: FontAction) => {
  switch (action.type) {
    case GET_FONTS:
      state = {
        ...state,
        error: "",
        FontsObj: null,
        loader: true,
      };
      break;
    case GET_FONTS_SUCCESS:
      state = {
        ...state,
        FontsObj: action.payload,
        loader: false,
      };
      break;
    case UPLOAD_FONTS:
      state = {
        ...state,
        error: "",
        success: null,
        uploadLoader: true,
      };
      break;
    case UPLOAD_FONTS_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        uploadLoader: false,
      };
      break;
    case EDIT_FONTS:
      state = {
        ...state,
        error: "",
        success: null,
        editLoader: true,
      };
      break;
    case EDIT_FONTS_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        editLoader: false,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loader: false,
        uploadLoader: false,
        editLoader: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default fonts;
