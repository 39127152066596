import React from "react";
import { Route, Redirect } from "react-router-dom";

import SetupUserProfileModal from "../pages/Settings/Modal/SetupUserProfileModal";
import { isUserLoggingIn } from '../services/auth/authservice';

type AppRoute = {
  [x: string]: any;
  component: any;
  layout: any;
  isAuthProtected: any;
  isCommon: any;
};

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isCommon,
  fromIframe,
  ...rest
}: AppRoute) => (
  <Route
    {...rest}
    render={props => {
      if (isCommon) {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      } else if (isAuthProtected) {
        if (isUserLoggingIn()) {
          return (
            <Layout fromIframe={fromIframe}>
              <Component {...props} fromIframe={fromIframe}/>
              <SetupUserProfileModal />
            </Layout>
          );
        } else {
          return (
            <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
          );
        }

      } else {
        if (isUserLoggingIn()) {
          return (
            <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
          );
        } else {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }
      }
    }}
  />
);
export default AppRoute;

