import React, { Component } from 'react';
import loaderImg from '../../assets/images/loader.gif'
import { connect } from 'react-redux';

interface AsyncLoaderProps {
  loader: Boolean;
}

class AsyncLoader extends Component<AsyncLoaderProps> {
    render() {
        const {loader} = this.props

        if(!loader) return null

        return (
            <React.Fragment>
            <div className="loader-container">
            <div className="loader">
              <img src={loaderImg} alt="loader"/>
            </div>
          </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => {
    
    const { loader } = state.Layout;
    return {loader};
} 

export default connect( mapStateToProps )( AsyncLoader )