import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

//Account Redux states
import { REGISTER_MULTIPLE_USERS } from './actionTypes';
import {registerMultipleUsersSuccessful, registerMultipleUsersFailed, registerMultipleUsers} from './actions';

//services
import {registerMultipleUsers as registerMultipleUsersService, ResponseGenerator} from '../../../services/userservice'
import _ from 'lodash';

function* registerUser({ payload }: any) {
  const response: ResponseGenerator = yield call(registerMultipleUsersService, payload);
  if (_.has(response, "data")) {
    yield put(registerMultipleUsersSuccessful("Users have been registered successfully !!"));
    payload.onSuccess(response.data);
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(registerMultipleUsersFailed(error));
    } else {
      yield put(registerMultipleUsersFailed("An Error has occurred"));
    }
  }
}

export function* watchMultipleUsersRegister() {
    yield takeEvery(REGISTER_MULTIPLE_USERS, registerUser);
}

function* registerMultipleSaga() {
    yield all([fork(watchMultipleUsersRegister)]);
}

export default registerMultipleSaga;