import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import Chat from "../Chat";
import { createChatSession } from "../../../store/actions";
import { Icurrentaccountselected } from "../../../store/interface";

interface ChatStarterProps {
  currentaccountselected: Icurrentaccountselected;
  isChatOpen: boolean;
  createChatSession: (message: string, accountId: String) => void;
  className?: string;
}

interface ChatStarterState {
  inputText: string;
}

class ChatStarter extends Component<ChatStarterProps, ChatStarterState> {
  constructor(props: ChatStarterProps) {
    super(props);
    this.state = {
      inputText: "",
    };
  }

  handleSendClick = () => {
    const { currentaccountselected, createChatSession } = this.props;
    const { inputText } = this.state;

    if (!isEmpty(inputText.trim())) {
      createChatSession(inputText.trim(), currentaccountselected.id);
      this.setState({ inputText: "" });
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ inputText: event.target.value });
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.handleSendClick();
    }
  };

  render() {
    const { inputText } = this.state;
    const { isChatOpen, className } = this.props;

    return (
      <>
        <div className={["input-container", className].join(" ")}>
          <input
            className="chatbot-input"
            placeholder="Start chat..."
            value={inputText}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
          />
          <button className="send-chat-button" onClick={this.handleSendClick}>
            <i className="fa fa-paper-plane" />
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentaccountselected } = state.Profile;
  const { isChatOpen } = state.Chat;

  return {
    currentaccountselected,
    isChatOpen,
  };
};

const mapDispatchToProps = {
  createChatSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatStarter);
