import {
  CREATE_LOG,
  CREATE_LOG_ERROR,
  CREATE_LOG_SUCCESS,
  CREATE_SNIPPET_SHARE_LINK,
  CREATE_SNIPPET_SHARE_LINK_ERROR,
  CREATE_SNIPPET_SHARE_LINK_SUCCESS,
  DELETE_RECORDING_SHARE_LINK,
  DELETE_RECORDING_SHARE_LINK_ERROR,
  DELETE_RECORDING_SHARE_LINK_SUCCESS,
  DELETE_SNIPPET_SHARE_LINK,
  DELETE_SNIPPET_SHARE_LINK_ERROR,
  DELETE_SNIPPET_SHARE_LINK_SUCCESS,
  DELETE_STATS_RECORDINGS,
  DELETE_STATS_RECORDINGS_ERROR,
  DELETE_STATS_RECORDINGS_SUCCESS,
  DELETE_TIME_LINE_COMMENT,
  DELETE_TIME_LINE_COMMENT_ERROR,
  DELETE_TIME_LINE_COMMENT_SUCCESS,
  GET_INTEGRATION_SETUP_FINISHED,
  GET_INTEGRATION_SETUP_FINISHED_ERROR,
  GET_INTEGRATION_SETUP_FINISHED_SUCCESS,
  GET_LOGS,
  GET_LOGS_ERROR,
  GET_LOGS_SUCCESS,
  GET_MEETING_AI_ANALYTICS,
  GET_MEETING_AI_ANALYTICS_ERROR,
  GET_MEETING_AI_ANALYTICS_SUCCESS,
  GET_RECORDING_SHARE_LINK_BY_LINK_HASH,
  GET_RECORDING_SHARE_LINK_BY_LINK_HASH_ERROR,
  GET_RECORDING_SHARE_LINK_BY_LINK_HASH_SUCCESS,
  GET_RECORDING_SHARE_LINKS_FOR_RECORDING,
  GET_RECORDING_SHARE_LINKS_FOR_RECORDING_ERROR,
  GET_RECORDING_SHARE_LINKS_FOR_RECORDING_SUCCESS,
  GET_RECORDING_SNIPPETS,
  GET_RECORDING_SNIPPETS_SUCCESS,
  GET_RECORDING_TRANSCRIPT,
  GET_RECORDING_TRANSCRIPT_ERROR,
  GET_RECORDING_TRANSCRIPT_SUCCESS,
  GET_SNIPPET,
  GET_SNIPPET_ERROR,
  GET_SNIPPET_RECORDING_TRANSCRIPT,
  GET_SNIPPET_RECORDING_TRANSCRIPT_ERROR,
  GET_SNIPPET_RECORDING_TRANSCRIPT_SUCCESS,
  GET_SNIPPET_SHARE_LINK_BY_LINK_HASH,
  GET_SNIPPET_SHARE_LINK_BY_LINK_HASH_ERROR,
  GET_SNIPPET_SHARE_LINK_BY_LINK_HASH_SUCCESS,
  GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET,
  GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET_ERROR,
  GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET_SUCCESS,
  GET_SNIPPET_SUCCESS,
  GET_SNIPPET_TIME_LINE,
  GET_SNIPPET_TIME_LINE_ERROR,
  GET_SNIPPET_TIME_LINE_SUCCESS,
  GET_STATS_COMPANIES,
  GET_STATS_COMPANIES_DAILY,
  GET_STATS_COMPANIES_DAILY_SUCCESS,
  GET_STATS_COMPANIES_ERROR,
  GET_STATS_COMPANIES_SUCCESS,
  GET_STATS_CONTACTS,
  GET_STATS_CONTACTS_DAILY,
  GET_STATS_CONTACTS_DAILY_SUCCESS,
  GET_STATS_CONTACTS_ERROR,
  GET_STATS_CONTACTS_SUCCESS,
  GET_STATS_DASHBOARD,
  GET_STATS_DASHBOARD_DAILY,
  GET_STATS_DASHBOARD_DAILY_SUCCESS,
  GET_STATS_DASHBOARD_ERROR,
  GET_STATS_DASHBOARD_SUCCESS,
  GET_STATS_INTERACTIONS,
  GET_STATS_INTERACTIONS_DAILY,
  GET_STATS_INTERACTIONS_DAILY_SUCCESS,
  GET_STATS_INTERACTIONS_ERROR,
  GET_STATS_INTERACTIONS_SUCCESS,
  GET_STATS_MEETINGS,
  GET_STATS_MEETINGS_COUNT,
  GET_STATS_MEETINGS_DAILY,
  GET_STATS_MEETINGS_DAILY_SUCCESS,
  GET_STATS_MEETINGS_ERROR,
  GET_STATS_MEETINGS_LIVE,
  GET_STATS_MEETINGS_LIVE_ERROR,
  GET_STATS_MEETINGS_LIVE_SUCCESS,
  GET_STATS_MEETINGS_SUCCESS,
  GET_STATS_MEMBERS,
  GET_STATS_MEMBERS_DAILY,
  GET_STATS_MEMBERS_DAILY_SUCCESS,
  GET_STATS_MEMBERS_ERROR,
  GET_STATS_MEMBERS_SUCCESS,
  GET_STATS_PARTICIPANTS,
  GET_STATS_PARTICIPANTS_DAILY,
  GET_STATS_PARTICIPANTS_DAILY_SUCCESS,
  GET_STATS_PARTICIPANTS_ERROR,
  GET_STATS_PARTICIPANTS_SUCCESS,
  GET_STATS_PRESENTATIONS,
  GET_STATS_PRESENTATIONS_DAILY,
  GET_STATS_PRESENTATIONS_DAILY_SUCCESS,
  GET_STATS_PRESENTATIONS_ERROR,
  GET_STATS_PRESENTATIONS_SUCCESS,
  GET_STATS_RECORDINGS,
  GET_STATS_RECORDINGS_ERROR,
  GET_STATS_RECORDINGS_SUCCESS,
  GET_STATS_ROOM_SESSIONS,
  GET_STATS_ROOM_SESSIONS_DAILY,
  GET_STATS_ROOM_SESSIONS_DAILY_SUCCESS,
  GET_STATS_ROOM_SESSIONS_ERROR,
  GET_STATS_ROOM_SESSIONS_SUCCESS,
  GET_STATS_SHARED_LINKS,
  GET_STATS_SHARED_LINKS_DAILY,
  GET_STATS_SHARED_LINKS_DAILY_SUCCESS,
  GET_STATS_SHARED_LINKS_ERROR,
  GET_STATS_SHARED_LINKS_SUCCESS,
  GET_STATS_SLIDES,
  GET_STATS_SLIDES_DAILY,
  GET_STATS_SLIDES_DAILY_SUCCESS,
  GET_STATS_SLIDES_ERROR,
  GET_STATS_SLIDES_SUCCESS,
  GET_STATS_TEAM,
  GET_STATS_TEAM_DAILY,
  GET_STATS_TEAM_DAILY_SUCCESS,
  GET_STATS_TEAM_ERROR,
  GET_STATS_TEAM_SUCCESS,
  GET_STATS_USER,
  GET_STATS_USER_DAILY,
  GET_STATS_USER_DAILY_SUCCESS,
  GET_STATS_USER_ERROR,
  GET_STATS_USER_SUCCESS,
  GET_STATS_VIEWS,
  GET_STATS_VIEWS_ERROR,
  GET_STATS_VIEWS_SUCCESS,
  GET_TIME_LINE,
  GET_TIME_LINE_COMMENTS,
  GET_TIME_LINE_COMMENTS_ERROR,
  GET_TIME_LINE_COMMENTS_SUCCESS,
  GET_TIME_LINE_ERROR,
  GET_TIME_LINE_SUCCESS,
  GET_USER_PROFILE_SETUP_FINISHED,
  GET_USER_PROFILE_SETUP_FINISHED_ERROR,
  GET_USER_PROFILE_SETUP_FINISHED_SUCCESS,
  POST_RECORDING_SNIPPET,
  POST_RECORDING_SNIPPET_SUCCESS,
  SEARCH_RECORDING_TRANSCRIPTS,
  SEARCH_RECORDING_TRANSCRIPTS_EMPTY,
  SEARCH_RECORDING_TRANSCRIPTS_ERROR,
  SEARCH_RECORDING_TRANSCRIPTS_SUCCESS,
  SHARE_RECORDING,
  SHARE_RECORDING_ERROR,
  SHARE_RECORDING_SUCCESS,
  UPDATE_RECORDING_SHARE_LINK,
  UPDATE_RECORDING_SHARE_LINK_ERROR,
  UPDATE_RECORDING_SHARE_LINK_SUCCESS,
  UPDATE_SNIPPET_ERROR,
  UPDATE_SNIPPET_SHARE_LINK,
  UPDATE_SNIPPET_SHARE_LINK_ERROR,
  UPDATE_SNIPPET_SHARE_LINK_SUCCESS,
  UPDATE_SNIPPET_SUCCESS,
  GET_AI_CATEGORIES_SUCCESS,
  GET_ALL_TAGS_BY_ACCOUNT,
  GET_ALL_TAGS_BY_ACCOUNT_SUCCESS,
  GET_SNIPPET_TAG_ERROR,
  ADD_SNIPPET_TAG_SUCCESS,
  DELETE_SNIPPET_TAG_SUCCESS,
  ADD_RECORDING_TAG_SUCCESS,
  DELETE_RECORDING_TAG_SUCCESS,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_ERROR,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_ERROR,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_ERROR,
  GET_TAG_ANALYTICS,
  GET_TAG_ANALYTICS_ERROR,
  GET_TAG_ANALYTICS_SUCCESS,
  GET_STATS_MEETINGS_COUNT_SUCCESS,
  CLEAR_RECORDINGS_DATA,
} from "./actionTypes";
import { AnalyticsAction, AnalyticsState } from "./interface";

const initialState: AnalyticsState = {
  error: "",
  loading: false,
  loadingMeetings: false,
  loadingTotal: false,
  loadingStats: false,
  sales: null,
  user: null,
  userDaily: null,
  team: null,
  teamDaily: null,
  meetings: null,
  meetingsCount: null,
  meetingsDaily: null,
  statsDaily: null,
  userError: "",
  success: "",
  teamError: "",
  meetingsError: "",
  meetingsLive: null,
  meetingsLiveError: "",
  interactions: null,
  interactionsDaily: null,
  interactionsError: "",
  participants: null,
  participantsDaily: null,
  participantsError: "",
  members: null,
  membersDaily: null,
  membersError: "",
  contacts: null,
  contactsDaily: null,
  contactsError: "",
  companies: null,
  companiesDaily: null,
  companiesError: "",
  views: null,
  viewsError: "",
  statsPresentation: null,
  statsPresentationDaily: null,
  statsPresentationError: "",
  slides: null,
  slidesDaily: null,
  slidesError: "",
  recordings: null,
  recordingsError: "",
  sharedLinks: null,
  sharedLinkDaily: null,
  sharedLinkError: "",
  roomSessions: null,
  roomSessionsDaily: null,
  roomSessionsError: "",
  dashboard: null,
  dashboardDaily: null,
  dashboardError: "",
  timeLineData: null,
  timeLineError: "",
  timeLineComments: null,
  timeLineCommentsError: "",
  meetingAIAnalytics: null,
  meetingAIAnalyticsError: "",
  recordingLoader: false,
  shareRecordingUrl: "",
  shareRecordingError: "",
  recordingShareLinks: null,
  recordingShareLinksError: "",
  recordingShareLink: null,
  recordingShareLinkError: "",
  deleteRecordingShareLinkSuccess: "",
  deleteRecordingShareLinkError: "",
  snippets: null,
  snippet: null,
  snippetAccessError: "",
  snippetPostSuccess: "",
  snippetPostError: "",
  snippetShareLinks: null,
  snippetShareLinksError: "",
  snippetShareLinkUrl: "",
  snippetShareLink: null,
  snippetShareLinkError: "",
  snippetUpdateSuccess: "",
  snippetUpdateError: "",
  deleteTimelineCommentSuccess: "",
  deleteTimelineCommentError: "",
  logs: [],
  userProfileSetupFinished: null,
  integrationSetupFinished: null,
  recordingTranscript: [],
  recordingTranscriptError: "",
  transcriptStats: null,
  transcriptStatsError: "",
  aiCategories: [],
  aiCategoriesError: {},
  allTagsFromAccount: [],
  snippetTagError: {},
  snippetSearchErrorMessage: "",
  isResultFromSearch: false,
  loadingTags: false,
  creatingTagError: '',
  deletingTagError: '',
  editTagError: '',
  tagAnalytics: [],
  loadingTagAnalytics: false,
  errorTagAnalytics: "",
};

const getAnalytics = (state = initialState, action: AnalyticsAction) => {
  switch (action.type) {
    case GET_STATS_DASHBOARD:
      state = {
        ...state,
        loading: true,
        dashboard: null,
        dashboardError: "",
      };
      break;
    case GET_STATS_DASHBOARD_SUCCESS:
      state = {
        ...state,
        loading: false,
        dashboard: action.payload,
      };
      break;
    case GET_STATS_DASHBOARD_DAILY:
      state = {
        ...state,
        loading: true,
        dashboardDaily: null,
        dashboardError: "",
      };
      break;
    case GET_STATS_DASHBOARD_DAILY_SUCCESS:
      state = {
        ...state,
        loading: false,
        dashboardDaily: action.payload,
      };
      break;
    case GET_STATS_DASHBOARD_ERROR:
      state = {
        ...state,
        dashboardError: action.payload,
        loading: false,
      };
      break;
    case GET_STATS_USER:
      state = {
        ...state,
        loading: true,
        loadingStats: true,
        user: null,
        userError: "",
        error: "",
      };
      break;
    case GET_STATS_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        loadingStats: false,
        user: action.payload,
      };
      break;
    case GET_STATS_USER_DAILY:
      state = {
        ...state,
        loading: true,
        userDaily: null,
        userError: "",
        error: "",
      };
      break;
    case GET_STATS_USER_DAILY_SUCCESS:
      state = {
        ...state,
        loading: false,
        userDaily: action.payload,
      };
      break;
    case GET_STATS_USER_ERROR:
      state = {
        ...state,
        userError: action.payload,
        loading: false,
      };
      break;
    case GET_STATS_TEAM:
      state = {
        ...state,
        loading: true,
        team: null,
        teamError: "",
      };
      break;
    case GET_STATS_TEAM_SUCCESS:
      state = {
        ...state,
        loading: false,
        team: action.payload,
      };
      break;
    case GET_STATS_TEAM_DAILY:
      state = {
        ...state,
        loading: true,
        teamDaily: null,
        teamError: "",
      };
      break;
    case GET_STATS_TEAM_DAILY_SUCCESS:
      state = {
        ...state,
        loading: false,
        teamDaily: action.payload,
      };
      break;
    case GET_STATS_TEAM_ERROR:
      state = {
        ...state,
        loading: false,
        teamError: action.payload,
      };
      break;
    case GET_STATS_MEETINGS:
      state = {
        ...state,
        loading: true,
        loadingMeetings: true,
        error: "",
      };
      break;
    case GET_STATS_MEETINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        loadingMeetings: false,
        meetings: action.payload,
      };
      break;
    case GET_STATS_MEETINGS_COUNT:
      state = {
        ...state,
        loadingTotal: true,
        // meetings: null,
        error: "",
      };
      break;
    case GET_STATS_MEETINGS_COUNT_SUCCESS:
      state = {
        ...state,
        loadingTotal: false,
        meetingsCount: action.payload,
      };
      break;
    case CLEAR_RECORDINGS_DATA:
      state = {
        ...state,
        meetings: [],
        // upcomingMeetings: []
      };
      break;
    case GET_STATS_MEETINGS_DAILY:
      state = {
        ...state,
        loading: true,
        meetingsDaily: null,
        error: "",
      };
      break;
    case GET_STATS_MEETINGS_DAILY_SUCCESS:
      state = {
        ...state,
        loading: false,
        meetingsDaily: action.payload,
      };
      break;
    case GET_STATS_MEETINGS_ERROR:
      state = {
        ...state,
        loading: false,
        meetingsError: action.payload,
      };
      break;
    case GET_STATS_MEETINGS_LIVE:
      state = {
        ...state,
        loading: true,
        meetingsLive: null,
        meetingsLiveError: "",
      };
      break;
    case GET_STATS_MEETINGS_LIVE_SUCCESS:
      state = {
        ...state,
        loading: false,
        meetingsLive: action.payload,
      };
      break;
    case GET_STATS_MEETINGS_LIVE_ERROR:
      state = {
        ...state,
        loading: false,
        meetingsLiveError: action.payload,
      };
      break;
    case GET_STATS_INTERACTIONS:
      state = {
        ...state,
        loading: true,
        interactions: null,
        interactionsError: "",
      };
      break;
    case GET_STATS_INTERACTIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        interactions: action.payload,
      };
      break;
    case GET_STATS_INTERACTIONS_DAILY:
      state = {
        ...state,
        loading: true,
        interactionsDaily: null,
        interactionsError: "",
      };
      break;
    case GET_STATS_INTERACTIONS_DAILY_SUCCESS:
      state = {
        ...state,
        loading: false,
        interactionsDaily: action.payload,
      };
      break;
    case GET_STATS_INTERACTIONS_ERROR:
      state = {
        ...state,
        loading: false,
        interactionsError: action.payload,
      };
      break;
    case GET_STATS_PARTICIPANTS:
      state = {
        ...state,
        loading: true,
        participants: null,
        participantsError: "",
      };
      break;
    case GET_STATS_PARTICIPANTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        participants: action.payload,
      };
      break;
    case GET_STATS_PARTICIPANTS_DAILY:
      state = {
        ...state,
        loading: true,
        participantsDaily: null,
        participantsError: "",
      };
      break;
    case GET_STATS_PARTICIPANTS_DAILY_SUCCESS:
      state = {
        ...state,
        loading: false,
        participantsDaily: action.payload,
      };
      break;
    case GET_STATS_PARTICIPANTS_ERROR:
      state = {
        ...state,
        loading: false,
        participantsError: action.payload,
      };
      break;
    case GET_STATS_MEMBERS:
      state = {
        ...state,
        loading: true,
        members: null,
        membersError: "",
      };
      break;
    case GET_STATS_MEMBERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        members: action.payload,
      };
      break;
    case GET_STATS_MEMBERS_DAILY:
      state = {
        ...state,
        loading: true,
        membersDaily: null,
        membersError: "",
      };
      break;
    case GET_STATS_MEMBERS_DAILY_SUCCESS:
      state = {
        ...state,
        loading: false,
        membersDaily: action.payload,
      };
      break;
    case GET_STATS_MEMBERS_ERROR:
      state = {
        ...state,
        loading: false,
        membersError: action.payload,
      };
      break;
    case GET_STATS_VIEWS:
      state = {
        ...state,
        loading: true,
        views: null,
        viewsError: "",
      };
      break;
    case GET_STATS_VIEWS_SUCCESS:
      state = {
        ...state,
        loading: false,
        views: action.payload,
      };
      break;
    case GET_STATS_VIEWS_ERROR:
      state = {
        ...state,
        loading: false,
        viewsError: action.payload,
      };
      break;
    case GET_STATS_SLIDES:
      state = {
        ...state,
        loading: true,
        slides: null,
        slidesError: "",
      };
      break;
    case GET_STATS_SLIDES_SUCCESS:
      state = {
        ...state,
        loading: false,
        slides: action.payload,
      };
      break;
    case GET_STATS_SLIDES_DAILY:
      state = {
        ...state,
        loading: true,
        slidesDaily: null,
        slidesError: "",
      };
      break;
    case GET_STATS_SLIDES_DAILY_SUCCESS:
      state = {
        ...state,
        loading: false,
        slidesDaily: action.payload,
      };
      break;
    case GET_STATS_SLIDES_ERROR:
      state = {
        ...state,
        loading: false,
        slidesError: action.payload,
      };
      break;
    case GET_STATS_RECORDINGS:
      state = {
        ...state,
        recordingLoader: true,
        recordings: null,
        recordingsError: "",
      };
      break;
    case GET_STATS_RECORDINGS_SUCCESS:
      state = {
        ...state,
        recordingLoader: false,
        recordings: action.payload,
      };
      break;
    case GET_STATS_RECORDINGS_ERROR:
      state = {
        ...state,
        recordingLoader: false,
        recordingsError: action.payload,
      };
      break;
    case DELETE_STATS_RECORDINGS:
      state = {
        ...state,
        loading: true,
        recordings: null,
        recordingsError: "",
      };
      break;
    case DELETE_STATS_RECORDINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case DELETE_STATS_RECORDINGS_ERROR:
      state = {
        ...state,
        loading: false,
        recordingsError: action.payload,
      };
      break;
    case GET_STATS_PRESENTATIONS:
      state = {
        ...state,
        loading: true,
        statsPresentation: null,
        statsPresentationError: "",
      };
      break;
    case GET_STATS_PRESENTATIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        statsPresentation: action.payload,
      };
      break;
    case GET_STATS_PRESENTATIONS_DAILY:
      state = {
        ...state,
        loading: true,
        statsPresentationDaily: null,
        statsPresentationError: "",
      };
      break;
    case GET_STATS_PRESENTATIONS_DAILY_SUCCESS:
      state = {
        ...state,
        loading: false,
        statsPresentationDaily: action.payload,
      };
      break;
    case GET_STATS_PRESENTATIONS_ERROR:
      state = {
        ...state,
        loading: false,
        statsPresentationError: action.payload,
      };
      break;
    case GET_STATS_SHARED_LINKS:
      state = {
        ...state,
        loading: true,
        sharedLinks: null,
        sharedLinkError: "",
      };
      break;
    case GET_STATS_SHARED_LINKS_SUCCESS:
      state = {
        ...state,
        loading: false,
        sharedLinks: action.payload,
      };
      break;
    case GET_STATS_SHARED_LINKS_DAILY:
      state = {
        ...state,
        loading: true,
        sharedLinkDaily: null,
        sharedLinkError: "",
      };
      break;
    case GET_STATS_SHARED_LINKS_DAILY_SUCCESS:
      state = {
        ...state,
        loading: false,
        sharedLinkDaily: action.payload,
      };
      break;
    case GET_STATS_SHARED_LINKS_ERROR:
      state = {
        ...state,
        loading: false,
        sharedLinkError: action.payload,
      };
      break;
    case GET_STATS_ROOM_SESSIONS:
      state = {
        ...state,
        loading: true,
        roomSessions: null,
        roomSessionsError: "",
      };
      break;
    case GET_STATS_ROOM_SESSIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        roomSessions: action.payload,
      };
      break;
    case GET_STATS_ROOM_SESSIONS_DAILY:
      state = {
        ...state,
        loading: true,
        roomSessionsDaily: null,
        roomSessionsError: "",
      };
      break;
    case GET_STATS_ROOM_SESSIONS_DAILY_SUCCESS:
      state = {
        ...state,
        loading: false,
        roomSessionsDaily: action.payload,
      };
      break;
    case GET_STATS_ROOM_SESSIONS_ERROR:
      state = {
        ...state,
        loading: false,
        roomSessionsError: action.payload,
      };
      break;
    case GET_STATS_CONTACTS:
      state = {
        ...state,
        loading: true,
        contacts: null,
        contactsError: "",
      };
      break;
    case GET_STATS_CONTACTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        contacts: action.payload,
      };
      break;
    case GET_STATS_CONTACTS_DAILY:
      state = {
        ...state,
        loading: true,
        contactsDaily: null,
        contactsError: "",
      };
      break;
    case GET_STATS_CONTACTS_DAILY_SUCCESS:
      state = {
        ...state,
        loading: false,
        contactsDaily: action.payload,
      };
      break;
    case GET_STATS_CONTACTS_ERROR:
      state = {
        ...state,
        loading: false,
        contactsError: action.payload,
      };
      break;
    case GET_STATS_COMPANIES:
      state = {
        ...state,
        loading: true,
        companies: null,
        companiesError: "",
      };
      break;
    case GET_STATS_COMPANIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        companies: action.payload,
      };
      break;
    case GET_STATS_COMPANIES_DAILY:
      state = {
        ...state,
        loading: true,
        companiesDaily: null,
        companiesError: "",
      };
      break;
    case GET_STATS_COMPANIES_DAILY_SUCCESS:
      state = {
        ...state,
        loading: false,
        companiesDaily: null,
      };
      break;
    case GET_STATS_COMPANIES_ERROR:
      state = {
        ...state,
        loading: false,
        companiesError: action.payload,
      };
      break;
    case GET_TIME_LINE:
      state = {
        ...state,
        loading: true,
        timeLineData: null,
        timeLineError: "",
      };
      break;
    case GET_TIME_LINE_SUCCESS:
      state = {
        ...state,
        loading: false,
        timeLineData: action.payload,
      };
      break;
    case GET_TIME_LINE_ERROR:
      state = {
        ...state,
        loading: false,
        timeLineError: action.payload,
      };
      break;
    case GET_TIME_LINE_COMMENTS:
      state = {
        ...state,
        loading: true,
        timeLineComments: null,
        timeLineCommentsError: "",
      };
      break;
    case GET_TIME_LINE_COMMENTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        timeLineComments: action.payload,
      };
      break;
    case GET_TIME_LINE_COMMENTS_ERROR:
      state = {
        ...state,
        loading: false,
        timeLineCommentsError: action.payload,
      };
      break;
    case DELETE_TIME_LINE_COMMENT:
      state = {
        ...state,
        loading: true,
        deleteTimelineCommentSuccess: "",
        deleteTimelineCommentError: "",
      };
      break;
    case DELETE_TIME_LINE_COMMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        deleteTimelineCommentSuccess: action.payload,
      };
      break;
    case DELETE_TIME_LINE_COMMENT_ERROR:
      state = {
        ...state,
        loading: false,
        deleteTimelineCommentError: action.payload,
      };
      break;
    case GET_SNIPPET_TIME_LINE:
      state = {
        ...state,
        loading: true,
        timeLineData: null,
        timeLineError: "",
      };
      break;
    case GET_SNIPPET_TIME_LINE_SUCCESS:
      state = {
        ...state,
        loading: false,
        timeLineData: action.payload,
      };
      break;
    case GET_SNIPPET_TIME_LINE_ERROR:
      state = {
        ...state,
        loading: false,
        timeLineError: action.payload,
      };
      break;
    case GET_MEETING_AI_ANALYTICS:
      state = {
        ...state,
        loading: true,
        meetingAIAnalytics: null,
        meetingAIAnalyticsError: "",
      };
      break;
    case GET_MEETING_AI_ANALYTICS_SUCCESS:
      state = {
        ...state,
        loading: false,
        meetingAIAnalytics: action.payload,
      };
      break;
    case GET_MEETING_AI_ANALYTICS_ERROR:
      state = {
        ...state,
        loading: false,
        meetingAIAnalyticsError: action.payload,
      };
      break;
    case SHARE_RECORDING:
      state = {
        ...state,
        loading: true,
        shareRecordingUrl: "",
        shareRecordingError: "",
      };
      break;
    case SHARE_RECORDING_SUCCESS:
      state = {
        ...state,
        loading: false,
        shareRecordingUrl: action.payload,
      };
      break;
    case SHARE_RECORDING_ERROR:
      state = {
        ...state,
        loading: false,
        shareRecordingError: action.payload,
      };
      break;
    case GET_RECORDING_SHARE_LINKS_FOR_RECORDING:
      state = {
        ...state,
        loading: true,
        recordingShareLinks: null,
        recordingShareLinksError: "",
      };
      break;
    case GET_RECORDING_SHARE_LINKS_FOR_RECORDING_SUCCESS:
      state = {
        ...state,
        loading: false,
        recordingShareLinks: action.payload,
      };
      break;
    case GET_RECORDING_SHARE_LINKS_FOR_RECORDING_ERROR:
      state = {
        ...state,
        loading: false,
        recordingShareLinksError: action.payload,
      };
      break;
    case GET_RECORDING_SHARE_LINK_BY_LINK_HASH:
      state = {
        ...state,
        loading: true,
        recordingShareLink: null,
        recordingShareLinkError: "",
      };
      break;
    case GET_RECORDING_SHARE_LINK_BY_LINK_HASH_SUCCESS:
      state = {
        ...state,
        loading: false,
        recordingShareLink: action.payload,
      };
      break;
    case GET_RECORDING_SHARE_LINK_BY_LINK_HASH_ERROR:
      state = {
        ...state,
        loading: false,
        recordingShareLink: null,
        recordingShareLinksError: action.payload,
      };
      break;
    case UPDATE_RECORDING_SHARE_LINK:
      state = {
        ...state,
        loading: true,
        shareRecordingUrl: "",
        shareRecordingError: "",
      };
      break;
    case UPDATE_RECORDING_SHARE_LINK_SUCCESS:
      state = {
        ...state,
        loading: false,
        shareRecordingUrl: action.payload,
      };
      break;
    case UPDATE_RECORDING_SHARE_LINK_ERROR:
      state = {
        ...state,
        loading: false,
        shareRecordingError: action.payload,
      };
      break;
    case DELETE_RECORDING_SHARE_LINK:
      state = {
        ...state,
        loading: true,
        deleteRecordingShareLinkSuccess: "",
        deleteRecordingShareLinkError: "",
      };
      break;
    case DELETE_RECORDING_SHARE_LINK_SUCCESS:
      state = {
        ...state,
        loading: false,
        deleteRecordingShareLinkSuccess: action.payload,
      };
      break;
    case DELETE_RECORDING_SHARE_LINK_ERROR:
      state = {
        ...state,
        loading: false,
        deleteRecordingShareLinkError: action.payload,
      };
      break;
    case GET_RECORDING_SNIPPETS:
      state = {
        ...state,
        loading: true,
        snippets: [],
      };
      break;
    case GET_RECORDING_SNIPPETS_SUCCESS:
      state = {
        ...state,
        loading: false,
        snippets: action.payload,
      };
      break;
    case GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET:
      state = {
        ...state,
        loading: true,
        snippetShareLinks: null,
        snippetShareLinksError: "",
      };
      break;
    case GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET_SUCCESS:
      state = {
        ...state,
        loading: false,
        snippetShareLinks: action.payload,
      };
      break;
    case GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET_ERROR:
      state = {
        ...state,
        loading: false,
        snippetShareLinksError: action.payload,
      };
      break;
    case GET_SNIPPET_SHARE_LINK_BY_LINK_HASH:
      state = {
        ...state,
        loading: true,
        snippetShareLink: null,
        snippetShareLinkError: "",
      };
      break;
    case GET_SNIPPET_SHARE_LINK_BY_LINK_HASH_SUCCESS:
      state = {
        ...state,
        loading: false,
        snippetShareLink: action.payload,
      };
      break;
    case GET_SNIPPET_SHARE_LINK_BY_LINK_HASH_ERROR:
      state = {
        ...state,
        loading: false,
        snippetShareLink: null,
        snippetShareLinkError: action.payload,
      };
      break;
    case CREATE_SNIPPET_SHARE_LINK:
      state = {
        ...state,
        loading: true,
        snippetShareLinkUrl: "",
        snippetShareLinkError: "",
      };
      break;
    case CREATE_SNIPPET_SHARE_LINK_SUCCESS:
      state = {
        ...state,
        loading: false,
        snippetShareLinkUrl: action.payload,
      };
      break;
    case CREATE_SNIPPET_SHARE_LINK_ERROR:
      state = {
        ...state,
        loading: false,
        snippetShareLinkError: action.payload,
      };
      break;
    case UPDATE_SNIPPET_SHARE_LINK:
      state = {
        ...state,
        loading: true,
        snippetShareLinkUrl: "",
        snippetShareLinkError: "",
      };
      break;
    case UPDATE_SNIPPET_SHARE_LINK_SUCCESS:
      state = {
        ...state,
        loading: false,
        snippetShareLinkUrl: action.payload,
      };
      break;
    case UPDATE_SNIPPET_SHARE_LINK_ERROR:
      state = {
        ...state,
        loading: false,
        snippetShareLinkError: action.payload,
      };
      break;
    case UPDATE_SNIPPET_ERROR:
      state = {
        ...state,
        loading: false,
        snippetUpdateError: action.payload,
      };
      break;
    case UPDATE_SNIPPET_SUCCESS:
      state = {
        ...state,
        loading: false,
        snippetUpdateSuccess: action.payload,
      };
      break;
    case DELETE_SNIPPET_SHARE_LINK:
      state = {
        ...state,
        loading: true,
        snippetShareLinkError: "",
      };
      break;
    case DELETE_SNIPPET_SHARE_LINK_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case DELETE_SNIPPET_SHARE_LINK_ERROR:
      state = {
        ...state,
        loading: false,
        snippetShareLinkError: action.payload,
      };
      break;

    case POST_RECORDING_SNIPPET:
      state = {
        ...state,
        loading: true,
        snippetPostSuccess: "",
      };
      break;
    case POST_RECORDING_SNIPPET_SUCCESS:
      state = {
        ...state,
        loading: false,
        snippetPostSuccess: action.payload,
      };
      break;
    case GET_SNIPPET:
      state = {
        ...state,
        loading: true,
        snippet: null,
        snippetAccessError: "",
      };
      break;
    case GET_SNIPPET_SUCCESS:
      state = {
        ...state,
        loading: false,
        snippet: action.payload,
      };
      break;
    case GET_SNIPPET_ERROR:
      state = {
        ...state,
        loading: false,
        snippetAccessError: action.payload,
      };
      break;
    case GET_LOGS:
      state = {
        ...state,
        loading: true,
        logs: null,
      };
      break;
    case GET_LOGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        logs: action.payload,
      };
      break;
    case GET_LOGS_ERROR:
      state = {
        ...state,
        loading: false,
        logs: [],
      };
      break;
    case GET_USER_PROFILE_SETUP_FINISHED:
      state = {
        ...state,
        loading: true,
        userProfileSetupFinished: null,
      };
      break;
    case GET_USER_PROFILE_SETUP_FINISHED_SUCCESS:
      state = {
        ...state,
        loading: false,
        userProfileSetupFinished: action.payload,
      };
      break;
    case GET_USER_PROFILE_SETUP_FINISHED_ERROR:
      state = {
        ...state,
        loading: false,
        userProfileSetupFinished: null,
      };
      break;
    case GET_INTEGRATION_SETUP_FINISHED:
      state = {
        ...state,
        loading: true,
        integrationSetupFinished: null,
      };
      break;
    case GET_INTEGRATION_SETUP_FINISHED_SUCCESS:
      state = {
        ...state,
        loading: false,
        integrationSetupFinished: action.payload,
      };
      break;
    case GET_INTEGRATION_SETUP_FINISHED_ERROR:
      state = {
        ...state,
        loading: false,
        integrationSetupFinished: null,
      };
      break;
    case CREATE_LOG:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_LOG_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_LOG_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_RECORDING_TRANSCRIPT:
      state = {
        ...state,
        loading: true,
        recordingTranscript: null,
        recordingTranscriptError: "",
      };
      break;
    case GET_RECORDING_TRANSCRIPT_SUCCESS:
      state = {
        ...state,
        loading: false,
        recordingTranscript: action.payload,
      };
      break;
    case GET_RECORDING_TRANSCRIPT_ERROR:
      state = {
        ...state,
        loading: false,
        recordingTranscriptError: action.payload,
      };
      break;
    case GET_SNIPPET_RECORDING_TRANSCRIPT:
      state = {
        ...state,
        loading: true,
        recordingTranscript: null,
        recordingTranscriptError: "",
      };
      break;
    case GET_SNIPPET_RECORDING_TRANSCRIPT_SUCCESS:
      state = {
        ...state,
        loading: false,
        recordingTranscript: action.payload,
      };
      break;
    case GET_SNIPPET_RECORDING_TRANSCRIPT_ERROR:
      state = {
        ...state,
        loading: false,
        recordingTranscriptError: action.payload,
      };
      break;
    case SEARCH_RECORDING_TRANSCRIPTS:
      state = {
        ...state,
        loading: true,
        transcriptStatsError: "",
      };
      break;
    case SEARCH_RECORDING_TRANSCRIPTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        transcriptStats: action.payload,
      };
      break;
    case SEARCH_RECORDING_TRANSCRIPTS_ERROR:
      state = {
        ...state,
        loading: false,
        transcriptStatsError: action.payload,
      };
      break;
    case SEARCH_RECORDING_TRANSCRIPTS_EMPTY:
      state = {
        ...state,
        loading: false,
        transcriptStats: null,
      };
      break;
    case GET_AI_CATEGORIES_SUCCESS:
      state = {
        ...state,
        aiCategories: action.payload,
      };
      break;
    case GET_ALL_TAGS_BY_ACCOUNT:
      state = {
        ...state,
        loadingTags: true,
      };
      break;
    case GET_ALL_TAGS_BY_ACCOUNT_SUCCESS:
      state = {
        ...state,
        loadingTags: false,
        allTagsFromAccount: action.payload,
      };
      break;
    case GET_SNIPPET_TAG_ERROR:
      state = {
        ...state,
        loading: false,
        snippetTagError: action.payload,
      };
      break;
    case ADD_SNIPPET_TAG_SUCCESS:
      const updatedTags = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      state = {
        ...state,
        snippet: state.snippet
          ? {
              ...state.snippet,
              tags: [...(state.snippet.tags || []), ...updatedTags],
            }
          : null,
      };
      break;
    case DELETE_SNIPPET_TAG_SUCCESS:
      state = {
        ...state,
        snippet: state.snippet
          ? {
              ...state.snippet,
              tags: state.snippet.tags.filter(
                (tag) => tag.id !== action.payload
              ),
            }
          : null,
      };
      break;
    case ADD_RECORDING_TAG_SUCCESS:
      state = {
        ...state,
        recordings: state.recordings
          ? {
              ...state.recordings,
              tags: [...(state.recordings.tags || []), action.payload],
            }
          : null,
      };
      break;
    case DELETE_RECORDING_TAG_SUCCESS:
      state = {
        ...state,
        recordings: state.recordings
          ? {
              ...state.recordings,
              tags: (state.recordings.tags || []).filter(
                (tag) => tag.id !== action.payload
              ),
            }
          : null,
      };
      break;
    case CREATE_TAG_SUCCESS:
      state = {
        ...state,
        allTagsFromAccount: [...state.allTagsFromAccount, action.payload],
      };
      break;
    case CREATE_TAG_ERROR:
      state = {
        ...state,
        creatingTagError: action.payload,
      };
      break;
    case DELETE_TAG_SUCCESS:
      state = {
        ...state,
        allTagsFromAccount: [
          ...state.allTagsFromAccount.filter(
            (tag) => tag.id !== action.payload
          ),
        ],
      };
      break;
    case DELETE_TAG_ERROR:
      state = {
        ...state,
        deletingTagError: action.payload,
      };
      break;
    case EDIT_TAG_SUCCESS:
      state = {
        ...state,
        allTagsFromAccount: state.allTagsFromAccount.map((tag) =>
          tag.id === action.payload.id
            ? { ...tag, name: action.payload.name }
            : tag
        ),
      };
      break;
    case EDIT_TAG_ERROR:
      state = {
        ...state,
        editTagError: action.payload,
      };
      break;
    case GET_TAG_ANALYTICS:
      state = {
        ...state,
        tagAnalytics: [],
        loadingTagAnalytics: true,
      }
      break;
    case GET_TAG_ANALYTICS_SUCCESS:
      state = {
        ...state,
        isResultFromSearch: true,
        tagAnalytics: action.payload,
        loadingTagAnalytics: false,
      }
      break;
    case GET_TAG_ANALYTICS_ERROR:
      state = {
        ...state,
        loadingTagAnalytics: false,
        errorTagAnalytics: action.payload
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default getAnalytics;
