import axios from 'axios';
import { endpoints } from '../utilities/endpoints'
import { urls } from '../utilities/urls'
import { commonheaders } from "../utilities/apiConfig";
import { IUser } from '../store/interface';

export interface ResponseGenerator{
    config?:any,
    data?:any,
    headers?:any,
    request?:any,
    status?:number,
    error?: any,
    errors?: any,
    statusText?:string
}
interface getMeetingpayload {
    id: String;
    data: {
      template: false;
      query?: String;
    };
  }

/** Meetings */
export function* getMeetings(payload: getMeetingpayload) {
    let value;
    if (payload.data.query) {
        value = `template=${payload.data.template}&query=${payload.data.query}`;
    } else {
        value = `template=${payload.data.template}`;
    }
    const abc: ResponseGenerator = yield axios.get(`${urls.baseApiURL}${endpoints.getUsers}/${payload.id}/${endpoints.meetings}?${value}`)
        .then((response) => { return response.data })
        .catch((error) => {
            if (error.response) {
                return error.response.data;
            }
            return error;
        });
    return abc;
}

/**
 * Get current user details
 */
 export function* getCurrentUserDetails() {
    const abc: ResponseGenerator = yield axios.get(`${urls.baseApiURL}${endpoints.verifyUser}`)
        .then((response) => { return response.data })
        .catch((error) => {
            if (error.response) {
                return error.response.data;
            }
            return error;
        });
        return abc;
}

 export function filterUsersByNameOrEmail(users: IUser[], text: String): IUser[] {
    return users.filter((user: IUser) => { 
        const userFullname = `${user.first_name} ${user.last_name}`;
        return userFullname.toLowerCase().includes(text.toLowerCase()) || 
               user.email.toLowerCase().includes(text.toLowerCase());
      });
}

export function* clearTeams(payload: Object) {
    const clearTeamsApi: ResponseGenerator = yield axios
      .post(
          `${urls.baseApiURL}${endpoints.getUsers}/teams/clear`,
          JSON.stringify(payload),
          commonheaders
        )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  
      return clearTeamsApi;
  }
