import {
  EDIT_PERSONAL_PROFILE,
  PERSONAL_PROFILE_ERROR,
  PERSONAL_PROFILE_SUCCESS,
  CREATE_USER_PROFILE,
  EDIT_USER_PROFILE,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_ERROR,
  EDIT_PERSONAL_PROFILE_NAME,
  EDIT_PERSONAL_PROFILE_PIC,
  ADD_USER_TO_TEAM,
  ADD_USER_TO_TEAM_SUCCESS,
  ADD_USER_TO_TEAM_ERROR,
  DELETE_USER_PROFILE,
  DELETE_USER_PROFILE_SUCCESS,
  DELETE_USER_PROFILE_ERROR,
  UPLOAD_DOCUMENT,
  CHANGE_PROFILE_PASSWORD,
  CHANGE_PROFILE_PASSWORD_SUCCESS,
  CHANGE_PROFILE_PASSWORD_ERROR,
  PROFILE_SETUP_COMPLETED,
} from "./actionTypes";
import defaultimg from "../../../assets/images/users/avatar-5.jpg";
import { EditPersonalState, EditPersonalAction } from "./interface";

const initialState: EditPersonalState = {
  error: [],
  success: "",
  name: "",
  pic: defaultimg,
  loader: false,
  deleteLoader: false,
  uploadedFile: null,
  userToTeamError: "",
  userToTeamSuccess: "",
  createSuccess: "",
  profileSetupCompleted: false,
};

const personalprofile = (state = initialState, action: EditPersonalAction) => {
  switch (action.type) {
    case EDIT_PERSONAL_PROFILE_PIC:
      state = {
        ...state,
        uploadedFile: null,
        loader: true,
        success: "",
        error: [],
      };
      break;
    case UPLOAD_DOCUMENT:
      state = { ...state, loader: false, uploadedFile: action.payload };
      break;
    case EDIT_PERSONAL_PROFILE_NAME:
      state = { ...state, loader: false, name: action.payload };
      break;
    case EDIT_PERSONAL_PROFILE:
      state = { ...state, loader: true, success: "", error: [] };
      break;
    case EDIT_USER_PROFILE:
      state = { ...state, loader: true, success: "", error: [] };
      break;
    case PERSONAL_PROFILE_SUCCESS:
      state = { ...state, loader: false, success: action.payload };
      break;
    case PERSONAL_PROFILE_ERROR:
      state = { ...state, loader: false, error: action.payload };
      break;
    case CHANGE_PROFILE_PASSWORD:
      state = { ...state, loader: true, success: "", error: [] };
      break;
    case CHANGE_PROFILE_PASSWORD_SUCCESS:
      state = { ...state, loader: false, success: action.payload };
      break;
    case CHANGE_PROFILE_PASSWORD_ERROR:
      state = { ...state, loader: false, error: action.payload };
      break;
    case CREATE_USER_PROFILE:
      state = { ...state, loader: true, createSuccess: "", error: [] };
      break;
    case CREATE_PROFILE_SUCCESS:
      state = { ...state, loader: false, createSuccess: action.payload };
      break;
    case CREATE_PROFILE_ERROR:
      state = { ...state, loader: false, error: action.payload };
      break;
    case ADD_USER_TO_TEAM:
      state = { ...state, userToTeamSuccess: "", userToTeamError: "" };
      break;
    case ADD_USER_TO_TEAM_SUCCESS:
      state = { ...state, userToTeamSuccess: action.payload };
      break;
    case ADD_USER_TO_TEAM_ERROR:
      state = { ...state, userToTeamError: action.payload };
      break;
    case DELETE_USER_PROFILE:
      state = { ...state, deleteLoader: true, success: "", error: [] };
      break;
    case DELETE_USER_PROFILE_SUCCESS:
      state = { ...state, deleteLoader: false, success: action.payload };
      break;
    case DELETE_USER_PROFILE_ERROR:
      state = { ...state, deleteLoader: false, error: action.payload };
      break;
    case PROFILE_SETUP_COMPLETED:
      state = { ...state, profileSetupCompleted: true }
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default personalprofile;
