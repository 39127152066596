import React, { Component } from "react";
import { connect } from "react-redux";

import MessageComponent from "../components/MessageComponent";
import { createChatSession } from "../../../../store/actions";
import { IChatInformation, IMessage, IUser } from "../../../../store/interface";

interface NewChatMockProps {
  newChatMock: IChatInformation;
  messages: IMessage[];
  currentuser: IUser;
  createChatSession: (messageContent: string) => void;
}

class NewChatMock extends Component<NewChatMockProps> {
  createChat = (messageContent: string) => {
    const { createChatSession } = this.props;

    createChatSession(messageContent);
  };

  render() {
    const { newChatMock, messages, currentuser } = this.props;

    return (
      <MessageComponent
        isLoading={false}
        messages={messages}
        chatTitle={newChatMock.chatName}
        handleSendMessage={this.createChat}
        chatInfo={newChatMock}
        currentuser={currentuser}
      />
    );
  }
}

const mapStateToProps = (state: any) => {
  const { newChatMock, messages } = state.Chat;
  const { currentuser } = state.Profile;

  return {
    currentuser,
    messages,
    newChatMock,
  };
};

const mapDispatchToProps = {
  createChatSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewChatMock);
