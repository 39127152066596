import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Row, Col, Button, Input } from "reactstrap";
import Select from "react-select";
import { cookies } from '../../services/utilities/utilservice'
import { isEmpty, map, includes } from "lodash";
import { History } from "history";

import { superAdminUser } from "../../helpers/common";
import { DropdownOption } from "../../pages/Meetings/interface";
import {
  setLocalStorage,
  deleteLocalStorage,
  getLocalStorage,
  setTokenCookies,
} from "../../services/utilities/utilservice";
import { resetFilters , setGlobalFiltersLoading} from "../../store/GlobalFilters/actions";
import { getAllUsers, loginAsUser, getUserProfile, switchToSession, emptyDashboards } from "../../store/actions";
import { Icurrentaccountselected, IUser } from "../../store/interface";

interface LoginAsModalProps {
  currentaccountselected: Icurrentaccountselected;
  loading: Boolean;
  userJWT: Object | null;
  allUsers: Object[];
  currentuser: IUser;
  error: Object | String;
  isOpenLoginModal: boolean;
  history: History;
  switchToSessionError: Object | string;
  onOpenLoginModal: (e: any) => void;
  getAllUsers: () => void;
  loginAsUser: (id: String, history: History) => void;
  getUserProfile: () => void;
  onClose: () => void;
  switchToSession: (link: string, history: History) => void;
  resetFilters: () => void;
  emptyDashboards: () => void;
  setGlobalFiltersLoading: () => void;
}

interface LoginAsModalState {
  mainUserToken: String;
  userRole: String;
  linkError: string;
}

class LoginAsModal extends Component<LoginAsModalProps, LoginAsModalState> {
  constructor(props: LoginAsModalProps) {
    super(props);
    this.state = {
      mainUserToken: "",
      userRole: "",
      linkError: "",
    };
  }

  componentDidUpdate(prevProps: LoginAsModalProps) {
    const {
      userJWT,
      currentaccountselected,
      currentuser,
      error,
      getUserProfile,
      onClose,
      getAllUsers,
    } = this.props;

    if (!isEmpty(userJWT) && userJWT !== prevProps.userJWT) {
      getUserProfile();
      onClose();
    }

    if (
      !isEmpty(currentaccountselected) &&
      currentaccountselected !== prevProps.currentaccountselected
    ) {
      this.setState({ userRole: currentaccountselected.right_level });
    }

    if (!isEmpty(currentuser) && currentuser !== prevProps.currentuser &&
      includes(
        superAdminUser,
        currentuser.email
      )
    ) {
      getAllUsers();
    }

    if (!isEmpty(error) && error !== prevProps.error) onClose();
  }

  handleSelectedUser = (userOption: DropdownOption) => {
    const { currentuser, history, loginAsUser, emptyDashboards, setGlobalFiltersLoading } = this.props;

    if (
      !isEmpty(currentuser) &&
      includes(
        superAdminUser,
        currentuser.email
      )
    ) {
      setGlobalFiltersLoading();
      resetFilters();
      setLocalStorage("isTempAccount", true);
      setLocalStorage("isSuperAdmin", true);
      emptyDashboards();
      loginAsUser(userOption.value.toString(), history);
    }
  };

  handleMainAccount = () => {
    const { getUserProfile, onClose, resetFilters, emptyDashboards, setGlobalFiltersLoading } = this.props;
    const token = cookies.get("auth-token");

    setGlobalFiltersLoading();
    resetFilters();
    setTokenCookies(token);
    deleteLocalStorage("isTempAccount");
    emptyDashboards();
    getUserProfile();
    onClose();
  };

  handleSwitchToSession = (link: string) => {
    if (!link) return;

    let sessionId = link.trim();

    if (!sessionId) return;

    if (link.startsWith('http://') || link.startsWith('https://')) {
      const match = link.match(/recording\/([^?]+)/);
      if (match && match[1]) {
        sessionId = match[1];
      } else {
        this.setState({linkError: "Invalid URL format!"});
        return;
      }
    }

    const { history, switchToSession } = this.props;

    switchToSession(sessionId, history);
  }

  render() {
    const { allUsers, isOpenLoginModal, switchToSessionError, onOpenLoginModal, onClose } = this.props;
    const { linkError } = this.state;
    const token = getLocalStorage("isTempAccount");

    const userOptions: DropdownOption[] = map(allUsers, (user: any) => {
      return {
        label: user.email,
        value: user.id.toString(),
        id: user.id.toString(),
      }
    });

    return (
      <Modal isOpen={isOpenLoginModal} toggle={onOpenLoginModal}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Switch Account</h5>
          <button
            type="button"
            onClick={onClose}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body loginas-modal">
          {token ? (
            <Row>
              <Col>
                <div className={`float-left mt-2`}>
                  <Button color="primary" onClick={this.handleMainAccount}>
                    Back to Main Account
                  </Button>
                </div>
                <div className="clearfix"/>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col
                className="team-dropdown"
                style={{ minWidth: '160px', display: 'inline-block' }}
                md="12"
              >
                <Select
                  placeholder="Search User.."
                  onChange={(option) =>
                    this.handleSelectedUser(option as DropdownOption)
                  }
                  options={userOptions}
                  classNamePrefix="select2-selection"
                />
              </Col>
              <Col style={{ marginTop: "10px" }}>
                <Input
                  placeholder="Switch to session..."
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === "Enter") {
                      this.handleSwitchToSession(e.currentTarget.value);
                    }
                  }}
                />
                {linkError && (
                  <div className="error" style={{ marginTop: "10px" }}>
                    <b>{linkError}</b>
                  </div>
                )}
                {
                  switchToSessionError && <div className="error" style={{ marginTop: "10px" }}>
                  <b>{switchToSessionError}</b>
                </div>
                }
              </Col>
            </Row>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentaccountselected, currentuser } = state.Profile;
  const { userJWT, allUsers, loading, error, switchToSessionError } = state.Login;
  return {
    currentaccountselected,
    loading,
    userJWT,
    allUsers,
    currentuser,
    error,
    switchToSessionError,
  };
};

export default connect(mapStateToProps, {
  getAllUsers,
  loginAsUser,
  getUserProfile,
  switchToSession,
  resetFilters,
  emptyDashboards,
  setGlobalFiltersLoading,
})(LoginAsModal);
