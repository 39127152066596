import React, { Component } from "react";

interface MessageInputComponentProps {
  content: string;
  isButtonDisabled: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
}

class MessageInputComponent extends Component<MessageInputComponentProps> {
  render() {
    const {
      content,
      isButtonDisabled,
      handleChange,
      handleKeyDown,
      handleSubmit,
    } = this.props;
    return (
      <div className="chatbot-input-container">
        <input
          type="text"
          className="chatbot-message-input"
          placeholder="Start writing to chatbot..."
          value={content}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <button
          className="chat-send-button"
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          Send
        </button>
      </div>
    );
  }
}

export default MessageInputComponent;
