export const GET_HITRATE_TOKEN = 'GET_HITRATE_TOKEN';
export const GET_HITRATE_TOKEN_SUCCESS = 'GET_HITRATE_TOKEN_SUCCESS';
export const GET_HITRATE_TOKEN_ERROR = 'GET_HITRATE_TOKEN_ERROR';
export const CREATE_HITRATE_TOKEN = 'CREATE_HITRATE_TOKEN';
export const CREATE_HITRATE_TOKEN_SUCCESS = 'CREATE_HITRATE_TOKEN_SUCCESS';
export const CREATE_HITRATE_TOKEN_ERROR = 'CREATE_HITRATE_TOKEN_ERROR';
export const EDIT_HITRATE_TOKEN = 'EDIT_HITRATE_TOKEN';
export const EDIT_HITRATE_TOKEN_SUCCESS = 'EDIT_HITRATE_TOKEN_SUCCESS';
export const EDIT_HITRATE_TOKEN_ERROR = 'EDIT_HITRATE_TOKEN_ERROR';
export const DELETE_HITRATE_TOKEN = 'DELETE_HITRATE_TOKEN';
export const DELETE_HITRATE_TOKEN_SUCCESS = 'DELETE_HITRATE_TOKEN_SUCCESS';
export const DELETE_HITRATE_TOKEN_ERROR = 'DELETE_HITRATE_TOKEN_ERROR';
