import {
  CLEAR_TEAMS,
  CLEAR_TEAMS_SUCCESS,
  CLEAR_TEAMS_ERROR,
} from "./actionTypes";

import {ClearTeamsAction, ClearTeamsState} from './interface';

const initialState: ClearTeamsState = {
  success : "" , 
  error : "",
  loader: false,
}

const clearTeams = (state = initialState, action: ClearTeamsAction) => {
  switch (action.type) {
    case CLEAR_TEAMS:
      state = {
        ...state,
        loader: true,
        success: "",
        error: ""
      };
      break;
    case CLEAR_TEAMS_SUCCESS:
      state = {
        ...state,
        success : action.payload,
        error : "",
        loader: false,
      };
      break;
    case CLEAR_TEAMS_ERROR:
      state = {
        ...state,
        success : "",
        error : action.payload,
        loader: false,
    }
    break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default clearTeams;
