import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  FormGroup,
  CardTitle,
  Modal,
  UncontrolledTooltip,
} from "reactstrap";
import Select, { OptionTypeBase } from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { isNull, isEmpty } from "lodash";

import img from "../../assets/images/emptyprofilepic.jpeg";
import src from "../../assets/images/small/img-4.jpg";
import ChangePassword from "./ChangePassword";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./auth.scss";
import { connect } from "react-redux";
import { History, Location } from "history";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IUser, Icurrentaccountselected } from "../../store/interface";
import {
  editPersonalProfile,
  editPersonalProfilePic,
} from "../../store/actions";
import { optionsLanguage, optionsPreferredIntegration } from "../../constants";
import InputTags from "../../components/InputTags";

type userProfileProps = {
  error: Object | String;
  success: String;
  history: History;
  location: Location;
  currentuser: IUser;
  currentaccountselected: Icurrentaccountselected;
  loader: Boolean;
  editPersonalProfilePic: (
    values: Object,
    id: string | number,
    is: Boolean
  ) => void;
  editPersonalProfile: (user: Object, id: string | number) => void;
  allowPasswordChanges: Boolean;
  onSubmit?: () => void;
};

type userProfileState = {
  first_name: String;
  last_name: String;
  mobile_number: String | Number;
  email: String;
  email_aliases?: string[];
  state: String;
  username: String;
  pic: string;
  timezone: String;
  selectedFiles: Object;
  modal_large: boolean;
  src: any;
  ratio1: any;
  ratio2: any;
  errorMessage: String | Object;
  successMessage: String;
  isOpenPasswordModal: boolean;
  language: string | null;
  integration: string | null;
};

class UserProfile extends Component<userProfileProps & RouteComponentProps, userProfileState> {
  cropper: any;
  constructor(props: userProfileProps & RouteComponentProps) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      mobile_number: "",
      email: "",
      email_aliases: [],
      state: "",
      username: "",
      pic: "",
      timezone: "",
      selectedFiles: [],
      modal_large: false,
      src,
      ratio1: 1,
      ratio2: 1,
      errorMessage: "",
      successMessage: "",
      isOpenPasswordModal: false,
      language: "",
      integration: "",
    };
  }

  static defaultProps = {
    allowPasswordChanges: true,
    showIntegration: true
  };

  componentDidMount() {
    const { currentuser } = this.props;

    if (currentuser) {
      this.handleProfilecall();
    }
  }

  componentDidUpdate(prevProps: userProfileProps) {
    const { error, success } = this.props;

    if (
      this.props.currentaccountselected !== prevProps.currentaccountselected ||
      this.props.currentuser !== prevProps.currentuser
    ) {
      this.handleProfilecall();
      if (!isEmpty(error) && error !== prevProps.error) {
        if (typeof error === "object" && !isEmpty(error)) {
          this.setState({ errorMessage: Object.values(error)[0] });
        } else {
          this.setState({ errorMessage: error });
        }
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 3000);
      }
    }

    if (!isEmpty(success) && success !== prevProps.success) {
      this.setState({ successMessage: success });
      setTimeout(() => {
        this.setState({ successMessage: "" });
      }, 3000);
    }
  }

  handleProfilecall = () => {
    if ("accounts" in this.props.currentuser) {
      let obj = this.props.currentuser;

      this.setState({
        first_name: obj.first_name ? obj.first_name : "",
        last_name: obj.last_name ? obj.last_name : "",
        mobile_number: obj.mobile_number ? obj.mobile_number : "",
        email: obj.email ? obj.email : "",
        email_aliases: obj.email_aliases ?? [],
        username: obj.username ? obj.username : "",
        timezone: obj.timezone ? obj.timezone : "",
        language: obj.language ? obj.language : "",
        integration: obj.integration ? obj.integration : "",
        pic: obj.profile_picture_url ? obj.profile_picture_url : "",
      });
    }
  };

  onFormSubmitted = (event: Event, error: Object[], values: Object) => {
    const {
      currentuser,
      editPersonalProfile: editPersonalProfileAction,
      onSubmit
    } = this.props;

    if (error.length === 0) {
      Object.assign(values, { profile_picture_url: this.state.pic });
      Object.assign(values, { email_aliases: this.state.email_aliases });

      editPersonalProfileAction(values, currentuser?.id!);

      if (onSubmit) {
        onSubmit();
      }
    }
  }

  handleAcceptedFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    this.tog_large();
    // reads the file
    this.onChange(file);

    Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: this.formatBytes(file.size),
    });

    this.setState({ selectedFiles: file });
  };

  formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  tog_large = () => {
    this.setState((prevState) => ({
      modal_large: !prevState.modal_large,
    }));
    this.removeBodyCss();
  }

  onChange = (files: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ src: reader.result });
    };
    reader.readAsDataURL(files);
  }

  dataURLtoFile = (dataurl: any, filename: string) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  cropImage = () => {
    if (typeof this.cropper.getCroppedCanvas() === "undefined") {
      return;
    }
    this.setState({
      pic: this.cropper.getCroppedCanvas().toDataURL(),
      modal_large: false,
    });

    var file = this.dataURLtoFile(
      this.cropper.getCroppedCanvas().toDataURL(),
      "profilepic.png"
    );
    this.props.editPersonalProfilePic(
      {
        type: "public",
        file: file,
        accountId: this.props.currentaccountselected.id,
      },
      this.props.currentuser?.id!,
      false
    );
  }

  handleChangePassword = (value?: Boolean) => {
    const { isOpenPasswordModal } = this.state;
    if (value === false) {
      this.setState({ isOpenPasswordModal: false });
    } else {
      this.setState({ isOpenPasswordModal: !isOpenPasswordModal });
    }
  };

  handleLanguage = (language: OptionTypeBase | null) => {
    this.setState({ language: language?.value });
  };

  handlePreferredPlatfom = (integration: OptionTypeBase | null) => {
    this.setState({ integration: integration?.value });
  }

  updatePicture = () => {
    let inputFile = document.getElementById("file-input");
    if(!isNull(inputFile)) {
      inputFile.click();
    }
  }

  handleEmailAliasesUpdate = (newEmailAliases: string[]) => {
    this.setState({ email_aliases: newEmailAliases });
  };

  render() {
    const { errorMessage, successMessage, isOpenPasswordModal } = this.state;
    const { allowPasswordChanges, currentaccountselected } = this.props;
    const isMeetricEngageEnabled = currentaccountselected.is_meetric_engage_enabled === true;

    return (
      <React.Fragment>
        <div className="profile-tab">
          <Card className="mb-0">
            <CardBody>
              <CardTitle className="mb-4">Edit Personal Details</CardTitle>
              <AvForm
                className="needs-validation mb-3"
                onSubmit={this.onFormSubmitted}
              >
                <Row>
                  <Col lg="4" md="6" className="image-container">
                    <div className="image-upload">
                      <label className="" htmlFor="file-input">
                        <div className="profile-image-wrapper">
                          {this.state.pic ? (
                            <img
                              alt="profile-pic"
                              className="profile-image"
                              src={this.state.pic}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src=img;
                              }}
                            />
                          ) : this.state.first_name ? (
                            <div className="empty-profile">
                              <span>{this.state.first_name.charAt(0)}</span>
                            </div>
                          ) : (
                            <img
                              alt="profile-pic"
                              className="profile-image"
                              src={img}
                            />
                          )}
                          <div className="img-content">
                            <div style={{ margin: "auto" }}>
                              Change Profile Pic
                            </div>
                          </div>
                        </div>
                      </label>

                      <input
                        style={{ display: "none" }}
                        id="file-input"
                        type="file"
                        onChange={this.handleAcceptedFiles}
                      />
                      <div className="centered-button">
                        <Button color="primary" type="button" className="btn-update-picture" onClick={this.updatePicture}>
                          Update Picture
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col lg="8" md="6">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="validateusername">Username</Label>
                          <AvField
                            name="username"
                            value={this.state.username}
                            placeholder="Username"
                            type="text"
                            errorMessage="Enter Username Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            required
                            id="validateusername"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="first-name"> First Name</Label>
                          <AvField
                            name="first_name"
                            value={this.state.first_name}
                            placeholder="First Name"
                            type="text"
                            errorMessage="Enter First Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="first-name"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="last-name"> Last Name</Label>
                          <AvField
                            name="last_name"
                            value={this.state.last_name}
                            placeholder="Last Name"
                            type="text"
                            errorMessage="Enter Last Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="last-name"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="email">Personal Email</Label>
                          <AvField
                            name="email"
                            value={this.state.email}
                            placeholder="For Example - customercare@pitchflow.com"
                            type="email"
                            errorMessage="Enter Valid Email Id"
                            className="form-control"
                            validate={{ email: true }}
                            required
                            id="email"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="personalcontactvalidate">
                            Mobile No.
                          </Label>
                          <AvField
                            name="mobile_number"
                            value={this.state.mobile_number}
                            placeholder="Ex - +1231231231 , -09898989898"
                            type="text"
                            className="form-control"
                            id="personalcontactvalidate"
                          />
                        </FormGroup>
                      </Col>
                    {/* HIDDEN TEMPORARY */}
                      {/* <Col md="6">
                        <FormGroup>
                          <label>Transcription Language</label>
                          <div className="modal-dropdown">
                            <Select
                              options={optionsLanguage}
                              value={this.state.language
                                ? optionsLanguage.find((lang) => lang.value === this.state.language)
                                : null
                              }
                              onChange={this.handleLanguage}
                              className="modal-dropdown"
                            />
                            <AvField
                              name="language"
                              value={this.state.language}
                              type="text"
                              className="hidden"
                            />
                          </div>
                        </FormGroup>
                      </Col> */}
                      {
                        isMeetricEngageEnabled && (
                          <Col md="6">
                            <FormGroup>
                              <label>Preferred Platform</label>
                              <div className="modal-dropdown">
                                <Select
                                  options={optionsPreferredIntegration}
                                  value={this.state.integration
                                    ? optionsPreferredIntegration.find((integration) => integration.value === this.state.integration)
                                    : null
                                  }
                                  onChange={this.handlePreferredPlatfom}
                                  className="modal-dropdown"
                                />
                                <AvField
                                  name="integration"
                                  value={this.state.integration}
                                  type="text"
                                  className="hidden"
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        )
                      }
                      <Col md="12" className="mb-5">
                        <Label htmlFor="email">Email Aliases</Label>
                        <InputTags
                          tags={this.state.email_aliases || []}
                          placeholder={"Enter email aliases..."}
                          updateTags={this.handleEmailAliasesUpdate}
                          tagType="email"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="float-right">
                  <Button color="primary" type="submit" className="btn-save">
                    Save Changes
                  </Button>
                </div>
                {allowPasswordChanges && (<div className="float-right">
                  <Button
                    color="primary"
                    onClick={this.handleChangePassword}
                    className="btn-save btn-pw mr-3"
                  >
                    Change Password
                  </Button>
                </div>)}
                <div className="clearfix"></div>
              </AvForm>
              {!isEmpty(errorMessage) ? (
                <Alert color="danger">{errorMessage}</Alert>
              ) : null}
              {!isEmpty(successMessage) ? (
                <Alert color="success">{successMessage}</Alert>
              ) : null}
            </CardBody>
          </Card>
        </div>
        <Modal
          size="lg"
          isOpen={this.state.modal_large}
          toggle={this.tog_large}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Image Cropper
            </h5>
            <button
              onClick={() => this.setState({ modal_large: false })}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="img-container mb-2">
              <Cropper
                style={{ height: 400, width: "100%" }}
                aspectRatio={this.state.ratio1 / this.state.ratio2}
                preview=".img-preview"
                guides={true}
                src={this.state.src}
                ref={(cropper) => {
                  this.cropper = cropper;
                }}
              />
            </div>
            <Button
              type="button"
              color="success"
              onClick={() => this.cropImage()}
            >
              <span className="docs-tooltip" id="imgResult1">
                Upload Cropped Image
              </span>
              <UncontrolledTooltip placement="top" target="imgResult1">
                Set Cropped Image as your profile picture
              </UncontrolledTooltip>
            </Button>
          </div>
        </Modal>
        {allowPasswordChanges && (<ChangePassword
          isOpenPasswordModal={isOpenPasswordModal}
          onChangePassword={this.handleChangePassword}
        />)}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { error, success, pic, loader } = state.EditPersonalProfileDetails;
  const { currentuser, currentaccountselected } = state.Profile;

  return { error, success, pic, currentuser, loader, currentaccountselected };
};

export default withRouter(
  connect(mapStateToProps, { editPersonalProfile, editPersonalProfilePic })(
    UserProfile
  )
);
