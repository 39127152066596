import React, { Component } from 'react';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { aiModelOptions } from '../../pages/GptPrompt/interface';

interface GptPromptComponentProps {
  sessionId?: string,
  isLoading: boolean;
  onSave: (
    sessionId: string,
    prompt: string,
    promptCount: string,
    aiModel: string
  ) => void;
}

interface GptPromptComponentState {
  sessionId: string;
  prompt: string;
  promptCount: string;
  aiModel: string;
  atLeastOnePromptSent: boolean;
}

class GptPromptComponent extends Component<
  GptPromptComponentProps,
  GptPromptComponentState
> {
  constructor(props: GptPromptComponentProps) {
    super(props);
    this.state = {
      sessionId: this.props.sessionId || '',
      prompt: '',
      promptCount: '1',
      aiModel: aiModelOptions[0].value,
      atLeastOnePromptSent: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<GptPromptComponentProps>): void {
    const { sessionId } = this.props;
    if (sessionId && prevProps.sessionId !== sessionId) {
      this.setState({ sessionId });
    }
  }

  handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (
      name === 'sessionId' ||
      name === 'prompt' ||
      name === 'promptCount' ||
      name === 'aiModel'
    ) {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { sessionId, prompt, promptCount, aiModel } = this.state;
    const { onSave } = this.props;
    this.setState({ atLeastOnePromptSent: true });

    onSave(sessionId, prompt, promptCount, aiModel);
  };

  render() {
    const { sessionId, prompt, promptCount, aiModel, atLeastOnePromptSent } = this.state;
    const isSingleSession = !!this.props.sessionId;

    return (
      <div className={`gpt-prompt-page ${isSingleSession ? 'compact' : ''}`}>
        <div className="gpt-prompt-header">
          {!isSingleSession && (
            <>
              <h1 className="gpt-prompt-title">GPT Prompt</h1>
              <AvForm>
                <Label htmlFor="aiModel">Choose AI model</Label>
                <AvField
                  value={aiModel}
                  type="select"
                  name="aiModel"
                  onChange={this.handleChange}
                >
                  {aiModelOptions.map((aiModel) => {
                    return (
                      <option
                        key={aiModel.value}
                        className="ai-model-option-item"
                        value={aiModel.value}
                      >
                        {aiModel.label}
                      </option>
                    );
                  })}
                </AvField>
              </AvForm>
            </>
          )}
        </div>
        <Form onSubmit={this.handleSubmit}>
          {!isSingleSession && (
            <FormGroup>
              <Label for="session">Session</Label>
              <Input
                type="text"
                name="sessionId"
                id="session"
                placeholder="Enter session..."
                value={sessionId}
                onChange={this.handleChange}
                required
              />
            </FormGroup>
          )}
          <FormGroup>
            {!isSingleSession && <Label for="prompt">Prompt</Label>}
            <Input
              type={isSingleSession ? 'text' : 'textarea'}
              name="prompt"
              id="prompt"
              className="prompt-textarea"
              placeholder="Enter prompt..."
              value={prompt}
              onChange={this.handleChange}
              required
            />
          </FormGroup>
          <div className="prompt-count-box">
            <Button type="submit" disabled={this.props.isLoading}>{isSingleSession ? 'Send' : 'Run'}</Button>
            {!isSingleSession && (
              <>
                <Input
                  type="number"
                  id="promptCount"
                  className="prompt-count-input"
                  value={promptCount}
                  onChange={this.handleChange}
                  name="promptCount"
                  min="1"
                />
                <Label for="promptCount" className="prompt-count-label">
                  times
                </Label>
              </>
            )}
          </div>
        </Form>
        {isSingleSession && !atLeastOnePromptSent && (
          <h6 style={{ margin: '10px', fontSize: '12px', color: '#495057', fontWeight: '400' }}>
            Ask any question, like &quot;What&apos;s the next step&quot;, &quot;Write me a follow up email based on the meeting&quot; or &quot;List dealbreaking objections&quot;
          </h6>
        )}
      </div>
    );
  }
}

export default GptPromptComponent;
