import _ from "lodash";
import React, { Component } from "react";
import Select, { ActionMeta, components, MenuPlacement, OptionsType } from "react-select";
import { selectPropsForModal } from "../../utilities/helperFunctions";

interface DropdownOption {
  label: String;
  value: String | number;
  id: String | number | null;
}

interface MultipleSelectProps {
  onChange: (options: DropdownOption[]) => void;
  options: DropdownOption[];
  defaultValue?: DropdownOption[];
  placeholder: string;
  menuPlacement?: MenuPlacement;
  isDisabled?: boolean;
}

interface MultipleSelectState {
  selectedOptions: DropdownOption[] | null;
}

const optionAll = { label: 'Select all', value: 'all', id: '' };

const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <label>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />{" "}
            {props.label}
          </label>
        </components.Option>
      </div>
    );
  };

const ValueContainer = (props: any) => {
  const {children, hasValue} = props;
  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }

  const [_, input] = children;
  const inputProps = {
    placeholder: props.selectProps.placeholder,
    ...input.props
  }
  return (
    <components.ValueContainer {...props}>
      <components.Input {...inputProps} />
    </components.ValueContainer>
  );
};

class MultipleSelect extends Component<MultipleSelectProps, MultipleSelectState> {
  constructor(props: MultipleSelectProps) {
    super(props);
    this.state = {
      selectedOptions: null,
    };
  }

  refresh() {
    const { onChange } = this.props;
    const { selectedOptions } = this.state;

    onChange(selectedOptions?.filter((option) => option.value !== 'all') as DropdownOption[]);
  }

  handleOnChange = (options: OptionsType<DropdownOption>, event: ActionMeta<DropdownOption>) => {
    const { options: allOptions } = this.props;

    const isAllSelected = (event.action === "select-option" && event.option?.value === "all") || 
      (options && (options || []).filter((o) => o.value !== "all").length === allOptions.length);

    if (!options || event.action === "deselect-option" && event.option?.value === "all") {
      this.setState({ selectedOptions: [] }, this.refresh);
    } else if (event.action === "deselect-option") {
      this.setState({ selectedOptions: options?.filter((o) => o.value !== "all") }, this.refresh);
    } else if (isAllSelected) {
      this.setState({ selectedOptions: [optionAll, ...allOptions] }, this.refresh);
    } else {
      this.setState({selectedOptions: options as DropdownOption[]}, this.refresh);
    }
  }

  componentDidUpdate(prevProps: MultipleSelectProps) {
    const { defaultValue } = this.props;

    if (defaultValue && !_.isEqual(defaultValue, prevProps.defaultValue)) {
      this.setState({ selectedOptions: defaultValue });
    }
  }

  render() {
    const {
      options,
      placeholder,
      defaultValue,
      menuPlacement,
      isDisabled
    } = this.props;
    const { selectedOptions } = this.state;

    return (
      <Select
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        placeholder={placeholder}
        value={selectedOptions || defaultValue}
        onChange={this.handleOnChange}
        components={{ Option, ValueContainer }}
        isMulti
        options={[optionAll, ...options]}
        classNamePrefix="select2-selection"
        menuPlacement={menuPlacement}
        isDisabled={isDisabled}
        {...selectPropsForModal}
      />
    );
  }
}

export default MultipleSelect;
