import { IUser } from '../userlist/interface';
import {
  SEND_ACTIVATION_EMAIL,
  SEND_ACTIVATION_EMAIL_SUCCESS,
  SEND_ACTIVATION_EMAIL_ERROR,
} from './actionTypes';


export const sendActivationEmail = (users: IUser[]) => {
  return {
      type: SEND_ACTIVATION_EMAIL,
      payload: { users: users }
  }
}

export const sendActivationEmailSuccess = (msg: String) => {
  return {
      type: SEND_ACTIVATION_EMAIL_SUCCESS,
      payload: msg
  }
}

export const sendActivationEmailError = (error: String) => {
  return {
    type: SEND_ACTIVATION_EMAIL_ERROR,
    payload: error,
  };
};