export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const EDIT_ACCOUNT = 'EDIT_ACCOUNT';
export const ACCOUNT_SUCCESS = 'ACCOUNT_SUCCESS';
export const ACCOUNT_ERROR = 'ACCOUNT_ERROR';
export const GET_ACCOUNT_DETAILS = 'GET_ACCOUNT_DETAILS';
export const GET_ACCOUNT_DETAILS_SUCCESS = 'GET_ACCOUNT_DETAILS_SUCCESS';
export const SET_ACCOUNT_BRANDING = 'SET_ACCOUNT_BRANDING';
export const ACCOUNT_BRANDING_SUCCESS = 'ACCOUNT_BRANDING_SUCCESS';
export const ACCOUNT_BRANDING_ERROR = 'ACCOUNT_BRANDING_ERROR';
export const DELETE_ACCOUNT_BRANDING_IMAGE = 'DELETE_ACCOUNT_BRANDING_IMAGE';

export const GET_ALL_ACCOUNTS = 'GET_ALL_ACCOUNTS';
export const GET_ALL_ACCOUNTS_SUCCESS = 'GET_ALL_ACCOUNTS_SUCCESS';
export const GET_ALL_ACCOUNTS_ERROR = 'GET_ALL_ACCOUNTS_ERROR';

export const GET_DEAL_STAGES = 'GET_DEAL_STAGES';
export const GET_DEAL_STAGES_SUCCESS = 'GET_DEAL_STAGES_SUCCESS';
export const GET_DEAL_STAGES_ERROR = 'GET_DEAL_STAGES_ERROR';
export const UPDATE_DEAL_STAGES = 'UPDATE_DEAL_STAGES';
