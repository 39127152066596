// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  SHOW_LOADER,
  HIDE_LOADER,
  SETTINGS_MENU,
  RECORDINGS_MENU,
  GET_DASHBOARDS_SUCCESS,
  GET_DASHBOARDS_ERROR,
  EMPTY_DASHBOARDS,
} from './actionTypes';
import { LayoutAction, LayoutState } from './interface';

const INIT_STATE: LayoutState = {
  layoutType: 'vertical',
  layoutWidth: 'fluid',
  leftSideBarTheme: 'dark',
  leftSideBarType: 'default',
  topbarTheme: 'dark',
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  loader: false,
  dashboards: [],
  dashboardError: '',
};

const Layout = (state = INIT_STATE, action: LayoutAction) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      };

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      };
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      };
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      };
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      };
    case TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: !state.showRightSidebar,
      };
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: true,
      };
    case HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: false,
      };
    case SHOW_LOADER:
      return {
        ...state,
        loader: true,
      };
    case HIDE_LOADER:
      return {
        ...state,
        loader: false,
      };
    case SETTINGS_MENU:
      return {
        ...state,
        isOpenSettingMenu: action.payload,
      };
    case RECORDINGS_MENU:
      return {
        ...state,
        isOpenRecordingsMenu: action.payload,
      };
    case GET_DASHBOARDS_SUCCESS:
      return {
        ...state,
        dashboards: action.payload.dashboards,
      };
    case GET_DASHBOARDS_ERROR:
      return {
        ...state,
        dashboardError: action.payload,
      };
    case EMPTY_DASHBOARDS:
      return {
        ...state,
        dashboards: [],
      };
    default:
      return state;
  }
};

export default Layout;
