import React, { Component } from "react";
import { NavLink, Row } from "reactstrap";

import {
  ISubNavbarObject,
  NavElementKeys,
} from "../../pages/RecordingsDetails/interface";

interface SubNavbarProps {
  navElements: ISubNavbarObject;
  activeTab: string;
  handleOnClick: (tab: string, uri: string) => void;
}

class SubNavbar extends Component<SubNavbarProps> {
  render() {
    const { navElements } = this.props;
    const { activeTab, handleOnClick } = this.props;

    return (
      <Row className="main-navbar-items">
        {(Object.keys(navElements) as NavElementKeys[]).map((key, index) => {
          const navElement = navElements[key];

          return (
            <NavLink
              key={index}
              onClick={() => handleOnClick(navElement.tab, navElement.uri)}
              className={`tab ${
                activeTab === navElement.tab ? "tab--active" : ""
              } ${navElement.tab === "1" ? "pl-0" : ""}`}
            >
              {navElement.name}
            </NavLink>
          );
        })}
      </Row>
    );
  }
}

export default SubNavbar;
