import {
    GET_CALENDAR,
    GET_CALENDAR_SUCCESS,
    GET_CALENDAR_ERROR,
    GET_MEETING_BOTS,
    GET_MEETING_BOTS_ERROR,
    GET_MEETING_BOTS_SUCCESS,
    GET_PROCESSING,
    GET_PROCESSING_ERROR,
    GET_PROCESSING_SUCCESS
} from "./actionTypes";
import { ICalendarPreview, IMeetingBot } from "./interface";

export const getCalendar = () => {
    return {
        type: GET_CALENDAR,
        payload: null,
    };
}

export const getCalendarSuccess = (calendars: ICalendarPreview[]) => {
    return {
        type: GET_CALENDAR_SUCCESS,
        payload: calendars,
    }
}

export const getCalendarError = (error: String | Object) => {
    return {
        type: GET_CALENDAR_ERROR,
        payload: error,
    }
}

export const getMeetingBots = (calendarId: string) => {
    return {
        type: GET_MEETING_BOTS,
        payload: calendarId,
    };
}

export const getMeetingBotsSuccess = (meetingBots: IMeetingBot[]) => {
    return {
        type: GET_MEETING_BOTS_SUCCESS,
        payload: meetingBots,
    }
}

export const getMeetingBotsError = (error: String | Object) => {
    return {
        type: GET_MEETING_BOTS_ERROR,
        payload: error,
    }
}

export const getProcessing = (calendarId: string) => {
    return {
        type: GET_PROCESSING,
        payload: calendarId,
    };
}

export const getProcessingSuccess = (meetingBots: IMeetingBot[]) => {
    return {
        type: GET_PROCESSING_SUCCESS,
        payload: meetingBots,
    }
}

export const getProcessingError = (error: String | Object) => {
    return {
        type: GET_PROCESSING_ERROR,
         payload: error,
    }
}

