import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { has } from "lodash";

import {
  CREATE_NEW_ROOM,
  GET_ROOMS,
  EDIT_ROOM,
  DELETE_ROOM,
  DUPLICATE_ROOM,
  CREATE_ROOM_FROM_TEMPLATE,
} from "./actionTypes";
import {
  getRoomsError,
  getRoomsSuccess,
  createRoomSuccess,
  editRoomSuccess,
  deleteRoomSuccess,
  roomsApiError,
  duplicateRoomSuccess,
  setDeletedRoom,
  setUpdatedRoom,
} from "./actions";
import {
  getRooms as getRoomsService,
  createNewRoom as createNewRoomService,
  editRoom as editRoomService,
  deleteRoom as deleteRoomService,
  ResponseGenerator,
  duplicateRoom,
  editRoom,
  createRoomFromTemplateService
} from "../../services/userservice";
import { appendDuplicateRoom } from "../actions";

function* getRooms({ payload: { id, filter } }: any) {
  const response: ResponseGenerator = yield call(getRoomsService, id, filter);
  if (has(response, "data")) {
    yield put(getRoomsSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getRoomsError(error));
    } else {
      yield put(getRoomsError("An Error has occured"));
    }
  }
}

function* createNewRoomHelper({ payload: { accountId, room} }: any) {
  const response: ResponseGenerator = yield call(createNewRoomService, accountId, room);
  if (has(response, "data")) {
    // yield put(getRoomsAction(accountId));
    yield put(setUpdatedRoom(response.data))
    yield put(createRoomSuccess("Room has been created successfully!"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getRoomsError(error));
    } else {
      yield put(getRoomsError("An Error has occured"));
    }
  }
}

function* editRoomHelper({ payload: { accountId, roomId, room } }: any) {
  const response: ResponseGenerator = yield call(editRoomService, accountId, roomId, room);
  if (has(response, "data")) {
    // yield put(getRoomsAction(accountId));
    yield put(setUpdatedRoom(response.data))
    yield put(editRoomSuccess("Room updated successfully!"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(roomsApiError(error));
    } else {
      yield put(roomsApiError("An Error has occured"));
    }
  }
}

function* deleteRoomHelper({ payload: { accountId, roomId } }: any) {
  const response: ResponseGenerator = yield call(deleteRoomService, accountId, roomId);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(roomsApiError(error));
    } else {
      yield put(roomsApiError("An Error has occured!!"));
    }
  } else {
    // yield put(getRoomsAction(accountId));
    yield put(setDeletedRoom(roomId));
    yield put(deleteRoomSuccess("Room deleted successfully!"));
  }
}

function* duplicateRoomHelper({
  payload: { data, roomId, accountId, isTemplate },
}: any) {
  const response: ResponseGenerator = yield call(
    duplicateRoom,
    roomId
  );
  if (has(response, "data")) {
    yield put(
      duplicateRoomSuccess("Room is duplicated successfully!")
    );
    yield put(appendDuplicateRoom({ data: response.data, isTemplate: isTemplate }));
    if (isTemplate) {
      // data.files=[... response.data.id]
      let presentations: string[] = []
      let files: string[] = []
      if(response.data.presentations.length>0) {
        for (let single of response.data.presentations) {
          presentations.push(single.id);
        }
        response.data.presentations = presentations;
      } 
      if(response.data.files.length>0) {
        for (let single of response.data.files) {
          files.push(single.id);
        }
        response.data.files = files;
      }
      
      const editresponse: ResponseGenerator = yield call(
        editRoom,
        accountId,
        response.data.id,
        response.data
      );
      if (has(editresponse, "data")) {
        yield put(
          editRoomSuccess("Room is updated successfully!")
        );
      }
    }
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(roomsApiError(error));
    } else {
      yield put(roomsApiError("An Error has occured"));
    }
  }
}

function* createRoomFromTemplate({
  payload: { data, name, accountId, isTemplate },
}: any) {
  const response: ResponseGenerator = yield call(
    createRoomFromTemplateService,
    data.id
  );
  if (has(response, "data")) {
    yield put(
      duplicateRoomSuccess("Room is Created successfully!")
    );

    if (isTemplate) {
      let presentations: string[] = []
      let files: string[] = []
      response.data.name = name;

      yield put(appendDuplicateRoom({ data: response.data, isTemplate: false }));

      if(response.data.presentations.length>0) {
        for (let single of response.data.presentations) {
          presentations.push(single.id);
        }
        response.data.presentations = presentations;
      } 
      if(response.data.files.length>0) {
        for (let single of response.data.files) {
          files.push(single.id);
        }
        response.data.files = files;
      }
      const editresponse: ResponseGenerator = yield call(
        editRoom,
        accountId,
        response.data.id,
        response.data
      );
      if (has(editresponse, "data")) {
        yield put(
          editRoomSuccess("Room is updated successfully!")
        );
      }
    }
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(roomsApiError(error));
    } else {
      yield put(roomsApiError("An Error has occured"));
    }
  }
}
export function* watchGetRooms() {
  yield takeEvery(GET_ROOMS, getRooms);
}

export function* watchCreateNewRoom() {
  yield takeEvery(CREATE_NEW_ROOM, createNewRoomHelper);
}

export function* watchEditRoom() {
  yield takeEvery(EDIT_ROOM, editRoomHelper);
}

export function* watchDeleteRoom() {
  yield takeEvery(DELETE_ROOM, deleteRoomHelper);
}

export function* watchDuplicateRoom() {
  yield takeEvery(DUPLICATE_ROOM, duplicateRoomHelper);
}
export function* watchCreateRoomFromTemplate() {
  yield takeEvery(CREATE_ROOM_FROM_TEMPLATE, createRoomFromTemplate);
}

function* roomsSaga() {
  yield all([
    fork(watchGetRooms),
    fork(watchCreateNewRoom),
    fork(watchEditRoom),
    fork(watchDeleteRoom),
    fork(watchDuplicateRoom),
    fork(watchCreateRoomFromTemplate)
    
  ]);
}

export default roomsSaga;
