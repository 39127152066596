import { v4 as uuidv4 } from "uuid";
import { IChatInformation } from "../../../../store/interface";

export const chatMock: IChatInformation = {
  chatId: uuidv4(),
  chatName: "New chat",
  createdAt: "",
  status: "ready",
  messages: [],
  lastMessage: "",
  isChatMock: true,
  uniqueKeyForNewChatMock: Date.now(),
};
