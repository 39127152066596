export const GET_MEETING_TYPES = "GET_MEETING_TYPES";
export const GET_MEETING_TYPES_ERROR = "GET_MEETING_TYPES_ERROR";
export const GET_MEETING_TYPES_SUCCESS = "GET_MEETING_TYPES_SUCCESS";
export const GET_CAMPAIGN = "GET_CAMPAIGN";
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS";
export const GET_CAMPAIGN_ERROR = "GET_CAMPAIGN_ERROR";
export const CREATE_MEETING_TYPE = "CREATE_MEETING_TYPE";
export const CREATE_MEETING_TYPE_SUCCESS = "CREATE_MEETING_TYPE_SUCCESS";
export const GET_CREATED_MEETING_TYPE = 'GET_CREATED_MEETING_TYPE';
export const EDIT_MEETING_TYPE = "EDIT_MEETING_TYPE";
export const EDIT_MEETING_TYPE_SUCCESS = "EDIT_MEETING_TYPE_SUCCESS";
export const GET_DEFAULT_MEETING_TYPES = "GET_DEFAULT_MEETING_TYPES";
export const GET_DEFAULT_MEETING_TYPES_SUCCESS = "GET_DEFAULT_MEETING_TYPES_SUCCESS";
export const GET_DEFAULT_MEETING_TYPES_ERROR = "GET_DEFAULT_MEETING_TYPES_ERROR";
export const API_ERROR = "API_ERROR";
export const EDIT_RECORDING_TYPE = "EDIT_RECORDING_TYPE";
export const EDIT_RECORDING_TYPE_ERROR = "EDIT_RECORDING_TYPE_ERROR";
