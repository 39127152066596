export const EDIT_PROFILE = "EDIT_PROFILE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const CHANGE_CURRENT_ACCOUNT = "CHANGE_CURRENT_ACCOUNT";
export const CHANGE_MULTIPLE_ACCOUNT_STATE = "CHANGE_MULTIPLE_ACCOUNT_STATE";
export const UPDATE_CURRENT_ACCOUNT = "UPDATE_CURRENT_ACCOUNT";
export const UPDATE_LYNES_INTEGRATION = "UPDATE_LYNES_INTEGRATION";
export const UPDATE_TELENOR_INTEGRATION = "UPDATE_TELENOR_INTEGRATION";
export const UPDATE_TELAVOX_INTEGRATION = "UPDATE_TELAVOX_INTEGRATION";
