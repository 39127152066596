import React, { Component } from 'react';
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Input,
  Label,
  List,
} from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  getTimeLineComments,
  addTimeLineComment,
  getUserList,
} from '../../store/actions';
import {
  IAnalyticsMeetingTimelineComment,
  IUser,
} from '../../store/interface';
import {
  escapeHtmlSpecialChars,
  formatDateTime,
  formatDate,
  formatTime,
} from '../../services/utilities/utilservice';

interface IComment extends IAnalyticsMeetingTimelineComment {}

interface DropdownGroup {
  label: String;
  value: IUser;
  id: String | number;
}

interface CommentsProps {
  accountId: String;
  currentuser: IUser;
  loading: boolean;
  progress: number;
  timeLineSessionId: string;
  timeLineTargetTime: number;
  timeLineDuration: number;
  timeLineUpdateSeek: any;
  timeLineUpdateSeekDirect: any;
  timeLineComments: IComment[];
  getTimeLineComments: (accountId: String, sessionId: String) => void;
  addTimeLineComment: (accountId: String, sessionId: String, comment: Object, resolve: any, reject: any) => void;
  users: IUser[];
  getUserList: (id: String) => void;
  timeLineHandleCut: (updateWidth: boolean) => void;
  //Snippets
  showAddSnippet: boolean;
  showSnippetForm: boolean;
  toggleAddSnippet: () => void;
  snippetStartSeconds: number;
  snippetEndSeconds: number | null,
  handleChangeSnippetName: (value: string) => void;
  handleSnippetSubmit: () => void;
  showAddComment: boolean;
  changeShowAddCommentModal: () => void;
  closeRndTooltip: () => void;
  closeAddCommentModal: () => void;
}

interface CommentsState {
  comments: IComment[];
  replies: any;
  isTagMenuVisible: boolean;
  userOptions: DropdownGroup[];
  filteredUserOptions: DropdownGroup[];
  sortedUserOptions: DropdownGroup[];
  activeEditableElement: any;
  x: number;
  y: number;
  activeIndex: number;
  showAddComment: boolean;
  showAddTag: boolean;
}

class Comments extends Component<
  CommentsProps,
  CommentsState
> {
  commentsLoaded: boolean = false;
  blurTimeout: any = null;
  caretIndexAtBlur: number = 0;
  skipFocusEvent: boolean = false;
  reloadComments: boolean = false;

  constructor(props: CommentsProps) {
    super(props);
    this.state = {
      comments: [],
      replies: {},
      isTagMenuVisible: false,
      userOptions: [],
      filteredUserOptions: [],
      sortedUserOptions: [],
      activeEditableElement: null,
      x: 0,
      y: 0,
      activeIndex: 0,
      showAddComment: false,
      showAddTag: false,
    };
  }

  componentDidMount() {
    const {
      accountId,
      timeLineSessionId,
    } = this.props;

    if (!_.isEmpty(accountId) && this.commentsLoaded === false) {
      this.commentsLoaded = true;
      this.props.getTimeLineComments(accountId, timeLineSessionId);
      this.props.getUserList(accountId);
    }

    window.onscroll = () => {
      this.positionTagMenu();
    };
  }

  componentDidUpdate() {
    const {
      accountId,
      timeLineSessionId,
      timeLineComments,
      users,
    } = this.props;
    const {
      comments,
      userOptions,
    } = this.state;

    if (!_.isEmpty(accountId) && this.commentsLoaded === false) {
      this.commentsLoaded = true;
      this.props.getTimeLineComments(accountId, timeLineSessionId);
      this.props.getUserList(accountId);
    }

    if (!_.isEmpty(timeLineComments) && !_.isEmpty(comments)) {
      let compareComments = timeLineComments.filter((item: IComment) => {
        return item.reply_parent_id === null;
      });

      if(compareComments.length !== comments.length) {
        this.reloadComments = true;
      }
    }

    if(this.reloadComments) {
      this.reloadComments = false;
      this.resetComments();
    }

    if (!_.isEmpty(timeLineComments) && _.isEmpty(comments)) {
      for (let item of timeLineComments) {
        this.parse(item);
      }
    }

    if (!_.isEmpty(users) && _.isEmpty(userOptions)) {
      let mappedUsers: DropdownGroup[] = users.map((user: IUser, key: number) => {
        return {
          label: this.getUserLabel(user),
          value: user,
          id: user.id,
        };
      });
      // Sort ascending by full name.
      mappedUsers.sort((a: DropdownGroup, b: DropdownGroup) => this.getUserLabel(a.value).localeCompare(this.getUserLabel(b.value)));
      // Sort descending by full name.
      let sortedUsers: DropdownGroup[] = _.cloneDeep(mappedUsers);
      sortedUsers.sort((a: DropdownGroup, b: DropdownGroup) => this.getUserLabel(b.value).localeCompare(this.getUserLabel(a.value)));

      this.setState({
        userOptions: mappedUsers,
        filteredUserOptions: mappedUsers,
        sortedUserOptions: sortedUsers,
      });
    }
  }

  resetComments = () => {
    this.setState({comments: []});
  }

  parse = (item: IComment, prepend: boolean = false) => {
    const {
      comments,
      replies,
    } = this.state;

    if (item.reply_parent_id === null) {
      prepend && comments.unshift(item);
      !prepend && comments.push(item);
      this.setState({ comments });
    } else {
      if (typeof replies[item.reply_parent_id] === 'undefined') replies[item.reply_parent_id] = [];
      prepend && replies[item.reply_parent_id].unshift(item);
      !prepend && replies[item.reply_parent_id].push(item);
      this.setState({ replies });
    }
  };

  getCaretCoordinates = () => {
    let x: number = 0;
    let y: number = 0;
    const isSupported: boolean = typeof window.getSelection !== 'undefined';

    if (isSupported) {
      const selection: any = window.getSelection();
      if (selection.rangeCount !== 0) {
        const range: any = selection.getRangeAt(0).cloneRange();
        range.collapse(true);
        const rect: any = range.getClientRects()[0];
        if (rect) {
          x = rect.left;
          y = rect.top;
        }
      }
    }

    return { x, y };
  };

  /**
   * Inside a contentEditable element.
   */
  getCaretIndex = (el: any) => {
    let position: number = 0;
    const isSupported: boolean = typeof window.getSelection !== 'undefined';

    if (isSupported) {
      const selection: any = window.getSelection();
      if (selection.rangeCount !== 0) {
        const range: any = window.getSelection()?.getRangeAt(0);
        if (typeof range !== 'undefined') {
          const preCaretRange: any = range.cloneRange();
          preCaretRange.selectNodeContents(el);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          position = preCaretRange.toString().length;
        }
      }
    }

    return position;
  };

  getStringBeforeCaret = (el: any) => {
    return el.innerText.slice(0, this.getCaretIndex(el));
  };

  restoreCaretIndex = (el: any, caretIndex: number) => {
    const isSupported: boolean =
      typeof window.getSelection !== 'undefined' &&
      typeof document.createRange !== 'undefined';
    if (!isSupported) return;

    let charIndex: number = 0;
    let nodeStack: any[] = [el];
    let node: any;
    let stop: boolean = false;

    const range: any = document.createRange();
    range.setStart(el, 0);
    range.collapse(true);

    while (!stop && (node = nodeStack.pop())) {
      if (node.nodeType == 3) {
        let nextCharIndex: number = charIndex + node.length;
        if (caretIndex >= charIndex && caretIndex <= nextCharIndex) {
          range.setStart(node, caretIndex - charIndex);
          stop = true;
        }
        charIndex = nextCharIndex;
      } else {
        let i: number = node.childNodes.length;
        while (i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }

    var sel = window.getSelection();
    sel?.removeAllRanges();
    sel?.addRange(range);
  };

  tagMenuControls = (event: any) => {
    const {
      isTagMenuVisible,
      filteredUserOptions,
      activeIndex,
    } = this.state;

    switch (event.key) {
      case 'ArrowUp':
        if (isTagMenuVisible === true) {
          event.preventDefault();
          const newIndex1: number = activeIndex === 0
            ? filteredUserOptions.length - 1
            : activeIndex - 1;
          this.changeActiveIndex(newIndex1);
        }
        break;
      case 'ArrowDown':
        if (isTagMenuVisible === true) {
          event.preventDefault();
          const newIndex2: number = activeIndex + 1 === filteredUserOptions.length
            ? 0
            : activeIndex + 1;
          this.changeActiveIndex(newIndex2);
        }
        break;
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'Home':
      case 'End':
      case 'PageUp':
      case 'PageDown':
        if (event.altKey === false) {
          let persistEventTarget: any = { currentTarget: event.currentTarget };
          setTimeout(() => {
            this.openTagMenu(persistEventTarget);
          });
        }
        break;
      case 'Enter':
      case 'Tab':
        if (isTagMenuVisible === true && event.shiftKey === false) {
          event.preventDefault();
          this.handleTagging(filteredUserOptions[activeIndex]);
        }
        break;
      case 'Escape':
        if (isTagMenuVisible === true) {
          event.preventDefault();
          this.closeTagMenu();
        }
        break;
    };
  };

  changeActiveIndex = (newIndex: number) => {
    this.setState({
      activeIndex: newIndex,
    });
  };

  openTagMenu = (event: any) => {
    const {
      userOptions,
    } = this.state;

    this.resetBlurTimeout();
    let string: string = this.getStringBeforeCaret(event.currentTarget);

    if (string.lastIndexOf('@') === -1) {
      this.setState({
        isTagMenuVisible: false,
        activeEditableElement: event.currentTarget,
      });
      return;
    }

    let names: string[] = string.slice(string.lastIndexOf('@') + 1).toLowerCase().split(' ');

    let filteredUserOptions: DropdownGroup[] = userOptions.filter((option: DropdownGroup) => {
      if (names.length > option.label.split(' ').length) {
        return false;
      }

      for (let name of names) {
        if (option.label.toLowerCase().includes(name) === false) {
          return false;
        }
      }

      return true;
    });

    this.setState({
      isTagMenuVisible: filteredUserOptions.length > 0,
      filteredUserOptions: filteredUserOptions,
      activeEditableElement: event.currentTarget,
      activeIndex: 0,
    }, () => {
      this.positionTagMenu();
    });
  };

  positionTagMenu = () => {
    const {
      isTagMenuVisible,
    } = this.state;

    if (isTagMenuVisible) {
      let coords: any = this.getCaretCoordinates();
      this.setState({
        x: coords.x,
        y: coords.y,
      });
    }
  };

  closeTagMenu = () => {
    this.setState({
      isTagMenuVisible: false,
    });
  };

  /**
   * Tag a user by selecting the option from the dropdown.
   */
  handleTagging = (option: DropdownGroup) => {
    const {
      activeEditableElement,
    } = this.state;

    let string = this.getStringBeforeCaret(activeEditableElement);
    let preText: string = activeEditableElement.innerText.slice(0, string.lastIndexOf('@') + 1);
    let afterText: string = activeEditableElement.innerText.slice(string.length);

    this.generateHtml(preText + option.label + (afterText[0] === ' ' ? afterText : ' ' + afterText));
    this.restoreCaretIndex(activeEditableElement, preText.length + option.label.length + 1);
    this.closeTagMenu();
  };

  generateHtml = (text: string) => {
    const {
      activeEditableElement,
    } = this.state;

    activeEditableElement.innerHTML = this.tagUsers(escapeHtmlSpecialChars(text));
  };

  tagUsers = (text: string) => {
    const {
      sortedUserOptions,
    } = this.state;
    let html: string = text;

    for (let option of sortedUserOptions) {
      const regex: any = new RegExp('(^|[^>])@' + this.getUserLabel(option.value), 'gi');
      html = html.replace(regex, '$1' + this.tagUser(option.value, false));
    }

    return html;
  };

  tagUser = (user: IUser, asJsxElement: boolean = true) => {
    if (asJsxElement === true) {
      return (
        <a href="javascript:void(0)" data-user-id={user.id}>
          @{this.getUserLabel(user)}
        </a>
      );
    }

    return (
      '<a href="javascript:void(0)" data-user-id="' + user.id + '">' +
        '@' + this.getUserLabel(user) +
      '</a>'
    );
  };

  getUserLabel = (user: IUser) => {
    return user.first_name + ' ' + user.last_name;
  };

  resetBlurTimeout = () => {
    clearTimeout(this.blurTimeout);
    this.blurTimeout = null;
  };

  handleSubmit = (event: any) => {
    event.preventDefault();

    const {
      accountId,
      currentuser,
      timeLineSessionId,
      timeLineTargetTime,
    } = this.props;

    let formElements: any = event.currentTarget.elements;
    let editableElement: any = event.currentTarget.querySelector('.editable-element');
    let text: string = editableElement.innerText.trim();
    if (text.length === 0) {
      return;
    }

    let comment: IComment = {
      id: 'temp',
      created_by: currentuser.id,
      reply_parent_id: formElements?.reply_parent_id?.value || null,
      text: text,
      target_time: typeof formElements?.target_time !== 'undefined'
        ? ~~formElements.target_time.value
        : ~~timeLineTargetTime,
      created_at: formatDateTime(moment().utc()),
      visible: formElements?.visible?.value === 'false' ? false : true,
    };

    let promise: any = new Promise((resolve, reject) => {
      this.props.addTimeLineComment(accountId, timeLineSessionId, comment, resolve, reject);
    });
    promise.then((response: any) => {
      this.props.getTimeLineComments(accountId, timeLineSessionId);

      editableElement.innerHTML = '';
      this.toggleAddComment();
      comment.id = response.data.id;
      comment.created_by = currentuser;
      this.parse(comment, true);
    });
  };

  toggleAddComment = () => {
    this.props.changeShowAddCommentModal();
  };

  getGroupedComments = () => {
    const {
      timeLineDuration,
    } = this.props;
    const {
      comments,
    } = this.state;

    return _.groupBy(comments, (comment: IComment) => {
      // 1000 comes from #video-line-main width.
      // 20 comes from .comments-preview-toggle width.
      let x: number = Math.floor(comment.target_time * 1000 / (timeLineDuration * 20));
      return x * timeLineDuration * 20 / 1000;
    });
  };

  getLeftPos = (targetTime: number) => {
    const {
      timeLineDuration,
    } = this.props;

    return targetTime / timeLineDuration * 100;
  };

  render() {
    const {
      progress,
      timeLineTargetTime,
      timeLineDuration,
      timeLineUpdateSeek,
      timeLineUpdateSeekDirect,
      showAddSnippet,
      showSnippetForm,
      snippetStartSeconds,
      snippetEndSeconds,
    } = this.props;
    const {
      comments,
      replies,
      isTagMenuVisible,
      filteredUserOptions,
      x,
      y,
      activeIndex,
      showAddComment,
      showAddTag,
    } = this.state;
    let previews: any = [];

    setTimeout(() => {
      const repositioner: any = document.getElementById('comments-to-timeline-repositioner');
      const wrapperEl: any = document.getElementById('timeline-wrapper');
      const lineEl: any = document.getElementById('video-line-main');
      const wrapperElOffsetHeight = wrapperEl ? wrapperEl.offsetHeight : 0;
      const lineElOffsetHeight = lineEl ? lineEl.offsetHeight : 0;

      // Calculate the top position of repositioner.
      if (repositioner) {
        const top: any = - wrapperElOffsetHeight + lineElOffsetHeight;
        repositioner.style.top = top + 'px';
      }

      // Show the add comment box on the left side if it's too far to the right and goes out of screen.
      const addCommentBox: any = document.getElementById('add-comment-box');
      if (addCommentBox === null) return;

      const rect: any = lineEl.getBoundingClientRect();
      const scrollBarWidth: number = 12;
      const reverse: boolean =
        (window.innerWidth - rect.left - rect.width * progress / 100) <
        (addCommentBox.offsetWidth + scrollBarWidth + 45);
      if (reverse) {
        addCommentBox.classList.add('reverse');
      } else {
        addCommentBox.classList.remove('reverse');
      }
    });

    return (
      <div className='comments'>
        <div id='comments-to-timeline-repositioner'>
          <div
            className='comments-line'
            onClick={(event: any) => {
              timeLineUpdateSeek(event);
            }}
          >
            {showAddSnippet &&
              <>
                <a
                  className='add-comments-toggle'
                  style={{
                    left: `${progress}%`,
                  }}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    this.toggleAddComment();
                    this.props.closeRndTooltip();
                  }}
                  title='Add comment'
                >
                  <i className='fa fa-comment'></i>
                </a>
                <a
                  className='add-comments-toggle'
                  id='add-resizable'
                  style={{
                    left: `${progress + 2.2}%`,
                  }}
                  onClick={(event: any) => {
                    this.props.timeLineHandleCut(false);
                    this.props.closeAddCommentModal();
                  }}
                  title='Add scissors'
                >
                  <i className='fa fa-cut'></i>
                </a>
              </>
            }

            {!_.isEmpty(_.forIn(this.getGroupedComments(), (items: IComment[], targetTime: any) => {
              targetTime = ~~targetTime;

              previews.push(
                <div
                  className='comments-preview-toggle'
                  style={{
                    left: this.getLeftPos(targetTime) + '%',
                  }}
                  key={targetTime.toString()}
                >
                  {items.length}

                  <div
                    className={targetTime > timeLineDuration / 2
                                 ? 'comments-preview reverse'
                                 : 'comments-preview'}
                  >
                    <List
                      type='unstyled'
                    >
                      {items.map((comment: IComment, index: number) => {
                        const div: any = document.createElement('div');
                        div.innerHTML = comment.text;
                        const text: string = div.innerText;

                        return (
                          <li
                            key={index.toString()}
                            onClick={(event: any) => {
                              document
                                ?.getElementById('comment-title-' + comment.id)
                                ?.scrollIntoView(true)
                              ;
                            }}
                          >
                            <b>{formatTime(comment.target_time)}</b>
                            &nbsp;&nbsp;
                            <span
                              title={text}
                              dangerouslySetInnerHTML={{
                                __html: text.length > 32 ? text.slice(0, 29) + '...' : text,
                              }}
                            />
                            &nbsp;
                            {this.tagUser(comment.created_by)}
                            &nbsp;
                            {formatDate(comment.created_at)}
                          </li>
                        );
                      })}
                    </List>
                  </div>
                </div>
              );
            })) && previews}
          </div>


          {showAddComment &&
            <div
              id='add-comment-box'
              className='add-comment-box'
              style={{
                width: "720px",
                left: `${progress}%`
              }}
            >
              <Row>
                <Col lg='12'>
                  <h5 className='add-comment-box-title'>
                    <i className='fa fa-comment'></i>
                    <span>Add comment</span>
                    <a onClick={(event: any) => {}}>
                      {formatTime(timeLineTargetTime)}
                    </a>
                  </h5>
                </Col>
              </Row>
            
              <Form onSubmit={this.handleSubmit}>
                {/** Textarea */}
                <Row>
                  <Col lg='12'>
                    <div
                      className='editable-element'
                      placeholder='Write comment here'
                      onKeyDown={(event: any) => {
                        this.tagMenuControls(event);
                      }}
                      onInput={(event: any) => {
                        let caretIndex: number = this.getCaretIndex(event.currentTarget);
                        this.generateHtml(event.currentTarget.innerText);
                        this.restoreCaretIndex(event.currentTarget, caretIndex);
                        this.openTagMenu(event);
                      }}
                      onClick={(event: any) => {
                        this.openTagMenu(event);
                      }}
                      onFocus={(event: any) => {
                        if (this.skipFocusEvent === true) return;
                        this.openTagMenu(event);
                      }}
                      onBlur={(event: any) => {
                        this.caretIndexAtBlur = this.getCaretIndex(event.currentTarget);
                        this.blurTimeout = setTimeout(() => {
                          this.closeTagMenu();
                        }, 250);
                      }}
                      contentEditable='true'
                      suppressContentEditableWarning={true}
                      style={{ minHeight: '80px', padding: '16px 14px' }}
                    >
                    </div>
                  </Col>
                </Row>
            
                <Row className='buttons-wrapper'>
                  {/** Buttons */}
                  <Col lg='3'>
                    <Button
                      color='blue'
                    >
                      Save
                    </Button>
                    <Button
                      color='dark'
                      onClick={this.toggleAddComment}
                    >
                      Cancel
                    </Button>
                  </Col>
            
                  {/** Radio buttons */}
                  <Col lg='9' className='radio-buttons-wrapper'>
                    <span><b>Comment visible for</b></span>
                    <FormGroup
                      check
                      inline
                    >
                      <Input
                        id='visible-false'
                        type='radio'
                        name='visible'
                        value='false'
                        defaultChecked
                      />
                      <Label
                        check
                        for='visible-false'
                      >
                        Only meeting owner
                      </Label>
                    </FormGroup>
            
                    <FormGroup
                      check
                      inline
                    >
                      <Input
                        id='visible-true'
                        type='radio'
                        name='visible'
                        value='true'
                      />
                      <Label
                        check
                        for='visible-true'
                      >
                        Anybody with recording access
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state: any) => {
  const {
    currentuser,
  } = state.Profile;
  const {
    loading,
    timeLineComments,
  } = state.SalesAnalytics;
  const { filterUsers } = state.getUsersList;

  return {
    currentuser,
    loading,
    users: filterUsers,
    timeLineComments,
  };
};

export default connect(mapStatetoProps, {
  getTimeLineComments,
  addTimeLineComment,
  getUserList,
})(Comments);
