export const optionsLanguage = [
  { value: 'en', label: 'English' },
  { value: 'sv', label: 'Swedish' },
];
  
export const optionsPreferredIntegration = [
  { value: null, label: 'Default platform' },
  { value: 'meetric', label: 'Meetric Engage' },
  { value: 'zoom', label: 'Zoom' },
  { value: 'teams', label: 'Teams' },
  { value: 'meet', label: 'Google Meet' },
];