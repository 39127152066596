import {
  INTEGRATE_MICROSOFT_DYNAMICS,
  INTEGRATION_SUCCESS,
  INTEGRATION_ERROR,
  LOGIN_PIPEDRIVE,
  LOGIN_PIPEDRIVE_SUCCESS,
  LOGIN_PIPEDRIVE_ERROR,
  INTEGRATION_REMOVE,
  INTEGRATION_REMOVE_SUCCESS,
  INTEGRATION_REMOVE_ERROR,
} from "./actionTypes";
import { IntegrationType } from "./interface";

export const integrateMicrosoftDynamics = (payload: any) => {
  return {
    type:   INTEGRATE_MICROSOFT_DYNAMICS,
    payload: payload,
  };

};
export const integrationSuccess = (msg: String) => {
  return {
    type: INTEGRATION_SUCCESS,
    payload: msg,
  };
};
export const integrationError = (error: String) => {
  return {
    type: INTEGRATION_ERROR,
    payload: error,
  };
};

export const loginPipedrive = () => {
  return {
    type: LOGIN_PIPEDRIVE,
  };

};
export const loginPipedriveSuccess = (redirectUrl: any) => {
  return {
    type: LOGIN_PIPEDRIVE_SUCCESS,
    payload: redirectUrl,
  };
};
export const loginPipedriveError = (error: String) => {
  return {
    type: LOGIN_PIPEDRIVE_ERROR,
    payload: error,
  };
};

export const removeIntegration = (integrationType: IntegrationType) => {
  return {
    type: INTEGRATION_REMOVE,
    payload: { integrationType },
  };
}

export const removeIntegrationSuccess = () => {
  return {
    type: INTEGRATION_REMOVE_SUCCESS,
  };
}

export const removeIntegrationError = (error: String) => {
  return {
    type: INTEGRATION_REMOVE_ERROR,
    payload: error,
  };
}
