export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_FILTER_USERS = "GET_FILTER_USERS";
export const GET_FILTER_USERS_SUCCESS = "GET_FILTER_USERS_SUCCESS";
export const API_ERROR = "GET_USERS_API_ERROR";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_ERROR = "SET_PASSWORD_ERROR";
export const SET_USER_ROLE = "SET_USER_ROLE,";
export const SET_USER_ROLE_SUCCESS = "SET_USER_ROLE_SUCCESS";
export const SET_USER_ROLE_ERROR = "SET_USER_ROLE_ERROR";
