export const CREATE_CHAT_SESSION = "CREATE_CHAT_SESSION";
export const CREATE_CHAT_SESSION_SUCCESS = "CREATE_CHAT_SESSION_SUCCESS";
export const CREATE_CHAT_SESSION_ERROR = "CREATE_CHAT_SESSION_ERROR";
export const GET_CHAT_TOPICS = "GET_CHAT_TOPICS";
export const GET_CHAT_TOPICS_SUCCESS = "GET_CHAT_TOPICS_SUCCESS";
export const GET_CHAT_TOPICS_ERROR = "GET_CHAT_TOPICS_ERROR";
export const GET_CHAT_MESSAGES = "GET_CHAT_MESSAGES";
export const GET_CHAT_MESSAGES_SUCCESS = "GET_CHAT_MESSAGES_SUCCESS";
export const GET_CHAT_MESSAGES_ERROR = "GET_CHAT_MESSAGES_ERROR";
export const CLOSE_CHAT = "CLOSE_CHAT";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";
export const EDIT_CHAT = "EDIT_CHAT";
export const EDIT_CHAT_ERROR = "EDIT_CHAT_ERROR";
export const DELETE_CHAT = "DELETE_CHAT";
export const DELETE_CHAT_SUCCESS = "DELETE_CHAT_SUCCESS";
export const DELETE_CHAT_ERROR = "DELETE_CHAT_ERROR";
export const ADD_NEW_CHAT = "ADD_NEW_CHAT";
export const GET_MOCK_CHAT = "GET_MOCK_CHAT";
