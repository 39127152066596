import React, { Component } from "react";
import { isEqual, isNull, last } from "lodash";

import MessageInputComponent from "./MessageInputComponent";
import { renderLoadingIndicator } from "../../../Widgets/utils/helperFunction";
import { IChatInformation, IMessage, IUser } from "../../../../store/interface";
import img from "../../../../assets/images/emptyprofilepic.jpeg";
import { formatMessage } from "../utils/helperFunctions";

interface MessageComponentProps {
  isLoading: boolean;
  messages: IMessage[];
  chatTitle: string;
  chatInfo: IChatInformation;
  currentuser: IUser;
  handleSendMessage: (messageContent: string) => void;
}

interface MessageComponentState {
  content: string;
  isButtonDisabled: boolean;
  showBotMessagePlaceholder: boolean;
}

class MessageComponent extends Component<
  MessageComponentProps,
  MessageComponentState
> {
  private messagesEndRef: React.RefObject<HTMLDivElement>;

  constructor(props: MessageComponentProps) {
    super(props);
    this.state = {
      content: "",
      isButtonDisabled: false,
      showBotMessagePlaceholder: false,
    };
    this.messagesEndRef = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps: MessageComponentProps) {
    const { messages, chatInfo } = this.props;

    if (messages.length > prevProps.messages.length) {
      this.scrollToBottom();
    }

    if (!isEqual(messages, prevProps.messages)) {
      if (chatInfo?.status === "streaming") {
        this.setState({
          isButtonDisabled: true,
        });
      } else {
        this.setState({
          isButtonDisabled: false,
        });
      }

      const lastMessage = last(messages);
      if (lastMessage?.role !== "assistant") {
        this.setState({
          showBotMessagePlaceholder: true,
        });
      } else {
        this.setState({
          showBotMessagePlaceholder: false,
        });
      }
    }
  }

  scrollToBottom = () => {
    if (this.messagesEndRef.current) {
      this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ content: event.target.value });
  };

  handleSubmit = () => {
    const { content, isButtonDisabled } = this.state;
    const { handleSendMessage } = this.props;

    if (!isButtonDisabled) {
      handleSendMessage(content);
      this.setState({ content: "" });
    }
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  };

  render() {
    const { isLoading, messages, chatTitle, currentuser } = this.props;
    const { content, isButtonDisabled, showBotMessagePlaceholder } = this.state;

    const finalMessages = messages.filter(message => message.role !== "tool");

    return (
      <main className="chat-main">
        <div className="chat-main-header">Topic: {chatTitle}</div>
        <div className="chat-messages">
          {isLoading ? (
            <div className="chat-message-loader">
              {renderLoadingIndicator()}
            </div>
          ) : (
            finalMessages.map((msg, index) => (
              <div key={index}>
                <div className="message-sender-name">
                  <div
                    className={`signle-user single-user-chat ${
                      msg.role === "assistant" ? "bot-chat-image" : ""
                    }`}
                  >
                    {msg.role === "user" ? (
                      <>
                        {!isNull(currentuser) &&
                        currentuser.profile_picture_url ? (
                          <img
                            alt="profile-pic"
                            className="profile-image profile-image-chat"
                            src={currentuser.profile_picture_url}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = img;
                            }}
                          />
                        ) : !isNull(currentuser) && currentuser.first_name ? (
                          <div className="signle-user">
                            <p>{currentuser.first_name.charAt(0)}</p>
                          </div>
                        ) : (
                          <img
                            alt="profile-pic"
                            className="profile-image"
                            src={img}
                          />
                        )}
                      </>
                    ) : (
                      <p>m</p>
                    )}
                  </div>
                  {msg.role === "user" ? "You" : "Assistant"}
                </div>
                <div
                  className={`chat-message ${
                    msg.role === "user" ? "outgoing" : "incoming"
                  }`}
                >
                  {formatMessage(msg.content)}
                </div>
              </div>
            ))
          )}
          {showBotMessagePlaceholder ? (
            <>
              <div className="message-sender-name">
                <div className="signle-user single-user-chat bot-chat-image">
                  <p>m</p>
                </div>
                Assistant
              </div>
              <div className="chat-message">
                <div className="assistant-placeholder-loader">
                  {renderLoadingIndicator()}
                </div>
              </div>
            </>
          ) : null}
          <div ref={this.messagesEndRef} />
        </div>
        <MessageInputComponent
          content={content}
          isButtonDisabled={isButtonDisabled}
          handleChange={this.handleChange}
          handleKeyDown={this.handleKeyDown}
          handleSubmit={this.handleSubmit}
        />
      </main>
    );
  }
}

export default MessageComponent;
