import React, { Component } from "react";
import { connect } from "react-redux";

import { DASHBOARD, INSPIRATION, MEETING, RECORDING, STATS } from "./utils/constants";
import GlobalFilterComponent from "./components/GlobalFilter";
import { DropdownOption } from "../../pages/Home/utils/constants";
import {
  FilterObject,
  ICampaign,
  IFilterTeam,
  IFilterUser,
  IMeetingTypes,
  IRecordingType,
  IDealStatus,
  ICallOutcome,
  IAccount,
} from "../../store/interface";
import {
  getGlobalFilters,
  updateFilters,
  emptyDateFilter,
} from "../../store/GlobalFilters/actions";
import { FilterMapping } from "./utils/interface";
import { findObjectsByIds } from "./utils/helperfunctions";
import DashboardGlobalFilterComponent from './components/DashboardGlobalFilterComponent';

interface GlobalFiltersProps {
  type: string;
  currentaccountselected: IAccount;
  recordingTypes: IRecordingType[];
  campaigns: ICampaign[];
  meetingTypes: IMeetingTypes[];
  filterUsers: IFilterUser[];
  filterTeams: IFilterTeam[];
  dealsStatus: IDealStatus[];
  callsOutcome: ICallOutcome[];
  dashboardFilters: FilterObject;
  meetingFilters: FilterObject;
  recordingFilters: FilterObject;
  statsFilters: FilterObject;
  inspirationFilters: FilterObject;
  isForcedCampaigns: boolean;
  updateFilters: (filterType: string, value: any, type: string) => void;
  getGlobalFilters: (accountId: String) => void;
  emptyDateFilter: (type: string) => void;
}

class GlobalFilters extends Component<GlobalFiltersProps> {
  updateFilter = (
    filterType: string,
    options: DropdownOption[] | DropdownOption
  ) => {
    const {
      type,
      updateFilters,
    } = this.props;
    if (filterType === 'filterTeams') {
      this.handleTeamAndUserUpdate(filterType, options);
      return;
    }

    const valueId = Array.isArray(options)
      ? options.map((option: DropdownOption) => option.value)
      : options.value;

    updateFilters(filterType, valueId, type.toUpperCase());
  };

  handleTeamAndUserUpdate = (
    filterType: string,
    teamOptions: DropdownOption[] | DropdownOption,
  ) => {
    const {
      type,
      updateFilters,
    } = this.props;

    const teamSelectedOptions = Array.isArray(teamOptions)
      ? teamOptions.map((option: DropdownOption) => option.value)
      : [teamOptions.value];

    updateFilters('filterUsers', [], type.toUpperCase());
    updateFilters(filterType, teamSelectedOptions, type.toUpperCase());
  };

  handleDateChange = (
    startDate: string | null,
    endDate: string | null,
    globalPeriod: null | string
  ) => {
    const {
      type,
      updateFilters,
    } = this.props;

    updateFilters('startDate', startDate, type.toUpperCase());
    updateFilters('endDate', endDate, type.toUpperCase());
    updateFilters('globalPeriod', globalPeriod, type.toUpperCase());
  };

  render() {
    const {
      currentaccountselected,
      type,
      recordingTypes,
      campaigns,
      meetingTypes,
      filterTeams,
      filterUsers,
      dealsStatus,
      callsOutcome,
      dashboardFilters,
      meetingFilters,
      recordingFilters,
      statsFilters,
      inspirationFilters,
      isForcedCampaigns,
    } = this.props;
    const isMeetingOrRecording = type === RECORDING || type === MEETING;

    const filterMapping: FilterMapping = {
      [DASHBOARD]: dashboardFilters,
      [MEETING]: meetingFilters,
      [RECORDING]: recordingFilters,
      [STATS]: statsFilters,
      [INSPIRATION]: inspirationFilters,
    };

    let filters: FilterObject = filterMapping[type] || {};

    filters = {
      ...filters,
      filterUsers: findObjectsByIds((filters.filterUsers || []).map(Number), filterUsers) as IFilterUser[],
      filterTeams: findObjectsByIds(filters.filterTeams || [], filterTeams) as IFilterTeam[],
      meetingTypes: findObjectsByIds(filters.meetingTypes || [], meetingTypes) as IMeetingTypes[],
      recordingTypes: findObjectsByIds(filters.recordingTypes || [], recordingTypes, true) as IRecordingType[],
      campaigns: findObjectsByIds(filters.campaigns || [], campaigns, true) as ICampaign[],
      dealsStatus: findObjectsByIds(filters.dealsStatus || [], dealsStatus, true) as IDealStatus[],
      callsOutcome: findObjectsByIds(filters.callsOutcome || [], callsOutcome, true) as ICallOutcome[],
    };

    if (type === DASHBOARD) {
      return (
        <DashboardGlobalFilterComponent
          currentaccountselected={currentaccountselected}
          filters={filters}
          recordingTypes={recordingTypes}
          campaigns={campaigns}
          meetingTypes={meetingTypes}
          filterTeams={filterTeams}
          filterUsers={filterUsers}
          dealsStatus={dealsStatus}
          callsOutcome={callsOutcome}
          isMeetingOrRecording={isMeetingOrRecording}
          updateFilter={this.updateFilter}
          handleDateChange={this.handleDateChange}
          isForcedCampaigns={isForcedCampaigns}
        />
      );
    }

    return (
      <GlobalFilterComponent
        filters={filters}
        recordingTypes={recordingTypes}
        campaigns={campaigns}
        meetingTypes={meetingTypes}
        filterTeams={filterTeams}
        filterUsers={filterUsers}
        dealsStatus={dealsStatus}
        callsOutcome={callsOutcome}
        isMeetingOrRecording={isMeetingOrRecording}
        updateFilter={this.updateFilter}
        handleDateChange={this.handleDateChange}
        isForcedCampaigns={isForcedCampaigns}
      />
    );
  }
}

const mapStateToProps = (state: any) => {
  const {
    recordingTypes,
    campaigns,
    meetingTypes,
    filterUsers,
    filterTeams,
    dealsStatus,
    callsOutcome,
    dashboardFilters,
    meetingFilters,
    recordingFilters,
    statsFilters,
    inspirationFilters,
    isForcedCampaigns,
  } = state.globalFilters;

  const { currentaccountselected } = state.Profile;

  return {
    currentaccountselected,
    recordingTypes,
    campaigns,
    meetingTypes,
    filterUsers,
    filterTeams,
    dealsStatus,
    callsOutcome,
    dashboardFilters,
    meetingFilters,
    recordingFilters,
    statsFilters,
    inspirationFilters,
    isForcedCampaigns,
  };
};

export default connect(mapStateToProps, {
  getGlobalFilters,
  updateFilters,
  emptyDateFilter,
})(GlobalFilters);
