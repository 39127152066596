export const GET_CALL_TOKEN = 'GET_CALL_TOKEN';
export const GET_CALL_TOKEN_SUCCESS = 'GET_CALL_TOKEN_SUCCESS';
export const GET_CALL_TOKEN_ERROR = 'GET_CALL_TOKEN_ERROR';
export const GET_CALL_TOKEN_SETTINGS = 'GET_CALL_TOKEN_SETTINGS';
export const GET_CALL_TOKEN_SETTINGS_SUCCESS = 'GET_CALL_TOKEN_SETTINGS_SUCCESS';
export const GET_CALL_TOKEN_SETTINGS_ERROR = 'GET_CALL_TOKEN_SETTINGS_ERROR';
export const CREATE_CALL_TOKEN_SETTINGS = 'CREATE_CALL_TOKEN_SETTINGS';
export const CREATE_CALL_TOKEN_SETTINGS_SUCCESS = 'CREATE_CALL_TOKEN_SETTINGS_SUCCESS';
export const CREATE_CALL_TOKEN_SETTINGS_ERROR = 'CREATE_CALL_TOKEN_SETTINGS_ERROR';
export const DELETE_CALL_TOKEN_SETTINGS = 'DELETE_CALL_TOKEN_SETTINGS';
export const DELETE_CALL_TOKEN_SETTINGS_SUCCESS = 'DELETE_CALL_TOKEN_SETTINGS_SUCCESS';
export const DELETE_CALL_TOKEN_SETTINGS_ERROR = 'DELETE_CALL_TOKEN_SETTINGS_ERROR';
export const UPDATE_CALL_TOKEN_SETTINGS = 'UPDATE_CALL_TOKEN_SETTINGS';
export const UPDATE_CALL_TOKEN_SETTINGS_ERROR = 'UPDATE_CALL_TOKEN_SETTINGS_ERROR';
export const UPDATE_CALL_TOKEN_SETTINGS_SUCCESS = 'UPDATE_CALL_TOKEN_SETTINGS_SUCCESS';
export const GET_ADVERSUS_RULES = 'GET_ADVERSUS_RULES';
export const GET_ADVERSUS_RULES_SUCCESS = 'GET_ADVERSUS_RULES_SUCCESS';
export const GET_ADVERSUS_RULES_ERROR = 'GET_ADVERSUS_RULES_ERROR';
export const CREATE_ADVERSUS_RULE = 'CREATE_ADVERSUS_RULE';
export const CREATE_ADVERSUS_RULE_SUCCESS = 'CREATE_ADVERSUS_RULE_SUCCESS';
export const CREATE_ADVERSUS_RULE_ERROR = 'CREATE_ADVERSUS_RULE_ERROR';
export const EDIT_ADVERSUS_RULE = 'EDIT_ADVERSUS_RULE';
export const EDIT_ADVERSUS_RULE_SUCCESS = 'EDIT_ADVERSUS_RULE_SUCCESS';
export const EDIT_ADVERSUS_RULE_ERROR = 'EDIT_ADVERSUS_RULE_ERROR';
export const DELETE_ADVERSUS_RULE = 'DELETE_ADVERSUS_RULE';
export const DELETE_ADVERSUS_RULE_SUCCESS = 'DELETE_ADVERSUS_RULE_SUCCESS';
export const DELETE_ADVERSUS_RULE_ERROR = 'DELETE_ADVERSUS_RULE_ERROR';
