import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { has } from 'lodash';

import { GET_AGENTS } from './actionTypes';
import {
  getAvailableAIAgentsError,
  getAvailableAIAgentsSuccess,
} from './actions';
import { ResponseGenerator } from '../../services/userservice';
import { getAvailableAIAgents } from '../../services/Dashboard';

function* getAvailableAIAgentsHelper({ payload }: any) {
  const response: ResponseGenerator = yield call(getAvailableAIAgents, payload);
  if (has(response, 'data')) {
    yield put(getAvailableAIAgentsSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getAvailableAIAgentsError(error));
    } else {
      yield put(getAvailableAIAgentsError('An error has occurred!'));
    }
  }
}

export function* watchGetAvailableAIAgents() {
  yield takeEvery(GET_AGENTS, getAvailableAIAgentsHelper);
}

function* DashboardSaga() {
  yield all([fork(watchGetAvailableAIAgents)]);
}

export default DashboardSaga;
