import {
  GET_SHARED_WITH_ME_DATA,
  GET_SHARED_WITH_ME_DATA_ERROR,
  GET_SHARED_WITH_ME_DATA_SUCCESS,
} from "./actionTypes";
import { ISharedWithMeData } from "./interface";

export const getSharedWithMe = (
  accountId: String,
  globalFilterString: string
) => ({
  type: GET_SHARED_WITH_ME_DATA,
  payload: { accountId, globalFilterString },
});

export const getSharedWithMeError = (error: string | Object) => ({
  type: GET_SHARED_WITH_ME_DATA_ERROR,
  payload: error,
});

export const getSharedWithMeSuccess = (
  dataSharedWithMe: ISharedWithMeData[]
) => ({
  type: GET_SHARED_WITH_ME_DATA_SUCCESS,
  payload: dataSharedWithMe,
});
