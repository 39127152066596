export const GET_ACCOUNT_TOKENS = 'GET_ACCOUNT_TOKENS';
export const GET_ACCOUNT_TOKENS_SUCCESS = 'GET_ACCOUNT_TOKENS_SUCCESS';
export const GET_ACCOUNT_TOKENS_ERROR = 'GET_ACCOUNT_TOKENS_ERROR';
export const CREATE_ACCOUNT_TOKEN = 'CREATE_ACCOUNT_TOKEN';
export const CREATE_ACCOUNT_TOKEN_SUCCESS = 'CREATE_ACCOUNT_TOKEN_SUCCESS';
export const CREATE_ACCOUNT_TOKEN_ERROR = 'CREATE_ACCOUNT_TOKEN_ERROR';
export const EDIT_ACCOUNT_TOKEN = 'EDIT_ACCOUNT_TOKEN';
export const EDIT_ACCOUNT_TOKEN_SUCCESS = 'EDIT_ACCOUNT_TOKEN_SUCCESS';
export const EDIT_ACCOUNT_TOKEN_ERROR = 'EDIT_ACCOUNT_TOKEN_ERROR';
export const DELETE_ACCOUNT_TOKEN = 'DELETE_ACCOUNT_TOKEN';
export const DELETE_ACCOUNT_TOKEN_SUCCESS = 'DELETE_ACCOUNT_TOKEN_SUCCESS';
export const DELETE_ACCOUNT_TOKEN_ERROR = 'DELETE_ACCOUNT_TOKEN_ERROR';
export const ENABLE_LYNES_INTEGRATION = 'ENABLE_LYNES_INTEGRATION';
export const ENABLE_LYNES_INTEGRATION_SUCCESS = 'ENABLE_LYNES_INTEGRATION_SUCCESS';
export const ENABLE_LYNES_INTEGRATION_ERROR = 'ENABLE_LYNES_INTEGRATION_ERROR';
export const DISABLE_LYNES_INTEGRATION = 'DISABLE_LYNES_INTEGRATION';
export const DISABLE_LYNES_INTEGRATION_SUCCESS = 'DISABLE_LYNES_INTEGRATION_SUCCESS';
export const DISABLE_LYNES_INTEGRATION_ERROR = 'DISABLE_LYNES_INTEGRATION_ERROR';
export const ENABLE_TELENOR_INTEGRATION = 'ENABLE_TELENOR_INTEGRATION';
export const ENABLE_TELENOR_INTEGRATION_SUCCESS = 'ENABLE_TELENOR_INTEGRATION_SUCCESS';
export const ENABLE_TELENOR_INTEGRATION_ERROR = 'ENABLE_TELENOR_INTEGRATION_ERROR';
export const DISABLE_TELENOR_INTEGRATION = 'DISABLE_TELENOR_INTEGRATION';
export const DISABLE_TELENOR_INTEGRATION_SUCCESS = 'DISABLE_TELENOR_INTEGRATION_SUCCESS';
export const DISABLE_TELENOR_INTEGRATION_ERROR = 'DISABLE_TELENOR_INTEGRATION_ERROR';
export const ENABLE_CRM_SERVICE_INTEGRATION = 'ENABLE_CRM_SERVICE_INTEGRATION';
export const ENABLE_CRM_SERVICE_INTEGRATION_SUCCESS = 'ENABLE_CRM_SERVICE_INTEGRATION_SUCCESS';
export const ENABLE_CRM_SERVICE_INTEGRATION_ERROR = 'ENABLE_CRM_SERVICE_INTEGRATION_ERROR';
export const DISABLE_CRM_SERVICE_INTEGRATION = 'DISABLE_CRM_SERVICE_INTEGRATION';
export const DISABLE_CRM_SERVICE_INTEGRATION_SUCCESS = 'DISABLE_CRM_SERVICE_INTEGRATION_SUCCESS';
export const DISABLE_CRM_SERVICE_INTEGRATION_ERROR = 'DISABLE_CRM_SERVICE_INTEGRATION_ERROR';
export const SET_CRM_SERVICE_INIT_STATE = 'SET_CRM_SERVICE_INIT_STATE';
