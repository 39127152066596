import {
  GET_FOLDERS,
  GET_FOLDERS_SUCCESS,
  API_ERROR,
  UPLOAD_FOLDER,
  UPLOAD_FOLDER_SUCCESS,
  EDIT_FOLDER,
  EDIT_FOLDER_SUCCESS,
  REMOVE_FOLDER,
  REMOVE_FOLDER_SUCCESS,
  CHECK_CUSTOM_FOLDERS,
  GET_CUSTOM_FOLDERS,
  GET_CUSTOM_FOLDERS_SUCCESS,
  MOVE_FOLDER,
  MOVE_FOLDER_SUCCESS,
  GET_USER_FOLDERS,
} from "./actionTypes";

export const getFolders = (data: Object) => {
  return {
    type: GET_FOLDERS,
    payload: { data },
  };
};

export const getFoldersSuccess = (data: Object[]) => {
  return {
    type: GET_FOLDERS_SUCCESS,
    payload: data,
  };
};

export const getCustomFolders = (id: String, folderId: String) => {
  return {
    type: GET_CUSTOM_FOLDERS,
    payload: { id, folderId },
  };
};

export const CheckCustomFolders = (
  id: String,
  folderId: String,
  callback?: Function
) => {
  return {
    type: CHECK_CUSTOM_FOLDERS,
    payload: { id, folderId, callback },
  };
};

export const getCustomFoldersSuccess = (data: Object[]) => {
  return {
    type: GET_CUSTOM_FOLDERS_SUCCESS,
    payload: data,
  };
};

export const foldersApiError = (mes: String) => {
  return {
    type: API_ERROR,
    payload: mes,
  };
};

export const uploadFolder = (fileObj: Object) => {
  return {
    type: UPLOAD_FOLDER,
    payload: { fileObj },
  };
};

export const uploadFolderSuccess = (mes: String) => {
  return {
    type: UPLOAD_FOLDER_SUCCESS,
    payload: mes,
  };
};

export const editFolder = (id: String, params: Object) => {
  return {
    type: EDIT_FOLDER,
    payload: { id, params },
  };
};

export const editFolderSuccess = (mes: String) => {
  return {
    type: EDIT_FOLDER_SUCCESS,
    payload: mes,
  };
};

export const removeFolder = (id: String) => {
  return {
    type: REMOVE_FOLDER,
    payload: { id },
  };
};

export const removeFolderSuccess = (mes: String) => {
  return {
    type: REMOVE_FOLDER_SUCCESS,
    payload: mes,
  };
};


export const getUsersFolders = (callback: Function) => {
  return {
    type: GET_USER_FOLDERS,
    payload: { callback },
  };
};

export const moveFolders = (fileObj: Object, callback: Function) => {
  return {
    type: MOVE_FOLDER,
    payload: { fileObj, callback },
  };
};

export const moveFoldersSuccess = (mes: String, fileObj: Object) => {
  return {
    type: MOVE_FOLDER_SUCCESS,
    payload: { mes, fileObj },
  };
};
