import React, { Component } from "react";
import { connect } from "react-redux";

import ChatModal from "./components/ChatModalComponent";
import ChatComponent from "./components/ChatComponent";
import { closeChat } from "../../../store/Chat/actions";
import { IChatInformation } from "../../../store/interface";

interface ChatProps {
  isChatOpen: boolean;
  newChatMock: IChatInformation;
  closeChat: () => void;
}

class Chat extends Component<ChatProps> {
  render() {
    const { isChatOpen, newChatMock, closeChat } = this.props;

    return (
      <>
        {isChatOpen && (
          <ChatModal isOpen={isChatOpen} handleClose={closeChat}>
            <div className={`chat-container ${isChatOpen ? "open" : "closed"}`}>
              <ChatComponent mockChat={newChatMock} />
            </div>
          </ChatModal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { isChatOpen, newChatMock } = state.Chat;

  return {
    isChatOpen,
    newChatMock,
  };
};

const mapDispatchToProps = {
  closeChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
