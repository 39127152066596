import {
    GET_CALENDAR,
    GET_CALENDAR_ERROR,
    GET_CALENDAR_SUCCESS,
    GET_MEETING_BOTS,
    GET_MEETING_BOTS_ERROR,
    GET_MEETING_BOTS_SUCCESS,
    GET_PROCESSING,
    GET_PROCESSING_ERROR,
    GET_PROCESSING_SUCCESS,
} from "./actionTypes";

import { CalendarsAction, CalendarState } from "./interface";

const initialState: CalendarState = {
    success: "",
    error: [],
    loader: false,
    calendars: null,
    meetingBots: null,
    processing: null,
}

const Calendar = (state = initialState, action: CalendarsAction) => {
    switch (action.type) {
        case GET_CALENDAR:
            state = {
                ...state,
                success: "",
                error: [],
                calendars: null,
            }
            break;
        case GET_CALENDAR_SUCCESS:
            state = {
                ...state,
                calendars: action.payload,
            }
            break;
        case GET_CALENDAR_ERROR:
            state = {
                ...state,
                error: action.payload,
            }
        case GET_MEETING_BOTS:
            state = {
                ...state,
                success: "",
                error: [],
                meetingBots: null,
            }
            break;
        case GET_MEETING_BOTS_SUCCESS:
            state = {
                ...state,
                meetingBots: action.payload,
            }
            break;
        case GET_MEETING_BOTS_ERROR:
            state = {
                ...state,
                error: action.payload,
            }
        case GET_PROCESSING:
            state = {
                ...state,
                success: "",
                error: [],
                processing: null,
            }
            break;
        case GET_PROCESSING_SUCCESS:
            state = {
                ...state,
                processing: action.payload,
            }
            break;
        case GET_PROCESSING_ERROR:
            state = {
                ...state,
                error: action.payload,
            }
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default Calendar;
