import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import _ from "lodash";

import {
  GET_STATS_USER,
  GET_STATS_USER_DAILY,
  GET_STATS_TEAM,
  GET_STATS_TEAM_DAILY,
  GET_STATS_MEETINGS,
  GET_STATS_MEETINGS_COUNT,
  GET_STATS_MEETINGS_DAILY,
  GET_STATS_MEETINGS_LIVE,
  GET_STATS_INTERACTIONS,
  GET_STATS_INTERACTIONS_DAILY,
  GET_STATS_PARTICIPANTS,
  GET_STATS_PARTICIPANTS_DAILY,
  GET_STATS_MEMBERS,
  GET_STATS_MEMBERS_DAILY,
  GET_STATS_VIEWS,
  GET_STATS_SLIDES,
  GET_STATS_SLIDES_DAILY,
  GET_STATS_PRESENTATIONS,
  GET_STATS_PRESENTATIONS_DAILY,
  GET_STATS_SHARED_LINKS,
  GET_STATS_SHARED_LINKS_DAILY,
  GET_STATS_ROOM_SESSIONS,
  GET_STATS_ROOM_SESSIONS_DAILY,
  GET_STATS_CONTACTS,
  GET_STATS_CONTACTS_DAILY,
  GET_STATS_COMPANIES,
  GET_STATS_COMPANIES_DAILY,
  GET_STATS_DASHBOARD,
  GET_STATS_DASHBOARD_DAILY,
  GET_STATS_RECORDINGS,
  DELETE_STATS_RECORDINGS,
  GET_TIME_LINE,
  GET_TIME_LINE_COMMENTS,
  DELETE_TIME_LINE_COMMENT,
  DELETE_SESSION,
  ADD_TIME_LINE_COMMENT,
  GET_SNIPPET_TIME_LINE,
  GET_MEETING_AI_ANALYTICS,
  SHARE_RECORDING,
  GET_RECORDING_SHARE_LINKS_FOR_RECORDING,
  DELETE_RECORDING_SHARE_LINK,
  UPDATE_RECORDING_SHARE_LINK,
  GET_RECORDING_SHARE_LINK_BY_LINK_HASH,
  GET_RECORDING_SNIPPETS,
  GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET,
  CREATE_SNIPPET_SHARE_LINK,
  UPDATE_SNIPPET_SHARE_LINK,
  DELETE_SNIPPET_SHARE_LINK,
  POST_RECORDING_SNIPPET,
  UPDATE_SNIPPET,
  GET_SNIPPET_SHARE_LINK_BY_LINK_HASH,
  GET_SNIPPET,
  GET_LOGS,
  GET_USER_PROFILE_SETUP_FINISHED,
  GET_INTEGRATION_SETUP_FINISHED,
  CREATE_LOG,
  GET_RECORDING_TRANSCRIPT,
  GET_SNIPPET_RECORDING_TRANSCRIPT,
  SEARCH_RECORDING_TRANSCRIPTS,
  GET_AI_CATEGORIES,
  GET_ALL_TAGS_BY_ACCOUNT,
  ADD_RECORDING_TAGS,
  DELETE_RECORDING_TAGS,
  ADD_SNIPPET_TAGS,
  DELETE_SNIPPET_TAG,
  CREATE_TAG,
  DELETE_TAG,
  EDIT_TAG,
  GET_TAG_ANALYTICS,
} from "./actionTypes";

import {
  getStatsUserSuccess,
  getStatsUserDailySuccess,
  getStatsUserError,
  getStatsTeamSuccess,
  getStatsTeamDailySuccess,
  getStatsTeamError,
  getStatsMeetingsSuccess,
  getStatsMeetingsDailySuccess,
  getStatsMeetingsError,
  getStatsMeetingsLiveSuccess,
  getStatsMeetingsLiveError,
  getStatsInteractionsSuccess,
  getStatsInteractionsDailySuccess,
  getStatsInteractionsError,
  getStatsParticipantsSuccess,
  getStatsParticipantsDailySuccess,
  getStatsParticipantsError,
  getStatsMembersSuccess,
  getStatsMembersDailySuccess,
  getStatsMembersError,
  getStatsPresentationsSuccess,
  getStatsPresentationsDailySuccess,
  getStatsPresentationsError,
  getStatsViewsError,
  getStatsViewsSuccess,
  getStatsSlidesSuccess,
  getStatsSlidesDailySuccess,
  getStatsSlidesError,
  getSharedLinksSuccess,
  getSharedLinksDailySuccess,
  getSharedLinksError,
  getStatsRoomSessionsSuccess,
  getStatsRoomSessionsDailySuccess,
  getStatsRoomSessionsError,
  getStatsContactsSuccess,
  getStatsContactsDailySuccess,
  getStatsContactsError,
  getStatsCompaniesSuccess,
  getStatsCompaniesDailySuccess,
  getStatsCompaniesError,
  getStatsDashboardSuccess,
  getStatsDashboardError,
  getStatsDashboardDailySuccess,
  getStatsRecordingsError,
  getStatsRecordingsSuccess,
  deleteStatsRecordingsError,
  deleteStatsRecordingsSuccess,
  getTimeLineSuccess,
  getTimeLineError,
  getTimeLineCommentsSuccess,
  getTimeLineCommentsError,
  getSnippetTimeLineSuccess,
  getSnippetTimeLineError,
  deleteTimeLineCommentSuccess,
  deleteTimeLineCommentError,
  deleteSessionSuccess,
  deleteSessionError,
  addTimeLineCommentSuccess,
  addTimeLineCommentError,
  getMeetingAIAnalyticsSuccess,
  getMeetingAIAnalyticsError,
  shareRecordingSuccess,
  shareRecordingError,
  getRecordingShareLinksForRecordingSuccess,
  getRecordingShareLinksForRecordingError,
  deleteRecordingShareLinkSuccess,
  deleteRecordingShareLinkError,
  updateRecordingShareLinkSuccess,
  updateRecordingShareLinkError,
  getRecordingShareLinkByLinkHashSuccess,
  getRecordingShareLinkByLinkHashError,
  getRecordingSnippetsSuccess,
  postRecordingSnippetSuccess,
  getSnippetShareLinksForSnippetSuccess,
  getSnippetShareLinksForSnippetError,
  createSnippetShareLinkSuccess,
  createSnippetShareLinkError,
  updateSnippetShareLinkSuccess,
  updateSnippetShareLinkError,
  updateSnippetSuccess,
  updateSnippetError,
  deleteSnippetShareLinkSuccess,
  deleteSnippetShareLinkError,
  getSnippetShareLinkByLinkHashSuccess,
  getSnippetShareLinkByLinkHashError,
  getSnippetSuccess,
  getSnippetError,
  getLogsSuccess,
  getLogsError,
  createLogSuccess,
  createLogError,
  getUserProfileSetupFinishedSuccess,
  getIntegrationSetupFinishedSuccess,
  getUserProfileSetupFinishedError,
  getIntegrationSetupFinishedError,
  getRecordingTranscriptError,
  getRecordingTranscriptSuccess,
  searchRecordingTranscriptsError,
  searchRecordingTranscriptsSuccess,
  getSnippetRecordingTranscriptError,
  getSnippetRecordingTranscriptSuccess,
  getRecordingSnippets as getRecordingSnippetsAction,
  getAiCategoriesSuccess,
  getAiCategoriesError,
  getAllTagsByAccountSuccess,
  addRecordingTagSuccess,
  addSnippetTagSuccess,
  deleteSnippetTagSuccess,
  getSnippetTagsError,
  deleteRecordingTagSuccess,
  createTag,
  createTagError,
  createTagSuccess,
  deleteTag,
  deleteTagError,
  deleteTagSuccess,
  editTag,
  editTagError,
  editTagSuccess,
  getTagAnalytics,
  getTagAnalyticsError,
  getTagAnalyticsSuccess,
  getStatsMeetingsCountSuccess,
  getStatsMeetingsCountError
} from "./actions";
import { IEventLog } from "./interface";

import {
  getStatsUser,
  getStatsUserDaily,
  getStatsTeam,
  getStatsTeamDaily,
  getStatsMeetings,
  getStatsMeetingsCount,
  getStatsMeetingsDaily,
  getStatsMeetingsLive,
  getStatsInteractions,
  getStatsInteractionsDaily,
  getStatsParticipants,
  getStatsParticipantsDaily,
  getStatsMembers,
  getStatsMembersDaily,
  getStatsContacts,
  getStatsContactsDaily,
  getStatsCompanies,
  getStatsCompaniesDaily,
  getStatsViews,
  getStatsPresentations,
  getStatsPresentationsDaily,
  getStatsSlides,
  getStatsSlidesDaily,
  getSharedLinks,
  getSharedLinksDaily,
  getRecordingShareLinksForRecording,
  shareRecordingService,
  getStatsRoomSessions,
  getStatsRoomSessionsDaily,
  ResponseGenerator,
  getRecordingsService,
  deleteRecordingService,
  getTimeLine,
  getTimeLineComments,
  deleteSession,
  addTimeLineComment,
  getMeetingAIAnalytics,
  deleteRecordingShareLink,
  updateRecordingShareLink,
  getRecordingShareLinkByLinkHash,
  getRecordingSnippets,
  postRecordingSnippet,
  getSnippetShareLinksForSnippet,
  createSnippetShareLink,
  updateSnippetShareLink,
  deleteSnippetShareLink,
  getSnippetShareLinkByLinkHash,
  getSnippet,
  deleteTimeLineComment,
  getLogs,
  createLog,
  getRecordingTranscript,
  getSnippetRecordingTranscript,
  searchRecordingTranscripts,
  getSnippetTimeLine,
  putSnippet,
  getAiCategories,
  getAllTags,
  addRecordingTag,
  deleteRecordingTag,
  createSnippetTag,
  removeSnippetTag,
  createTagByAccount,
  deleteTagByAccount,
  editTagByAccount,
  fetchTagAnalytics,
} from "../../services/userservice"
import { setTranscript } from '../actions';

function* getStatsDashboardHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(getStatsUser, id, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsDashboardError(error));
    } else {
      yield put(getStatsDashboardError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsDashboardSuccess(response.data));
  }
}

function* getStatsDashboardDailyHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(getStatsUserDaily, id, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsDashboardError(error));
    } else {
      yield put(getStatsDashboardError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsDashboardDailySuccess(response.data));
  }
}

function* getStatsUserHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(getStatsUser, id, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsUserError(error));
    } else {
      yield put(getStatsUserError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsUserSuccess(response.data));
  }
}

function* getStatsUserDailyHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(getStatsUserDaily, id, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsUserError(error));
    } else {
      yield put(getStatsUserError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsUserDailySuccess(response.data));
  }
}

function* getStatsTeamHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(getStatsTeam, id, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsTeamError(error));
    } else {
      yield put(getStatsTeamError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsTeamSuccess(response.data));
  }
}

function* getStatsTeamDailyHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(getStatsTeamDaily, id, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsTeamError(error));
    } else {
      yield put(getStatsTeamError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsTeamDailySuccess(response.data));
  }
}

function* getStatsMeetingsHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(getStatsMeetings, id, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsMeetingsError(error));
    } else {
      yield put(getStatsMeetingsError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsMeetingsSuccess(response.data));
  }
}

function* getStatsMeetingsCountHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(getStatsMeetingsCount, id, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsMeetingsCountError(error));
    } else {
      yield put(getStatsMeetingsCountError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsMeetingsCountSuccess(response.data));
  }
}

function* getStatsMeetingsDailyHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(
    getStatsMeetingsDaily,
    id,
    params
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsMeetingsError(error));
    } else {
      yield put(getStatsMeetingsError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsMeetingsDailySuccess(response.data));
  }
}

function* getStatsMeetingsLiveHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(getStatsMeetingsLive, id, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsMeetingsLiveError(error));
    } else {
      yield put(getStatsMeetingsLiveError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsMeetingsLiveSuccess(response.data));
  }
}

function* getStatsInteractionsHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(
    getStatsInteractions,
    id,
    params
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsInteractionsError(error));
    } else {
      yield put(getStatsInteractionsError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsInteractionsSuccess(response.data));
  }
}

function* getStatsInteractionsDailyHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(
    getStatsInteractionsDaily,
    id,
    params
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsInteractionsError(error));
    } else {
      yield put(getStatsInteractionsError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsInteractionsDailySuccess(response.data));
  }
}

function* getStatsPresentationsHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(
    getStatsPresentations,
    id,
    params
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsPresentationsError(error));
    } else {
      yield put(getStatsPresentationsError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsPresentationsSuccess(response.data));
  }
}

function* getStatsPresentationsDailyHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(getStatsPresentationsDaily, id, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsPresentationsError(error));
    } else {
      yield put(getStatsPresentationsError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsPresentationsDailySuccess(response.data));
  }
}

function* getStatsSlidesHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(getStatsSlides, id, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsSlidesError(error));
    } else {
      yield put(getStatsSlidesError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsSlidesSuccess(response.data));
  }
}

function* getStatsSlidesDailyHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(getStatsSlidesDaily, id, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsSlidesError(error));
    } else {
      yield put(getStatsSlidesError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsSlidesDailySuccess(response.data));
  }
}

function* getStatsViewsHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(getStatsViews, id, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsViewsError(error));
    } else {
      yield put(getStatsViewsError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsViewsSuccess(response.data));
  }
}

function* getStatsParticipantsHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(
    getStatsParticipants,
    id,
    params
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsParticipantsError(error));
    } else {
      yield put(getStatsParticipantsError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsParticipantsSuccess(response.data));
  }
}

function* getStatsParticipantsDailyHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(
    getStatsParticipantsDaily,
    id,
    params
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsParticipantsError(error));
    } else {
      yield put(getStatsParticipantsError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsParticipantsDailySuccess(response.data));
  }
}

function* getStatsMembersHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(
    getStatsMembers,
    id,
    params
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsMembersError(error));
    } else {
      yield put(getStatsMembersError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsMembersSuccess(response.data));
  }
}

function* getStatsMembersDailyHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(
    getStatsMembersDaily,
    id,
    params
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsMembersError(error));
    } else {
      yield put(getStatsMembersError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsMembersDailySuccess(response.data));
  }
}

function* getStatsContactsHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(
    getStatsContacts,
    id,
    params
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsContactsError(error));
    } else {
      yield put(getStatsContactsError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsContactsSuccess(response.data));
  }
}

function* getStatsContactsDailyHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(
    getStatsContactsDaily,
    id,
    params
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsContactsError(error));
    } else {
      yield put(getStatsContactsError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsContactsDailySuccess(response.data));
  }
}

function* getStatsCompaniesHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(
    getStatsCompanies,
    id,
    params
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsCompaniesError(error));
    } else {
      yield put(getStatsCompaniesError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsCompaniesSuccess(response.data));
  }
}

function* getStatsCompaniesDailyHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(
    getStatsCompaniesDaily,
    id,
    params
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsCompaniesError(error));
    } else {
      yield put(getStatsCompaniesError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsCompaniesDailySuccess(response.data));
  }
}

function* getSharedLinksHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(getSharedLinks, id, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getSharedLinksError(error));
    } else {
      yield put(getSharedLinksError("An Error has occured!!"));
    }
  } else {
    yield put(getSharedLinksSuccess(response.data));
  }
}

function* getSharedLinksDailyHelper({ payload: { id, params }}: any) {
  const response: ResponseGenerator = yield call(
    getSharedLinksDaily,
    id,
    params
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getSharedLinksError(error));
    } else {
      yield put(getSharedLinksError("An Error has occured!!"));
    }
  } else {
    yield put(getSharedLinksDailySuccess(response.data));
  }
}

function* getStatsRoomSessionsHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(getStatsRoomSessions, id, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsRoomSessionsError(error));
    } else {
      yield put(getStatsRoomSessionsError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsRoomSessionsSuccess(response.data));
  }
}

function* getStatsRoomSessionsDailyHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(
    getStatsRoomSessionsDaily,
    id,
    params
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsRoomSessionsError(error));
    } else {
      yield put(getStatsRoomSessionsError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsRoomSessionsDailySuccess(response.data));
  }
}

function* getStatsRecordings({ payload: { id, filter } }: any) {
  const response: ResponseGenerator = yield call(getRecordingsService, id);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getStatsRecordingsError(error));
    } else {
      yield put(getStatsRecordingsError("An Error has occured!!"));
    }
  } else {
    yield put(getStatsRecordingsSuccess(response.data));
  }
}

function* deleteStatsRecordings({ payload: { id, callback } }: any) {
  const response: ResponseGenerator = yield call(deleteRecordingService, id);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteStatsRecordingsError(error));
    } else {
      yield put(deleteStatsRecordingsError("An Error has occured!!"));
    }
  } else {
    const msg = "Recording deleted successfully";
    yield put(deleteStatsRecordingsSuccess(msg));
    callback(msg);
  }
}

function* getTimeLineHelper({ payload: { accountId, sessionId } }: any) {
  const response: ResponseGenerator = yield call(getTimeLine, accountId, sessionId);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getTimeLineSuccess(error));
    } else {
      yield put(getTimeLineError("An Error has occured!!"));
    }
  } else {
    yield put(getTimeLineSuccess(response.data));
  }
}

function* getTimeLineCommentsHelper({ payload: { accountId, sessionId } }: any) {
  const response: ResponseGenerator = yield call(getTimeLineComments, accountId, sessionId);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getTimeLineCommentsError(error));
    } else {
      yield put(getTimeLineCommentsError("An Error has occured!!"));
    }
  } else {
    yield put(getTimeLineCommentsSuccess(response.data));
  }
}

function* deleteTimeLineCommentHelper({ payload: { commentId, resolve, reject } }: any) {
  const response: ResponseGenerator = yield call(deleteTimeLineComment, commentId, resolve, reject);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteTimeLineCommentError(error));
    } else {
      yield put(deleteTimeLineCommentError("An Error has occured!!"));
    }
  } else {
    yield put(deleteTimeLineCommentSuccess("Success"));
  }
}

function* deleteSessionHelper({ payload: { accountId, sessionId, resolve, reject } }: any) {
  const response: ResponseGenerator = yield call(deleteSession, accountId, sessionId, resolve, reject);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteSessionError(error));
    } else {
      yield put(deleteSessionError("An Error has occured!!"));
    }
  } else {
    yield put(deleteSessionSuccess(response.data));
  }
}

function* addTimeLineCommentHelper({ payload: { accountId, sessionId, comment, resolve, reject } }: any) {
  const response: ResponseGenerator = yield call(addTimeLineComment, accountId, sessionId, comment, resolve, reject);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(addTimeLineCommentError(error));
    } else {
      yield put(addTimeLineCommentError("An Error has occured!!"));
    }
  } else {
    yield put(addTimeLineCommentSuccess(response.data));
  }
}

function* getSnippetTimeLineHelper({ payload: { accountId, snippetUuid } }: any) {
  const response: ResponseGenerator = yield call(getSnippetTimeLine, accountId, snippetUuid);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getSnippetTimeLineSuccess(error));
    } else {
      yield put(getSnippetTimeLineError("An Error has occured!!"));
    }
  } else {
    yield put(getSnippetTimeLineSuccess(response.data));
  }
}

function* getMeetingAIAnalyticsHelper({ payload: { accountId, sessionId } }: any) {
  const response: ResponseGenerator = yield call(getMeetingAIAnalytics, accountId, sessionId);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getMeetingAIAnalyticsError(error));
    } else {
      yield put(getMeetingAIAnalyticsError("An Error has occured!!"));
    }
  } else {
    yield put(getMeetingAIAnalyticsSuccess(response.data));
  }
}

function* shareRecordingHelper({ payload }: any) {
  const response: ResponseGenerator = yield call(shareRecordingService, payload.data);

  if (_.has(response, "data")) {
    yield put(shareRecordingSuccess(response.data));
    payload.onSuccess(response.data);
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(shareRecordingError(error));
    } else {
      yield put(shareRecordingError("An Error has occured, please try again!"));
    }
  }
}

function* getRecordingShareLinksForRecordingHelper({ payload: { payload }}: any) {
  const response: ResponseGenerator = yield call(getRecordingShareLinksForRecording, payload);

  if (_.has(response, "data")) {
    yield put(getRecordingShareLinksForRecordingSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getRecordingShareLinksForRecordingError(error));
    } else {
      yield put(getRecordingShareLinksForRecordingError("An Error has occured, please try again!"));
    }
  }
}

function* getRecordingShareLinkByLinkHashHelper({ payload: { linkHash, onSuccess }}: any) {
  const response: ResponseGenerator = yield call(getRecordingShareLinkByLinkHash, linkHash);

  if (_.has(response, "data")) {
    yield put(getRecordingShareLinkByLinkHashSuccess(response.data));
    onSuccess(response.data);
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getRecordingShareLinkByLinkHashError(error));
    } else {
      yield put(getRecordingShareLinkByLinkHashError("An Error has occured, please try again!"));
    }
  }
}

function* updateRecordingShareLinkHelper({ payload: { id, data, onSuccess }}: any) {
  const response: ResponseGenerator = yield call(updateRecordingShareLink, id, data);

  if (_.has(response, "data")) {
    yield put(updateRecordingShareLinkSuccess(response.data));
    onSuccess(response.data);
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(updateRecordingShareLinkError(error));
    } else {
      yield put(updateRecordingShareLinkError("An Error has occured, please try again!"));
    }
  }
}

function* deleteRecordingShareLinkHelper({ payload: { data, onSuccess }}: any) {
  const response: ResponseGenerator = yield call(deleteRecordingShareLink, data);

  if (response.status === 200) {
    yield put(deleteRecordingShareLinkSuccess(response.data));
    onSuccess(response.data);
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteRecordingShareLinkError(error));
    } else {
      yield put(deleteRecordingShareLinkError("An Error has occured, please try again!"));
    }
  }
}

function* getRecordingSnippetsHelper({ payload: { payload }}: any) {
  const response: ResponseGenerator = yield call(getRecordingSnippets, payload);

  if (_.has(response, "data")) {
    yield put(getRecordingSnippetsSuccess(response.data));
  }
}

function* getSnippetHelper({ payload: { id, filter } }: any) {
  const response: ResponseGenerator = yield call(getSnippet, id);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getSnippetError(error));
    } else {
      yield put(getSnippetError("An Error has occured!!"));
    }
  } else {
    yield put(getSnippetSuccess(response.data));
  }
}
function* updateSnippetHelper({ payload: { id, data, onSuccess }}: any) {
  const response: ResponseGenerator = yield call(putSnippet, id, data);

  if (_.has(response, "data")) {
    yield put(updateSnippetSuccess('Success'));
    onSuccess(response.data);
    yield put(getRecordingSnippetsAction(data.recording_session_id))
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(updateSnippetError(error));
    } else {
      yield put(updateSnippetError("An Error has occured, please try again!"));
    }
  }
}

function* postRecordingSnippetHelper({ payload: { sessionId, data, onSuccess }}: any) {
  const response: ResponseGenerator = yield call(postRecordingSnippet, sessionId, data);

  if (_.has(response, "data")) {
    yield put(postRecordingSnippetSuccess('Success'));
    onSuccess(response.data);
  }
}

function* getSnippetShareLinkForSnippetHelper({ payload: { payload }}: any) {
  const response: ResponseGenerator = yield call(getSnippetShareLinksForSnippet, payload);

  if (_.has(response, "data")) {
    yield put(getSnippetShareLinksForSnippetSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getSnippetShareLinksForSnippetError(error));
    } else {
      yield put(getSnippetShareLinksForSnippetError("An Error has occured, please try again!"));
    }
  }
}

function* getSnippetShareLinkByLinkHashHelper({ payload: { linkHash, onSuccess }}: any) {
  const response: ResponseGenerator = yield call(getSnippetShareLinkByLinkHash, linkHash);

  if (_.has(response, "data")) {
    yield put(getSnippetShareLinkByLinkHashSuccess(response.data));
    onSuccess(response.data);
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getSnippetShareLinkByLinkHashError(error));
    } else {
      yield put(getSnippetShareLinkByLinkHashError("An Error has occured, please try again!"));
    }
  }
}

function* createSnippetShareLinkHelper({ payload: { data, onSuccess }}: any) {
  const response: ResponseGenerator = yield call(createSnippetShareLink, data);

  if (_.has(response, "data")) {
    yield put(createSnippetShareLinkSuccess(response.data));
    onSuccess(response.data);
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(createSnippetShareLinkError(error));
    } else {
      yield put(createSnippetShareLinkError("An Error has occured, please try again!"));
    }
  }
}

function* updateSnippetShareLinkHelper({ payload: { id, data, onSuccess }}: any) {
  const response: ResponseGenerator = yield call(updateSnippetShareLink, id, data);

  if (_.has(response, "data")) {
    yield put(updateSnippetShareLinkSuccess(response.data));
    onSuccess(response.data);
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(updateSnippetShareLinkError(error));
    } else {
      yield put(updateSnippetShareLinkError("An Error has occured, please try again!"));
    }
  }
}

function* deleteSnippetShareLinkHelper({ payload: { id, onSuccess }}: any) {
  const response: ResponseGenerator = yield call(deleteSnippetShareLink, id);

  if (_.has(response, "data")) {
    yield put(deleteSnippetShareLinkSuccess(response.data));
    onSuccess(response.data);
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteSnippetShareLinkError(error));
    } else {
      yield put(deleteSnippetShareLinkError("An Error has occured, please try again!"));
    }
  }
}

function* getEventLogsHelper(data: any) {
  const response: ResponseGenerator = yield call(getLogs, data);

  if (_.has(response.data, "data")) {
    yield put(getLogsSuccess(response.data.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getLogsError(error));
    } else {
      yield put(getLogsError("An Error has occured, please try again!"));
    }
  }
}

function* getUserProfileSetupLogsHelper({ payload: { userId }}: any) {
  const profileDetailsFinishedAction = "profile_details_finished";
  const profileDetailsActionType = "profile_details";

  const queryParams = {
    user_id: userId,
    actions: [profileDetailsFinishedAction],
    action_types: [profileDetailsActionType],
  };
  const response: ResponseGenerator = yield call(getLogs, queryParams);

  if (_.has(response.data, "data")) {
    const profileSetupFinished = _.some(response.data.data,
      (log: IEventLog) => log.action === profileDetailsFinishedAction && log.action_type === profileDetailsActionType
    );

    yield put(getUserProfileSetupFinishedSuccess(profileSetupFinished));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getUserProfileSetupFinishedError(error));
    } else {
      yield put(getUserProfileSetupFinishedError("An Error has occured, please try again!"));
    }
  }
}
function* getIntegrationSetupLogsHelper({ payload: { userId }}: any) {
  const integrationsSetupFinishedAction = "integrations_setup_finished";
  const integrationsSetupActionType = "integrations_setup";

  const queryParams = {
    user_id: userId,
    actions: [integrationsSetupFinishedAction],
    action_types: [integrationsSetupActionType],
  };
  const response: ResponseGenerator = yield call(getLogs, queryParams);

  if (_.has(response.data, "data")) {
    const profileSetupFinished = _.some(response.data.data,
      (log: IEventLog) => log.action === integrationsSetupFinishedAction && log.action_type === integrationsSetupActionType
    );

    yield put(getIntegrationSetupFinishedSuccess(profileSetupFinished));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getIntegrationSetupFinishedError(error));
    } else {
      yield put(getIntegrationSetupFinishedError("An Error has occured, please try again!"));
    }
  }
}

function* createEventLogHelper({ payload: { data, onSuccess }}: any) {
  const response: ResponseGenerator = yield call(createLog, data);

  if (_.has(response, "data")) {
    yield put(createLogSuccess("Log created successfully"));

    if (onSuccess) {
      onSuccess(response.data);
    }
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(createLogError(error));
    } else {
      yield put(createLogError("An Error has occured, please try again!"));
    }
  }
}

function* getRecordingTranscriptHelper({ payload: { sessionId, filters } }: any) {
  const response: ResponseGenerator = yield call(getRecordingTranscript, sessionId, filters);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getRecordingTranscriptError(error));
    } else {
      yield put(getRecordingTranscriptError("An Error has occured!!"));
    }
  } else {
    yield put(getRecordingTranscriptSuccess(response.data));
    yield put(setTranscript(response.data))
  }
}

function* getSnippetRecordingTranscriptHelper({ payload: { snippetUuid } }: any) {
  const response: ResponseGenerator = yield call(getSnippetRecordingTranscript, snippetUuid);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getSnippetRecordingTranscriptError(error));
    } else {
      yield put(getSnippetRecordingTranscriptError("An Error has occured!!"));
    }
  } else {
    yield put(getSnippetRecordingTranscriptSuccess(response.data));
  }
}

function* searchRecordingTranscriptsHelper({ payload: { accountId, search, params }}: any) {
  const response: ResponseGenerator = yield call(searchRecordingTranscripts, accountId, search, params);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(searchRecordingTranscriptsError(error));
    } else {
      yield put(searchRecordingTranscriptsError("An Error has occured!!"));
    }
  } else {
    yield put(searchRecordingTranscriptsSuccess(response.data));
  }
}

function* getAiCategoriesHelper() {
  const response: ResponseGenerator = yield call(getAiCategories);

  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getAiCategoriesError(error));
    } else {
      yield put(getAiCategoriesError("An Error has occured!!"));
    }
  } else {
    yield put(getAiCategoriesSuccess(response.data));
  }
}

function* getAllTagsByAccountHelper({ payload: { accountId } }: any) {
  const response: ResponseGenerator = yield call(
    getAllTags,
    accountId
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getRecordingTranscriptError(error));
    } else {
      yield put(getRecordingTranscriptError("An Error has occured!!"));
    }
  } else {
    yield put(getAllTagsByAccountSuccess(response.data));
  }
}

function* addRecordingTagHelper({
  payload: { name, sessionId, accountId },
}: any) {
  const response: ResponseGenerator = yield call(
    addRecordingTag,
    name,
    sessionId,
    accountId,
  );

  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getRecordingTranscriptError(error));
    } else {
      yield put(getRecordingTranscriptError("An Error has occured!!"));
    }
  } else {
    yield put(addRecordingTagSuccess(response.data))
    yield put({ type: 'GET_ALL_TAGS_BY_ACCOUNT', payload: { accountId } });
  }
}

function* deleteRecordingTagHelper({
  payload: { id, sessionId, accountId },
}: any) {
  const response: ResponseGenerator = yield call(
    deleteRecordingTag,
    id,
    sessionId,
    accountId
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getRecordingTranscriptError(error));
    } else {
      yield put(getRecordingTranscriptError("An Error has occured!!"));
    }
  } else {
    yield put(deleteRecordingTagSuccess(id))
  }
}

function* addSnippetTagHelper({
  payload: { name, snippetUuid, accountId },
}: any) {
  const response: ResponseGenerator = yield call(
    createSnippetTag,
    name,
    snippetUuid,
    accountId
  );

  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getSnippetTagsError(error));
    }
  } else {
    yield put(addSnippetTagSuccess(response.data))
    yield put({ type: 'GET_ALL_TAGS_BY_ACCOUNT', payload: { accountId } });
  }
}

function* deleteSnippetTagHelper({
  payload: { id, snippetUuid, accountId },
}: any) {
  const response: ResponseGenerator = yield call(
    removeSnippetTag,
    id,
    snippetUuid,
    accountId,
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getSnippetTagsError(error));
    }
  } else {
    yield put(deleteSnippetTagSuccess(id));
  }
}

function* createTagHelper({ payload } : ReturnType<typeof createTag>) {
  const response: ResponseGenerator = yield call(
    createTagByAccount,
    payload.accountId,
    payload.name,
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(createTagError(error));
    } else {
      yield put(createTagError("An Error has occured!!"));
    }
  } else {
    yield put(createTagSuccess(response.data));
  }
}

function* deleteTagHelper({ payload } : ReturnType<typeof deleteTag>) {
  const response: ResponseGenerator = yield call(
    deleteTagByAccount,
    payload.id,
    payload.accountId,
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteTagError(error));
    } else {
      yield put(deleteTagError("An Error has occured!!"));
    }
  } else {
    yield put(deleteTagSuccess(payload.id));
  }
}

function* editTagHelper({ payload } : ReturnType<typeof editTag>) {
  const response: ResponseGenerator = yield call(
    editTagByAccount,
    payload.id,
    payload.name,
    payload.accountId,
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(editTagError(error));
    } else {
      yield put(editTagError("An Error has occured!!"));
    }
  } else {
    yield put(editTagSuccess(response.data));
  }
}

function* getTagAnalyticsHelper({ payload: { accountId, filters } }: any) {
  const response: ResponseGenerator = yield call(fetchTagAnalytics, accountId, filters);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getTagAnalyticsError(error));
    } else {
      yield put(getTagAnalyticsError("An Error has occured!!"));
    }
  } else {
    yield put(getTagAnalyticsSuccess(response.data));
  }
}

export function* watchGetStatsParticipants() {
  yield takeEvery(GET_STATS_PARTICIPANTS, getStatsParticipantsHelper);
}

export function* watchGetStatsParticipantsDaily() {
  yield takeEvery(GET_STATS_PARTICIPANTS_DAILY, getStatsParticipantsDailyHelper);
}

export function* watchGetStatsMembers() {
  yield takeEvery(GET_STATS_MEMBERS, getStatsMembersHelper);
}

export function* watchGetStatsMembersDaily() {
  yield takeEvery(GET_STATS_MEMBERS_DAILY, getStatsMembersDailyHelper);
}

export function* watchGetStatsContacts() {
  yield takeEvery(GET_STATS_CONTACTS, getStatsContactsHelper);
}

export function* watchGetStatsContactsDaily() {
  yield takeEvery(GET_STATS_CONTACTS_DAILY, getStatsContactsDailyHelper);
}

export function* watchGetStatsCompanies() {
  yield takeEvery(GET_STATS_COMPANIES, getStatsCompaniesHelper);
}

export function* watchGetStatsCompaniesDaily() {
  yield takeEvery(GET_STATS_COMPANIES_DAILY, getStatsCompaniesDailyHelper);
}

export function* watchGetStatsViews() {
  yield takeEvery(GET_STATS_VIEWS, getStatsViewsHelper);
}

export function* watchGetStatsSlides() {
  yield takeEvery(GET_STATS_SLIDES, getStatsSlidesHelper);
}

export function* watchGetStatsSlidesDaily() {
  yield takeEvery(GET_STATS_SLIDES_DAILY, getStatsSlidesDailyHelper);
}

export function* watchGetStatsPresentations() {
  yield takeEvery(GET_STATS_PRESENTATIONS, getStatsPresentationsHelper);
}

export function* watchGetStatsPresentationsDaily() {
  yield takeEvery(GET_STATS_PRESENTATIONS_DAILY, getStatsPresentationsDailyHelper);
}

export function* watchGetStatsDashboard() {
  yield takeEvery(GET_STATS_DASHBOARD, getStatsDashboardHelper);
}

export function* watchGetStatsDashboardDaily() {
  yield takeEvery(GET_STATS_DASHBOARD_DAILY, getStatsDashboardDailyHelper);
}

export function* watchGetStatsUser() {
  yield takeEvery(GET_STATS_USER, getStatsUserHelper);
}

export function* watchGetStatsUserDaily() {
  yield takeEvery(GET_STATS_USER_DAILY, getStatsUserDailyHelper);
}

export function* watchGetStatsTeam() {
  yield takeEvery(GET_STATS_TEAM, getStatsTeamHelper);
}

export function* watchGetStatsTeamDaily() {
  yield takeEvery(GET_STATS_TEAM_DAILY, getStatsTeamDailyHelper);
}

export function* watchGetStatsMeetings() {
  yield takeEvery(GET_STATS_MEETINGS, getStatsMeetingsHelper);
}

export function* watchGetStatsMeetingsCount() {
  yield takeEvery(GET_STATS_MEETINGS_COUNT, getStatsMeetingsCountHelper);
}

export function* watchGetStatsMeetingsDaily() {
  yield takeEvery(GET_STATS_MEETINGS_DAILY, getStatsMeetingsDailyHelper);
}

export function* watchGetStatsMeetingsLive() {
  yield takeEvery(GET_STATS_MEETINGS_LIVE, getStatsMeetingsLiveHelper);
}

export function* watchGetStatsInteractions() {
  yield takeEvery(GET_STATS_INTERACTIONS, getStatsInteractionsHelper);
}

export function* watchGetStatsInteractionsDaily() {
  yield takeEvery(
    GET_STATS_INTERACTIONS_DAILY,
    getStatsInteractionsDailyHelper
  );
}

export function* watchGetStatsSharedLinksDailyHelper() {
  yield takeEvery(GET_STATS_SHARED_LINKS_DAILY, getSharedLinksDailyHelper);
}

export function* watchGetStatsSharedLinksHelper() {
  yield takeEvery(GET_STATS_SHARED_LINKS, getSharedLinksHelper);
}

export function* watchGetStatsRoomSessionsHelper() {
  yield takeEvery(GET_STATS_ROOM_SESSIONS, getStatsRoomSessionsHelper);
}

export function* watchGetStatsRoomSessionsDailyHelper() {
  yield takeEvery(GET_STATS_ROOM_SESSIONS_DAILY, getStatsRoomSessionsDailyHelper);
}

export function* watchGetStatsRecordingHelper() {
  yield takeEvery(GET_STATS_RECORDINGS, getStatsRecordings);
}

export function* watchDeleteStatsRecordingHelper() {
  yield takeEvery(DELETE_STATS_RECORDINGS, deleteStatsRecordings);
}

export function* watchGetTimeLine() {
  yield takeEvery(GET_TIME_LINE, getTimeLineHelper);
}

export function* watchGetTimeLineComments() {
  yield takeEvery(GET_TIME_LINE_COMMENTS, getTimeLineCommentsHelper);
}

export function* watchDeleteTimeLineComment() {
  yield takeEvery(DELETE_TIME_LINE_COMMENT, deleteTimeLineCommentHelper);
}
export function* watchGetSnippetTimeLine() {
  yield takeEvery(GET_SNIPPET_TIME_LINE, getSnippetTimeLineHelper)
}

export function* watchDeleteSession() {
  yield takeEvery(DELETE_SESSION, deleteSessionHelper);
}

export function* watchAddTimeLineComment() {
  yield takeEvery(ADD_TIME_LINE_COMMENT, addTimeLineCommentHelper);
}

export function* watchGetMeetingAIAnalytics() {
  yield takeEvery(GET_MEETING_AI_ANALYTICS, getMeetingAIAnalyticsHelper);
}

export function* watchShareRecording() {
  yield takeEvery(SHARE_RECORDING, shareRecordingHelper);
}

export function* watchGetRecordingShareLinksForRecording() {
  yield takeEvery(GET_RECORDING_SHARE_LINKS_FOR_RECORDING, getRecordingShareLinksForRecordingHelper);
}

export function* watchGetRecordingShareLinkByHash() {
  yield takeEvery(GET_RECORDING_SHARE_LINK_BY_LINK_HASH, getRecordingShareLinkByLinkHashHelper)
}

export function* watchUpdateRecordingShareLink() {
  yield takeEvery(UPDATE_RECORDING_SHARE_LINK, updateRecordingShareLinkHelper);
}

export function* watchDeleteRecordingShareLink() {
  yield takeEvery(DELETE_RECORDING_SHARE_LINK, deleteRecordingShareLinkHelper);
}

export function* watchGetRecordingSnippets() {
  yield takeEvery(GET_RECORDING_SNIPPETS, getRecordingSnippetsHelper);
}

export function* watchGetSnippet() {
  yield takeEvery(GET_SNIPPET, getSnippetHelper);
}

export function* watchPostRecordingSnippet() {
  yield takeEvery(POST_RECORDING_SNIPPET, postRecordingSnippetHelper);
}

export function* watchUpdateSnippet() {
  yield takeEvery(UPDATE_SNIPPET, updateSnippetHelper);
}

export function* watchGetSnippetShareLinksForSnippet() {
  yield takeEvery(GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET, getSnippetShareLinkForSnippetHelper);
}

export function* watchGetSnippetShareLinkByLinkHash() {
  yield takeEvery(GET_SNIPPET_SHARE_LINK_BY_LINK_HASH, getSnippetShareLinkByLinkHashHelper);
}

export function* watchCreateSnippetShareLink() {
  yield takeEvery(CREATE_SNIPPET_SHARE_LINK, createSnippetShareLinkHelper);
}

export function* watchUpdateSnippetShareLink() {
  yield takeEvery(UPDATE_SNIPPET_SHARE_LINK, updateSnippetShareLinkHelper);
}

export function* watchDeleteSnippetShareLink() {
  yield takeEvery(DELETE_SNIPPET_SHARE_LINK, deleteSnippetShareLinkHelper);
}

export function* watchGetEventLogs() {
  yield takeEvery(GET_LOGS, getEventLogsHelper);
}

export function* watchGetUserProfileSetupFinished() {
  yield takeEvery(GET_USER_PROFILE_SETUP_FINISHED, getUserProfileSetupLogsHelper);
}

export function* watchGetIntegrationSetupFinished() {
  yield takeEvery(GET_INTEGRATION_SETUP_FINISHED, getIntegrationSetupLogsHelper);
}

export function* watchCreateEventLogs() {
  yield takeEvery(CREATE_LOG, createEventLogHelper);
}

export function* watchGetRecordingTranscript() {
  yield takeEvery(GET_RECORDING_TRANSCRIPT, getRecordingTranscriptHelper);
}

export function* watchGetSnippetRecordingTranscript() {
  yield takeEvery(GET_SNIPPET_RECORDING_TRANSCRIPT, getSnippetRecordingTranscriptHelper);
}
export function* watchSearchRecordingTranscripts() {
  yield takeEvery(SEARCH_RECORDING_TRANSCRIPTS, searchRecordingTranscriptsHelper);
}

export function* watchGetAiCategories() {
  yield takeEvery(GET_AI_CATEGORIES, getAiCategoriesHelper);
}

export function* watchGetAllTagsByAccount() {
  yield takeEvery(GET_ALL_TAGS_BY_ACCOUNT, getAllTagsByAccountHelper);
}

export function* watchAddRecordingTag() {
  yield takeEvery(ADD_RECORDING_TAGS, addRecordingTagHelper);
}

export function* watchDeleteRecordingTag() {
  yield takeEvery(DELETE_RECORDING_TAGS, deleteRecordingTagHelper);
}

export function* watchAddSnippetTag () {
  yield takeEvery(ADD_SNIPPET_TAGS, addSnippetTagHelper);
}

export function* watchDeleteSnippetTag() {
  yield takeEvery(DELETE_SNIPPET_TAG, deleteSnippetTagHelper);
}

export function* watchCreateTagHelper(){
  yield takeEvery(CREATE_TAG, createTagHelper);
}

export function* watchDeleteTagHelper(){
  yield takeEvery(DELETE_TAG, deleteTagHelper);
}

export function* watchEditTagHelper(){
  yield takeEvery(EDIT_TAG, editTagHelper);
}

export function* watchGetTagAnalytics() {
  yield takeEvery(GET_TAG_ANALYTICS, getTagAnalyticsHelper);
}

function* getAnalyticsSaga() {
  yield all([
    fork(watchGetStatsUser),
    fork(watchGetStatsUserDaily),
    fork(watchGetStatsTeam),
    fork(watchGetStatsTeamDaily),
    fork(watchGetStatsMeetings),
    fork(watchGetStatsMeetingsCount),
    fork(watchGetStatsMeetingsDaily),
    fork(watchGetStatsMeetingsLive),
    fork(watchGetStatsInteractions),
    fork(watchGetStatsInteractionsDaily),
    fork(watchGetStatsPresentations),
    fork(watchGetStatsPresentationsDaily),
    fork(watchGetStatsSlides),
    fork(watchGetStatsSlidesDaily),
    fork(watchGetStatsParticipants),
    fork(watchGetStatsParticipantsDaily),
    fork(watchGetStatsMembers),
    fork(watchGetStatsMembersDaily),
    fork(watchGetStatsContacts),
    fork(watchGetStatsContactsDaily),
    fork(watchGetStatsCompanies),
    fork(watchGetStatsCompaniesDaily),
    fork(watchGetStatsSharedLinksHelper),
    fork(watchGetStatsSharedLinksDailyHelper),
    fork(watchGetStatsRoomSessionsHelper),
    fork(watchGetStatsRoomSessionsDailyHelper),
    fork(watchGetStatsRecordingHelper),
    fork(watchDeleteStatsRecordingHelper),
    fork(watchGetStatsViews),
    fork(watchGetStatsDashboard),
    fork(watchGetStatsDashboardDaily),
    fork(watchGetTimeLine),
    fork(watchGetTimeLineComments),
    fork(watchDeleteSession),
    fork(watchAddTimeLineComment),
    fork(watchDeleteTimeLineComment),
    fork(watchGetSnippetTimeLine),
    fork(watchGetMeetingAIAnalytics),
    fork(watchShareRecording),
    fork(watchGetRecordingShareLinksForRecording),
    fork(watchGetRecordingShareLinkByHash),
    fork(watchUpdateRecordingShareLink),
    fork(watchDeleteRecordingShareLink),
    fork(watchGetRecordingSnippets),
    fork(watchGetSnippet),
    fork(watchPostRecordingSnippet),
    fork(watchUpdateSnippet),
    fork(watchGetSnippetShareLinksForSnippet),
    fork(watchGetSnippetShareLinkByLinkHash),
    fork(watchCreateSnippetShareLink),
    fork(watchUpdateSnippetShareLink),
    fork(watchDeleteSnippetShareLink),
    fork(watchGetEventLogs),
    fork(watchGetUserProfileSetupFinished),
    fork(watchGetIntegrationSetupFinished),
    fork(watchCreateEventLogs),
    fork(watchGetRecordingTranscript),
    fork(watchGetSnippetRecordingTranscript),
    fork(watchSearchRecordingTranscripts),
    fork(watchGetAiCategories),
    fork(watchGetAllTagsByAccount),
    fork(watchAddRecordingTag),
    fork(watchDeleteRecordingTag),
    fork(watchAddSnippetTag),
    fork(watchDeleteSnippetTag),
    fork(watchCreateTagHelper),
    fork(watchDeleteTagHelper),
    fork(watchEditTagHelper),
    fork(watchGetTagAnalytics),
  ]);
}

export default getAnalyticsSaga;
