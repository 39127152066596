import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { has } from "lodash";

import {
  getGlobalFilters as getGlobalFiltersAction,
  getGlobalFiltersSuccess,
  getGlobalFiltersError,
} from "./actions";
import { GET_GLOBAL_FILTERS } from "./actionTypes";
import { ResponseGenerator, getGlobalFilters } from "../../services/userservice";

function* getGlobalFiltersHelper({
  payload,
}: ReturnType<typeof getGlobalFiltersAction>) {
  const response: ResponseGenerator = yield call(getGlobalFilters, payload.accountId);

  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getGlobalFiltersError(error));
    } else {
      yield put(getGlobalFiltersError("An error has occurred!"));
    }
  } else {
    yield put(getGlobalFiltersSuccess(response.data));
  }
}

export function* watchGetGlobalFilters() {
  yield takeEvery(GET_GLOBAL_FILTERS, getGlobalFiltersHelper);
}

function* GlobalFilterSaga() {
  yield all([fork(watchGetGlobalFilters)]);
}

export default GlobalFilterSaga;
