import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Select from "react-select";

import { IMeetingType } from "../../../store/interface";
import { ModalFooter } from "reactstrap";
import { DropdownOption } from "../../Home/utils/constants";

interface EditRecordingModalProps {
  isOpen: boolean;
  meetingTypes: IMeetingType[];
  closeModal: () => void;
  onSave: (selectedMeetingType: DropdownOption | null) => void;
}

interface EditRecordingModalState {
  meetingTypeSelectedOptions: DropdownOption | null;
}

class EditRecordingModal extends Component<
  EditRecordingModalProps,
  EditRecordingModalState
> {
  constructor(props: EditRecordingModalProps) {
    super(props);
    this.state = {
      meetingTypeSelectedOptions: null,
    };
  }

  handleMeetingTypeOptions = (
    selectedOption: DropdownOption | null,
    actionMeta: any
  ) => {
    this.setState({ meetingTypeSelectedOptions: selectedOption });
  };

  handleSave = () => {
    const { onSave } = this.props;
    const { meetingTypeSelectedOptions } = this.state;

    onSave(meetingTypeSelectedOptions)
  }

  render() {
    const { isOpen, meetingTypes, closeModal } = this.props;
    const { meetingTypeSelectedOptions } = this.state;

    const emptyOption: DropdownOption = { label: "", value: "", id: "" };

    const meetingTypeOptions: DropdownOption[] = [emptyOption].concat(
      (meetingTypes || []).map((el: any) => {
        return {
          label: el.name,
          value: el.id,
          id: el.id,
        };
      })
    );

    return (
      <>
        <Modal isOpen={isOpen} size="md" centered>
          <ModalHeader>
            Edit meeting type
            <button
              onClick={closeModal}
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              className="close-button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody>
            <Select
              value={meetingTypeSelectedOptions}
              onChange={this.handleMeetingTypeOptions}
              options={meetingTypeOptions}
            />
          </ModalBody>
          <ModalFooter><button className="btn btn-primary btn-sm" onClick={this.handleSave}>Save</button></ModalFooter>
        </Modal>
      </>
    );
  }
}

export default EditRecordingModal;
