import {
  GET_ROOMS,
  GET_ROOMS_ERROR,
  GET_ROOMS_SUCCESS,
  CREATE_NEW_ROOM,
  CREATE_ROOM_SUCCESS,
  EDIT_ROOM,
  EDIT_ROOM_SUCCESS,
  DELETE_ROOM,
  DELETE_ROOM_SUCCESS,
  API_ERROR,
  DUPLICATE_ROOM,
  DUPLICATE_ROOM_SUCCESS,
  DUPLICATE_ROOM_ERROR,
  APPEND_DUPLICATE_ROOM,
  CREATE_ROOM_FROM_TEMPLATE,
  SET_ROOM_SEARCH_TEXT,
  SET_UPDATED_ROOM,
  SET_DELETED_ROOM
} from "./actionTypes";
import { RoomsAction, RoomState } from "./interface";

const initialState: RoomState = {
  success: "",
  error: [],
  loader: false,
  deleteLoader: false,
  rooms: [],
  isTemplate: false,
  duplicatedRoom: null,
  searchText: '',
  deletedRoomId: '',
  updatedRoom: null
};

const Rooms = (state = initialState, action: RoomsAction) => {
  switch (action.type) {
    case GET_ROOMS:
      state = {
        ...state,
        success: "",
        error: [],
        loader: true,
        rooms: [],
      };
      break;
    case GET_ROOMS_SUCCESS:
      state = {
        ...state,
        rooms: action.payload,
        loader: false,
      };
      break;
    case GET_ROOMS_ERROR:
      state = { ...state, error: action.payload, loader: false };
      break;
    case CREATE_NEW_ROOM:
      state = {
        ...state,
        success: "",
        error: [],
        loader: true,
      };
      break;
    case CREATE_ROOM_SUCCESS:
      state = { ...state, success: action.payload, loader: false };
      break;
    case EDIT_ROOM:
      state = {
        ...state,
        success: "",
        error: [],
        loader: true,
      };
      break;
    case EDIT_ROOM_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loader: false,
      };
      break;
    case DELETE_ROOM:
      state = {
        ...state,
        success: "",
        error: [],
        deleteLoader: true,
      };
      break;
    case DELETE_ROOM_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        deleteLoader: false,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        deleteLoader: false,
        loader: false,
      };
      break;
    case DUPLICATE_ROOM:
      state = {
        ...state,
        success: "",
        error: [],
        loader: true,
      };
      break;
    case DUPLICATE_ROOM_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        deleteLoader: false,
      };
      break;
    case DUPLICATE_ROOM_ERROR:
      state = {
        ...state,
        success: action.payload,
        deleteLoader: false,
      };
      break;
    case APPEND_DUPLICATE_ROOM:
      state = {
        ...state,
        isTemplate: action.payload.isTemplate,
        duplicatedRoom: action.payload.data
      };
      break;
    case CREATE_ROOM_FROM_TEMPLATE:
      state = {
        ...state,
        success: "",
        error: [],
        loader: true,
      };
      break;
    case SET_ROOM_SEARCH_TEXT:
      state = { ...state, searchText: action.payload };
      break;
    case SET_UPDATED_ROOM:
      state = { ...state, updatedRoom: action.payload }
      break;
    case SET_DELETED_ROOM:
      state = { ...state, deletedRoomId: action.payload }
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Rooms;
