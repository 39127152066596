import axios, { AxiosResponse } from "axios";
import { urls } from "../../utilities/urls";
import { endpoints } from "../../utilities/endpoints";
import { commonheaders } from "../../utilities/apiConfig";
import { IAnalyticsKeywordsData, IKeywordsGroup } from "../../pages/Settings/Analytics/IAnalyticTypes";

export async function getAnalyticsKeywords(accountId: String): Promise<AxiosResponse<IAnalyticsKeywordsData[]>> {
  const response = await axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.analytics}/keywords`,
      {
        // @ts-ignore
        headers: commonheaders,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export async function saveAnalyticKeywords(
  accountId: String,
  keyword: { keyword: string; phrases: string; use_translation: boolean; keyword_group_ids: string[] }
) {
  const response = await axios
    .post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.analytics}/keywords`,
      { ...keyword },
      {
        // @ts-ignore
        headers: commonheaders,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export async function updateAnalyticKeywords(accountId: String, keyword: any)
{
  const response = await axios
    .put(`${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.analytics}/keywords/${keyword.id}`,
      {
        keyword: keyword.keyword,
        phrases: keyword.phrases,
        use_translation: keyword.use_translation,
        match_whole_word: keyword.match_whole_word,
        keyword_group_ids: keyword.keyword_group_ids
      },
      {
        // @ts-ignore
        headers: commonheaders,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
    
  return response;
}

export async function deleteKeyword(accountId: String, keywordId: number) {
  const response = await axios
    .delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.analytics}/keywords/${keywordId}`,
      {
        // @ts-ignore
        headers: commonheaders,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export async function getExcludedProducts(accountId: String) {
  const response = await axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.analytics}/excluded`,
      {
        // @ts-ignore
        headers: commonheaders,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export async function removeValueFromExcludeTable(accountId: String, valueId : string) {
  const response = await axios
    .delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.analytics}/excluded/${valueId}`,
      {
        // @ts-ignore
        headers: commonheaders,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export async function getUnexpectedAndExpectedValues(accountId: String) {
  const response = await axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.analytics}/change-word`,
      {
        // @ts-ignore
        headers: commonheaders,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export async function removeUnexpectedAndExpectedWord(accountId: String, wordId : string) {
  const response = await axios
    .delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.analytics}/change-word/${wordId}`,
      {
        // @ts-ignore
        headers: commonheaders,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export async function saveChangedWord(accountId: String, unexpectedWord : string, expectedWord: string) {
  const response = await axios
    .post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.analytics}/change-word`,
      {
        "unexpectedWord": unexpectedWord,
        "expectedWord": expectedWord,
      },
      {
        // @ts-ignore
        headers: commonheaders
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export async function getKeywordsGroup(accountId: String): Promise<IKeywordsGroup[]> {
  try {
    const response = await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.keywordGroup}`,
      {
        // @ts-ignore
        headers: commonheaders,
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function createKeywordsGroup(accountId: String, name: string): Promise<IKeywordsGroup> {
  try {
    const response = await axios.post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.keywordGroup}`,
      { name },
      {
        // @ts-ignore
        headers: commonheaders,
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      throw error;
    }
  }
}

export async function deleteKeywordsGroup(accountId: String, id: string): Promise<{ error?: string }> {
  try {
    const response = await axios.delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.keywordGroup}/${id}`,
      {
        // @ts-ignore
        headers: commonheaders,
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      throw error;
    }
  }
}

export async function updateKeywordsGroup(accountId: String, id: string, name: string): Promise<void> {
  try {
    const response = await axios.put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.keywordGroup}/${id}`,
      { name },
      {
        // @ts-ignore
        headers: commonheaders,
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      throw error;
    }
  }
}

export async function getKeywordsWithLanguages(accountId: String): Promise<void> {
  const response = await axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.analytics}/keywords-language`,
      {
        // @ts-ignore
        headers: commonheaders,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}
