import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { GET_FILES, DELETE_FILE, EDIT_FILE } from "./actionTypes";
import { getFilesSuccess, deleteFileSuccess, editFileSuccess, filesApiError } from "./actions";

//services
import { getFilesByUser, deleteFile, editFile, ResponseGenerator } from "../../services/userservice";
import _ from "lodash";

function* getFilesHelper({ payload: { filter } }: any) {
  const response: ResponseGenerator = yield call(getFilesByUser, filter);
  if (_.has(response, "data")) {
    yield put(getFilesSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(filesApiError(error));
    } else {
      yield put(filesApiError("An error has occured"));
    }
  }
}

function* deleteFileHelper({ payload: { id } }: any) {
  const response: ResponseGenerator = yield call(deleteFile, id);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(filesApiError(error));
    } else {
      yield put(filesApiError("An error has occured"));
    }
  } else {
    yield put(deleteFileSuccess("File deleted successfully!", id));
  }
}

function* editFileHelper({ payload: { id, data } }: any) {
  const response: ResponseGenerator = yield call(editFile, id, data);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(filesApiError(error));
    } else {
      yield put(filesApiError("An error has occured"));
    }
  } else {
    yield put(editFileSuccess("File updated successfully!"));
  }
}

export function* watchGetFiles() {
  yield takeEvery(GET_FILES, getFilesHelper);
}

export function* watchDeleteFile() {
  yield takeEvery(DELETE_FILE, deleteFileHelper);
}

export function* watchEditFile() {
  yield takeEvery(EDIT_FILE, editFileHelper);
}

function* filesSaga() {
  yield all([fork(watchGetFiles), fork(watchDeleteFile), fork(watchEditFile)]);
}

export default filesSaga;
