import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { has } from 'lodash';

import {
  getHitrateTokenError,
  getHitrateTokenSuccess,
  createHitrateToken as createHitrateTokenAction,
  createHitrateTokenError,
  createHitrateTokenSuccess,
  editHitrateToken as editHitrateTokenAction,
  editHitrateTokenError,
  editHitrateTokenSuccess,
  deleteHitrateToken as deleteHitrateTokenAction,
  deleteHitrateTokenError,
  deleteHitrateTokenSuccess,
} from "./actions";

import {
  getHitrateToken,
  createHitrateToken,
  editHitrateToken,
  deleteHitrateToken,
} from '../../../services/settings/HitrateIntegration';

import { ResponseGenerator } from "../../../services/userservice";
import { IHitrateToken } from "./interface";
import {
  GET_HITRATE_TOKEN,
  CREATE_HITRATE_TOKEN,
  EDIT_HITRATE_TOKEN,
  DELETE_HITRATE_TOKEN,
} from "./actionTypes";


function* getHitrateTokenHelper() {
  const response: ResponseGenerator = yield call(getHitrateToken);
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getHitrateTokenError(error));
    } else {
      yield put(getHitrateTokenError('An error has occurred!!'));
    }
  } else {
    yield put(getHitrateTokenSuccess(response as unknown as IHitrateToken));
  }
}

function* createHitrateTokenHelper({
 payload,
}: ReturnType<typeof createHitrateTokenAction>) {
  const response: ResponseGenerator = yield call(
    createHitrateToken,
    payload,
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(createHitrateTokenError(error));
    } else {
      yield put(createHitrateTokenError('An error has occurred!!'));
    }
  } else {
    yield put(createHitrateTokenSuccess(response as unknown as IHitrateToken));
  }
}

function* editHitrateTokenHelper({
 payload,
}: ReturnType<typeof editHitrateTokenAction>) {
  const response: ResponseGenerator = yield call(
    editHitrateToken,
    payload,
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(editHitrateTokenError(error));
    } else {
      yield put(editHitrateTokenError('An error has occurred!!'));
    }
  } else {
    yield put(editHitrateTokenSuccess(response as unknown as IHitrateToken));
  }
}

function* deleteHitrateTokenHelper({
  payload,
}: ReturnType<typeof deleteHitrateTokenAction>) {
  const response: ResponseGenerator = yield call(deleteHitrateToken, payload);

  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteHitrateTokenError(error));
    } else {
      yield put(deleteHitrateTokenError('An error has occurred!!'));
    }
  } else {
    yield put(deleteHitrateTokenSuccess());
  }
}

export function* watchGetHitrateTokenHelper() {
  yield takeEvery(GET_HITRATE_TOKEN, getHitrateTokenHelper);
}

export function* watchCreateHitrateTokenHelper() {
  yield takeEvery(CREATE_HITRATE_TOKEN, createHitrateTokenHelper);
}

export function* watchEditHitrateTokenHelper() {
  yield takeEvery(EDIT_HITRATE_TOKEN, editHitrateTokenHelper);
}

export function* watchDeleteHitrateTokenHelper() {
  yield takeEvery(DELETE_HITRATE_TOKEN, deleteHitrateTokenHelper);
}

function* HitrateTokenSaga() {
  yield all([fork(watchGetHitrateTokenHelper)]);
  yield all([fork(watchCreateHitrateTokenHelper)]);
  yield all([fork(watchEditHitrateTokenHelper)]);
  yield all([fork(watchDeleteHitrateTokenHelper)]);
}

export default HitrateTokenSaga;
