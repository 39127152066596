import {
  GET_FONTS,
  GET_FONTS_SUCCESS,
  API_ERROR,
  UPLOAD_FONTS,
  UPLOAD_FONTS_SUCCESS,
  EDIT_FONTS,
  EDIT_FONTS_SUCCESS,
} from "./actionTypes";

export const getFonts = (id: String) => {
  return {
    type: GET_FONTS,
    payload: { id },
  };
};

export const getFontsSuccess = (mes: String) => {
  return {
    type: GET_FONTS_SUCCESS,
    payload: mes,
  };
};

export const fontsApiError = (mes: String) => {
  return {
    type: API_ERROR,
    payload: mes,
  };
};

export const uploadFonts = (id: String, fileObj: Object) => {
  return {
    type: UPLOAD_FONTS,
    payload: { id, fileObj },
  };
};

export const uploadFontsSuccess = (mes: String) => {
  return {
    type: UPLOAD_FONTS_SUCCESS,
    payload: mes,
  };
};

export const editFonts = (id: String, params: Object, fontId: String) => {
  return {
    type: EDIT_FONTS,
    payload: { id, params, fontId },
  };
};

export const editFontsSuccess = (mes: String) => {
  return {
    type: EDIT_FONTS_SUCCESS,
    payload: mes,
  };
};
