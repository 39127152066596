import React, { Component } from 'react';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, TabContent, TabPane } from 'reactstrap';

import { navElements } from './constants';
import CommentsPage from '../Comments/CommentsPage';
import SnippetsPage from '../Snippets/SnippetsPage';
import SharedLinksPage from '../SharedLinks/SharedLinksPage';
import SharedWithMePage from '../SharedWithMe/SharedWithMePage';
import RecordingsPage from '../Recordings/RecordingsPage';
import SubNavbar from '../../components/RecordingsDetails/SubNavbar';
import { Icurrentaccountselected } from '../../store/auth/profile/interface';
import GlobalFilters from '../../components/GlobalFilters';
import { RECORDING } from '../../components/GlobalFilters/utils/constants';
import { FilterObject } from '../../store/GlobalFilters/interface';
import { createFilterString } from '../../components/GlobalFilters/utils/helperfunctions';

interface RecordingsDetailsNavigationProps {
  currentaccountselected: Icurrentaccountselected;
  recordingFilters: FilterObject;
  location: Location;
  history: History;
}

interface RecordingsDetailsNavigationState {
  activeTab: string;
  activeTabName: string;
  tabNames: string[];
}

class RecordingsDetailsNavigation extends Component<
  RecordingsDetailsNavigationProps,
  RecordingsDetailsNavigationState
> {
  constructor(props: RecordingsDetailsNavigationProps) {
    super(props);
    this.state = {
      activeTab: '1',
      activeTabName: '',
      tabNames: ['', 'Snippets', 'Comments', 'Share-links', 'Shared-with-me'],
    };
  }

  componentDidMount() {
    const path = this.props.location.pathname.split('/')[2];
    if (!path) {
      this.setState({
        activeTab: '1',
        activeTabName: '',
      });
    } else if (path !== this.state.tabNames[0].toLowerCase()) {
      const indexfound = this.state.tabNames.findIndex(
        (t) => t.toLowerCase() === path
      );
      this.setState({
        activeTab: (indexfound + 1).toString(),
        activeTabName: this.state.tabNames[indexfound],
      });
    }
  }

  componentDidUpdate(prevProps: RecordingsDetailsNavigationProps) {
    const { location } = this.props;
    const { tabNames } = this.state;

    const activeTab = this.getActiveTab(location);
    const prevPropsActiveTab = this.getActiveTab(prevProps.location);

    if (activeTab !== prevPropsActiveTab) {
      const indexfound = tabNames.findIndex(
        (t) => t.toLowerCase() === activeTab
      );

      if (activeTab) {
        this.setState({
          activeTab: (indexfound + 1).toString(),
          activeTabName: tabNames[indexfound],
        });
      } else {
        this.setState({
          activeTab: '1',
          activeTabName: '',
        });
      }
    }
  }

  getActiveTab = (location: Location) => {
    return location.pathname.split('/')[2];
  };

  handleToggle = (tab: string, path: string) => {
    const { activeTab } = this.state;

    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });

      this.props.history.push(path);
    }
  };

  render() {
    const { activeTab } = this.state;
    const { recordingFilters } = this.props;

    const globalFilterString = createFilterString(recordingFilters);

    return (
      <div className='page-content meeting-page'>
        <Col>
          <SubNavbar
            handleOnClick={this.handleToggle}
            navElements={navElements}
            activeTab={activeTab}
          />
          <div className='meeting-header filters'>
            <div
              className='meeting-header__actions'
              style={{ marginLeft: '16px' }}
            >
              <GlobalFilters type={RECORDING} />
            </div>
          </div>
        </Col>
        <div>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={navElements.recordings.tab}>
              <RecordingsPage globalFilterString={globalFilterString} />
            </TabPane>
            <TabPane tabId={navElements.snippets.tab}>
              {activeTab === navElements.snippets.tab && (
                <SnippetsPage globalFilterString={globalFilterString} />
              )}
            </TabPane>
            <TabPane tabId={navElements.comments.tab}>
              {activeTab === navElements.comments.tab && (
                <CommentsPage globalFilterString={globalFilterString} />
              )}
            </TabPane>
            <TabPane tabId={navElements.shareLinks.tab}>
              {activeTab === navElements.shareLinks.tab && (
                <SharedLinksPage globalFilterString={globalFilterString} />
              )}
            </TabPane>
            <TabPane tabId={navElements.sharedWithMe.tab}>
              {activeTab === navElements.sharedWithMe.tab && (
                <SharedWithMePage globalFilterString={globalFilterString} />
              )}
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentaccountselected } = state.Profile;
  const { recordingFilters } = state.globalFilters;

  return {
    currentaccountselected,
    recordingFilters,
  };
};

export default withRouter(
  connect(mapStateToProps)(RecordingsDetailsNavigation)
);
