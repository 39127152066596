import { IAdversusRule, ICreateAdversusRule } from '../../pages/Settings/CallImport/utils/constants';
import {
  CREATE_ADVERSUS_RULE,
  CREATE_ADVERSUS_RULE_ERROR,
  CREATE_ADVERSUS_RULE_SUCCESS,
  CREATE_CALL_TOKEN_SETTINGS,
  CREATE_CALL_TOKEN_SETTINGS_ERROR,
  CREATE_CALL_TOKEN_SETTINGS_SUCCESS,
  DELETE_ADVERSUS_RULE,
  DELETE_ADVERSUS_RULE_ERROR,
  DELETE_ADVERSUS_RULE_SUCCESS,
  DELETE_CALL_TOKEN_SETTINGS,
  DELETE_CALL_TOKEN_SETTINGS_ERROR,
  DELETE_CALL_TOKEN_SETTINGS_SUCCESS,
  EDIT_ADVERSUS_RULE,
  EDIT_ADVERSUS_RULE_ERROR,
  EDIT_ADVERSUS_RULE_SUCCESS,
  GET_ADVERSUS_RULES,
  GET_ADVERSUS_RULES_ERROR,
  GET_ADVERSUS_RULES_SUCCESS,
  GET_CALL_TOKEN,
  GET_CALL_TOKEN_ERROR,
  GET_CALL_TOKEN_SETTINGS,
  GET_CALL_TOKEN_SETTINGS_ERROR,
  GET_CALL_TOKEN_SETTINGS_SUCCESS,
  GET_CALL_TOKEN_SUCCESS,
  UPDATE_CALL_TOKEN_SETTINGS,
  UPDATE_CALL_TOKEN_SETTINGS_ERROR,
  UPDATE_CALL_TOKEN_SETTINGS_SUCCESS
} from "./actionTypes";

export enum CallTokenType {
  Adversus = 'adverus',
  S2 = 's2',
}

export interface ICallImportToken {
  id: string;
  type: CallTokenType;
  key: string;
  import_calls?: boolean;
  createdAt?: string;
  account?: string;
  secret?: string;
}

export interface ICreateCallImportToken {
  id?: string;
  token_type?: CallTokenType;
  import_calls?: boolean;
  key?: string;
  secret?: string;
}

export interface ICallImportSettings {
  id?: string,
  createdAt?: string,
  tokenId?: string,
  tokenType?: CallTokenType,
  projectId: string,
  campaignId: string,
  count30Days?: number,
  lastImport?: string,
  minLength: number,
}

export interface ICreateCallImportSettings {
  label?: string,
  tokenId: string,
  tokenType?: CallTokenType,
  projectId?: string,
  campaignId?: string,
  minLength?: number,
}

export interface CallImportState {
  tokens: ICallImportToken[];
  isTokensLoading: boolean;
  tokenError: string | Object;

  tokenSettings: ICallImportSettings[];
  isTokenSettingsLoading: boolean;
  tokenSettingsError: string | Object;

  createdTokenSettings: ICallImportSettings | null;
  createTokenSettingError: string | Object;
  isCreatingTokenLoader: boolean;

  deleteTokenSettingsError: string | Object;
  isDeletingTokenLoader: boolean;

  updateTokenSettingsError: string | Object;
  isUpdatingTokenLoader: boolean;

  adversusRules: IAdversusRule[];
  adversusRulesLoading: boolean;
  adversusRulesError: string | Object;
}

export type getCallTokens = {
  type: typeof GET_CALL_TOKEN,
  payload: { accountId: String },
}

export type getCallTokensSuccess = {
  type: typeof GET_CALL_TOKEN_SUCCESS,
  payload: ICallImportToken[],
}

export type getCallTokensError = {
  type: typeof GET_CALL_TOKEN_ERROR,
  payload: string | Object,
}

export type getCallTokenSettings = {
  type: typeof GET_CALL_TOKEN_SETTINGS,
  payload: { accountId: String },
}

export type getCallTokenSettingsSuccess = {
  type: typeof GET_CALL_TOKEN_SETTINGS_SUCCESS,
  payload: ICallImportSettings[],
}

export type getCallTokenSettingsError = {
  type: typeof GET_CALL_TOKEN_SETTINGS_ERROR,
  payload: string | Object,
}

export type createCallTokenSettings = {
  type: typeof CREATE_CALL_TOKEN_SETTINGS,
  payload: { accountId: String, tokenSettings: ICallImportSettings },
}

export type createCallTokenSettingsSuccess = {
  type: typeof CREATE_CALL_TOKEN_SETTINGS_SUCCESS,
  payload: ICallImportSettings,
}

export type createCallTokenSettingsError = {
  type: typeof CREATE_CALL_TOKEN_SETTINGS_ERROR,
  payload: string | Object,
}

export type deleteCallTokenSettings = {
  type: typeof DELETE_CALL_TOKEN_SETTINGS,
  payload: { accountId: String, tokenSettingsId: string },
}

export type deleteCallTokenSettingsSuccess = {
  type: typeof DELETE_CALL_TOKEN_SETTINGS_SUCCESS,
  payload: null,
}

export type deleteCallTokenSettingsError = {
  type: typeof DELETE_CALL_TOKEN_SETTINGS_ERROR,
  payload: string | Object,
}

export type updateCallTokenSettings = {
  type: typeof UPDATE_CALL_TOKEN_SETTINGS,
  payload: { accountId: String, tokenSettings: ICallImportSettings },
}

export type updateCallTokenSettingsSuccess = {
  type: typeof UPDATE_CALL_TOKEN_SETTINGS_SUCCESS,
  payload: ICallImportSettings,
}

export type updateCallTokenSettingsError = {
  type: typeof UPDATE_CALL_TOKEN_SETTINGS_ERROR,
  payload: string | Object,
}

export type getAdversusRules = {
  type: typeof GET_ADVERSUS_RULES;
  payload: String;
};

export type getAdversusRulesSuccess = {
  type: typeof GET_ADVERSUS_RULES_SUCCESS;
  payload: IAdversusRule[];
};

export type getAdversusRulesError = {
  type: typeof GET_ADVERSUS_RULES_ERROR;
  payload: string | Object;
};

export type createAdversusRule = {
  type: typeof CREATE_ADVERSUS_RULE;
  payload: { accountId: String; rule: ICreateAdversusRule };
};

export type createAdversusRuleSuccess = {
  type: typeof CREATE_ADVERSUS_RULE_SUCCESS;
  payload: IAdversusRule;
};

export type createAdversusRulesError = {
  type: typeof CREATE_ADVERSUS_RULE_ERROR;
  payload: string | Object;
};

export type editAdversusRule = {
  type: typeof EDIT_ADVERSUS_RULE;
  payload: { accountId: String; rule: IAdversusRule };
};

export type editAdversusRuleSuccess = {
  type: typeof EDIT_ADVERSUS_RULE_SUCCESS;
  payload: IAdversusRule;
};

export type editAdversusRuleError = {
  type: typeof EDIT_ADVERSUS_RULE_ERROR;
  payload: string | Object;
};

export type deleteAdversusRule = {
  type: typeof DELETE_ADVERSUS_RULE;
  payload: { accountId: String; ruleId: string };
};

export type deleteAdversusRuleSuccess = {
  type: typeof DELETE_ADVERSUS_RULE_SUCCESS;
  payload: string;
};

export type deleteAdversusRuleError = {
  type: typeof DELETE_ADVERSUS_RULE_ERROR;
  payload: string | Object;
};

export type CallImportAction =
  | getCallTokens
  | getCallTokensError
  | getCallTokensSuccess
  | getCallTokenSettings
  | getCallTokenSettingsError
  | getCallTokenSettingsSuccess
  | createCallTokenSettings
  | createCallTokenSettingsSuccess
  | createCallTokenSettingsError
  | deleteCallTokenSettings
  | deleteCallTokenSettingsSuccess
  | deleteCallTokenSettingsError
  | updateCallTokenSettings
  | updateCallTokenSettingsError
  | updateCallTokenSettingsSuccess
  | getAdversusRules
  | getAdversusRulesSuccess
  | getAdversusRulesError
  | createAdversusRule
  | createAdversusRuleSuccess
  | createAdversusRulesError
  | editAdversusRule
  | editAdversusRuleSuccess
  | editAdversusRuleError
  | deleteAdversusRule
  | deleteAdversusRuleSuccess
  | deleteAdversusRuleError;
