export const GET_MEETINGS = "GET_MEETINGS";
export const GET_MEETINGS_ERROR = "GET_MEETINGS_ERROR";
export const GET_MEETINGS_SUCCESS = "GET_MEETINGS_SUCCESS";
export const GET_MEETINGS_COUNT = "GET_MEETINGS_COUNT";
export const GET_MEETINGS_COUNT_ERROR = "GET_MEETINGS_COUNT_ERROR";
export const GET_MEETINGS_COUNT_SUCCESS = "GET_MEETINGS_COUNT_SUCCESS";
export const CREATE_MEETING = "CREATE_MEETING";
export const CREATE_MEETING_SUCCESS = "CREATE_MEETING_SUCCESS";
export const EDIT_MEETING = "EDIT_MEETING";
export const EDIT_MEETING_SUCCESS = "EDIT_MEETING_SUCCESS";
export const DELETE_MEETING = 'DELETE_MEETING';
export const DELETE_MEETING_SUCCESS = 'DELETE_MEETING_SUCCESS';
export const GET_CREATED_MEETING = 'GET_CREATED_MEETING';
export const API_ERROR = "API_ERROR";
export const GET_MEETINGS_USING_TIME_OPTIONS_SUCCESS = "GET_MEETS_USING_TIME_OPTIONS_SUCCESS";
export const CLEAR_MEETINGS_DATA = "CLEAR_MEETINGS_DATA";
export const GET_MEETING_CALENDAR_SYNC_INFO = "GET_MEETING_CALENDAR_SYNC_INFO";
export const GET_MEETING_CALENDAR_SYNC_INFO_ERROR = "GET_MEETING_CALENDAR_SYNC_INFO_ERROR";
export const GET_MEETING_CALENDAR_SYNC_INFO_SUCCESS = "GET_MEETING_CALENDAR_SYNC_INFO_SUCCESS";
export const CLEAR_MEETING_CALENDAR_SYNC_INFO = "CLEAR_MEETING_CALENDAR_SYNC_INFO";
