import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col } from "reactstrap";
import { History, Location } from "history";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";

import CompareChart from "./CompareChart";
import { getStatsUser } from "../../store/actions";
import {
  FilterObject,
  IAnalyticsUser,
  IFilterUser,
} from "../../store/interface";
import GlobalFilters from "../../components/GlobalFilters";
import { STATS } from "../../components/GlobalFilters/utils/constants";
import {
  formatMinutesToHours,
  formatSecondsToMinutes,
  roundValue,
  roundValueWithOneDecimal,
} from "../../services/utilities/newUtilservice";
import { Icurrentaccountselected } from "../../store/auth/profile/interface";
import { createFilterString } from "../../components/GlobalFilters/utils/helperfunctions";

interface CompareViewProps {
  users: IFilterUser[];
  currentaccountselected: Icurrentaccountselected;
  history: History;
  location: Location;
  loading: boolean;
  analyticsData: IAnalyticsUser[];
  statFilters: FilterObject;
  getStatsUser: (id: String, params?: String) => void;
}

interface CompareViewState {
  shownValues: string;
  shownValuesType: string;
  valueType: string;
  getDataFirstLoad: boolean;
  formatter?: (value: any) => {};
}

export const optionNone = { label: "Not specified", value: "none", id: null };

class CompareView extends Component<CompareViewProps, CompareViewState> {
  constructor(props: CompareViewProps) {
    super(props);
    this.state = {
      shownValuesType: "total_meeting_time",
      shownValues: "Interaction time",
      valueType: " hours",
      getDataFirstLoad: true,
      formatter: formatMinutesToHours,
    };
  }

  componentDidMount() {
    const { currentaccountselected, statFilters, getStatsUser } = this.props;
    const filterString = createFilterString(statFilters);

    if (!isEmpty(currentaccountselected)) {
      this.setState({
        getDataFirstLoad: false,
      });

      getStatsUser(currentaccountselected.id, filterString);
    }
  }

  componentDidUpdate(prevProps: CompareViewProps, prevState: CompareViewState) {
    const { currentaccountselected, statFilters, getStatsUser } = this.props;
    const filterString = createFilterString(statFilters);

    if(prevProps.currentaccountselected !== currentaccountselected) {
      getStatsUser(currentaccountselected.id, filterString);
    }

    if (prevProps.statFilters !== statFilters) {
      getStatsUser(currentaccountselected.id, filterString);
    }
  }

  changeShownValue = (
    shownValuesType: string,
    valueName: string,
    valueType: string,
    formatter?: (value: any) => {}
  ) => {
    this.setState({
      shownValues: valueName,
      shownValuesType,
      valueType,
      formatter,
    });
  };

  render() {
    const { users, analyticsData } = this.props;
    const { shownValues, shownValuesType, valueType, formatter } = this.state;

    return (
      <React.Fragment>
        <Col lg="12">
          <CardBody>
            <div id="compare-view">
              <div className="header">
                <div className="page-name">Stats (beta)</div>
                <div className="active-filters">
                  <div>
                    <GlobalFilters type={STATS} />
                  </div>
                </div>
              </div>
              <div className="page-content">
                <Card className="options">
                  <CardBody className="options-content">
                    <div className="group-header">General</div>
                    <div className="link_disabled">Signals</div>
                    <div className="group-header">Meeting</div>
                    <div
                      onClick={() => this.changeShownValue('total_meeting_time', 'Interaction time', " hours", formatMinutesToHours)}
                      className={shownValuesType === "total_meeting_time" ? "link active" : "link"}
                    >Interaction time</div>
                      <div
                      onClick={() => this.changeShownValue('meetings', 'Number of interactions', "", roundValue)}
                      className={shownValuesType === "meetings" ? "link active" : "link"}
                    >Number of interactions</div>
                    <div
                      onClick={() => this.changeShownValue('avg_meeting_time', 'Avg. Interaction time', " min", formatSecondsToMinutes)}
                      className={shownValuesType === "avg_meeting_time" ? "link active" : "link"}
                    >Avg. Interaction time</div>
                    <div className="link_disabled">Scheduled meetings</div>
                    <div
                      onClick={() => this.changeShownValue('meeting_engagement_percentage', 'Customer in-tab %', "%", roundValueWithOneDecimal)}
                      className={shownValuesType === "meeting_engagement_percentage" ? "link active" : "link"}
                    >Customer in-tab %</div>
                    <div
                      onClick={() => this.changeShownValue('avg_slides_shown', 'Slides shown', '', roundValueWithOneDecimal)}
                      className={shownValuesType === "avg_slides_shown" ? "active link" : "link"}
                    >Slides shown</div>
                    <div
                      onClick={() => this.changeShownValue('direct_share_percentage', 'Direct share %', '%', roundValueWithOneDecimal)}
                      className={shownValuesType === "direct_share_percentage" ? "link active" : "link"}
                    >Direct share %</div>
                    <div
                      onClick={() => this.changeShownValue('face2face_percentage', 'Face to face %', "%", roundValueWithOneDecimal)}
                      className={shownValuesType === "face2face_percentage" ? "link active" : "link"}
                    >Face to face %</div>
                    <div
                      onClick={() => this.changeShownValue('screen_share_percentage', 'Screen share %', "%", roundValueWithOneDecimal)}
                      className={shownValuesType === "screen_share_percentage" ? "link active" : "link"}
                    >Screen share %</div>

                    <div className="group-header">Voice-based</div>
                    <div
                      onClick={() => this.changeShownValue('longest_monologue', 'Monologues', ' min', formatSecondsToMinutes)}
                      className={shownValuesType === "longest_monologue" ? "link active" : "link"}
                    >Monologues</div>
                    <div
                      onClick={() => this.changeShownValue('longest_user_story', 'Customer Stories', ' min', formatSecondsToMinutes)}
                      className={shownValuesType === "longest_customer_story" ? "link active" : "link"}
                    >Customer stories</div>
                    <div
                      onClick={() => this.changeShownValue('avg_division', 'Talk:listen ratio', '% per meeting', roundValueWithOneDecimal)}
                      className={shownValuesType === "avg_division" ? "link active" : "link"}
                    >Talk:listen ratio</div>
                    <div
                      onClick={() => this.changeShownValue('avg_shifts_per_minute', 'Conversation shifts', ' / meeting', roundValueWithOneDecimal)}
                      className={shownValuesType === "avg_shifts_per_minute" ? "link active" : "link"}
                    >Conversation shifts</div>
                    <div
                      onClick={() => this.changeShownValue('avg_interruptions', 'Interruptions', ' / meeting', roundValueWithOneDecimal)}
                      className={shownValuesType === "avg_interruptions" ? "link active" : "link"}
                    >Interruptions</div>

                    <div className="group-header">Words-based</div>
                    <div
                      onClick={() => this.changeShownValue('avg_words_per_minute', 'Avg. Words per minute', " word / min", roundValueWithOneDecimal)}
                      className={shownValuesType === "avg_words_per_minute" ? "link active" : "link"}
                    >Words per minute</div>
                    <div className="link_disabled">Keywords</div>
                    <div className="group-header">Content</div>
                    <div className="link_disabled">Usage by user</div>
                    <div className="link_disabled">Top documents</div>
                    <div className="link_disabled">Top pages</div>
                  </CardBody>
                </Card>
                <Card className="meeting-score-chart">
                  <CardBody className="chart-content">
                    {analyticsData && (
                      <CompareChart
                        users={users}
                        analyticsData={analyticsData}
                        valueType={valueType}
                        shownValuesLabel={shownValues}
                        shownValuesType={shownValuesType}
                        formatter={formatter}
                      />
                    )}
                  </CardBody>
                </Card>
              </div>
            </div>
          </CardBody>
        </Col>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentaccountselected } = state.Profile;
  const { user, loading } = state.SalesAnalytics;

  const { filterUsers, statFilters } = state.globalFilters;

  return {
    currentaccountselected,
    users: filterUsers,
    loading,
    analyticsData: user,
    statFilters,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getStatsUser,
  })(CompareView)
);
