export const GET_CALENDAR = 'GET_CALENDAR';
export const GET_CALENDAR_SUCCESS = 'GET_CALENDAR_SUCCESS';
export const GET_CALENDAR_ERROR = 'GET_CALENDAR_ERROR';
export const GET_MEETING_BOTS = 'GET_MEETING_BOTS';
export const GET_MEETING_BOTS_SUCCESS = 'GET_MEETING_BOTS_SUCCESS';
export const GET_MEETING_BOTS_ERROR = 'GET_MEETING_BOTS_ERROR';
export const GET_PROCESSING = 'GET_PROCESSING';
export const GET_PROCESSING_SUCCESS = 'GET_PROCESSING_SUCCESS';
export const GET_PROCESSING_ERROR = 'GET_PROCESSING_ERROR';
export const API_ERROR = 'API_ERROR';
