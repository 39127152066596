import React, { Component } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";

import CommentsDetailsTable from "./CommentsDetailsTable";
import { IUser } from "../../store/interface";
import { ICommentDetails } from "../../store/comments/interface";
import {
  deleteCommentDetails,
  getCommentDetails,
} from "../../store/comments/actions";
import { Icurrentaccountselected } from "../../store/auth/profile/interface";
import DeleteModal from "../Home/components/DeleteModal";

interface CommentsProps {
  currentaccountselected: Icurrentaccountselected;
  globalFilterString: string;
  commentDetails: ICommentDetails[];
  isCommentsLoading: boolean;
  currentuser: IUser;
  getCommentDetails: (accountId: String, globalFilterString: string) => void;
  deleteCommentDetails: (commentId: String) => void;
}

interface CommentsState {
  isDeleteModalOpen: boolean;
  commentIdToDelete: string | null;
}

class CommentsPage extends Component<CommentsProps, CommentsState> {
  constructor(props: CommentsProps) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
      commentIdToDelete: null,
    };
  }

  componentDidMount() {
    const { currentaccountselected, globalFilterString, getCommentDetails } =
      this.props;

    if (!isEmpty(currentaccountselected)) {
      getCommentDetails(currentaccountselected.id, globalFilterString);
    }
  }

  componentDidUpdate(prevProps: CommentsProps) {
    const { currentaccountselected, globalFilterString, getCommentDetails } =
      this.props;

    if (
      !isEmpty(currentaccountselected) &&
      (currentaccountselected?.id !== prevProps.currentaccountselected?.id ||
        globalFilterString !== prevProps.globalFilterString)
    ) {
      getCommentDetails(currentaccountselected.id, globalFilterString);
    }
  }

  confirmDeleteComment = (commentId: string) => {
    this.setState({
      isDeleteModalOpen: true,
      commentIdToDelete: commentId,
    });
  };

  handleDeleteComment = async () => {
    const {
      currentaccountselected,
      globalFilterString,
      deleteCommentDetails,
      getCommentDetails,
    } = this.props;
    const { commentIdToDelete } = this.state;

    if (!commentIdToDelete) return;

    await deleteCommentDetails(commentIdToDelete);
    this.setState({
      isDeleteModalOpen: false,
      commentIdToDelete: null,
    });

    getCommentDetails(currentaccountselected.id, globalFilterString);
  };

  canDeleteComment = (commentOwnerId: number) => {
    const { currentuser } = this.props;

    return currentuser && commentOwnerId === currentuser.id;
  };

  render() {
    const { commentDetails, isCommentsLoading } = this.props;

    return (
      <>
        <div className="page-content meeting-page">
          <CommentsDetailsTable
            canDeleteComment={this.canDeleteComment}
            comments={commentDetails}
            loading={isCommentsLoading}
            onDelete={this.confirmDeleteComment}
          />
          <DeleteModal
            title="comment"
            isOpen={this.state.isDeleteModalOpen}
            handleDelete={this.handleDeleteComment}
            handleClose={() =>
              this.setState({
                isDeleteModalOpen: false,
                commentIdToDelete: null,
              })
            }
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentaccountselected, currentuser } = state.Profile;
  const { commentDetails, loading: isCommentsLoading } = state.Comment;

  return {
    currentaccountselected,
    commentDetails,
    isCommentsLoading,
    currentuser,
  };
};

export default connect(mapStateToProps, {
  getCommentDetails,
  deleteCommentDetails,
})(CommentsPage);
