import axios, { AxiosResponse } from 'axios';
import { ResponseGenerator } from '../../userservice';
import { urls } from '../../../utilities/urls';
import { endpoints } from '../../../utilities/endpoints';
import { commonheaders } from '../../../utilities/apiConfig';
import {
  ICallImportSettings,
  ICallImportToken,
} from '../../../store/CallImports/interface';
import {
  IAdversusRule,
  ICreateAdversusRule,
  mapAdversusRule,
} from '../../../pages/Settings/CallImport/utils/constants';

export async function getCallTokens(
  accountId: String
): Promise<AxiosResponse<ICallImportToken[]>> {
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.callUserToken}`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getCallTokenSettings(
  accountId: String,
): Promise<ICallImportSettings[]> {
  try {
    const response: ResponseGenerator = await axios.get<ICallImportSettings[]>(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.callImportSettings}`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function createTokenSettings(
  accountId: String,
  tokenSettings: ICallImportSettings,
) {
  try {
    return await axios.post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.callImportSettings}/${tokenSettings.tokenId}`,
      {
        ...tokenSettings,
      },
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function updateTokenSettings(
  accountId: String,
  tokenSettings: ICallImportSettings,
) {
  try {
    return await axios.put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.callImportSettings}/${tokenSettings.id}`,
      {
        ...tokenSettings,
      },
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function deleteTokenSettings(
  accountId: String,
  tokenSettingsId: String,
) {
  try {
    return await axios.delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.callImportSettings}/${tokenSettingsId}`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getAdversusRules(
  accountId: String
): Promise<IAdversusRule[]> {
  try {
    const response: ResponseGenerator = await axios.get<IAdversusRule[]>(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.adversusRule}`,
      commonheaders
    );

    const mappedRules = response.data.data.map((rule: any) => mapAdversusRule(rule));
    return mappedRules;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function createAdversusRule(
  accountId: String,
  rule: ICreateAdversusRule
) {
  try {
    return await axios.post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.adversusRule}`,
      {
        token_id: rule.tokenId,
        search_field: rule.searchField,
        search_value: rule.searchValue,
        min_length: rule.minLength,
        rule: rule.rule
      },
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function editAdversusRule(accountId: String, rule: IAdversusRule) {
  try {
    return await axios.put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.adversusRule}/${rule.id}`,
      {
        token_id: rule.tokenId,
        search_field: rule.searchField,
        search_value: rule.searchValue,
        min_length: rule.minLength,
        rule: rule.rule
      },
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function deleteAdversusRule(accountId: String, ruleId: String) {
  try {
    return await axios.delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.adversusRule}/${ruleId}`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}
