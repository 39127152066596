export const EDIT_PERSONAL_PROFILE = 'EDIT_PERSONAL_PROFILE';
export const PERSONAL_PROFILE_SUCCESS = 'PERSONAL_PROFILE_SUCCESS';
export const CHANGE_PROFILE_PASSWORD = 'CHANGE_PROFILE_PASSWORD';
export const CHANGE_PROFILE_PASSWORD_SUCCESS = 'CHANGE_PROFILE_PASSWORD_SUCCESS';
export const CHANGE_PROFILE_PASSWORD_ERROR = 'CHANGE_PROFILE_PASSWORD_ERROR';
export const EDIT_USER_PROFILE = 'EDIT_USER_PROFILE';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const PERSONAL_PROFILE_ERROR = 'PERSONAL_PROFILE_ERROR';
export const EDIT_PERSONAL_PROFILE_NAME = 'EDIT_PERSONAL_PROFILE_NAME';
export const EDIT_PERSONAL_PROFILE_PIC = 'EDIT_PERSONAL_PROFILE_PIC';
export const CREATE_PROFILE_SUCCESS = 'CREATE_PROFILE_SUCCESS';
export const CREATE_PROFILE_ERROR = 'CREATE_PROFILE_ERROR';
export const CREATE_USER_PROFILE = 'CREATE_USER_PROFILE';
export const ADD_USER_TO_TEAM = 'ADD_USER_TO_TEAM';
export const ADD_USER_TO_TEAM_SUCCESS = 'ADD_USER_TO_TEAM_SUCCESS';
export const ADD_USER_TO_TEAM_ERROR = 'ADD_USER_TO_TEAM_ERROR';
export const DELETE_USER_PROFILE = 'DELETE_USER_PROFILE';
export const DELETE_USER_PROFILE_SUCCESS = 'DELETE_USER_PROFILE_SUCCESS';
export const DELETE_USER_PROFILE_ERROR = 'DELETE_USER_PROFILE_ERROR';
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const PROFILE_SETUP_COMPLETED = "PROFILE_SETUP_COMPLETED";
