export enum IAIAgentTabs {
  Create = 1,
  Settings,
}

export enum IAIAgentPreviewTabs {
  Results = 1,
  Transcript,
}

export interface OptionType {
  label: string;
  value: string | null;
}

export interface MatchParams {
  agentId: string;
}

export interface IPlaybookCustomBlock {
  id: string;
  uuid: string;
  parentId: string | null;
  title: string;
  description: string;
  weight?: number;
  children?: IPlaybookCustomBlock[];
}

export interface IPlaybookCustomBlockData {
  id: string;
  uuid: string;
  parent_id: string | null;
  title: string;
  description: string;
  weight?: number;
  children?: IPlaybookCustomBlockData[];
}

export interface IQuestionCustomBlock {
  id: string;
  uuid: string;
  parentId: string | null;
  title: string;
  description: string;
  outputType: string;
  outputOptionList: string[] | null;
  children?: IQuestionCustomBlock[];
}

export interface IQuestionCustomBlockData {
  id: string;
  uuid: string;
  parent_id: string | null;
  title: string;
  description: string;
  output_type: string;
  children?: IQuestionCustomBlockData[];
}

export interface DndData {
  source: {
    droppableId: string;
    index: number;
  };
  destination: {
    droppableId: string;
    index: number;
  };
}
