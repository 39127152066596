import {
  GET_SHARE_LINK_DETAILS,
  GET_SHARE_LINK_DETAILS_ERROR,
  GET_SHARE_LINK_DETAILS_SUCCESS,
  GET_UNSHARE_LINK_DETAILS,
  GET_UNSHARE_LINK_DETAILS_ERROR,
} from "./actionTypes";
import { IShareLinkDetails } from "./interface";

export const getShareLinkDetails = (accountId: String, globalFilterString: string) => {
  return {
    type: GET_SHARE_LINK_DETAILS,
    payload: { accountId, globalFilterString }
  }
}

export const getShareLinkDetailsError = (error: string | Object) => {
  return {
    type: GET_SHARE_LINK_DETAILS_ERROR,
    payload: error,
  }
}

export const getShareLinkDetailsSuccess = (shareLinkDetails: IShareLinkDetails[]) => {
  return {
    type: GET_SHARE_LINK_DETAILS_SUCCESS,
    payload: shareLinkDetails,
  }
}

export const getUnshareLinkDetails = (accountId: String, shareLinkId: string, shareLinkType: string) => {
  return {
    type: GET_UNSHARE_LINK_DETAILS,
    payload: { accountId, shareLinkId, shareLinkType }
  }
}

export const getUnshareLinkDetailsError = (error: string | Object) => {
  return {
    type: GET_UNSHARE_LINK_DETAILS_ERROR,
    payload: error,
  }
}
