import { CREATE_REGISTRATION, CREATE_REGISTRATION_SUCCESS, CREATE_REGISTRATION_ERROR } from './actionTypes';
import { RegistrationAction, RegistrationState } from './interface';

const initialState: RegistrationState = {
  success : "" ,
  error : "",
  loader: false,
}

const Registration = (state = initialState, action: RegistrationAction) => {
  switch (action.type) {
    case CREATE_REGISTRATION:
      state = {
        loader: true,
        error : "",
        success : "",
      }
      break;
    case CREATE_REGISTRATION_SUCCESS:
      state = {
        ...state,
        success : action.payload,
        error : "",
        loader: false,
      }
      break;
    case CREATE_REGISTRATION_ERROR:
      state = {
        ...state,
        success : "",
        error : action.payload,
        loader: false,
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
}

export default Registration;