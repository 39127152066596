import {
  API_ERROR,
  CREATE_MEETING_TYPE,
  CREATE_MEETING_TYPE_SUCCESS,
  EDIT_MEETING_TYPE,
  EDIT_MEETING_TYPE_SUCCESS,
  EDIT_RECORDING_TYPE_ERROR,
  GET_CAMPAIGN,
  GET_CAMPAIGN_ERROR,
  GET_CAMPAIGN_SUCCESS,
  GET_CREATED_MEETING_TYPE,
  GET_DEFAULT_MEETING_TYPES,
  GET_DEFAULT_MEETING_TYPES_ERROR,
  GET_DEFAULT_MEETING_TYPES_SUCCESS,
  GET_MEETING_TYPES,
  GET_MEETING_TYPES_ERROR,
  GET_MEETING_TYPES_SUCCESS,
} from "./actionTypes";

import { MeetingTypeAction, MeetingTypeState } from "./interface";

const initialState: MeetingTypeState = {
  success: "",
  error: [],
  getLoader: false,
  loader: false,
  deleteLoader: false,
  createdResource: [],
  meetingTypes: null,
  campaigns: null,
  defaultMeetingTypes: null,
  deletedId: "",
  editTypeError: "",
};

const MeetingTypes = (state = initialState, action: MeetingTypeAction) => {
  switch (action.type) {
    case GET_MEETING_TYPES:
      state = {
        ...state,
        getLoader: true,
        meetingTypes: null,
        error: [],
      };
      break;
    case GET_MEETING_TYPES_SUCCESS:
      state = {
        ...state,
        getLoader: false,
        meetingTypes: action.payload,
      };
      break;
    case GET_MEETING_TYPES_ERROR:
      state = {
        ...state,
        getLoader: false,
        error: action.payload,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        deleteLoader: false,
        loader: false,
      };
      break;
    case GET_CREATED_MEETING_TYPE:
      state = { ...state, createdResource: action.payload, loader: false };
      break;
    case CREATE_MEETING_TYPE:
      state = {
        ...state,
        success: "",
        error: [],
        createdResource: [],
        loader: true,
      };
      break;
    case CREATE_MEETING_TYPE_SUCCESS:
      const newMeetingType = action.payload;
      const updatedMeetingTypes = [...(state.meetingTypes || [])];

      let insertIndex = updatedMeetingTypes.findIndex(type =>
        type?.sort_index !== null && type?.sort_index !== undefined &&
        newMeetingType?.sort_index !== null && newMeetingType?.sort_index !== undefined &&
        type.sort_index <= newMeetingType.sort_index
      );

      if (insertIndex === -1) {
        insertIndex = updatedMeetingTypes.length;
      }

      updatedMeetingTypes.splice(insertIndex, 0, newMeetingType);

      state = { ...state, meetingTypes: updatedMeetingTypes, loader: false };
      break;
    case EDIT_MEETING_TYPE:
      state = {
        ...state,
        success: "",
        error: [],
        createdResource: [],
        loader: true,
      };
      break;
    case EDIT_MEETING_TYPE_SUCCESS:
      const newMeetingTypeEdit = action.payload;
      const updatedMeetingTypesEdit = [...(state.meetingTypes || [])];
      let insertIndexEdit = updatedMeetingTypesEdit.findIndex(type =>
        type?.sort_index !== null && type?.sort_index !== undefined &&
        newMeetingTypeEdit?.sort_index !== null && newMeetingTypeEdit?.sort_index !== undefined &&
        type.sort_index <= newMeetingTypeEdit.sort_index
      );

      if (insertIndexEdit === -1) {
        insertIndexEdit = updatedMeetingTypesEdit.length;
      }

      updatedMeetingTypesEdit.splice(insertIndexEdit, 0, newMeetingTypeEdit);

      state = { ...state, meetingTypes: updatedMeetingTypesEdit, loader: false };
      break;
    case GET_DEFAULT_MEETING_TYPES:
      state = {
        ...state,
        getLoader: true,
        defaultMeetingTypes: null,
        error: [],
      };
      break;
    case GET_DEFAULT_MEETING_TYPES_SUCCESS:
      state = {
        ...state,
        getLoader: false,
        defaultMeetingTypes: action.payload,
      };
      break;
    case GET_DEFAULT_MEETING_TYPES_ERROR:
      state = {
        ...state,
        getLoader: false,
        error: action.payload,
      };
      break;
    case GET_CAMPAIGN:
      state = {
        ...state,
        getLoader: true,
        campaigns: null,
      };
      break;
    case GET_CAMPAIGN_SUCCESS:
      state = {
        ...state,
        getLoader: false,
        campaigns: action.payload,
      };
      break;
    case GET_CAMPAIGN_ERROR:
      state = {
        ...state,
        getLoader: false,
        error: action.payload,
      };
      break;
    case EDIT_RECORDING_TYPE_ERROR:
      state = {
        ...state,
        editTypeError: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default MeetingTypes;
