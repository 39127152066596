import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  GET_FOLDERS,
  CHECK_CUSTOM_FOLDERS,
  GET_CUSTOM_FOLDERS,
  UPLOAD_FOLDER,
  EDIT_FOLDER,
  REMOVE_FOLDER,
  MOVE_FOLDER,
  GET_USER_FOLDERS,
} from "./actionTypes";
import {
  getFoldersSuccess,
  getCustomFoldersSuccess,
  moveFoldersSuccess,
  foldersApiError,
  uploadFolderSuccess,
  editFolderSuccess,
  removeFolderSuccess,
} from "./actions";

//services
import {
  getFolder,
  getCustomFolders,
  moveFolders,
  uploadFolder,
  editFolder,
  removeFolder,
  getUserFolders,
  ResponseGenerator,
} from "../../services/userservice";
import _ from "lodash";

function* getFolderHelper({ payload: { data } }: any) {
  const response: ResponseGenerator = yield call(getFolder, data);
  if (_.has(response, "data")) {
    yield put(getFoldersSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(foldersApiError(error));
    } else {
      yield put(foldersApiError("An Error has occured"));
    }
  }
}

function* moveFoldersHelper({ payload: { fileObj, callback } }: any) {
  const response: ResponseGenerator = yield call(moveFolders, fileObj);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(foldersApiError(error));
    } else {
      yield put(foldersApiError("An Error has occured"));
    }
  } else {
    callback(true);
    yield put(
      moveFoldersSuccess("Files or folders moved successfully", fileObj)
    );
  }
}

function* getUserFoldersHelper({ payload: { callback } }: any) {
  const response: ResponseGenerator = yield call(getUserFolders);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(foldersApiError(error));
    } else {
      yield put(foldersApiError("An Error has occured"));
    }
  } else {
    callback(response.data);
  }
}

function* getCustomFoldersHelper({ payload: { id, folderId } }: any) {
  const response: ResponseGenerator = yield call(
    getCustomFolders,
    id,
    folderId
  );
  if (_.has(response, "data")) {
    yield put(getCustomFoldersSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(foldersApiError(error));
    } else {
      yield put(foldersApiError("An Error has occured"));
    }
  }
}

function* checkustomFoldersHelper({
  payload: { id, folderId, callback },
}: any) {
  const response: ResponseGenerator = yield call(
    getCustomFolders,
    id,
    folderId
  );
  if (_.has(response, "data")) {
    if (callback) {
      callback(response.data);
    }
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(foldersApiError(error));
    } else {
      yield put(foldersApiError("An Error has occured"));
    }
  }
}

function* uploadFolderHelper({ payload: { fileObj } }: any) {
  const response: ResponseGenerator = yield call(uploadFolder, fileObj);
  if (_.has(response, "data")) {
    yield put(uploadFolderSuccess("Folder Uploaded Successfully"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(foldersApiError(error));
    } else {
      yield put(foldersApiError("An Error has occured"));
    }
  }
}

function* editFolderHelper({ payload: { id, params } }: any) {
  const response: ResponseGenerator = yield call(editFolder, id, params);
  if (_.has(response, "data")) {
    yield put(editFolderSuccess("Folder Updated Successfully"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(foldersApiError(error));
    } else {
      yield put(foldersApiError("An Error has occured"));
    }
  }
}

function* removeFolderHelper({ payload: { id } }: any) {
  const response: ResponseGenerator = yield call(removeFolder, id);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(foldersApiError(error));
    } else {
      yield put(foldersApiError("An Error has occured"));
    }
  } else {
    yield put(removeFolderSuccess("Folder deleted Successfully"));
  }
}

export function* watchGetFolder() {
  yield takeEvery(GET_FOLDERS, getFolderHelper);
}

export function* watchUploadFolder() {
  yield takeEvery(UPLOAD_FOLDER, uploadFolderHelper);
}

export function* watchEditFolder() {
  yield takeEvery(EDIT_FOLDER, editFolderHelper);
}

export function* watchRemoveFolder() {
  yield takeEvery(REMOVE_FOLDER, removeFolderHelper);
}

export function* watchGetCustomFolders() {
  yield takeEvery(GET_CUSTOM_FOLDERS, getCustomFoldersHelper);
}

export function* watchCheckCustomFolders() {
  yield takeEvery(CHECK_CUSTOM_FOLDERS, checkustomFoldersHelper);
}

export function* watchmoveFolders() {
  yield takeEvery(MOVE_FOLDER, moveFoldersHelper);
}

export function* watchgetUserFolders() {
  yield takeEvery(GET_USER_FOLDERS, getUserFoldersHelper);
}

function* foldersSaga() {
  yield all([
    fork(watchGetFolder),
    fork(watchmoveFolders),
    fork(watchUploadFolder),
    fork(watchEditFolder),
    fork(watchGetCustomFolders),
    fork(watchRemoveFolder),
    fork(watchCheckCustomFolders),
    fork(watchgetUserFolders),
  ]);
}

export default foldersSaga;
