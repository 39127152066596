import React, { Component } from "react";
import { connect } from "react-redux";
import { last, isEqual } from "lodash";

import MessageComponent from "../components/MessageComponent";
import { sendMessage, getChatMessages } from "../../../../store/actions";
import { IChatInformation, IMessage, IUser } from "../../../../store/interface";

interface MessagesProps {
  chatInformation: IChatInformation;
  messages: IMessage[];
  selectedChatLoading: boolean;
  currentuser: IUser;
  sendMessage: (message: string, chatId: string) => void;
  getChatMessages: (chatId: string) => void;
}

class Messages extends Component<MessagesProps> {
  intervalId: NodeJS.Timeout | null = null;

  componentDidMount() {
    this.setupInterval();
  }

  componentDidUpdate(prevProps: MessagesProps) {
    const { messages, chatInformation } = this.props;
    const lastMessage = last(messages);

    if (prevProps.chatInformation?.chatId !== chatInformation?.chatId) {
      this.clearInterval();
      this.setupInterval();
    }

    if (
      !isEqual(chatInformation, prevProps.chatInformation) &&
      chatInformation?.status === "ready"
    ) {
      this.clearInterval();
    } else if (
      chatInformation?.status === "ready" &&
      lastMessage?.role !== "assistant"
    ) {
      this.setupInterval();
    }
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  setupInterval = () => {
    const { chatInformation, getChatMessages } = this.props;

    if (chatInformation?.chatId) {
      this.intervalId = setInterval(() => {
        getChatMessages(chatInformation.chatId);
      }, 2000);
    }
  };

  clearInterval = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  };

  handleSendMessage = (messageContent: string) => {
    const { chatInformation, sendMessage } = this.props;

    messageContent.trim() &&
      sendMessage(messageContent, chatInformation.chatId);
  };

  render() {
    const { chatInformation, messages, currentuser, selectedChatLoading } =
      this.props;

    return (
      <MessageComponent
        isLoading={selectedChatLoading}
        messages={messages}
        chatTitle={chatInformation?.chatName}
        handleSendMessage={this.handleSendMessage}
        chatInfo={chatInformation}
        currentuser={currentuser}
      />
    );
  }
}

const mapStateToProps = (state: any) => {
  const { chatInformation, selectedChatLoading, messages } = state.Chat;
  const { currentuser } = state.Profile;

  return {
    currentuser,
    messages,
    chatInformation,
    selectedChatLoading,
  };
};

const mapDispatchToProps = {
  sendMessage,
  getChatMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
