import {
  EDIT_PERSONAL_PROFILE,
  PERSONAL_PROFILE_ERROR,
  PERSONAL_PROFILE_SUCCESS,
  EDIT_PERSONAL_PROFILE_NAME,
  EDIT_PERSONAL_PROFILE_PIC,
  EDIT_USER_PROFILE,
  CREATE_PROFILE_ERROR,
  CREATE_PROFILE_SUCCESS,
  CREATE_USER_PROFILE,
  ADD_USER_TO_TEAM,
  ADD_USER_TO_TEAM_SUCCESS,
  ADD_USER_TO_TEAM_ERROR,
  DELETE_USER_PROFILE,
  DELETE_USER_PROFILE_SUCCESS,
  DELETE_USER_PROFILE_ERROR,
  UPLOAD_DOCUMENT,
  CHANGE_PROFILE_PASSWORD,
  CHANGE_PROFILE_PASSWORD_SUCCESS,
  CHANGE_PROFILE_PASSWORD_ERROR,
  PROFILE_SETUP_COMPLETED,
} from "./actionTypes";

export const editPersonalProfile = (user: Object, id: string | number) => {
  return {
    type: EDIT_PERSONAL_PROFILE,
    payload: { user, id },
  };
};

export const changeProfilePassword = (passwordData: Object) => {
  return {
    type: CHANGE_PROFILE_PASSWORD,
    payload: { passwordData },
  };
};

export const changeProfilePasswordSuccess = (msg: String) => {
  return {
    type: CHANGE_PROFILE_PASSWORD_SUCCESS,
    payload: msg,
  };
};

export const changeProfilePasswordError = (msg: String) => {
  return {
    type: CHANGE_PROFILE_PASSWORD_ERROR,
    payload: msg,
  };
};

export const editUserProfile = (
  user: Object,
  id: String,
  accountID: String
) => {
  return {
    type: EDIT_USER_PROFILE,
    payload: { user, id, accountID },
  };
};

export const editPersonalProfileSuccess = (msg: String) => {
  return {
    type: PERSONAL_PROFILE_SUCCESS,
    payload: msg,
  };
};

export const createUserProfile = (user: Object, id: String) => {
  return {
    type: CREATE_USER_PROFILE,
    payload: { user, id },
  };
};

export const createPersonalProfileSuccess = (msg: String) => {
  return {
    type: CREATE_PROFILE_SUCCESS,
    payload: msg,
  };
};

export const personalProfileError = (error: Object | String) => {
  return {
    type: CREATE_PROFILE_ERROR,
    payload: error,
  };
};

export const editPersonalProfileNameChange = (msg: String) => {
  return {
    type: EDIT_PERSONAL_PROFILE_NAME,
    payload: msg,
  };
};

export const editPersonalProfileError = (error: Object | String) => {
  return {
    type: PERSONAL_PROFILE_ERROR,
    payload: error,
  };
};

export const editPersonalProfilePic = (
  pic: any,
  id: string | number,
  onlyDocument: any
) => {
  return {
    type: EDIT_PERSONAL_PROFILE_PIC,
    payload: { pic, id, onlyDocument },
  };
};

export const uploadedDocument = (file: Object) => {
  return {
    type: UPLOAD_DOCUMENT,
    payload: file,
  };
};

export const addUserToTeam = (teamId: String, userIds: Object[]) => {
  return {
    type: ADD_USER_TO_TEAM,
    payload: { teamId, userIds },
  };
};

export const addUserToTeamSuccess = (mes: String) => {
  return {
    type: ADD_USER_TO_TEAM_SUCCESS,
    payload: mes,
  };
};

export const addUserToTeamError = (error: Object | String) => {
  return {
    type: ADD_USER_TO_TEAM_ERROR,
    payload: error,
  };
};

export const deleteUserProfile = (accountID: String, params: Object) => {
  return {
    type: DELETE_USER_PROFILE,
    payload: { accountID, params },
  };
};

export const deleteUserProfileSuccess = (msg: String) => {
  return {
    type: DELETE_USER_PROFILE_SUCCESS,
    payload: msg,
  };
};

export const deleteUserProfileError = (error: String | Object) => {
  return {
    type: DELETE_USER_PROFILE_ERROR,
    payload: error,
  };
};

export const completeProfileSetup = () => {
  return {
    type: PROFILE_SETUP_COMPLETED,
  }
}
