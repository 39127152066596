import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import _ from "lodash";
import { SEND_ACTIVATION_EMAIL } from "./actionTypes";
import { sendActivationEmailSuccess, sendActivationEmailError } from "./actions";

//services
import { sendActivationEmail as  sendActivationEmailServices, ResponseGenerator } from '../../../services/userservice';

//Dispatch redux action's are directly from here.
function* sendEmail({ payload: {users} }: any) {
  const response: ResponseGenerator = yield call(sendActivationEmailServices, users);
  if (_.has(response, "data")) {
    if(users.length > 1) {
      yield put(sendActivationEmailSuccess(`Activation reminder email sent successfully to ${users.length} users!`));
    }else{
      yield put(sendActivationEmailSuccess(`Activation reminder email sent successfully!`));
    }
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(sendActivationEmailError(error));
    } else {
      yield put(sendActivationEmailError("An Error has occurred"));
    }
  }
}

export function* watchSendActivationEmail() {
  yield takeEvery(SEND_ACTIVATION_EMAIL, sendEmail);
}

function* sendActivationEmailSaga() {
  yield all([fork(watchSendActivationEmail)]);
}

export default sendActivationEmailSaga;
