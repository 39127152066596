import {
  API_ERROR,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_FILTER_USERS,
  GET_FILTER_USERS_SUCCESS,
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
  SET_USER_ROLE,
  SET_USER_ROLE_SUCCESS,
  SET_USER_ROLE_ERROR,
} from "./actionTypes";
import { UserState, UserAction } from './interface';

const initialState: UserState = {
  error: "",
  loading: false,
  users: null,
  filterUsers: null,
  success: "",
  userRoleError: "",
  userRoleSuccess: "",
};

const getUsersList = (state = initialState, action: UserAction) => {
  switch (action.type) {
    case GET_USERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        users: action.payload,
      };
      break;
    case GET_FILTER_USERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_FILTER_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        filterUsers: action.payload,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case SET_PASSWORD:
      state = { ...state, loading: true, success: "", error: "" };
      break;
    case SET_PASSWORD_SUCCESS:
      state = { ...state, loading: false, success: action.payload, error: "" };
      break;
    case SET_PASSWORD_ERROR:
      state = { ...state, loading: false, error: action.payload };
      break;
    case SET_USER_ROLE:
      state = { ...state, userRoleSuccess: "", userRoleError: "" };
      break;
    case SET_USER_ROLE_SUCCESS:
      state = { ...state, userRoleSuccess: action.payload };
      break;
    case SET_USER_ROLE_ERROR:
      state = { ...state, userRoleError: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default getUsersList;
