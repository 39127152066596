import React from "react";

interface CloseButtonProps {
  handleClose: () => void;
}

const CloseButton: React.FC<CloseButtonProps> = ({ handleClose }) => {
  return (
    <button
      type="button"
      onClick={handleClose}
      className="close-chat-button"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  );
};

export default CloseButton;
