import React, { Component } from "react";
import { ITranscriptPhase } from "../../store/interface";

interface ThumbnailPhasesProps {
  data: ITranscriptPhase[];
}

class ThumbnailPhases extends Component<ThumbnailPhasesProps> {
  render() {
    const phases = this.props.data;
    if (phases.length <= 0) {
      return (<></>);
    }
    const totalVideoTime = phases[phases.length - 1].end;
    const phasePercentages = phases.map(
      (phase) => (phase.duration / totalVideoTime) * 100
    );

    return (
      <div>
        <ul className="phase-container">
          {phases.map((phase, index) => (
            <li
              key={index}
              className="phase-items"
              style={{ width: `${phasePercentages[index]}%` }}
            >
              {phase.name}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default ThumbnailPhases;
