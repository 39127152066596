import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//Import Images
import error from "../../assets/images/error-img.png";

interface Pages404Props {
  history: {
    push: any;
  };
}

class Pages404 extends Component<Pages404Props> {

  redirectToDashboard = () => {
    this.props.history.push("/dashboard");
    window.location.reload();
  };

  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  <h1 className="display-2 font-weight-medium">Error!</h1>
                  <h4 className="text-uppercase error-text">
                    Oh, something went wrong here, try reloading, please contact
                    support if this issue continues.
                  </h4>
                  <div className="mt-5 text-center">
                    <div
                      className="btn btn-primary waves-effect waves-light"
                      onClick={this.redirectToDashboard}
                    >
                      Back to Dashboard
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="8" xl="6">
                <div>
                  <img src={error} alt="" className="img-fluid" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(null, {})(Pages404));
