import { IAIAgent } from '../interface';
import { GET_AGENTS, GET_AGENTS_ERROR, GET_AGENTS_SUCCESS } from './actionTypes';

export const getAvailableAIAgents = (accountId: String) => ({
  type: GET_AGENTS,
  payload: accountId,
});

export const getAvailableAIAgentsSuccess = (availableAIAgents: IAIAgent[]) => ({
  type: GET_AGENTS_SUCCESS,
  payload: availableAIAgents,
});

export const getAvailableAIAgentsError = (error: string | object) => ({
  type: GET_AGENTS_ERROR,
  payload: error,
});
