/* LAYOUT */
export const GET_DASHBOARDS = "GET_DASHBOARDS";
export const GET_DASHBOARDS_ERROR = "GET_DASHBOARDS_ERROR";
export const GET_DASHBOARDS_SUCCESS = "GET_DASHBOARDS_SUCCESS";
export const EMPTY_DASHBOARDS = "EMPTY_DASHBOARDS";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";

//loader state
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const SETTINGS_MENU = "SETTINGS_MENU";

export const RECORDINGS_MENU = "RECORDINGS_MENU";

export const CREATE_WIDGET_LAYOUT = "CREATE_WIDGET_LAYOUT";
