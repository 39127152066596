import axios, { AxiosResponse } from 'axios';
import { ResponseGenerator } from '../../userservice';
import { urls } from '../../../utilities/urls';
import { endpoints } from '../../../utilities/endpoints';
import { commonheaders } from '../../../utilities/apiConfig';
import { ITelavoxToken } from "../../../store/settings/Telavox/interface";

export async function getTelavoxToken(): Promise<AxiosResponse<ITelavoxToken|null>>
{
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}tokens/${endpoints.telavoxToken}`,
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function createTelavoxToken(telavoxToken: ITelavoxToken): Promise<AxiosResponse<ITelavoxToken>>
{
  try {
    const response: ResponseGenerator = await axios.post(
      `${urls.baseApiURL}tokens/${endpoints.telavoxToken}`,
      telavoxToken,
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function editTelavoxToken(telavoxToken: ITelavoxToken): Promise<AxiosResponse<ITelavoxToken>>
{
  try {
    const response: ResponseGenerator = await axios.put(
      `${urls.baseApiURL}tokens/${endpoints.telavoxToken}/${telavoxToken.id}`,
      telavoxToken,
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function deleteTelavoxToken(id: string): Promise<AxiosResponse<null>>
{
  try {
    const response: ResponseGenerator = await axios.delete(
      `${urls.baseApiURL}tokens/${endpoints.telavoxToken}/${id}`,
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}
