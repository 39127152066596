import React, { Component } from 'react';
import MultipleSelect from '../../MultipleSelect';
import { DropdownOption } from '../../../pages/Home/utils/constants';
import {
  convertToDropdownOptions,
  filterOutUsersByTeam,
} from '../utils/helperfunctions';
import {
  FilterObject,
  IAccount,
  ICallOutcome,
  ICampaign,
  IDealStatus,
  IFilterTeam,
  IFilterUser,
  IMeetingTypes,
  IRecordingType,
} from '../../../store/interface';
import DateFilterControlled from '../../Filters/DateFilterControlled';
import RecordingsSearcher from '../../RecordingsSearcher';
import { noop } from 'lodash';

interface DashboardGlobalFilterComponentProps {
  currentaccountselected: IAccount;
  filters: FilterObject;
  isMeetingOrRecording: boolean;
  recordingTypes: IRecordingType[];
  campaigns: ICampaign[];
  meetingTypes: IMeetingTypes[];
  filterUsers: IFilterUser[];
  filterTeams: IFilterTeam[];
  callsOutcome: ICallOutcome[];
  dealsStatus: IDealStatus[];
  isForcedCampaigns: boolean;
  handleDateChange: (
    startDate: string | null,
    endDate: string | null,
    globalPeriod: null | string
  ) => void;
  updateFilter: (
    filterType: string,
    options: DropdownOption[] | DropdownOption
  ) => void;
}

interface DashboardGlobalFilterComponentState {
  dropdownOpen: boolean,
  backupNonSessionIdFilters: FilterObject | null,
}

const emptyValues: FilterObject = {
  callsOutcome: [],
  campaigns: [],
  dealsStatus: [],
  filterTeams: [],
  filterUsers: [],
  meetingTypes: [],
  recordingTypes: [],
  globalPeriod: "0",
  endDate: null,
  startDate: null,
};

class DashboardGlobalFilterComponent extends Component<
  DashboardGlobalFilterComponentProps,
  DashboardGlobalFilterComponentState
> {
  constructor(props: DashboardGlobalFilterComponentProps) {
    super(props);
    this.state = {
      dropdownOpen: false,
      backupNonSessionIdFilters: null,
    };
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  updateSessionIdFilter = (sessionId: string) => {
    const { filters, updateFilter, handleDateChange } = this.props;
    const { backupNonSessionIdFilters } = this.state;

    // TODO: deduplicate the logic in this if/elseif and render()
    if (sessionId) {
      this.setState({ backupNonSessionIdFilters: filters });
      for (let key in filters) {
        if (key in emptyValues && !['startDate', 'endDate', 'globalPeriod', 'filterUsers', 'filterTeams'].includes(key)) {
          // @ts-ignore
          updateFilter(key, convertToDropdownOptions(emptyValues[key], key));
        }
      }

      // @ts-ignore
      const filterTeamsValue = convertToDropdownOptions(emptyValues.filterTeams);
      updateFilter('filterTeams', filterTeamsValue);
      // @ts-ignore
      const filterUsersValue = filterOutUsersByTeam(filterTeamsValue, emptyValues.filterUsers);
      updateFilter('filterUsers', filterUsersValue);

      handleDateChange(emptyValues.startDate, emptyValues.endDate, emptyValues.globalPeriod);
    } else if (backupNonSessionIdFilters) {
      this.setState({ backupNonSessionIdFilters: null });
      for (let key in backupNonSessionIdFilters) {
        if (!['startDate', 'endDate', 'globalPeriod', 'filterUsers', 'filterTeams'].includes(key)) {
          // @ts-ignore
          updateFilter(key, convertToDropdownOptions(backupNonSessionIdFilters[key], key));
        }
      }

      // @ts-ignore
      const filterTeamsValue = convertToDropdownOptions(backupNonSessionIdFilters.filterTeams);
      updateFilter('filterTeams', filterTeamsValue);
      // @ts-ignore
      const filterUsersValue = filterOutUsersByTeam(filterTeamsValue, backupNonSessionIdFilters.filterUsers);
      updateFilter('filterUsers', filterUsersValue);

      handleDateChange(backupNonSessionIdFilters.startDate, backupNonSessionIdFilters.endDate, backupNonSessionIdFilters.globalPeriod);
    }
    updateFilter('filterSession', { id: sessionId, label: '', value: sessionId })
  }

  render() {
    const {
      isMeetingOrRecording,
      currentaccountselected,
      recordingTypes,
      campaigns,
      meetingTypes,
      filterTeams,
      filterUsers,
      callsOutcome,
      dealsStatus,
      filters,
      isForcedCampaigns,
      updateFilter,
      handleDateChange,
    } = this.props;

    const { dropdownOpen } = this.state;

    const recordingTypeOptions = convertToDropdownOptions(
      recordingTypes,
      'recordingTypes'
    );
    const recordingTypeDefaultValue = convertToDropdownOptions(
      filters.recordingTypes,
      'recordingTypes'
    );

    const campaignOptions = convertToDropdownOptions(campaigns, 'campaigns');
    const campaignDefaultValue = convertToDropdownOptions(
      filters.campaigns,
      'campaigns'
    );

    const meetingTypeOptions = convertToDropdownOptions(meetingTypes);
    const meetingTypeDefaultValue = convertToDropdownOptions(
      filters.meetingTypes
    );

    const filterTeamOptions = convertToDropdownOptions(filterTeams);
    const teamDefaultValue = convertToDropdownOptions(filters.filterTeams);

    const filterUserOptions = filterOutUsersByTeam(
      teamDefaultValue,
      filterUsers
    );
    const userDefaultValue = filterOutUsersByTeam(
      teamDefaultValue,
      filters.filterUsers
    );

    const dealsStatusOptions = convertToDropdownOptions(
      dealsStatus,
      'dealsStatus'
    );
    const dealsStatusDefaultValue = convertToDropdownOptions(
      filters.dealsStatus,
      'dealsStatus'
    );

    const callsOutcomeOptions = convertToDropdownOptions(
      callsOutcome,
      'callsOutcome'
    );
    const callsOutcomeDefaultValue = convertToDropdownOptions(
      filters.callsOutcome,
      'callsOutcome'
    );

    const activeMoreFilters = [
      recordingTypeDefaultValue,
      campaignDefaultValue,
      dealsStatusDefaultValue,
      callsOutcomeDefaultValue,
      meetingTypeDefaultValue,
    ].filter(arr => arr.length).length;

    return (
      <>
        <div className="filters" style={{ alignItems: 'center' }}>
          <div className="filters__title">Filter by</div>
          <div className="filters__search">
            <RecordingsSearcher
              isClearable
              accountId={currentaccountselected?.id as string}
              sessionId={filters.filterSession || ''}
              placeholder="Search meetings"
              onSessionChange={this.updateSessionIdFilter}
              onOpenClose={noop}
            />
          </div>
          <div className="filters__filter short">
            <MultipleSelect
              isDisabled={!!filters.filterSession}
              defaultValue={teamDefaultValue}
              onChange={(options: DropdownOption[]) => {
                updateFilter('filterTeams', options);
              }}
              options={filterTeamOptions}
              placeholder="Teams"
            />
          </div>
          <div className="filters__filter short">
            <MultipleSelect
              isDisabled={!!filters.filterSession}
              defaultValue={userDefaultValue}
              onChange={(options: DropdownOption[]) => {
                updateFilter('filterUsers', options);
              }}
              options={filterUserOptions}
              placeholder="Users"
            />
          </div>
          <div
            className="filters__filter filters__filter--date-time"
            style={{
              backgroundColor: 'white',
              color: '#ced4da',
              borderRadius: '4px',
            }}
          >
            <DateFilterControlled
              isDisabled={!!filters.filterSession}
              startDate={filters.startDate}
              endDate={filters.endDate}
              periodValue={filters.globalPeriod}
              onDateChange={handleDateChange}
            />
          </div>

          <div className="filters__filter" style={{ position: 'relative', minWidth: activeMoreFilters ? '135px' : '' }}>
            <button disabled={!!filters.filterSession} className="btn more-filters-button" onClick={this.toggleDropdown}>
              <span className="more-filters-button__text">
                {dropdownOpen ? 'Hide filters' : `More filters ${activeMoreFilters ? `(${activeMoreFilters})` : ''}`}
              </span>
              <i className={`more-filters-button__icon mdi mdi-chevron-${dropdownOpen ? 'up' : 'down'}`}></i>
            </button>

            {dropdownOpen && (
              <div className="more-filters-dropdown">
                <div className="more-filters-dropdown__content">
                  <div className="filters__filter long">
                    <MultipleSelect
                      isDisabled={!!filters.filterSession}
                      defaultValue={recordingTypeDefaultValue}
                      onChange={(options: DropdownOption[]) => {
                        updateFilter('recordingTypes', options);
                      }}
                      options={recordingTypeOptions}
                      placeholder="Recording Type"
                    />
                  </div>
                  {!isForcedCampaigns && (
                    <div className="filters__filter medium">
                      <MultipleSelect
                        isDisabled={!!filters.filterSession}
                        defaultValue={campaignDefaultValue}
                        onChange={(options: DropdownOption[]) => {
                          updateFilter('campaigns', options);
                        }}
                        options={campaignOptions}
                        placeholder="Campaign"
                      />
                    </div>
                  )}
                  <div className="filters__filter long">
                    <MultipleSelect
                      isDisabled={!!filters.filterSession}
                      defaultValue={dealsStatusDefaultValue}
                      onChange={(options: DropdownOption[]) => {
                        updateFilter('dealsStatus', options);
                      }}
                      options={dealsStatusOptions}
                      placeholder="Deal Status"
                    />
                  </div>
                  <div className="filters__filter long">
                    <MultipleSelect
                      isDisabled={!!filters.filterSession}
                      defaultValue={callsOutcomeDefaultValue}
                      onChange={(options: DropdownOption[]) => {
                        updateFilter('callsOutcome', options);
                      }}
                      options={callsOutcomeOptions}
                      placeholder="Call Outcome"
                    />
                  </div>
                  <div className="filters__filter long">
                    <MultipleSelect
                      isDisabled={!!filters.filterSession}
                      defaultValue={meetingTypeDefaultValue}
                      onChange={(options: DropdownOption[]) => {
                        updateFilter('meetingTypes', options);
                      }}
                      options={meetingTypeOptions}
                      placeholder="Meeting Types"
                    />
                  </div>
                </div>

                <div
                  className="more-filters-dropdown__backdrop"
                  onClick={this.toggleDropdown}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default DashboardGlobalFilterComponent;
