import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { GET_FONTS, UPLOAD_FONTS, EDIT_FONTS } from "./actionTypes";
import { getFontsSuccess, fontsApiError, uploadFontsSuccess, editFontsSuccess } from "./actions";

//services
import { getFonts, uploadFonts, editFonts, ResponseGenerator } from "../../services/userservice";
import _ from "lodash";

function* getFontsHelper({ payload: { id } }: any) {
  const response: ResponseGenerator = yield call(getFonts, id);
  if (_.has(response, "data")) {
    yield put(getFontsSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(fontsApiError(error));
    } else {
      yield put(fontsApiError("An Error has occured"));
    }
  }
}

function* uploadFontsHelper({ payload: { id, fileObj } }: any) {
  const response: ResponseGenerator = yield call(uploadFonts, id, fileObj);
  if (_.has(response, "data")) {
    yield put(uploadFontsSuccess("Fonts Uploaded Successfully"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(fontsApiError(error));
    } else {
      yield put(fontsApiError("An Error has occured"));
    }
  }
}

function* editFontsHelper({ payload: { id, params, fontId } }: any) {
  const response: ResponseGenerator = yield call(editFonts, id, params, fontId);
  if (_.has(response, "data")) {
    yield put(editFontsSuccess("Fonts Updated Successfully"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(fontsApiError(error));
    } else {
      yield put(fontsApiError("An Error has occured"));
    }
  }
}

export function* watchGetFonts() {
  yield takeEvery(GET_FONTS, getFontsHelper);
}

export function* watchUploadFonts() {
  yield takeEvery(UPLOAD_FONTS, uploadFontsHelper);
}

export function* watchEditFonts() {
  yield takeEvery(EDIT_FONTS, editFontsHelper);
}

function* fontsSaga() {
  yield all([fork(watchGetFonts), fork(watchUploadFonts), fork(watchEditFonts)]);
}

export default fontsSaga;
