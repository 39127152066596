import {
  GET_KEYWORDS,
  GET_KEYWORDS_SUCCESS,
  GET_KEYWORDS_ERROR,
  GET_KEYWORD_GROUPS,
  GET_KEYWORD_GROUPS_SUCCESS,
  GET_KEYWORD_GROUPS_ERROR,
  GET_KEYWORD_LANGUAGES,
  GET_KEYWORD_LANGUAGES_ERROR,
  GET_KEYWORD_LANGUAGES_SUCCESS,
} from "./actionTypes";

import { IKeywordLanguage } from "./interface";
import {
  IAnalyticsKeywordsData,
  IKeywordsGroup
} from '../../pages/Settings/Analytics/IAnalyticTypes';

export const getKeywords = (accountId: String) => {
  return {
    type: GET_KEYWORDS,
    payload: { accountId },
  }
}

export const getKeywordsSuccess = (keywords: IAnalyticsKeywordsData[]) => {
  return {
    type: GET_KEYWORDS_SUCCESS,
    payload: keywords,
  }
}

export const getKeywordsError = (error: String | Object) => {
  return {
    type: GET_KEYWORDS_ERROR,
    payload: error,
  }
}

export const getKeywordGroups = (accountId: String) => {
  return {
    type: GET_KEYWORD_GROUPS,
    payload: { accountId },
  }
}

export const getKeywordGroupsSuccess = (keywordGroups: IKeywordsGroup[]) => {
  return {
    type: GET_KEYWORD_GROUPS_SUCCESS,
    payload: keywordGroups,
  }
}

export const getKeywordGroupsError = (error: String | Object) => {
  return {
    type: GET_KEYWORD_GROUPS_ERROR,
    payload: error,
  }
}

export const getKeywordLanguages = (accountId: String) => {
  return {
    type: GET_KEYWORD_LANGUAGES,
    payload: { accountId },
  }
}

export const getKeywordLanguagesSuccess = (keywordLanguages: IKeywordLanguage[]) => {
  return {
    type: GET_KEYWORD_LANGUAGES_SUCCESS,
    payload: keywordLanguages,
  }
}

export const getKeywordLanguagesError = (error: String | Object) => {
  return {
    type: GET_KEYWORD_LANGUAGES_ERROR,
    payload: error,
  }
}
