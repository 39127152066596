import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  getKeywordsWithLanguages,
  getAnalyticsKeywords,
  getKeywordsGroup,
} from "../../services/analytics/analyticsservice";
import { ResponseGenerator } from "../../services/userservice";
import { has } from "lodash";
import {
  getKeywords as getKeywordsAction,
  getKeywordsError,
  getKeywordsSuccess,
  getKeywordGroups as getKeywordGroupsAction,
  getKeywordGroupsError,
  getKeywordGroupsSuccess,
  getKeywordLanguages as getKeywordLanguagesAction,
  getKeywordLanguagesError,
  getKeywordLanguagesSuccess,
} from "./actions";
import { GET_KEYWORD_GROUPS, GET_KEYWORD_LANGUAGES, GET_KEYWORDS } from "./actionTypes";
import { IKeywordsGroup } from '../../pages/Settings/Analytics/IAnalyticTypes';

function* getKeywordsHelper({ payload }: ReturnType<typeof getKeywordsAction>) {
  const response: ResponseGenerator = yield call(getAnalyticsKeywords, payload.accountId);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getKeywordsError(error));
    } else {
      yield put(getKeywordsError("An error has occurred!!"));
    }
  } else {
    yield put(getKeywordsSuccess(response.data));
  }
}

function* getKeywordGroupsHelper({ payload }: ReturnType<typeof getKeywordGroupsAction>) {
  const response: ResponseGenerator = yield call(getKeywordsGroup, payload.accountId);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getKeywordGroupsError(error));
    } else {
      yield put(getKeywordGroupsError("An error has occurred!!"));
    }
  } else {
    yield put(getKeywordGroupsSuccess(response as IKeywordsGroup[]));
  }
}

function* getKeywordLanguagesHelper({ payload }: ReturnType<typeof getKeywordLanguagesAction>) {
  const response: ResponseGenerator = yield call(getKeywordsWithLanguages, payload.accountId);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getKeywordLanguagesError(error));
    } else {
      yield put(getKeywordLanguagesError("An error has occurred!!"));
    }
  } else {
    yield put(getKeywordLanguagesSuccess(response.data));
  }
}

export function* watchKeywordLanguagesHelper() {
  yield takeEvery(GET_KEYWORD_LANGUAGES, getKeywordLanguagesHelper);
}

export function* watchKeywordsHelper() {
  yield takeEvery(GET_KEYWORDS, getKeywordsHelper);
}

export function* watchKeywordGroupsHelper() {
  yield takeEvery(GET_KEYWORD_GROUPS, getKeywordGroupsHelper);
}

function* KeywordSaga() {
  yield all([
    fork(watchKeywordLanguagesHelper),
    fork(watchKeywordsHelper),
    fork(watchKeywordGroupsHelper),
  ]);
}

export default KeywordSaga;
