import React, { Component } from "react";
import createMovieIcon from "../../assets/images/create-video.svg";

class CreatingSnippetMessage extends Component {
  render() {
    return (
      <div>
        <div className="snippet-message-container">
          <div className="create-snippet-text">
            <img
              src={createMovieIcon}
              alt="create movie"
              className="video-icon"
            />
            <h1 className="headline-text">Creating recording snippet</h1>
            <h4 className="headline-details-text">Please come back in a few minutes</h4>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatingSnippetMessage;
