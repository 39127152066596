const stagingURL = "https://dashboard.staging.pitchflow.io";
const acceptFlowURL = "https://app.acceptflow.com";
const acceptStackURL = "https://app.acceptstack.com";
const meetricURL = "https://app.meetric.com";
const pitchflowURL = "https://app.pitchflow.com";
const localURL = "http://localhost:3000";

export const isStagingURL = window.location.origin === stagingURL;
export const isAcceptFlowURL = window.location.origin === acceptFlowURL;
export const isAcceptStackURL = window.location.origin === acceptStackURL;
export const isMeetricURL = window.location.origin === meetricURL;
export const isPitchflowURL = window.location.origin === pitchflowURL;
export const isLocalURL = window.location.origin === localURL;

let paths = {
    editorURL: "",
    baseApiURL: "",
    baseIframeURL: "",
    baseURL: "",
    loginUrl: "",
};

if (isStagingURL) {
    paths = {
        editorURL: "https://presentation.acceptflow.com/editor",
        baseApiURL: "https://api.acceptflow.com/api/",
        baseIframeURL: "https://presentation.acceptflow.com/presentation/preview",
        baseURL: "https://presentation.acceptflow.com",
        loginUrl: "/login",
    }
} else if (isAcceptFlowURL) {
    paths = {
        editorURL: "https://presentation.acceptflow.com/editor",
        baseApiURL: "https://api.acceptflow.com/api/",
        baseIframeURL: "https://presentation.acceptflow.com/presentation/preview",
        baseURL: "https://presentation.acceptflow.com",
        loginUrl: "/login",
    }
}
else if (isAcceptStackURL) {
    paths = {
        editorURL: "https://presentation.acceptstack.com/editor",
        baseApiURL: "https://api.acceptstack.com/api/",
        baseIframeURL: "https://presentation.acceptstack.com/presentation/preview",
        baseURL: "https://presentation.acceptstack.com",
        loginUrl: "/login",
    }
} else if (isPitchflowURL) {
    document.location = document
        .location
        .href
        .replace("pitchflow.com","meetric.com");
} else if (isLocalURL) {
    paths = {
        editorURL: "https://presentation.acceptflow.com/editor",
        baseApiURL: "http://localhost:8000/api/",
        baseIframeURL: "https://presentation.acceptflow.com/presentation/preview",
        baseURL: "https://presentation.acceptflow.com",
        loginUrl: "/login",
    }
} else {
    paths = {
        editorURL: "https://presentation.meetric.com/editor",
        baseApiURL: "https://api.meetric.com/api/",
        baseIframeURL: "https://presentation.meetric.com/presentation/preview",
        baseURL: "https://presentation.meetric.com",
        loginUrl: "/login",
    }
}

export const urls = {
    editorURL: paths.editorURL,
    baseApiURL: paths.baseApiURL,
    baseIframeURL: paths.baseIframeURL,
    baseURL: paths.baseURL,
    loginUrl: paths.loginUrl,
}
