import {
  CREATE_RECEIVER,
  CREATE_RECEIVER_SUCCESS,
  CREATE_RECEIVER_ERROR,
  GET_RECEIVER,
  GET_RECEIVER_SUCCESS,
  GET_RECEIVER_ERROR,
} from "./actionTypes";
import { ReceiversAction, ReceiverState } from "./interface";

const initialState: ReceiverState = {
  error: "",
  receiver: null,
  receiverData: "",
  loader: false,
  getLoader: false,
};

const receivers = (state = initialState, action: ReceiversAction) => {
  switch (action.type) {
    case CREATE_RECEIVER:
      state = {
        ...state,
        error: "",
        receiverData: "",
        loader: true,
      };
      break;
    case CREATE_RECEIVER_SUCCESS:
      state = {
        ...state,
        receiverData: action.payload,
        loader: false,
      };
      break;
    case CREATE_RECEIVER_ERROR:
      state = {
        ...state,
        error: action.payload,
        loader: false,
      };
      break;
    case GET_RECEIVER:
      state = {
        ...state,
        error: "",
        receiver: null,
        loader: true,
      };
      break;
    case GET_RECEIVER_SUCCESS:
      state = {
        ...state,
        receiver: action.payload,
        loader: false,
      };
      break;
    case GET_RECEIVER_ERROR:
      state = {
        ...state,
        error: action.payload,
        loader: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default receivers;
