import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { IMeetingBot } from '../../store/settings/Calendars/interface';
import { getBotLogsForMeetingBot } from '../../services/userservice';
import TableLayout from '../../components/TableLayout';
import { isEmpty } from 'lodash';
import { limitStringCharacters } from '../../services/utilities/newUtilservice';
import { formatDateTime } from '../../services/utilities/utilservice';

interface MeetingLogsModalProps {
  meetingId: string;
  onClose: () => void;
}

interface MeetingLogsModalState {
  isLoading: boolean;
  meetingLogs: IMeetingBot[];
}

const MEETING_BOT_HEADERS = ['Event ID', 'Event Name', 'Created at'];

class MeetingLogsModal extends Component<MeetingLogsModalProps, MeetingLogsModalState>
{
  constructor(props: MeetingLogsModalProps) {
    super(props);
    this.state = {
      isLoading: true,
      meetingLogs: [],
    }
  }

  componentDidMount() {
    this.fetchMeetingLogs();
  }

  componentDidUpdate(prevProps: Readonly<MeetingLogsModalProps>) {
    const { meetingId: prevMeetingId } = prevProps;
    const { meetingId } = this.props;

    if (prevMeetingId !== meetingId) {
      this.fetchMeetingLogs();
    }
  }

  fetchMeetingLogs = async () => {
    const { meetingId } = this.props;

    let meetingLogs = [];

    this.setState({
      isLoading: true,
    })

    try {
      const response = await getBotLogsForMeetingBot(meetingId);

      meetingLogs = response.data;
    } catch (e: any) {
      console.info('Failed fetching meeting bot logs...');
    }

    this.setState({
      isLoading: false,
      meetingLogs,
    })
  }

  render() {
    const { onClose } = this.props;
    const { meetingLogs, isLoading } = this.state;

    return (
      <Modal
        centered
        isOpen
        size="lg"
        className="recording-modal modal-dialog"
      >
        <ModalHeader>
          Meeting Bot Logs
          <button
            onClick={onClose}
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            className="keyword-close-button"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody style={{ height: '70vh' }}>
          <div style={{ height: '100%', overflowY: 'auto' }}>
            <TableLayout tableHeaders={MEETING_BOT_HEADERS} loading={isLoading}>
              {!isEmpty(meetingLogs) && meetingLogs.length > 0 ? (
                meetingLogs.map((log, index) => (
                  <tr key={log.id}>
                    <td>{index + 1}</td>
                    <td>{log.id}</td>
                    <td>{limitStringCharacters(log.event_name)}</td>
                    <td>{formatDateTime(log.created_at)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} style={{ marginTop: '10px', textAlign: 'center' }}>
                    <h5>No meeting bot information for this meeting.</h5>
                  </td>
                </tr>
              )}
            </TableLayout>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default MeetingLogsModal;
