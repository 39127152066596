import React, { CSSProperties } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Select from 'react-select';

import EarthImg from "../../assets/images/earth-americas-solid.svg";
import LinkImg from "../../assets/images/link-solid.svg";
import LockImg from "../../assets/images/lock.svg";
import { formatTime } from '../../services/utilities/utilservice';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = (data: any) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const OPTIONS = [{
  label: "All comments",
  value: "all",
  id: 1
}, {
  label: "None",
  value: "none",
  id: 4
}];

const GENERAL_ACCESS_OPTIONS = [{
  label: "All comments",
  value: "all",
  id: 1
}, {
  label: "Account comments",
  value: "account",
  id: 2
}, {
  label: "Own teams comments",
  value: "team",
  id: 3
}];

const PUBLIC_ACCESS_ENABLE_OPTIONS = [{
  label: "Anyone with the link",
  value: "anyone",
  id: 1,
}, {
  label: "Restricted",
  value: "disabled",
  id: 2,
}];

type Props = {
  isEdit: boolean;
  snippetStartSeconds: any;
  snippetEndSeconds: any;
  snippetName: any
  snippetDescription: any
  handleChangeSnippetName: any;
  handleChangeSnippetDescription: any;
  shareRecording: any;
  handleSnippetSubmit: any;
  toggleAddSnippet: any;
  meeting: any;
  onSelectSharedTarget: any;
  onHandleGeneralAccess: any;
  groupedOptions: any
  shareToUser: { value: string, label: string; email: string, type: number, team_id: string, user_id: string, can_view: string, can_comment: boolean }[];
  generalAccess: any;
  removeShareUser: (label: string) => void;
  canViewHandler: any;
  canCommentHandler: any;
  handleSubmitCreateAndShare: any;
  showClipboardMessage: boolean;
  changeShareRecording: any;
  closeShareModal: any;
}

function SaveAndShareModal({
  isEdit,
  snippetStartSeconds,
  snippetEndSeconds,
  snippetName,
  snippetDescription,
  handleChangeSnippetName,
  handleChangeSnippetDescription,
  shareRecording,
  handleSnippetSubmit,
  toggleAddSnippet,
  meeting,
  onSelectSharedTarget,
  onHandleGeneralAccess,
  groupedOptions,
  shareToUser,
  generalAccess,
  removeShareUser,
  canViewHandler,
  canCommentHandler,
  handleSubmitCreateAndShare,
  showClipboardMessage,
  changeShareRecording,
  closeShareModal,
}: Props) {

  const getValue = (value: string) => {
    return OPTIONS.filter(option => option.value === value);
  }

  const isAnyoneAccess = generalAccess === "anyone";

  return (
    <Modal isOpen={true} className="modal-share-snippet">

      <ModalHeader>
        {
          shareRecording ?
            "Share snippet" :
            <>Save to snippets <span>{formatTime(snippetStartSeconds)} - {formatTime(snippetEndSeconds)}</span></>
        }
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col lg='12'>
              <Label>Name</Label>
              <Input
                type="text"
                value={snippetName}
                onChange={(event: any) => handleChangeSnippetName(event?.target.value)}
                placeholder="Snippet name" />
              <Label>Description</Label>
              <Input
                type="textarea"
                defaultValue={snippetDescription}
                onChange={(event: any) => handleChangeSnippetDescription(event?.target.value)}
                placeholder="Write something about your snippet ( optional )"
              />
              {
                !isEdit && (
                  <FormGroup check>
                    <Input type="checkbox" checked={shareRecording} onChange={changeShareRecording} />
                    {' '}
                    <Label check>
                      Generate sharable link
                    </Label>
                  </FormGroup>
                )
              }
              {
                !shareRecording &&
                <ModalFooter className="modal-footer-share">
                  <Button color="primary" onClick={handleSnippetSubmit}>
                    Save
                  </Button>
                  <Button color="secondary" onClick={toggleAddSnippet}>
                    Cancel
                  </Button>
                </ModalFooter>
              }
              {
                shareRecording &&
                <>
                  <h5>Add access to shared link:</h5>
                  <Select
                    value={null}
                    onChange={onSelectSharedTarget}
                    options={groupedOptions}
                    formatGroupLabel={formatGroupLabel}
                  />
                  {shareToUser.length > 0 && (
                    <div className='people-with-access-header'>
                      <div className='header-part-1'>People with access</div>
                      <div className='header-part-2 hidden'>View comments</div>
                      <div className='header-part-3 hidden'>Can comment</div>
                    </div>
                  )}
                  <div className='people-with-access'>
                    {
                      shareToUser.map((user, index) => (
                        <div className='user-with-access' key={index}>
                          <div className='user-picture-container'>
                            <div className='user-picture'><p>{user.label.charAt(0)}</p></div>
                          </div>
                          <div className='user-name-email'>
                            <div className='user-name'>
                              {user.label}
                            </div>
                            <div className='user-email'>
                              {user.email}
                            </div>
                          </div>
                            <Select
                              value={getValue(user.can_view)}
                              className='user-comments transparent'
                              options={OPTIONS}
                              isDisabled={true}
                              onChange={(option: any) => canViewHandler(user.label, option)
                              }

                          />
                          <div className='can-comment'>
                            <Input type="checkbox"
                              className='transparent'
                              checked={false}
                              disabled={true}
                              onChange={() => canCommentHandler(user.label)} />
                          </div>
                          <a
                            className='close-btn'
                            onClick={() => removeShareUser(user.label)}
                          >
                            <i className='fa fa-times'></i>
                          </a>
                        </div>
                      ))
                    }
                  </div>
                  <div className='people-with-access'>
                    <div className='user-with-access'>
                      <div className='general-access-text'>Public access</div>
                      <div className="link-icon user-picture-container">
                      </div>
                    </div>
                    <div className="user-with-access">
                      <div className="link-icon user-picture-container">
                        <div className='icon-container' style={{backgroundColor: isAnyoneAccess ? "#E6F4EA" : "#DFDFDF"}}>
                          {isAnyoneAccess ?
                            <img src={EarthImg} alt="planet icons" className='earth-icon'></img> :
                            <img src={LockImg} alt="lock" className='earth-icon'></img>
                          }
                        </div>
                      </div>
                      <div className='link-description user-name-email'>
                        <select
                          className='public-access-select'
                          onChange={(option: any) => onHandleGeneralAccess(option.target.value)}
                        >
                          {
                            PUBLIC_ACCESS_ENABLE_OPTIONS.map((option: any) => {
                              return <option value={option.value} selected={generalAccess === option.value}>{option.label}</option>
                            })
                          }
                        </select>
                        <div className='content-text'>{isAnyoneAccess ? "Anyone with the link" : "Only people with access"}</div>
                      </div>
                      {isAnyoneAccess ? (
                        <>
                          <Select
                            isDisabled={true}
                            value={getValue(generalAccess.can_view)}
                            className='user-comments transparent'
                            onChange={(option: any) => onHandleGeneralAccess(option.value)}
                            options={GENERAL_ACCESS_OPTIONS}
                          />
                          <div className='can-comment'>
                            <div className='can-comment'>
                              <Input
                                type="checkbox"
                                className='transparent'
                                disabled={true}
                                checked={generalAccess.can_comment}
                              />
                            </div>
                          </div></>) : (
                        <div className="public-access-anyone"></div>
                      )}
                    </div>

                  </div>
                </>
              }
            </Col>
          </Row>
        </Form>
      </ModalBody>
      {
        shareRecording &&
        <ModalFooter>
          <div className='botton-message-container'>
            <Button color="primary button-link" onClick={handleSubmitCreateAndShare}>
              <img src={LinkImg} alt="copy-link" className="link-icon" /> Copy Link
            </Button>
            {
              showClipboardMessage && <p className='clipboard-message'>Link copied to clipboard!</p>
            }
          </div>
          <div className='botton-message-container'>
          {
            !isEdit && (
              <Button color="btn btn-secondary" onClick={toggleAddSnippet}>
                Cancel
              </Button>
            )
          }
          <Button color="btn btn-info" onClick={() => closeShareModal(true)}>
            {!isEdit ? 'Save & close' : 'Done'}
          </Button>
          </div>

        </ModalFooter>
      }
    </Modal>);

}

export { SaveAndShareModal }
