import React, { Component } from 'react';
import { Table } from 'reactstrap';
import './LoadingTable.scss';

interface loadingTableProps {
	headers: String[];
	rows: Number;
	columns: String[];
}

class LoadingTable extends Component<loadingTableProps> {
	render() {
		return (
			<React.Fragment>
				<div className='table-responsive mt-4'>
					<Table className='table-centered table-nowrap table-hover loadingTable '>
						<thead className='thead-light'>
							<tr>
								<th scope='col' style={{ width: '70px' }}>
									#
								</th>
								{this.props.headers.map((data , key) => {
									return <th key={key}>{data}</th>;
								})}
							</tr>
						</thead>
						<tbody>
							{new Array(this.props.rows).fill(0).map((data, key) => {
								return (
									<tr key={key}>
										{this.props.columns.map((data, key) => {
											return (
												<td key={key} className={data ? 'tdbutton' : 'tdtext'}>
													<span></span>
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</Table>
				</div>
			</React.Fragment>
		);
	}
}

export default LoadingTable;
