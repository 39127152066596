import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { has } from "lodash";

import {
  createCallTokenSettings,
  createCallTokenSettingsError,
  createCallTokenSettingsSuccess,
  deleteCallTokenSettings,
  deleteCallTokenSettingsError,
  deleteCallTokenSettingsSuccess,
  getAdversusRules,
  getAdversusRulesError,
  getAdversusRulesSuccess,
  getCallTokens,
  getCallTokensError,
  getCallTokenSettings,
  getCallTokenSettingsError,
  getCallTokenSettingsSuccess,
  getCallTokensSuccess,
  updateCallTokenSettings,
  updateCallTokenSettingsError,
  updateCallTokenSettingsSuccess,
  createAdversusRule,
  createAdversusRuleSuccess,
  createAdversusRuleError,
  editAdversusRule,
  editAdversusRuleError,
  editAdversusRuleSuccess,
  deleteAdversusRule,
  deleteAdversusRuleError,
  deleteAdversusRuleSuccess
} from './actions';

import {
  CREATE_CALL_TOKEN_SETTINGS,
  DELETE_CALL_TOKEN_SETTINGS,
  GET_ADVERSUS_RULES,
  CREATE_ADVERSUS_RULE,
  EDIT_ADVERSUS_RULE,
  DELETE_ADVERSUS_RULE,
  GET_CALL_TOKEN,
  GET_CALL_TOKEN_SETTINGS,
  UPDATE_CALL_TOKEN_SETTINGS,
} from './actionTypes';

import {
  createTokenSettings as createTokenSettingsService,
  deleteTokenSettings as deleteTokenSettingsService,
  getCallTokens as getCallTokensService,
  getCallTokenSettings as getCallTokenSettingsService,
  updateTokenSettings as updateTokenSettingsService,
  getAdversusRules as getAdversusRulesService,
  createAdversusRule as createAdversusRuleService,
  editAdversusRule as editAdversusRuleService,
  deleteAdversusRule as deleteAdversusRuleService,
} from '../../services/settings/call-import';

import { ResponseGenerator } from "../../services/userservice";
import { IAdversusRule, mapAdversusRule } from '../../pages/Settings/CallImport/utils/constants';

function* getCallTokensHelper({ payload }: ReturnType<typeof getCallTokens>) {
  const response: ResponseGenerator = yield call(getCallTokensService, payload.accountId);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getCallTokensError(error));
    } else {
      yield put(getCallTokensError("An error has occurred!!"));
    }
  } else {
    yield put(getCallTokensSuccess(response.data));
  }
}

function* getCallTokenSettingsHelper({ payload }: ReturnType<typeof getCallTokenSettings>) {
  const response: ResponseGenerator = yield call(getCallTokenSettingsService, payload.accountId);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getCallTokenSettingsError(error));
    } else {
      yield put(getCallTokenSettingsError("An error has occurred!!"));
    }
  } else {
    yield put(getCallTokenSettingsSuccess(response.data));
  }
}

function* updateCallTokenSettingsHelper({ payload }: ReturnType<typeof updateCallTokenSettings>) {
  const response: ResponseGenerator = yield call(updateTokenSettingsService, payload.accountId, payload.tokenSettings);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(updateCallTokenSettingsError(error));
    } else {
      yield put(updateCallTokenSettingsError("An error has occurred!!"));
    }
  } else {
    yield put(updateCallTokenSettingsSuccess(response.data));
    yield put(getCallTokenSettings(payload.accountId));
  }
}

function* deleteCallTokenSettingsHelper({ payload }: ReturnType<typeof deleteCallTokenSettings>) {
  const response: ResponseGenerator = yield call(deleteTokenSettingsService, payload.accountId, payload.tokenSettingsId);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteCallTokenSettingsError(error));
    } else {
      yield put(deleteCallTokenSettingsError("An error has occurred!!"));
    }
  } else {
    yield put(deleteCallTokenSettingsSuccess());
    yield put(getCallTokenSettings(payload.accountId));
  }
}

function* createCallTokenSettingsHelper({ payload }: ReturnType<typeof createCallTokenSettings>) {
  const response: ResponseGenerator = yield call(createTokenSettingsService, payload.accountId, payload.tokenSettings);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(createCallTokenSettingsError(error));
    } else {
      yield put(createCallTokenSettingsError("An error has occurred!!"));
    }
  } else {
    yield put(createCallTokenSettingsSuccess(response.data));
    yield put(getCallTokenSettings(payload.accountId));
  }
}

function* getAdversusRulesHelper({ payload }: ReturnType<typeof getAdversusRules>) {
  const response: ResponseGenerator = yield call(getAdversusRulesService, payload);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getAdversusRulesError(error));
    } else {
      yield put(getAdversusRulesError("An error has occurred!!"));
    }
  } else {
    yield put(getAdversusRulesSuccess(response as IAdversusRule[]));
  }
}

function* createAdversusRuleHelper({ payload }: ReturnType<typeof createAdversusRule>) {
  const response: ResponseGenerator = yield call(createAdversusRuleService, payload.accountId, payload.rule);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(createAdversusRuleError(error));
    } else {
      yield put(createAdversusRuleError("An error has occurred!!"));
    }
  } else {
    const mappedRule = mapAdversusRule(response.data.data);
    yield put(createAdversusRuleSuccess(mappedRule));
  }
}

function* editAdversusRuleHelper({ payload }: ReturnType<typeof editAdversusRule>) {
  const response: ResponseGenerator = yield call(editAdversusRuleService, payload.accountId, payload.rule);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(editAdversusRuleError(error));
    } else {
      yield put(editAdversusRuleError("An error has occurred!!"));
    }
  } else {
    const mappedRule = mapAdversusRule(response.data.data);
    yield put(editAdversusRuleSuccess(mappedRule));
  }
}

function* deleteAdversusRuleHelper({ payload }: ReturnType<typeof deleteAdversusRule>) {
  const response: ResponseGenerator = yield call(deleteAdversusRuleService, payload.accountId, payload.ruleId);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteAdversusRuleError(error));
    } else {
      yield put(deleteAdversusRuleError("An error has occurred!!"));
    }
  } else {
    yield put(deleteAdversusRuleSuccess(payload.ruleId));
    yield put(getCallTokenSettings(payload.accountId));
  }
}

export function* watchGetCallTokensHelper() {
  yield takeEvery(GET_CALL_TOKEN, getCallTokensHelper);
}

export function* watchGetCallTokenSettingsHelper() {
  yield takeEvery(GET_CALL_TOKEN_SETTINGS, getCallTokenSettingsHelper);
}

export function* watchCreateCallTokenSettingsHelper() {
  yield takeEvery(CREATE_CALL_TOKEN_SETTINGS, createCallTokenSettingsHelper);
}

export function* watchDeleteCallTokenSettingsHelper() {
  yield takeEvery(DELETE_CALL_TOKEN_SETTINGS, deleteCallTokenSettingsHelper);
}

export function* watchUpdateCallTokenSettingsHelper() {
  yield takeEvery(UPDATE_CALL_TOKEN_SETTINGS, updateCallTokenSettingsHelper);
}

export function* watchGetAdversusRulesHelper() {
  yield takeEvery(GET_ADVERSUS_RULES, getAdversusRulesHelper);
}

export function* watchCreateAdversusRuleHelper() {
  yield takeEvery(CREATE_ADVERSUS_RULE, createAdversusRuleHelper);
}

export function* watchEditAdversusRuleHelper() {
  yield takeEvery(EDIT_ADVERSUS_RULE, editAdversusRuleHelper);
}
export function* watchDeleteAdversusRuleHelper() {
  yield takeEvery(DELETE_ADVERSUS_RULE, deleteAdversusRuleHelper);
}

function* CallImportSaga() {
  yield all([
    fork(watchGetCallTokensHelper),
    fork(watchGetCallTokenSettingsHelper),
    fork(watchCreateCallTokenSettingsHelper),
    fork(watchDeleteCallTokenSettingsHelper),
    fork(watchUpdateCallTokenSettingsHelper),
    fork(watchGetAdversusRulesHelper),
    fork(watchCreateAdversusRuleHelper),
    fork(watchEditAdversusRuleHelper),
    fork(watchDeleteAdversusRuleHelper),
  ]);
}

export default CallImportSaga;