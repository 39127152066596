import axios, { AxiosResponse } from 'axios';

import { commonheaders } from '../../utilities/apiConfig';
import { endpoints } from '../../utilities/endpoints';
import { urls } from '../../utilities/urls';

export async function postGPTPrompt(
  sessionId: string,
  prompt: string,
  promptCount: string,
  aiModel: string,
): Promise<string[]> {
  try {
    const response: AxiosResponse<string[]> = await axios.post(
      `${urls.baseApiURL}${endpoints.chatGPT}/${endpoints.session}/${sessionId}/${endpoints.customPrompt}`,
      {
        prompt_text: prompt,
        prompt_count: promptCount,
        ai_model: aiModel
      },
      {
        // @ts-ignore
        headers: commonheaders,
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response;
    }
    return error;
  }
}

export async function getGPTPromptById(
  promptId: string
): Promise<string> {
  try {
    const response: AxiosResponse<string> = await axios.get(
      `${urls.baseApiURL}${endpoints.chatGPT}/${endpoints.customPrompt}/${promptId}`,
      {
        // @ts-ignore
        headers: commonheaders,
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response;
    }
    return error;
  }
}
