export const GET_TEAMS = "GET_TEAMS";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_ERROR = "GET_TEAMS_ERROR";
export const GET_FILTER_TEAMS = "GET_FILTER_TEAMS";
export const GET_FILTER_TEAMS_SUCCESS = "GET_FILTER_TEAMS_SUCCESS";
export const GET_FILTER_TEAMS_ERROR = "GET_FILTER_TEAMS_ERROR";
export const CREATE_NEW_TEAM = "CREATE_NEW_TEAM";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_ERROR = "CREATE_TEAM_ERROR";
export const API_ERROR = "API_ERROR";
export const REMOVE_USER_FROM_TEAM_SUCCESS = "REMOVE_USER_FROM_TEAM_SUCCESS";
export const REMOVE_USER_FROM_TEAM = "REMOVE_USER_FROM_TEAM";
export const ADD_MANAGER_TO_TEAM = "ADD_MANAGER_TO_TEAM";
export const ADD_MANAGER_TO_TEAM_SUCCESS = "ADD_MANAGER_TO_TEAM_SUCCESS";
export const GET_MANAGED_TEAMS = "GET_MANAGED_TEAMS";
export const GET_MANAGED_TEAMS_SUCCESS = "GET_MANAGED_TEAMS_SUCCESS";
export const EDIT_TEAM = 'EDIT_TEAM';
export const EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS';
