import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { has } from "lodash";

import {
  createS2AdversusSettings,
  createS2AdversusSettingsError,
  createS2AdversusSettingsSuccess,
  deleteS2AdversusSettings,
  deleteS2AdversusSettingsError,
  deleteS2AdversusSettingsSuccess,
  updateS2AdversusSettings,
  updateS2AdversusSettingsError,
  updateS2AdversusSettingsSuccess,
} from './actions';

import { getAdversusRules, getCallTokens, getCallTokenSettings } from "../../store/CallImports/actions";

import {
  CREATE_S2_ADVERSUS_SETTINGS,
  DELETE_S2_ADVERSUS_SETTINGS,
  UPDATE_S2_ADVERSUS_SETTINGS,
} from './actionTypes';

import {
  createTokenSettings as createTokenSettingsService,
  deleteS2AdversusTokenSettings as deleteTokenSettingsService,
  updateTokenSettings as updateTokenSettingsService,
} from '../../services/settings/s2Adversus';

import { ResponseGenerator } from "../../services/userservice";

function* updateS2AdversusSettingsHelper({ payload }: ReturnType<typeof updateS2AdversusSettings>) {
  const response: ResponseGenerator = yield call(updateTokenSettingsService, payload.accountId, payload.tokenSettings);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(updateS2AdversusSettingsError(error));
    } else {
      yield put(updateS2AdversusSettingsError("An error has occurred!!"));
    }
  } else {
    yield put(updateS2AdversusSettingsSuccess(response.data));
    yield put(getCallTokens(payload.accountId));
    yield put(getCallTokenSettings(payload.accountId));
  }
}

function* deleteS2AdversusSettingsHelper({ payload }: ReturnType<typeof deleteS2AdversusSettings>) {
  const response: ResponseGenerator = yield call(deleteTokenSettingsService, payload.accountId, payload.tokenSettingsId);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteS2AdversusSettingsError(error));
    } else {
      yield put(deleteS2AdversusSettingsError("An error has occurred!!"));
    }
  } else {
    yield put(deleteS2AdversusSettingsSuccess());
    yield put(getCallTokens(payload.accountId));
    yield put(getCallTokenSettings(payload.accountId));
    yield put(getAdversusRules(payload.accountId))
  }
}

function* createS2AdversusSettingsHelper({ payload }: ReturnType<typeof createS2AdversusSettings>) {
  const response: ResponseGenerator = yield call(createTokenSettingsService, payload.accountId, payload.tokenSettings);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(createS2AdversusSettingsError(error));
    } else {
      yield put(createS2AdversusSettingsError("An error has occurred!!"));
    }
  } else {
    yield put(createS2AdversusSettingsSuccess(response.data));
    yield put(getCallTokens(payload.accountId));
    yield put(getCallTokenSettings(payload.accountId));
  }
}

export function* watchCreateS2AdversusSettingsHelper() {
  yield takeEvery(CREATE_S2_ADVERSUS_SETTINGS, createS2AdversusSettingsHelper);
}

export function* watchDeleteS2AdversusSettingsHelper() {
  yield takeEvery(DELETE_S2_ADVERSUS_SETTINGS, deleteS2AdversusSettingsHelper);
}

export function* watchUpdateS2AdversusSettingsHelper() {
  yield takeEvery(UPDATE_S2_ADVERSUS_SETTINGS, updateS2AdversusSettingsHelper);
}

function* S2AdversusSaga() {
  yield all([
    fork(watchCreateS2AdversusSettingsHelper),
    fork(watchDeleteS2AdversusSettingsHelper),
    fork(watchUpdateS2AdversusSettingsHelper),
  ]);
}

export default S2AdversusSaga;