export const GET_PRESENTATIONS = "GET_PRESENTATIONS";
export const GET_PRESENTATIONS_SUCCESS = "GET_PRESENTATIONS_SUCCESS";
export const API_ERROR = "GET_PRESENTATIONS_API_ERROR";
export const SET_PRESENTATION = "SET_PRESENTATION";
export const SET_PRESENTATION_SUCCESS = "SET_PRESENTATION_SUCCESS";
export const DUPLICATE_PRESENTATION = "DUPLICATE_PRESENTATION";
export const APPEND_DUPLICATE_PRESENTATION = "APPEND_DUPLICATE_PRESENTATION";
export const EDIT_PRESENTATION = "EDIT_PRESENTATION";
export const RENAME_PRESENTATION = "RENAME_PRESENTATION";
export const REMOVE_PRESENTATION = "REMOVE_PRESENTATION";
export const REMOVE_PRESENTATION_SUCCESS = "REMOVE_PRESENTATION_SUCCESS";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SET_IMPORT_PPTX = "SET_IMPORT_PPTX";
export const GET_IMPORT_PRESENTATION = "GET_IMPORT_PRESENTATION";
export const IMPORT_PRESENTATION_ERROR = "IMPORT_PRESENTATION_ERROR";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR";
export const SET_FILTER_DOCUMENT = "SET_FILTER_DOCUMENT";

export const GET_GOOGLE_DRIVE_FILES_LIST = "GET_GOOGLE_DRIVE_FILES_LIST";
export const DRIVE_API_ERROR = "DRIVE_API_ERROR";
export const GET_GOOGLE_DOWNLOAD = "GET_GOOGLE_DOWNLOAD";
export const GET_GOOGLE_DOWNLOAD_SUCCESS = "GET_GOOGLE_DOWNLOAD_SUCCESS";
export const GET_GOOGLE_DOWNLOAD_ERROR = "GET_GOOGLE_DOWNLOAD_ERROR";
export const SET_GOOGLE_DRIVE_LOGOUT = "SET_GOOGLE_DRIVE_LOGOUT";
export const SET_GOOGLE_DRIVE_LOGOUT_ERROR = "SET_GOOGLE_DRIVE_LOGOUT_ERROR";
export const DELETE_MULTIPLE_DOCS = "DELETE_MULTIPLE_DOCS";
export const DELETE_MULTIPLE_DOCS_SUCCESS = "DELETE_MULTIPLE_DOCS_SUCCESS";
