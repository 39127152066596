import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { has } from 'lodash';

import {
  getDestinyTokenError,
  getDestinyTokenSuccess,
  createDestinyToken as createDestinyTokenAction,
  createDestinyTokenError,
  createDestinyTokenSuccess,
  editDestinyToken as editDestinyTokenAction,
  editDestinyTokenError,
  editDestinyTokenSuccess,
  deleteDestinyToken as deleteDestinyTokenAction,
  deleteDestinyTokenError,
  deleteDestinyTokenSuccess,
} from "./actions";

import {
  getDestinyToken,
  createDestinyToken,
  editDestinyToken,
  deleteDestinyToken,
} from '../../../services/settings/DestinyIntegration';

import { ResponseGenerator } from "../../../services/userservice";
import { IDestinyToken } from "./interface";
import {
  GET_DESTINY_TOKEN,
  CREATE_DESTINY_TOKEN,
  EDIT_DESTINY_TOKEN,
  DELETE_DESTINY_TOKEN,
} from "./actionTypes";


function* getDestinyTokenHelper() {
  const response: ResponseGenerator = yield call(getDestinyToken);
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getDestinyTokenError(error));
    } else {
      yield put(getDestinyTokenError('An error has occurred!!'));
    }
  } else {
    yield put(getDestinyTokenSuccess(response as unknown as IDestinyToken));
  }
}

function* createDestinyTokenHelper({
 payload,
}: ReturnType<typeof createDestinyTokenAction>) {
  const response: ResponseGenerator = yield call(
    createDestinyToken,
    payload,
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(createDestinyTokenError(error));
    } else {
      yield put(createDestinyTokenError('An error has occurred!!'));
    }
  } else {
    yield put(createDestinyTokenSuccess(response as unknown as IDestinyToken));
  }
}

function* editDestinyTokenHelper({
 payload,
}: ReturnType<typeof editDestinyTokenAction>) {
  const response: ResponseGenerator = yield call(
    editDestinyToken,
    payload,
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(editDestinyTokenError(error));
    } else {
      yield put(editDestinyTokenError('An error has occurred!!'));
    }
  } else {
    yield put(editDestinyTokenSuccess(response as unknown as IDestinyToken));
  }
}

function* deleteDestinyTokenHelper({
  payload,
}: ReturnType<typeof deleteDestinyTokenAction>) {
  const response: ResponseGenerator = yield call(deleteDestinyToken, payload);

  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteDestinyTokenError(error));
    } else {
      yield put(deleteDestinyTokenError('An error has occurred!!'));
    }
  } else {
    yield put(deleteDestinyTokenSuccess());
  }
}

export function* watchGetDestinyTokenHelper() {
  yield takeEvery(GET_DESTINY_TOKEN, getDestinyTokenHelper);
}

export function* watchCreateDestinyTokenHelper() {
  yield takeEvery(CREATE_DESTINY_TOKEN, createDestinyTokenHelper);
}

export function* watchEditDestinyTokenHelper() {
  yield takeEvery(EDIT_DESTINY_TOKEN, editDestinyTokenHelper);
}

export function* watchDeleteDestinyTokenHelper() {
  yield takeEvery(DELETE_DESTINY_TOKEN, deleteDestinyTokenHelper);
}

function* DestinyTokenSaga() {
  yield all([fork(watchGetDestinyTokenHelper)]);
  yield all([fork(watchCreateDestinyTokenHelper)]);
  yield all([fork(watchEditDestinyTokenHelper)]);
  yield all([fork(watchDeleteDestinyTokenHelper)]);
}

export default DestinyTokenSaga;
