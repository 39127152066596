import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { GET_USER_PROFILE, CHANGE_CURRENT_ACCOUNT } from './actionTypes';
import { profileSuccess, profileError, changeCurrentAccount } from './actions';
import { getAccountDetails, getAiCategories, getAvailableAIAgents, getDashboards, getGlobalFilters } from '../../actions'

//services
import { getLocalStorage, setLocalStorage } from '../../../services/utilities/newUtilservice';
import { getCurrentUserDetails, ResponseGenerator } from '../../../services/newUserService';
import { has, includes, isEmpty } from 'lodash';
import {superAdminUser} from "../../../helpers/common";
import { getAssociatedUsers } from "../associated-users/actions";
import { getAssociatedTeams } from "../associated-teams/actions";
import { UPDATE_ALL_FILTERS_CAMPAIGN } from '../../GlobalFilters/actionTypes';
import {logoutUser} from "../../../services/auth/authservice";
import { getKeywords, getKeywordGroups } from '../../keywords/actions';
import { isAccessibleUnauthorized } from '../../../utilities/apiConfig';

function* getProfile() {
    const response: ResponseGenerator = yield call(getCurrentUserDetails);
    let accountFromStorage: null | Object = getLocalStorage('currentSelectedAccount');
    if (has(response, "data")) {
        response.data.isSuperAdmin = includes(superAdminUser, response.data.email)
            || includes(superAdminUser, response.data.accounts[0].id);

        const account = response.data.accounts[0];
        if (account?.is_dashboard_enabled === false) {
          const url = new URL(window.location.href);
          url.hostname = url.hostname.replace(/^app/, 'switchboard');
          url.pathname = '/';
          window.location.href = url.toString();
        }

        if (accountFromStorage) {
            yield put(changeCurrentAccount(account));
            setLocalStorage('currentSelectedAccount', account);
            setLocalStorage('is_beta', account?.is_beta)
            yield put(getAccountDetails(response.data.id));
            yield put(profileSuccess(response.data, false));
            if (response.data.campaigns && !isEmpty(response.data.campaigns)) {
              yield put({
                  type: UPDATE_ALL_FILTERS_CAMPAIGN,
                  payload: response.data.campaigns
              });
            } else {
              yield put({
                type: UPDATE_ALL_FILTERS_CAMPAIGN,
                payload: []
            });
            }
        } else {
            if (response.data.accounts.length === 1) {
                yield put(changeCurrentAccount(account))
                yield put(profileSuccess(response.data, false));
                if (response.data.campaigns && !isEmpty(response.data.campaigns)) {
                  yield put({
                    type: UPDATE_ALL_FILTERS_CAMPAIGN,
                    payload: response.data.campaigns
                  });
                }
                setLocalStorage('currentSelectedAccount', account);
                yield put(getAccountDetails(response.data.id));
            } else {
                yield put(changeCurrentAccount({}))
                yield put(profileSuccess(response.data, true));
                yield put({
                  type: UPDATE_ALL_FILTERS_CAMPAIGN,
                  payload: []
              });
            }
        }


    } else {
        const error = response.error || response.errors;
        if (error) {
            yield put(profileError(error));
        } else {
            yield put(profileError("An Error has occured"));
        }

      const path = window.location.pathname;

      if (!isAccessibleUnauthorized(path)) {
        yield logoutUser();
        window.location.assign('/login');
      }
    }
}

function* changeCurrentAccountHelper({ payload }: any) {
  setLocalStorage('currentSelectedAccount', payload);

  if (!isEmpty(payload)) {
    yield put(getGlobalFilters(payload.id))
    yield put(getAiCategories());
    yield put(getDashboards(payload.id))
    yield put(getAssociatedUsers(payload.id))
    yield put(getAssociatedTeams(payload.id))
    yield put(getKeywords(payload.id))
    yield put(getKeywordGroups(payload.id))
    yield put(getAvailableAIAgents(payload.id))
  }
}

export function* watchProfile() {
    yield takeEvery(GET_USER_PROFILE, getProfile)
}

export function* watchChangeAccount() {
    yield takeEvery(CHANGE_CURRENT_ACCOUNT, changeCurrentAccountHelper)
}

function* ProfileSaga() {
    yield all([
        fork(watchProfile),
        fork(watchChangeAccount),
    ]);
}

export default ProfileSaga;
