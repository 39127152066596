import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Spinner, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import { initBem } from '../../utilities/bem';
import SwitchComponent from '../../components/SwitchButton/SwitchComponent';
import { sleep } from '../../utilities/helperFunctions';
import {
  streamToS3Urls,
  detectWhenDisabled,
  hackForceVideoDuration,
} from './helperFunctions';
import {
  startLiveRecordingUpload,
  getLiveRecordingUploadUrls,
  endLiveRecordingUpload,
  cancelLiveRecordingUpload,
  getTodaysMeetings,
  editMeetingAsync,
  updateMeetingParticipants,
} from '../../services/userservice';
import { createParticipant, EditParticipantsList } from './EditParticipantsList';
import userGreen from "../../assets/images/dashboard/user-green.svg";
import userBlue from "../../assets/images/dashboard/user-blue.svg";
import { formatTime, validateEmail } from '../../services/utilities/utilservice';
import SweetAlert from 'react-bootstrap-sweetalert';
import { noop } from 'lodash';
const Player = require('video-react');

type RecordLiveMeetingProps = {
  currentUser: any;
  currentAccount: any;
};

type RecordLiveMeetingState = {
  meetingTitle: string;
  microphoneAndCameraAvailable: boolean;
  isMicrophoneEnabled: boolean;
  isCameraEnabled: boolean;
  screenSharingAvailable: boolean;
  isScreenSharingEnabled: boolean;
  microphoneStream: MediaStream|null;
  cameraStream: MediaStream|null;
  screenSharingStream: MediaStream|null;
  isCameraMainView: boolean;
  isRecordingStarting: boolean;
  jointStream: MediaStream|null;
  mediaRecorder: MediaRecorder|null;
  canvasCtx: CanvasRenderingContext2D|null;
  secondaryViewStyles: any;
  errors: any;
  showingInfoModal: boolean;
  participants: any[];
  meetingsToday: any[]|null;
  meetingsSearch: string;
  isMeetingSelected: boolean;
  selectedMeetingId: string|null;
  meetingFromApi: any;
  areReceiversModified: boolean;
  isMeetingDone: boolean;
  showingConfirmStopModal: boolean;
  viewUrl: string|null;
  checkingDuration: any;
  recordingDuration: number;
  showingErrorModal: boolean;
  recordingId: string|null;
  uploadId: string|null;
};

const VIDEO_WIDTH = 1280;
const VIDEO_HEIGHT = 720;

const STREAMS = {
  MICROPHONE: 'MICROPHONE',
  CAMERA: 'CAMERA',
  SCREEN: 'SCREEN',
};

const DEVICES = {
  [STREAMS.MICROPHONE]: 'microphone',
  [STREAMS.CAMERA]: 'camera',
  [STREAMS.SCREEN]: 'screen sharing',
};

const ICONS = {
  [STREAMS.MICROPHONE]: 'microphone',
  [STREAMS.CAMERA]: 'video',
  [STREAMS.SCREEN]: 'desktop',
};

type streamKey = keyof typeof STREAMS;

const initialState = {
  meetingTitle: '',
  microphoneAndCameraAvailable: false,
  isMicrophoneEnabled: false,
  isCameraEnabled: false,
  screenSharingAvailable: false,
  isScreenSharingEnabled: false,
  microphoneStream: null,
  cameraStream: null,
  screenSharingStream: null,
  isCameraMainView: true,
  isRecordingStarting: false,
  jointStream: null,
  mediaRecorder: null,
  canvasCtx: null,
  secondaryViewStyles: { top: 0, left: 0, width: 0, height: 0 },
  errors: {},
  showingInfoModal: false,
  participants: [createParticipant()],
  meetingsToday: null,
  meetingsSearch: '',
  isMeetingSelected: false,
  selectedMeetingId: null,
  meetingFromApi: null,
  areReceiversModified: false,
  isMeetingDone: false,
  showingConfirmStopModal: false,
  viewUrl: null,
  checkingDuration: null,
  recordingDuration: 0,
  showingErrorModal: false,
  recordingId: null,
  uploadId: null,
};

class RecordLiveMeeting extends Component<RecordLiveMeetingProps, RecordLiveMeetingState> {
  lastFrameMs: number = 0;
  cameraVideoElementRef: React.RefObject<any> = createRef();
  screenVideoElementRef: React.RefObject<any> = createRef();
  canvasElementRef: React.RefObject<any> = createRef();
  previewVideoPlayerRef: React.RefObject<any> = createRef();
  // TODO: everything related to audioRef is a hack for throttling, find better fix and remove
  audioRef: React.RefObject<any> = createRef();

  constructor(props: any) {
    super(props);
    this.state = initialState;
  }

  componentDidMount = () => {
    this.initializeCapabilitiesAndCanvas();
    setTimeout(() => {
      this.drawVideos();
      window.document.title = 'Record Live meeting | Meetric';
    }, 40);
    if (this.props.currentUser?.id) {
      this.fetchTodaysMeetings();
    }
    window.addEventListener('beforeunload', this.onBeforeUnload);
  };

  initializeCapabilitiesAndCanvas = () => {
    const microphoneAndCameraAvailable = !!navigator.mediaDevices?.getUserMedia;
    const screenSharingAvailable = !!navigator.mediaDevices?.getDisplayMedia;

    const canvasCtx = this.canvasElementRef.current?.getContext('2d', {
      alpha: false,
      desynchronized: true,
    });
    canvasCtx.fillStyle = 'black';

    this.setState({
      microphoneAndCameraAvailable,
      screenSharingAvailable,
      errors: {
        [STREAMS.MICROPHONE]: microphoneAndCameraAvailable ? null : this.getErrorMessage(DEVICES[STREAMS.MICROPHONE], { name: 'NotFoundError' }),
        [STREAMS.CAMERA]: microphoneAndCameraAvailable ? null : this.getErrorMessage(DEVICES[STREAMS.CAMERA], { name: 'NotFoundError' }),
      },
      canvasCtx,
    });
  };

  componentWillUnmount = () => {
    if (this.state.mediaRecorder) {
      this.stopRecording(false);
      this.cancelAnyUploadsInProgress();
    }
    this.state.checkingDuration?.cleanup();
    this.drawVideos = () => {};
    window.removeEventListener('beforeunload', this.onBeforeUnload);
    window.removeEventListener('unload', this.onUnload);
  };

  componentDidUpdate = (prevProps: Readonly<RecordLiveMeetingProps>, prevState: any) => {
    if (this.props.currentUser?.id
        && this.props.currentUser?.id !== prevProps.currentUser?.id) {
      this.fetchTodaysMeetings();
    }
    this.state.checkingDuration?.recheck(
      this.previewVideoPlayerRef.current,
      this.state.recordingDuration,
    );
  };

  onBeforeUnload = (event: BeforeUnloadEvent) => {
    if (this.state.isRecordingStarting || this.state.mediaRecorder) {
      event.preventDefault();
      event.returnValue = 'Recording in progress';
      return true;
    }
  };

  onUnload = () => {
    this.cancelAnyUploadsInProgress();
  };

  cancelAnyUploadsInProgress = () => {
    if (this.state.isRecordingStarting || this.state.mediaRecorder) {
      const { recordingId, uploadId } = this.state;
      cancelLiveRecordingUpload(recordingId!, uploadId!);
    }
  };

  fetchTodaysMeetings = async () => {
    const { data: meetingsToday } = await getTodaysMeetings(this.props.currentUser.id);
    this.setState({ meetingsToday });
  };

  drawVideos = () => {
    const {
      cameraStream,
      screenSharingStream,
      canvasCtx,
      isCameraMainView,
    } = this.state;

    canvasCtx?.fillRect(0, 0, VIDEO_WIDTH, VIDEO_HEIGHT);
    const mainView = isCameraMainView ? this.cameraVideoElementRef : this.screenVideoElementRef;
    const secondaryView = isCameraMainView ? this.screenVideoElementRef : this.cameraVideoElementRef;

    if (cameraStream || screenSharingStream) {
      this.drawVideo(mainView.current, true);
      if (!!(isCameraMainView ? screenSharingStream : cameraStream)) {
        this.drawVideo(secondaryView.current, false);
      }
    }
    setTimeout(this.drawVideos, 40);
  };

  drawVideo = (video: HTMLVideoElement, isMainView: boolean) => {
    let x = 0, y = 0, width = VIDEO_WIDTH, height = VIDEO_HEIGHT;
    const aspectRatio = video.videoWidth / video.videoHeight;
    const desiredAspectRatio = VIDEO_WIDTH / VIDEO_HEIGHT;

    if (aspectRatio > desiredAspectRatio) {
      height *= desiredAspectRatio / aspectRatio;
      y = (VIDEO_HEIGHT - height) / 2;
    } else if (aspectRatio < desiredAspectRatio) {
      width *= aspectRatio / desiredAspectRatio;
      x = (VIDEO_WIDTH - width) / 2;
    }

    if (!isMainView) {
      width /= 4;
      height /= 4;
      x = VIDEO_WIDTH - width;
      y = VIDEO_HEIGHT - height;
      this.setState({ secondaryViewStyles: {
        top: ((y - 10) / VIDEO_HEIGHT * 100) + '%',  // y - 10 because misaligned - can't find cause now
        left: (x / VIDEO_WIDTH * 100) + '%',
        width: (width / VIDEO_WIDTH * 100) + '%',
        height: (height / VIDEO_HEIGHT * 100) + '%',
      }});
    }

    this.state.canvasCtx?.drawImage(
      video,
      Math.round(x),
      Math.round(y),
      Math.round(width),
      Math.round(height),
    );
  };

  startRecording = async () => {
    await this.pruneParticipants();
    this.setState({
      isRecordingStarting: true,
      areReceiversModified: false,
    });

    const audio = this.audioRef.current;
    if (audio) {
      audio.volume = 0.002;
      audio.play();
    }
    const {
      recording,
      meeting,
      uploadId,
      uploadUrls: signedS3UploadUrls,
      viewUrl,
    } = await startLiveRecordingUpload({
      meetingId: this.state.selectedMeetingId,
      title: this.state.meetingTitle,
      participants: this.state.participants.map(({ tempId, ...participant }) => participant),
    });

    this.setState({
      meetingFromApi: meeting,
      selectedMeetingId: meeting.id,
      meetingTitle: meeting.name,
      participants: this.mapReceiversToParticipants(meeting.receivers),
      recordingId: recording.id,
      uploadId,
      viewUrl,
    });

    let timerTickTimeout: any = null;

    let wakeLock = null;
    try {
      // @ts-ignore
      wakeLock = await window.navigator.wakeLock?.request();
    } catch (e) {}

    try {
      const jointStream = this.createJointStream();
      const mediaRecorder = new MediaRecorder(jointStream);
      this.setState({ jointStream, mediaRecorder, isRecordingStarting: false });

      let recordingStart = 0;
      const componentInstance = this;
      timerTickTimeout = setTimeout(function tick() {
        const recordingDurationInMs = window.performance.now() - recordingStart;
        const recordingDuration = recordingDurationInMs / 1000 | 0;
        window.document.title = `${formatTime(recordingDuration)} | ${meeting.name} | Meetric`;

        componentInstance.setState({ recordingDuration });
        timerTickTimeout = setTimeout(tick, 1000 - (recordingDurationInMs % 1000));
      });

      const binaryStream = new ReadableStream({
        start: (controller) => {
          mediaRecorder.ondataavailable = async (event) => {
            controller.enqueue(new Uint8Array(await event.data.arrayBuffer()));
          };

          mediaRecorder.onstop = () => {
            controller.close();
          };

          mediaRecorder.start(1000);
          recordingStart = window.performance.now();
        },
        // @ts-ignore
        type: 'bytes',
      });

      const partETags = await streamToS3Urls(
        binaryStream,
        signedS3UploadUrls,
        (offset: number, page: number) => {
          return getLiveRecordingUploadUrls(recording.id, uploadId, offset, page);
        }
      );
      clearTimeout(timerTickTimeout);
      window.document.title = `${meeting.name} | Meetric`;

      const { data: finishedRecording } = await endLiveRecordingUpload({
        recordingId: recording.id,
        uploadId,
        partETags,
        recordingDuration: (window.performance.now() - recordingStart) / 1000,
      });

      this.setState({
        showingInfoModal: true,
        isRecordingStarting: false,
        isMeetingDone: true,
        checkingDuration: hackForceVideoDuration(
          '.' + initBem('record-live-meeting')('finished-recording-preview'),
        ),
      });
    } catch (e) {
      console.error(e);
      this.stopRecording(false);
      this.setState({ showingErrorModal: true });
    } finally {
      this.setState({ isRecordingStarting: false });
      clearTimeout(timerTickTimeout);
      wakeLock?.release();
    }
  };

  createJointStream = () => {
    const { microphoneStream } = this.state;
    const canvasStream = this.canvasElementRef.current?.captureStream(24);
    const jointStream = new MediaStream([
      ...canvasStream.getTracks(),
      ...microphoneStream!.getTracks(),
    ]);
    return jointStream;
  };

  stopRecording = (isButtonClick: boolean) => {
    this.audioRef.current?.pause();
    if (this.state.mediaRecorder) {
      this.state.mediaRecorder.ondataavailable = null;
      this.state.mediaRecorder.stop();
    }
    this.state.jointStream?.getTracks().forEach(track =>
      this.state.jointStream?.removeTrack(track)
    );
    this.stopMicrophone(true);
    this.stopCamera();
    this.stopScreenSharing();
    this.setState({
      jointStream: null,
      mediaRecorder: null,
      isRecordingStarting: isButtonClick,
    });
  };

  toggleMicrophone = async () => {
    if (this.state.isMicrophoneEnabled) {
      this.stopMicrophone(false);
    } else {
      this.startMicrophone();
    }
  };

  toggleScreenSharing = async () => {
    if (this.state.isScreenSharingEnabled) {
      this.stopScreenSharing();
    } else {
      this.startScreenSharing();
    }
  };

  toggleCamera = async () => {
    if (this.state.isCameraEnabled) {
      this.stopCamera();
    } else {
      this.startCamera();
    }
  };

  getErrorMessage = (device: string, error: any) => {
    if (error.name === 'NotFoundError') {
      return `${capitalize(device)} is not available.`
    }
    if (error.name === 'NotAllowedError') {
      return `${capitalize(device)} access was not granted.`
    }
    return `Could not activate ${device}.`;
  };

  startMicrophone = async () => {
    this.setState({ isMicrophoneEnabled: true });
    if (this.state.jointStream) {
      this.state.microphoneStream?.getTracks().forEach(track => track.enabled = true);
      return;
    }

    try {
      const microphoneStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.setState({
        microphoneStream,
        errors: { ...this.state.errors, [STREAMS.MICROPHONE]: null }
      });
      detectWhenDisabled(microphoneStream, () => this.stopMicrophone(false));
    } catch (error) {
      this.setState({
        isMicrophoneEnabled: false,
        errors: {
          ...this.state.errors,
          [STREAMS.MICROPHONE]: this.getErrorMessage(DEVICES.MICROPHONE, error),
        }
      });
    }
  };

  stopMicrophone = (forceRelease: boolean) => {
    this.setState({ isMicrophoneEnabled: false });
    if (this.state.jointStream && !forceRelease) {
      this.state.microphoneStream?.getTracks().forEach(track => track.enabled = false);
      return;
    }

    this.state.microphoneStream?.getTracks().forEach(track => track.stop());
    this.setState({ microphoneStream: null });
  };

  startCamera = async () => {
    this.setState({ isCameraEnabled: true });
    try {
      const cameraStream = await navigator.mediaDevices.getUserMedia({ video: true });
      detectWhenDisabled(cameraStream, () => this.setState({
        isCameraEnabled: false,
        cameraStream: null,
      }));
      this.cameraVideoElementRef.current.srcObject = cameraStream;
      this.cameraVideoElementRef.current.onloadedmetadata = function () { this.play(); };

      // Timeout to avoid a flash - video gets full screen width for a split second
      await sleep(100);

      this.setState({
        cameraStream,
        isCameraMainView: !this.state.screenSharingStream,
        errors: { ...this.state.errors, [STREAMS.CAMERA]: null },
      });
    } catch (error) {
      this.setState({
        isCameraEnabled: false,
        errors: {
          ...this.state.errors,
          [STREAMS.CAMERA]: this.getErrorMessage(DEVICES.CAMERA, error),
        },
      });
    }
  };

  stopCamera = () => {
    this.state.cameraStream?.getTracks().forEach(track => track.stop());
    if (this.cameraVideoElementRef.current) {
      this.cameraVideoElementRef.current.srcObject = null;  // Firefox-specific
    }
    this.setState({
      isCameraEnabled: false,
      cameraStream: null,
      isCameraMainView: !this.state.screenSharingStream,
    });
  };

  startScreenSharing = async () => {
    this.setState({ isScreenSharingEnabled: true });
    try {
      const screenSharingStream = await navigator.mediaDevices.getDisplayMedia({ video: true });

      detectWhenDisabled(screenSharingStream, () => this.setState({
        isScreenSharingEnabled: false,
        screenSharingStream: null,
        isCameraMainView: true,
      }));

      this.screenVideoElementRef.current.srcObject = screenSharingStream;
      this.screenVideoElementRef.current.onloadedmetadata = function () { this.play(); };

      // Timeout to avoid a flash - video gets full screen width for a split second
      await sleep(100);

      this.setState({
        screenSharingStream,
        isCameraMainView: false,
        errors: { ...this.state.errors, [STREAMS.SCREEN]: null }
      });
    } catch (error) {
      this.setState({
        isScreenSharingEnabled: false,
        isCameraMainView: true,
        errors: {
          ...this.state.errors,
          [STREAMS.SCREEN]: this.getErrorMessage(DEVICES.SCREEN, error),
        },
      });
    }
  };

  stopScreenSharing = () => {
    this.state.screenSharingStream?.getTracks().forEach(track => track.stop());
    this.setState({
      isScreenSharingEnabled: false,
      screenSharingStream: null,
      isCameraMainView: true,
    });
  };


  toggleMainView = () => {
    this.setState({ isCameraMainView: !this.state.isCameraMainView });
  };

  getSwitchLabel = (stream: streamKey) => {
    return (
      <>
        <i className={`fa fa-${ICONS[stream]}`} />
        {this.state.errors[stream] && (
          <i
            className="fa fa-warning switch-warning-icon"
            data-tooltip={this.state.errors[stream]}
          />
        )}
      </>
    );
  };

  updateMeetingTitle = (meetingTitle: string) => {
    this.setState({ meetingTitle });
  }

  updateParticipants = (participants: any[], callback = noop) => {
    const areReceiversModified = this.state.isMeetingSelected && !!this.state.selectedMeetingId;
    this.setState({ participants, areReceiversModified }, callback);
  };

  pruneParticipants = () => {
    return new Promise(resolve => {
      if (this.state.participants.every(
        participant => !participant.email && !participant.firstName && !participant.lastName && !participant.phone)
          && !(this.state.isMeetingSelected && !!this.state.selectedMeetingId)
      ) {
        this.updateParticipants([], resolve);
      } else {
        resolve(true);
      }
    });
  };
  
  updateMeetingOnApi = async () => {
    const titleModified = this.state.meetingTitle !== this.state.meetingFromApi?.name;
    if (titleModified) {
      const { receivers = null, new_receivers = null, ...meetingFromApi } = {
        ...this.state.meetingFromApi,
        name: this.state.meetingTitle,
      };

      this.setState({ meetingFromApi });
      editMeetingAsync(
        this.props.currentAccount.id!,
        this.state.selectedMeetingId!,
        meetingFromApi,
      );
    }

    if (this.state.areReceiversModified) {
      this.setState({ areReceiversModified: false });
      const { data } = await updateMeetingParticipants(
        this.state.selectedMeetingId!,
        this.state.participants,
      );
      this.setState({ participants: this.mapReceiversToParticipants(data) });
    }
  };

  // In this context "participants" are camelCased, "receivers" snake_cased
  mapReceiversToParticipants = (receivers: any[]) => {
    return receivers.map((receiver: any) => ({
      id: receiver.id,
      firstName: receiver.first_name || '',
      lastName: receiver.last_name || '',
      email: receiver.email || '',
      phone: receiver.phone || '',
    }));
  };

  selectMeeting = (meeting: any) => {
    this.setState({
      isMeetingSelected: true,
      selectedMeetingId: meeting?.id || null,
      meetingTitle: meeting?.name || '',
      participants: meeting?.receivers
        ? this.mapReceiversToParticipants(meeting.receivers)
        : [createParticipant()],
    });
  };

  getDisabledReceiversIds = () => {
    if (!this.state.isMeetingSelected) return [];
    return this.state.meetingsToday
      ?.find(({ id }) => id === this.state.selectedMeetingId)
      ?.meeting_receivers
      ?.filter(({ is_added_later }: any) => !is_added_later)
      .map(({ receiver }: any) => receiver.id)
    || [];
  }

  unselectMeeting = () => {
    this.setState({
      isMeetingSelected: false,
      selectedMeetingId: null,
      meetingTitle: '',
      participants: [createParticipant()],
      areReceiversModified: false,
    });
  };

  renderParticipants = () => {
    const { participants } = this.state;
    return participants.map(participant => (
      <div key={participant.id || participant.tempId} className="participant-data">
        <span className="participant-name">
          {participant.firstName}
          {participant.firstName && participant.lastName && ' '}
          {participant.lastName}
        </span>
        {' '}
        {participant.email && <span className="participant-email">{participant.email}</span>}
        {' '}
        {participant.phone && <span className="participant-phone">{participant.phone}</span>}
      </div>
    ));
  };

  anyInvalidParticipantData = () => {
    return this.state.participants?.some(({ email }) => email && !validateEmail(email));
  };

  resetState = () => {
    this.setState(initialState, () => {
      this.initializeCapabilitiesAndCanvas();
      this.fetchTodaysMeetings();
      window.document.title = 'Record Live meeting | Meetric';
    });
  };

  confirmStop = () => {
    this.setState({
      showingConfirmStopModal: true,
    });
  };

  getFilteredMeetingsToday = () => {
    const { meetingsSearch, meetingsToday } = this.state;
    const lowercaseSearch = meetingsSearch.toLowerCase();
    return meetingsToday!.filter(meeting =>
      !lowercaseSearch
        || lowercaseSearch.split(' ').every(word =>
          meeting.name.toLowerCase().includes(word)
            || meeting.created_by.first_name?.toLowerCase().includes(word)
            || meeting.created_by.last_name?.toLowerCase().includes(word)
            || meeting.created_by.email?.toLowerCase().includes(word)
            || meeting.created_by.phone?.toLowerCase().includes(word)
            || meeting.receivers?.some((receiver: any) =>
              receiver.first_name?.toLowerCase().includes(word)
                || receiver.last_name?.toLowerCase().includes(word)
                || receiver.email?.toLowerCase().includes(word)
                || receiver.phone?.toLowerCase().includes(word)
            )
        )
    );
  };

  render() {
    const {
      meetingTitle,
      microphoneAndCameraAvailable,
      microphoneStream,
      isCameraEnabled,
      cameraStream,
      screenSharingAvailable,
      isScreenSharingEnabled,
      screenSharingStream,
      isRecordingStarting,
      isMicrophoneEnabled,
      mediaRecorder,
      secondaryViewStyles,
      showingInfoModal,
      participants,
      meetingsToday,
      meetingsSearch,
      isMeetingSelected,
      areReceiversModified,
      isMeetingDone,
      recordingDuration,
      showingConfirmStopModal,
      viewUrl,
      meetingFromApi,
      showingErrorModal,
    } = this.state;

    const bem = initBem('record-live-meeting');
    const recordingInitiated = !!(isRecordingStarting || mediaRecorder || isMeetingDone);

    const anyInvalidParticipantData = this.anyInvalidParticipantData();
    const isStartButtonDisabled = !isMicrophoneEnabled || !isMeetingSelected || isRecordingStarting || anyInvalidParticipantData;
    const startButtonTooltipProps: any = {};
    if (isStartButtonDisabled) {
      if (!isRecordingStarting) {
        startButtonTooltipProps['data-tooltip'] = anyInvalidParticipantData
          ? 'One or more participant emails is invalid'
          : 'Unmute your microphone and select a meeting to start recording';
      } else {
        startButtonTooltipProps['data-tooltip'] = microphoneStream
          ? 'Please wait, starting...'
          : 'Please wait, saving...';
      }
    }

    return (
      <div className={bem('page')}>
        <div className={bem('header') + (mediaRecorder ? ' recording' : '') + (isMeetingDone ? ' meeting-finished' : '')}>
          {isMeetingDone ? (
            <h2 className={bem('title')}>Meeting <span className="hide-on-mobile">is</span> finished</h2>
          ) : !!mediaRecorder ? (
            <h2 className={bem('title')}>Recording &nbsp; {formatTime(recordingDuration)}</h2>
          ) : (
            <h2 className={bem('title')}>Notetaker is live</h2>
          )}
          {isMeetingDone ? (
            <button className="recording-button start-new-meeting" onClick={this.resetState}>
              Start a new meeting
            </button>
          ) : !!mediaRecorder ? (
            <button
              className="recording-button recording-stop-button"
              onClick={this.confirmStop}
              disabled={isRecordingStarting}
            >
              {isRecordingStarting ? <Spinner /> : 'Stop recording'}
            </button>
          ) : (
            <div className={`button-tooltip-container ${!isRecordingStarting ? 'long-tooltip' : ''}`} {...startButtonTooltipProps}>
              <button
                className="recording-button"
                onClick={this.startRecording}
                disabled={isStartButtonDisabled}
              >
                {isRecordingStarting ? <Spinner /> : 'Start recording'}
              </button>
            </div>
          )}
        </div>

        <div className={bem('content')}>
        <audio ref={this.audioRef} loop preload="metadata" data-durationhint="409" playsInline style={{display: 'none'}}>
          <source src="/whitenoise.mp3" type="audio/mpeg" data-transcodekey="mp3" />
        </audio>
          {!isMeetingDone && (
            <div className={bem('recording-in-progress-preview')}>
              <div className="video-switches">
                <SwitchComponent
                  label={this.getSwitchLabel(STREAMS.MICROPHONE as streamKey)}
                  disabled={!microphoneAndCameraAvailable || isRecordingStarting}
                  checked={isMicrophoneEnabled}
                  onChange={this.toggleMicrophone}
                />
                <SwitchComponent
                  label={this.getSwitchLabel(STREAMS.CAMERA as streamKey)}
                  disabled={!microphoneAndCameraAvailable}
                  checked={isCameraEnabled}
                  onChange={this.toggleCamera}
                />
                {screenSharingAvailable && (
                  <SwitchComponent
                    label={this.getSwitchLabel(STREAMS.SCREEN as streamKey)}
                    checked={isScreenSharingEnabled}
                    onChange={this.toggleScreenSharing}
                  />
                )}
              </div>

              {!!mediaRecorder && !isMicrophoneEnabled && (
                <span className="video-warning">
                  Your microphone is muted.
                </span>
              )}

              <div className="video-preview">
                <canvas width={VIDEO_WIDTH} height={VIDEO_HEIGHT} ref={this.canvasElementRef}></canvas>
                {cameraStream && screenSharingStream && (
                  <div
                    className="secondary-view-area"
                    onClick={this.toggleMainView}
                    style={secondaryViewStyles}
                  ></div>
                )}
              </div>

              <video
                className="meeting-video"
                ref={this.cameraVideoElementRef}
              ></video>
              <video
                className="meeting-video"
                ref={this.screenVideoElementRef}
              ></video>
            </div>
          )}

          <div className={bem('meetings-data')}>
            {isMeetingSelected ? (
              <>
                {!isMeetingDone && (
                  <h4 className="back-link" onClick={this.unselectMeeting}>
                    {recordingInitiated ? (
                      <></>
                    ) : (
                      <>
                        <i className="fa fa-chevron-left" />
                        {' '}
                        Select another meeting
                      </>
                    )}
                  </h4>
                )}
                <Input
                  className="meeting-title"
                  type="text"
                  placeholder="Meeting title"
                  value={meetingTitle}
                  onChange={(e: any) => this.updateMeetingTitle(e.target.value)}
                />
                <EditParticipantsList
                  participants={participants}
                  onUpdate={this.updateParticipants}
                  disabledParticipantIds={this.getDisabledReceiversIds()}
                />
                {recordingInitiated && (
                  <button
                    className="btn btn-primary"
                    disabled={
                      !this.props.currentAccount
                        || isRecordingStarting
                        || (!areReceiversModified && meetingTitle === meetingFromApi?.name)
                        || this.anyInvalidParticipantData()
                    }
                    onClick={this.updateMeetingOnApi}
                  >
                    Update
                  </button>
                )}
              </>
            ) : (
              <>
                {!!meetingsToday?.length && <h4 className="todays-meetings-title">Today's meetings</h4>}
                <div className="todays-meetings">
                  {meetingsToday ? (
                    meetingsToday.length ? (
                      <div className="todays-meetings-list">
                        <Input
                          type="text"
                          placeholder="Search meetings"
                          value={meetingsSearch}
                          onChange={(e: any) => this.setState({ meetingsSearch: e.target.value })}
                        />
                        {this.getFilteredMeetingsToday()
                          .map(meeting => (
                          <div key={meeting.id} className="todays-meeting-item" onClick={() => this.selectMeeting(meeting)}>
                            <div className="todays-meeting-left">
                              <h5 className="todays-meeting-start-time">
                                {new Date(meeting.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                              </h5>
                              <div className="todays-meeting-creator">
                                <img src={userGreen} />
                                {[
                                  meeting.created_by.first_name,
                                  meeting.created_by.last_name,
                                ].join(' ')}
                              </div>
                              <div className="todays-meeting-name">
                                {meeting.name}
                              </div>
                            </div>

                            <hr />

                            <div className="todays-meeting-right">
                              {meeting.receivers?.length ? meeting.receivers.map((receiver: any) => (
                                <div key={receiver.id} className="todays-meeting-receiver">
                                  <img src={userBlue} />
                                  {[receiver.first_name, receiver.last_name].join(" ").trim() || receiver.email}
                                  {receiver.company && ` (${receiver.company})`}
                                </div>
                              )) : (
                                <span>(No participants)</span>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="no-meetings-message">No meetings scheduled for today</div>
                    )
                  ) : (
                    <>
                      <div className="loading-meetings-message">Loading today's meetings...</div>
                      <Spinner />
                    </>
                  )}

                  <div key={null} className="todays-meeting-item new-meeting" onClick={() => this.selectMeeting(null)}>
                    <i className="fa fa-plus" />
                    <span>New meeting</span>
                  </div>
                </div>
              </>
            )}
          </div>

          {isMeetingDone && (
            <div className={bem('finished-recording-preview-container')}>
              <div className={bem('finished-recording-preview')}  style={{ visibility: 'hidden' }}>
                <Player.Player
                  playsInline
                  src={viewUrl}
                  ref={this.previewVideoPlayerRef}
                >
                  <Player.BigPlayButton
                    position="center"
                  />
                  <Player.ControlBar autoHide={true}>
                    <Player.PlayToggle />
                    <Player.ReplayControl seconds={10} order={2.1} />
                    <Player.ForwardControl seconds={10} order={3.1} />
                    <Player.PlaybackRateMenuButton rates={[2, 1.5, 1.25, 1, 0.75, 0.5]} order={6.1} />
                  </Player.ControlBar>
                </Player.Player>
              </div>
            </div>
          )}
        </div>

        {showingInfoModal && (
          <SweetAlert
            title="Recording saved"
            confirmBtnBsStyle="success"
            onConfirm={() => this.setState({ showingInfoModal: false })}
          >
            <h6>
              Your recording has been successfully saved.
              It will appear on the
              {' '}
              <Link className={bem('recordings-link')} to="/recordings">
                Recordings
              </Link>
              {' '}
              page shortly.
            </h6>
          </SweetAlert>
        )}

        {showingConfirmStopModal && (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              this.stopRecording(true);
              this.setState({ showingConfirmStopModal: false });
            }}
            onCancel={() => this.setState({ showingConfirmStopModal: false })}
          >
            <h6>
              Do you want to finish recording this meeting? It cannot be resumed later.
            </h6>
          </SweetAlert>
        )}

        {showingErrorModal && (
          <SweetAlert
          title="Recording error"
          error
          onConfirm={() => {
            this.setState({ showingErrorModal: false });
          }}
        >
          <h6>
            An error occured while trying to save the recording. Please contact support if the error persists.
          </h6>
        </SweetAlert>
        )}
      </div>
    );
  }
}

export default connect((state: any) => ({
  currentUser: state.Profile.currentuser,
  currentAccount: state.Profile.currentaccountselected,
}))(RecordLiveMeeting);
