import React, { Component } from "react";
import { Link } from "react-router-dom";

import { TABLE_SHARED_LINK_HEADERS } from "./constants";
import TableLayout from "../../components/TableLayout";
import {
  IShareLinkDetails,
  SharedLinkPermissions,
} from "../../store/share-links/interface";
import {
  firstLetterUppercase,
  formatDateTime,
} from "../../services/utilities/utilservice";

interface SharedLinksDetailsTableProps {
  sharedLinks: IShareLinkDetails[];
  loading: boolean;
  unshare: (shareLinkId: string, shareLinkType: string) => void;
  canUnshareShareLink: (ownerId: number) => boolean;
}

class SharedLinksDetailsTable extends Component<SharedLinksDetailsTableProps> {
  renderSharedWithDetails(sharedWith: SharedLinkPermissions) {
    let namesToDisplay: string[] = [];

    if (sharedWith.users && sharedWith.users.length > 0) {
      namesToDisplay = namesToDisplay.concat(sharedWith.users);
    }

    if (sharedWith.teams && sharedWith.teams.length > 0) {
      namesToDisplay = namesToDisplay.concat(sharedWith.teams);
    }

    if (sharedWith.everyone) {
      namesToDisplay.push("Everyone with link");
    }

    return namesToDisplay.join(", ");
  }

  render() {
    const { sharedLinks, loading, unshare, canUnshareShareLink } = this.props;

    return (
      <div className="table-userlist light-fontweight-table snippet-table">
        <TableLayout tableHeaders={TABLE_SHARED_LINK_HEADERS} loading={loading}>
          {sharedLinks &&
            sharedLinks.map((sharedLink: IShareLinkDetails, index: number) => {
              return (
                <tr key={sharedLink.id}>
                  <td>{index + 1}</td>
                  <td className="underline-on-hover">
                    <Link
                      to={{
                        pathname: `/${sharedLink.type}/${sharedLink.recordingSessionId}`,
                        state: { sharedLink },
                      }}
                      target="_blank"
                    >
                      {sharedLink.name}
                    </Link>
                  </td>
                  <td>{firstLetterUppercase(sharedLink.type)}</td>
                  <td>{formatDateTime(sharedLink.createdAt)}</td>
                  <td>{sharedLink.owner}</td>
                  <td className="td-wrap-text">
                    {this.renderSharedWithDetails(sharedLink.sharedWith)}
                  </td>
                  <td>
                    <Link
                      to={{
                        pathname: `/${sharedLink.type}-share/${sharedLink.linkHash}`,
                        state: { sharedLink },
                      }}
                      target="_blank"
                      className="btn btn-primary btn-sm btn-edit"
                    >
                      View
                    </Link>
                    {canUnshareShareLink(sharedLink.ownerId) && (
                      <button
                        className="btn btn-primary btn-sm btn-edit"
                        onClick={() => unshare(sharedLink.id, sharedLink.type)}
                      >
                        Unshare
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
        </TableLayout>
      </div>
    );
  }
}

export default SharedLinksDetailsTable;
