import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { has } from 'lodash';

import {
  getTelavoxTokenError,
  getTelavoxTokenSuccess,
  createTelavoxToken as createTelavoxTokenAction,
  createTelavoxTokenError,
  createTelavoxTokenSuccess,
  editTelavoxToken as editTelavoxTokenAction,
  editTelavoxTokenError,
  editTelavoxTokenSuccess,
  deleteTelavoxToken as deleteTelavoxTokenAction,
  deleteTelavoxTokenError,
  deleteTelavoxTokenSuccess,
} from "./actions";

import {
  getTelavoxToken,
  createTelavoxToken,
  editTelavoxToken,
  deleteTelavoxToken,
} from '../../../services/settings/TelavoxIntegration';

import { ResponseGenerator } from "../../../services/userservice";
import { ITelavoxToken } from "./interface";
import {
  GET_TELAVOX_TOKEN,
  CREATE_TELAVOX_TOKEN,
  EDIT_TELAVOX_TOKEN,
  DELETE_TELAVOX_TOKEN,
} from "./actionTypes";


function* getTelavoxTokenHelper() {
  const response: ResponseGenerator = yield call(getTelavoxToken);
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getTelavoxTokenError(error));
    } else {
      yield put(getTelavoxTokenError('An error has occurred!!'));
    }
  } else {
    yield put(getTelavoxTokenSuccess(response as unknown as ITelavoxToken));
  }
}

function* createTelavoxTokenHelper({
 payload,
}: ReturnType<typeof createTelavoxTokenAction>) {
  const response: ResponseGenerator = yield call(
    createTelavoxToken,
    payload,
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(createTelavoxTokenError(error));
    } else {
      yield put(createTelavoxTokenError('An error has occurred!!'));
    }
  } else {
    yield put(createTelavoxTokenSuccess(response as unknown as ITelavoxToken));
  }
}

function* editTelavoxTokenHelper({
 payload,
}: ReturnType<typeof editTelavoxTokenAction>) {
  const response: ResponseGenerator = yield call(
    editTelavoxToken,
    payload,
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(editTelavoxTokenError(error));
    } else {
      yield put(editTelavoxTokenError('An error has occurred!!'));
    }
  } else {
    yield put(editTelavoxTokenSuccess(response as unknown as ITelavoxToken));
  }
}

function* deleteTelavoxTokenHelper({
  payload,
}: ReturnType<typeof deleteTelavoxTokenAction>) {
  const response: ResponseGenerator = yield call(deleteTelavoxToken, payload);

  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteTelavoxTokenError(error));
    } else {
      yield put(deleteTelavoxTokenError('An error has occurred!!'));
    }
  } else {
    yield put(deleteTelavoxTokenSuccess());
  }
}

export function* watchGetTelavoxTokenHelper() {
  yield takeEvery(GET_TELAVOX_TOKEN, getTelavoxTokenHelper);
}

export function* watchCreateTelavoxTokenHelper() {
  yield takeEvery(CREATE_TELAVOX_TOKEN, createTelavoxTokenHelper);
}

export function* watchEditTelavoxTokenHelper() {
  yield takeEvery(EDIT_TELAVOX_TOKEN, editTelavoxTokenHelper);
}

export function* watchDeleteTelavoxTokenHelper() {
  yield takeEvery(DELETE_TELAVOX_TOKEN, deleteTelavoxTokenHelper);
}

function* TelavoxTokenSaga() {
  yield all([fork(watchGetTelavoxTokenHelper)]);
  yield all([fork(watchCreateTelavoxTokenHelper)]);
  yield all([fork(watchEditTelavoxTokenHelper)]);
  yield all([fork(watchDeleteTelavoxTokenHelper)]);
}

export default TelavoxTokenSaga;
