import React, { Component } from "react";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userSetPassword } from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";
import loaderImg from "../../assets/images/loader.gif";
import googleImg from "../../assets/images/google-icon.svg";
import { endpoints } from "../../utilities/endpoints";
import { urls } from "../../utilities/urls";
import _ from "lodash";
import { History, Location } from "history";

interface SetPasswordProps {
  success: String;
  history: History;
  location: Location;
  loading: Boolean;
  error: Object | String;
  userSetPassword: (token: String, params: Object, pathString: string) => void;
}

class SetPassword extends Component<SetPasswordProps> {
  constructor(props: SetPasswordProps) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps: SetPasswordProps) {
    const { success } = this.props;
    if (!_.isEmpty(success) && success !== prevProps.success) {
      setTimeout(() => {
        this.props.history.push("/settings/userlist");
      }, 3000);
    }
  }

  // handleValidSubmit
  handleValidSubmit = (
    event: Event,
    values: {
      password: String;
      confirmPassword: String;
    }
  ) => {
    const tokenstring = this.props.location && this.props.location.search;
    const token = new URLSearchParams(tokenstring).get("token") || "";
    const pathString = this.props.location && this.props.location.pathname;
    if (values.password === values.confirmPassword) {
      const params = {
        password: values.password,
      };
      this.props.userSetPassword(token, params, pathString);
    }
  };

  handleOpenPopup() {
    const url = `${urls.baseApiURL}${endpoints.loginWithGoogle}`;
    const width = 600,
      height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    return window.open(
      url,
      "_self",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, 
      scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
      height=${height}, top=${top}, left=${left}`
    );
  }

  handleLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.handleOpenPopup();
  };

  render() {
    const { loading } = this.props;
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2 set-password-home"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">
                            {this.props?.location?.pathname === "/setPassword"
                              ? "Set Password"
                              : "Reset Password"}
                          </h5>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profileImg} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logoImg}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        <Row>
                          <Col lg="12">
                            <div className="form-group">
                              <AvField
                                name="password"
                                label="Password"
                                type="password"
                                required
                                placeholder="Enter Password"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="form-group">
                              <AvField
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                required
                                validate={{
                                  match: {
                                    value: "password",
                                    errorMessage: "Password doesn't match",
                                  },
                                  required: {
                                    value: true,
                                    errorMessage: "This field is invalid",
                                  },
                                }}
                                placeholder="Enter Confirm Password"
                              />
                            </div>
                          </Col>
                        </Row>
                        <div className="mt-4">
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                          >
                            {loading ? (
                              <img
                                src={loaderImg}
                                alt="loader"
                                width="20"
                                height="20"
                              />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                        {this.props?.location?.pathname === "/setPassword" && (
                          <div className="form-group mt-3 d-flex justify-content-center">
                            <button
                              className="btn btn-google mt-3 waves-effect waves-light"
                              type="button"
                              ref="googleLoginBtn"
                              onClick={(
                                event: React.MouseEvent<HTMLButtonElement>
                              ) => this.handleLogin(event)}
                            >
                              <img src={googleImg} alt="Google" />
                              <span>Login with Google</span>
                            </button>
                          </div>
                        )}
                        <div className="mt-4 text-center">
                          {this.props.error && this.props.error ? (
                            <Alert color="danger">{this.props.error}</Alert>
                          ) : null}
                          {this.props.success && this.props.success ? (
                            <Alert color="success">{this.props.success}</Alert>
                          ) : null}
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>© {new Date().getFullYear()} Meetric</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state: any) => {
  const { error, success, loading } = state.getUsersList;
  return { error, success, loading };
};

export default withRouter(
  connect(mapStatetoProps, { userSetPassword })(SetPassword)
);
