import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { getMeetings } from "../../../store/actions";
import { IDeal, Icurrentaccountselected } from "../../../store/interface";
import { ModalFooter } from "reactstrap";
import { DropdownOption } from "../../Home/utils/constants";
import { Input, Label, FormGroup } from "reactstrap";
import { getDealsByAccountAndReceivers, applyDealToReceiversOnMeetings } from "../../../services/DealsService/dealService";


interface MeetingDealsModalProps {
  isOpen: boolean;
  closeModal: () => void;
  currentaccountselected: Icurrentaccountselected;
  receivers: string[];
  sessionId: String;
  currentDeal: DropdownOption | null;
  shouldFetchMeetings: any;
}

interface MeetingDealsModalState {
  meetingDealSelectedOptions: DropdownOption | null;
  checkboxSelected: boolean;
  dealOptions: IDeal[];
}

class MeetingDealsModal extends Component<
  MeetingDealsModalProps,
  MeetingDealsModalState
> {
  constructor(props: MeetingDealsModalProps) {
    super(props);
    this.state = {
      meetingDealSelectedOptions: null,
      checkboxSelected: false,
      dealOptions: []
    };
  }

  async componentDidUpdate(prevProps: MeetingDealsModalProps) {
    const { isOpen, currentaccountselected, receivers, currentDeal} = this.props;
    if (isOpen && !prevProps.isOpen) {
      try {
        const deals = await getDealsByAccountAndReceivers({
          receiver_ids: receivers,
          account_id: currentaccountselected.id
        });

        this.setState({
          dealOptions: deals
        });
  
      } catch (error) {
        console.error("Error fetching deals:", error);
      }
    }

    if(currentDeal && !prevProps.currentDeal) {
      this.setState({
        meetingDealSelectedOptions: currentDeal
      });
    }
  }

   handleFormSumbit = async () => {
    const { sessionId, currentaccountselected, closeModal, shouldFetchMeetings} = this.props;
    const {meetingDealSelectedOptions, checkboxSelected} = this.state;
    const dealId = meetingDealSelectedOptions?.id === 'empty' ? null : meetingDealSelectedOptions?.id?.toString() || '';

    try {
      await applyDealToReceiversOnMeetings(currentaccountselected.id, dealId, sessionId, checkboxSelected);
      closeModal();
      shouldFetchMeetings(true);
    } catch (error) {
      console.error("Error applying Deal to Receivers on Meetings:", error);
    }
  };

  handleDealOptions = (
    selectedOption: DropdownOption | null,
    actionMeta: any
  ) => {
    this.setState({ meetingDealSelectedOptions: selectedOption });
  };

  render() {
    const { isOpen, closeModal } = this.props;
    const { dealOptions, meetingDealSelectedOptions, checkboxSelected} = this.state;
    const emptyOption: DropdownOption = { label: "Unassign deal from meeting", value: 'null', id: "empty" };

    const meetingDealOptions: DropdownOption[] = [emptyOption].concat(
      (dealOptions || []).map((el: any) => {
        return {
          label: el.name,
          value: el.id,
          id: el.id,
        };
      })
    );

    return (
      <>
        <Modal isOpen={isOpen} size="md" centered>
          <ModalHeader>
            Choose Receiver Based Deals
            <button
              onClick={closeModal}
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              className="close-button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody>
            <Select
              value={meetingDealSelectedOptions}
              onChange={this.handleDealOptions}
              options={meetingDealOptions}
            />
            <FormGroup check className="checkbox m-2 flex items-center">
              <Input
                type="checkbox"
                name="applyToAllMeetings"
                onChange={() => {
                  this.setState({
                    checkboxSelected: !checkboxSelected,
                  });
                }}
                id="applyToAllMeetings"
              />
              <Label for="applyToAllMeetings" check>
                Change deal for all meetings with this receiver/receivers
              </Label>
            </FormGroup>
          </ModalBody>
          <ModalFooter><button className="btn btn-primary btn-sm" onClick={this.handleFormSumbit}>Save</button></ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { meetingFilters } = state.globalFilters;

  return {
    meetingFilters
  };
};

export default connect(mapStateToProps, { getMeetings })(MeetingDealsModal)