import {
  INTEGRATION_ERROR,
  INTEGRATION_SUCCESS,
  INTEGRATE_MICROSOFT_DYNAMICS,
  LOGIN_PIPEDRIVE,
  LOGIN_PIPEDRIVE_SUCCESS,
  LOGIN_PIPEDRIVE_ERROR,
  INTEGRATION_REMOVE,
  INTEGRATION_REMOVE_SUCCESS,
  INTEGRATION_REMOVE_ERROR,
} from "./actionTypes";
import { IntegrationAction, IntegrationState } from "./interface";

const initialState: IntegrationState = {
  error: [],
  success: "",
  loader: false,
  payload: null,
  redirectUrl: null,
};

const Integration = (state = initialState, action: IntegrationAction) => {
  switch (action.type) {
    case INTEGRATE_MICROSOFT_DYNAMICS:
      state = { ...state, loader: true, error: [], success: "", payload: action.payload};
      break;
    case INTEGRATION_SUCCESS:
      state = { ...state, loader: false, success: action.payload };
      break;
    case INTEGRATION_ERROR:
      state = { ...state, loader: false, error: action.payload };
      break;
    case LOGIN_PIPEDRIVE:
      state = { ...state, loader: true, error: [], redirectUrl: null};
      break;
    case LOGIN_PIPEDRIVE_SUCCESS:
      state = { ...state, loader: false, redirectUrl: action.payload };
      break;
    case LOGIN_PIPEDRIVE_ERROR:
      state = { ...state, loader: false, error: action.payload };
      break;
    case INTEGRATION_REMOVE:
      state = { ...state, loader: true };
      break;
    case INTEGRATION_REMOVE_SUCCESS:
      state = { ...state, loader: false };
      break;
    case INTEGRATION_REMOVE_ERROR:
      state = { ...state, loader: false, error: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Integration;
