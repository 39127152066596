import {
  GET_MEETINGS,
  GET_MEETINGS_ERROR,
  GET_MEETINGS_SUCCESS,
  GET_MEETINGS_COUNT,
  GET_MEETINGS_COUNT_SUCCESS,
  GET_MEETINGS_COUNT_ERROR,
  CREATE_MEETING,
  CREATE_MEETING_SUCCESS,
  EDIT_MEETING,
  EDIT_MEETING_SUCCESS,
  DELETE_MEETING,
  DELETE_MEETING_SUCCESS,
  GET_CREATED_MEETING,
  API_ERROR,
  GET_MEETINGS_USING_TIME_OPTIONS_SUCCESS,
  CLEAR_MEETINGS_DATA,
  GET_MEETING_CALENDAR_SYNC_INFO,
  GET_MEETING_CALENDAR_SYNC_INFO_SUCCESS,
  GET_MEETING_CALENDAR_SYNC_INFO_ERROR,
  CLEAR_MEETING_CALENDAR_SYNC_INFO,
} from "./actionTypes";
import { IMeetingType } from "../settings/MeetingType/interface";
import { MeetingTime } from "../../pages/Meetings/interface";
import { FilterObject } from "../interface";

export const meetingTypeToStateKey: MeetingTypeToStateKeyMap = {
  [MeetingTime.UPCOMING]: "upcomingMeetings",
  [MeetingTime.PAST]: "pastMeetings",
  [MeetingTime.UNSCHEDULED]: "unscheduledMeetings",
};

interface Attendee {
  index?: number;
  first_name: string;
  last_name?: string;
  company?: string;
  email: string;
  phone?: string;
  position?: string;
  isCompany?: Boolean;
}

export interface sendMeetingData {
  start_time: string | null | undefined;
  name: String;
  company: String;
  template: boolean;
  duration: string | 3600;
  branding_id: string | null;
  language: string | null;
  platform: string;
  background: any;
}

export interface IMeeting {
  id?: string;
  auto_join?: boolean;
  name: string;
  subdomain: string;
  company: string;
  duration: string;
  language: string;
  original_integration: string;
  integration: string;
  tags: String[];
  start_time: Date;
  files: {
    id: String;
    mime_type: String;
    filetype: String;
    url: String;
  }[];
  receivers: Attendee[];
  presentations: {
    id: String;
    name: String;
  };
  logo_img: String;
  background_img: String;
  background: {};
  branding_id: String;
  created_at: string;
  created_by: {
    first_name: string,
    last_name: string,
  }
}

export interface IMeetingCalendarSyncData {
  meeting: string;
  eventId: string;
  eventUrl: string;
  autoJoin: boolean;
  autoJoinTriggeredAt: string;
  recordedByMeeting: string;
  filterReason: string;
  eventLog: string[]; 
}
export interface MeetingState {
  success: String;
  error: String | Object;
  getLoader: Boolean;
  getCountLoader: Boolean;
  meetingsCount: number;
  loader: Boolean;
  deleteLoader: Boolean;
  createdResource: Object | String | null | String[];
  upcomingMeetings: IMeeting[];
  pastMeetings: IMeeting[];
  unscheduledMeetings: IMeeting[];
  deletedId: string;
  meetings: IMeeting[];
  meetingTypes: IMeetingType[] | null;
  meetingTimeSelected: MeetingTime;
  meetingCalendarSyncInfo: IMeetingCalendarSyncData | null;
  meetingCalendarSyncInfoError: String | Object | null;
}

export type MeetingTypeToStateKeyMap = {
  [key in MeetingTime]: string;
};

export interface getMeetings {
  type: typeof GET_MEETINGS;
  payload: {
    id: String;
    data: FilterObject;
    meetingType?: string;
    limit?: number;
    query?: string;
  };
}

export type getMeetingsSuccess = {
  type: typeof GET_MEETINGS_SUCCESS;
  payload: IMeeting[];
};

export type clearMeetingsData = {
  type: typeof CLEAR_MEETINGS_DATA;
}

export type getMeetingsError = {
  type: typeof GET_MEETINGS_ERROR;
  payload: {
    error: String | Object;
  };
};

export interface getMeetingsCount {
  type: typeof GET_MEETINGS_COUNT;
  payload: {
    id: String;
    meetingTime: MeetingTime;
    meetingFilters?: FilterObject;
    query?: string;
  };
}

export type getMeetingsCountSuccess = {
  type: typeof GET_MEETINGS_COUNT_SUCCESS;
  payload: number;
};

export type getMeetingsCountError = {
  type: typeof GET_MEETINGS_COUNT_ERROR;
  payload: {
    error: String | Object;
  };
};

export type getCreatedMeeting = {
  type: typeof GET_CREATED_MEETING;
  payload: {
    meetings: IMeeting | null;
  };
};

export interface createMeeting {
  type: typeof CREATE_MEETING;
  payload: {
    accountId: String;
    meetings: sendMeetingData | null;
    meetingTime: MeetingTime;
  };
}

export interface createMeetingSuccess {
  type: typeof CREATE_MEETING_SUCCESS;
  payload: string;
}

export interface editMeeting {
  type: typeof EDIT_MEETING;
  payload: {
    accountId: String;
    meetingId: String;
    meetings: sendMeetingData;
    meetingTime: MeetingTime;
  };
}

export type editMeetingSuccess = {
  type: typeof EDIT_MEETING_SUCCESS;
  payload: String;
};

export type deleteMeetingSuccess = {
  type: typeof DELETE_MEETING_SUCCESS;
  payload: { mes: string, meetingId: string, meetingTime?: string };
};

export interface deleteMeeting {
  type: typeof DELETE_MEETING;
  payload: { accountId: String; meetingId: String; meetingTime?: string };
}

export type apiMeetingError = {
  type: typeof API_ERROR;
  payload: {
    error: String | Object;
  };
};

export type getMeetingsUsingTimeOptionsSuccess = {
  type: typeof GET_MEETINGS_USING_TIME_OPTIONS_SUCCESS;
  payload: { timeType: string; data: IMeeting[] };
};

export type getMeetingCalendarSyncInfo = {
  type: typeof GET_MEETING_CALENDAR_SYNC_INFO,
  payload: IMeetingCalendarSyncData,
}

export type getMeetingCalendarSyncInfoSuccess = {
  type: typeof GET_MEETING_CALENDAR_SYNC_INFO_SUCCESS,
  payload: IMeetingCalendarSyncData,
}

export type getMeetingCalendarSyncInfoError = {
  type: typeof GET_MEETING_CALENDAR_SYNC_INFO_ERROR,
  payload: {
    error: String | Object;
  },
}

export type clearMeetingCalendarSyncInfo = {
  type: typeof CLEAR_MEETING_CALENDAR_SYNC_INFO;
}

export type MeetingAction =
  | getMeetings
  | getMeetingsSuccess
  | getCreatedMeeting
  | getMeetingsError
  | getMeetingsCount
  | getMeetingsCountSuccess
  | getMeetingsCountError
  | createMeeting
  | createMeetingSuccess
  | editMeeting
  | editMeetingSuccess
  | deleteMeeting
  | deleteMeetingSuccess
  | apiMeetingError
  | getMeetingsUsingTimeOptionsSuccess
  | clearMeetingsData
  | getMeetingCalendarSyncInfo
  | getMeetingCalendarSyncInfoSuccess
  | getMeetingCalendarSyncInfoError
  | clearMeetingCalendarSyncInfo
