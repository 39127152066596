import React, { Component } from 'react';
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Input,
  Label,
  List,
} from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  getTimeLineComments,
  addTimeLineComment,
  deleteTimeLineComment,
  getUserList,
} from '../../store/actions';
import {
  IAnalyticsMeetingTimelineComment,
  IUser,
} from '../../store/interface';
import {
  escapeHtmlSpecialChars,
  formatDateTime,
  formatDate,
  formatTime,
} from '../../services/utilities/utilservice';

interface IComment extends IAnalyticsMeetingTimelineComment {}

interface DropdownGroup {
  label: String;
  value: IUser;
  id: String | number;
}

interface CommentListProps {
  accountId: String;
  currentuser: IUser;
  loading: boolean;
  progress: number;
  timeLineSessionId: string;
  timeLineUpdateSeekDirect: any;
  timeLineComments: IComment[];
  getTimeLineComments: (accountId: String, sessionId: String) => void;
  addTimeLineComment: (accountId: String, sessionId: String, comment: Object, resolve: any, reject: any) => void;
  deleteTimeLineComment: (comment: String, resolve: any, reject: any) => void;
  users: IUser[];
  getUserList: (id: String) => void;
  showAddSnippet: boolean;
  timeLineTargetTime: number;
  canComment?: boolean;
}

interface CommentListState {
  comments: IComment[];
  replies: any;
  isTagMenuVisible: boolean;
  userOptions: DropdownGroup[];
  filteredUserOptions: DropdownGroup[];
  sortedUserOptions: DropdownGroup[];
  activeEditableElement: any;
  x: number;
  y: number;
  activeIndex: number;
  showAddComment: boolean;
  addCommentTime: number;
  timerStopped: boolean;
}

class CommentList extends Component<
  CommentListProps,
  CommentListState
> {
  commentsLoaded: boolean = false;
  blurTimeout: any = null;
  caretIndexAtBlur: number = 0;
  skipFocusEvent: boolean = false;
  reloadComments: boolean = false;

  constructor(props: CommentListProps) {
    super(props);
    this.state = {
      comments: [],
      replies: {},
      isTagMenuVisible: true,
      userOptions: [],
      filteredUserOptions: [],
      sortedUserOptions: [],
      activeEditableElement: null,
      x: 0,
      y: -100,
      activeIndex: 0,
      showAddComment: false,
      addCommentTime: 0,
      timerStopped: false
    };
  }

  componentDidMount() {
    const {
      accountId,
      timeLineSessionId,
    } = this.props;

    if (!_.isEmpty(accountId) && this.commentsLoaded === false) {
      this.commentsLoaded = true;
      this.props.getTimeLineComments(accountId, timeLineSessionId);
      this.props.getUserList(accountId);
    }

    window.onscroll = () => {
      this.positionTagMenu();
    };
  }

  componentDidUpdate() {
    const {
      accountId,
      timeLineSessionId,
      timeLineComments,
      users,
    } = this.props;
    const {
      comments,
      userOptions,
    } = this.state;

    if (!_.isEmpty(accountId) && this.commentsLoaded === false) {
      this.commentsLoaded = true;
      this.props.getTimeLineComments(accountId, timeLineSessionId);
      this.props.getUserList(accountId);
    }

    if (!_.isEmpty(timeLineComments) && !_.isEmpty(comments)) {
      let compareComments = timeLineComments.filter((item: IComment) => {
        return item.reply_parent_id === null;
      });

      if(compareComments.length !== comments.length) {
        this.reloadComments = true;
      }
    }

    if(this.reloadComments) {
      this.reloadComments = false;
      this.resetComments();
    }

    if (!_.isEmpty(timeLineComments) && _.isEmpty(comments)) {
      for (let item of timeLineComments) {
        this.parse(item);
      }
    }

    if (!_.isEmpty(users) && _.isEmpty(userOptions)) {
      let mappedUsers: DropdownGroup[] = users.map((user: IUser, key: number) => {
        return {
          label: this.getUserLabel(user),
          value: user,
          id: user.id,
        };
      });
      // Sort ascending by full name.
      mappedUsers.sort((a: DropdownGroup, b: DropdownGroup) => this.getUserLabel(a.value).localeCompare(this.getUserLabel(b.value)));
      // Sort descending by full name.
      let sortedUsers: DropdownGroup[] = _.cloneDeep(mappedUsers);
      sortedUsers.sort((a: DropdownGroup, b: DropdownGroup) => this.getUserLabel(b.value).localeCompare(this.getUserLabel(a.value)));

      this.setState({
        userOptions: mappedUsers,
        filteredUserOptions: mappedUsers,
        sortedUserOptions: sortedUsers,
      });
    }
  }

  resetComments = () => {
    this.setState({comments: []});
  }

  parse = (item: IComment, prepend: boolean = false) => {
    const {
      comments,
      replies,
    } = this.state;

    if (item.reply_parent_id === null) {
      item.collapsedReply = true;
      prepend && comments.unshift(item);
      !prepend && comments.push(item);
      this.setState({ comments });
    } else {
      if (typeof replies[item.reply_parent_id] === 'undefined') replies[item.reply_parent_id] = [];
      prepend && replies[item.reply_parent_id].unshift(item);
      !prepend && replies[item.reply_parent_id].push(item);
      this.setState({ replies });
    }
  };

  getCaretCoordinates = () => {
    let x: number = 0;
    let y: number = -100;
    const isSupported: boolean = typeof window.getSelection !== 'undefined';

    if (isSupported) {
      const selection: any = window.getSelection();
      if (selection.rangeCount !== 0) {
        const range: any = selection.getRangeAt(0).cloneRange();
        range.collapse(true);
        const rect: any = range.getClientRects()[0];
        if (rect) {
          x = rect.left;
          y = rect.top;
        }
      }
    }

    return { x, y };
  };

  /**
   * Inside a contentEditable element.
   */
  getCaretIndex = (el: any) => {
    let position: number = 0;
    const isSupported: boolean = typeof window.getSelection !== 'undefined';

    if (isSupported) {
      const selection: any = window.getSelection();
      if (selection.rangeCount !== 0) {
        const range: any = window.getSelection()?.getRangeAt(0);
        if (typeof range !== 'undefined') {
          const preCaretRange: any = range.cloneRange();
          preCaretRange.selectNodeContents(el);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          position = preCaretRange.toString().length;
        }
      }
    }

    return position;
  };

  getStringBeforeCaret = (el: any) => {
    return el.innerText.slice(0, this.getCaretIndex(el));
  };

  restoreCaretIndex = (el: any, caretIndex: number) => {
    const isSupported: boolean =
      typeof window.getSelection !== 'undefined' &&
      typeof document.createRange !== 'undefined';
    if (!isSupported) return;

    let charIndex: number = 0;
    let nodeStack: any[] = [el];
    let node: any;
    let stop: boolean = false;

    const range: any = document.createRange();
    range.setStart(el, 0);
    range.collapse(true);

    while (!stop && (node = nodeStack.pop())) {
      if (node.nodeType == 3) {
        let nextCharIndex: number = charIndex + node.length;
        if (caretIndex >= charIndex && caretIndex <= nextCharIndex) {
          range.setStart(node, caretIndex - charIndex);
          stop = true;
        }
        charIndex = nextCharIndex;
      } else {
        let i: number = node.childNodes.length;
        while (i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }

    var sel = window.getSelection();
    sel?.removeAllRanges();
    sel?.addRange(range);
  };

  tagMenuControls = (event: any) => {
    const {
      isTagMenuVisible,
      filteredUserOptions,
      activeIndex,
    } = this.state;

    switch (event.key) {
      case 'ArrowUp':
        if (isTagMenuVisible === true) {
          event.preventDefault();
          const newIndex1: number = activeIndex === 0
            ? filteredUserOptions.length - 1
            : activeIndex - 1;
          this.changeActiveIndex(newIndex1);
        }
        break;
      case 'ArrowDown':
        if (isTagMenuVisible === true) {
          event.preventDefault();
          const newIndex2: number = activeIndex + 1 === filteredUserOptions.length
            ? 0
            : activeIndex + 1;
          this.changeActiveIndex(newIndex2);
        }
        break;
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'Home':
      case 'End':
      case 'PageUp':
      case 'PageDown':
        if (event.altKey === false) {
          let persistEventTarget: any = { currentTarget: event.currentTarget };
          setTimeout(() => {
            this.openTagMenu(persistEventTarget);
          });
        }
        break;
      case 'Enter':
      case 'Tab':
        if (isTagMenuVisible === true && event.shiftKey === false) {
          event.preventDefault();
          this.handleTagging(filteredUserOptions[activeIndex]);
        }
        break;
      case 'Escape':
        if (isTagMenuVisible === true) {
          event.preventDefault();
          this.closeTagMenu();
        }
        break;
    };
  };

  changeActiveIndex = (newIndex: number) => {
    this.setState({
      activeIndex: newIndex,
    });
  };

  openTagMenu = (event: any) => {
    const {
      userOptions,
    } = this.state;

    this.resetBlurTimeout();
    let string: string = this.getStringBeforeCaret(event.currentTarget);

    if (string.lastIndexOf('@') === -1) {
      this.setState({
        isTagMenuVisible: false,
        activeEditableElement: event.currentTarget,
      });
      return;
    }

    let names: string[] = string.slice(string.lastIndexOf('@') + 1).toLowerCase().split(' ');

    let filteredUserOptions: DropdownGroup[] = userOptions.filter((option: DropdownGroup) => {
      if (names.length > option.label.split(' ').length) {
        return false;
      }

      for (let name of names) {
        if (option.label.toLowerCase().includes(name) === false) {
          return false;
        }
      }

      return true;
    });

    this.setState({
      isTagMenuVisible: filteredUserOptions.length > 0,
      filteredUserOptions: filteredUserOptions,
      activeEditableElement: event.currentTarget,
      activeIndex: 0,
    }, () => {
      this.positionTagMenu();
    });
  };

  positionTagMenu = () => {
    const {
      isTagMenuVisible,
    } = this.state;

    if (isTagMenuVisible) {
      let coords: any = this.getCaretCoordinates();
      this.setState({
        x: coords.x,
        y: coords.y,
      });
    }
  };

  closeTagMenu = () => {
    this.setState({
      isTagMenuVisible: false,
    });
  };

  /**
   * Tag a user by selecting the option from the dropdown.
   */
  handleTagging = (option: DropdownGroup) => {
    const {
      activeEditableElement,
    } = this.state;

    let string = this.getStringBeforeCaret(activeEditableElement);
    let preText: string = activeEditableElement.innerText.slice(0, string.lastIndexOf('@') + 1);
    let afterText: string = activeEditableElement.innerText.slice(string.length);

    this.generateHtml(preText + option.label + (afterText[0] === ' ' ? afterText : ' ' + afterText));
    this.restoreCaretIndex(activeEditableElement, preText.length + option.label.length + 1);
    this.closeTagMenu();
  };

  generateHtml = (text: string) => {
    const {
      activeEditableElement,
    } = this.state;

    activeEditableElement.innerHTML = this.tagUsers(escapeHtmlSpecialChars(text));
  };

  tagUsers = (text: string) => {
    const {
      sortedUserOptions,
    } = this.state;
    let html: string = text;

    for (let option of sortedUserOptions) {
      const regex: any = new RegExp('(^|[^>])@' + this.getUserLabel(option.value), 'gi');
      html = html.replace(regex, '$1' + this.tagUser(option.value, false));
    }

    return html;
  };

  tagUser = (user: IUser, asJsxElement: boolean = true) => {
    if (asJsxElement === true) {
      return (
        <a href="javascript:void(0)" data-user-id={user.id}>
          @{this.getUserLabel(user)}
        </a>
      );
    }

    return (
      '<a href="javascript:void(0" data-user-id="' + user.id + '">' +
        '@' + this.getUserLabel(user) +
      '</a>'
    );
  };

  getUserLabel = (user: IUser) => {
    return user.first_name + ' ' + user.last_name;
  };

  resetBlurTimeout = () => {
    clearTimeout(this.blurTimeout);
    this.blurTimeout = null;
  };

  handleSubmit = (event: any, reload: boolean) => {
    event.preventDefault();

    const {
      accountId,
      currentuser,
      timeLineSessionId,
    } = this.props;

    let formElements: any = event.currentTarget.elements;
    let editableElement: any = event.currentTarget.querySelector('.editable-element');
    let text: string = editableElement.innerText.trim();
    if (text.length === 0) {
      return;
    }

    let comment: IComment = {
      id: 'temp',
      created_by: currentuser.id,
      reply_parent_id: formElements?.reply_parent_id?.value || null,
      text: text,
      target_time: typeof formElements?.target_time !== 'undefined'
        ? ~~formElements.target_time.value
        : 0,
      created_at: formatDateTime(moment().utc()),
      visible: formElements?.visible?.value === 'false' ? false : true,
    };

    let promise: any = new Promise((resolve, reject) => {
      this.props.addTimeLineComment(accountId, timeLineSessionId, comment, resolve, reject);
    });
    promise.then((response: any) => {
      editableElement.innerHTML = '';
      this.toggleAddComment();
      comment.id = response.data.id;
      comment.created_by = currentuser;
      this.parse(comment, true);
      this.handleTimerStop(false);
      if (reload) {
        this.props.getTimeLineComments(accountId, timeLineSessionId);
      }
    });
  };

  toggleAddComment = () => {
    const {
      showAddComment,
    } = this.state;

    this.setState({ showAddComment: !showAddComment });
  };

  getGroupedComments = () => {
    const {
      comments,
    } = this.state;

    return comments;
  };

  getLeftPos = (targetTime: number) => {

    return 0;
  };

  handleTimerStop = (stop: boolean) => {
    const {
      timeLineTargetTime
    } = this.props;

    this.setState({ addCommentTime: timeLineTargetTime, timerStopped: stop });
  }

  uncollapseCommentReply = (comment: IComment) => {
    const { comments } = this.state;

    let index = comments.findIndex(item => item.id === comment.id);
    comments[index].collapsedReply = !comment.collapsedReply;

    this.setState({comments});
  }

  deleteComment = (comment: IComment) => {
    const {
      accountId,
      timeLineSessionId
    } = this.props;

    // On Success
    // this.props.deleteTimeLineComment(
    //   accountId,
    //   timeLineSessionId,
    //   comment,
    //   () => {
    //     this.props.getTimeLineComments(accountId, timeLineSessionId)
    //   },
    //   ()=> {}
    // );

    let promise: any = new Promise((resolve, reject) => {
      this.props.deleteTimeLineComment(comment.id, resolve, reject);
    });

    promise.then((response: any) => {
      this.props.getTimeLineComments(accountId, timeLineSessionId);
      this.reloadComments = true;
    });
  }

  render() {
    const {
      progress,
      timeLineUpdateSeekDirect,
      showAddSnippet,
      timeLineTargetTime,
      canComment
    } = this.props;
    const {
      comments,
      replies,
      isTagMenuVisible,
      filteredUserOptions,
      x,
      y,
      activeIndex,
      showAddComment,
      addCommentTime,
      timerStopped
    } = this.state;
    let previews: any = [];

    return (

      <div className='comments pb-3 px-3'>
        {canComment && (
          <div className='new-comment'>

            <div className='comment-wrapper'>
            <h4
              id={'new-comment-title'}
              className='comment-title'
            >
              Write comment at
              <a
                onClick={(event: any) => {
                  const time = timerStopped ? addCommentTime : timeLineTargetTime;
                  timeLineUpdateSeekDirect(time);
                }}
              >
                {' ' + formatTime(timerStopped ? addCommentTime : timeLineTargetTime)}
              </a>
            </h4>

                <Form onSubmit={(e: any) => this.handleSubmit(e, true)}>
                  <Row>
                    {/** Textarea */}
                    <Col lg='12' style={{ paddingRight: '0' }}>
                      <div
                        className='editable-element'
                        placeholder='Enter comment. Use @ to notify team members.'
                        onKeyDown={(event: any) => {
                          if(!timerStopped) {
                            this.handleTimerStop(true);
                          }
                          this.tagMenuControls(event);
                        }}
                        onInput={(event: any) => {
                          let caretIndex: number = this.getCaretIndex(event.currentTarget);
                          this.generateHtml(event.currentTarget.innerText);
                          this.restoreCaretIndex(event.currentTarget, caretIndex);
                          this.openTagMenu(event);
                        }}
                        onClick={(event: any) => {
                          this.openTagMenu(event);
                        }}
                        onFocus={(event: any) => {
                          if (this.skipFocusEvent === true) return;
                          this.openTagMenu(event);
                        }}
                        onBlur={(event: any) => {
                          this.caretIndexAtBlur = this.getCaretIndex(event.currentTarget);
                          this.blurTimeout = setTimeout(() => {
                            this.closeTagMenu();
                          }, 250);
                          if (event.target.innerHTML === "") {
                            this.handleTimerStop(false);
                          }
                        }}
                        contentEditable='true'
                        suppressContentEditableWarning={true}
                        style={{height: "150px"}}
                      >
                      </div>
                    </Col>
                  </Row>

                  <div className='d-flex justify-content-between align-items-center'>
                    {/* <Row> */}
                    <span className='comment-text mr-2 '><b className='small-comment'>Visible for</b></span>
                    {/** Radio buttons */}
                    <span className='button-group'>
                      <FormGroup check inline className="mr-2">
                        <Input
                          id='visible-false'
                          type='radio'
                          name='visible'
                          value='false'
                          defaultChecked
                        />
                        <Label
                          check
                          for='visible-false'
                          className="comment-text small-comment"
                        >
                          Owner
                        </Label>
                      </FormGroup>

                      <FormGroup check inline>
                        <Input
                          id='visible-true'
                          type='radio'
                          name='visible'
                          value='true'
                        />
                        <Label
                          check
                          for='visible-true'
                          className="comment-text small-comment"
                        >
                          Anybody
                        </Label>
                      </FormGroup>
                    </span>
                    <span className='ml-auto'>
                       <Button color='blue' className='post-comment-button small-comment'>
                      Post comment
                    </Button>
                    </span>
                  </div>


                  {/* <input type='hidden' name='reply_parent_id' value={comment.id} /> */}
                  <input type='hidden' name='target_time' value={addCommentTime} />
                </Form>
            </div>
          </div>
        )}

        <div className='comment-list'>


          {/** List of comments */}
          {/* {!_.isEmpty(comments) ? */}

          {!_.isEmpty(comments) && comments.map((comment: IComment) => {
            return (
              <div key={comment.id}>
                {/** Comment title */}
                <div className='d-flex justify-content-between align-items-center'>
                  <h3
                    id={'comment-title-' + comment.id}
                    className='comment-title'
                  >
                    <a
                      onClick={(event: any) => {
                        timeLineUpdateSeekDirect(comment.target_time);
                      }}
                    >
                      {formatTime(comment.target_time)}
                    </a>
                  </h3>
                    <i className="fa fa-trash fa-lg" aria-hidden="true" onClick={() => this.deleteComment(comment)}></i>
                </div>

                <div className='comment-wrapper'>
                  {/** Main comment */}
                  <Row className='comment-box' data-id={comment.id} onClick={() => this.uncollapseCommentReply(comment)}>
                    <Col lg='12'>
                      <p
                        className='comment-text'
                        dangerouslySetInnerHTML={{ __html: this.tagUsers(comment.text) }}
                      />
                      <p
                        className='comment-details'
                      >
                        Said by {this.tagUser(comment.created_by)}, {formatDate(comment.created_at)}
                      </p>
                    </Col>
                  </Row>

                  {/** Replies */}
                  {replies[comment.id]?.map((reply: IComment, key: number) => {
                    return (
                      <Row key={key} className='reply-box' data-id={reply.id}>
                        <Col lg='12'>
                          <p
                            className='comment-text'
                            dangerouslySetInnerHTML={{ __html: this.tagUsers(reply.text) }}
                          />
                          <p
                            className='comment-details'
                          >
                            Said by {this.tagUser(reply.created_by)}, {formatDate(reply.created_at)}
                          </p>
                        </Col>
                      </Row>
                    );
                  })}
                  { (showAddSnippet && !comment.collapsedReply) &&
                    <Form onSubmit={(e: any) => this.handleSubmit(e, false)}>
                      <Row>
                        {/** Textarea */}
                        <Col lg='12' style={{ paddingRight: '0' }}>
                          <div
                            className='editable-element'
                            placeholder='Write reply here'
                            onKeyDown={(event: any) => {
                              this.tagMenuControls(event);
                            }}
                            onInput={(event: any) => {
                              let caretIndex: number = this.getCaretIndex(event.currentTarget);
                              this.generateHtml(event.currentTarget.innerText);
                              this.restoreCaretIndex(event.currentTarget, caretIndex);
                              this.openTagMenu(event);
                            }}
                            onClick={(event: any) => {
                              this.openTagMenu(event);
                            }}
                            onFocus={(event: any) => {
                              if (this.skipFocusEvent === true) return;
                              this.openTagMenu(event);
                            }}
                            onBlur={(event: any) => {
                              this.caretIndexAtBlur = this.getCaretIndex(event.currentTarget);
                              this.blurTimeout = setTimeout(() => {
                                this.closeTagMenu();
                              }, 250);
                            }}
                            contentEditable='true'
                            suppressContentEditableWarning={true}
                          >
                          </div>
                        </Col>
                        {/** Buttons */}
                        <Col lg='8'>
                          <Button
                            color='blue'
                            className="reply-btn"
                          >
                            Reply
                          </Button>
                        </Col>
                      </Row>

                      <input type='hidden' name='reply_parent_id' value={comment.id} />
                      <input type='hidden' name='target_time' value={comment.target_time} />
                    </Form>
                  }
                </div>
              </div>
            );
          })}

          {_.isEmpty(comments) &&
          <div className='comment-wrapper'>
          <Row className='comment-box justify-content-center align-items-center'>
              <span
                className='comment-details'
              >
                There are no comments yet...
              </span>
          </Row>
          </div>

          }
           {/* :
          <p>
            test
          </p>
          } */}

          {/** User tag menu */}
          {isTagMenuVisible &&
              <div
                  className='inline-select-menu'
                  style={{ left: `${x}px`, top: `${y}px`, }}
              >
                <List type='unstyled'>
                  {filteredUserOptions.map((option: DropdownGroup, index: number) => {
                    return (
                        <li
                            className={activeIndex === index ? 'active' : ''}
                            key={index.toString()}
                            onMouseEnter={(event: any) => {
                              this.changeActiveIndex(index);
                            }}
                            onClick={(event: any) => {
                              const {
                                activeEditableElement,
                              } = this.state;

                              this.resetBlurTimeout();
                              this.skipFocusEvent = true;
                              activeEditableElement.focus();
                              setTimeout(() => {
                                this.restoreCaretIndex(activeEditableElement, this.caretIndexAtBlur);
                                this.handleTagging(option);
                                this.skipFocusEvent = false;
                              });
                            }}
                        >
                          {option.label}
                        </li>
                    );
                  })}
                </List>
              </div>
          }
        </div>
    </div>

    );
  }
}

const mapStatetoProps = (state: any) => {
  const {
    currentuser,
  } = state.Profile;
  const {
    loading,
    timeLineComments,
  } = state.SalesAnalytics;
  const { filterUsers } = state.getUsersList;

  return {
    currentuser,
    loading,
    users: filterUsers,
    timeLineComments,
  };
};

export default connect(mapStatetoProps, {
  getTimeLineComments,
  addTimeLineComment,
  deleteTimeLineComment,
  getUserList,
})(CommentList);
