import { REGISTER_USER, REGISTER_USER_SUCCESSFUL, REGISTER_USER_FAILED } from './actionTypes';

export const registerUser = (user: Object) => {
    return {
        type: REGISTER_USER,
        payload: { user }
    }
}

export const registerUserSuccessful = (msg: String) => {
    return {
        type: REGISTER_USER_SUCCESSFUL,
        payload: msg
    }
}

export const registerUserFailed = (msg: String | Object) => {
    return {
        type: REGISTER_USER_FAILED,
        payload: msg
    }
}
