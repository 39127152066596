import { has } from "lodash";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
	CHANGE_LAYOUT,
	CHANGE_LAYOUT_WIDTH,
	CHANGE_SIDEBAR_THEME,
	CHANGE_SIDEBAR_TYPE,
	CHANGE_TOPBAR_THEME,
	TOGGLE_RIGHT_SIDEBAR,
	SHOW_RIGHT_SIDEBAR,
	HIDE_RIGHT_SIDEBAR,
	CREATE_WIDGET_LAYOUT,
  GET_DASHBOARDS,
} from "./actionTypes";

import {
	changeSidebarType as changeSidebarTypeAction,
	changeTopbarTheme as changeTopbarThemeAction,
  getDashboardsError,
  getDashboardsSuccess,
  getDashboards as getDashboardsAction
} from "./actions";
import { ResponseGenerator, createWidgetLayout, getDashboards } from "../../services/userservice";

function changeBodyAttribute(attribute: string, value: string) {
	if (document.body) document.body.setAttribute(attribute, value);
	return true;
}

function manageBodyClass(cssClass: string, action = "toggle") {
	switch (action) {
		case "add":
			if (document.body) document.body.classList.add(cssClass);
			break;
		case "remove":
			if (document.body) document.body.classList.remove(cssClass);
			break;
		default:
			if (document.body) document.body.classList.toggle(cssClass);
			break;
	}

	return true;
}

function* changeLayout({ payload: layout }: any) {
	try {
		if (layout === 'horizontal') {
			yield put(changeTopbarThemeAction('dark'));
			document.body.removeAttribute('data-sidebar');
			document.body.removeAttribute('data-sidebar-size');
		} else {
			yield put(changeTopbarThemeAction('light'));
		}
		yield call(changeBodyAttribute, "data-layout", layout);

	} catch (error) { }
}

function* changeLayoutWidth({ payload: width }: any) {
	try {
		if (width === 'boxed') {
			yield put(changeSidebarTypeAction("icon"));
		}
		else {
			// yield put(changeSidebarTypeAction("default"));
		}
		yield call(changeBodyAttribute, "data-layout-size", width);
	} catch (error) { }
}

function* changeLeftSidebarTheme({ payload: theme }: any) {
	try {
		yield call(changeBodyAttribute, "data-sidebar", theme);
	} catch (error) { }
}

function* changeTopbarTheme({ payload: theme }: any) {
	try {
		yield call(changeBodyAttribute, "data-topbar", theme);
	} catch (error) { }
}

function* changeLeftSidebarType({ payload: { sidebarType, isMobile } }: any) {
	try {
		switch (sidebarType) {
			case "compact":
				yield call(changeBodyAttribute, "data-sidebar-size", "small");
				yield call(manageBodyClass, "sidebar-enable", "remove");
				yield call(manageBodyClass, "vertical-collpsed", "remove");
				break;
			case "icon":
				yield call(changeBodyAttribute, "data-keep-enlarged", "true");
				yield call(manageBodyClass, "vertical-collpsed", "add");
				break;
			case "condensed":
				yield call(manageBodyClass, "sidebar-enable", "add");
				if (!isMobile) yield call(manageBodyClass, "vertical-collpsed", "add");
				break;
			default:
				yield call(changeBodyAttribute, "data-sidebar-size", "");
				yield call(manageBodyClass, "sidebar-enable", "remove");
				if (!isMobile) yield call(manageBodyClass, "vertical-collpsed", "remove");
				break;
		}
	} catch (error) { }
}

function* toggleRightSidebar() {
	try {
		yield call(manageBodyClass, "right-bar-enabled");
	} catch (error) { }
}

function* showRightSidebar() {
	try {
		yield call(manageBodyClass, "right-bar-enabled", "add");
	} catch (error) { }
}

function* hideRightSidebar() {
	try {
		yield call(manageBodyClass, "right-bar-enabled", "remove");
	} catch (error) { }
}

function* createWidgetLayoutHelper({ payload }: any) {
	yield call(createWidgetLayout, payload, payload.accountId);
}

function* getDashboardHelper({ payload }: ReturnType<typeof getDashboardsAction>) {
  const response: ResponseGenerator = yield call(getDashboards, payload.accountId);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getDashboardsError(error));
    } else {
      yield put(getDashboardsError("An error has occurred!!"));
    }
  } else {
    yield put(getDashboardsSuccess(response.data));
  }
}

export function* watchChangeLayoutType() {
	yield takeEvery(CHANGE_LAYOUT, changeLayout);
}

export function* watchChangeLayoutWidth() {
	yield takeEvery(CHANGE_LAYOUT_WIDTH, changeLayoutWidth);
}

export function* watchChangeLeftSidebarTheme() {
	yield takeEvery(CHANGE_SIDEBAR_THEME, changeLeftSidebarTheme);
}

export function* watchChangeLeftSidebarType() {
	yield takeEvery(CHANGE_SIDEBAR_TYPE, changeLeftSidebarType);
}

export function* watchChangeTopbarTheme() {
	yield takeEvery(CHANGE_TOPBAR_THEME, changeTopbarTheme);
}

export function* watchToggleRightSidebar() {
	yield takeEvery(TOGGLE_RIGHT_SIDEBAR, toggleRightSidebar);
}

export function* watchShowRightSidebar() {
	yield takeEvery(SHOW_RIGHT_SIDEBAR, showRightSidebar);
}

export function* watchHideRightSidebar() {
	yield takeEvery(HIDE_RIGHT_SIDEBAR, hideRightSidebar);
}

export function* watchCreateWidgetLayout() {
	yield takeEvery(CREATE_WIDGET_LAYOUT, createWidgetLayoutHelper);
}

export function* watchGetDashboardHelper() {
	yield takeEvery(GET_DASHBOARDS, getDashboardHelper);
}

function* LayoutSaga() {
	yield all([
		fork(watchChangeLayoutType),
		fork(watchChangeLayoutWidth),
		fork(watchChangeLeftSidebarTheme),
		fork(watchChangeLeftSidebarType),
		fork(watchToggleRightSidebar),
		fork(watchShowRightSidebar),
		fork(watchHideRightSidebar),
		fork(watchChangeTopbarTheme),
		fork(watchCreateWidgetLayout),
		fork(watchGetDashboardHelper),
	]);
}

export default LayoutSaga;
