import axios, { AxiosResponse } from 'axios';
import { ResponseGenerator } from '../../userservice';
import { urls } from '../../../utilities/urls';
import { endpoints } from '../../../utilities/endpoints';
import { commonheaders } from '../../../utilities/apiConfig';
import {
  IAccountToken,
  ICreateAccountToken,
  IEnableCRMServiceIntegration,
} from '../../../store/settings/AccountTokens/interface';

export async function getAccountTokens(
  accountId: String
): Promise<AxiosResponse<IAccountToken[]>> {
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.accountTokens}`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function createAccountToken(
  accountId: String,
  token: ICreateAccountToken
) {
  try {
    return await axios.post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.accountTokens}`,
      {
        ...token,
      },
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function updateAccountToken(
  accountId: String,
  token: IAccountToken
) {
  try {
    return await axios.put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.accountTokens}/${token.id}`,
      {
        ...token,
      },
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function deleteAccountToken(
  accountId: String,
  accountTokenId: string
) {
  try {
    return await axios.delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.accountTokens}/${accountTokenId}`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function enableLynes(
  accountId: String,
) {
  try {
    return await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.accountTokens}/${endpoints.lynesEnable}`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function disableLynes(
  accountId: String,
) {
  try {
    return await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.accountTokens}/${endpoints.lynesDisable}`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function enableTelenor(
  accountId: String,
) {
  try {
    return await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.accountTokens}/${endpoints.telenorEnable}`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function disableTelenor(
  accountId: String,
) {
  try {
    return await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.accountTokens}/${endpoints.telenorDisable}`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function enableCRMService(payload: IEnableCRMServiceIntegration) {
  try {
    return await axios.post(
      `${urls.baseApiURL}${endpoints.integration}/${endpoints.crmService}/${endpoints.enable}`,
      {
        ...payload,
      },
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function disableCRMService() {
  try {
    return await axios.get(
      `${urls.baseApiURL}${endpoints.integration}/${endpoints.crmService}/${endpoints.disable}`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}
