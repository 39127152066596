import React, { Component } from 'react';

interface AIAgentPlaybookSummaryPreviewProps {
  playbookSummary?: string;
}
interface AIAgentPlaybookSummaryPreviewState {}

class AIAgentPlaybookSummaryPreview extends Component<AIAgentPlaybookSummaryPreviewProps, AIAgentPlaybookSummaryPreviewState>
{
  formatSummary(summary: string) {
    let formattedSummary;

    if (summary.includes('**')) {
      formattedSummary = summary.replace(/\*\*(.*?)\*\*/g, '<span style="font-weight: bold !important; font-size: 14px !important;">$1</span>');
    } else {
      formattedSummary = summary.replace(/([^:]*:)(.*)/gm, '<span style="font-weight: bold !important;">$1</span><span style="font-weight: normal !important;">$2</span>');
    }

    const formattedSummary2 = formattedSummary.replace(/\n+/gm, '<br /><br />');
    return { __html: formattedSummary2 };
  }

  render() {
    const { playbookSummary } = this.props;

    return (
      <div className="ai-agent-playbook-summary">
        <p className="ai-agent-playbook-summary__title">Playbook summary:</p>
        <div className="ai-agent-playbook-summary__text">
          {`${playbookSummary ? playbookSummary : 'No playbook summary for this meeting.'}`}
        </div>
      </div>
    )
  }
}

export default AIAgentPlaybookSummaryPreview;
