import {
  CREATE_S2_ADVERSUS_SETTINGS,
  CREATE_S2_ADVERSUS_SETTINGS_ERROR,
  CREATE_S2_ADVERSUS_SETTINGS_SUCCESS,
  DELETE_S2_ADVERSUS_SETTINGS,
  DELETE_S2_ADVERSUS_SETTINGS_ERROR,
  DELETE_S2_ADVERSUS_SETTINGS_SUCCESS,
  UPDATE_S2_ADVERSUS_SETTINGS,
  UPDATE_S2_ADVERSUS_SETTINGS_ERROR,
  UPDATE_S2_ADVERSUS_SETTINGS_SUCCESS
} from "./actionTypes";

import { CallImportAction, CallImportState} from "./interface";

const initialState: CallImportState = {
  tokens: [],
  isTokensLoading: false,
  tokenError: '',

  tokenSettings: [],
  isTokenSettingsLoading: false,
  tokenSettingsError: '',

  createdTokenSettings: null,
  createTokenSettingError: '',
  isCreatingTokenLoader: false,

  deleteS2AdversusTokenSettingsError: '',
  isDeletingTokenLoader: false,

  updateTokenSettingsError: '',
  isUpdatingTokenLoader: false,
};

const S2Adversus = (state = initialState, action: CallImportAction) => {
  switch (action.type) {
    case CREATE_S2_ADVERSUS_SETTINGS:
      state = {
        ...state,
        isCreatingTokenLoader: true,
        createdTokenSettings: null,
      }
      break;
    case CREATE_S2_ADVERSUS_SETTINGS_SUCCESS:
      state = {
        ...state,
        createdTokenSettings: action.payload,
        isCreatingTokenLoader: false,
      }
      break;
    case CREATE_S2_ADVERSUS_SETTINGS_ERROR:
      state = {
        ...state,
        createTokenSettingError: action.payload,
        isCreatingTokenLoader: false,
      }
      break;
    case DELETE_S2_ADVERSUS_SETTINGS:
      state = {
        ...state,
        isDeletingTokenLoader: true,
      }
      break;
    case DELETE_S2_ADVERSUS_SETTINGS_SUCCESS:
      state = {
        ...state,
        isDeletingTokenLoader: false,
      }
      break;
    case DELETE_S2_ADVERSUS_SETTINGS_ERROR:
      state = {
        ...state,
        deleteS2AdversusTokenSettingsError: action.payload,
        isDeletingTokenLoader: false,
      }
      break;
    case UPDATE_S2_ADVERSUS_SETTINGS:
      state = {
        ...state,
        isUpdatingTokenLoader: true,
      }
      break;
    case UPDATE_S2_ADVERSUS_SETTINGS_SUCCESS:
      state = {
        ...state,
        isUpdatingTokenLoader: false,
      }
      break;
    case UPDATE_S2_ADVERSUS_SETTINGS_ERROR:
      state = {
        ...state,
        updateTokenSettingsError: action.payload,
        isUpdatingTokenLoader: false,
      }
      break;
    default:
      state = { ...state }
      break;
  }

  return state;
}

export default S2Adversus;
