import React, { Component } from "react";
import { Table } from "reactstrap";
import * as _ from "lodash";
import LoadingTable from "../Common/LoadingTable";

interface tableLayoutProps {
  tableHeaders: String[];
  loading: Boolean;
  isHideIndex?: boolean;
  rowsSelectable?: boolean;
}

class TableLayout extends Component<tableLayoutProps> {
  render() {
    const { tableHeaders, loading, isHideIndex, rowsSelectable } = this.props;
    return loading ? (
      <LoadingTable
        rows={5}
        columns={new Array(tableHeaders.length + 1).fill(0)}
        headers={tableHeaders}
      />
    ) : (
      <Table className="table-centered table-nowrap table-hover bg-white">
        <thead className="thead-light">
          <tr>
          {!isHideIndex && <th scope="col" style={{ width: "70px" }}>
              #
            </th>}
          {rowsSelectable && <th scope="col"></th>}
            {!_.isEmpty(tableHeaders) &&
              tableHeaders.map((headline, index) => {
                return (
                  <th scope="col" key={index}>
                    {headline}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody>{this.props.children}</tbody>
      </Table>
    );
  }
}

export default TableLayout;
