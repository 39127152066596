import {
    REGISTER_MULTIPLE_USERS,
    REGISTER_MULTIPLE_USERS_FAILED,
    REGISTER_MULTIPLE_USERS_SUCCESSFUL,
} from './actionTypes';

export const registerMultipleUsers = (users: Object[], account_id: string, onSuccess: any) => {
    return {
        type: REGISTER_MULTIPLE_USERS,
        payload: {
            account_id: account_id,
            users: users,
            onSuccess: onSuccess,
        }
    }
}

export const registerMultipleUsersSuccessful = (msg: String) => {
    return {
        type: REGISTER_MULTIPLE_USERS_SUCCESSFUL,
        payload: msg
    }
}

export const registerMultipleUsersFailed = (data: Object | Object) => {
    return {
        type: REGISTER_MULTIPLE_USERS_FAILED,
        payload: data
    }
}