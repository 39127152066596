import {
  CREATE_RECEIVER,
  CREATE_RECEIVER_SUCCESS,
  CREATE_RECEIVER_ERROR,
  GET_RECEIVER,
  GET_RECEIVER_SUCCESS,
  GET_RECEIVER_ERROR,
} from "./actionTypes";
import {
  SendReceiverData,
  IReceiver,
} from "./interface";

export const createReceiver = (object: SendReceiverData) => {
  return {
    type: CREATE_RECEIVER,
    payload: { object },
  };
};

export const createReceiverSuccess = (mes: String) => {
  return {
    type: CREATE_RECEIVER_SUCCESS,
    payload: mes,
  };
};

export const createReceiverError = (mes: any) => {
  return {
    type: CREATE_RECEIVER_ERROR,
    payload: mes,
  };
};

export const getReceiver = (accountId: String, sessionId: String) => {
  return {
    type: GET_RECEIVER,
    payload: { accountId, sessionId },
  };
};

export const getReceiverSuccess = (receiver: IReceiver) => {
  return {
    type: GET_RECEIVER_SUCCESS,
    payload: receiver,
  };
};

export const getReceiverError = (mes: String | Object) => {
  return {
    type: GET_RECEIVER_ERROR,
    payload: mes,
  };
};
