import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  EDIT_PERSONAL_PROFILE,
  EDIT_PERSONAL_PROFILE_PIC,
  EDIT_USER_PROFILE,
  CREATE_USER_PROFILE,
  ADD_USER_TO_TEAM,
  DELETE_USER_PROFILE,
  CHANGE_PROFILE_PASSWORD,
} from "./actionTypes";
import {
  editPersonalProfileSuccess,
  editPersonalProfileError,
  addUserToTeamSuccess,
  addUserToTeamError,
  uploadedDocument,
} from "./actions";

import {
  editUserProfile,
  setProfilePic,
  createUserProfile,
  setAddUserToTeam,
  deleteUserProfile,
  ResponseGenerator,
  changeProfilePassword,
} from "../../../services/userservice";

import {
  editPersonalProfile as editProfile,
  profileSuccess,
  getUserList,
  createPersonalProfileSuccess,
  personalProfileError,
  deleteUserProfileSuccess,
  deleteUserProfileError,
  changeProfilePasswordSuccess,
  changeProfilePasswordError,
} from "../../actions";
import _ from "lodash";

function* editPersonalProfile({ payload: { user, id } }: any) {
  const response: ResponseGenerator = yield call(editUserProfile, user, id);
  if (_.has(response, "data")) {
    yield put(profileSuccess(response.data));
    yield put(
      editPersonalProfileSuccess("Profile has been updated successfully")
    );
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(editPersonalProfileError(error));
    } else {
      yield put(editPersonalProfileError("An Error has occurred"));
    }
  }
}

function* changeProfilePasswordSaga({ payload: { passwordData } }: any) {
  const response: ResponseGenerator = yield call(
    changeProfilePassword,
    passwordData
  );
  if (_.has(response, "data")) {
    yield put(
      changeProfilePasswordSuccess(
        "Profile password has been updated successfully"
      )
    );
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(changeProfilePasswordError(error));
    } else {
      yield put(changeProfilePasswordError("An Error has occurred"));
    }
  }
}

function* createPersonalProfile({ payload: { user, id } }: any) {
  const response: ResponseGenerator = yield call(createUserProfile, user, id);
  if (_.has(response, "data")) {
    yield put(createPersonalProfileSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(personalProfileError(error));
    } else {
      yield put(personalProfileError("An Error has occurred"));
    }
  }
}

function* editUserSaga({ payload: { user, id, accountID } }: any) {
  const response: ResponseGenerator = yield call(editUserProfile, user, id);
  if (_.has(response, "data")) {
    yield put(getUserList(accountID));
    yield put(
      editPersonalProfileSuccess("Profile has been updated successfully")
    );
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(personalProfileError(error));
    } else {
      yield put(personalProfileError("An Error has occurred"));
    }
  }
}

function* editPersonalProfilePicSaga({
  payload: { pic, id, onlyDocument },
}: any) {
  const response: ResponseGenerator = yield call(setProfilePic, pic);
  if (_.has(response, "data")) {
    if (onlyDocument) {
      yield put(uploadedDocument(response.data));
    } else {
      yield put(editProfile({ profile_picture_url: response.data.path }, id));
      yield put(
        editPersonalProfileSuccess("Profile Pic has been updated successfully")
      );
    }
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(editPersonalProfileError(error));
    } else {
      yield put(editPersonalProfileError("An Error has occurred"));
    }
  }
}

function* addUserToTeamSaga({ payload: { teamId, userIds } }: any) {
  const response: ResponseGenerator = yield call(
    setAddUserToTeam,
    teamId,
    userIds
  );
  if (_.has(response, "data")) {
    yield put(addUserToTeamSuccess("Added user to team successfully"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(addUserToTeamError(error));
    } else {
      yield put(addUserToTeamError("An Error has occurred"));
    }
  }
}

function* deleteUserProfileSaga({ payload: { accountID, params } }: any) {
  const response: ResponseGenerator = yield call(
    deleteUserProfile,
    accountID,
    params
  );
  if (_.has(response, "data")) {
    yield put(deleteUserProfileSuccess("User deleted successfully"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteUserProfileError(error));
    } else {
      yield put(deleteUserProfileError("An Error has occurred"));
    }
  }
}

export function* watchPersonalProfile() {
  yield takeEvery(EDIT_PERSONAL_PROFILE, editPersonalProfile);
}

export function* watchCreatePersonalProfile() {
  yield takeEvery(CREATE_USER_PROFILE, createPersonalProfile);
}

export function* watchUploadProfilePic() {
  yield takeEvery(EDIT_PERSONAL_PROFILE_PIC, editPersonalProfilePicSaga);
}

export function* watchUserProfile() {
  yield takeEvery(EDIT_USER_PROFILE, editUserSaga);
}

export function* watchAddUserToTeam() {
  yield takeEvery(ADD_USER_TO_TEAM, addUserToTeamSaga);
}

export function* watchDeleteUserProfile() {
  yield takeEvery(DELETE_USER_PROFILE, deleteUserProfileSaga);
}

export function* watchChangeProfilePassword() {
  yield takeEvery(CHANGE_PROFILE_PASSWORD, changeProfilePasswordSaga);
}

function* PersonalProfileSaga() {
  yield all([
    fork(watchPersonalProfile),
    fork(watchUploadProfilePic),
    fork(watchAddUserToTeam),
    fork(watchCreatePersonalProfile),
    fork(watchUserProfile),
    fork(watchDeleteUserProfile),
    fork(watchChangeProfilePassword),
  ]);
}

export default PersonalProfileSaga;
