import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  DUPLICATE_PRESENTATION,
  EDIT_PRESENTATION,
  SET_IMPORT_PPTX,
  GET_PRESENTATIONS,
  REMOVE_PRESENTATION,
  SET_PRESENTATION,
  GET_CATEGORIES,
  GET_GOOGLE_DRIVE_FILES_LIST,
  GET_GOOGLE_DOWNLOAD,
  SET_GOOGLE_DRIVE_LOGOUT,
  DELETE_MULTIPLE_DOCS,
} from "./actionTypes";
import {
  presentationApiError,
  getPresentationsSuccess,
  importPresentationError,
  getCategoriesSuccess,
  getCategoriesError,
  getPresentations as getPresentationsAction,
  appendDuplicatePresentation,
  removePresentationSuccess,
  getImportPresentationsPPtx,
  setPresentationSuccess,
  driveApiError,
  getGoogleDownloadSuccess,
  getGoogleDownloadError,
  setDriveLogOutError,
  deleteMultipleDocsSuccess,
} from "./actions";

//services
import {
  addEmptySlide,
  duplicatePresentation,
  editPresentation,
  getPresentations,
  setPresentation,
  removePresentation,
  setImportPresentation,
  getCategories,
  ResponseGenerator,
  deleteMultipleDocs,
} from "../../services/userservice";
import {
  getGoogleDriveFilesService,
  getGoogleDriveDownloadService,
  getGoogleDriveLogOutService,
} from "../../services/auth/authservice";
import _ from "lodash";
import { getFolders } from "../Folders/actions";

function* getPresentationsHelper({ payload }: any) {
  const { id, filter } = payload;
  const response: ResponseGenerator = yield call(getPresentations, id, filter);
  if (_.has(response, "data")) {
    yield put(getPresentationsSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(presentationApiError(error));
    } else {
      yield put(presentationApiError("An Error has occured"));
    }
  }
}

function* setPresentationsHelper({ payload: { presentation, id } }: any) {
  const response: ResponseGenerator = yield call(setPresentation, presentation);
  console.log("setpresentation = ");
  if (_.has(response, "data")) {
    yield put(setPresentationSuccess("Presentation is created successfully!", response));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(presentationApiError(error));
    } else {
      yield put(presentationApiError("An Error has occured"));
    }
  }
}

function* getCategoriesHelper({ payload: { id } }: any) {
  const response: ResponseGenerator = yield call(getCategories, id);
  if (_.has(response, "data")) {
    yield put(getCategoriesSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getCategoriesError(error));
    } else {
      yield put(getCategoriesError("An Error has occured"));
    }
  }
}

function* setImportPptxHelper({ payload: { data, id } }: any) {
  const response: ResponseGenerator = yield call(
    setImportPresentation,
    data,
    id
  );
  if (_.has(response, "data")) {
    yield put(getImportPresentationsPPtx(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(importPresentationError(error));
    } else {
      yield put(importPresentationError("An Error has occured"));
    }
  }
}

function* duplicatePresentationsHelper({
  payload: { data, presentation, id, isTemplate },
}: any) {
  const response: ResponseGenerator = yield call(
    duplicatePresentation,
    data,
    presentation
  );
  if (_.has(response, "data")) {
    yield put(
      setPresentationSuccess("Presentation is duplicated successfully!", response)
    );
    yield put(appendDuplicatePresentation({ data: response.data, isTemplate }));
    if (isTemplate) {
      const values = {
        template: true,
      };
      const editresponse: ResponseGenerator = yield call(
        editPresentation,
        values,
        response.data.id
      );
      if (_.has(editresponse, "data")) {
        yield put(
          setPresentationSuccess("Presentation is updated successfully!", editresponse)
        );
      }
    }
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(presentationApiError(error));
    } else {
      yield put(presentationApiError("An Error has occured"));
    }
  }
}

function* editPresentationsHelper({
  payload: { presentation, uid, pid },
}: any) {
  const response: ResponseGenerator = yield call(
    editPresentation,
    presentation,
    pid
  );
  if (_.has(response, "data")) {
    yield put(setPresentationSuccess("Presentation is updated successfully!", response));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(presentationApiError(error));
    } else {
      yield put(presentationApiError("An Error has occured"));
    }
  }
}

function* removePresentationHelper({ payload: { presentation, id } }: any) {
  const response: ResponseGenerator = yield call(
    removePresentation,
    presentation
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(presentationApiError(error));
    } else {
      yield put(presentationApiError("An Error has occured!!"));
    }
  } else {
    // yield put(setPresentationSuccess("Presentation is deleted successfully!"));
    yield put(
      removePresentationSuccess(
        "Presentation is deleted successfully!",
        presentation
      )
    );
  }
}

function* getGoogleDriveFilesList({ payload: { folderId, callback, onErrorCallback } }: any) {
  const response: ResponseGenerator = yield call(
    getGoogleDriveFilesService,
    folderId
  );
  if (_.has(response, "data")) {
    callback(response.data);
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(driveApiError(error));
    } else {
      yield put(driveApiError("An Error has occured"));
    }
    onErrorCallback();
  }
}

function* getGoogleDownloadFile({ payload: { data, type, onSuccess } }: any) {
  const response: ResponseGenerator = yield call(
    getGoogleDriveDownloadService,
    data,
    type
  );
  if (_.has(response, "data")) {
    yield put(getGoogleDownloadSuccess("File uploaded successfully"));

    onSuccess();
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getGoogleDownloadError(error));
    } else {
      yield put(getGoogleDownloadError("An Error has occured"));
    }
  }
}

function* setGoogleDriveLogOut({ payload: { callback } }: any) {
  const response: ResponseGenerator = yield call(getGoogleDriveLogOutService);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(setDriveLogOutError(error));
    } else {
      yield put(setDriveLogOutError("An Error has occured!!"));
    }
  } else {
    callback(response);
  }
}

function* deleteMultipleDocsHelper({ payload: { presentationIds, filesIds } }: any): any {
  const response: ResponseGenerator = yield call(
    deleteMultipleDocs,
    presentationIds, filesIds
  );
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(presentationApiError(error));
    } else {
      yield put(presentationApiError("An Error has occured!!"));
    }
  } else {
    yield put(
      deleteMultipleDocsSuccess(
        presentationIds, filesIds
      )
    );
  }
}

export function* watchGetCategoriesHelper() {
  yield takeEvery(GET_CATEGORIES, getCategoriesHelper);
}

export function* watchGetPresentations() {
  yield takeEvery(GET_PRESENTATIONS, getPresentationsHelper);
}

export function* watchEditPresentations() {
  yield takeEvery(EDIT_PRESENTATION, editPresentationsHelper);
}

export function* watchSetPresentations() {
  yield takeEvery(SET_PRESENTATION, setPresentationsHelper);
}

export function* watchSetImportPptx() {
  yield takeEvery(SET_IMPORT_PPTX, setImportPptxHelper);
}

export function* watchDuplicatePresentations() {
  yield takeEvery(DUPLICATE_PRESENTATION, duplicatePresentationsHelper);
}

export function* watchRemovePresentations() {
  yield takeEvery(REMOVE_PRESENTATION, removePresentationHelper);
}

export function* watchgetGoogleDriveFileList() {
  yield takeEvery(GET_GOOGLE_DRIVE_FILES_LIST, getGoogleDriveFilesList);
}

export function* watchsetGoogleDriveLogOut() {
  yield takeEvery(SET_GOOGLE_DRIVE_LOGOUT, setGoogleDriveLogOut);
}

export function* watchgetGoogleDownloadFile() {
  yield takeEvery(GET_GOOGLE_DOWNLOAD, getGoogleDownloadFile);
}

export function* watchDeleteMultipleDocs() {
  yield takeEvery(DELETE_MULTIPLE_DOCS, deleteMultipleDocsHelper);
}
function* getPresentationsSaga() {
  yield all([
    fork(watchSetPresentations),
    fork(watchGetCategoriesHelper),
    fork(watchSetImportPptx),
    fork(watchGetPresentations),
    fork(watchDuplicatePresentations),
    fork(watchEditPresentations),
    fork(watchRemovePresentations),
    fork(watchgetGoogleDriveFileList),
    fork(watchgetGoogleDownloadFile),
    fork(watchsetGoogleDriveLogOut),
    fork(watchDeleteMultipleDocs)
  ]);
}

export default getPresentationsSaga;
