import React from "react";
import {
  IChatInformation,
  IChatInformationFromBackEnd,
} from "../../../../store/interface";

export const mapToCamelCase = (
  data: IChatInformationFromBackEnd[]
): IChatInformation[] => {
  return data.map((item) => ({
    chatName: item.chat_name,
    createdAt: item.created_at,
    chatId: item.id,
    status: item.status,
    lastMessage: item.last_message,
    messages: [
      ...item.messages,
      { role: "assistant", content: item.last_message + " ..." },
    ],
  }));
};

export const mapChatToCamelCase = (
  data: IChatInformationFromBackEnd
): IChatInformation => {
  return {
    chatName: data.chat_name,
    createdAt: data.created_at,
    chatId: data.id,
    status: data.status,
    lastMessage: data.last_message,
    messages:
      data.last_message === null
        ? data.messages
        : [
            ...data.messages,
            { role: "assistant", content: data.last_message + " ..." },
          ],
  };
};

const toCamelCase = (str: string) =>
  str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

export function mapKeysToCamelCase<T>(
  obj: Record<string, any>
): T {
  return Object.entries(obj).reduce((accumulator: Record<string, any>, [key, value]) => {
    if (value && typeof value === 'object' && !(value instanceof Array)) {
      accumulator[toCamelCase(key)] = mapKeysToCamelCase(value);
    } else {
      accumulator[toCamelCase(key)] = value;
    }
    return accumulator;
  }, {}) as T;
}

export const formatMessage = (message: string) => {
  if (message) {
    return message
      .split("\n")
      .map((line, index, array) =>
        index === array.length - 1 ? line : [line, <br key={index} />]
      );
  } else {
    return [];
  }
};
