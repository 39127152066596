export const GET_AI_AGENTS = 'GET_AI_AGENTS';
export const GET_AI_AGENTS_SUCCESS = 'GET_AI_AGENTS_SUCCESS';
export const GET_AI_AGENTS_ERROR = 'GET_AI_AGENTS_ERROR';

export const CREATE_AI_AGENT = 'CREATE_AI_AGENT';
export const CREATE_AI_AGENT_SUCCESS = 'CREATE_AI_AGENT_SUCCESS';
export const CREATE_AI_AGENT_ERROR = 'CREATE_AI_AGENT_ERROR';

export const DELETE_AI_AGENT = 'DELETE_AI_AGENT';
export const DELETE_AI_AGENT_SUCCESS = 'DELETE_AI_AGENT_SUCCESS';
export const DELETE_AI_AGENT_ERROR = 'DELETE_AI_AGENT_ERROR';

export const UPDATE_AI_AGENT = 'UPDATE_AI_AGENT';
export const UPDATE_AI_AGENT_ERROR = 'UPDATE_AI_AGENT_ERROR';
export const UPDATE_AI_AGENT_SUCCESS = 'UPDATE_AI_AGENT_SUCCESS';

export const GET_AI_AGENT = 'GET_AI_AGENT';
export const GET_AI_AGENT_SUCCESS = 'GET_AI_AGENT_SUCCESS';
export const GET_AI_AGENT_ERROR = 'GET_AI_AGENT_ERROR';

export const GET_AI_AGENT_REQUESTS = 'GET_AI_AGENT_REQUESTS';
export const GET_AI_AGENT_REQUESTS_SUCCESS = 'GET_AI_AGENT_REQUESTS_SUCCESS';
export const GET_AI_AGENT_REQUESTS_ERROR = 'GET_AI_AGENT_REQUESTS_ERROR';

export const SET_CURRENT_AI_AGENT = 'SET_CURRENT_AI_AGENT';

export const SEND_AI_AGENT_REQUEST = 'SEND_AI_AGENT_REQUEST';
export const SEND_AI_AGENT_REQUEST_SUCCESS = 'SEND_AI_AGENT_REQUEST_SUCCESS';
export const SEND_AI_AGENT_REQUEST_ERROR = 'SEND_AI_AGENT_REQUEST_ERROR';

export const TRY_AI_AGENT_REQUEST = 'TRY_AI_AGENT_REQUEST';
export const TRY_AI_AGENT_REQUEST_SUCCESS = 'TRY_AI_AGENT_REQUEST_SUCCESS';
export const TRY_AI_AGENT_REQUEST_ERROR = 'TRY_AI_AGENT_REQUEST_ERROR';

export const GET_AI_AGENT_REQUEST = 'GET_AI_AGENT_REQUEST';
export const GET_AI_AGENT_REQUEST_SUCCESS = 'GET_AI_AGENT_REQUEST_SUCCESS';
export const GET_AI_AGENT_REQUEST_ERROR = 'GET_AI_AGENT_REQUEST_ERROR';

export const GET_AI_AGENT_TYPES = 'GET_AI_AGENT_TYPES';
export const GET_AI_AGENT_TYPES_SUCCESS = 'GET_AI_AGENT_TYPES_SUCCESS';
export const GET_AI_AGENT_TYPES_ERROR = 'GET_AI_AGENT_TYPES_ERROR';

export const GET_PLAYBOOK_FOR_SESSION = 'GET_PLAYBOOK_FOR_SESSION';
export const GET_PLAYBOOK_FOR_SESSION_SUCCESS = 'GET_PLAYBOOK_FOR_SESSION_SUCCESS';
export const GET_PLAYBOOK_FOR_SESSION_ERROR = 'GET_PLAYBOOK_FOR_SESSION_ERROR';

export const SET_TRANSCRIPT = 'SET_TRANSCRIPT';
export const EMPTY_TRANSCRIPT = 'EMPTY_TRANSCRIPT';

export const EMPTY_AGENT = 'EMPTY_AGENT';
