import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { History } from "history";

import "./top-navbar.scss";
import NavbarContent from "./NavbarContent";
import pitchflowLogo from "../../assets/images/meetric_logo_black.svg";
import pitchflowLogosmall from "../../assets/images/meetric_logo_black_small.svg";
import {
  toggleRightSidebar,
  getUserProfile,
  changeCurrentAccount,
  changeMultipleAccountState,
  settingsMenu,
  recordingsMenu,
} from "../../store/actions";
import { Icurrentaccountselected, IUser } from "../../store/interface";
import { getPartnerFromLocal } from '../../services/partner/partnerService';

interface NavbarProps {
  menuOpen: boolean;
  history: History;
  location: any;
  currentuser: IUser;
  currentaccountselected: Icurrentaccountselected;
  hasMultipleAccounts: boolean;
  isOpenSettingMenu: boolean;
  isOpenRecordingsMenu: boolean;
  type: String;
  fromIframe: boolean;
  accountdetails: any;
  showContent: boolean;
  toggleRightSidebar: () => void;
  getUserProfile: () => void;
  changeCurrentAccount: (data: Object) => void;
  changeMultipleAccountState: (hasMultipleAccounts: Boolean) => void;
  settingsMenu: (values?: any) => void;
  recordingsMenu: (values?: any) => void;
}

interface NavbarState {
  isSearch: boolean;
  user: IUser | null;
  currentaccountselected: Icurrentaccountselected | null;
  account_modal: boolean;
  searchValue: String;
  accountToggle: boolean;
}

class Navbar extends Component<NavbarProps, NavbarState> {
  constructor(props: NavbarProps) {
    super(props);
    this.state = {
      isSearch: false,
      user: null,
      currentaccountselected: null,
      account_modal: false,
      searchValue: "",
      accountToggle: false,
    };
  }

  componentDidMount() {
    this.props.getUserProfile();
  }

  componentDidUpdate(prevProps: NavbarProps) {
    const { hasMultipleAccounts, currentaccountselected, currentuser } = this.props;

    if (prevProps !== this.props) {
      if (this.props.currentuser) {
        let modal = false;
        if (hasMultipleAccounts) {
          modal = true;
        }

        this.setState({
          user: currentuser,
          currentaccountselected: currentaccountselected,
          account_modal: modal,
        });
      }
    }
  }

  render() {
    const { isOpenSettingMenu, isOpenRecordingsMenu } = this.props;

    const partner = getPartnerFromLocal();

    return (
      <React.Fragment>
        <div className="topnav custome-horizontal-menu">
          <div className="d-flex justify-content-between align-items-center h-100">
            <ul className="navbar-nav d-flex">
              <a className="navbar-brand" href="/dashboard">
                {partner && partner.logoMain ? (
                  <span className="logo-lg">
                    <img
                      style={{ height: "30px" }}
                      src={partner.logoMain}
                      alt="big logo"
                    ></img>
                  </span>
                ) : (
                  <span className="logo-lg">
                    <img
                      style={{ width: "120px", height: "auto" }}
                      src={pitchflowLogo}
                      alt="big logo"
                    ></img>
                  </span>
                )}
                <span className="logo-sm">
                  <img src={partner && partner.logoMain ? partner.logoMain : pitchflowLogosmall} alt=""></img>
                </span>
              </a>
            </ul>
            {!this.props.showContent && (
              <>
                <ul></ul>
                <ul></ul>
                <ul></ul>
              </>
            )}
            {this.props.showContent && (
              <NavbarContent
                menuOpen={this.props.menuOpen}
                users={this.state.user}
                type={this.props.type}
                currentAccount={this.state.currentaccountselected}
                currentuser={this.props.currentuser}
                isOpenSettingMenu={isOpenSettingMenu}
                isOpenRecordingsMenu={isOpenRecordingsMenu}
                settingsMenu={this.props.settingsMenu}
                recordingsMenu={this.props.recordingsMenu}
                location={this.props.location}
                history={this.props.history}
                accountdetails={this.props.accountdetails}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { isOpenSettingMenu, isOpenRecordingsMenu } = state.Layout;
  const { currentuser, currentaccountselected, hasMultipleAccounts } =
    state.Profile;
  const { accountdetails } = state.AccountDetails;

  return {
    currentuser,
    currentaccountselected,
    hasMultipleAccounts,
    isOpenSettingMenu,
    isOpenRecordingsMenu,
    accountdetails,
  };
};

export default withRouter<any, any>(
  connect(mapStateToProps, {
    toggleRightSidebar,
    getUserProfile,
    changeCurrentAccount,
    changeMultipleAccountState,
    settingsMenu,
    recordingsMenu,
  })(Navbar)
);
