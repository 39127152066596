import React from "react";
import { produce } from "immer";
import { v4 as uuidv4 } from "uuid";
import { Input } from "reactstrap";
import { Button } from "reactstrap";
import { validateEmail } from "../../services/utilities/utilservice";

interface EditParticipantsListProps {
  participants: any[];
  onUpdate: (participants: any[]) => void;
  disabled?: boolean;
  disabledParticipantIds: any[];
}

export function createParticipant() {
  return {
    tempId: uuidv4(),
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };
}

export class EditParticipantsList extends React.Component<EditParticipantsListProps> {

  updateParticipantField = (index: number, field: string, value: any) => {
    const updatedParticipants = produce(this.props.participants, draftParticipants => {
      draftParticipants[index][field] = value;

      if (field === 'email') {
        const { firstName, lastName } = this.deriveNamesFromEmail(value);
        draftParticipants[index].firstName = firstName;
        draftParticipants[index].lastName = lastName;
      }
    });
    this.props.onUpdate(updatedParticipants);
  };


  addParticipant = () => {
    const updatedParticipants = [
      ...this.props.participants,
      createParticipant(),
    ];
    this.props.onUpdate(updatedParticipants);
  };

  removeParticipant = (index: number) => {
    const updatedParticipants = produce(this.props.participants, draftParticipants => {
      draftParticipants.splice(index, 1);
    });
    this.props.onUpdate(updatedParticipants);
  };

  deriveNamesFromEmail = (email: string) => {
    const [localPart] = email.split('@');
    const [firstName, lastName] = localPart.split('.');

    return {
      firstName: firstName ? firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase() : '',
      lastName: lastName ? lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase() : ''
    };
  }

  render() {
    return <>
      {this.props.participants.map((participant, index) => (
        <div className="participant-data" key={participant.id || participant.tempId}>
          <Input
            type="email"
            className="email"
            placeholder="Email"
            disabled={this.props.disabled || participant.id}
            value={participant.email}
            onChange={(e: any) => this.updateParticipantField(index, 'email', e.target.value)}
            invalid={!!participant.email && !validateEmail(participant.email)}
          />
          <Input
            type="text"
            className="first-name"
            placeholder="First name"
            disabled={this.props.disabled || this.props.disabledParticipantIds.includes(participant.id)}
            value={participant.firstName}
            onChange={(e: any) => this.updateParticipantField(index, 'firstName', e.target.value)}
          />
          <Input
            type="text"
            className="last-name"
            placeholder="Last name"
            disabled={this.props.disabled || this.props.disabledParticipantIds.includes(participant.id)}
            value={participant.lastName}
            onChange={(e: any) => this.updateParticipantField(index, 'lastName', e.target.value)}
          />
          <Input
            type="tel"
            className="phone"
            placeholder="Phone number"
            disabled={this.props.disabled || this.props.disabledParticipantIds.includes(participant.id)}
            value={participant.phone}
            onChange={(e: any) => this.updateParticipantField(index, 'phone', e.target.value)}
          />
          <Button disabled={this.props.disabled} onClick={() => this.removeParticipant(index)}>
            <i className="fa fa-trash" />
          </Button>
        </div>
      ))}
      <button className="add-participant" disabled={this.props.disabled} onClick={this.addParticipant}>
        <i className="fa fa-plus" />
        {' '}
        Add participant
      </button>
    </>
  }
}
