import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Card,
  CardBody,
  Col,
} from 'reactstrap';
import { History, Location } from 'history';
import * as _ from 'lodash';

import { optionNone } from '../CompareView';
import MultipleSelect from '../../components/MultipleSelect';
import { DropdownOption } from '../Meetings/interface';
import { isUserRightLevel } from '../../services/utilities/utilservice';
import { getMeetingTypes } from '../../store/settings/MeetingType/actions';
import { ITeams } from '../../store/settings/Teams/interface';
import { IUser } from '../../store/auth/userlist/interface';
import { Icurrentaccountselected } from '../../store/auth/profile/interface';
import { IMeetingType, meetingTypeOptions } from '../../store/settings/MeetingType/interface';
import {
  searchRecordingTranscripts,
  searchRecordingTranscriptsEmpty,
} from '../../store/Analytics/actions';
import {
  ITranscriptSentence,
} from "../../store/Analytics/interface";
import InfinitySearch from "../../components/Search/InfinitySearch";
import loaderImg from "../../assets/images/loader.gif";
import upRightImg from "../../assets/images/up-right-from-square-solid.svg";
import SwitchComponent from "../../components/SwitchButton/SwitchComponent";

interface ConversationInsightsProps {
  currentaccountselected: Icurrentaccountselected;
  teams: ITeams[];
  users: IUser[];
  meetingTypes: IMeetingType[];
  transcriptSentences: ITranscriptSentence[];
  totalSentences: Number;
  loading: boolean,
  getMeetingTypes: (id: String, options?: meetingTypeOptions) => void;
  searchRecordingTranscripts: (accountId: String, search: String, params?: String) => void;
  searchRecordingTranscriptsEmpty: () => void;
  history: History;
  location: Location;
}

interface ConversationInsightsState {
  userOptions: DropdownOption[];
  teamOptions: DropdownOption[];
  meetingTypeOptions: DropdownOption[];
  teamSelectedOptions: DropdownOption[] | null;
  userSelectedOptions: DropdownOption[] | null;
  meetingTypeSelectedOptions: null | DropdownOption[];
  isRangeDateSelected: boolean;
  currentTimeSelected: number;
  interval: String;
  startDate: null | string;
  endDate: null | string;
  searchTerm: string;
  limit: number;
  useTranslation: boolean;
  matchWholeWord: boolean;
}

const DEFAULT_DATE: string = "0";
const DEFAULT_LIMIT: number = 25;

class ConversationInsights extends Component<
  ConversationInsightsProps,
  ConversationInsightsState
> {
  constructor(props: ConversationInsightsProps) {
    super(props);
    this.state = {
      userOptions: [],
      teamOptions: [],
      meetingTypeOptions: [],
      teamSelectedOptions: null,
      userSelectedOptions: null,
      meetingTypeSelectedOptions: null,
      isRangeDateSelected: false,
      currentTimeSelected: 0,
      interval: 'weekly',
      startDate: null,
      endDate: null,
      searchTerm: '',
      limit: DEFAULT_LIMIT,
      useTranslation: false,
      matchWholeWord: false,
    }
  }

  componentDidMount() {
    const { currentaccountselected, getMeetingTypes: getMeetingTypesAction } = this.props;

    if (!_.isEmpty(currentaccountselected)) {
      getMeetingTypesAction(currentaccountselected.id);
    }
  }

  componentDidUpdate(prevProps: ConversationInsightsProps, prevStateL: ConversationInsightsState) {
    const {
      teams,
      users,
      meetingTypes,
      currentaccountselected,
      searchRecordingTranscriptsEmpty: searchRecordingTranscriptsEmptyAction
    } = this.props;
    const {
      userOptions,
      teamOptions,
      meetingTypeOptions,
      searchTerm,
      useTranslation,
      matchWholeWord,
    } = this.state;

    if (
        (prevStateL.useTranslation !== useTranslation && this.isTermLongEnough(searchTerm))
        || (prevStateL.matchWholeWord !== matchWholeWord && this.isTermLongEnough(searchTerm))
    ) {
      searchRecordingTranscriptsEmptyAction();
      this.setState({
        limit: DEFAULT_LIMIT,
      }, () => this.handleSearch(true))
    }

    if (_.isEmpty(teamOptions) || teams !== prevProps.teams) {
      const teamsData: DropdownOption[] = [optionNone].concat(
        (teams || []).map((el: any) => {
          return {
            label: el.name,
            value: el.id,
            id: el.id,
          };
        })
      );
      this.setState({ teamOptions: teamsData });
    }

    if (users && (_.isEmpty(userOptions) || users !== prevProps.users)) {
      const usersData: DropdownOption[] =
        (users || []).map((el: any) => {
          return {
            label: el.first_name + ' ' + el.last_name,
            value: el.id,
            id: el.id,
          };
        });

      this.setState({ userOptions: usersData });
    }

    if (meetingTypeOptions && (_.isEmpty(meetingTypeOptions) || meetingTypes !== prevProps.meetingTypes)) {
      const meetingTypesData: DropdownOption[] = [optionNone].concat(
        (meetingTypes || []).map((el: any) => {
          return {
            label: el.name,
            value: el.id,
            id: el.id,
          };
        })
      );
      this.setState({ meetingTypeOptions: meetingTypesData });
    }

    if (
      !_.isEmpty(currentaccountselected)
      && currentaccountselected !== prevProps.currentaccountselected
    ) {
      this.props.getMeetingTypes(currentaccountselected.id, { disabled: true, teams: true });
    }

    if (
      !_.isEmpty(prevStateL.searchTerm)
      && _.isEmpty(searchTerm)
    ) {
      searchRecordingTranscriptsEmptyAction()
    }
  };

  handleTeamSelect = (teamSelectedOptions: DropdownOption[]) => {
    this.setState({
      teamSelectedOptions,
      limit: DEFAULT_LIMIT,
    }, () => this.handleDebouncedSearch());
  };

  handleUserSelect = (userSelectedOptions: DropdownOption[]) => {
    this.setState({
      userSelectedOptions,
      limit: DEFAULT_LIMIT,
    }, () => this.handleDebouncedSearch());
  };

  handleMeetingTypeSelect = (meetingTypeSelectedOptions: DropdownOption[]) => {
    this.setState({
      meetingTypeSelectedOptions,
      limit: DEFAULT_LIMIT,
    }, () => this.handleDebouncedSearch());
  };

  handleDateChange = (startDate: null | string, endDate: null | string) => {
    this.setState({
      startDate: startDate,
      endDate: endDate,
      limit: DEFAULT_LIMIT,
    }, () => this.handleDebouncedSearch());
  };

  handleLoadTranscripts = (value: String) => {
    let { limit } = this.state;
    const { transcriptSentences } = this.props;

    if (limit - transcriptSentences.length > DEFAULT_LIMIT) {
      return;
    }

    if (value === InfinitySearch.ADD_SEARCH_LIMIT) {
      limit += DEFAULT_LIMIT;
    }

    this.setState({
      limit
    }, () => this.handleInstantSearch());
  };

  handleSearch = (force: boolean) => {
    const {
      currentaccountselected,
      searchRecordingTranscripts: searchRecordingTranscriptsAction,
      searchRecordingTranscriptsEmpty: searchRecordingTranscriptsEmptyAction,
    } = this.props;
    const {
      searchTerm,
    } = this.state;
    const params = this.getFilters();

    if (_.isEmpty(searchTerm)) {
      searchRecordingTranscriptsEmptyAction()
      return;
    }

    let trimmedSearchTerm = this.trimWhitespaces(searchTerm);

    if (!_.isEmpty(currentaccountselected) && (force || this.isTermLongEnough(searchTerm))) {
      searchRecordingTranscriptsAction(
        currentaccountselected.id,
        trimmedSearchTerm,
        params,
      )
    }
  }

  handleInstantSearch = () => {
    this.handleSearch(true);
  };

  handleDebouncedSearch = _.debounce(() => {
    this.handleSearch(false);
  }, 600);

  handleSearchTermOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { searchTerm } = this.state;

    const {
      searchRecordingTranscriptsEmpty: searchRecordingTranscriptsEmptyAction,
    } = this.props;

    this.setState({
      searchTerm: event.target.value,
      limit: DEFAULT_LIMIT,
    })

    if (this.trimWhitespaces(searchTerm) === this.trimWhitespaces(event.target.value)) {
      return;
    }
    searchRecordingTranscriptsEmptyAction();
    this.handleDebouncedSearch()
  }

  handleSearchTermOnKeyPress = (event: any): void => {
    const { searchTerm } = this.state;

    if (
      event.key === 'Enter'
      && !_.isEmpty(event.target.value)
      && this.trimWhitespaces(searchTerm) !== this.trimWhitespaces(event.target.value)
    ) {
      this.handleInstantSearch();
    }
  }

  formatTime = (seconds: number) => {
    let s = ~~seconds;

    if (s >= 3600) {
      return moment.utc(s * 1000).format('HH:mm:ss');
    } else {
      return moment.utc(s * 1000).format('mm:ss');
    }
  };

  getFilters = () => {
    const {
      teamSelectedOptions,
      userSelectedOptions,
      meetingTypeSelectedOptions,
      startDate,
      endDate,
      limit,
      useTranslation,
      matchWholeWord,
    } = this.state;

    let filters = '';

    if (teamSelectedOptions) {
      filters += _.reduce(
        teamSelectedOptions,
        (result: string, teamSelectedOption: DropdownOption) => `${result}&teams[]=${teamSelectedOption.value}`,
        ''
      )
    }

    if (userSelectedOptions) {
      filters += _.reduce(
        userSelectedOptions,
        (result: string, userSelectedOption: DropdownOption) => `${result}&users[]=${userSelectedOption.value}`,
        ''
      )
    }

    if (meetingTypeSelectedOptions) {
      filters += _.reduce(
        meetingTypeSelectedOptions,
        (result: string, meetingTypeSelectedOption: DropdownOption) => `${result}&meeting_types[]=${meetingTypeSelectedOption.value}`,
        ''
      )
    }

    if (startDate) {
      filters += `&start_date=${startDate};`
    }

    if (endDate) {
      filters += `&end_date=${endDate};`
    }

    if (limit) {
      filters += `&limit=${limit}`;
    }

    if (useTranslation) {
      filters += `&use_translation=${useTranslation}`;
    }

    if (matchWholeWord) {
      filters += `&match_whole_word=${matchWholeWord}`;
    }

    return filters;
  }

  highlightSearchWords = (transcriptSentence: string) => {
    const { searchTerm } = this.state;

    if (!searchTerm) {
      return <>{transcriptSentence}</>;
    }

    let trimmedSearch = this.trimWhitespaces(searchTerm);

    const searchTerms = trimmedSearch.split(",");
    const filteredSearchTerms = searchTerms.filter((searchTerm: string) => searchTerm !== '');

    const matchRegex = RegExp(filteredSearchTerms.join("|"), "ig");
    const matches = Array.from(transcriptSentence.matchAll(matchRegex));

    return transcriptSentence.split(matchRegex).map((nonBoldText, index, arr) => (
      <React.Fragment key={index}>
        {nonBoldText}
        {index + 1 !== arr.length && <b style={{ color: "#3C64F0" }}>{matches[index] }</b>}
      </React.Fragment>
    ));
  }

  isTermLongEnough = (searchTerm: string): boolean => {
    const length = 2;
    return searchTerm.length >= length;
  }

  trimWhitespaces = (string: string): string => {
    return string.replace(/\s+/g, ' ').trim();
  }

  render() {
    const {
      currentaccountselected,
      transcriptSentences,
      totalSentences,
      loading,
    } = this.props;
    const {
      teamOptions,
      userOptions,
      meetingTypeOptions,
      searchTerm,
      useTranslation,
      matchWholeWord,
    } = this.state;

    return (
      <React.Fragment>
        <Col lg="12">
          <CardBody>
            <div id="conversation-insights">
              <div className="header">
                <div className="page-name">Conversation Search</div>
                <div className="filters">
                  <div className="filters__title">Filter by</div>
                  {isUserRightLevel(currentaccountselected) && (
                    <div
                      className="mr-2 team-dropdown filters__filter"
                      style={{ minWidth: '160px', display: 'inline-block' }}
                    >
                      <MultipleSelect
                        onChange={(options: DropdownOption[]) => this.handleTeamSelect(options)}
                        options={teamOptions}
                        placeholder="Teams"
                      />
                    </div>
                  )}

                  {isUserRightLevel(currentaccountselected) && (
                    <div
                      className="mr-2 team-dropdown filters__filter"
                      style={{ minWidth: '160px', display: 'inline-block' }}
                    >
                      <MultipleSelect
                        placeholder="Users"
                        onChange={(options: DropdownOption[]) =>
                          this.handleUserSelect(options)
                        }
                        options={userOptions}
                      />
                    </div>
                  )}

                  <div
                    className="mr-2 team-dropdown filters__filter"
                    style={{ minWidth: '190px', display: 'inline-block' }}
                  >
                    <MultipleSelect
                      placeholder="Meeting Types"
                      onChange={(options: DropdownOption[]) =>
                        this.handleMeetingTypeSelect(options)
                      }
                      options={meetingTypeOptions}
                    />
                  </div>
                </div>
              </div>
              <div className="page-content">
                <Card>
                  <CardBody className="transcript-search-form">
                    <div className="transcript-search-form-header">
                      <div className="transcript-search-form-header-title">Sentences</div>
                    </div>
                    <div className="transcript-search">
                      <div className="input-group">
                        <div className="transcript-search__icon">
                          <span className="input-group-text"><i className="fas fa-search" /></span>
                        </div>
                        <input
                          className="transcript-search__input"
                          type="text"
                          name="searchTranscripts"
                          value={searchTerm}
                          placeholder="Search transcripts"
                          onChange={(event: any) => this.handleSearchTermOnChange(event)}
                          onKeyDown={(event: any) => this.handleSearchTermOnKeyPress(event)}
                        />
                      </div>
                    </div>
                    <div
                      className='example-text'
                      style={{ marginTop: "15px" }}
                    ><b>Examples:</b> Competitor names, objection keywords, question keywords, features, complaints, praises</div>
                    {totalSentences && (
                      <div className="d-flex align-items-center mt-3">
                        <span className="pr-2">Total results:</span>
                        <span className="d-flex flex-column-reverse font-weight-bolder">{totalSentences}</span>
                      </div>
                    )}
                    <div>
                      <div className="keyword-language mt-4">
                        <p className="keyword-language__label">Search Language: </p>
                        <div className="d-flex flex-row justify-content-start">
                          <div className="keyword-language__radio-wrapper w-auto mr-3">
                            <label className="keyword-language__radio-wrapper__radio-label">Original</label>
                            <input
                                className="keyword-language__radio-wrapper__radio-button"
                                type="radio"
                                value={"Original"}
                                checked={!useTranslation}
                                onChange={() => this.setState(
                                    prevState => ({
                                      useTranslation: !prevState.useTranslation,
                                    })
                                )}
                            />
                          </div>
                          <div className="keyword-language__radio-wrapper w-auto">
                            <label className="keyword-language__radio-wrapper__radio-label">English</label>
                            <input
                                className="keyword-language__radio-wrapper__radio-button"
                                type="radio"
                                value={"English"}
                                checked={useTranslation}
                                onChange={() => this.setState(
                                    prevState => ({
                                      useTranslation: !prevState.useTranslation,
                                    })
                                )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="keyword-switch m-0">
                        <SwitchComponent
                            label={"Match full words"}
                            checked={matchWholeWord}
                            onChange={() => this.setState(
                                (prevState) => ({
                                  matchWholeWord: !prevState.matchWholeWord,
                                })
                            )}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="transcript-sentences">
                  {
                    _.map(
                      transcriptSentences,
                      (transcriptSentence: ITranscriptSentence, index: number) =>
                        <Card key={index}>
                          <CardBody className="transcript-sentences_sentence">
                            <div className="transcript-sentences_sentence_header">
                              <div
                                className="transcript-sentences_sentence_time"
                                onClick={() => window.open(`/recording/${transcriptSentence.session_id}?start=${transcriptSentence.start}`, "_blank")}
                              >
                                {this.formatTime(transcriptSentence.start)}
                              </div>
                              <div className="transcript-sentences_sentence_speaker-name">
                                {transcriptSentence.speaker_name}
                              </div>
                              <div className="transcript-sentences_sentence_meeting-name"
                                onClick={() => window.open(`/recording/${transcriptSentence.session_id}`, "_blank")}
                              >
                                {transcriptSentence.meeting_name}
                              </div>
                            </div>
                            <div className="transcript-sentences_sentence_body">
                              <img
                                className='recording-url'
                                src={upRightImg}
                                alt='upRight box'
                                width="12"
                                height="12"
                                onClick={() => window.open(`/recording/${transcriptSentence.session_id}?start=${transcriptSentence.start}`, "_blank")}
                              />
                              <span>"{this.highlightSearchWords(transcriptSentence.transcript)}"</span>
                            </div>
                          </CardBody>
                        </Card>
                    )
                  }
                </div>
                {
                  transcriptSentences
                  && !loading
                  && transcriptSentences?.length >= DEFAULT_LIMIT
                  && (
                    <InfinitySearch
                      onPageChange={this.handleLoadTranscripts}
                    />
                  )
                }
                {
                  transcriptSentences?.length === 0
                  && (
                    <div className="text-center">No results found</div>
                  )
                }
                {
                  loading && (
                    <div className="meeting-list__loading">
                      <img
                        src={loaderImg}
                        alt="loader"
                        width="64"
                        height="64"
                      />
                    </div>
                  )
                }
              </div>
            </div>
          </CardBody>
        </Col>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentaccountselected } = state.Profile;
  const { teams } = state.Teams;
  const { users } = state.getUsersList;
  const { meetingTypes } = state.MeetingTypes;
  const { transcriptStats, loading } = state.SalesAnalytics;

  return {
    currentaccountselected,
    teams,
    users,
    meetingTypes,
    transcriptSentences: transcriptStats?.transcriptionSentences,
    totalSentences: transcriptStats?.count,
    loading,
  };
};

export default connect(mapStateToProps, {
  getMeetingTypes,
  searchRecordingTranscripts,
  searchRecordingTranscriptsEmpty,
})(ConversationInsights);
