export const CREATE_RIGHT = "CREATE_RIGHT";
export const CREATE_RIGHT_SUCCESS = "CREATE_RIGHT_SUCCESS";
export const CREATE_RIGHT_ERROR = "CREATE_RIGHT_ERROR";
export const UPDATE_RIGHT = "UPDATE_RIGHT";
export const UPDATE_RIGHT_SUCCESS = "UPDATE_RIGHT_SUCCESS";
export const UPDATE_RIGHT_ERROR = "UPDATE_RIGHT_ERROR";
export const REMOVE_RIGHTS_ERROR = "REMOVE_RIGHTS_ERROR";
export const GET_RIGHT = "GET_RIGHT";
export const GET_RIGHT_SUCCESS = "GET_RIGHT_SUCCESS";
export const GET_RIGHT_ERROR = "GET_RIGHT_ERROR";
export const DELETE_RIGHT = "DELETE_RIGHT";
export const DELETE_RIGHT_SUCCESS = "DELETE_RIGHT_SUCCESS";
export const DELETE_RIGHT_ERROR = "DELETE_RIGHT_ERROR";
export const CREATE_RIGHTS = "CREATE_RIGHTS";
export const CREATE_RIGHTS_SUCCESS = "CREATE_RIGHTS_SUCCESS";
export const GET_RIGHTS = "GET_RIGHTS";
export const GET_RIGHTS_SUCCESS = "GET_RIGHTS_SUCCESS";
export const UPDATE_RIGHTS = "UPDATE_RIGHTS";
export const UPDATE_RIGHTS_SUCCESS = "UPDATE_RIGHTS_SUCCESS";
export const REMOVE_RIGHTS = "REMOVE_RIGHTS";
export const REMOVE_RIGHTS_SUCCESS = "REMOVE_RIGHTS_SUCCESS";
