import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Modal,
  ModalHeader,
  ModalBody,
  CardFooter,
  Card,
  CardHeader,
  CardSubtitle,
  CardBody,
  Button,
} from 'reactstrap';

import { INTEGRATION } from '../IntegrationsProfile';
import googleCalendarImg from '../../../assets/images/googleCalendarImg.png';
import googleCalendar from '../../../assets/images/googleCalendar.png';
import outlookImg from '../../../assets/images/outlookImg.png';
import outlook from '../../../assets/images/outlook.png';
import { IUser } from '../../../store/interface';
import { FinishedIntegration } from '../../../store/settings/Integration/interface';
import { urls } from '../../../utilities/urls';

interface IntegrationCalendarsProps {
  currentuser: IUser;
}

interface IntegrationCalendarsState {
  showModal: boolean;
  finishedIntegrations: FinishedIntegration[];
}

class IntegrationCalendars extends Component<
  IntegrationCalendarsProps,
  IntegrationCalendarsState
> {
  constructor(props: IntegrationCalendarsProps) {
    super(props);
    this.state = {
      showModal: false,
      finishedIntegrations: [],
    };
  }

  componentDidMount(): void {
    const { currentuser } = this.props;

    if (currentuser && currentuser.integrations) {
      const hasGoogleMeetIntegration = currentuser.integrations.some(
        (integration) => integration.type === INTEGRATION.GOOGLE_MEET
      );

      const hasMicrosoftTeamsIntegration = currentuser.integrations.some(
        (integration) => integration.type === INTEGRATION.MICROSOFT_TEAMS
      );

      const hasIntegration =
        hasGoogleMeetIntegration || hasMicrosoftTeamsIntegration;

      this.setState({
        showModal: !hasIntegration,
        finishedIntegrations: currentuser.integrations,
      });
    }
  }

  findIntegrationByType = (
    finishedIntegrations: FinishedIntegration[],
    integrationType: string
  ): FinishedIntegration | undefined => {
    return finishedIntegrations.find(
      (integration) => integration.type === integrationType
    );
  };

  handleGoogleMeetClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const redirectUrl = `${urls.baseApiURL}google-meet/login`;

    axios.get(redirectUrl).then((response) => {
      const responseUrl = response?.data?.url;
      if (responseUrl) {
        window.open(responseUrl, '_blank');
      }
    });
  };

  handleMicrosoftTeamsClick = (event: any) => {
    event.preventDefault();
    const redirectUrl = `${urls.baseApiURL}ms-teams/login`;

    axios.get(redirectUrl).then((response) => {
      const responseUrl = response?.data?.url;
      if (responseUrl) {
        window.open(responseUrl, '_blank');
      }
    });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { showModal } = this.state;

    return (
      <Modal centered isOpen={showModal} className="modal-width">
        <ModalHeader>
          <Button close onClick={this.handleCloseModal} />
          <span>
            Integrate your calendar to sync with Meetric AI Notetaker, enabling
            it to join meetings and provide summaries and insights.
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="integration-calendars-group integration-cards">
            <div>
              <Card className="card_integration">
                <CardHeader>
                  <img src={googleCalendarImg} alt="loader" height="20" />
                  <span>Google Calendar Notetaker</span>
                  <hr />
                  <CardSubtitle>
                    Allow our Meeting Notetaker AI bot to join meetings created
                    in Google Calendar
                  </CardSubtitle>
                </CardHeader>
                <CardBody className="integration">
                  <div>
                    <img
                      src={googleCalendar}
                      alt="loader"
                      width="180"
                      height="130"
                    />
                  </div>
                  <div
                    className="integration-card-connect"
                    onClick={this.handleGoogleMeetClick}
                  >
                    Connect now
                  </div>
                </CardBody>
              </Card>
            </div>
            <div>
              <Card className="card_integration">
                <CardHeader>
                  <img src={outlookImg} alt="loader" height="20" />
                  <span>Outlook Notetaker</span>
                  <hr />
                  <CardSubtitle>
                    Allow our Meeting Notetaker AI bot to join meetings created
                    in Outlook Calendar
                  </CardSubtitle>
                </CardHeader>
                <CardBody className="integration">
                  <div>
                    <img src={outlook} alt="loader" width="180" height="130" />
                  </div>
                  <div
                    className="integration-card-connect"
                    onClick={this.handleMicrosoftTeamsClick}
                  >
                    Connect now
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentuser } = state.Profile;

  return {
    currentuser,
  };
};

export default connect(mapStateToProps)(IntegrationCalendars);
