import {
  GET_SHARE_LINK_DETAILS,
  GET_SHARE_LINK_DETAILS_ERROR,
  GET_SHARE_LINK_DETAILS_SUCCESS,
  GET_UNSHARE_LINK_DETAILS_ERROR,
} from "./actionTypes";

import { ShareLinkAction, ShareLinkState } from "./interface";

const initialState: ShareLinkState = {
  shareLinkDetails: null,
  isLoading: false,
  error: '',
}

const ShareLink = (state = initialState, action: ShareLinkAction) => {
  switch (action.type) {
    case GET_SHARE_LINK_DETAILS:
      state = {
        ...state,
        isLoading: true,
        shareLinkDetails: null,
      }
      break;
    case GET_SHARE_LINK_DETAILS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        shareLinkDetails: action.payload,
      }
      break;
    case GET_SHARE_LINK_DETAILS_ERROR:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break;
    case GET_UNSHARE_LINK_DETAILS_ERROR:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break;
    default:
      state = { ...state };
  }

  return state;
}

export default ShareLink;
