export const DASHBOARD = "dashboard";
export const MEETING = "meeting";
export const RECORDING = "recording";
export const STATS = "stats";
export const INSPIRATION = 'inspiration';

export const transformKeysToGlobalFilterKeys = {
  recordingTypes: "recording_types",
  campaigns: "campaign_ids",
  filterSession: "session_id",
  filterTeams: "global_team_ids",
  filterUsers: "global_user_ids",
  dealsStatus: 'deals_status',
  callsOutcome: 'calls_outcome',
  meetingTypes: "global_meeting_types",
  startDate: "global_start",
  endDate: "global_end",
  agentIds: "agent_ids",
};

export type FilterTypeKey = keyof typeof transformKeysToGlobalFilterKeys;
