import {
  API_ERROR,
  GET_ASSOCIATED_USERS,
  GET_ASSOCIATED_USERS_SUCCESS,
} from "./actionTypes";
import { AssociatedUserAction, AssociatedUserState } from "./interface";

const initialState: AssociatedUserState = {
  error: "",
  loading: false,
  users: null,
}

const getAssociatedUsers = (state = initialState, action: AssociatedUserAction) => {
  switch(action.type) {
    case GET_ASSOCIATED_USERS:
      state = {
        ...state,
        loading: true
      }
      break;
    case GET_ASSOCIATED_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        users: action.payload
      }
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break;
  }
  return state;
}

export default getAssociatedUsers;