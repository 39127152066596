import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  INTEGRATE_MICROSOFT_DYNAMICS,
  INTEGRATION_REMOVE,
  LOGIN_PIPEDRIVE,
} from "./actionTypes";
import {
  integrationError,
  integrationSuccess,
  loginPipedriveSuccess,
  loginPipedriveError,
  removeIntegrationSuccess,
  removeIntegrationError,
} from "./actions";

import {
  integrateMicrofsoftDynamics, loginPipedrive, removeIntegration, ResponseGenerator
} from "../../../services/userservice";

import _ from "lodash";

function* integrateMicrosoft(payload: any) {
  const response: ResponseGenerator = yield call(integrateMicrofsoftDynamics, payload?.payload);
  if (_.has(response, "data")) {
    yield put(integrationSuccess("Details has been edited successfully"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(integrationError(error));
    } else {
      yield put(integrationError("An Error has occured"));
    }
  }
}

function* loginPipedriveHelper() {
  const response: ResponseGenerator = yield call(loginPipedrive);
  if (_.has(response, "data")) {
    yield put(loginPipedriveSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(loginPipedriveError(error));
    } else {
      yield put(loginPipedriveError("An Error has occured"));
    }
  }
}

function* removeIntegrationHelper({ payload: { integrationType }}: any) {
  const response: ResponseGenerator = yield call(removeIntegration, integrationType);

  if (response.status === 204) {
    yield put(removeIntegrationSuccess());
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(removeIntegrationError(error));
    } else {
      yield put(removeIntegrationError("An Error has occured"));
    }
  }
}


export function* watchIntegrateMicrosoft() {
  yield takeEvery(INTEGRATE_MICROSOFT_DYNAMICS, integrateMicrosoft);
}

export function* watchLoginPipedrive() {
  yield takeEvery(LOGIN_PIPEDRIVE, loginPipedriveHelper);
}

export function* watchRemoveIntegration() {
  yield takeEvery(INTEGRATION_REMOVE, removeIntegrationHelper);
}

function* IntegrationSaga() {
  yield all([
    fork(watchIntegrateMicrosoft),
    fork(watchLoginPipedrive),
    fork(watchRemoveIntegration),
  ]);
}

export default IntegrationSaga;
