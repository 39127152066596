import axios, { AxiosResponse } from "axios";

import { ResponseGenerator } from "../userservice";
import { commonheaders } from "../../utilities/apiConfig";
import { endpoints } from "../../utilities/endpoints";
import { urls } from "../../utilities/urls";
import { ISharedWithMeData } from "../../store/interface";

export async function getSharedWithMeData(
  accountId: String,
  globalFilterString: string
): Promise<AxiosResponse<ISharedWithMeData[]>> {
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.recordingDetails}/shared-with-me?${globalFilterString}`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}
