import {
  GET_ROOMS,
  GET_ROOMS_ERROR,
  GET_ROOMS_SUCCESS,
  CREATE_NEW_ROOM,
  CREATE_ROOM_SUCCESS,
  EDIT_ROOM,
  EDIT_ROOM_SUCCESS,
  DELETE_ROOM,
  DELETE_ROOM_SUCCESS,
  API_ERROR,
  DUPLICATE_ROOM,
  DUPLICATE_ROOM_SUCCESS,
  DUPLICATE_ROOM_ERROR,
  APPEND_DUPLICATE_ROOM,
  CREATE_ROOM_FROM_TEMPLATE,
  SET_ROOM_SEARCH_TEXT,
  SET_DELETED_ROOM,
  SET_UPDATED_ROOM,
} from "./actionTypes";
import { IRoom, SendRoomData } from "./interface";

export const getRooms = (id: String, filter?: String) => {
  return {
    type: GET_ROOMS,
    payload: { id, filter },
  };
};

export const getRoomsSuccess = (rooms: IRoom) => {
  return {
    type: GET_ROOMS_SUCCESS,
    payload: rooms,
  };
};

export const getRoomsError = (error: String) => {
  return {
    type: GET_ROOMS_ERROR,
    payload: error,
  };
};

export const createNewRoom = (accountId: String, room: SendRoomData | File) => {
  return {
    type: CREATE_NEW_ROOM,
    payload: { accountId, room },
  };
};

export const createRoomSuccess = (mes: String) => {
  return {
    type: CREATE_ROOM_SUCCESS,
    payload: mes,
  };
};

export const editRoom = (accountId: String, roomId: String, room: SendRoomData) => {
  return {
    type: EDIT_ROOM,
    payload: { accountId, roomId, room },
  };
};

export const editRoomSuccess = (mes: String) => {
  return {
    type: EDIT_ROOM_SUCCESS,
    payload: mes,
  };
};

export const deleteRoom = (accountId: String, roomId: String) => {
  return {
    type: DELETE_ROOM,
    payload: { accountId, roomId },
  };
};

export const deleteRoomSuccess = (mes: String) => {
  return {
    type: DELETE_ROOM_SUCCESS,
    payload: mes,
  };
};

export const roomsApiError = (error: String) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const duplicateRoom = (data: Object,
  roomId: String,
  accountId: String,
  isTemplate: Boolean) => {
  return {
    type: DUPLICATE_ROOM,
    payload: {
      data: data,
      roomId: roomId,
      accountId: accountId,
      isTemplate: isTemplate
    },
  };
};
export const createRoomFromTemplate = (data: Object,
  name: String,
  accountId: String,
  isTemplate: Boolean) => {
  return {
    type: CREATE_ROOM_FROM_TEMPLATE,
    payload: {
      data: data,
      name: name,
      accountId: accountId,
      isTemplate: isTemplate
    },
  };
};

export const duplicateRoomSuccess = (mes: String) => {
  return {
    type: DUPLICATE_ROOM_SUCCESS,
    payload: mes,
  };
};

export const duplicateRoomError = (error: String) => {
  return {
    type: DUPLICATE_ROOM_ERROR,
    payload: error,
  };
};

export const appendDuplicateRoom = (room: {
  data: Object;
  isTemplate: Boolean;
}) => {
  return {
    type: APPEND_DUPLICATE_ROOM,
    payload: room,
  };
};
export const setRoomSearchValue = (value: String) => {
  return {
    type: SET_ROOM_SEARCH_TEXT,
    payload: value,
  };
};
export const setUpdatedRoom = (value: IRoom) => {
  return {
    type: SET_UPDATED_ROOM,
    payload: value,
  };
};
export const setDeletedRoom = (value: string) => {
  return {
    type: SET_DELETED_ROOM,
    payload: value,
  };
};

