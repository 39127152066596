import React, { Component } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import DatePicker from "react-datepicker";

import { time_filter_options } from "../../pages/Analytics/Common/analyticsIntialData";
import { isEmpty } from 'lodash';
import {
  DEFAULT_DATE_STATE,
  formatDate,
  getEndDatesForAPI,
} from "../../services/utilities/utilservice";

interface DateFilterProps {
  startDate: string | null;
  endDate: string | null;
  periodValue: string | null;
  isDisabled?: boolean;
  onDateChange: (
    startDate: null | string,
    endDate: null | string,
    globalPeriod: null | string
  ) => void;
}

interface DateFilterState {
  dateFilterToggle: boolean;
  datePickerClicked: boolean;
  startDateData: string | null;
  endDateData: string | null;
}

class DateFilterControlled extends Component<DateFilterProps, DateFilterState> {
  dropdownRef: React.RefObject<HTMLDivElement>;

  constructor(props: DateFilterProps) {
    super(props);

    this.state = {
      dateFilterToggle: false,
      datePickerClicked: false,
      startDateData: null,
      endDateData: null
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.handleRenderDates();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps: Readonly<DateFilterProps>) {
    const {startDate, endDate} = this.props
    if(startDate !== prevProps.startDate || endDate !== prevProps.endDate) {
      this.setState({
        dateFilterToggle: false,
        datePickerClicked: false,
      })
      this.handleRenderDates();
    }
  }

  handleClickOutside = (event: MouseEvent) => {
    if (event.target instanceof Node) {
      if (
        this.dropdownRef.current &&
        !this.dropdownRef.current.contains(event.target)
      ) {
        this.setState({ dateFilterToggle: false, datePickerClicked: false });
      }
    }
  };

  getDisplayText = (
    startDate: string | null,
    endDate: string | null,
    periodValue: string | null
  ): string => {
    if (Number(periodValue)) {
      return String(time_filter_options[Number(periodValue)].label);
    } else if (startDate && endDate) {
      return "Date Range";
    } else {
      return "Date";
    }
  };

  handleTimeChange = (value: any) => {
    const { onDateChange } = this.props;

    const currentTimeDates = getEndDatesForAPI(value);

    onDateChange(currentTimeDates.startdate, currentTimeDates.enddate, value);

    this.setState({
      dateFilterToggle: false
    });
  };

  handleRangeDateStart = (date: Date) => {
    this.closeDatePicker();

    const { endDate, onDateChange } = this.props;

    onDateChange(formatDate(date), endDate, null);
  };

  handleRangeDateEnd = (date: Date) => {
    this.closeDatePicker();
    const { startDate, onDateChange } = this.props;

    onDateChange(startDate, formatDate(date), null);
  };

  closeDatePicker = () => {
    this.setState({
      datePickerClicked: false,
      dateFilterToggle: false,
    });
  };

  handleBlur = () => {
    this.setState({
      datePickerClicked: false
    })
  }

  handleRenderDates = () => {
    const { startDate, endDate, periodValue } = this.props;
    const periodValueToString = !isEmpty(periodValue) ? periodValue : null;

    if(!isEmpty(periodValueToString) && periodValueToString !== DEFAULT_DATE_STATE) {
      const { startdate, enddate } = getEndDatesForAPI(periodValueToString) || {};

      this.setState({
        startDateData: startdate,
        endDateData: enddate
      })

    } else if(periodValueToString === DEFAULT_DATE_STATE) {
      this.setState({
        startDateData: null,
        endDateData: null
      })
    } else {
      this.setState({
        startDateData: startDate,
        endDateData: endDate
      })
    }
  };

  render() {
    const { dateFilterToggle, datePickerClicked, startDateData, endDateData } = this.state;
    const { startDate, endDate, periodValue, isDisabled } = this.props;
    const startDateAsDate = startDateData ? new Date(startDateData) : null;
    const endDateAsDate = endDateData ? new Date(endDateData) : null;

    const datePickerHeight = datePickerClicked ? "350px" : "auto";
    const datePickerWidth = datePickerClicked ? "300px" : "auto";

    return (
      <div ref={this.dropdownRef}>
        <UncontrolledDropdown isOpen={dateFilterToggle} direction="down" disabled={isDisabled}>
          <DropdownToggle
            tag="button"
            className={`btn btn-primary ${isDisabled ? 'disabled' : ''}`}
            style={{ backgroundColor: isDisabled ? 'hsl(0,0%,95%)' : '' }}
            onClick={() =>
              this.setState({ dateFilterToggle: !dateFilterToggle })
            }
          >
            {this.getDisplayText(startDate, endDate, periodValue)}
            <i className="mdi mdi-chevron-down" />
          </DropdownToggle>
          <DropdownMenu style={{ width: "300px" }}>
            {time_filter_options.map((data, key) => {
              return (
                <DropdownItem
                  key={key}
                  onClick={(event: any) => {
                    this.handleTimeChange(event.target.value);
                  }}
                  value={key}
                >
                  {data.label}
                </DropdownItem>
              );
            })}
            <div
              style={{
                height: datePickerHeight,
                width: datePickerWidth,
              }}
            >
              <DropdownItem>
                <div style={{ display: "grid" }}>
                  <div className="my-2">Date Range</div>
                  <div
                    style={{ width: 'fit-content' }}
                    onClick={() =>
                      this.setState({ datePickerClicked: true })
                    }
                  >
                    <DatePicker
                      selected={startDateAsDate}
                      onChange={this.handleRangeDateStart}
                      selectsStart
                      startDate={startDateAsDate}
                      endDate={endDateAsDate}
                      onBlur={this.handleBlur}
                    />
                  </div>
                  <div
                  style={{ width: 'fit-content' }}
                  onClick={() =>
                    this.setState({ datePickerClicked: true })
                  }>
                    <DatePicker
                      className="mb-2"
                      selected={endDateAsDate}
                      onChange={this.handleRangeDateEnd}
                      selectsEnd
                      startDate={startDateAsDate}
                      endDate={endDateAsDate}
                      minDate={startDateAsDate}
                      onBlur={this.handleBlur}
                    />
                  </div>
                </div>
              </DropdownItem>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  }
}

export default DateFilterControlled;
