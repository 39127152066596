import {
  GET_ASSOCIATED_TEAMS,
  GET_ASSOCIATED_TEAMS_SUCCESS,
  API_ERROR,
} from "./actionTypes";
import { IAssociatedTeam } from "./interface";

export const getAssociatedTeams = (id: String) => {
  return {
    type: GET_ASSOCIATED_TEAMS,
    payload: { id },
  };
};

export const getAssociatedTeamsSuccess = (teams: IAssociatedTeam[]) => {
  return {
    type: GET_ASSOCIATED_TEAMS_SUCCESS,
    payload: teams,
  };
};

export const associatedTeamsApiError = (error: String) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};