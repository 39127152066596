import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { CREATE_RIGHT, GET_RIGHT, UPDATE_RIGHT, DELETE_RIGHT, CREATE_RIGHTS, GET_RIGHTS, UPDATE_RIGHTS, REMOVE_RIGHTS } from './actionTypes';
import {
  createRightError,
  createRightSuccess,
  getRightSuccess,
  getRightError,
  updateRightSuccess,
  updateRightError,
  deleteRightSuccess,
  deleteRightError,
  createRightsSuccess,
  getRightsSuccess,
  updateRightsSuccess,
  removeRightError,
  removeRightsSuccess
} from "./actions";

//services
import {
  createAccountRights,
  getRights,
  ResponseGenerator,
  updateARights,
  deleteRights,
  createRights,
  getPresentationRights,
  updateRights,
  removeRights
} from '../../services/userservice';
import _ from 'lodash';

function* createAccountRightsHelper({ payload: { object } }: any) {
  const response: ResponseGenerator = yield call(createAccountRights, object);
  if (_.has(response, "data")) {
    yield put(createRightSuccess("Rights created successfully!"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(createRightError(error));
    } else {
      yield put(createRightError("An Error has occured"));
    }
  }
}

function* updateAccountRightsHelper({ payload: { object, id } }: any) {
  const response: ResponseGenerator = yield call(updateARights, object, id);
  if (_.has(response, "data")) {
    yield put(updateRightSuccess("Rights updated successfully!"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(updateRightError(error));
    } else {
      yield put(updateRightError("An Error has occured"));
    }
  }
}

function* getAccountRightHelper({ payload: { id } }: any) {
  const response: ResponseGenerator = yield call(getRights, id);
  if (_.has(response, "data")) {
    yield put(getRightSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getRightError(error));
    } else {
      yield put(getRightError("An Error has occured"));
    }
  }
}

function* deleteRightsHelper({ payload: { id } }: any) {
  const response: ResponseGenerator = yield call(deleteRights, id);
  if (_.has(response, "data")) {
    yield put(deleteRightSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteRightError(error));
    } else {
      yield put(deleteRightError("An Error has occured"));
    }
  }
}

function* createRightsHelper({ payload: { object } }: any) {
  const response: ResponseGenerator = yield call(createRights, object);
  if (_.has(response, "data")) {
    yield put(createRightsSuccess("Rights created successfully!"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(createRightError(error));
    } else {
      yield put(createRightError("An Error has occured"));
    }
  }
}

function* getRightsHelper({ payload: { id, selectPresentationId, type } }: any) {
  const response: ResponseGenerator = yield call(getPresentationRights, 
    { id: id, presentationId: selectPresentationId , type: type});
  if (_.has(response, "data")) {
    yield put(getRightsSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getRightError(error));
    } else {
      yield put(getRightError("An Error has occured"));
    }
  }
}

function* updateRightsHelper({ payload: { object, id } }: any) {
  const response: ResponseGenerator = yield call(updateRights, object, id);
  if (_.has(response, "data")) {
    yield put(updateRightsSuccess("Rights updated successfully!"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(updateRightError(error));
    } else {
      yield put(updateRightError("An Error has occured"));
    }
  }
}


function* removeRightsHelper({ payload: {  id, right_type } }: any) {
  const response: ResponseGenerator = yield call(removeRights, id, right_type);
  if (_.has(response, "data")) {
    yield put(removeRightsSuccess("Rights removed successfully!"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(removeRightError(error));
    } else {
      yield put(removeRightError("An Error has occured"));
    }
  }
}

export function* watchCreateAccountRights() {
  yield takeEvery(CREATE_RIGHT, createAccountRightsHelper);
}

export function* watchUpdateAccountRights() {
  yield takeEvery(UPDATE_RIGHT, updateAccountRightsHelper);
}

export function* watchgetRights() {
  yield takeEvery(GET_RIGHT, getAccountRightHelper);
}

export function* watchdeleteRights() {
  yield takeEvery(DELETE_RIGHT, deleteRightsHelper);
}

export function* watchCreateRights() {
  yield takeEvery(CREATE_RIGHTS, createRightsHelper);
}

export function* watchGetRights() {
  yield takeEvery(GET_RIGHTS, getRightsHelper);
}

export function* watchUpdateRights() {
  yield takeEvery(UPDATE_RIGHTS, updateRightsHelper);
}
export function* watchRemoveRights() {
  yield takeEvery(REMOVE_RIGHTS, removeRightsHelper);
}

function* rightsSaga() {
  yield all(
    [
      fork(watchCreateAccountRights),
      fork(watchgetRights),
      fork(watchUpdateAccountRights),
      fork(watchdeleteRights),
      fork(watchCreateRights),
      fork(watchGetRights),
      fork(watchUpdateRights),
      fork(watchRemoveRights),
    ]
  );
}

export default rightsSaga;
