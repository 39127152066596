import { MeetingTime } from "../../pages/Meetings/interface";
import { FilterObject } from "../interface";
import {
  GET_MEETINGS,
  GET_MEETINGS_ERROR,
  GET_MEETINGS_SUCCESS,
  GET_MEETINGS_COUNT,
  GET_MEETINGS_COUNT_SUCCESS,
  GET_MEETINGS_COUNT_ERROR,
  CREATE_MEETING,
  CREATE_MEETING_SUCCESS,
  EDIT_MEETING,
  EDIT_MEETING_SUCCESS,
  DELETE_MEETING,
  DELETE_MEETING_SUCCESS,
  GET_CREATED_MEETING,
  API_ERROR,
  GET_MEETINGS_USING_TIME_OPTIONS_SUCCESS,
  CLEAR_MEETINGS_DATA,
  GET_MEETING_CALENDAR_SYNC_INFO,
  GET_MEETING_CALENDAR_SYNC_INFO_ERROR,
  GET_MEETING_CALENDAR_SYNC_INFO_SUCCESS,
  CLEAR_MEETING_CALENDAR_SYNC_INFO,
} from "./actionTypes";
import { IMeeting, IMeetingCalendarSyncData, sendMeetingData } from "./interface";

export const getMeetings = (id: string | number, data: FilterObject, meetingType?: string, limit?: number, query?: string) => {
  return {
    type: GET_MEETINGS,
    payload: { id, data, meetingType, limit, query },
  };
};

export const getMeetingsSuccess = (meetings: Object) => {
  return {
    type: GET_MEETINGS_SUCCESS,
    payload: meetings,
  };
};

export const getMeetingsError = (error: String) => {
  return {
    type: GET_MEETINGS_ERROR,
    payload: error,
  };
};

export const clearMeetingsData = () => {
  return {
    type: CLEAR_MEETINGS_DATA,
    payload: "data cleared",
  };
};

export const getMeetingsCount = (id: string | number, meetingTime: MeetingTime, meetingFilters?: FilterObject, query?: string) => {
  return {
    type: GET_MEETINGS_COUNT,
    payload: {id, meetingTime, meetingFilters, query},
  };
};

export const getMeetingsCountSuccess = (meetingsCount: number) => {
  return {
    type: GET_MEETINGS_COUNT_SUCCESS,
    payload: meetingsCount,
  };
};

export const getMeetingsCountError = (error: String) => {
  return {
    type: GET_MEETINGS_COUNT_ERROR,
    payload: error,
  };
};

export const getCreatedMeeting = (data: Object) => {
  return {
    type: GET_CREATED_MEETING,
    payload: data,
  };
};

export const createMeeting = (accountId: String, meeting: sendMeetingData, meetingTime: MeetingTime) => {
  return {
    type: CREATE_MEETING,
    payload: { accountId, meeting, meetingTime },
  };
};

export const createMeetingSuccess = (success: String) => {
  return {
    type: CREATE_MEETING_SUCCESS,
    payload: success,
  };
};

export const editMeeting = (accountId: String, meetingId: String, meeting: sendMeetingData, meetingTime: MeetingTime) => {
  return {
    type: EDIT_MEETING,
    payload: { accountId, meetingId, meeting, meetingTime },
  };
};

export const editMeetingSuccess = (mes: String) => {
  return {
    type: EDIT_MEETING_SUCCESS,
    payload: mes,
  };
};

export const deleteMeeting = (accountId: String, meetingId: String, meetingTime?: string) => {
  return {
    type: DELETE_MEETING,
    payload: { accountId, meetingId, meetingTime },
  };
};

export const deleteMeetingSuccess = (mes: string, meetingId: string, meetingTime?: string) => {
  return {
    type: DELETE_MEETING_SUCCESS,
    payload: { mes, meetingId, meetingTime },
  };
};

export const meetingApiError = (error: String) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const getMeetingsUsingTimeOptionsSuccess = (timeType: string, data: IMeeting[]) => ({
  type: GET_MEETINGS_USING_TIME_OPTIONS_SUCCESS,
  payload: { timeType, data }
});

export const getMeetingCalendarSyncInfo = (meetingId: string) => {
  return {
    type: GET_MEETING_CALENDAR_SYNC_INFO,
    payload: { meetingId },
  };
};

export const getMeetingCalendarSyncInfoSuccess = (calendarSyncInfo: IMeetingCalendarSyncData) => {
  return {
    type: GET_MEETING_CALENDAR_SYNC_INFO_SUCCESS,
    payload: calendarSyncInfo,
  };
};

export const getMeetingCalendarSyncInfoError = (error: String) => {
  return {
    type: GET_MEETING_CALENDAR_SYNC_INFO_ERROR,
    payload: error,
  };
};

export const clearMeetingCalendarSyncInfo = () => {
  return {
    type: CLEAR_MEETING_CALENDAR_SYNC_INFO,
  };
};
