import {
  GET_BRANDING,
  GET_BRANDING_ERROR,
  GET_BRANDING_SUCCESS,
  CREATE_BRANDING,
  CREATE_BRANDING_SUCCESS,
  EDIT_BRANDING,
  EDIT_BRANDING_SUCCESS,
  DELETE_BRANDING,
  DELETE_BRANDING_SUCCESS,
  GET_CREATED_BRANDING,
  API_ERROR,
} from "./actionTypes";
import { SendBrandingData, IBranding } from "./interface";

export const getBranding = (id: String) => {
  return {
    type: GET_BRANDING,
    payload: { id },
  };
};

export const getBrandingSuccess = (brandings: IBranding) => {
  return {
    type: GET_BRANDING_SUCCESS,
    payload: brandings,
  };
};

export const getBrandingError = (error: String) => {
  return {
    type: GET_BRANDING_ERROR,
    payload: error,
  };
};

export const getCreatedBranding = (data: IBranding) => {
  return {
    type: GET_CREATED_BRANDING,
    payload: data,
  };
};

export const createBranding = (accountId: String, branding: SendBrandingData) => {
  return {
    type: CREATE_BRANDING,
    payload: { accountId, branding },
  };
};

export const createBrandingSuccess = (mes: String) => {
  return {
    type: CREATE_BRANDING_SUCCESS,
    payload: mes,
  };
};

export const editBranding = (accountId: String, brandingId: String, branding: SendBrandingData) => {
  return {
    type: EDIT_BRANDING,
    payload: { accountId, brandingId, branding },
  };
};

export const editBrandingSuccess = (mes: String) => {
  return {
    type: EDIT_BRANDING_SUCCESS,
    payload: mes,
  };
};

export const deleteBranding = (accountId: String, brandingId: String) => {
  return {
    type: DELETE_BRANDING,
    payload: { accountId, brandingId },
  };
};

export const deleteBrandingSuccess = (mes: String) => {
  return {
    type: DELETE_BRANDING_SUCCESS,
    payload: mes,
  };
};

export const brandingApiError = (error: String) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

