import { REGISTER_USER, REGISTER_USER_SUCCESSFUL, REGISTER_USER_FAILED } from './actionTypes';
import { RegisterAction, RegisterState } from './interface';

const initialState: RegisterState = {
    success : "" , 
    error : "",
    loader: false,
}

const register = (state = initialState, action: RegisterAction) => {
    switch (action.type) {
        case REGISTER_USER:
            state = {
                loader: true,
                error : "",
                success : "", 
            }
            break;
        case REGISTER_USER_SUCCESSFUL:
            state = {
                ...state,
                success : action.payload,
                error : "",
                loader: false,
            }
            break;
        case REGISTER_USER_FAILED:
            state = {
                ...state,
                success : "",
                error : action.payload,
                loader: false,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default register;