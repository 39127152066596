export const GET_TELAVOX_TOKEN = 'GET_TELAVOX_TOKEN';
export const GET_TELAVOX_TOKEN_SUCCESS = 'GET_TELAVOX_TOKEN_SUCCESS';
export const GET_TELAVOX_TOKEN_ERROR = 'GET_TELAVOX_TOKEN_ERROR';
export const CREATE_TELAVOX_TOKEN = 'CREATE_TELAVOX_TOKEN';
export const CREATE_TELAVOX_TOKEN_SUCCESS = 'CREATE_TELAVOX_TOKEN_SUCCESS';
export const CREATE_TELAVOX_TOKEN_ERROR = 'CREATE_TELAVOX_TOKEN_ERROR';
export const EDIT_TELAVOX_TOKEN = 'EDIT_TELAVOX_TOKEN';
export const EDIT_TELAVOX_TOKEN_SUCCESS = 'EDIT_TELAVOX_TOKEN_SUCCESS';
export const EDIT_TELAVOX_TOKEN_ERROR = 'EDIT_TELAVOX_TOKEN_ERROR';
export const DELETE_TELAVOX_TOKEN = 'DELETE_TELAVOX_TOKEN';
export const DELETE_TELAVOX_TOKEN_SUCCESS = 'DELETE_TELAVOX_TOKEN_SUCCESS';
export const DELETE_TELAVOX_TOKEN_ERROR = 'DELETE_TELAVOX_TOKEN_ERROR';
