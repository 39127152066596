import { GET_COMMENT_DETAILS, GET_COMMENT_DETAILS_ERROR, GET_COMMENT_DETAILS_SUCCESS, } from "./actionTypes";

import { CommentAction, CommentState } from "./interface";

const initialState: CommentState = {
  commentDetails: null,
  isLoading: false,
  error: '',
}

const Comment = (state = initialState, action: CommentAction) => {
  switch (action.type) {
    case GET_COMMENT_DETAILS:
      state = {
        ...state,
        isLoading: true,
        commentDetails: null,
      }
      break;
    case GET_COMMENT_DETAILS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        commentDetails: action.payload,
      }
      break;
    case GET_COMMENT_DETAILS_ERROR:
      state = {
        ...state,
        isLoading: false,
        error: '',
      }
      break;
    default:
      state = { ...state };
  }

  return state;
}

export default Comment;
