import {
  CREATE_RIGHT,
  CREATE_RIGHT_SUCCESS,
  CREATE_RIGHT_ERROR,
  UPDATE_RIGHT,
  UPDATE_RIGHT_SUCCESS,
  UPDATE_RIGHT_ERROR,
  GET_RIGHT,
  GET_RIGHT_SUCCESS,
  GET_RIGHT_ERROR,
  DELETE_RIGHT,
  DELETE_RIGHT_SUCCESS,
  DELETE_RIGHT_ERROR,
  CREATE_RIGHTS_SUCCESS,
  CREATE_RIGHTS,
  GET_RIGHTS_SUCCESS,
  GET_RIGHTS,
  UPDATE_RIGHTS,
  UPDATE_RIGHTS_SUCCESS,
  REMOVE_RIGHTS,
  REMOVE_RIGHTS_SUCCESS,
  REMOVE_RIGHTS_ERROR
} from "./actionTypes";
import { RightsAction, RightState } from "./interface";

const initialState: RightState = {
  error: "",
  success: "",
  loader: false,
  getLoader: false,
  rightsData: null,
  presentationRights: []
};

const rights = (state = initialState, action: RightsAction) => {
  switch (action.type) {
    case CREATE_RIGHT:
      state = {
        ...state,
        error: "",
        success: "",
        loader: true,
      };
      break;
    case CREATE_RIGHT_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loader: false,
      };
      break;
    case CREATE_RIGHT_ERROR:
      state = {
        ...state,
        error: action.payload,
        loader: false,
      };
      break;
    case UPDATE_RIGHT:
      state = {
        ...state,
        error: "",
        success: "",
        loader: true,
      };
      break;
    case UPDATE_RIGHT_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loader: false,
      };
      break;
    case UPDATE_RIGHT_ERROR:
      state = {
        ...state,
        error: action.payload,
        loader: false,
      };
      break;
    case REMOVE_RIGHTS:
      state = {
        ...state,
        error: "",
        success: "",
        loader: true,
      };
      break;
    case REMOVE_RIGHTS_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loader: false,
      };
      break;
    case REMOVE_RIGHTS_ERROR:
      state = {
        ...state,
        success: action.payload,
        loader: false,
      };
      break;
    case GET_RIGHT:
      state = {
        ...state,
        error: "",
        rightsData: null,
        getLoader: true,
      };
      break;
    case GET_RIGHT_SUCCESS:
      state = {
        ...state,
        rightsData: action.payload,
        getLoader: false,
      };
      break;
    case GET_RIGHT_ERROR:
      state = {
        ...state,
        error: action.payload,
        getLoader: false,
      };
      break;
    case DELETE_RIGHT:
      state = {
        ...state,
        error: "",
        success: "",
        loader: true,
      };
      break;
    case DELETE_RIGHT_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loader: false,
      };
      break;
    case DELETE_RIGHT_ERROR:
      state = {
        ...state,
        error: action.payload,
        loader: false,
      };
      break;
    case CREATE_RIGHTS:
      state = {
        ...state,
        error: "",
        success: "",
        loader: true,
      };
      break;
    case CREATE_RIGHTS_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loader: false,
      };
      break;
    case GET_RIGHTS:
      state = {
        ...state,
        error: "",
        presentationRights: null,
        getLoader: true,
      };
      break;
    case GET_RIGHTS_SUCCESS:
      state = {
        ...state,
        presentationRights: action.payload,
        getLoader: false,
      };
      break;
    case UPDATE_RIGHTS:
      state = {
        ...state,
        error: "",
        success: "",
        loader: true,
      };
      break;
    case UPDATE_RIGHTS_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loader: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default rights;
