import {
  DELETE_SNIPPET_DETAILS,
  DELETE_SNIPPET_DETAILS_ERROR,
  DELETE_SNIPPET_DETAILS_SUCCESS,
  GET_SNIPPET_DETAILS,
  GET_SNIPPET_DETAILS_ERROR,
  GET_SNIPPET_DETAILS_SUCCESS,
} from './actionTypes';

import { SnippetState, SnippetAction } from "./interface";

const initialState: SnippetState = {
  snippetDetails: null,
  error: '',
  isLoading: false,
  deleteLoader: false,
}

const Snippet = (state = initialState, action: SnippetAction) => {
  switch (action.type) {
    case GET_SNIPPET_DETAILS:
      state = {
        ...state,
        isLoading: true,
        snippetDetails: null,
      }
      break;
    case GET_SNIPPET_DETAILS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        snippetDetails: action.payload,
      }
      break;
    case GET_SNIPPET_DETAILS_ERROR:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break;
    case DELETE_SNIPPET_DETAILS:
      state = {
        ...state,
        deleteLoader: true,
        error: [],
      }
      break;
    case DELETE_SNIPPET_DETAILS_SUCCESS:
      state = {
        ...state,
        deleteLoader: false,
      }
      break;
    case DELETE_SNIPPET_DETAILS_ERROR:
      state = {
        ...state,
        deleteLoader: false,
        error: action.payload,
      }
      break;
    default:
      state = { ...state }
      break;
  }

  return state;
}

export default Snippet;
