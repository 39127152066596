import {
  GET_TEAMS_SUCCESS,
  GET_TEAMS_ERROR,
  GET_TEAMS,
  GET_FILTER_TEAMS,
  GET_FILTER_TEAMS_SUCCESS,
  GET_FILTER_TEAMS_ERROR,
  CREATE_NEW_TEAM,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_ERROR,
  API_ERROR,
  REMOVE_USER_FROM_TEAM_SUCCESS,
  REMOVE_USER_FROM_TEAM,
  ADD_MANAGER_TO_TEAM,
  ADD_MANAGER_TO_TEAM_SUCCESS,
  GET_MANAGED_TEAMS,
  GET_MANAGED_TEAMS_SUCCESS,
  EDIT_TEAM,
  EDIT_TEAM_SUCCESS,
} from "./actionTypes";
import { Teamstate, TeamsAction } from "./interface";

const initialState: Teamstate = {
  success: "",
  error: [],
  loader: false,
  teams: null,
  filterTeams: null,
  managedTeams: null,
};

const Teams = (state = initialState, action: TeamsAction) => {
  switch (action.type) {
    case GET_TEAMS:
      state = {
        ...state,
        success: "",
        error: [],
        loader: true,
        // teams: null,
      };
      break;
    case GET_TEAMS_SUCCESS:
      state = {
        ...state,
        teams: action.payload,
        loader: false,
      };
      break;
    case GET_TEAMS_ERROR:
      state = { ...state, error: action.payload, loader: false };
      break;
    case GET_FILTER_TEAMS:
      state = {
        ...state,
        success: "",
        error: [],
        loader: true,
      };
      break;
    case GET_FILTER_TEAMS_SUCCESS:
      state = {
        ...state,
        filterTeams: action.payload,
        loader: false,
      };
      break;
    case GET_FILTER_TEAMS_ERROR:
      state = { ...state, error: action.payload, loader: false };
      break;
    case CREATE_NEW_TEAM:
      state = {
        ...state,
        success: "",
        error: [],
        loader: true,
      };
      break;
    case CREATE_TEAM_SUCCESS:
      state = { ...state, success: action.payload, loader: false };
      break;
    case CREATE_TEAM_ERROR:
      state = { ...state, error: action.payload, loader: false };
      break;
    case GET_MANAGED_TEAMS:
      state = {
        ...state,
        success: "",
        error: [],
        loader: true,
        managedTeams: null,
      };
      break;
    case GET_MANAGED_TEAMS_SUCCESS:
      state = {
        ...state,
        managedTeams: action.payload,
        loader: false,
      };
      break;
    case ADD_MANAGER_TO_TEAM:
      state = {
        ...state,
        success: "",
        error: [],
        loader: true,
      };
      break;
    case ADD_MANAGER_TO_TEAM_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loader: false,
      };
      break;
    case REMOVE_USER_FROM_TEAM:
      state = {
        ...state,
        success: "",
        error: [],
        loader: true,
      };
      break;
    case REMOVE_USER_FROM_TEAM_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loader: false,
      };
      break;
    case EDIT_TEAM:
      state = {
        ...state,
        success: "",
        error: [],
        loader: true,
      };
      break;
    case EDIT_TEAM_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loader: false,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loader: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Teams;
