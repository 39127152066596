import React, { Component } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";

import SharedLinksDetailsTable from "./SharedLinksDetailsTable";
import UnshareLinkModal from "./UnshareLinkModal";
import { IUser } from "../../store/interface";
import { IShareLinkDetails } from "../../store/share-links/interface";
import {
  getShareLinkDetails,
  getUnshareLinkDetails,
} from "../../store/share-links/actions";
import { Icurrentaccountselected } from "../../store/auth/profile/interface";

interface SharedLinksProps {
  currentaccountselected: Icurrentaccountselected;
  globalFilterString: string;
  shareLinkDetails: IShareLinkDetails[];
  isSharedLinksLoading: boolean;
  currentuser: IUser;
  getShareLinkDetails: (accountId: String, globalFilterString: string) => void;
  getUnshareLinkDetails: (accountId: String, id: string, type: string) => void;
}

interface SharedLinksState {
  isUnshareLinkModalOpen: boolean;
  sharedLinkId: string;
  shareLinkType: string;
}

class SharedLinksPage extends Component<SharedLinksProps, SharedLinksState> {
  constructor(props: SharedLinksProps) {
    super(props);
    this.state = {
      isUnshareLinkModalOpen: false,
      sharedLinkId: "",
      shareLinkType: "",
    };
  }

  componentDidMount() {
    const { currentaccountselected, globalFilterString, getShareLinkDetails } =
      this.props;

    if (!isEmpty(currentaccountselected)) {
      getShareLinkDetails(currentaccountselected.id, globalFilterString);
    }
  }

  componentDidUpdate(prevProps: SharedLinksProps, prevState: SharedLinksState) {
    const { currentaccountselected, globalFilterString, getShareLinkDetails } =
      this.props;

    if (
      !isEmpty(currentaccountselected) &&
      (currentaccountselected?.id !== prevProps.currentaccountselected?.id ||
        globalFilterString !== prevProps.globalFilterString)
    ) {
      getShareLinkDetails(currentaccountselected.id, globalFilterString);
    }
  }

  toggleUnshareLinkModal = (sharedLinkId: string, shareLinkType: string) => {
    this.setState({
      isUnshareLinkModalOpen: true,
      sharedLinkId: sharedLinkId,
      shareLinkType,
    });
  };

  handleCloseModal = () => {
    this.setState({ isUnshareLinkModalOpen: false });
  };

  unshareLink = () => {
    const { currentaccountselected, getUnshareLinkDetails } = this.props;
    const { sharedLinkId, shareLinkType } = this.state;

    this.handleCloseModal();

    getUnshareLinkDetails(
      currentaccountselected.id,
      sharedLinkId,
      shareLinkType
    );
  };

  canUnshareShareLink = (ownerId: number) => {
    const { currentuser } = this.props;

    return ownerId === currentuser.id;
  };

  render() {
    const { isSharedLinksLoading } = this.props;
    const { isUnshareLinkModalOpen } = this.state;

    return (
      <>
        <div className="page-content meeting-page">
          <SharedLinksDetailsTable
            sharedLinks={this.props.shareLinkDetails}
            loading={isSharedLinksLoading}
            unshare={this.toggleUnshareLinkModal}
            canUnshareShareLink={this.canUnshareShareLink}
          />
          <UnshareLinkModal
            isOpen={isUnshareLinkModalOpen}
            handleClose={this.handleCloseModal}
            handleUnshare={this.unshareLink}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentaccountselected, currentuser } = state.Profile;
  const { shareLinkDetails, isLoading: isSharedLinksLoading } = state.ShareLink;

  return {
    currentaccountselected,
    shareLinkDetails,
    isSharedLinksLoading,
    currentuser,
  };
};

export default connect(mapStateToProps, {
  getShareLinkDetails,
  getUnshareLinkDetails,
})(SharedLinksPage);
