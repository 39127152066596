import {
  CREATE_HITRATE_TOKEN,
  CREATE_HITRATE_TOKEN_ERROR,
  CREATE_HITRATE_TOKEN_SUCCESS,
  DELETE_HITRATE_TOKEN,
  DELETE_HITRATE_TOKEN_ERROR,
  DELETE_HITRATE_TOKEN_SUCCESS,
  EDIT_HITRATE_TOKEN,
  EDIT_HITRATE_TOKEN_ERROR,
  EDIT_HITRATE_TOKEN_SUCCESS,
  GET_HITRATE_TOKEN,
  GET_HITRATE_TOKEN_ERROR,
  GET_HITRATE_TOKEN_SUCCESS,
} from './actionTypes';

import { HitrateAction, HitrateState, } from './interface';

const initialState: HitrateState = {
  error: "",
  hitrateToken: null,
  isLoading: false,
}

const HitrateIntegration = (state = initialState, action: HitrateAction) => {
  switch (action.type) {
    case GET_HITRATE_TOKEN:
    case CREATE_HITRATE_TOKEN:
    case EDIT_HITRATE_TOKEN:
    case DELETE_HITRATE_TOKEN:
      state = {
        ...state,
        isLoading: true,
      }

      break;
    case GET_HITRATE_TOKEN_SUCCESS:
    case CREATE_HITRATE_TOKEN_SUCCESS:
    case EDIT_HITRATE_TOKEN_SUCCESS:
    case DELETE_HITRATE_TOKEN_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        hitrateToken: action.payload,
      }

      break;
    case GET_HITRATE_TOKEN_ERROR:
    case CREATE_HITRATE_TOKEN_ERROR:
    case EDIT_HITRATE_TOKEN_ERROR:
    case DELETE_HITRATE_TOKEN_ERROR:
      state = {
        ...state,
        isLoading: false,
        error: action.payload
      }

      break;
    default:
      state = {...state};
      break;
  }

  return state;
}

export default HitrateIntegration;
