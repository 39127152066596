import {
  GET_SHARED_WITH_ME_DATA,
  GET_SHARED_WITH_ME_DATA_ERROR,
  GET_SHARED_WITH_ME_DATA_SUCCESS,
} from "./actionTypes";
import { SharedWithMeAction, SharedWithMeState } from "./interface";

const initialState: SharedWithMeState = {
  sharedWithMe: null,
  isLoading: false,
  error: "",
};

const SharedWithMe = (state = initialState, action: SharedWithMeAction) => {
  switch (action.type) {
    case GET_SHARED_WITH_ME_DATA:
      state = {
        ...state,
        isLoading: true,
        sharedWithMe: null,
      };
      break;
    case GET_SHARED_WITH_ME_DATA_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        sharedWithMe: action.payload,
      };
      break;
    case GET_SHARED_WITH_ME_DATA_ERROR:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
  }

  return state;
};

export default SharedWithMe;
