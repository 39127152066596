import React, { Component } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";

import SnippetsTable from "./SnippetsTable";
import { Icurrentaccountselected } from "../../store/auth/profile/interface";
import {
  deleteSnippetDetails,
  getSnippetDetails,
  getUnshareSnippet,
} from "../../store/snippets/actions";
import { IUser } from "../../store/interface";
import { ISnippetDetails } from "../../store/snippets/interface";
import DeleteModal from "../Home/components/DeleteModal";

interface SnippetsProps {
  currentaccountselected: Icurrentaccountselected;
  globalFilterString: string;
  snippetDetails: ISnippetDetails[];
  isLoading: boolean;
  currentuser: IUser;
  getSnippetDetails: (accountId: String, params: string) => void;
  deleteSnippetDetails: (
    accountId: String,
    snippetId: String,
    onSuccess?: () => void,
  ) => void;
  getUnshareSnippet: (
    accountId: String,
    snippetUuid: string,
    onSuccess?: () => void,
  ) => void;
}

interface SnippetsState {
  isDeleteModalOpen: boolean;
  snippetIdToDelete: string | null;
}

class SnippetsPage extends Component<SnippetsProps, SnippetsState> {
  constructor(props: SnippetsProps) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
      snippetIdToDelete: null,
    };
  }

  componentDidMount() {
    const { currentaccountselected, globalFilterString, getSnippetDetails } = this.props;

    if (!isEmpty(currentaccountselected)) {
      getSnippetDetails(
        currentaccountselected.id,
        globalFilterString
      );
    }
  }

  componentDidUpdate(prevProps: SnippetsProps, prevState: SnippetsState) {
    const { currentaccountselected, globalFilterString, getSnippetDetails } = this.props;

    if (
      !isEmpty(currentaccountselected) &&
      (currentaccountselected?.id !== prevProps.currentaccountselected?.id ||
        globalFilterString !== prevProps.globalFilterString)
    ) {
      getSnippetDetails(
        currentaccountselected.id,
        globalFilterString
      );
    }
  }

  confirmDeleteSnippet = (snippetId: string) => {
    this.setState({
      isDeleteModalOpen: true,
      snippetIdToDelete: snippetId,
    });
  };

  handleDeleteSnippet = async () => {
    const {
      currentaccountselected,
      deleteSnippetDetails,
    } = this.props;
    const { snippetIdToDelete } = this.state;

    if (!snippetIdToDelete) return;

    await deleteSnippetDetails(
      currentaccountselected.id,
      snippetIdToDelete,
      this.refetchSnippets,
    );

    this.setState({
      isDeleteModalOpen: false,
      snippetIdToDelete: null,
    });
  };

  unshareSnippet = (snippetUuid: string) => {
    const {
      currentaccountselected,
      getUnshareSnippet: getUnshareSnippetAction,
    } = this.props;

    getUnshareSnippetAction(
      currentaccountselected.id,
      snippetUuid,
      this.refetchSnippets,
    );
  }

  refetchSnippets = () => {
    const {
      currentaccountselected,
      getSnippetDetails,
      globalFilterString,
    } = this.props;

    getSnippetDetails(currentaccountselected.id, globalFilterString);
  }

  canModifySnippet = (snippetOwnerId: number) => {
    const { currentuser } = this.props;
    
    return currentuser && snippetOwnerId === currentuser.id;
  }

  render() {
    const { snippetDetails, isLoading } = this.props;
    const { isDeleteModalOpen } = this.state;

    return (
      <>
        <div className="page-content meeting-page">
          <SnippetsTable
            snippets={snippetDetails}
            loading={isLoading}
            onDelete={this.confirmDeleteSnippet}
            canModifySnippet={this.canModifySnippet}
            unshare={this.unshareSnippet}
          />
          <DeleteModal
            title="snippet"
            isOpen={isDeleteModalOpen}
            handleDelete={this.handleDeleteSnippet}
            handleClose={() =>
              this.setState({
                isDeleteModalOpen: false,
                snippetIdToDelete: null,
              })
            }
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentaccountselected, currentuser } = state.Profile;
  const { snippetDetails, isLoading } = state.Snippet;

  return {
    currentaccountselected,
    snippetDetails,
    isLoading,
    currentuser,
  };
};

export default connect(mapStateToProps, {
  getSnippetDetails,
  deleteSnippetDetails,
  getUnshareSnippet,
})(SnippetsPage);
