import React, { Component } from "react";
import { connect } from "react-redux";

import ChatTopicComponent from "./ChatTopicComponent";
import EditChatModal from "../components/EditChatModal";
import { chatMock } from "../utils/constants";
import {
  getChatTopics,
  getChatMessages,
  editChat,
  deleteChat,
  addNewChat,
  getMockChat,
} from "../../../../store/Chat/actions";
import { IChatInformation } from "../../../../store/interface";
import DeleteModal from "../../../../pages/Home/components/DeleteModal";

interface ChatTopicProps {
  chats: IChatInformation[];
  chatsLoading: boolean;
  chatInformation: IChatInformation;
  newChatMock: IChatInformation;
  getChatTopics: () => void;
  getChatMessages: (chatId: string) => void;
  editChat: (chatId: string, chatTitle: string) => void;
  deleteChat: (chatId: string) => void;
  addNewChat: (chatMock: IChatInformation) => void;
  getMockChat: () => void;
}

interface ChatTopicState {
  activeChatId: string;
  activeItemId: string;
  showDropdown: boolean;
  isEditModalOpen: boolean;
  selectedIdForDeleting: string;
  openConfirmDeleteModal: boolean;
}

class ChatTopic extends Component<ChatTopicProps, ChatTopicState> {
  chatTopicComponentRef = React.createRef<ChatTopicComponent>();

  constructor(props: ChatTopicProps) {
    super(props);
    this.state = {
      activeChatId: "",
      activeItemId: "",
      showDropdown: false,
      isEditModalOpen: false,
      openConfirmDeleteModal: false,
      selectedIdForDeleting: "",
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps: Readonly<ChatTopicProps>): void {
    const { chatInformation } = this.props;

    if (prevProps.chatInformation.chatId !== chatInformation.chatId) {
      this.setState({ activeItemId: chatInformation.chatId });
    }
  }

  createNewChatMock = () => {
    const { addNewChat } = this.props;

    addNewChat(chatMock);

    this.setState({
      activeChatId: chatMock.chatId,
    });
  };

  handleClickOutside = (event: any) => {
    if (
      this.chatTopicComponentRef.current &&
      !this.chatTopicComponentRef.current.dropdownRef.current?.contains(
        event.target
      )
    ) {
      this.setState({ showDropdown: false });
    }
  };

  fetchChatTopicMessages = (chatId: string, isForNewChat: boolean) => {
    const { getChatMessages } = this.props;

    getChatMessages(chatId);
  };

  toggleOptionsDropdown = (chatId: string) => {
    this.setState((prevState) => ({
      activeChatId: chatId,
      showDropdown:
        !prevState.showDropdown || prevState.activeChatId !== chatId,
    }));
  };

  toggleEditModal = () => {
    this.setState((prevState) => ({
      isEditModalOpen: !prevState.isEditModalOpen,
      showDropdown: false,
    }));
  };

  renameChat = (newTitle: string) => {
    const { editChat } = this.props;
    const { activeChatId } = this.state;

    editChat(activeChatId, newTitle);
  };

  deleteChat = () => {
    const { deleteChat } = this.props;
    const { selectedIdForDeleting } = this.state;

    deleteChat(selectedIdForDeleting);
    this.closeConfirmDeleteModal();
  };

  openConfirmDeleteModal = (chatId: string) => {
    this.setState({
      showDropdown: false,
      openConfirmDeleteModal: true,
      selectedIdForDeleting: chatId,
    });
  };

  closeConfirmDeleteModal = () => {
    this.setState({
      openConfirmDeleteModal: false,
      selectedIdForDeleting: "",
    });
  };

  setActiveChat = (chatId: string) => {
    this.setState({ activeChatId: chatId });
  };

  render() {
    const { chats, chatsLoading, newChatMock, editChat, getMockChat } =
      this.props;
    const {
      activeChatId,
      activeItemId,
      showDropdown,
      isEditModalOpen,
      openConfirmDeleteModal,
    } = this.state;

    return (
      <>
        <ChatTopicComponent
          ref={this.chatTopicComponentRef}
          isLoading={chatsLoading}
          chats={chats}
          getSpecificChat={this.fetchChatTopicMessages}
          editChat={editChat}
          deleteChat={this.openConfirmDeleteModal}
          toggleOptionsDropdown={this.toggleOptionsDropdown}
          openEditModal={this.toggleEditModal}
          showDropdown={showDropdown}
          activeChatId={activeChatId}
          activeItemId={activeItemId}
          setActiveChat={this.setActiveChat}
          createNewChat={this.createNewChatMock}
          newChatMock={newChatMock}
          getMockChat={getMockChat}
        />
        <EditChatModal
          chatTitle={
            chats.find((chat) => chat.chatId === activeChatId)?.chatName || ""
          }
          isOpen={isEditModalOpen}
          editTitle={this.renameChat}
          closeModal={this.toggleEditModal}
        />
        <DeleteModal
          isOpen={openConfirmDeleteModal}
          title="chat"
          handleClose={this.closeConfirmDeleteModal}
          handleDelete={this.deleteChat}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { chats, chatsLoading, chatInformation, newChatMock } = state.Chat;

  return {
    chats,
    chatsLoading,
    chatInformation,
    newChatMock,
  };
};

const mapDispatchToProps = {
  getChatTopics,
  getChatMessages,
  editChat,
  deleteChat,
  addNewChat,
  getMockChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatTopic);
