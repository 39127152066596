import React, { Component } from "react";
import { isEmpty } from "lodash";
import { Modal, ModalHeader, Col, Form, Button, Input } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";

import { TagType } from "../../../store/interface";
import {
  SnippetTag,
  Tag,
} from "../../../pages/InspirationLibrary/utils/interface";
import { getAllTagsByAccount } from "../../../store/Analytics/actions";

interface CreateTagModalProps {
  allTagsFromAccount: TagType[];
  tags: Tag[] | SnippetTag[];
  isOpen: boolean;
  accountId: String;
  isUsedForSettings?: boolean;
  onAddTag: (name: string, accountId: String) => void;
  toggleModal: () => void;
  getAllTagsByAccount: (accountId: String) => void;
}

interface CreateTagModalState {
  tagValue: string;
  tagError: string;
  updatedTag: TagType;
  options: {
    label: string;
    value: string;
  }[];
}

class CreateTagModal extends Component<
  CreateTagModalProps,
  CreateTagModalState
> {
  constructor(props: CreateTagModalProps) {
    super(props);
    this.state = {
      tagValue: "",
      tagError: "",
      updatedTag: {
        name: "",
        id: "",
        usage_count: 0,
      },
      options: [],
    };
  }

  componentDidMount() {
    const { allTagsFromAccount } = this.props;

    const selectOptions = allTagsFromAccount.map((tag) => ({
      label: tag.name,
      value: tag.name,
    }));

    this.setState({ options: selectOptions });
  }

  componentDidUpdate(prevProps: Readonly<CreateTagModalProps>): void {
    const { allTagsFromAccount } = this.props;

    if (allTagsFromAccount !== prevProps.allTagsFromAccount) {
      const selectOptions = allTagsFromAccount.map((tag) => ({
        label: tag.name,
        value: tag.name,
      }));

      this.setState({ options: selectOptions });
    }
  }

  validate = (event: React.FormEvent<HTMLFormElement>) => {
    const { tags, accountId, onAddTag, toggleModal } = this.props;
    const { tagValue } = this.state;
    
    event.preventDefault();

    if (!isEmpty(tagValue)) {
      const tagExists = tags.some((tag) => tag.name === tagValue);

      if (!tagExists) {
        onAddTag(tagValue, accountId);
        toggleModal();

        this.setState({
          tagValue: "",
          tagError: "",
        });
      } else {
        this.setState({ tagError: "Tag is already set!" });
      }
    } else {
      this.setState({ tagError: "This field cannot be empty!" });
    }
  };

  handleSetTagValue = (data: { label: string; value: string }) => {
    data && this.setState({ tagValue: data.value, tagError: "" });
  };

  render() {
    const { tagError, options, tagValue } = this.state;
    const { isOpen, isUsedForSettings, toggleModal } = this.props;

    return (
      <Modal isOpen={isOpen} size="md" centered>
        <div className="add-tag-modal-box">
          <ModalHeader>Create inspiration tag:</ModalHeader>
          <Form className="form-body" onSubmit={this.validate}>
            <Col lg="12">
              {isUsedForSettings ? (
                <Input
                  value={tagValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({ tagValue: e.target.value, tagError: "" })
                  }
                  placeholder="Create inspiration tag..."
                />
              ) : (
                <CreatableSelect
                  isClearable
                  options={options}
                  onChange={(newValue: any) => this.handleSetTagValue(newValue)}
                />
              )}
            </Col>
            {tagError && <div className="tag-error">{tagError}</div>}
            <div className="buttons-wrapper">
              <Button color="blue" type="submit">
                Save
              </Button>
              <Button color="dark" onClick={toggleModal}>
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentaccountselected } = state.Profile;
  const { allTagsFromAccount, loadingTags } = state.SalesAnalytics;

  return {
    currentaccountselected,
    allTagsFromAccount,
    loadingTags,
  };
};

export default connect(mapStateToProps, {
  getAllTagsByAccount,
})(CreateTagModal);
