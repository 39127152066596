import {
  API_ERROR,
  GET_PRESENTATIONS,
  GET_CATEGORIES,
  GET_PRESENTATIONS_SUCCESS,
  IMPORT_PRESENTATION_ERROR,
  SET_IMPORT_PPTX,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  SET_PRESENTATION,
  GET_IMPORT_PRESENTATION,
  DUPLICATE_PRESENTATION,
  EDIT_PRESENTATION,
  REMOVE_PRESENTATION,
  APPEND_DUPLICATE_PRESENTATION,
  REMOVE_PRESENTATION_SUCCESS,
  SET_SEARCH_TEXT,
  SET_FILTER_DOCUMENT,
  SET_PRESENTATION_SUCCESS,
  GET_GOOGLE_DOWNLOAD,
  GET_GOOGLE_DOWNLOAD_SUCCESS,
  GET_GOOGLE_DOWNLOAD_ERROR,
  SET_GOOGLE_DRIVE_LOGOUT,
  SET_GOOGLE_DRIVE_LOGOUT_ERROR,
  DELETE_MULTIPLE_DOCS,
  DELETE_MULTIPLE_DOCS_SUCCESS,
} from "./actionTypes";
import _ from "lodash";
import { PresentationAction, PresentationState } from "./interface";

const initialState: PresentationState = {
  error: "",
  loading: false,
  presentations: null,
  searchText: "",
  newPresentation: null,
  categories: [],
  pptxLoading: false,
  createPresentationLoader: false,
  dublicatedPresentation: {},
  filterDocument: "",
  driveloading: false,
  isTemplate: false,
  success: "",
  removeItemId: null,
  removeSuccess: "",
  multiDeleteSuccess: null,
  createdPresentation: null
};

const getPresentations = (state = initialState, action: PresentationAction) => {
  switch (action.type) {
    case GET_PRESENTATIONS:
      state = {
        ...state,
        loading: true,
        createPresentationLoader: false,
        presentations: null,
        success: "",
        error: "",
      };
      break;
    case SET_PRESENTATION:
      state = {
        ...state,
        loading: true,
        createPresentationLoader: true,
        error: [],
        success: "",
      };
      break;
    case SET_PRESENTATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        createPresentationLoader: false,
        success: action.payload.mes,
        createdPresentation: action.payload.data
      };
      break;
    case GET_IMPORT_PRESENTATION:
      state = {
        ...state,
        loading: false,
        pptxLoading: false,
        newPresentation: action.payload,
      };
      break;
    case IMPORT_PRESENTATION_ERROR:
      state = {
        ...state,
        pptxLoading: false,
        error: action.payload,
      };
      break;
    case SET_IMPORT_PPTX:
      state = {
        ...state,
        error: "",
        pptxLoading: true,
      };
      break;
    case SET_FILTER_DOCUMENT:
      state = {
        ...state,
        filterDocument: action.payload,
      };
      break;
    case GET_PRESENTATIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        presentations: action.payload,
      };
      break;
    case DUPLICATE_PRESENTATION:
      state = {
        ...state,
        dublicatedPresentation: null,
        isTemplate: false,
        success: "",
        error: "",
      };
      break;
    case APPEND_DUPLICATE_PRESENTATION:
      state = {
        ...state,
        isTemplate: action.payload.isTemplate,
        dublicatedPresentation: action.payload.data,
      };
      break;
    case REMOVE_PRESENTATION:
      state = {
        ...state,
        removeSuccess: "",
        error: "",
      };
      break;
    case REMOVE_PRESENTATION_SUCCESS:
      // let presentations = [...state?.presentations!];
      // presentations.splice(
      //   presentations.findIndex((i: any) => i.id === action.payload.pid),
      //   1
      // );
      state = {
        ...state,
        removeSuccess: action.payload.msg,
        removeItemId: action.payload.pid,
        // presentations,
      };
      break;
    case EDIT_PRESENTATION:
      state = {
        ...state,
        loading: false,
        success: "",
        error: "",
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        createPresentationLoader: false,
      };
      break;
    case GET_CATEGORIES:
      state = { ...state, categories: [] };
      break;
    case GET_CATEGORIES_SUCCESS:
      state = { ...state, categories: action.payload, loading: false };
      break;
    case GET_CATEGORIES_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case GET_GOOGLE_DOWNLOAD:
      state = { ...state, success: "", error: "", driveloading: true };
      break;
    case GET_GOOGLE_DOWNLOAD_SUCCESS:
      state = { ...state, success: action.payload, driveloading: false };
      break;
    case GET_GOOGLE_DOWNLOAD_ERROR:
      state = { ...state, error: action.payload, driveloading: false };
      break;
    case SET_SEARCH_TEXT:
      state = { ...state, searchText: action.payload };
      break;
    case SET_GOOGLE_DRIVE_LOGOUT:
      state = { ...state, error: "", loading: true };
      break;
    case SET_GOOGLE_DRIVE_LOGOUT_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case DELETE_MULTIPLE_DOCS:
      state = { ...state, removeSuccess: '', loading: false };
      break;
    case DELETE_MULTIPLE_DOCS_SUCCESS:
      state = { ...state, multiDeleteSuccess: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default getPresentations;
