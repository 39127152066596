import {
  DELETE_COMMENT_DETAILS,
  DELETE_COMMENT_DETAILS_ERROR,
  DELETE_COMMENT_DETAILS_SUCCESS,
  GET_COMMENT_DETAILS,
  GET_COMMENT_DETAILS_ERROR,
  GET_COMMENT_DETAILS_SUCCESS,
} from './actionTypes';

import { ICommentDetails } from "./interface";

export const getCommentDetails = (accountId: String, globalFilterString: string) => {
  return {
    type: GET_COMMENT_DETAILS,
    payload: accountId, globalFilterString
  }
}

export const getCommentDetailsError = (error: String | Object) => {
  return {
    type: GET_COMMENT_DETAILS_ERROR,
    payload: error
  }
}

export const getCommentDetailsSuccess = (commentDetails: ICommentDetails[]) => {
  return {
    type: GET_COMMENT_DETAILS_SUCCESS,
    payload: commentDetails,
  }
}

export const deleteCommentDetails = (
  commentId: String,
) => {
  return {
    type: DELETE_COMMENT_DETAILS,
    payload: { commentId },
  }
}

export const deleteCommentDetailsSuccess = () => {
  return {
    type: DELETE_COMMENT_DETAILS_SUCCESS,
  }
}

export const deleteCommentDetailsError = (error: String | Object) => {
  return {
    type: DELETE_COMMENT_DETAILS_ERROR,
    payload: error,
  }
}

