import { MeetingTime } from "../../pages/Meetings/interface";
import {
  CREATE_MEETING,
  CREATE_MEETING_SUCCESS,
  DELETE_MEETING,
  DELETE_MEETING_SUCCESS,
  EDIT_MEETING,
  EDIT_MEETING_SUCCESS,
  GET_CREATED_MEETING,
  GET_MEETINGS,
  GET_MEETINGS_ERROR,
  GET_MEETINGS_SUCCESS,
  GET_MEETINGS_COUNT,
  GET_MEETINGS_COUNT_SUCCESS,
  GET_MEETINGS_COUNT_ERROR,
  GET_MEETINGS_USING_TIME_OPTIONS_SUCCESS,
  CLEAR_MEETINGS_DATA,
  GET_MEETING_CALENDAR_SYNC_INFO,
  GET_MEETING_CALENDAR_SYNC_INFO_SUCCESS,
  GET_MEETING_CALENDAR_SYNC_INFO_ERROR,
  CLEAR_MEETING_CALENDAR_SYNC_INFO,
} from "./actionTypes";
import { MeetingAction, MeetingState, meetingTypeToStateKey } from './interface';

const initialState: MeetingState = {
  success: "",
  error: [],
  getLoader: false,
  getCountLoader: false,
  meetingsCount: 0,
  loader: false,
  deleteLoader: false,
  createdResource: [],
  upcomingMeetings: [],
  pastMeetings: [],
  unscheduledMeetings: [],
  meetings: [],
  deletedId: "",
  meetingTypes: null,
  meetingTimeSelected: MeetingTime.UPCOMING,
  meetingCalendarSyncInfo: null,
  meetingCalendarSyncInfoError: null,
};

const Meetings = (state = initialState, action: MeetingAction) => {
  switch (action.type) {
    case GET_MEETINGS:
      state = {
        ...state,
        success: "",
        getLoader: true,
        error: [],
        meetings: [],
      };
      break;
    case GET_MEETINGS_SUCCESS:
      state = {
        ...state,
        meetings: action.payload,
        getLoader: false,
      };
      break;
    case GET_MEETINGS_ERROR:
      state = { ...state, error: action.payload, getLoader: false };
      break;
    case CLEAR_MEETINGS_DATA:
      state = {
        ...state,
        meetings: [],
        upcomingMeetings: []
      };
      break;
    case GET_MEETINGS_COUNT:
      state = {
        ...state,
        success: "",
        getCountLoader: true,
        error: [],
        meetingsCount: 0,
      };
      break;
    case GET_MEETINGS_COUNT_SUCCESS:
      state = {
        ...state,
        meetingsCount: action.payload,
        getCountLoader: false,
      };
      break;
    case GET_MEETINGS_COUNT_ERROR:
      state = { ...state, error: action.payload, getLoader: false };
      break;
    case GET_CREATED_MEETING:
      state = { ...state, createdResource: action.payload, loader: false };
      break;
    case CREATE_MEETING:
      state = {
        ...state,
        success: "",
        error: [],
        createdResource: [],
        loader: true,
      };
      break;
    case CREATE_MEETING_SUCCESS:
      state = { ...state, success: action.payload, loader: false };
      break;
    case EDIT_MEETING:
      state = {
        ...state,
        success: "",
        error: [],
        createdResource: [],
        loader: true,
      };
      break;
    case EDIT_MEETING_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loader: false,
      };
      break;
    case DELETE_MEETING:
      state = {
        ...state,
        success: "",
        error: [],
        deleteLoader: true,
      };
      break;
      case DELETE_MEETING_SUCCESS:
        const { mes: successMessage, meetingId, meetingTime } = action.payload;

        const updatedState = {
          ...state,
          success: successMessage,
          deletedId: meetingId,
          meetings: state.meetings.filter((meeting) => meeting.id !== meetingId),
          deleteLoader: false,
        };

        if (meetingTime === MeetingTime.UPCOMING) {
          updatedState.upcomingMeetings = state.upcomingMeetings.filter((meeting) => meeting.id !== meetingId);
        } else if (meetingTime === MeetingTime.PAST) {
          updatedState.pastMeetings = state.pastMeetings.filter((meeting) => meeting.id !== meetingId);
        } else if (meetingTime === MeetingTime.UNSCHEDULED) {
          updatedState.unscheduledMeetings = state.unscheduledMeetings.filter((meeting) => meeting.id !== meetingId);
        }

        state = updatedState;
        break;

    case GET_MEETINGS_USING_TIME_OPTIONS_SUCCESS:
      const stateKey =
        meetingTypeToStateKey[action.payload.timeType as MeetingTime];
      if (stateKey) {
        state = {
          ...state,
          [stateKey]: action.payload.data,
        };
      }
      break;

    case GET_MEETING_CALENDAR_SYNC_INFO:
      state = {
        ...state,
        meetingCalendarSyncInfo: null,
        meetingCalendarSyncInfoError: null,
      };
      break;
    case GET_MEETING_CALENDAR_SYNC_INFO_SUCCESS:
      state = {
        ...state,
        meetingCalendarSyncInfo: action.payload,
      };
      break;
    case GET_MEETING_CALENDAR_SYNC_INFO_ERROR:
      state = {
        ...state,
        meetingCalendarSyncInfoError: action.payload,
      };
      break;
    case CLEAR_MEETING_CALENDAR_SYNC_INFO:
      state = {
        ...state,
        meetingCalendarSyncInfo: null,
      };
      break;
  }

  return state;
};

export default Meetings;
