export const avgInteractionTime = "Average interaction time";
export const customerInTab = "Customer in-tab %";
export const customerStories = "Customer stories";
export const directShare = "Direct share %";
export const faceToFace = "Face to face %";
export const numberOfInteractions = "Number of interactions";
export const scheduledInteractions = "Scheduled interactions";
export const longestMonologues = "Longest monologues";
export const meetingScore = "Meeting score";
export const questions = "Questions";
export const screenShare = "Screen share %";
export const slidesShown = "Slides shown";
export const interactionTime = "Interaction time";
export const wordsPerMinute = "Words per minute";
export const averageInterruptions = "Average interruptions";
export const conversationShifts = "Conversation shifts";
export const talkListenRatio = "Talk:listen ratio";
export const callOutcomes = "Call outcomes";
export const deals = "Deals";
export const compareWidgets = "Compare widgets";
export const aiAgent = "Ai Agent";
