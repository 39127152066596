import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { History, Location } from "history";
import _ from "lodash";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import { salesforceUserAuthSuccess } from "../../store/actions";

//Import Images
import success from "../../assets/images/success.svg";
import warning from "../../assets/images/warning.svg";

type successProps = {
  error: Object | String;
  history: History;
  location: Location;
  salesforceUserAuthSuccess: (code: string, history: History) => void;
};

type successState = {
  errorMessage: String | Object;
};

class SalesforceAuthCompleted extends Component<successProps, successState> {
  constructor(props: successProps) {
    super(props);
    this.state = {
      errorMessage: "",
    };
  }

  componentDidMount() {
    const { search } = this.props.location;
    const queryParams = new URLSearchParams(search);
    const error = queryParams.get("error");
    const errorDescription = queryParams.get("error_description");
    const code = queryParams.get("code");

    if (error) {
      this.setState({ errorMessage: _.capitalize(errorDescription || error) });
    }
    if (code) {
      this.props.salesforceUserAuthSuccess(code, this.props.history);
    }
  }

  componentDidUpdate(prevProps: successProps) {
    const { error } = this.props;
    if (!_.isEmpty(error) && error !== prevProps.error) {
      if (typeof error === "object" && !_.isEmpty(error)) {
        this.setState({ errorMessage: Object.values(error)[0] });
      } else {
        this.setState({ errorMessage: error });
      }
    }
  }

  render() {
    const { errorMessage } = this.state;
    return (
      <>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="p-8">
                    <div className="text-center">
                      <div className="succss-avatar-md mx-auto mb-4">
                        <img
                          src={_.isEmpty(errorMessage) ? success : warning}
                          className="succss-img"
                          alt={_.isEmpty(errorMessage) ? "success" : "error"}
                        ></img>
                      </div>

                      <div className="row justify-content-center">
                        <div className="col-xl-10">
                          <h3 className="text-primary">
                            {_.isEmpty(errorMessage) ? "Success !" : "Error !"}
                          </h3>
                          <p className="text-muted font-size-14">
                            {_.isEmpty(errorMessage)
                              ? "Please wait while redirect to your integrations page"
                              : errorMessage}
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state: any) => {
  const { error, loading } = state.Login;
  return { error, loading };
};

const mapDispatchToProps = {
    salesforceUserAuthSuccess,
};

export default withRouter(
  connect(mapStatetoProps, mapDispatchToProps)(SalesforceAuthCompleted)
);
