import {
  SEND_ACTIVATION_EMAIL,
  SEND_ACTIVATION_EMAIL_SUCCESS,
  SEND_ACTIVATION_EMAIL_ERROR
} from "./actionTypes";

import {SendActivationEmailAction, SendActivationEmailState} from './interface';

const initialState: SendActivationEmailState = {
  success : "" , 
  error : "",
  loader: false,
}

const sendActivationEmail = (state = initialState, action: SendActivationEmailAction) => {
  switch (action.type) {
    case SEND_ACTIVATION_EMAIL:
      state = {
        ...state,
        loader: true,
        success: "",
        error: ""
      };
      break;
    case SEND_ACTIVATION_EMAIL_SUCCESS:
      state = {
        ...state,
        success : action.payload,
        error : "",
        loader: false,
      };
      break;
    case SEND_ACTIVATION_EMAIL_ERROR:
      state = {
        ...state,
        success : "",
        error : action.payload,
        loader: false,
    }
    break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default sendActivationEmail;
