import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { History, Location } from "history";
import _ from "lodash";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import { loginGoogleUser, loginGoogleDriveUser } from "../../store/actions";

//Import Images
import success from "../../assets/images/success.svg";
import warning from "../../assets/images/warning.svg";
import { urls } from "../../utilities/urls";

type successProps = {
  error: Object | String;
  history: History;
  location: Location;
  loginGoogleUser: (code: string, history: History) => void;
  loginGoogleDriveUser: (code: string, history: History) => void;
};

type successState = {
  errorMessage: String | Object;
};

class Success extends Component<successProps, successState> {
  constructor(props: successProps) {
    super(props);
    this.state = {
      errorMessage: "",
    };
  }

  componentDidMount() {
    const search = this.props.location.search;
    window.addEventListener("beforeunload", this.handleParentRefresh);
    if (search) {
      const stateCode = new URLSearchParams(search).get("state");
      if (stateCode) {
        this.props.loginGoogleUser(search, this.props.history);
      } else {
        this.props.loginGoogleDriveUser(search, this.props.history);
      }
    }
  }

  componentDidUpdate(prevProps: successProps) {
    const { error } = this.props;
    console.log('Google Login Errors:');console.log(error);console.log(prevProps.error);
    if (!_.isEmpty(error) && error !== prevProps.error) {
      if (typeof error === "object" && !_.isEmpty(error)) {
        this.setState({ errorMessage: Object.values(error)[0] });
      } else {
        this.setState({ errorMessage: error });
      }
      setTimeout(() => {
        window.location.replace(urls.loginUrl);
      }, 5000);
    }
  }

  handleParentRefresh = () => {
    if (window.opener != null && !window.opener.closed) {
      localStorage.setItem("driveLoginSuccess", "true");
      window.opener.location.reload();
    }
  };

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleParentRefresh);
  }

  render() {
    const { errorMessage } = this.state;
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="p-8">
                    <div className="text-center">
                      <div className="succss-avatar-md mx-auto mb-4">
                        <img
                          src={_.isEmpty(errorMessage) ? success : warning}
                          className="succss-img"
                          alt={_.isEmpty(errorMessage) ? "success" : "error"}
                        ></img>
                      </div>

                      <div className="row justify-content-center">
                        <div className="col-xl-10">
                          <h3 className="text-primary">
                            {_.isEmpty(errorMessage) ? "Success !" : "Error !"}
                          </h3>
                          <p className="text-muted font-size-14">
                            {_.isEmpty(errorMessage)
                              ? "Please wait while redirect to dashboard"
                              : errorMessage}
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state: any) => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(
  connect(mapStatetoProps, { loginGoogleUser, loginGoogleDriveUser })(Success)
);
