export const GET_ROOMS = "GET_ROOMS";
export const GET_ROOMS_ERROR = "GET_ROOMS_ERROR";
export const GET_ROOMS_SUCCESS = "GET_ROOMS_SUCCESS";
export const CREATE_NEW_ROOM = "CREATE_NEW_ROOM";
export const CREATE_ROOM_SUCCESS = "CREATE_ROOM_SUCCESS";
export const EDIT_ROOM = "EDIT_ROOM";
export const EDIT_ROOM_SUCCESS = "EDIT_ROOM_SUCCESS";
export const DELETE_ROOM = 'DELETE_ROOM,';
export const DELETE_ROOM_SUCCESS = 'DELETE_ROOM_SUCCESS';
export const API_ERROR = "API_ERROR";
export const DUPLICATE_ROOM = "DUPLICATE_ROOM";
export const DUPLICATE_ROOM_SUCCESS = 'DUPLICATE_ROOM_SUCCESS';
export const DUPLICATE_ROOM_ERROR = "DUPLICATE_ROOM_ERROR";
export const APPEND_DUPLICATE_ROOM = "APPEND_DUPLICATE_ROOM";
export const CREATE_ROOM_FROM_TEMPLATE = "CREATE_ROOM_FROM_TEMPLATE";
export const SET_ROOM_SEARCH_TEXT = "SET_ROOM_SEARCH_TEXT";
export const SET_UPDATED_ROOM = "SET_UPDATED_ROOM";
export const SET_DELETED_ROOM = "SET_DELETED_ROOM";