import { History, Location } from "history";
import { match } from "react-router-dom";

import {
  FilterObject,
  Icurrentaccountselected,
  IMeeting,
  IUser,
} from "../../store/interface";
import { IMeetingBot } from '../../store/settings/Calendars/interface';

export interface MeetingProps {
  createdResource: any;
  currentaccountselected: Icurrentaccountselected;
  currentuser: IUser;
  error: String | Object;
  history: History;
  importError: String | Object;
  location: Location;
  match: match;
  upcomingMeetings: IMeeting[];
  pastMeetings: IMeeting[];
  unscheduledMeetings: IMeeting[];
  meetingsCount: number;
  getCountLoader: boolean;
  newPresentation: null;
  staticContext: undefined;
  success: String;
  deletedId: string;
  getLoader: boolean;
  meetingFilters: FilterObject;
  updateInProgress: boolean;
  getMeetings: (id: string | number, data: FilterObject, meetingType?: string, limit?: number, query?: string) => void;
  getMeetingsCount: (id: string | number, meetingTime: MeetingTime, meetingFilters?: FilterObject, query?: string) => void;
  deleteMeeting: (id: String, deleteId: String, meetingType?: string) => void;
  setImportPptx: () => void;
  getUserProfile: () => void;
  clearMeetingsData: () => void;
  getMeetingCalendarSyncInfo: (meetingId: string) => void;
  clearMeetingCalendarSyncInfo: () => void;
}

export enum MeetingTime {
  UPCOMING = "upcoming",
  PAST = "past",
  UNSCHEDULED = "unscheduled"
}

export interface MeetingState {
  activeTab: string;
  meetingTime: MeetingTime;
  newMeetingOpen: Boolean;
  editObj: IMeeting | null;
  isRedirect: Boolean;
  searchMeeting: string;
  inviteModal: boolean;
  calendarSyncInfoModal: boolean;
  meetingInfo: IMeeting | null;
  deleteUserData: IMeeting | null;
  confirmDelete: boolean;
  deleteUserKey: String;
  limit: number;
  meetings: IMeeting[];
  loadingTotalNumberOfMeetings: boolean;
  totalNumberOfMeetings: number;
  initialMeetingsLoading: boolean;
  meetingsLoading: boolean;
  meetingForBotLogs?: IMeeting;
  showBotLogsModal: boolean;
}

export interface DropdownOption {
  label: String;
  value: String | number;
  id: String | number | null;
}

export type MeetingData = {
  query: boolean;
  template: String;
  filterTeams: string[];
  filterUsers: number;
  meetingTypes: string[];
  recordingTypes: string[];
  dealsStatus: string[];
  callsOutcome: string[];
  campaigns: string[];
  startDate: string;
  endDate: string;
};

export type DealOptionsData = {
  receiver_ids: string[];
  account_id: String;
};

export interface Duration {
  value: Number | null;
  label: String;
}

export interface Attendee {
  index?: number;
  first_name: string;
  last_name?: string;
  company?: string;
  email: string;
  phone?: string;
  position?: string;
  isCompany?: Boolean;
}
