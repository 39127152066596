import {
  GET_RESOURCES,
  GET_RESOURCES_ERROR,
  GET_RESOURCES_SUCCESS,
  CREATE_NEW_RESOURCE,
  CREATE_RESOURCE_SUCCESS,
  EDIT_RESOURCE,
  EDIT_RESOURCE_SUCCESS,
  DELETE_RESOURCE,
  DELETE_RESOURCE_SUCCESS,
  API_ERROR,
} from "./actionTypes";
import { IResources, SendResourcesData } from "./interface";

export const getResources = (id: String) => {
  return {
    type: GET_RESOURCES,
    payload: { id },
  };
};

export const getResourcesSuccess = (resources: IResources[] ) => {
  return {
    type: GET_RESOURCES_SUCCESS,
    payload: resources,
  };
};

export const getResourcesError = (error: String) => {
  return {
    type: GET_RESOURCES_ERROR,
    payload: error,
  };
};

export const createNewResource = (accountId: String, resource: SendResourcesData) => {
  return {
    type: CREATE_NEW_RESOURCE,
    payload: { accountId, resource },
  };
};

export const createResourceSuccess = (mes: String) => {
  return {
    type: CREATE_RESOURCE_SUCCESS,
    payload: mes,
  };
};

export const editResource = (accountId: String, resourceId: String, resource: SendResourcesData) => {
  return {
    type: EDIT_RESOURCE,
    payload: { accountId, resourceId, resource },
  };
};

export const editResourceSuccess = (mes: String) => {
  return {
    type: EDIT_RESOURCE_SUCCESS,
    payload: mes,
  };
};

export const deleteResource = (accountId: String, resourceId: String) => {
  return {
    type: DELETE_RESOURCE,
    payload: { accountId, resourceId },
  };
};

export const deleteResourceSuccess = (mes: String) => {
  return {
    type: DELETE_RESOURCE_SUCCESS,
    payload: mes,
  };
};

export const resourceApiError = (error: String) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

