import {REGISTER_MULTIPLE_USERS, REGISTER_MULTIPLE_USERS_SUCCESSFUL, REGISTER_MULTIPLE_USERS_FAILED} from './actionTypes';
import {MultipleUserRegisterAction, MultipleUserRegisterState} from './interface';

const initialState: MultipleUserRegisterState = {
    success : "" , 
    error : "",
    loader: false,
}

const multipleRegister = (state = initialState, action: MultipleUserRegisterAction) => {
    switch (action.type) {
        case REGISTER_MULTIPLE_USERS:
            state = {
                loader: true,
                error : "",
                success : "", 
            }
            break;
        case REGISTER_MULTIPLE_USERS_SUCCESSFUL:
            state = {
                ...state,
                success : action.payload,
                error : "",
                loader: false,
            }
            break;
        case REGISTER_MULTIPLE_USERS_FAILED:
            state = {
                ...state,
                success : "",
                error : action.payload,
                loader: false,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default multipleRegister;