import axios, { AxiosResponse } from 'axios';
import { ResponseGenerator } from '../userservice';
import { urls } from '../../utilities/urls';
import { endpoints } from '../../utilities/endpoints';
import { commonheaders } from '../../utilities/apiConfig';
import { IAIAgent } from '../../store/interface';

export async function getAvailableAIAgents(
  accountId: String
): Promise<AxiosResponse<IAIAgent[]>> {
  try {
    const response: ResponseGenerator = await axios.get<IAIAgent[]>(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.aiAIAgents}/active`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}
