import {
  GET_FOLDERS,
  GET_FOLDERS_SUCCESS,
  API_ERROR,
  UPLOAD_FOLDER,
  UPLOAD_FOLDER_SUCCESS,
  EDIT_FOLDER,
  EDIT_FOLDER_SUCCESS,
  REMOVE_FOLDER,
  REMOVE_FOLDER_SUCCESS,
  GET_CUSTOM_FOLDERS,
  GET_CUSTOM_FOLDERS_SUCCESS,
  MOVE_FOLDER_SUCCESS,
  MOVE_FOLDER,
} from "./actionTypes";
import { FolderState, FolderAction } from "./interface";

const initialState: FolderState = {
  error: "",
  success: null,
  loader: false,
  uploadLoader: false,
  editLoader: false,
  folderObj: null,
  customFolderObj: null,
};

const folders = (state = initialState, action: FolderAction) => {
  switch (action.type) {
    case GET_FOLDERS:
      state = {
        ...state,
        error: "",
        folderObj: null,
        loader: true,
      };
      break;
    case GET_FOLDERS_SUCCESS:
      state = {
        ...state,
        folderObj: action.payload,
        loader: false,
      };
      break;
    case MOVE_FOLDER:
      state = {
        ...state,
        error: "",
        // folderObj: null,
        loader: true,
      };
      break;
    case MOVE_FOLDER_SUCCESS:
      state = {
        ...state,
        success: action.payload.msg,
        folderObj: {
          ...state.folderObj,
          files:
            state?.folderObj?.files.filter(
              (fileItem) =>
                !action.payload?.fileObj?.files.find(
                  (ele) => fileItem.id === ele
                )
            ) || [],
          folders:
            state?.folderObj?.folders.filter(
              (folderItem) =>
                !action.payload?.fileObj?.folders.find(
                  (ele2) => folderItem?.id === ele2
                )
            ) || [],
          presentations:
            state?.folderObj?.presentations.filter(
              (presentationItem) =>
                !action.payload?.fileObj?.presentations.find(
                  (ele3) => presentationItem?.id === ele3
                )
            ) || [],
        },
        loader: false,
      };
      break;
    case GET_CUSTOM_FOLDERS:
      state = {
        ...state,
        error: "",
        customFolderObj: null,
        loader: true,
      };
      break;
    case GET_CUSTOM_FOLDERS_SUCCESS:
      state = {
        ...state,
        customFolderObj: action.payload,
        folderObj: action.payload,
        loader: false,
      };
      break;
    case UPLOAD_FOLDER:
      state = {
        ...state,
        error: "",
        success: null,
        uploadLoader: true,
      };
      break;
    case UPLOAD_FOLDER_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        uploadLoader: false,
      };
      break;
    case EDIT_FOLDER:
      state = {
        ...state,
        error: "",
        success: null,
        editLoader: true,
      };
      break;
    case EDIT_FOLDER_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        editLoader: false,
      };
      break;
    case REMOVE_FOLDER:
      state = {
        ...state,
        error: "",
        success: null,
        editLoader: true,
      };
      break;
    case REMOVE_FOLDER_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        editLoader: false,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loader: false,
        uploadLoader: false,
        editLoader: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default folders;
