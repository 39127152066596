import { isEqual } from "lodash";

import {
  CLOSE_CHAT,
  CREATE_CHAT_SESSION,
  CREATE_CHAT_SESSION_SUCCESS,
  GET_CHAT_MESSAGES,
  GET_CHAT_TOPICS,
  GET_CHAT_TOPICS_ERROR,
  GET_CHAT_TOPICS_SUCCESS,
  GET_CHAT_MESSAGES_ERROR,
  GET_CHAT_MESSAGES_SUCCESS,
  SEND_MESSAGE_ERROR,
  SEND_MESSAGE_SUCCESS,
  EDIT_CHAT_ERROR,
  DELETE_CHAT_SUCCESS,
  DELETE_CHAT_ERROR,
  EDIT_CHAT,
  ADD_NEW_CHAT,
  GET_MOCK_CHAT,
} from "./actionTypes";
import { ChatState, ChatAction } from "./interface";

const initialState: ChatState = {
  chatStarterLoading: false,
  error: "",
  isChatOpen: false,
  chatInformation: {
    chatName: "",
    createdAt: "",
    chatId: "",
    status: "",
    messages: [],
    lastMessage: "",
  },
  messages: [],
  chats: [],
  chatsLoading: false,
  selectedChatLoading: false,
  newChatMock: {
    chatName: "",
    createdAt: "",
    chatId: "",
    status: "",
    messages: [],
    lastMessage: "",
    isChatMock: false,
  },
};

const Chat = (state = initialState, action: ChatAction) => {
  switch (action.type) {
    case CREATE_CHAT_SESSION:
      state = {
        ...state,
        chatStarterLoading: true,
        isChatOpen: true,
        selectedChatLoading: true,
        newChatMock: {
          chatName: "",
          createdAt: "",
          chatId: "",
          status: "",
          messages: [],
          lastMessage: "",
        },
      };
      break;
    case CREATE_CHAT_SESSION_SUCCESS:
      state = {
        ...state,
        chatStarterLoading: false,
        chatInformation: action.payload,
        selectedChatLoading: false,
      };
      break;
    case GET_CHAT_TOPICS:
      state = {
        ...state,
        chatsLoading: true,
      };
      break;
    case GET_CHAT_TOPICS_SUCCESS:
      state = {
        ...state,
        chatsLoading: false,
        chats: action.payload,
      };
      break;
    case GET_CHAT_TOPICS_ERROR:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case GET_CHAT_MESSAGES:
      state = {
        ...state,
        selectedChatLoading: false,
      };
      break;
    case GET_CHAT_MESSAGES_SUCCESS:
      const messages = action.payload.data.filter(mess => !("tool_calls" in mess));

      if (!isEqual(messages, state.messages)) {
        state = {
          ...state,
          messages: messages,
          chatInformation: action.payload.chat ?? state.chatInformation,
        };
      }
      break;
    case GET_CHAT_MESSAGES_ERROR:
      state = {
        ...state,
        selectedChatLoading: false,
        error: action.payload,
      };
      break;
    case CLOSE_CHAT:
      state = {
        ...state,
        isChatOpen: false,
        messages: [],
      };
      break;
    case SEND_MESSAGE_SUCCESS: {
      state = {
        ...state,
        messages: action.payload,
      };
      break;
    }
    case SEND_MESSAGE_ERROR: {
      state = {
        ...state,
        error: action.payload,
      };
      break;
    }
    case EDIT_CHAT: {
      const chatIndex = state.chats.findIndex(
        (chat) => chat.chatId === action.payload.chatId
      );
      const updatedChats = [...state.chats];
      let updatedChatInformation = { ...state.chatInformation };

      if (chatIndex !== -1) {
        updatedChats[chatIndex] = {
          ...updatedChats[chatIndex],
          chatName: action.payload.chatTitle,
        };

        if (state.chatInformation.chatId === action.payload.chatId) {
          updatedChatInformation = {
            ...state.chatInformation,
            chatName: action.payload.chatTitle,
          };
        }
      }

      state = {
        ...state,
        chatInformation: updatedChatInformation,
        chats: updatedChats,
      };
      break;
    }
    case EDIT_CHAT_ERROR: {
      state = {
        ...state,
        error: action.payload,
      };
      break;
    }
    case DELETE_CHAT_SUCCESS: {
      const updatedChats = state.chats.filter(
        (chat) => chat.chatId !== action.payload
      );
      const isDeletedChatSelected =
        state.chatInformation.chatId === action.payload;

      state = {
        ...state,
        chats: updatedChats,
        chatInformation: isDeletedChatSelected
          ? updatedChats.length > 0
            ? updatedChats[0]
            : {
                chatName: "",
                createdAt: "",
                chatId: "",
                status: "",
                messages: [],
                lastMessage: "",
              }
          : state.chatInformation,
        messages: isDeletedChatSelected
          ? updatedChats.length > 0
            ? updatedChats[0].messages
            : []
          : state.messages,
      };
      break;
    }
    case DELETE_CHAT_ERROR: {
      state = {
        ...state,
        error: action.payload,
      };
      break;
    }
    case ADD_NEW_CHAT:
      state = {
        ...state,
        chatInformation: {
          chatName: "",
          createdAt: "",
          chatId: "",
          status: "",
          messages: [],
          lastMessage: "",
        },
        newChatMock: action.payload,
        messages: [],
      };
      break;
    case GET_MOCK_CHAT:
      state = {
        ...state,
        chatInformation: {
          chatName: "",
          createdAt: "",
          chatId: "",
          status: "",
          messages: [],
          lastMessage: "",
        },
        newChatMock: {
          ...state.newChatMock,
          uniqueKeyForNewChatMock: Date.now(),
        },
        messages: [],
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default Chat;
