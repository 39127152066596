import { IMeetingCalendarSyncData as IMeetingCalendarSyncInfoData } from '../../store/Meetings/interface';
import axios from 'axios';
import { urls } from '../../utilities/urls';
import { endpoints } from '../../utilities/endpoints';
import { createPartnerFromData, Partner } from './partnerService';

export async function getPartner(partnerId?: string): Promise<Partner|null> {
  try {
    let route = `${urls.baseApiURL}${endpoints.partner}/`;

    if (partnerId) {
      route = route + `${partnerId}`;
    }

    const response = await axios.get(route);

    const partner = response.data.partner ?? null;

    return partner ? createPartnerFromData(partner) : null;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}
