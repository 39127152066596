import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  Tooltip,
  Button,
  UncontrolledCarousel,
} from "reactstrap";
import { connect } from "react-redux";
import {
  isEmpty,
  reduce,
  size,
  map,
  round,
  toNumber,
  nth,
  slice,
} from "lodash";
import moment from "moment";
import chroma from "chroma-js";

import userBlue from "../../../assets/images/dashboard/user-blue.svg";
import bmo from "../../../assets/images/dashboard/bmo.svg";
import { IAnalyticsMeetings } from "../../../store/Analytics/interface";
import { formatDecimal, formatPercentage } from "../../../services/utilities/utilservice";
import { adaptDateToTimezone, limitStringCharacters } from "../../../services/utilities/newUtilservice";
import { Icurrentaccountselected } from "../../../store/auth/profile/interface";

interface RecordingProps {
  currentaccountselected: Icurrentaccountselected;
  meeting: IAnalyticsMeetings;
  showDelete: boolean;
  onDelete: (data: IAnalyticsMeetings) => void;
  editMeetingType: (data: IAnalyticsMeetings) => void;
  openDealsModal: (data: IAnalyticsMeetings) => void;
  onViewLogs: (data: IAnalyticsMeetings) => void;
}

interface RecordingState {
  actionsOpened: boolean;
  notesOpened: boolean;
  participantsOpened: boolean;
  tooltipOpen: boolean;
  openEmailTooltips: any;
  hoverThumbnail: boolean;
}

interface Participant {
  id: string;
  first_name: string;
  last_name?: string;
  email?: string;
  phone?: string;
}

class Recording extends Component<RecordingProps, RecordingState> {
  constructor(props: RecordingProps) {
    super(props);

    const openEmailTooltips: Object = reduce(
      props.meeting.participants,
      (tooltips: Object, participant: Participant) => {
        return {
          ...tooltips,
          [participant.id]: false,
        };
      },
      {}
    );

    this.state = {
      actionsOpened: false,
      participantsOpened: false,
      notesOpened: false,
      tooltipOpen: false,
      openEmailTooltips,
      hoverThumbnail: false,
    };
  }

  toggleTooltip = (receiverId: string) => {
    const { openEmailTooltips } = this.state;

    openEmailTooltips[receiverId] = !openEmailTooltips[receiverId];

    this.setState({
      openEmailTooltips,
    });
  };

  showRecordingAppearanceInfo = () => {
    const {
      meeting: { date, meeting_time },
    } = this.props;

    const recTime = moment(String(date))
      .add(meeting_time, "seconds")
      .add(30, "minutes");

    if (moment().isBefore(recTime)) {
      return (
        <div className="meeting__info__thumbnail__no-recording__appearance">
          Recordings can take up to 30 minutes to appear
        </div>
      );
    }

    return null;
  };

  showLiveStatus = () => {
    return (
      <div className="meeting__info__thumbnail__no-recording__live">
        <div className="red-circle-animation"></div> Live
      </div>
    );
  };

  render() {
    const {
      currentaccountselected,
      meeting,
      showDelete,
      editMeetingType,
      openDealsModal,
      onViewLogs,
    } = this.props;

    const {
      actionsOpened,
      participantsOpened,
      tooltipOpen,
      notesOpened,
      hoverThumbnail
    } = this.state;

    //gradient definition and color at specific point extraction.
    const f = chroma
      .scale(["#00B388", "#2F8FD4", "#000000"])
      .domain([0, 0.45, 0.8]);
    const meetingScore = meeting.meeting_score;
    const scoreColor = f(round(100 - toNumber(meetingScore)) / 100).toString();
    const showSpeakHelpBox = false;
    const showEngagementHelpBox = false;
    const thumbnails = map(meeting.thumbnails, (thumbnail, index) => ({
      caption: "",
      key: index,
      src: thumbnail?.toString(),
    }));
    const thumbnail: String | undefined = nth(meeting.thumbnails, 3);

    const activeThumbnail = thumbnail ? (
      <img
        onMouseEnter={() => {
          this.setState({ hoverThumbnail: true });
        }}
        className="meeting__info__thumbnail__image"
        src={thumbnail.toString()}
        alt="thumbnail"
      />
    ) : null;

    return (
      <div className="meeting meeting--recording">
        <Tooltip
          className="meeting-tooltip"
          placement="left"
          isOpen={tooltipOpen}
          target={`tooltip-${meeting.session_id}`}
          toggle={() => this.setState({ tooltipOpen: !tooltipOpen })}
        >
          <div className="meeting-tooltip__header">
            <div className="meeting-tooltip__header__title">
              Total meeting score
            </div>
            <div
              className="meeting-tooltip__header__score"
              style={{ color: scoreColor }}
            >
              {meetingScore ? round(toNumber(meetingScore)) : "-"}
            </div>
          </div>
          <div className="meeting-tooltip__table">
            <table>
              <thead>
                <tr>
                  <th>Breakdown</th>
                  <th>Goal</th>
                  <th>Result</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Engagement</td>
                  <td>-</td>
                  <td>
                    {meeting.meeting_engagement_percentage
                      ? formatPercentage(meeting.meeting_engagement_percentage)
                      : "-"}
                  </td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Talk:listen ratio</td>
                  <td>-</td>
                  <td>
                    {meeting.division
                      ? formatPercentage(meeting.division)
                      : "-"}
                  </td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Conversation shifts</td>
                  <td>-</td>
                  <td>
                    {meeting.shifts_per_minute
                      ? `${meeting.shifts_per_minute}/min`
                      : "-"}
                  </td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="meeting-tooltip__type">
            <div className="meeting-tooltip__type__label">Meeting type:</div>
            <div className="meeting-tooltip__type__value">
              {meeting.meeting_type || "-"}
            </div>
          </div>
          {showSpeakHelpBox && (
            <div className="meeting-tooltip__help">
              <img src={bmo} />
              Let your participants speak more. Maybe wait 1-2 seconds after
              they finished before speaking.
            </div>
          )}
          {showEngagementHelpBox && (
            <div className="meeting-tooltip__help">
              <img src={bmo} />
              Check if customer looses engagement. When that happens, ask an
              engaging question.
            </div>
          )}
        </Tooltip>
        <div className="meeting__info meeting__info--thumbnail-and-general-info">
          <div
            className={`meeting__info__thumbnail ${
              !meeting.finished_merging
                ? "meeting__info__thumbnail--no-recording"
                : ""
            }`}
          >
            {meeting.finished_merging ? (
              <Link
                className="meeting__info__thumbnail__link"
                to={{
                  pathname: `/recording/${meeting.session_id}`,
                  state: { meeting },
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  focusable="false"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z" />
                  <path
                    fill="#ffffff"
                    d="M 10 16.5 V 7.5 L 16 12 L 10 16.5 Z"
                  ></path>
                </svg>
                {!isEmpty(thumbnails) &&
                thumbnails.length > 1 &&
                hoverThumbnail ? (
                  <UncontrolledCarousel
                    indicators={false}
                    controls={false}
                    interval="500"
                    slide={false}
                    items={thumbnails}
                    mouseLeave={(e: any) => {
                      this.setState({ hoverThumbnail: false });
                    }}
                  />
                ) : (
                  activeThumbnail
                )}
              </Link>
            ) : (
              <div className="meeting__info__thumbnail__no-recording">
                <div className="meeting__info__thumbnail__no-recording__info">
                  {meeting.live
                    ? ""
                    : meeting.has_recording
                    ? "Processing recording"
                    : "No recording available"}
                </div>
                {!meeting.live
                  ? this.showRecordingAppearanceInfo()
                  : this.showLiveStatus()}
              </div>
            )}
          </div>
          <div className="meeting__info__general-info">
            <div className="meeting__info__meeting-type">
              {meeting.meeting_type}
            </div>
            <div className="meeting__info__name">
              {meeting.name}{" "}
            </div>
            <div className="meeting__info__time-info">
              <span className="meeting__info__time-info--created-at mr-2">
                {adaptDateToTimezone(
                  meeting.date as string,
                  currentaccountselected?.company_timezone
                )}
              </span>{" "}
              <span className="meeting__info__time-info--duration">
                {meeting.meeting_time < 60 &&
                  moment
                    .utc(
                      moment
                        .duration(meeting.meeting_time, "seconds")
                        .asMilliseconds()
                    )
                    .format("s[s]")}
                {meeting.meeting_time >= 60 &&
                  meeting.meeting_time < 3600 &&
                  moment
                    .utc(
                      moment
                        .duration(meeting.meeting_time, "seconds")
                        .asMilliseconds()
                    )
                    .format("m[m]")}
                {meeting.meeting_time >= 3600 &&
                  moment
                    .utc(
                      moment
                        .duration(meeting.meeting_time, "seconds")
                        .asMilliseconds()
                    )
                    .format("H[h] m[m]")}
              </span>
            </div>
            <div className="meeting__info__actions" >
              {meeting.session_note && (
                <Button
                  onClick={() => this.setState({ notesOpened: !notesOpened })}
                  className="meeting__info__actions--note"
                >
                  <i className="fa-regular fa-note-sticky" title="Notes"></i>
                </Button>
              )}
              <Dropdown
                className="meeting__info__actions--more"
                title="Recording options"
                isOpen={actionsOpened}
                toggle={() =>
                  this.setState({ actionsOpened: !actionsOpened })
                }
              >
                <DropdownToggle caret>
                  <i className="fas fa-bars" />
                </DropdownToggle>
                <DropdownMenu>
                  {showDelete && (
                    <DropdownItem onClick={() => this.props.onDelete(meeting)}>
                      Delete session
                    </DropdownItem>
                  )}
                  <DropdownItem onClick={() => editMeetingType(meeting)}>
                    Edit meeting type
                  </DropdownItem>
                  <DropdownItem onClick={() => onViewLogs(meeting)}>
                    View logs
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              {meeting?.deal && (
                <div className="meeting__info__actions--modal" title={meeting?.deal.name} onClick={() => openDealsModal(meeting)}>
                  <i className="fa-regular fa-handshake"></i>
                  {meeting?.deal ? <label>{limitStringCharacters(meeting?.deal.name || '', 15)}</label> : null}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="meeting__info meeting__info--participation">
          <span className="meeting__info__company-name">
            {!isEmpty(meeting.company_domains) &&
              `${meeting.company_domains[0].name}`}
          </span>
          <div className="meeting__info__participants">
            <ul>
              <li>
                <i className="fa fa-user user-green" aria-hidden="true" />
                <span>
                  {`${[meeting.user.first_name, meeting.user.last_name].join(
                    " "
                  )} (Presenter)`}
                </span>
              </li>
              {map(
                slice(meeting.participants, 0, 2),
                (receiver: Participant, index: any) => (
                  <div key={index}>
                    {!isEmpty(slice(meeting.participants, 0, 2)) ? 
                      <li key={String(receiver?.id)}>
                        <i className="fa fa-user" aria-hidden="true" />
                        <span
                          id={`tooltip-${meeting.session_id}-participant-${receiver?.id}`}
                        >
                          {[receiver?.first_name, receiver?.last_name]
                            .join(" ")
                            .trim() || receiver?.email || receiver?.phone}
                        </span>
                        {receiver?.email && (
                          <Tooltip
                            className="email-tooltip"
                            placement="bottom"
                            isOpen={this.state.openEmailTooltips[receiver?.id]}
                            target={`tooltip-${meeting.session_id}-participant-${receiver?.id}`}
                            toggle={() => this.toggleTooltip(receiver?.id)}
                          >
                            <div className="tooltip-email">
                              <p>{receiver?.email}</p>
                            </div>
                          </Tooltip>
                        )}
                      </li> 
                    : null}
                  </div>
                )
              )}
            </ul>
          </div>
          {size(meeting.participants) > 2 && (
            <>
              <button
                type="button"
                className="meeting__info__show-more"
                onClick={() =>
                  this.setState({ participantsOpened: !participantsOpened })
                }
              >
                +{size(meeting.participants) - 2} more participants
              </button>
              <Modal
                isOpen={participantsOpened}
                toggle={() =>
                  this.setState({ participantsOpened: !participantsOpened })
                }
                centered={true}
                className="meeting-modal share-modal"
              >
                <ModalHeader>
                  <h5 className="modal-title mt-0">Meeting participants</h5>
                  <button
                    type="button"
                    onClick={() =>
                      this.setState({ participantsOpened: !participantsOpened })
                    }
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </ModalHeader>
                <ModalBody>
                  {map(meeting.participants, (receiver: Participant) => (
                    <div
                      className="meeting__info__user-name"
                      key={String(receiver.id)}
                    >
                      <img src={userBlue} />
                      <span>
                        {[receiver.first_name, receiver.last_name]
                          .join(" ")
                          .trim() || receiver.email}
                      </span>
                    </div>
                  ))}
                </ModalBody>
              </Modal>
            </>
          )}
        </div>
        <div className="meeting__info meeting__info--statistics">
          <div className="meeting__info__statistics__item meeting__info__statistics__item--score">
            <div className="meeting__info__statistics__item__label">
              Meeting score
            </div>
            <div
              className="meeting__info__statistics__item__value meeting__info__score"
              id={`tooltip-${meeting.session_id}`}
              style={{ color: scoreColor }}
            >
              {meetingScore ? round(toNumber(meetingScore)) : "-"}
            </div>
          </div>
          <div className="meeting__info__statistics__item meeting__info__statistics__item--engagement">
            <div className="meeting__info__statistics__item__label">
              Engagement
            </div>
            <div
              className="meeting__info__statistics__item__value"
              id={`tooltip-${meeting.session_id}`}
              style={{ color: scoreColor }}
            >
              {meeting.meeting_engagement_percentage
                ? formatPercentage(meeting.meeting_engagement_percentage)
                : "-"}
            </div>
          </div>
          <div className="meeting__info__statistics__item meeting__info__statistics__item--talk-list-ratio">
            <div className="meeting__info__statistics__item__label">
              Talk:Listen ratio
            </div>
            <div
              className="meeting__info__statistics__item__value"
              id={`tooltip-${meeting.session_id}`}
              style={{ color: scoreColor }}
            >
              {meeting.division ? formatPercentage(meeting.division) : "-"}
            </div>
          </div>
          <div className="meeting__info__statistics__item meeting__info__statistics__item--conversation-shifts">
            <div className="meeting__info__statistics__item__label">
              Conversation shifts
            </div>
            <div
              className="meeting__info__statistics__item__value"
              id={`tooltip-${meeting.session_id}`}
              style={{ color: scoreColor }}
            >
              {meeting.shifts_per_minute
                ? `${formatDecimal(toNumber(meeting.shifts_per_minute))}/min`
                : "-"}
            </div>
          </div>
          <Modal
            isOpen={notesOpened}
            toggle={() => this.setState({ notesOpened: !notesOpened })}
            centered={true}
            className="meeting-modal share-modal"
          >
            <ModalHeader>
              <h5 className="modal-title mt-0">Notes</h5>
              <button
                type="button"
                onClick={() => this.setState({ notesOpened: !notesOpened })}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody
              dangerouslySetInnerHTML={{ __html: meeting.session_note }}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentaccountselected } = state.Profile;

  return { currentaccountselected };
};

export default connect(mapStateToProps)(Recording);
