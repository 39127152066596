import {
  GET_FILES,
  GET_FILES_SUCCESS,
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  EDIT_FILE,
  EDIT_FILE_SUCCESS,
  API_ERROR,
} from "./actionTypes";

export const getFiles = (filter: String) => {
  return {
    type: GET_FILES,
    payload: { filter },
  };
};

export const getFilesSuccess = (mes: String) => {
  return {
    type: GET_FILES_SUCCESS,
    payload: mes,
  };
};

export const deleteFile = (id: String) => {
  return {
    type: DELETE_FILE,
    payload: { id },
  };
};

export const deleteFileSuccess = (mes: String, deleteItemId: String) => {
  return {
    type: DELETE_FILE_SUCCESS,
    payload: { mes, deleteItemId },
  };
};

export const editFile = (id: String, data: Object) => {
  return {
    type: EDIT_FILE,
    payload: { id, data },
  };
};

export const editFileSuccess = (mes: String) => {
  return {
    type: EDIT_FILE_SUCCESS,
    payload: mes,
  };
};

export const filesApiError = (mes: String) => {
  return {
    type: API_ERROR,
    payload: mes,
  };
};
