export const GET_GLOBAL_FILTERS = "GET_GLOBAL_FILTERS";
export const GET_GLOBAL_FILTERS_SUCCESS = "GET_GLOBAL_FILTERS_SUCCESS";
export const GET_GLOBAL_FILTERS_ERROR = "GET_GLOBAL_FILTERS_ERROR";
export enum FilterActionTypes {
  UPDATE_DASHBOARD_FILTERS = "UPDATE_DASHBOARD_FILTERS",
  UPDATE_MEETING_FILTERS = "UPDATE_MEETING_FILTERS",
  UPDATE_RECORDING_FILTERS = "UPDATE_RECORDING_FILTERS",
  UPDATE_STATS_FILTERS = "UPDATE_STATS_FILTERS",
  UPDATE_INSPIRATION_FILTERS = "UPDATE_INSPIRATION_FILTERS",
};
export const EMPTY_DATE_FILTERS = "EMPTY_DATE_FILTERS";
export const UPDATE_DASHBOARD_FILTERS_AFTER_EDIT = "UPDATE_DASHBOARD_FILTERS_AFTER_EDIT";
export const UPDATE_ALL_FILTERS_CAMPAIGN = "UPDATE_ALL_FILTERS_CAMPAIGN";
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_GLOBAL_FILTERS_LOADING = 'SET_GLOBAL_FILTERS_LOADING';
