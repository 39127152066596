import {
  GET_AGENTS,
  GET_AGENTS_ERROR,
  GET_AGENTS_SUCCESS,
} from './actionTypes';
import { DashboardAction, DashboardState } from './interface';

const initialState: DashboardState = {
  error: '',
  isLoading: false,
  availableAIAgents: [],
};

const dashboardReducer = (
  state = initialState,
  action: DashboardAction
): DashboardState => {
  switch (action.type) {
    case GET_AGENTS:
      return {
        ...state,
        availableAIAgents: [],
        isLoading: true,
      };
    case GET_AGENTS_SUCCESS:
      return {
        ...state,
        availableAIAgents: action.payload,
        isLoading: false,
      };
    case GET_AGENTS_ERROR:
      return {
        ...state,
        availableAIAgents: [],
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
