import React, { Component } from "react";
import { Link } from "react-router-dom";

import { TABLE_COMMENTS_HEADERS } from "./constants";
import TableLayout from "../../components/TableLayout";
import { limitStringCharacters } from "../../services/utilities/newUtilservice";
import { formatDateTime } from "../../services/utilities/utilservice";
import { ICommentDetails } from "../../store/comments/interface";

interface CommentsDetailsTableProps {
  comments: ICommentDetails[];
  loading: boolean;
  canDeleteComment: (commentOwnerId: number) => boolean;
  onDelete: (commentId: string) => void;
}

class CommentsDetailsTable extends Component<CommentsDetailsTableProps> {
  render() {
    const { comments, loading, onDelete, canDeleteComment } = this.props;

    return (
      <div className="table-userlist light-fontweight-table snippet-table">
        <TableLayout tableHeaders={TABLE_COMMENTS_HEADERS} loading={loading}>
          {comments &&
            comments.map((comment: ICommentDetails, index: any) => {
              return (
                <tr key={comment.id}>
                  <td>{index + 1}</td>
                  <td className="underline-on-hover">
                    <Link
                      to={{
                        pathname: "/recording/" + comment.sessionId,
                        state: { comment },
                      }}
                      target="_blank"
                    >
                      {limitStringCharacters(comment.comment)}
                    </Link>
                  </td>
                  <td>{comment.recordingName}</td>
                  <td>{formatDateTime(comment.createdAt)}</td>
                  <td>{comment.recordingOwnerName}</td>
                  <td>{comment.commentName}</td>
                  <td>{comment.repliesCount}</td>
                  <td>
                    <Link
                      to={{
                        pathname: "/recording/" + comment.sessionId,
                        state: { comment },
                      }}
                      target="_blank"
                      className="btn btn-primary btn-sm btn-edit"
                    >
                      View
                    </Link>
                    {/* HIDDEN TEMPORARILY */}
                    {/* {canDeleteComment(comment.commenterId) && (
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => onDelete(comment.id)}
                      >
                        Delete
                      </button>
                    )} */}
                  </td>
                </tr>
              );
            })}
        </TableLayout>
      </div>
    );
  }
}

export default CommentsDetailsTable;
