import React, { Component } from "react";

import { IChatInformation } from "../../../../store/interface";
import { renderLoadingIndicator } from "../../../Widgets/utils/helperFunction";

interface ChatTopicComponentProps {
  chats: IChatInformation[];
  isLoading: boolean;
  showDropdown: boolean;
  activeChatId: string;
  activeItemId: string;
  newChatMock: IChatInformation;
  toggleOptionsDropdown: (chatId: string) => void;
  getSpecificChat: (chatId: string, isForNewChat: boolean) => void;
  editChat: (chatId: string, chatTitle: string) => void;
  openEditModal: () => void;
  deleteChat: (chatId: string) => void;
  setActiveChat: (chatId: string) => void;
  createNewChat: () => void;
  getMockChat: () => void;
}

class ChatTopicComponent extends Component<ChatTopicComponentProps> {
  dropdownRef = React.createRef<HTMLDivElement>();

  renderDropdown = (chatId: string) => {
    const { openEditModal, deleteChat } = this.props;

    const handleDeleteClick = (e: any) => {
      e.stopPropagation();
      deleteChat(chatId);
    };

    const handleRenameClick = (e: any) => {
      e.stopPropagation();
      openEditModal();
    };

    return (
      <div className="chat-dropdown" ref={this.dropdownRef}>
        <div className="dropdown-chat-item" onClick={handleRenameClick}>
          Rename chat
        </div>
        <div className="dropdown-chat-item" onClick={handleDeleteClick}>
          Delete chat
        </div>
      </div>
    );
  };

  renderChatMock = (chat: IChatInformation) => {
    const {
      showDropdown,
      activeChatId,
      setActiveChat,
      toggleOptionsDropdown,
      getMockChat,
    } = this.props;

    return (
      <li
        key={chat.chatId}
        className={`chat-topic-item ${
          activeChatId === chat.chatId ? "activeChat" : ""
        }`}
        onClick={() => {
          getMockChat();
          setActiveChat(chat.chatId);
        }}
      >
        <span>{chat.chatName}</span>
        <i
          className="fa-light fa-pen icon-grip-dots"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            toggleOptionsDropdown(chat.chatId);
          }}
        />
        {showDropdown &&
          activeChatId === chat.chatId &&
          this.renderDropdown(chat.chatId)}
      </li>
    );
  };

  render() {
    const {
      isLoading,
      chats,
      showDropdown,
      activeChatId,
      activeItemId,
      newChatMock,
      getSpecificChat,
      toggleOptionsDropdown,
      setActiveChat,
      createNewChat,
    } = this.props;

    return (
      <>
        <aside className="chat-sidebar">
          <div className="chat-sidebar-header">
            <div>Chat Topics</div>
            <i
              className="fa-regular fa-message-plus icon-grip-dots"
              onClick={createNewChat}
            />
          </div>
          <ul className="chat-topic-list">
            {isLoading ? (
              <div className="chat-sidebar-loader">
                {renderLoadingIndicator()}
              </div>
            ) : (
              <>
                {newChatMock.isChatMock && this.renderChatMock(newChatMock)}
                {chats?.map((chat) => (
                  <li
                    key={chat.chatId}
                    className="chat-topic-item"
                    onClick={() => {
                      getSpecificChat(chat.chatId, true);
                      setActiveChat(chat.chatId);
                    }}
                  >
                    <span
                      className={
                        activeItemId === chat.chatId ? "activeChat" : ""
                      }
                    >
                      {chat.chatName}
                    </span>
                    <i
                      className="fa-light fa-pen icon-grip-dots"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        toggleOptionsDropdown(chat.chatId);
                      }}
                    />
                    {showDropdown &&
                      activeChatId === chat.chatId &&
                      this.renderDropdown(chat.chatId)}
                  </li>
                ))}
              </>
            )}
          </ul>
        </aside>
      </>
    );
  }
}

export default ChatTopicComponent;
