import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { has } from "lodash";

import {
  deleteSnippetDetailsError,
  deleteSnippetDetailsSuccess,
  getSnippetDetails as getSnippetDetailsAction,
  getSnippetDetailsError,
  getSnippetDetailsSuccess,
  getUnshareSnippet as getUnshareSnippetAction
} from "./actions";

import {
  DELETE_SNIPPET_DETAILS,
  GET_SNIPPET_DETAILS,
  UNSHARE_SNIPPET_DETAILS,
} from "./actionTypes";

import { deleteSnippet, getSnippetDetails, unshareSnippet } from "../../services/snippets";
import { ResponseGenerator } from "../../services/userservice";

function* getSnippetDetailsHelper({ payload }: ReturnType<typeof getSnippetDetailsAction>) {
  const response: ResponseGenerator = yield call(getSnippetDetails, payload.accountId, payload.params);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getSnippetDetailsError(error));
    } else {
      yield put(getSnippetDetailsError("An error has occurred!!"));
    }
  } else {
    yield put(getSnippetDetailsSuccess(response.data));
  }
}

function* deleteSnippetDetailsHelper({payload: { accountId, snippetId, onSuccess}}: any) {
  const response: ResponseGenerator = yield call(deleteSnippet, accountId, snippetId);
  if(has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if(error) {
      yield  put(deleteSnippetDetailsError(error));
    } else {
      yield put(deleteSnippetDetailsError("An error has occurred!!"))
    }
  } else {
    yield put(deleteSnippetDetailsSuccess());

    if (onSuccess) {
      onSuccess();
    }
  }
}

function* unshareSnippetDetailsHelper({
                                        payload,
                                      }: ReturnType<typeof getUnshareSnippetAction>) {
  const response: ResponseGenerator = yield call(
    unshareSnippet,
    payload.accountId,
    payload.snippetUuid,
  );
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteSnippetDetailsError(error));
    } else {
      yield put(deleteSnippetDetailsError("An error has occurred!!"));
    }
  } else {
    yield put(deleteSnippetDetailsSuccess());

    if (payload.onSuccess) {
      payload.onSuccess();
    }
  }
}

export function* watchGetSnippetDetailsHelper() {
  yield takeEvery(GET_SNIPPET_DETAILS, getSnippetDetailsHelper);
}

export function* watchDeleteSnippetDetailsHelper() {
  yield takeEvery(DELETE_SNIPPET_DETAILS, deleteSnippetDetailsHelper)
}

export function* watchUnshareSnippetDetailsHelper() {
  yield takeEvery(UNSHARE_SNIPPET_DETAILS, unshareSnippetDetailsHelper)
}

function* SnippetSaga() {
  yield all([
    fork(watchGetSnippetDetailsHelper),
    fork(watchDeleteSnippetDetailsHelper),
    fork(watchUnshareSnippetDetailsHelper),
  ]);
}

export default SnippetSaga;
