import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import AsyncLoader from './Common/AsyncLoader';
import { History, Location } from 'history';

interface NonAuthLayoutProps {
    location: Location;
    history: History;
}

class NonAuthLayout extends Component <NonAuthLayoutProps>{
    constructor(props: NonAuthLayoutProps) {
        super(props);
        this.state={};
        this.capitalizeFirstLetter.bind(this);
    }
    
    capitalizeFirstLetter = (string: string) => {
        return string.charAt(1).toUpperCase() + string.slice(2);
      };

    componentDidMount(){
        let currentage = this.capitalizeFirstLetter(this.props.location.pathname);

        document.title =
          currentage + " | Meetric";
    }
    render() {
        return <React.Fragment>
            {this.props.children}
            <AsyncLoader />
        </React.Fragment>;
    }
}

export default withRouter<any, any>(NonAuthLayout);