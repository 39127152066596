import { getAssociatedUsersService, ResponseGenerator } from "../../../services/userservice";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import _ from "lodash";
import { getAssociatedUsersSuccess, associatedUsersApiError } from "./actions";
import { GET_ASSOCIATED_USERS } from "./actionTypes";

function* getAssociatedUsers({ payload: { id } }: any) {
  const response: ResponseGenerator = yield call(getAssociatedUsersService, id);
  if (_.has(response, "data")) {
    yield put(getAssociatedUsersSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(associatedUsersApiError(error));
    } else {
      yield put(associatedUsersApiError("An Error has occurred"));
    }
  }
}

export function* watchAssociatedUsers() {
  yield takeEvery(GET_ASSOCIATED_USERS, getAssociatedUsers);
}

function* getAssociatedUsersSaga() {
  yield all([fork(watchAssociatedUsers)]);
}

export default getAssociatedUsersSaga;