import {
  API_ERROR,
  GET_ASSOCIATED_TEAMS,
  GET_ASSOCIATED_TEAMS_SUCCESS,
} from "./actionTypes";
import { AssociatedTeamAction, AssociatedTeamState } from "./interface";

const initialState: AssociatedTeamState = {
  error: "",
  loading: false,
  teams: null,
}

const getAssociatedTeams = (state = initialState, action: AssociatedTeamAction) => {
  switch(action.type) {
    case GET_ASSOCIATED_TEAMS:
      state = {
        ...state,
        loading: true
      }
      break;
    case GET_ASSOCIATED_TEAMS_SUCCESS:
      state = {
        ...state,
        loading: false,
        teams: action.payload
      }
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break;
  }
  return state;
}

export default getAssociatedTeams;