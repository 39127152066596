import React, { Component, RefObject } from "react";

interface InfinitySearchProps {
  onPageChange: (text: string, key?: number) => void;
}

class InfinitySearch extends Component<InfinitySearchProps> {
  private observer: IntersectionObserver | null = null;
  private bottomBoundaryRef: RefObject<HTMLDivElement>;
  public static ADD_SEARCH_LIMIT: 'add';

  constructor(props: InfinitySearchProps) {
    super(props);
    this.bottomBoundaryRef = React.createRef();
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      {
        threshold: 1.0
      }
    );
    if (this.bottomBoundaryRef.current) {
      this.observer.observe(this.bottomBoundaryRef.current);
    }
  }

  componentWillUnmount() {
    if (this.observer && this.bottomBoundaryRef.current) {
      this.observer.unobserve(this.bottomBoundaryRef.current);
    }
  }

  handleObserver(entities: IntersectionObserverEntry[]) {
    const { onPageChange } = this.props;

    const target = entities[0];
    if (target.isIntersecting) {
      onPageChange(InfinitySearch.ADD_SEARCH_LIMIT);
    }
  }

  render() {
    return (
      <div ref={this.bottomBoundaryRef}/>
    )
  }
}

export default InfinitySearch;
