import React, { Component } from "react";
import ChatTopic from "../ChatTopic";
import Messages from "../Messages";
import { IChatInformation } from "../../../../store/interface";
import NewChatMock from "../NewChatMock";

interface ChatComponentProps {
  mockChat: IChatInformation;
}
class ChatComponent extends Component<ChatComponentProps> {
  render() {
    const { mockChat } = this.props;

    return (
      <>
        <ChatTopic />
        {mockChat.uniqueKeyForNewChatMock ? <NewChatMock /> : <Messages />}
      </>
    );
  }
}

export default ChatComponent;
