export const GET_FOLDERS= "GET_FOLDERS";
export const GET_FOLDERS_SUCCESS= "GET_FOLDERS_SUCCESS";
export const API_ERROR = "API_ERROR";
export const UPLOAD_FOLDER= "UPLOAD_FOLDER";
export const UPLOAD_FOLDER_SUCCESS= "UPLOAD_FOLDER_SUCCESS";
export const EDIT_FOLDER= "EDIT_FOLDER";
export const EDIT_FOLDER_SUCCESS= "EDIT_FOLDER_SUCCESS";
export const REMOVE_FOLDER= "REMOVE_FOLDER";
export const REMOVE_FOLDER_SUCCESS= "REMOVE_FOLDER_SUCCESS";
export const CHECK_CUSTOM_FOLDERS= "CHECK_CUSTOM_FOLDERS";
export const GET_CUSTOM_FOLDERS= "GET_CUSTOM_FOLDERS";
export const GET_CUSTOM_FOLDERS_SUCCESS= "GET_CUSTOM_FOLDERS_SUCCESS";
export const MOVE_FOLDER = 'MOVE_FOLDER';
export const MOVE_FOLDER_SUCCESS = 'MOVE_FOLDER_SUCCESS';
export const GET_USER_FOLDERS = 'GET_USER_FOLDERS';