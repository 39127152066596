
export function initBem(block: string) {
  return (element?: string, ...modifiers: string[]) => {
    let className = block;
    if (element){
      className += `__${element}`;
    }
    if (modifiers.length) {
      className = ['', ...modifiers].map(modifier => `${className}--${modifier}`).join(' ');
    }
    return className;
  };
}
