import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

//Account Redux states
import { REGISTER_USER } from './actionTypes';
import { registerUserSuccessful, registerUserFailed } from './actions';

//services
import {registerUser as registerUserService, ResponseGenerator} from '../../../services/userservice'
import _ from 'lodash';

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }: any) {
  const response: ResponseGenerator = yield call(registerUserService, user);
  if (_.has(response, "data")) {
    yield put(registerUserSuccessful("Registered Sucessfully !! Please login to continue .."));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(registerUserFailed(error));
    } else {
      yield put(registerUserFailed("An Error has occured"));
    }
  }
}

export function* watchUserRegister() {
    yield takeEvery(REGISTER_USER, registerUser);
}

function* registerSaga() {
    yield all([fork(watchUserRegister)]);
}

export default registerSaga;