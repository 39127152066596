import React, { Component } from 'react';
import { IAnalyticsUser, IUser } from '../../store/interface';
import { DropdownOption } from '../Meetings/interface';
import noDataAvailable from "../../assets/images/noDataAvailable.png";
import _ from 'lodash';
interface CompareChartProps {
  users: IUser[];
  analyticsData: IAnalyticsUser[];
  shownValuesLabel?: string;
  valueType: string;
  shownValuesType: string;
  formatter?: (value: any) => {};
  limit?: number;
}

interface CompareChartState {
  userOptions: DropdownOption[];
  series: any;
  chartOptions: { name: string, profilePicture?: string }[];
}

class CompareChart extends Component<CompareChartProps, CompareChartState> {
  constructor(props: CompareChartProps) {
    super(props);
    this.state = {
      series: [],
      chartOptions: [],
      userOptions: [],
    }
  };

  roundValueWithOneDecimal = (value: number) => {
    return value.toFixed(1);
  }

  componentDidMount() {
    this.calculateChartValues()
  }

  componentDidUpdate(prevProps: CompareChartProps, prevState: CompareChartState) {
    if (this.props.analyticsData !== prevProps.analyticsData || this.props.shownValuesType !== prevProps.shownValuesType) {
      this.calculateChartValues()
		}
	}

  calculateChartValues = () => {
    const filteredAnalyticsData = this.props.analyticsData?.filter((data) => (data as any)[this.props.shownValuesType] > 1);

    const sortedAnalyticsData = filteredAnalyticsData?.sort((a, b) => {
      return (b as any)[this.props.shownValuesType] - (a as any)[this.props.shownValuesType];
    });

    const chartOptions = sortedAnalyticsData.map((userStats: IAnalyticsUser) => {
      const user: IUser | undefined = _.find(
        this.props.users,
        (user: IUser) => userStats.user_id === user.id
      );

      return {
        name: user
          ? `${user.first_name} ${user.last_name}`
          : `${userStats.user_first_name} ${userStats.user_last_name}`,
        profilePicture: user ? user.profile_picture_url : undefined,
      };
    });

    const seriesData = _.map(
      sortedAnalyticsData,
      (user) =>
        Math.round(((user as any)[this.props.shownValuesType] as number) * 100) / 100
    );

    this.setState({ series: seriesData, chartOptions: chartOptions });
  }

  calculateAverageValue = () => {
    const { series } = this.state;

    let sum: number = 0;
    let numberOfItems: number = 0;

    series.forEach((item: number) => {
      if (item) {
        sum += item;
        numberOfItems += 1;
      }
    });

    return numberOfItems > 0 ? sum / numberOfItems : 0;
  }

  calculateWidth = (value: number) => {
    const onePercentage = this.state.series[0] / 100;

    if (value === 0) {
      return 0.00000001
    }

    return value / onePercentage;
  }

  renderChartBar = () => {
    const { chartOptions, series } = this.state;
    const { limit } = this.props
    const optionsLimit = limit ? Math.min(limit, chartOptions.length) : chartOptions.length;

    let bars: any = [];

    for (let i = 0; i < optionsLimit; i++) {
      const width = this.calculateWidth(series[i]);

      const chartBar = (
        <div className="chart-bar" key={i}>
          <div className="user-image">
            {chartOptions[i].profilePicture
              ? <img
                className="rounded-circle"
                src={chartOptions[i].profilePicture}
                alt="profile-pic"
              /> : <span>
                {chartOptions[i].name.charAt(0)}
              </span>
            }
          </div>
          <div className="bar-value">
            <div className="user-name">{chartOptions[i].name}</div>
            <div className="bar-wrapper">
              <div className="bar-progress" style={{ width: `${width}%` }}>
                {width > 8 && (
                  <span className="tooltiptext">{this.formatValue(series[i])}</span>
                )}
              </div>
              {width <= 8 && series[i] > 0 && (
                  <span className="tooltiptext-black">{this.formatValue(series[i])}</span>
              )}
            </div>

          </div>
        </div>
      );

      bars = [
        ...bars,
        chartBar
      ];
    }

    return bars;
  }

  formatValue = (value: any) => {
    const { formatter } = this.props;

    return formatter ? formatter(value) : this.roundValueWithOneDecimal(value);
  }

  render() {
    const average = this.calculateAverageValue();
    const { shownValuesLabel, valueType } = this.props;
    const { series } = this.state;

    return (
      <React.Fragment>
        <div className={`chart ${average <= 0 && 'empty'}`}>
          <div className="chart-info">
            <div className="bar-color" />
            <div className="chart-value-type">{shownValuesLabel}</div>
          </div>
          <div className="chart-values">
            {average > 0 ? (
              <>
                {this.renderChartBar()}
                {
                  average !== series[0] &&
                  <>
                    <div className="average-value" style={{ left: "calc(" + this.calculateWidth(average) + "%" }}></div>
                    <div
                      className="average-title top"
                      style={{ left: "calc(" + this.calculateWidth(average) + "% - 115px" }}
                    >
                      <div className="light-title">Average </div>
                      <span>{this.formatValue(this.calculateAverageValue())}{valueType}</span>
                    </div>
                  </>
                }
                <div className="thirds-value" style={{ left: "33%" }}></div>
                <div className="thirds-value" style={{ left: "66%" }}></div>
                <div className="thirds-value" style={{ left: "100%" }}></div>
                <div
                  className="average-title"
                  style={{ left: "calc(100% - 115px)" }}
                >
                  <div className="light-title">Top </div>
                  <span>{this.formatValue(series[0])}{valueType}</span>
                </div>
              </>
            ) : (
              <div className="chart-values-empty">
                <div>
                  <img src={noDataAvailable} alt='no-data-img' className={`chart-no-data-img ${average <= 0 && 'no-data-image'}`}/>
                  Not enough data to generate report.
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CompareChart;
