import {
  GET_TELAVOX_TOKEN,
  GET_TELAVOX_TOKEN_SUCCESS,
  GET_TELAVOX_TOKEN_ERROR,
  CREATE_TELAVOX_TOKEN,
  CREATE_TELAVOX_TOKEN_SUCCESS,
  CREATE_TELAVOX_TOKEN_ERROR,
  EDIT_TELAVOX_TOKEN,
  EDIT_TELAVOX_TOKEN_SUCCESS,
  EDIT_TELAVOX_TOKEN_ERROR,
  DELETE_TELAVOX_TOKEN,
  DELETE_TELAVOX_TOKEN_SUCCESS, DELETE_TELAVOX_TOKEN_ERROR,
} from './actionTypes';

import { ITelavoxToken } from './interface';

export const getTelavoxToken = () => ({
  type: GET_TELAVOX_TOKEN,
  payload: null,
});

export const getTelavoxTokenSuccess = (telavoxToken: ITelavoxToken|null) => ({
  type: GET_TELAVOX_TOKEN_SUCCESS,
  payload: telavoxToken,
});

export const getTelavoxTokenError = (error: String | Object) => ({
  type: GET_TELAVOX_TOKEN_ERROR,
  payload: error,
});

export const createTelavoxToken = (telavoxToken: ITelavoxToken) => ({
  type: CREATE_TELAVOX_TOKEN,
  payload: telavoxToken,
});

export const createTelavoxTokenSuccess = (telavoxToken: ITelavoxToken) => ({
  type: CREATE_TELAVOX_TOKEN_SUCCESS,
  payload: telavoxToken,
});

export const createTelavoxTokenError = (error: String | Object) => ({
  type: CREATE_TELAVOX_TOKEN_ERROR,
  payload: error,
});

export const editTelavoxToken = (telavoxToken: ITelavoxToken) => ({
  type: EDIT_TELAVOX_TOKEN,
  payload: telavoxToken,
});

export const editTelavoxTokenSuccess = (telavoxToken: ITelavoxToken) => ({
  type: EDIT_TELAVOX_TOKEN_SUCCESS,
  payload: telavoxToken,
});

export const editTelavoxTokenError = (error: String | Object) => ({
  type: EDIT_TELAVOX_TOKEN_ERROR,
  payload: error,
});

export const deleteTelavoxToken = (id: string) => ({
  type: DELETE_TELAVOX_TOKEN,
  payload: id,
});

export const deleteTelavoxTokenSuccess = () => ({
  type: DELETE_TELAVOX_TOKEN_SUCCESS,
  payload: null,
});

export const deleteTelavoxTokenError = (error: String | Object) => ({
  type: DELETE_TELAVOX_TOKEN_ERROR,
  payload: error,
});
