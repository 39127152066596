import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects";
import {
  CREATE_MEETING,
  GET_MEETINGS,
  GET_MEETINGS_COUNT,
  EDIT_MEETING,
  DELETE_MEETING,
  GET_MEETING_CALENDAR_SYNC_INFO,
} from "./actionTypes";
import {
  getMeetingsError,
  getMeetingsSuccess,
  getCreatedMeeting,
  createMeetingSuccess,
  editMeetingSuccess,
  deleteMeetingSuccess,
  meetingApiError,
  getMeetingsUsingTimeOptionsSuccess,
  getMeetingsCountSuccess,
  getMeetingsCountError,
  getMeetingCalendarSyncInfoSuccess,
  getMeetingCalendarSyncInfoError,
} from "./actions";
import {
  getMeetings as getMeetingsService,
  createNewMeeting as createNewMeetingService,
  editMeeting as editMeetingService,
  deleteMeeting as deleteMeetingService,
  setAccountBranding,
  ResponseGenerator,
  getTotalNumberOfMeetings as getMeetingsTotalService,
  getMeetingCalendarSyncInfo,
} from "../../services/userservice";
import _ from "lodash";
import { AnyAction } from "redux";

function* getMeetings({ payload: { id, data, meetingType, limit, query } }: any) {
  const response: ResponseGenerator = yield call(getMeetingsService, id, data, meetingType, limit, query);
  if (_.has(response, "data")) {
    yield put(getMeetingsSuccess(response.data));
    yield put(getMeetingsUsingTimeOptionsSuccess(meetingType, response.data))
  } else if (response.error || response.errors) {
    yield dispatchErrorAction(response, getMeetingsError);
  }
}

function* getMeetingsCount({ payload: { id, meetingTime, meetingFilters, query } }: any) {
  const response: ResponseGenerator = yield call(getMeetingsTotalService, id, meetingTime, meetingFilters, query);
  if (_.has(response, "count")) {
    yield put(getMeetingsCountSuccess(response.count ? response.count : 0));
    // yield put(getMeetingsUsingTimeOptionsSuccess(meetingType, response.data))
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getMeetingsCountError(error));
    } else if (response.error || response.errors) {
      yield dispatchErrorAction(response, getMeetingsCountError);
    }
  }
}

function* createNewMeetingHelper({ payload: { accountId, meeting, meetingTime } }: any) {
  const response: ResponseGenerator = yield call(createNewMeetingService, accountId, meeting);
  if (_.has(response, "data")) {
    yield put(getCreatedMeeting(response.data));
    const data = {
      background: meeting.background_img,
      logo: meeting.logo_img,
    }
    yield put(createMeetingSuccess("Meeting has been created successfully!"));
    yield put({
      type: "GET_MEETINGS",
      payload: {
        id: response.data.created_by.id,
        data: {},
        meetingType: meetingTime,
        limit: 50,
        query: ""
      },
    });
    yield call(setAccountBranding, response.data.id, data, true);
  } else if (response.error || response.errors) {
    yield dispatchErrorAction(response, meetingApiError);
  }
}

function* editMeetingHelper({ payload: { accountId, meetingId, meeting, meetingTime } }: any) {
  const response: ResponseGenerator = yield call(editMeetingService, accountId, meetingId, meeting);
  if (_.has(response, "data")) {
    yield put(getCreatedMeeting(response.data));
    yield put(editMeetingSuccess("Meeting updated successfully!"));
    yield put({
      type: "GET_MEETINGS",
      payload: {
        id: response.data.created_by.id,
        data: {},
        meetingType: meetingTime,
        limit: 50,
        query: ""
      },
    });
  } else if (response.error || response.errors) {
    yield dispatchErrorAction(response, meetingApiError);
  }
}

function* deleteMeetingHelper({ payload: { accountId, meetingId, meetingTime } }: any) {
  const response: ResponseGenerator = yield call(deleteMeetingService, accountId, meetingId);
  if (_.has(response, "error") || _.has(response, "errors")) {
    yield dispatchErrorAction(response, meetingApiError);
  } else {
    yield put(deleteMeetingSuccess("Meeting deleted successfully!", meetingId, meetingTime));
  }
}

function* getCalendarSyncInfoHelper({ payload: { meetingId } }: any) {
  const response: ResponseGenerator = yield call(getMeetingCalendarSyncInfo, meetingId);
  if (_.has(response, "error") || _.has(response, "errors")) {
    yield dispatchErrorAction(response, getMeetingCalendarSyncInfoError);
  } else {
    yield put(getMeetingCalendarSyncInfoSuccess(response.data));
  }
}

function* dispatchErrorAction(
  response: ResponseGenerator,
  actionFunc: (error: any) => { type: String, payload: String }
) {
  const error = response.error || response.errors;
  if (error) {
    yield put(actionFunc(error));
  } else {
    yield put(actionFunc("An Error has occured!!"));
  }
}

export function* watchGetMeetings() {
  yield takeEvery(GET_MEETINGS, getMeetings);
}

export function* watchGetMeetingsCount() {
  yield takeEvery(GET_MEETINGS_COUNT, getMeetingsCount);
}

export function* watchCreateNewMeeting() {
  yield takeEvery(CREATE_MEETING, createNewMeetingHelper);
}

export function* watchEditMeeting() {
  yield takeEvery(EDIT_MEETING, editMeetingHelper);
}

export function* watchDeleteMeeting() {
  yield takeEvery(DELETE_MEETING, deleteMeetingHelper);
}

export function* watchGetCalendarSyncInfo() {
  yield takeLatest(GET_MEETING_CALENDAR_SYNC_INFO, getCalendarSyncInfoHelper);
}

function* getMeetingsSaga() {
  yield all([
    fork(watchGetMeetings),
    fork(watchGetMeetingsCount),
    fork(watchCreateNewMeeting),
    fork(watchEditMeeting),
    fork(watchDeleteMeeting),
    fork(watchGetCalendarSyncInfo),
  ]);
}

export default getMeetingsSaga;
