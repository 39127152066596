import { CallTokenType } from "../../../../store/CallImports/interface";

export const TokenTypeMap: Record<CallTokenType | string, string> = {
  [CallTokenType.Adversus]: "Adversus",
  [CallTokenType.S2]: "S2",
};

export const CALL_TOKENS = {
  s2: CallTokenType.S2,
  adverus: CallTokenType.Adversus,
};

export const TABLE_TOKEN_SETTINGS_HEADERS = [
  "Campaign Name",
  "Campaign Id",
  "Integration",
  "Min Meeting Length (sec)",
  "30-Days Count",
  "Last Import Date",
  "Created At",
  "Actions",
];

export const TABLE_TOKEN_S2_ADVERSUS_SETTINGS_HEADERS = [
  "Type",
  "Import Calls",
  "Created At",
  "Actions",
];

export const TABLE_HEADER_ADVERSUS_RULES = [
  "Token Id",
  "Min Meeting Length (sec)",
  "Search Value",
  "Rule",
  "Rule Value",
  "Created At",
  "Action",
];

export const MODAL_HEADER_LABELS = {
  EDIT: "Edit call import settings",
  CREATE: "Create new call import settings",
};

export const S2_ADVERSUS_MODAL_HEADER_LABELS = {
  EDIT: "Edit S2/Adversus settings",
  CREATE: "Create new S2/Adversus settings",
};

export interface TokenDropdownOption {
  label: string;
  value: string;
  id: string;
}

export interface IAdversusRule {
  id: string;
  tokenId: string;
  searchField: SearchField;
  rule: Rule;
  searchValue: string;
  createdAt: string;
  minLength: number;
}

export enum SearchField {
  CampaignName = 'campaign_name',
  CampaignId = 'campaign_id',
}

export enum Rule {
  Exact = 'exact',
  Range = 'range',
  StartsWith = 'starts_with',
  EndsWith = 'ends_with',
  Contains = 'contains',
}

export interface ICreateAdversusRule {
  tokenId: string;
  searchField: SearchField;
  searchValue: string;
  rule: Rule;
  minLength: number;
}

interface DropdownOption {
  label: string;
  value: Rule;
  id: string;
}

export type SelectOption = {
  value: string;
  label: string;
};

export type ActionMeta = {
  action: string;
  name?: string;
  option?: SelectOption;
};

export const companyNameRuleOptions: DropdownOption[] = [
  { label: "Exact", value: Rule.Exact, id: "exact" },
  { label: "Starts With", value: Rule.StartsWith, id: "starts_with" },
  { label: "Ends With", value: Rule.EndsWith, id: "ends_with" },
  { label: "Contains", value: Rule.Contains, id: "contains" },
];

export const companyIdRuleOptions: DropdownOption[] = [
  { label: "Range", value: Rule.Range, id: "range" },
];

export const searchFieldOptions = [
  { value: SearchField.CampaignName, label: 'Campaign Name' },
  { value: SearchField.CampaignId, label: 'Campaign ID' },
];

export const ADVERSUS_MODAL_HEADER_LABELS = {
  EDIT: "Edit Adversus import rule",
  CREATE: "Create new Adversus import rule",
};

function mapToEnumValue<T extends object>(enumObj: T, value: string): T[keyof T] {
  const keys = Object.keys(enumObj) as Array<keyof T>;
  for (const key of keys) {
    if ((enumObj[key] as unknown) === value) {
      return enumObj[key];
    }
  }
  throw new Error(`Value "${value}" is not part of the enum`);
}

export function mapAdversusRule(data: any): IAdversusRule {
  return {
    id: data.id,
    tokenId: data.token_id,
    searchField: mapToEnumValue(SearchField, data.search_field),
    rule: mapToEnumValue(Rule, data.rule),
    searchValue: data.search_value,
    createdAt: data.created_at,
    minLength: data.min_length,
  };
}
