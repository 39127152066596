import axios from 'axios';
import { DealOptionsData } from '../../pages/Meetings/interface';
import { endpoints } from "../../utilities/endpoints";
import { urls } from "../../utilities/urls";
import { commonheaders } from '../../utilities/apiConfig';

export async function getDealsByAccountAndReceivers(data: DealOptionsData) {
    const {
      receiver_ids,
      account_id
    } = data;

    try {
      const response = await axios.get(
        `${urls.baseApiURL}${endpoints.accounts}/${account_id}/${endpoints.receivers}/${endpoints.deals}`,
        {
          ...commonheaders,
          params: {
            receiver_ids: receiver_ids
          },
        }
      );

      return response.data[0];
    } catch (error: any) {
      if (error.response) {
        return error.response.data;
      }
      return error.message;
    }
  }

export async function applyDealToReceiversOnMeetings(account_id: String, deal_id: String | null, session_id: String, is_update_all_meeting_deals_for_receiver: boolean) {

    try {
        const response = await axios.put(
        `${urls.baseApiURL}${endpoints.accounts}/${account_id}/${endpoints.deals}/${endpoints.update_meeting}/${session_id}`,
        {
            ...commonheaders,
            is_update_all_meeting_deals_for_receiver: is_update_all_meeting_deals_for_receiver,
            deal_id: deal_id
        }
        );

        return response.data.data;
    } catch (error: any) {
        if (error.response) {
        return error.response.data;
        }
        return error.message;
    }
}