import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';

import CreatingSnippetMessage from './CreatingSnippetMessage';
import loaderImg from '../../assets/images/loader.gif';
import pitchflowLogosmall from '../../assets/images/logo-small.svg';
import pitchflowLogo from '../../assets/images/pitchflow_logo_white_trans.svg';
import VideoPlayer from '../../components/Recording/VideoPlayer';
import { loginShareLink } from '../../store/auth/login/actions';
import {
  getSnippet,
  getSnippetRecordingTranscript,
  getSnippetShareLinkByLinkHash,
  getSnippetTimeLine,
} from '../../store/Analytics/actions';
import {
  IAnalyticsMeetingTimeline,
  IAnalyticsRecordings,
  ISnippet, ITranscript
} from '../../store/Analytics/interface';
import { changeSidebarTheme } from '../../store/layout/actions';
import Navbar from '../../components/HorizontalLayout/Navbar';
import AccessDenied from "./AccessDeniedPage";

const ACCESS_DENIED = {
  title: "You don't have access to this snippet",
  description: "Please ask the snippet owner for a shared link",
}

interface ISnippetShareLink {
  created_at: Date;
  emails: string[];
  expires_at: Date;
  id: number;
  include_enriched_timeline: boolean;
  link_hash: string;
  name: string;
  owner: Object;
  snippet: ISnippet;
  users: Object[];
}

interface SharedSnippetPageProps {
  match: any;
  leftSideBarTheme: String;
  snippetShareLink: null | ISnippetShareLink;
  snippet: ISnippet,
  timeLineData: IAnalyticsMeetingTimeline[];
  recordingTranscript: ITranscript | null;
  loading: boolean,
  snippetShareLinkError?: string | null;
  loginShareLink: (shareLink: string, onSuccess: any) => void;
  getSnippetShareLinkByLinkHash: (linkHash: string, onSuccess: any) => void;
  getSnippet: (id: String, params?: String) => void;
  getSnippetTimeLine: (accountId: String, snippetUuid: String) => void;
  getSnippetRecordingTranscript: (snippetUuid: String) => void;
  changeSidebarTheme: (type: String) => void;
}

class SharedSnippetPage extends Component<SharedSnippetPageProps, {}> {
  snippetLoaded: boolean = false;

  constructor(props: SharedSnippetPageProps) {
    super(props);

    const {
      match,
      leftSideBarTheme,
      loginShareLink: loginShareLinkAction,
      getSnippetShareLinkByLinkHash: getSnippetShareLinkByLinkHashAction,
      changeSidebarTheme: changeSidebarThemeAction,
    } = this.props;

    const linkHash = match.params.linkHash;

    if (leftSideBarTheme) {
      changeSidebarThemeAction(leftSideBarTheme);
    }
    loginShareLinkAction(linkHash, () => {
      getSnippetShareLinkByLinkHashAction(linkHash, () => { });
    });
  }

  componentDidMount() {
    document.body.setAttribute('data-layout', 'horizontal');

    const { snippetShareLink, getSnippet: getSnippetAction } = this.props;

    if (snippetShareLink) {
      this.snippetLoaded = true;
      getSnippetAction(snippetShareLink.snippet.uuid);
    }
  }

  componentDidUpdate() {
    const { snippetShareLink, getSnippet: getSnippetAction } = this.props;

    if (
      snippetShareLink
      && !this.snippetLoaded
    ) {
      this.snippetLoaded = true;
      getSnippetAction(snippetShareLink.snippet.uuid);
    }
  }

  renderSideBar() {
    return (
      <>
        <header id="page-topbar" className="custome-horizontal-header">
          <div className="navbar-header">
            <div className="d-flex w-100">
              <div className="navbar-brand-box custome-box logo-box">
                <div
                  className="logo logo-light"
                >
                  <span className="logo-sm">
                    <img
                      style={{ width: "26px", height: "auto" }}
                      src={pitchflowLogosmall}
                      alt="small logo"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      style={{ width: "140px", height: "auto" }}
                      src={pitchflowLogo}
                      alt="big logo"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="vertical-menu custome-vertical-menu">
          <div data-simplebar className="h-100 full-sidebar">
            <div id="sidebar-menu" className={`sidebar-default responsive-menu`}>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const {
      snippet,
      snippetShareLink,
      snippetShareLinkError,
      timeLineData,
      recordingTranscript,
      getSnippetTimeLine: getSnippetTimeLineAction,
      getSnippetRecordingTranscript: getSnippetRecordingTranscriptAction,
    } = this.props;

    const recording: IAnalyticsRecordings = {
      url: snippet?.status === "ready" ? snippet.url : "",
      screenshots: null
    }

    const content = snippetShareLink
      ? (
        snippetShareLink.snippet.status !== 'pending' ?
          <VideoPlayer
            sessionId={snippetShareLink.snippet.uuid}
            recordingSessionId={snippetShareLink.snippet.recording.session_id}
            accountId={snippetShareLink.snippet.recording.account.id}
            currentuser={null}
            recording={recording}
            recordingLoader={snippetShareLink.snippet.status === 'ready'}
            showComments={false}
            showSnippets={false}
            showAddSnippet={false}
            showTranscription={true}
            getRecordingTranscript={getSnippetRecordingTranscriptAction}
            recordingTranscript={recordingTranscript}
            getTimeLine={getSnippetTimeLineAction}
            timeLineData={timeLineData}
            snippets={[]}
            snippetPostSuccess={""}
            isSuperAdmin={false}
          /> :
          <CreatingSnippetMessage />
      ) :
        snippetShareLinkError ?
          <AccessDenied title={ACCESS_DENIED.title} description={ACCESS_DENIED.description}/>
          : (
          <Row className="metting-main m-0 pt-4 pb-0">
            <Container className="text-center">
              <div className="loader-wrapper">
                <img src={loaderImg} alt="" />
              </div>
            </Container>
          </Row>
          );

    return (
      <>
        <div id="layout-wrapper d-flex flex-column">
          <Navbar menuOpen={true} showContent={false}></Navbar>
          <div className="main-content">
            {content}
          </div>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state: any) => {
  const {
    leftSideBarTheme,
  } = state.Layout;
  const {
    snippet,
    snippetShareLink,
    snippetShareLinkError,
    timeLineData,
    recordingTranscript,
    loading,
  } = state.SalesAnalytics;

  return {
    snippet,
    snippetShareLink,
    snippetShareLinkError,
    timeLineData,
    recordingTranscript,
    loading,
    leftSideBarTheme,
  };
};

export default connect(mapStateToProps, {
  loginShareLink,
  getSnippetShareLinkByLinkHash,
  getSnippet,
  getSnippetTimeLine,
  getSnippetRecordingTranscript,
  changeSidebarTheme,
})(SharedSnippetPage);
