import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { has } from "lodash";

import {
  deleteCommentDetailsError,
  deleteCommentDetailsSuccess,
  getCommentDetails as getCommentDetailsAction,
  getCommentDetailsError,
  getCommentDetailsSuccess,
} from "./actions";

import { DELETE_COMMENT_DETAILS, GET_COMMENT_DETAILS, } from "./actionTypes";

import { deleteComment, getCommentDetails } from "../../services/comments";
import { ResponseGenerator } from "../../services/userservice";

function* getCommentDetailsHelper(payload: ReturnType<typeof getCommentDetailsAction>) {
  const response: ResponseGenerator = yield call(getCommentDetails, payload.payload, payload.globalFilterString);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getCommentDetailsError(error));
    } else {
      yield put(getCommentDetailsError("An error has occurred!!"));
    }
  } else {
    yield put(getCommentDetailsSuccess(response.data));
  }
}

function* deleteCommentDetailsHelper({ payload: { commentId } }: any) {
  const response: ResponseGenerator = yield call(deleteComment, commentId);
  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteCommentDetailsError(error))
    } else {
      yield put(deleteCommentDetailsError("An error has occurred!!"))
    }
  } else {
    yield put(deleteCommentDetailsSuccess());
  }
}

export function* watchGetCommentDetailsHelper() {
  yield takeEvery(GET_COMMENT_DETAILS, getCommentDetailsHelper);
}

export function* watchDeleteCommentDetailsHelper() {
  yield takeEvery(DELETE_COMMENT_DETAILS, deleteCommentDetailsHelper)
}

function* CommentSaga() {
  yield all([
    fork(watchGetCommentDetailsHelper),
    fork(watchDeleteCommentDetailsHelper),
  ]);
}

export default CommentSaga;
