import React from "react";

import CloseButton from "../../../CloseButton";

interface ChatModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const ChatModal: React.FC<ChatModalProps> = ({
  isOpen,
  children,
  handleClose,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="custom-modal-overlay">
      <div className="custom-modal">
        <div className="custom-modal-header">
          <CloseButton handleClose={handleClose} />
        </div>
        <div className="custom-modal-content">{children}</div>
      </div>
    </div>
  );
};

export default ChatModal;
