export const TABLE_CALENDAR_HEADERS = [
  "Snippet name",
  "Snippet desc",
  "Parent meeting",
  "Start time",
  "End time",
  "Created at",
  "Snippet owner",
  "Shared",
  "Action",
];
