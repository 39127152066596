import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  CREATE_NEW_RESOURCE,
  GET_RESOURCES,
  EDIT_RESOURCE,
  DELETE_RESOURCE,
} from "./actionTypes";
import {
  getResourcesError,
  getResourcesSuccess,
  getResources as getResourcesAction,
  createResourceSuccess,
  editResourceSuccess,
  deleteResourceSuccess,
  resourceApiError,
} from "./actions";
import {
  getResources as getResourcesService,
  createNewResource as createNewResourceService,
  editResource as editResourceService,
  deleteResource as deleteResourceService,
} from "../../../services/userservice";
import _ from "lodash";
import { ResponseGenerator } from "../../../services/userservice";

function* getResources({ payload: { id } }: any) {
  const response: ResponseGenerator = yield call(getResourcesService, id);
  if (_.has(response, "data")) {
    yield put(getResourcesSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getResourcesError(error));
    } else {
      yield put(getResourcesError("An Error has occured"));
    }
  }
}

function* createNewResourceHelper({ payload: { accountId, resource} }: any) {
  const response: ResponseGenerator = yield call(createNewResourceService, accountId, resource);
  if (_.has(response, "data")) {
    yield put(getResourcesAction(accountId));
    yield put(createResourceSuccess("Resource has been created successfully!"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getResourcesError(error));
    } else {
      yield put(getResourcesError("An Error has occured"));
    }
  }
}

function* editResourceHelper({ payload: { accountId, resourceId, resource } }: any) {
  const response: ResponseGenerator = yield call(editResourceService, accountId, resourceId, resource);
  if (_.has(response, "data")) {
    yield put(editResourceSuccess("Resource updated successfully!"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(resourceApiError(error));
    } else {
      yield put(resourceApiError("An Error has occured"));
    }
  }
}

function* deleteResourceHelper({ payload: { accountId, resourceId } }: any) {
  const response: ResponseGenerator = yield call(deleteResourceService, accountId, resourceId);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(resourceApiError(error));
    } else {
      yield put(resourceApiError("An Error has occured!!"));
    }
  } else {
    yield put(deleteResourceSuccess("Resource deleted successfully!"));
  }
}

export function* watchGetResources() {
  yield takeEvery(GET_RESOURCES, getResources);
}

export function* watchCreateNewResource() {
  yield takeEvery(CREATE_NEW_RESOURCE, createNewResourceHelper);
}

export function* watchEditResource() {
  yield takeEvery(EDIT_RESOURCE, editResourceHelper);
}

export function* watchDeleteResource() {
  yield takeEvery(DELETE_RESOURCE, deleteResourceHelper);
}

function* getResourcesSaga() {
  yield all([
    fork(watchGetResources),
    fork(watchCreateNewResource),
    fork(watchEditResource),
    fork(watchDeleteResource),
  ]);
}

export default getResourcesSaga;
