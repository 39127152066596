import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  GET_USER_PROFILE,
  CHANGE_CURRENT_ACCOUNT,
  CHANGE_MULTIPLE_ACCOUNT_STATE,
  UPDATE_CURRENT_ACCOUNT,
  UPDATE_LYNES_INTEGRATION,
  UPDATE_TELENOR_INTEGRATION,
  UPDATE_TELAVOX_INTEGRATION,
} from "./actionTypes";
import { Icurrentaccountselected } from "./interface";

export const editProfile = (user: Object) => ({
  type: EDIT_PROFILE,
  payload: { user },
});

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
  payload: null,
});

export const profileSuccess = (
  user: Object,
  hasMultipleAccounts?: Boolean
) => ({
  type: PROFILE_SUCCESS,
  payload: { user, hasMultipleAccounts },
});

export const changeMultipleAccountState = (hasMultipleAccounts: Boolean) => ({
  type: CHANGE_MULTIPLE_ACCOUNT_STATE,
  payload: { hasMultipleAccounts },
});

export const profileError = (error: Object | String) => ({
  type: PROFILE_ERROR,
  payload: error,
});

export const changeCurrentAccount = (account: Object) => ({
  type: CHANGE_CURRENT_ACCOUNT,
  payload: account,
});

export const updateCurrentAccount = (account: Icurrentaccountselected) => ({
  type: UPDATE_CURRENT_ACCOUNT,
  payload: account
});

export const updateLynesIntegration = (lynes_min_duration: number | undefined) => ({
  type: UPDATE_LYNES_INTEGRATION,
  payload: lynes_min_duration,
});

export const updateTelenorIntegration = (telenor_min_duration: number | undefined) => ({
  type: UPDATE_TELENOR_INTEGRATION,
  payload: telenor_min_duration,
});

export const updateTelavoxIntegration = (telavox_min_duration: number | undefined) => ({
  type: UPDATE_TELAVOX_INTEGRATION,
  payload: telavox_min_duration,
});
