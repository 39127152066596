export const GET_BRANDING = "GET_BRANDING";
export const GET_BRANDING_ERROR = "GET_BRANDING_ERROR";
export const GET_BRANDING_SUCCESS = "GET_BRANDING_SUCCESS";
export const CREATE_BRANDING = "CREATE_BRANDING";
export const CREATE_BRANDING_SUCCESS = "CREATE_BRANDING_SUCCESS";
export const EDIT_BRANDING = "EDIT_BRANDING";
export const EDIT_BRANDING_SUCCESS = "EDIT_BRANDING_SUCCESS";
export const DELETE_BRANDING = 'DELETE_BRANDING';
export const DELETE_BRANDING_SUCCESS = 'DELETE_BRANDING_SUCCESS';
export const GET_CREATED_BRANDING = 'GET_CREATED_BRANDING';
export const API_ERROR = "API_ERROR";