import {
  GET_FILES,
  GET_FILES_SUCCESS,
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  EDIT_FILE,
  EDIT_FILE_SUCCESS,
  API_ERROR,
} from "./actionTypes";
import { FileAction, FileState } from "./interface";

const initialState: FileState = {
  error: "",
  success: "",
  deletesuccess: "",
  loader: false,
  removeItemId: null,
  filesObj: null,
};

const files = (state = initialState, action: FileAction) => {
  switch (action.type) {
    case GET_FILES:
      state = {
        ...state,
        error: "",
        filesObj: null,
        loader: true,
      };
      break;
    case GET_FILES_SUCCESS:
      state = {
        ...state,
        filesObj: action.payload,
        loader: false,
      };
      break;
    case DELETE_FILE:
      state = {
        ...state,
        error: "",
        deletesuccess: "",
        loader: true,
      };
      break;
    case DELETE_FILE_SUCCESS:
      state = {
        ...state,
        deletesuccess: action.payload.mes,
        removeItemId: action.payload.deleteItemId,
        loader: false,
      };
      break;
    case EDIT_FILE:
      state = {
        ...state,
        error: "",
        success: "",
        loader: true,
      };
      break;
    case EDIT_FILE_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        loader: false,
      };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loader: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default files;
