import {
  CREATE_RIGHT,
  CREATE_RIGHT_SUCCESS,
  CREATE_RIGHT_ERROR,
  UPDATE_RIGHT,
  UPDATE_RIGHT_SUCCESS,
  UPDATE_RIGHT_ERROR,
  REMOVE_RIGHTS_ERROR,
  GET_RIGHT,
  GET_RIGHT_SUCCESS,
  GET_RIGHT_ERROR,
  DELETE_RIGHT,
  DELETE_RIGHT_SUCCESS,
  DELETE_RIGHT_ERROR,
  CREATE_RIGHTS,
  CREATE_RIGHTS_SUCCESS,
  GET_RIGHTS_SUCCESS,
  GET_RIGHTS,
  UPDATE_RIGHTS_SUCCESS,
  UPDATE_RIGHTS, REMOVE_RIGHTS, REMOVE_RIGHTS_SUCCESS
} from "./actionTypes";

export const createRight = (object: Object) => {
  return {
    type: CREATE_RIGHT,
    payload: { object },
  };
};

export const createRightSuccess = (mes: String) => {
  return {
    type: CREATE_RIGHT_SUCCESS,
    payload: mes,
  };
};

export const createRightError = (mes: String) => {
  return {
    type: CREATE_RIGHT_ERROR,
    payload: mes,
  };
};

export const updateRight = (object: Object, id: String) => {
  return {
    type: UPDATE_RIGHT,
    payload: { object, id },
  };
};

export const updateRightSuccess = (mes: String) => {
  return {
    type: UPDATE_RIGHT_SUCCESS,
    payload: mes,
  };
};

export const updateRightError = (mes: String) => {
  return {
    type: UPDATE_RIGHT_ERROR,
    payload: mes,
  };
};

export const removeRightError = (mes: String) => {
      return {
    type: REMOVE_RIGHTS_ERROR,
    payload: mes,
  };
};

export const getRight = (id: String) => {
  return {
    type: GET_RIGHT,
    payload: { id },
  };
};

export const getRightSuccess = (data: Object) => {
  return {
    type: GET_RIGHT_SUCCESS,
    payload: data,
  };
};

export const getRightError = (mes: String) => {
  return {
    type: GET_RIGHT_ERROR,
    payload: mes,
  };
};

export const deleteRight = (id: String) => {
  return {
    type: DELETE_RIGHT,
    payload: { id },
  };
};

export const deleteRightSuccess = (mes: String) => {
  return {
    type: DELETE_RIGHT_SUCCESS,
    payload: mes,
  };
};

export const deleteRightError = (mes: String) => {
  return {
    type: DELETE_RIGHT_ERROR,
    payload: mes,
  };
};
export const createRights = (object: Object) => {
  return {
    type: CREATE_RIGHTS,
    payload: { object },
  };
};

export const createRightsSuccess = (mes: String) => {
  return {
    type: CREATE_RIGHTS_SUCCESS,
    payload: mes,
  };
};
export const getRights = (id: String, selectPresentationId: string, type: string,) => {
  return {
    type: GET_RIGHTS,
    payload: { id, selectPresentationId, type }
  };
};
export const getRightsSuccess = (data: Object) => {
  return {
    type: GET_RIGHTS_SUCCESS,
    payload: data,
  };
};
export const updateRights = (object: Object, id: String) => {
  return {
    type: UPDATE_RIGHTS,
    payload: { object, id },
  };
};
export const updateRightsSuccess = (mes: String) => {
  return {
    type: UPDATE_RIGHTS_SUCCESS,
    payload: mes,
  };
};
export const removeRights = (id: String, right_type: String) => {
  return {
    type: REMOVE_RIGHTS,
    payload: {  id , right_type},
  };
};
export const removeRightsSuccess = (mes: String) => {
  return {
    type: REMOVE_RIGHTS_SUCCESS,
    payload: mes,
  };
};