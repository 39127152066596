import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  CREATE_BRANDING,
  GET_BRANDING,
  EDIT_BRANDING,
  DELETE_BRANDING,
} from "./actionTypes";
import {
  getBrandingError,
  getBrandingSuccess,
  getCreatedBranding,
  createBrandingSuccess,
  editBrandingSuccess,
  deleteBrandingSuccess,
  brandingApiError,
} from "./actions";
import {
  getBranding as getBrandingService,
  createNewBranding as createNewBrandingService,
  editBranding as editBrandingService,
  deleteBranding as deleteBrandingService,
  setAccountBranding,
  ResponseGenerator,
} from "../../../services/userservice";
import _ from "lodash";

function* getBranding({ payload: { id } }: any) {
  const response: ResponseGenerator = yield  call(getBrandingService, id);
  if (_.has(response, "data")) {
    yield put(getBrandingSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getBrandingError(error));
    } else {
      yield put(getBrandingError("An Error has occured"));
    }
  }
}

function* createNewBrandingHelper({ payload: { accountId, branding} }: any) {
  const response: ResponseGenerator = yield  call(createNewBrandingService, accountId, branding);
  if (_.has(response, "data")) {
    yield put(getCreatedBranding(response.data));
    const data = {
      background: branding.background_img,
      logo: branding.logo_img,
    }
    yield call(setAccountBranding, response.data.id, data, false);
    yield put(createBrandingSuccess("Branding has been created successfully!"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(brandingApiError(error));
    } else {
      yield put(brandingApiError("An Error has occured"));
    }
  }
}

function* editBrandingHelper({ payload: { accountId, brandingId, branding } }: any) {
  const response: ResponseGenerator = yield  call(editBrandingService, accountId, brandingId, branding);
  if (_.has(response, "data")) {
    yield put(getCreatedBranding(response.data));
    yield put(editBrandingSuccess("Branding updated successfully!"));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(brandingApiError(error));
    } else {
      yield put(brandingApiError("An Error has occured"));
    }
  }
}

function* deleteBrandingHelper({ payload: { accountId, brandingId } }: any) {
  const response: ResponseGenerator = yield  call(deleteBrandingService, accountId, brandingId);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(brandingApiError(error));
    } else {
      yield put(brandingApiError("An Error has occured!!"));
    }
  } else {
    yield put(deleteBrandingSuccess("Branding deleted successfully!"));
  }
}

export function* watchGetBranding() {
  yield takeEvery(GET_BRANDING, getBranding);
}

export function* watchCreateNewBranding() {
  yield takeEvery(CREATE_BRANDING, createNewBrandingHelper);
}

export function* watchEditBranding() {
  yield takeEvery(EDIT_BRANDING, editBrandingHelper);
}

export function* watchDeleteBranding() {
  yield takeEvery(DELETE_BRANDING, deleteBrandingHelper);
}

function* brandingSaga() {
  yield all([
    fork(watchGetBranding),
    fork(watchCreateNewBranding),
    fork(watchEditBranding),
    fork(watchDeleteBranding),
  ]);
}

export default brandingSaga;
