export const GET_STATS_USER = 'GET_STATS_USER';
export const GET_STATS_USER_SUCCESS = 'GET_STATS_USER_SUCCESS';
export const GET_STATS_USER_ERROR = 'GET_STATS_USER_ERROR';
export const GET_STATS_USER_DAILY_SUCCESS = 'GET_STATS_USER_DAILY_SUCCESS';
export const GET_STATS_USER_DAILY = 'GET_STATS_USER_DAILY';
export const GET_STATS_TEAM = 'GET_STATS_TEAM';
export const GET_STATS_TEAM_SUCCESS = 'GET_STATS_TEAM_SUCCESS';
export const GET_STATS_TEAM_ERROR = 'GET_STATS_TEAM_ERROR';
export const GET_STATS_TEAM_DAILY = 'GET_STATS_TEAM_DAILY';
export const GET_STATS_TEAM_DAILY_SUCCESS = 'GET_STATS_TEAM_DAILY_SUCCESS';
export const GET_STATS_MEETINGS = 'GET_STATS_MEETINGS';
export const GET_STATS_MEETINGS_SUCCESS = 'GET_STATS_MEETINGS_SUCCESS';
export const GET_STATS_MEETINGS_ERROR = 'GET_STATS_MEETINGS_ERROR';
export const GET_STATS_MEETINGS_COUNT = 'GET_STATS_MEETINGS_COUNT';
export const GET_STATS_MEETINGS_COUNT_SUCCESS = 'GET_STATS_MEETINGS_COUNT_SUCCESS';
export const GET_STATS_MEETINGS_COUNT_ERROR = 'GET_STATS_MEETINGS_COUNT_ERROR';
export const GET_STATS_MEETINGS_DAILY = 'GET_STATS_MEETINGS_DAILY';
export const GET_STATS_MEETINGS_DAILY_SUCCESS = 'GET_STATS_MEETINGS_DAILY_SUCCESS';
export const GET_STATS_MEETINGS_LIVE = 'GET_STATS_MEETINGS_LIVE';
export const GET_STATS_MEETINGS_LIVE_SUCCESS = 'GET_STATS_MEETINGS_LIVE_SUCCESS';
export const GET_STATS_MEETINGS_LIVE_ERROR = 'GET_STATS_MEETINGS_LIVE_ERROR';
export const GET_STATS_INTERACTIONS = 'GET_STATS_INTERACTIONS';
export const GET_STATS_INTERACTIONS_SUCCESS = 'GET_STATS_INTERACTIONS_SUCCESS';
export const GET_STATS_INTERACTIONS_DAILY = 'GET_STATS_INTERACTIONS_DAILY';
export const GET_STATS_INTERACTIONS_DAILY_SUCCESS = 'GET_STATS_INTERACTIONS_DAILY_SUCCESS';
export const GET_STATS_INTERACTIONS_ERROR = 'GET_STATS_INTERACTIONS_ERROR';
export const GET_STATS_PARTICIPANTS_ERROR = 'GET_STATS_PARTICIPANTS_ERROR';
export const GET_STATS_PARTICIPANTS_SUCCESS = 'GET_STATS_PARTICIPANTS_SUCCESS';
export const GET_STATS_PARTICIPANTS_DAILY = 'GET_STATS_PARTICIPANTS_DAILY';
export const GET_STATS_PARTICIPANTS_DAILY_SUCCESS = 'GET_STATS_PARTICIPANTS_DAILY_SUCCESS';
export const GET_STATS_PARTICIPANTS = 'GET_STATS_PARTICIPANTS';
export const GET_STATS_MEMBERS_ERROR = 'GET_STATS_MEMBERS_ERROR';
export const GET_STATS_MEMBERS_SUCCESS = 'GET_STATS_MEMBERS_SUCCESS';
export const GET_STATS_MEMBERS_DAILY = 'GET_STATS_MEMBERS_DAILY';
export const GET_STATS_MEMBERS_DAILY_SUCCESS = 'GET_STATS_MEMBERS_DAILY_SUCCESS';
export const GET_STATS_MEMBERS = 'GET_STATS_MEMBERS';
export const GET_STATS_VIEWS_ERROR = 'GET_STATS_VIEWS_ERROR';
export const GET_STATS_VIEWS_SUCCESS = 'GET_STATS_VIEWS_SUCCESS';
export const GET_STATS_VIEWS = 'GET_STATS_VIEWS';
export const GET_STATS_SLIDES_ERROR = 'GET_STATS_SLIDES_ERROR';
export const GET_STATS_SLIDES_SUCCESS = 'GET_STATS_SLIDES_SUCCESS';
export const GET_STATS_SLIDES_DAILY = 'GET_STATS_SLIDES_DAILY';
export const GET_STATS_SLIDES_DAILY_SUCCESS = 'GET_STATS_SLIDES_DAILY_SUCCESS';
export const GET_STATS_SLIDES = 'GET_STATS_SLIDES';
export const GET_STATS_PRESENTATIONS_ERROR = 'GET_STATS_PRESENTATIONS_ERROR';
export const GET_STATS_PRESENTATIONS_SUCCESS = 'GET_STATS_PRESENTATIONS_SUCCESS';
export const GET_STATS_PRESENTATIONS_DAILY = 'GET_STATS_PRESENTATIONS_DAILY';
export const GET_STATS_PRESENTATIONS_DAILY_SUCCESS = 'GET_STATS_PRESENTATIONS_DAILY_SUCCESS';
export const GET_STATS_PRESENTATIONS = 'GET_STATS_PRESENTATIONS';
export const GET_STATS_SHARED_LINKS_DAILY = 'GET_STATS_SHARED_LINKS_DAILY';
export const GET_STATS_SHARED_LINKS_DAILY_SUCCESS = 'GET_STATS_SHARED_LINKS_DAILY_SUCCESS';
export const GET_STATS_SHARED_LINKS_ERROR = 'GET_STATS_SHARED_LINKS_ERROR';
export const GET_STATS_SHARED_LINKS = 'GET_STATS_SHARED_LINKS';
export const GET_STATS_SHARED_LINKS_SUCCESS = 'GET_STATS_SHARED_LINKS_SUCCESS';
export const GET_STATS_ROOM_SESSIONS = 'GET_STATS_ROOM_SESSIONS';
export const GET_STATS_ROOM_SESSIONS_SUCCESS = 'GET_STATS_ROOM_SESSIONS_SUCCESS';
export const GET_STATS_ROOM_SESSIONS_ERROR = 'GET_STATS_ROOM_SESSIONS_ERROR';
export const GET_STATS_ROOM_SESSIONS_DAILY = 'GET_STATS_ROOM_SESSIONS_DAILY';
export const GET_STATS_ROOM_SESSIONS_DAILY_SUCCESS = 'GET_STATS_ROOM_SESSIONS_DAILY_SUCCESS';
export const DELETE_SESSION = 'DELETE_SESSION';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_ERROR = 'DELETE_SESSION_ERROR';
export const GET_STATS_DASHBOARD = 'GET_STATS_DASHBOARD';
export const GET_STATS_DASHBOARD_SUCCESS = 'GET_STATS_DASHBOARD_SUCCESS';
export const GET_STATS_DASHBOARD_DAILY = 'GET_STATS_DASHBOARD_DAILY';
export const GET_STATS_DASHBOARD_DAILY_SUCCESS = 'GET_STATS_DASHBOARD_DAILY_SUCCESS';
export const GET_STATS_DASHBOARD_ERROR = 'GET_STATS_DASHBOARD_ERROR;'
export const GET_STATS_RECORDINGS = 'GET_STATS_RECORDINGS;'
export const GET_STATS_RECORDINGS_SUCCESS = 'GET_STATS_RECORDINGS_SUCCESS;'
export const GET_STATS_RECORDINGS_ERROR = 'GET_STATS_RECORDINGS_ERROR;'
export const DELETE_STATS_RECORDINGS = 'DELETE_STATS_RECORDINGS;'
export const DELETE_STATS_RECORDINGS_SUCCESS = 'DELETE_STATS_RECORDINGS_SUCCESS;'
export const DELETE_STATS_RECORDINGS_ERROR = 'DELETE_STATS_RECORDINGS_ERROR;'
export const GET_TIME_LINE = 'GET_TIME_LINE';
export const GET_TIME_LINE_SUCCESS = 'GET_TIME_LINE_SUCCESS';
export const GET_TIME_LINE_ERROR = 'GET_TIME_LINE_ERROR';
export const GET_TIME_LINE_COMMENTS = 'GET_TIME_LINE_COMMENTS';
export const GET_TIME_LINE_COMMENTS_SUCCESS = 'GET_TIME_LINE_COMMENTS_SUCCESS';
export const GET_TIME_LINE_COMMENTS_ERROR = 'GET_TIME_LINE_COMMENTS_ERROR';
export const ADD_TIME_LINE_COMMENT = 'ADD_TIME_LINE_COMMENT';
export const ADD_TIME_LINE_COMMENT_SUCCESS = 'ADD_TIME_LINE_COMMENT_SUCCESS';
export const ADD_TIME_LINE_COMMENT_ERROR = 'ADD_TIME_LINE_COMMENT_ERROR';
export const DELETE_TIME_LINE_COMMENT = 'DELETE_TIME_LINE_COMMENT';
export const DELETE_TIME_LINE_COMMENT_SUCCESS = 'DELETE_TIME_LINE_COMMENT_SUCCESS';
export const DELETE_TIME_LINE_COMMENT_ERROR = 'DELETE_TIME_LINE_COMMENT_ERROR';
export const GET_MEETING_AI_ANALYTICS = 'GET_MEETING_AI_ANALYTICS';
export const GET_MEETING_AI_ANALYTICS_SUCCESS = 'GET_MEETING_AI_ANALYTICS_SUCCESS';
export const GET_MEETING_AI_ANALYTICS_ERROR = 'GET_MEETING_AI_ANALYTICS_ERROR';
export const GET_STATS_CONTACTS_ERROR = 'GET_STATS_CONTACTS_ERROR';
export const GET_STATS_CONTACTS_SUCCESS = 'GET_STATS_CONTACTS_SUCCESS';
export const GET_STATS_CONTACTS_DAILY = 'GET_STATS_CONTACTS_DAILY';
export const GET_STATS_CONTACTS_DAILY_SUCCESS = 'GET_STATS_CONTACTS_DAILY_SUCCESS';
export const GET_STATS_CONTACTS = 'GET_STATS_CONTACTS';
export const GET_STATS_COMPANIES_ERROR = 'GET_STATS_COMPANIES_ERROR';
export const GET_STATS_COMPANIES_SUCCESS = 'GET_STATS_COMPANIES_SUCCESS';
export const GET_STATS_COMPANIES_DAILY = 'GET_STATS_COMPANIES_DAILY';
export const GET_STATS_COMPANIES_DAILY_SUCCESS = 'GET_STATS_COMPANIES_DAILY_SUCCESS';
export const GET_STATS_COMPANIES = 'GET_STATS_COMPANIES';
export const SHARE_RECORDING = 'SHARE_RECORDING';
export const SHARE_RECORDING_SUCCESS = 'SHARE_RECORDING_SUCCESS';
export const SHARE_RECORDING_ERROR = 'SHARE_RECORDING_ERROR';
export const GET_RECORDING_SHARE_LINKS_FOR_RECORDING = 'GET_RECORDING_SHARE_LINKS_FOR_RECORDING';
export const GET_RECORDING_SHARE_LINKS_FOR_RECORDING_SUCCESS = 'GET_RECORDING_SHARE_LINKS_FOR_RECORDING_SUCCESS';
export const GET_RECORDING_SHARE_LINKS_FOR_RECORDING_ERROR = 'GET_RECORDING_SHARE_LINKS_FOR_RECORDING_ERROR';
export const GET_RECORDING_SHARE_LINK_BY_LINK_HASH = 'GER_RECORDING_SHARE_LINK_BY_LINK_HASH';
export const GET_RECORDING_SHARE_LINK_BY_LINK_HASH_SUCCESS = 'GER_RECORDING_SHARE_LINK_BY_LINK_HASH_SUCCESS';
export const GET_RECORDING_SHARE_LINK_BY_LINK_HASH_ERROR = 'GER_RECORDING_SHARE_LINK_BY_LINK_HASH_ERROR';
export const UPDATE_RECORDING_SHARE_LINK = 'UPDATE_RECORDING_SHARE_LINK';
export const UPDATE_RECORDING_SHARE_LINK_SUCCESS = 'UPDATE_RECORDING_SHARE_LINK_SUCCESS';
export const UPDATE_RECORDING_SHARE_LINK_ERROR = 'UPDATE_RECORDING_SHARE_LINK_ERROR';
export const DELETE_RECORDING_SHARE_LINK = 'DELETE_RECORDING_SHARE_LINK';
export const DELETE_RECORDING_SHARE_LINK_SUCCESS = 'DELETE_RECORDING_SHARE_LINK_SUCCESS';
export const DELETE_RECORDING_SHARE_LINK_ERROR = 'DELETE_RECORDING_SHARE_LINK_ERROR';
export const GET_RECORDING_SNIPPETS = 'GET_RECORDING_SNIPPETS';
export const GET_RECORDING_SNIPPETS_SUCCESS = 'GET_RECORDING_SNIPPETS_SUCCESS';
export const GET_SNIPPET = "GET_SNIPPET";
export const GET_SNIPPET_SUCCESS = "GET_SNIPPET_SUCCESS";
export const GET_SNIPPET_ERROR = "GET_SNIPPET_ERROR";
export const GET_SNIPPET_TIME_LINE = "GET_SNIPPET_TIME_LINE";
export const GET_SNIPPET_TIME_LINE_SUCCESS = "GET_SNIPPET_TIME_LINE_SUCCESS";
export const GET_SNIPPET_TIME_LINE_ERROR = "GET_SNIPPET_TIME_LINE_ERROR";
export const POST_RECORDING_SNIPPET = 'POST_RECORDING_SNIPPETS';
export const POST_RECORDING_SNIPPET_SUCCESS = 'POST_RECORDING_SNIPPETS_SUCCESS';
export const GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET = 'GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET';
export const GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET_SUCCESS = 'GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET_SUCCESS';
export const GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET_ERROR = 'GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET_ERROR';
export const GET_SNIPPET_SHARE_LINK_BY_LINK_HASH = 'GET_SNIPPET_SHARE_LINK_BY_LINK_HASH';
export const GET_SNIPPET_SHARE_LINK_BY_LINK_HASH_SUCCESS = 'GET_SNIPPET_SHARE_LINK_BY_LINK_HASH_SUCCESS';
export const GET_SNIPPET_SHARE_LINK_BY_LINK_HASH_ERROR = 'GET_SNIPPET_SHARE_LINK_BY_LINK_HASH_ERROR';
export const CREATE_SNIPPET_SHARE_LINK = 'CREATE_SNIPPET_SHARE_LINK';
export const CREATE_SNIPPET_SHARE_LINK_SUCCESS = 'CREATE_SNIPPET_SHARE_LINK_SUCCESS';
export const CREATE_SNIPPET_SHARE_LINK_ERROR = 'CREATE_SNIPPET_SHARE_LINK_ERROR';
export const UPDATE_SNIPPET_SHARE_LINK = 'UPDATE_SNIPPET_SHARE_LINK';
export const UPDATE_SNIPPET_SHARE_LINK_SUCCESS = 'UPDATE_SNIPPET_SHARE_LINK_SUCCESS';
export const UPDATE_SNIPPET_SHARE_LINK_ERROR = 'UPDATE_SNIPPET_SHARE_LINK_ERROR';
export const DELETE_SNIPPET_SHARE_LINK = 'DELETE_SNIPPET_SHARE_LINK';
export const DELETE_SNIPPET_SHARE_LINK_SUCCESS = 'DELETE_SNIPPET_SHARE_LINK_SUCCESS';
export const DELETE_SNIPPET_SHARE_LINK_ERROR = 'DELETE_SNIPPET_SHARE_LINK_ERROR';
export const UPDATE_SNIPPET = 'UPDATE_SNIPPET';
export const UPDATE_SNIPPET_SUCCESS = 'UPDATE_SNIPPET_SUCCESS';
export const UPDATE_SNIPPET_ERROR = 'UPDATE_SNIPPET_ERROR';
export const GET_LOGS = 'GET_LOGS';
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';
export const GET_LOGS_ERROR = 'GET_LOGS_ERROR';
export const GET_USER_PROFILE_SETUP_FINISHED = 'GET_USER_PROFILE_SETUP_FINISHED';
export const GET_USER_PROFILE_SETUP_FINISHED_SUCCESS = 'GET_USER_PROFILE_SETUP_FINISHED_SUCCESS';
export const GET_USER_PROFILE_SETUP_FINISHED_ERROR = 'GET_USER_PROFILE_SETUP_FINISHED_ERROR';
export const GET_INTEGRATION_SETUP_FINISHED = 'GET_INTEGRATION_SETUP_FINISHED';
export const GET_INTEGRATION_SETUP_FINISHED_SUCCESS = 'GET_INTEGRATION_SETUP_FINISHED_SUCCESS';
export const GET_INTEGRATION_SETUP_FINISHED_ERROR = 'GET_INTEGRATION_SETUP_FINISHED_ERROR';
export const CREATE_LOG = 'CREATE_LOG';
export const CREATE_LOG_SUCCESS = 'CREATE_LOG_SUCCESS';
export const CREATE_LOG_ERROR = 'CREATE_LOG_ERROR';
export const GET_RECORDING_TRANSCRIPT = 'GET_RECORDING_TRANSCRIPT';
export const GET_RECORDING_TRANSCRIPT_SUCCESS = 'GET_RECORDING_TRANSCRIPT_SUCCESS';
export const GET_RECORDING_TRANSCRIPT_ERROR = 'GET_RECORDING_TRANSCRIPT_ERROR';
export const GET_SNIPPET_RECORDING_TRANSCRIPT = 'GET_SNIPPET_RECORDING_TRANSCRIPT';
export const GET_SNIPPET_RECORDING_TRANSCRIPT_SUCCESS = 'GET_SNIPPET_RECORDING_TRANSCRIPT_SUCCESS';
export const GET_SNIPPET_RECORDING_TRANSCRIPT_ERROR = 'GET_RECORDING_SNIPPET_TRANSCRIPT_ERROR';
export const SEARCH_RECORDING_TRANSCRIPTS = 'SEARCH_RECORDING_TRANSCRIPTS';
export const SEARCH_RECORDING_TRANSCRIPTS_SUCCESS = 'SEARCH_RECORDING_TRANSCRIPTS_SUCCESS';
export const SEARCH_RECORDING_TRANSCRIPTS_ERROR = 'SEARCH_RECORDING_TRANSCRIPTS_ERROR';
export const SEARCH_RECORDING_TRANSCRIPTS_EMPTY = 'SEARCH_RECORDING_TRANSCRIPTS_EMPTY';
export const GET_AI_CATEGORIES = 'GET_AI_CATEGORIES';
export const GET_AI_CATEGORIES_SUCCESS = 'GET_AI_CATEGORIES_SUCCESS';
export const GET_AI_CATEGORIES_ERROR = 'GET_AI_CATEGORIES_ERROR';
export const GET_ALL_TAGS_BY_ACCOUNT = 'GET_ALL_TAGS_BY_ACCOUNT';
export const GET_ALL_TAGS_BY_ACCOUNT_SUCCESS = 'GET_ALL_TAGS_BY_ACCOUNT_SUCCESS';
export const ADD_RECORDING_TAGS = 'ADD_RECORDING_TAGS';
export const ADD_RECORDING_TAG_SUCCESS = 'ADD_RECORDING_TAG_SUCCESS';
export const DELETE_RECORDING_TAG_SUCCESS = 'DELETE_RECORDING_TAG_SUCCESS';
export const DELETE_RECORDING_TAGS = 'DELETE_RECORDING_TAGS';
export const GET_SNIPPET_TAGS = 'GET_SNIPPET_TAGS';
export const GET_SNIPPET_TAGS_SUCCESS = 'GET_SNIPPET_TAGS_SUCCESS';
export const ADD_SNIPPET_TAGS = 'ADD_SNIPPET_TAGS';
export const ADD_SNIPPET_TAG_SUCCESS = 'ADD_SNIPPET_TAG_SUCCESS';
export const DELETE_SNIPPET_TAG = 'DELETE_SNIPPET_TAG';
export const DELETE_SNIPPET_TAG_SUCCESS = 'DELETE_SNIPPET_TAG_SUCCESS';
export const GET_SNIPPET_TAG_ERROR = 'GET_SNIPPET_TAG_ERROR';
export const CREATE_TAG = "CREATE_TAG";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_ERROR = "CREATE_TAG_ERROR";
export const DELETE_TAG = "DELETE_TAG";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_ERROR = "DELETE_TAG_ERROR";
export const EDIT_TAG = "EDIT_TAG";
export const EDIT_TAG_SUCCESS = "EDIT_TAG_SUCCESS";
export const EDIT_TAG_ERROR = "EDIT_TAG_ERROR";
export const GET_TAG_ANALYTICS = 'GET_TAG_ANALYTICS';
export const GET_TAG_ANALYTICS_SUCCESS = 'GET_TAG_ANALYTICS_SUCCESS';
export const GET_TAG_ANALYTICS_ERROR = 'GET_TAG_ANALYTICS_ERROR';
export const CLEAR_RECORDINGS_DATA = 'CLEAR_RECORDINGS_DATA';
