import { all, call, fork, put, takeEvery, PutEffect } from "redux-saga/effects";
import { has } from "lodash";
import { Action } from "redux";

import {
  createChatSession as createChatSessionAction,
  createChatSessionError,
  createChatSessionSuccess,
  getChatMessages as getChatMessagesAction,
  getChatMessagesError,
  getChatMessagesSuccess,
  getChatTopicsError,
  getChatTopicsSuccess,
  sendMessage as sendMessageAction,
  sendMessageError,
  sendMessageSuccess,
  editChat as editChatAction,
  editChatError,
  deleteChat as deleteChatAction,
  deleteChatError,
  deleteChatSuccess,
  getChatTopics as getChatTopicsAction,
} from "./actions";
import {
  CREATE_CHAT_SESSION,
  DELETE_CHAT,
  EDIT_CHAT,
  GET_CHAT_MESSAGES,
  GET_CHAT_TOPICS,
  SEND_MESSAGE,
} from "./actionTypes";
import {
  ErrorActionCreator,
  IChatInformation,
  IChatInformationFromBackEnd,
  IMessage,
  ResponseType,
} from "./interface";
import {
  mapChatToCamelCase,
  mapToCamelCase,
} from "../../components/AiChat/Chat/utils/helperFunctions";
import { ResponseGenerator } from "../../services/userservice";
import {
  createChatSession,
  getChatTopics,
  getChatMessages,
  sendMessage,
  editChat,
  deleteChat,
} from "../../services/chat/chatService";

function* handleError(
  response: ResponseType,
  errorAction: ErrorActionCreator
): Generator<PutEffect<Action>, void, unknown> {
  const error = response.error || response.errors || "An error has occurred!";
  yield put(errorAction(error));
}

function* createChatSessionHelper({
  payload,
}: ReturnType<typeof createChatSessionAction>) {
  try {
    const response: ResponseGenerator = yield call(createChatSession, payload);
    if (has(response, "error") || has(response, "errors")) {
      yield call(handleError, response, createChatSessionError);
    } else {
      const chat = mapChatToCamelCase(
        response as unknown as IChatInformationFromBackEnd
      );
      yield put(createChatSessionSuccess(chat));
      yield put(getChatMessagesSuccess(chat.messages as IMessage[]));
      yield put(getChatTopicsAction());
    }
  } catch (error) {
    yield put(createChatSessionError("An error has occurred!"));
  }
}

function* getChatTopicsHelper() {
  try {
    const response: ResponseGenerator = yield call(getChatTopics);
    if (has(response, "error") || has(response, "errors")) {
      yield call(handleError, response, createChatSessionError);
    } else {
      const mappedData = mapToCamelCase(response.data);
      yield put(getChatTopicsSuccess(mappedData as IChatInformation[]));
    }
  } catch (error) {
    yield put(getChatTopicsError("An error has occurred!"));
  }
}

function* getChatMessagesHelper({
  payload,
}: ReturnType<typeof getChatMessagesAction>) {
  try {
    const response: ResponseGenerator = yield call(
      getChatMessages,
      payload.chatId
    );
    if (has(response, "error") || has(response, "errors")) {
      yield call(handleError, response, getChatMessagesError);
    } else {
      const mappedMessages = mapChatToCamelCase(
        response as unknown as IChatInformationFromBackEnd
      );
      yield put(
        getChatMessagesSuccess(mappedMessages.messages, mappedMessages)
      );
    }
  } catch (error) {
    yield put(getChatMessagesError("An error has occurred!"));
  }
}

function* sendMessageHelper({ payload }: ReturnType<typeof sendMessageAction>) {
  try {
    const response: ResponseGenerator = yield call(
      sendMessage,
      payload.chatId,
      payload.message
    );
    if (has(response, "error") || has(response, "errors")) {
      yield call(handleError, response, sendMessageError);
    } else {
      const mappedData = mapChatToCamelCase(response.data);
      yield put(sendMessageSuccess(mappedData.messages));
    }
  } catch (error) {
    yield put(sendMessageError("An error has occurred!"));
  }
}

function* editChatHelper({ payload }: ReturnType<typeof editChatAction>) {
  try {
    const response: ResponseGenerator = yield call(
      editChat,
      payload.chatId,
      payload.chatTitle
    );
    if (has(response, "error") || has(response, "errors")) {
      yield call(handleError, response, editChatError);
    }
  } catch (error) {
    yield put(editChatError("An error has occurred!"));
  }
}

function* deleteChatHelper({ payload }: ReturnType<typeof deleteChatAction>) {
  try {
    const response: ResponseGenerator = yield call(deleteChat, payload);
    if (has(response, "error") || has(response, "errors")) {
      yield call(handleError, response, deleteChatError);
    } else {
      yield put(deleteChatSuccess(payload));
    }
  } catch (error) {
    yield put(deleteChatError("An error has occurred!"));
  }
}

export function* watchChat() {
  yield takeEvery(CREATE_CHAT_SESSION, createChatSessionHelper);
  yield takeEvery(GET_CHAT_TOPICS, getChatTopicsHelper);
  yield takeEvery(GET_CHAT_MESSAGES, getChatMessagesHelper);
  yield takeEvery(SEND_MESSAGE, sendMessageHelper);
  yield takeEvery(EDIT_CHAT, editChatHelper);
  yield takeEvery(DELETE_CHAT, deleteChatHelper);
}

function* ChatSaga() {
  yield all([fork(watchChat)]);
}

export default ChatSaga;
