import axios, { AxiosResponse } from "axios";

import { ResponseGenerator } from "../userservice";
import { commonheaders } from "../../utilities/apiConfig";
import { endpoints } from "../../utilities/endpoints";
import { urls } from "../../utilities/urls";
import { IAnalyticsKeywordsData } from "../../pages/Settings/Analytics/IAnalyticTypes";

export async function getShareLinkDetails(
  accountId: String,
  globalFilterString: string
): Promise<AxiosResponse<IAnalyticsKeywordsData[]>> {
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.recordingDetails}/share-links?${globalFilterString}`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getUnshareLinkDetails(accountId: String, shareLinkId: string, type: string): Promise<AxiosResponse<void>> {
  const requestBody = {
    shareLinkId,
    type
  };

  try {
    const response: ResponseGenerator = await axios.post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.recordingDetails}/share-links/unshare`,
      requestBody,
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}
