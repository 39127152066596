import {
  API_ERROR,
  CHECK_GOOGLE_LOGIN_USER,
  GET_ALL_USERS_LIST,
  GET_ALL_USERS_SUCCESS,
  LOGIN_AS_USER,
  LOGIN_AS_USER_SUCCESS,
  LOGIN_SHARE_LINK,
  LOGIN_SHARE_LINK_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  SWITCH_TO_SESSION,
  SWITCH_TO_SESSION_ERROR,
} from "./actionTypes";
import { loginAction, loginState } from "./interface";

const initialState: loginState = {
  error: "",
  loading: false,
  userJWT: null,
  loginUser: null,
  allUsers: [],
  shareLinkJwt: null,
  switchToSessionError: "",
};

const login = (state = initialState, action: loginAction) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
        error: [],
        loginUser: null,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        loginUser: action.payload,
      };
      break;
    case LOGOUT_USER:
      state = { ...state };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state };
      break;
    case LOGIN_AS_USER:
      state = {
        ...state,
        loading: true,
        error: [],
        userJWT: null,
      };
      break;
    case LOGIN_AS_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        userJWT: action.payload,
      };
      break;
    case GET_ALL_USERS_LIST:
      state = {
        ...state,
        loading: true,
        error: [],
        allUsers: [],
      };
      break;
    case GET_ALL_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        allUsers: action.payload,
      };
      break;
    case CHECK_GOOGLE_LOGIN_USER:
      state = {
        ...state,
        error: [],
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    case LOGIN_SHARE_LINK:
      state = {
        ...state,
        loading: true,
        shareLinkJwt: null
      }
      break;
    case LOGIN_SHARE_LINK_SUCCESS:
      state = {
        ...state,
        loading: false,
        shareLinkJwt: action.payload
      }
      break;
    case SWITCH_TO_SESSION:
      state = {
        ...state,
        loading: true,
      }
      break;
    case SWITCH_TO_SESSION_ERROR:
      state = {
        ...state,
        loading: false,
        switchToSessionError: action.payload,
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
