import {
  GET_SNIPPET_DETAILS,
  GET_SNIPPET_DETAILS_ERROR,
  GET_SNIPPET_DETAILS_SUCCESS,
  DELETE_SNIPPET_DETAILS,
  DELETE_SNIPPET_DETAILS_ERROR,
  DELETE_SNIPPET_DETAILS_SUCCESS,
  UNSHARE_SNIPPET_DETAILS,
} from "./actionTypes";

import { ISnippetDetails } from "./interface";

export const getSnippetDetails = (accountId: String, params: string) => {
  return {
    type: GET_SNIPPET_DETAILS,
    payload: { accountId, params },
  };
};

export const getSnippetDetailsError = (error: String | Object) => {
  return {
    type: GET_SNIPPET_DETAILS_ERROR,
    payload: error,
  };
};

export const getSnippetDetailsSuccess = (snippetDetails: ISnippetDetails[]) => {
  return {
    type: GET_SNIPPET_DETAILS_SUCCESS,
    payload: snippetDetails,
  };
};

export const deleteSnippetDetails = (
  accountId: String,
  snippetId: String,
  onSuccess?: () => void,
) => {
  return {
    type: DELETE_SNIPPET_DETAILS,
    payload: { accountId, snippetId, onSuccess },
  };
};

export const deleteSnippetDetailsSuccess = () => {
  return {
    type: DELETE_SNIPPET_DETAILS_SUCCESS,
  };
};

export const deleteSnippetDetailsError = (error: String | Object) => {
  return {
    type: DELETE_SNIPPET_DETAILS_ERROR,
    payload: error,
  };
};

export const getUnshareSnippet = (
  accountId: String,
  snippetUuid: string,
  onSuccess?: () => void,
) => {
  return {
    type: UNSHARE_SNIPPET_DETAILS,
    payload: { accountId, snippetUuid, onSuccess },
  };
};
