import React, { Component } from "react";
import { Button } from "reactstrap";

import CreateTagModal from "./components/CreateTagModal";
import { ADMIN, USER_MANAGEMENT } from "../../pages/InspirationLibrary/utils/constants";
import {
  Tag,
  SnippetTag,
} from "../../pages/InspirationLibrary/utils/interface";
import { Icurrentaccountselected, TagType } from "../../store/interface";

interface TagsProps {
  currentaccountselected: Icurrentaccountselected;
  tags: Tag[] | SnippetTag[];
  accountTags: TagType[];
  onAddTag: (name: string, accountId: String) => void;
  deleteTag: (id: string) => void;
  getAllTags: (accountId: String) => void;
}

interface TagsState {
  showAddTagModal: boolean;
}

class Tags extends Component<TagsProps, TagsState> {
  constructor(props: TagsProps) {
    super(props);
    this.state = { showAddTagModal: false };
  }

  toggleModal = () => {
    const { showAddTagModal } = this.state;
    this.setState({ showAddTagModal: !showAddTagModal });
  };

  render() {
    const { showAddTagModal } = this.state;
    const { tags, currentaccountselected, deleteTag, onAddTag } = this.props;
    const isAdminOrManager =
      currentaccountselected.right_level === ADMIN ||
      currentaccountselected.right_level === USER_MANAGEMENT;

    const isTagCreationAllowed = currentaccountselected.allow_users_inspiration_tag_changes || isAdminOrManager;

    return (
      <>
        {tags.map((tag, index) => (
          <div key={index} className="tag">
            {tag.name}

            {isAdminOrManager && (
              <button
                className="delete-tag-button buttonTextColorImportant"
                onClick={() => deleteTag(tag.id)}
              >
                x
              </button>
            )}
          </div>
        ))}

        <div className="add-tag">
          {isTagCreationAllowed && (
            <Button
              className="add-tag-button"
              color="success"
              onClick={this.toggleModal}
            >
              Add new inspiration tag
            </Button>
          )}

          {showAddTagModal && (
            <CreateTagModal
              accountId={currentaccountselected.id}
              isOpen={showAddTagModal}
              toggleModal={this.toggleModal}
              tags={tags}
              onAddTag={onAddTag}
            />
          )}
        </div>
      </>
    );
  }
}

export default Tags;
