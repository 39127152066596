import {
  API_ERROR,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  SET_PRESENTATION_SUCCESS,
  SET_FILTER_DOCUMENT,
  IMPORT_PRESENTATION_ERROR,
  GET_CATEGORIES_ERROR,
  GET_PRESENTATIONS_SUCCESS,
  GET_PRESENTATIONS,
  SET_IMPORT_PPTX,
  GET_IMPORT_PRESENTATION,
  SET_PRESENTATION,
  DUPLICATE_PRESENTATION,
  EDIT_PRESENTATION,
  REMOVE_PRESENTATION,
  APPEND_DUPLICATE_PRESENTATION,
  REMOVE_PRESENTATION_SUCCESS,
  SET_SEARCH_TEXT,
  GET_GOOGLE_DRIVE_FILES_LIST,
  DRIVE_API_ERROR,
  GET_GOOGLE_DOWNLOAD,
  GET_GOOGLE_DOWNLOAD_SUCCESS,
  GET_GOOGLE_DOWNLOAD_ERROR,
  SET_GOOGLE_DRIVE_LOGOUT,
  SET_GOOGLE_DRIVE_LOGOUT_ERROR,
  DELETE_MULTIPLE_DOCS,
  DELETE_MULTIPLE_DOCS_SUCCESS,
} from "./actionTypes";
import { IPresentation } from "./interface";

export const getPresentations = (id: String, filter?: String) => {
  return {
    type: GET_PRESENTATIONS,
    payload: { id, filter },
  };
};

export const getPresentationsSuccess = (presentations: IPresentation[]) => {
  return {
    type: GET_PRESENTATIONS_SUCCESS,
    payload: presentations,
  };
};

export const setPresentation = (presentation: Object, id: String) => {
  return {
    type: SET_PRESENTATION,
    payload: { presentation, id },
  };
};

export const setPresentationSuccess = (mes: String, data: any) => {
  return {
    type: SET_PRESENTATION_SUCCESS,
    payload: {mes, data},
  };
};

export const setImportPptx = (data: Object, id: String) => {
  return {
    type: SET_IMPORT_PPTX,
    payload: { data, id },
  };
};

export const getImportPresentationsPPtx = (presentation: IPresentation[]) => {
  return {
    type: GET_IMPORT_PRESENTATION,
    payload: presentation,
  };
};

export const importPresentationError = (err: String | Object) => {
  return {
    type: IMPORT_PRESENTATION_ERROR,
    payload: err,
  };
};

export const editPresentation = (
  presentation: Object,
  uid: String,
  pid: String
) => {
  return {
    type: EDIT_PRESENTATION,
    payload: { presentation, uid, pid },
  };
};

export const duplicatePresentation = (
  data: Object,
  presentation: String,
  id: String,
  isTemplate: Boolean
) => {
  return {
    type: DUPLICATE_PRESENTATION,
    payload: { data, presentation, id, isTemplate },
  };
};

export const appendDuplicatePresentation = (presentation: {
  data: IPresentation;
  isTemplate: Boolean;
}) => {
  return {
    type: APPEND_DUPLICATE_PRESENTATION,
    payload: presentation,
  };
};

export const removePresentation = (presentation: Object, id: String) => {
  return {
    type: REMOVE_PRESENTATION,
    payload: { presentation, id },
  };
};

export const removePresentationSuccess = (msg: String, pid: String) => {
  return {
    type: REMOVE_PRESENTATION_SUCCESS,
    payload: { msg, pid },
  };
};

export const presentationApiError = (error: Object | String) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const setSearchValue = (value: String) => {
  return {
    type: SET_SEARCH_TEXT,
    payload: value,
  };
};

export const setFilterDocuments = (value: any) => {
  return {
    type: SET_FILTER_DOCUMENT,
    payload: value,
  };
};

export const getCategories = (id: String) => {
  return {
    type: GET_CATEGORIES,
    payload: { id },
  };
};

export const getCategoriesSuccess = (mes: Object[]) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: mes,
  };
};

export const getCategoriesError = (mes: Object | String) => {
  return {
    type: GET_CATEGORIES_ERROR,
    payload: mes,
  };
};

export const getGoogleDriveFileList = (
  folderId: String | null,
  callback: Function,
  onErrorCallback?: Function
) => {
  return {
    type: GET_GOOGLE_DRIVE_FILES_LIST,
    payload: { folderId, callback, onErrorCallback },
  };
};

export const driveApiError = (error: Object | String) => {
  return {
    type: DRIVE_API_ERROR,
    payload: error,
  };
};

export const handleGoogleDownload = (data: any, type: string, onSuccess: any ) => {
  return {
    type: GET_GOOGLE_DOWNLOAD,
    payload: { data, type, onSuccess },
  };
};

export const getGoogleDownloadSuccess = (mes: String) => {
  return {
    type: GET_GOOGLE_DOWNLOAD_SUCCESS,
    payload: mes,
  };
};

export const getGoogleDownloadError = (error: Object | String) => {
  return {
    type: GET_GOOGLE_DOWNLOAD_ERROR,
    payload: error,
  };
};

export const setDriveLogOut = (callback: Function) => {
  return {
    type: SET_GOOGLE_DRIVE_LOGOUT,
    payload: { callback },
  };
};

export const setDriveLogOutError = (error: Object | String) => {
  return {
    type: SET_GOOGLE_DRIVE_LOGOUT_ERROR,
    payload: error,
  };
};

export const deleteMultipleDocs = (presentationIds: string[], fileIds: string[]) => {
  return {
    type: DELETE_MULTIPLE_DOCS,
    payload: {presentationIds, fileIds},
  };
};
export const deleteMultipleDocsSuccess = (presentationIds: string[], fileIds: string[]) => {
  return {
    type: DELETE_MULTIPLE_DOCS_SUCCESS,
    payload: {presentationIds, fileIds},
  };
};
