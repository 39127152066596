import {
  GET_GLOBAL_FILTERS,
  GET_GLOBAL_FILTERS_SUCCESS,
  GET_GLOBAL_FILTERS_ERROR,
  EMPTY_DATE_FILTERS,
  UPDATE_DASHBOARD_FILTERS_AFTER_EDIT,
  RESET_FILTERS,
  SET_GLOBAL_FILTERS_LOADING,
} from "./actionTypes";
import { FilterObject } from "./interface";

export const getGlobalFilters = (accountId: String) => ({
  type: GET_GLOBAL_FILTERS,
  payload: { accountId },
});

export const getGlobalFiltersSuccess = (data: any) => ({
  type: GET_GLOBAL_FILTERS_SUCCESS,
  payload: data,
});

export const getGlobalFiltersError = (message: string | Object) => ({
  type: GET_GLOBAL_FILTERS_ERROR,
  payload: message,
});

export const updateFilters = (filterType: string, value: any, type: string) => ({
  type: `UPDATE_${type}_FILTERS`,
  payload: { filterType, value },
});

export const emptyDateFilter = (type: string) => ({
  type: EMPTY_DATE_FILTERS,
  payload: type,
});

export const updateDashboardFilters = (filters: FilterObject) => ({
  type: UPDATE_DASHBOARD_FILTERS_AFTER_EDIT,
  payload: filters,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});

export const setGlobalFiltersLoading = () => ({
  type: SET_GLOBAL_FILTERS_LOADING,
});
