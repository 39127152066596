import { Tag, SnippetTag } from "../../pages/InspirationLibrary/utils/interface";
import {
  GET_STATS_USER,
  GET_STATS_USER_SUCCESS,
  GET_STATS_USER_DAILY_SUCCESS,
  GET_STATS_USER_DAILY,
  GET_STATS_USER_ERROR,
  GET_STATS_TEAM,
  GET_STATS_TEAM_SUCCESS,
  GET_STATS_TEAM_DAILY,
  GET_STATS_TEAM_DAILY_SUCCESS,
  GET_STATS_TEAM_ERROR,
  GET_STATS_MEETINGS,
  GET_STATS_MEETINGS_SUCCESS,
  GET_STATS_MEETINGS_DAILY,
  GET_STATS_MEETINGS_DAILY_SUCCESS,
  GET_STATS_MEETINGS_ERROR,
  GET_STATS_MEETINGS_LIVE,
  GET_STATS_MEETINGS_LIVE_SUCCESS,
  GET_STATS_MEETINGS_LIVE_ERROR,
  GET_STATS_INTERACTIONS,
  GET_STATS_INTERACTIONS_SUCCESS,
  GET_STATS_INTERACTIONS_DAILY,
  GET_STATS_INTERACTIONS_DAILY_SUCCESS,
  GET_STATS_INTERACTIONS_ERROR,
  GET_STATS_PARTICIPANTS_ERROR,
  GET_STATS_PARTICIPANTS_SUCCESS,
  GET_STATS_PARTICIPANTS_DAILY,
  GET_STATS_PARTICIPANTS_DAILY_SUCCESS,
  GET_STATS_PARTICIPANTS,
  GET_STATS_MEMBERS_ERROR,
  GET_STATS_MEMBERS_SUCCESS,
  GET_STATS_MEMBERS_DAILY,
  GET_STATS_MEMBERS_DAILY_SUCCESS,
  GET_STATS_MEMBERS,
  GET_STATS_CONTACTS_ERROR,
  GET_STATS_CONTACTS_SUCCESS,
  GET_STATS_CONTACTS_DAILY,
  GET_STATS_CONTACTS_DAILY_SUCCESS,
  GET_STATS_CONTACTS,
  GET_STATS_COMPANIES_ERROR,
  GET_STATS_COMPANIES_SUCCESS,
  GET_STATS_COMPANIES_DAILY,
  GET_STATS_COMPANIES_DAILY_SUCCESS,
  GET_STATS_COMPANIES,
  GET_STATS_VIEWS_ERROR,
  GET_STATS_VIEWS_SUCCESS,
  GET_STATS_VIEWS,
  GET_STATS_SLIDES_ERROR,
  GET_STATS_SLIDES_SUCCESS,
  GET_STATS_SLIDES_DAILY,
  GET_STATS_SLIDES_DAILY_SUCCESS,
  GET_STATS_SLIDES,
  GET_STATS_PRESENTATIONS_ERROR,
  GET_STATS_PRESENTATIONS_SUCCESS,
  GET_STATS_PRESENTATIONS_DAILY,
  GET_STATS_PRESENTATIONS_DAILY_SUCCESS,
  GET_STATS_PRESENTATIONS,
  GET_STATS_SHARED_LINKS_DAILY,
  GET_STATS_SHARED_LINKS_DAILY_SUCCESS,
  GET_STATS_SHARED_LINKS,
  GET_STATS_SHARED_LINKS_SUCCESS,
  GET_STATS_SHARED_LINKS_ERROR,
  GET_STATS_ROOM_SESSIONS,
  GET_STATS_ROOM_SESSIONS_SUCCESS,
  GET_STATS_ROOM_SESSIONS_ERROR,
  GET_STATS_ROOM_SESSIONS_DAILY,
  GET_STATS_ROOM_SESSIONS_DAILY_SUCCESS,
  DELETE_SESSION,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_ERROR,
  GET_STATS_DASHBOARD,
  GET_STATS_DASHBOARD_SUCCESS,
  GET_STATS_DASHBOARD_DAILY,
  GET_STATS_DASHBOARD_DAILY_SUCCESS,
  GET_STATS_DASHBOARD_ERROR,
  GET_STATS_RECORDINGS,
  GET_STATS_RECORDINGS_SUCCESS,
  GET_STATS_RECORDINGS_ERROR,
  DELETE_STATS_RECORDINGS,
  DELETE_STATS_RECORDINGS_SUCCESS,
  DELETE_STATS_RECORDINGS_ERROR,
  GET_TIME_LINE,
  GET_TIME_LINE_SUCCESS,
  GET_TIME_LINE_ERROR,
  GET_TIME_LINE_COMMENTS,
  GET_TIME_LINE_COMMENTS_SUCCESS,
  GET_TIME_LINE_COMMENTS_ERROR,
  ADD_TIME_LINE_COMMENT,
  ADD_TIME_LINE_COMMENT_SUCCESS,
  ADD_TIME_LINE_COMMENT_ERROR,
  DELETE_TIME_LINE_COMMENT,
  DELETE_TIME_LINE_COMMENT_SUCCESS,
  DELETE_TIME_LINE_COMMENT_ERROR,
  GET_MEETING_AI_ANALYTICS,
  GET_MEETING_AI_ANALYTICS_SUCCESS,
  GET_MEETING_AI_ANALYTICS_ERROR,
  SHARE_RECORDING,
  SHARE_RECORDING_SUCCESS,
  SHARE_RECORDING_ERROR,
  GET_RECORDING_SHARE_LINKS_FOR_RECORDING,
  GET_RECORDING_SHARE_LINKS_FOR_RECORDING_SUCCESS,
  GET_RECORDING_SHARE_LINKS_FOR_RECORDING_ERROR,
  DELETE_RECORDING_SHARE_LINK,
  DELETE_RECORDING_SHARE_LINK_SUCCESS,
  DELETE_RECORDING_SHARE_LINK_ERROR,
  UPDATE_RECORDING_SHARE_LINK,
  UPDATE_RECORDING_SHARE_LINK_SUCCESS,
  UPDATE_RECORDING_SHARE_LINK_ERROR,
  GET_RECORDING_SHARE_LINK_BY_LINK_HASH,
  GET_RECORDING_SHARE_LINK_BY_LINK_HASH_SUCCESS,
  GET_RECORDING_SHARE_LINK_BY_LINK_HASH_ERROR,
  GET_RECORDING_SNIPPETS,
  GET_RECORDING_SNIPPETS_SUCCESS,
  POST_RECORDING_SNIPPET,
  POST_RECORDING_SNIPPET_SUCCESS,
  GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET,
  GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET_SUCCESS,
  GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET_ERROR,
  CREATE_SNIPPET_SHARE_LINK,
  CREATE_SNIPPET_SHARE_LINK_SUCCESS,
  CREATE_SNIPPET_SHARE_LINK_ERROR,
  UPDATE_SNIPPET_SHARE_LINK,
  UPDATE_SNIPPET_SHARE_LINK_SUCCESS,
  UPDATE_SNIPPET_SHARE_LINK_ERROR,
  DELETE_SNIPPET_SHARE_LINK,
  DELETE_SNIPPET_SHARE_LINK_SUCCESS,
  DELETE_SNIPPET_SHARE_LINK_ERROR,
  GET_SNIPPET_SHARE_LINK_BY_LINK_HASH,
  GET_SNIPPET_SHARE_LINK_BY_LINK_HASH_SUCCESS,
  GET_SNIPPET_SHARE_LINK_BY_LINK_HASH_ERROR,
  GET_SNIPPET,
  GET_SNIPPET_SUCCESS,
  GET_SNIPPET_ERROR,
  GET_SNIPPET_TIME_LINE,
  GET_SNIPPET_TIME_LINE_SUCCESS,
  GET_SNIPPET_TIME_LINE_ERROR,
  GET_LOGS,
  GET_LOGS_SUCCESS,
  GET_LOGS_ERROR,
  CREATE_LOG,
  CREATE_LOG_SUCCESS,
  CREATE_LOG_ERROR,
  GET_USER_PROFILE_SETUP_FINISHED,
  GET_USER_PROFILE_SETUP_FINISHED_SUCCESS,
  GET_USER_PROFILE_SETUP_FINISHED_ERROR,
  GET_INTEGRATION_SETUP_FINISHED,
  GET_INTEGRATION_SETUP_FINISHED_SUCCESS,
  GET_INTEGRATION_SETUP_FINISHED_ERROR,
  GET_RECORDING_TRANSCRIPT,
  GET_RECORDING_TRANSCRIPT_SUCCESS,
  GET_RECORDING_TRANSCRIPT_ERROR,
  GET_SNIPPET_RECORDING_TRANSCRIPT,
  GET_SNIPPET_RECORDING_TRANSCRIPT_SUCCESS,
  GET_SNIPPET_RECORDING_TRANSCRIPT_ERROR,
  SEARCH_RECORDING_TRANSCRIPTS,
  SEARCH_RECORDING_TRANSCRIPTS_ERROR,
  SEARCH_RECORDING_TRANSCRIPTS_SUCCESS,
  SEARCH_RECORDING_TRANSCRIPTS_EMPTY,
  UPDATE_SNIPPET,
  UPDATE_SNIPPET_SUCCESS,
  UPDATE_SNIPPET_ERROR,
  GET_AI_CATEGORIES,
  GET_AI_CATEGORIES_SUCCESS,
  GET_AI_CATEGORIES_ERROR,
  GET_ALL_TAGS_BY_ACCOUNT,
  GET_ALL_TAGS_BY_ACCOUNT_SUCCESS,
  ADD_RECORDING_TAGS,
  ADD_RECORDING_TAG_SUCCESS,
  DELETE_RECORDING_TAGS,
  ADD_SNIPPET_TAGS,
  ADD_SNIPPET_TAG_SUCCESS,
  DELETE_SNIPPET_TAG,
  DELETE_SNIPPET_TAG_SUCCESS,
  GET_SNIPPET_TAG_ERROR,
  DELETE_RECORDING_TAG_SUCCESS,
  CREATE_TAG,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_ERROR,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_ERROR,
  EDIT_TAG,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_ERROR,
  GET_TAG_ANALYTICS,
  GET_TAG_ANALYTICS_SUCCESS,
  GET_TAG_ANALYTICS_ERROR,
  GET_STATS_MEETINGS_COUNT,
  GET_STATS_MEETINGS_COUNT_SUCCESS,
  GET_STATS_MEETINGS_COUNT_ERROR,
  CLEAR_RECORDINGS_DATA
} from "./actionTypes";
import {
  IAnalyticsUser,
  IAnalyticsUserDaily,
  IAnalyticsTeam,
  IAnalyticsTeamDaily,
  IAnalyticsMeetings,
  IAnalyticsMeetingsDaily,
  IAnalyticsMeetingLive,
  IAnalyticsInteraction,
  IAnalyticsInteractionDaily,
  IAnalyticsPresentation,
  IAnalyticsPresentationDaily,
  IAnalyticsSlide,
  IAnalyticsSlideDaily,
  IAnalyticsSharedLink,
  IAnalyticsSharedLinkDaily,
  IAnalyticsRoomSession,
  IAnalyticsRoomSessionDaily,
  IAnalyticsParticipants,
  IAnalyticsParticipantsDaily,
  IAnalyticsMembers,
  IAnalyticsMembersDaily,
  IAnalyticsContact,
  IAnalyticsContactDaily,
  IAnalyticsCompany,
  IAnalyticsCompanyDaily,
  IAnalyticsView,
  IAnalyticsMeetingTimeline,
  IAnalyticsMeetingTimelineComment,
  IAnalyticsMeetingAIAnalytics,
  IEventLog,
  ITranscript,
  ITranscriptStats,
  IAiCategory,
  IRecordingTranscriptFilters,
  TagType,
  ITagAnalytics,
  IAnalyticsMeetingsCount,
} from "./interface";

export const getStatsDashboard = (id: String, params?: String) => {
  return {
    type: GET_STATS_DASHBOARD,
    payload: { id, params },
  };
};

export const getStatsDashboardSuccess = (user: IAnalyticsUser) => {
  return {
    type: GET_STATS_DASHBOARD_SUCCESS,
    payload: user,
  };
};

export const getStatsDashboardDaily = (id: String, params?: String) => {
  return {
    type: GET_STATS_DASHBOARD_DAILY,
    payload: { id, params },
  };
};

export const getStatsDashboardDailySuccess = (user: IAnalyticsUserDaily) => {
  return {
    type: GET_STATS_DASHBOARD_DAILY_SUCCESS,
    payload: user,
  };
};

export const getStatsDashboardError = (mes: String | Object) => {
  return {
    type: GET_STATS_DASHBOARD_ERROR,
    payload: mes,
  };
};

export const getStatsUser = (id: String, params?: String) => {
  return {
    type: GET_STATS_USER,
    payload: { id, params },
  };
};

export const getStatsUserSuccess = (user: IAnalyticsUser) => {
  return {
    type: GET_STATS_USER_SUCCESS,
    payload: user,
  };
};

export const getStatsUserDaily = (id: String, params?: String) => {
  return {
    type: GET_STATS_USER_DAILY,
    payload: { id, params },
  };
};

export const getStatsUserDailySuccess = (user: IAnalyticsUserDaily) => {
  return {
    type: GET_STATS_USER_DAILY_SUCCESS,
    payload: user,
  };
};

export const getStatsUserError = (mes: String | Object) => {
  return {
    type: GET_STATS_USER_ERROR,
    payload: mes,
  };
};

export const getStatsTeam = (id: String, params?: String) => {
  return {
    type: GET_STATS_TEAM,
    payload: { id, params },
  };
};

export const getStatsTeamSuccess = (team: IAnalyticsTeam) => {
  return {
    type: GET_STATS_TEAM_SUCCESS,
    payload: team,
  };
};

export const getStatsTeamDaily = (id: String, params?: String) => {
  return {
    type: GET_STATS_TEAM_DAILY,
    payload: { id, params },
  };
};

export const getStatsTeamDailySuccess = (team: IAnalyticsTeamDaily) => {
  return {
    type: GET_STATS_TEAM_DAILY_SUCCESS,
    payload: team,
  };
};

export const getStatsTeamError = (mes: String | Object) => {
  return {
    type: GET_STATS_TEAM_ERROR,
    payload: mes,
  };
};

export const getStatsMeetings = (id: String, params?: String) => {
  return {
    type: GET_STATS_MEETINGS,
    payload: { id, params },
  };
};

export const getStatsMeetingsSuccess = (meetings: IAnalyticsMeetings) => {
  return {
    type: GET_STATS_MEETINGS_SUCCESS,
    payload: meetings,
  };
};

export const getStatsMeetingsCount = (id: String, params?: String) => {
  return {
    type: GET_STATS_MEETINGS_COUNT,
    payload: { id, params },
  };
};

export const getStatsMeetingsCountSuccess = (meetingsCount: IAnalyticsMeetingsCount) => {
  return {
    type: GET_STATS_MEETINGS_COUNT_SUCCESS,
    payload: meetingsCount,
  };
};

export const clearRecordingsData = () => {
  return {
    type: CLEAR_RECORDINGS_DATA,
    payload: "data cleared",
  };
};

export const getStatsMeetingsCountError = (mes: String | Object) => {
  return {
    type: GET_STATS_MEETINGS_COUNT_ERROR,
    payload: mes,
  };
};

export const getStatsMeetingsDaily = (id: String, params?: String) => {
  return {
    type: GET_STATS_MEETINGS_DAILY,
    payload: { id, params },
  };
};

export const getStatsMeetingsDailySuccess = (
  meetingsDaily: IAnalyticsMeetingsDaily
) => {
  return {
    type: GET_STATS_MEETINGS_DAILY_SUCCESS,
    payload: meetingsDaily,
  };
};

export const getStatsMeetingsError = (mes: String | Object) => {
  return {
    type: GET_STATS_MEETINGS_ERROR,
    payload: mes,
  };
};

export const getStatsMeetingsLive = (id: String, params?: String) => {
  return {
    type: GET_STATS_MEETINGS_LIVE,
    payload: { id, params },
  };
};

export const getStatsMeetingsLiveSuccess = (meetingsLive: IAnalyticsMeetingLive) => {
  return {
    type: GET_STATS_MEETINGS_LIVE_SUCCESS,
    payload: meetingsLive,
  };
};

export const getStatsMeetingsLiveError = (mes: String | Object) => {
  return {
    type: GET_STATS_MEETINGS_LIVE_ERROR,
    payload: mes,
  };
};

export const getStatsInteractions = (id: String, params?: String) => {
  return {
    type: GET_STATS_INTERACTIONS,
    payload: { id, params },
  };
};

export const getStatsInteractionsSuccess = (
  interaction: IAnalyticsInteraction
) => {
  return {
    type: GET_STATS_INTERACTIONS_SUCCESS,
    payload: interaction,
  };
};

export const getStatsInteractionsDaily = (id: String, params?: String) => {
  return {
    type: GET_STATS_INTERACTIONS_DAILY,
    payload: { id, params },
  };
};

export const getStatsInteractionsDailySuccess = (
  interactionDaily: IAnalyticsInteractionDaily
) => {
  return {
    type: GET_STATS_INTERACTIONS_DAILY_SUCCESS,
    payload: interactionDaily,
  };
};

export const getStatsInteractionsError = (mes: Object | String) => {
  return {
    type: GET_STATS_INTERACTIONS_ERROR,
    payload: mes,
  };
};

export const getStatsPresentations = (id: String, params?: String) => {
  return {
    type: GET_STATS_PRESENTATIONS,
    payload: { id, params },
  };
};

export const getStatsPresentationsDaily = (id: String, params?: String) => {
  return {
    type: GET_STATS_PRESENTATIONS_DAILY,
    payload: { id, params },
  };
};

export const getStatsPresentationsDailySuccess = (presentationsDaily: IAnalyticsPresentationDaily) => {
  return {
    type: GET_STATS_PRESENTATIONS_DAILY_SUCCESS,
    payload: presentationsDaily,
  };
};

export const getStatsPresentationsSuccess = (presentations: IAnalyticsPresentation) => {
  return {
    type: GET_STATS_PRESENTATIONS_SUCCESS,
    payload: presentations,
  };
};

export const getStatsPresentationsError = (mes: String | Object) => {
  return {
    type: GET_STATS_PRESENTATIONS_ERROR,
    payload: mes,
  };
};

export const getStatsSlides = (id: String, params?: String) => {
  return {
    type: GET_STATS_SLIDES,
    payload: { id, params },
  };
};

export const getStatsSlidesSuccess = (slide: IAnalyticsSlide) => {
  return {
    type: GET_STATS_SLIDES_SUCCESS,
    payload: slide,
  };
};

export const getStatsSlidesDaily = (id: String, params?: String) => {
  return {
    type: GET_STATS_SLIDES_DAILY,
    payload: { id, params },
  };
};

export const getStatsSlidesDailySuccess = (slide: IAnalyticsSlideDaily) => {
  return {
    type: GET_STATS_SLIDES_DAILY_SUCCESS,
    payload: slide,
  };
};

export const getStatsSlidesError = (mes: String | Object) => {
  return {
    type: GET_STATS_SLIDES_ERROR,
    payload: mes,
  };
};

export const deleteSession = (
  accountId: String,
  sessionId: String,
  resolve: any,
  reject: any,
) => {
  return {
    type: DELETE_SESSION,
    payload: { accountId, sessionId, resolve, reject },
  };
};

export const deleteSessionSuccess = (mes: String) => {
  return {
    type: DELETE_SESSION_SUCCESS,
    payload: mes,
  };
};

export const deleteSessionError = (mes: String | Object) => {
  return {
    type: DELETE_SESSION_ERROR,
    payload: mes,
  };
};

export const getStatsRecordings = (id: String, params?: String) => {
  return {
    type: GET_STATS_RECORDINGS,
    payload: { id, params },
  };
};

export const getStatsRecordingsSuccess = (recordings: IAnalyticsSlide) => {
  return {
    type: GET_STATS_RECORDINGS_SUCCESS,
    payload: recordings,
  };
};

export const getStatsRecordingsError = (mes: String | Object) => {
  return {
    type: GET_STATS_RECORDINGS_ERROR,
    payload: mes,
  };
};

export const deleteStatsRecording = (id: String, callback?: Function) => {
  return {
    type: DELETE_STATS_RECORDINGS,
    payload: { id, callback },
  };
};

export const deleteStatsRecordingsSuccess = (mes: String) => {
  return {
    type: DELETE_STATS_RECORDINGS_SUCCESS,
    payload: mes,
  };
};

export const deleteStatsRecordingsError = (mes: String | Object) => {
  return {
    type: DELETE_STATS_RECORDINGS_ERROR,
    payload: mes,
  };
};

export const getStatsViews = (id: String, params?: String) => {
  return {
    type: GET_STATS_VIEWS,
    payload: { id, params },
  };
};

export const getStatsViewsSuccess = (views: IAnalyticsView) => {
  return {
    type: GET_STATS_VIEWS_SUCCESS,
    payload: views,
  };
};

export const getStatsViewsError = (mes: String | Object) => {
  return {
    type: GET_STATS_VIEWS_ERROR,
    payload: mes,
  };
};

export const getStatsParticipants = (id: String, params?: String) => {
  return {
    type: GET_STATS_PARTICIPANTS,
    payload: { id, params },
  };
};

export const getStatsParticipantsSuccess = (participant: IAnalyticsParticipants) => {
  return {
    type: GET_STATS_PARTICIPANTS_SUCCESS,
    payload: participant,
  };
};

export const getStatsParticipantsDaily = (id: String, params?: String) => {
  return {
    type: GET_STATS_PARTICIPANTS_DAILY,
    payload: { id, params },
  };
};

export const getStatsParticipantsDailySuccess = (participant: IAnalyticsParticipantsDaily) => {
  return {
    type: GET_STATS_PARTICIPANTS_DAILY_SUCCESS,
    payload: participant,
  };
};

export const getStatsParticipantsError = (mes: String | Object) => {
  return {
    type: GET_STATS_PARTICIPANTS_ERROR,
    payload: mes,
  };
};

export const getStatsMembers = (id: String, params?: String) => {
  return {
    type: GET_STATS_MEMBERS,
    payload: { id, params },
  };
};

export const getStatsMembersSuccess = (member: IAnalyticsMembers) => {
  return {
    type: GET_STATS_MEMBERS_SUCCESS,
    payload: member,
  };
};

export const getStatsMembersDaily = (id: String, params?: String) => {
  return {
    type: GET_STATS_MEMBERS_DAILY,
    payload: { id, params },
  };
};

export const getStatsMembersDailySuccess = (member: IAnalyticsMembersDaily) => {
  return {
    type: GET_STATS_MEMBERS_DAILY_SUCCESS,
    payload: member,
  };
};

export const getStatsMembersError = (mes: String | Object) => {
  return {
    type: GET_STATS_MEMBERS_ERROR,
    payload: mes,
  };
};

export const getSharedLinks = (id: String, params?: String) => {
  return {
    type: GET_STATS_SHARED_LINKS,
    payload: { id, params },
  };
};

export const getSharedLinksSuccess = (sharedLinks: IAnalyticsSharedLink) => {
  return {
    type: GET_STATS_SHARED_LINKS_SUCCESS,
    payload: sharedLinks,
  };
};

export const getSharedLinksError = (mes: String | Object) => {
  return {
    type: GET_STATS_SHARED_LINKS_ERROR,
    payload: mes,
  };
};

export const getSharedLinksDaily = (id: String, params?: String) => {
  return {
    type: GET_STATS_SHARED_LINKS_DAILY,
    payload: { id, params },
  };
};

export const getSharedLinksDailySuccess = (
  sharedLinks: IAnalyticsSharedLinkDaily
) => {
  return {
    type: GET_STATS_SHARED_LINKS_DAILY_SUCCESS,
    payload: sharedLinks,
  };
};

export const getStatsRoomSessions = (id: String, params?: String) => {
  return {
    type: GET_STATS_ROOM_SESSIONS,
    payload: { id, params },
  };
};

export const getStatsRoomSessionsSuccess = (roomSessions: IAnalyticsRoomSession) => {
  return {
    type: GET_STATS_ROOM_SESSIONS_SUCCESS,
    payload: roomSessions,
  };
};

export const getStatsRoomSessionsError = (mes: String | Object) => {
  return {
    type: GET_STATS_ROOM_SESSIONS_ERROR,
    payload: mes,
  };
};

export const getStatsRoomSessionsDaily = (id: String, params?: String) => {
  return {
    type: GET_STATS_ROOM_SESSIONS_DAILY,
    payload: { id, params },
  };
};

export const getStatsRoomSessionsDailySuccess = (
  roomSessionsDaily: IAnalyticsRoomSessionDaily
) => {
  return {
    type: GET_STATS_ROOM_SESSIONS_DAILY_SUCCESS,
    payload: roomSessionsDaily,
  };
};

export const getStatsContacts = (id: String, params?: String) => {
  return {
    type: GET_STATS_CONTACTS,
    payload: { id, params },
  };
};

export const getStatsContactsSuccess = (contact: IAnalyticsContact) => {
  return {
    type: GET_STATS_CONTACTS_SUCCESS,
    payload: contact,
  };
};

export const getStatsContactsDaily = (id: String, params?: String) => {
  return {
    type: GET_STATS_CONTACTS_DAILY,
    payload: { id, params },
  };
};

export const getStatsContactsDailySuccess = (contact: IAnalyticsContactDaily) => {
  return {
    type: GET_STATS_CONTACTS_DAILY_SUCCESS,
    payload: contact,
  };
};

export const getStatsContactsError = (mes: String | Object) => {
  return {
    type: GET_STATS_CONTACTS_ERROR,
    payload: mes,
  };
};

export const getStatsCompanies = (id: String, params?: String) => {
  return {
    type: GET_STATS_COMPANIES,
    payload: { id, params },
  };
};

export const getStatsCompaniesSuccess = (company: IAnalyticsCompany) => {
  return {
    type: GET_STATS_COMPANIES_SUCCESS,
    payload: company,
  };
};

export const getStatsCompaniesDaily = (id: String, params?: String) => {
  return {
    type: GET_STATS_COMPANIES_DAILY,
    payload: { id, params },
  };
};

export const getStatsCompaniesDailySuccess = (company: IAnalyticsCompanyDaily) => {
  return {
    type: GET_STATS_COMPANIES_DAILY_SUCCESS,
    payload: company,
  };
};

export const getStatsCompaniesError = (mes: String | Object) => {
  return {
    type: GET_STATS_COMPANIES_ERROR,
    payload: mes,
  };
};

export const getTimeLine = (accountId: String, sessionId: String) => {
  return {
    type: GET_TIME_LINE,
    payload: { accountId, sessionId },
  };
};

export const getTimeLineSuccess = (timeLine: IAnalyticsMeetingTimeline) => {
  return {
    type: GET_TIME_LINE_SUCCESS,
    payload: timeLine,
  };
};

export const getTimeLineError = (mes: String | Object) => {
  return {
    type: GET_TIME_LINE_ERROR,
    payload: mes,
  };
};

export const getTimeLineComments = (accountId: String, sessionId: String) => {
  return {
    type: GET_TIME_LINE_COMMENTS,
    payload: { accountId, sessionId },
  };
};

export const getTimeLineCommentsSuccess = (timeLineComments: IAnalyticsMeetingTimelineComment) => {
  return {
    type: GET_TIME_LINE_COMMENTS_SUCCESS,
    payload: timeLineComments,
  };
};

export const getTimeLineCommentsError = (mes: String | Object) => {
  return {
    type: GET_TIME_LINE_COMMENTS_ERROR,
    payload: mes,
  };
};

export const addTimeLineComment = (
  accountId: String,
  sessionId: String,
  comment: Object,
  resolve: any,
  reject: any,
) => {
  return {
    type: ADD_TIME_LINE_COMMENT,
    payload: { accountId, sessionId, comment, resolve, reject },
  };
};

export const addTimeLineCommentSuccess = (mes: String) => {
  return {
    type: ADD_TIME_LINE_COMMENT_SUCCESS,
    payload: mes,
  };
};

export const addTimeLineCommentError = (mes: String | Object) => {
  return {
    type: ADD_TIME_LINE_COMMENT_ERROR,
    payload: mes,
  };
};

export const deleteTimeLineComment = (
  commentId: String,
  resolve: any,
  reject: any,
) => {
  return {
    type: DELETE_TIME_LINE_COMMENT,
    payload: { commentId, resolve, reject },
  };
};

export const deleteTimeLineCommentSuccess = (mes: String) => {
  return {
    type: DELETE_TIME_LINE_COMMENT_SUCCESS,
    payload: mes,
  };
};

export const deleteTimeLineCommentError = (mes: String | Object) => {
  return {
    type: DELETE_TIME_LINE_COMMENT_ERROR,
    payload: mes,
  };
};


export const getMeetingAIAnalytics = (accountId: String, sessionId: String) => {
  return {
    type: GET_MEETING_AI_ANALYTICS,
    payload: { accountId, sessionId },
  };
};

export const getMeetingAIAnalyticsSuccess = (meetingAIAnalytics: IAnalyticsMeetingAIAnalytics) => {
  return {
    type: GET_MEETING_AI_ANALYTICS_SUCCESS,
    payload: meetingAIAnalytics,
  };
};

export const getMeetingAIAnalyticsError = (mes: String | Object) => {
  return {
    type: GET_MEETING_AI_ANALYTICS_ERROR,
    payload: mes,
  };
};

export const shareRecording = (data: Object, onSuccess: any) => {
  return {
    type: SHARE_RECORDING,
    payload: {
      data,
      onSuccess
    },
  };
};

export const shareRecordingSuccess = (mes: String) => {
  return {
    type: SHARE_RECORDING_SUCCESS,
    payload: mes,
  };
};

export const shareRecordingError = (error: String | Object) => {
  return {
    type: SHARE_RECORDING_ERROR,
    payload: error,
  };
};

export const getRecordingShareLinksForRecording = (payload: Object) => {
  return {
    type: GET_RECORDING_SHARE_LINKS_FOR_RECORDING,
    payload: { payload },
  };
};

export const getRecordingShareLinksForRecordingSuccess = (mes: String) => {
  return {
    type: GET_RECORDING_SHARE_LINKS_FOR_RECORDING_SUCCESS,
    payload: mes,
  };
};

export const getRecordingShareLinksForRecordingError = (error: String | Object) => {
  return {
    type: GET_RECORDING_SHARE_LINKS_FOR_RECORDING_ERROR,
    payload: error,
  };
};

export const getRecordingShareLinkByLinkHash = (linkHash: string, onSuccess: any) => {
  return {
    type: GET_RECORDING_SHARE_LINK_BY_LINK_HASH,
    payload: { linkHash, onSuccess },
  };
};

export const getRecordingShareLinkByLinkHashSuccess = (recordingShareLink: Object) => {
  return {
    type: GET_RECORDING_SHARE_LINK_BY_LINK_HASH_SUCCESS,
    payload: recordingShareLink,
  };
};

export const getRecordingShareLinkByLinkHashError = (error: String | Object) => {
  return {
    type: GET_RECORDING_SHARE_LINK_BY_LINK_HASH_ERROR,
    payload: error,
  };
};

export const updateRecordingShareLink = (id: string, data: Object, onSuccess: any) => {
  return {
    type: UPDATE_RECORDING_SHARE_LINK,
    payload: { id, data, onSuccess },
  };
};

export const updateRecordingShareLinkSuccess = (mes: String) => {
  return {
    type: UPDATE_RECORDING_SHARE_LINK_SUCCESS,
    payload: mes,
  };
};

export const updateRecordingShareLinkError = (error: String | Object) => {
  return {
    type: UPDATE_RECORDING_SHARE_LINK_ERROR,
    payload: error,
  };
};

export const deleteRecordingShareLink = (data: Object, onSuccess: any) => {
  return {
    type: DELETE_RECORDING_SHARE_LINK,
    payload: { data, onSuccess },
  };
};

export const deleteRecordingShareLinkSuccess = (mes: String) => {
  return {
    type: DELETE_RECORDING_SHARE_LINK_SUCCESS,
    payload: mes,
  };
};

export const deleteRecordingShareLinkError = (error: String | Object) => {
  return {
    type: DELETE_RECORDING_SHARE_LINK_ERROR,
    payload: error,
  };
};

export const getRecordingSnippets = (payload: Object) => {
  return {
    type: GET_RECORDING_SNIPPETS,
    payload: { payload },
  };
};

export const getRecordingSnippetsSuccess = (data: Object) => {
  return {
    type: GET_RECORDING_SNIPPETS_SUCCESS,
    payload: data,
  };
};

export const getSnippet = (payload: Object) => {
  return {
    type: GET_SNIPPET,
    payload: { id: payload }
  }
}

export const getSnippetSuccess = (payload: Object) => {
  return {
    type: GET_SNIPPET_SUCCESS,
    payload: payload
  }
}

export const getSnippetError = (error: String | Object) => {
  return {
    type: GET_SNIPPET_ERROR,
    payload: error
  }
}

export const getSnippetTimeLine = (accountId: String, snippetUuid: String) => {
  return {
    type: GET_SNIPPET_TIME_LINE,
    payload: { accountId, snippetUuid },
  };
};

export const getSnippetTimeLineSuccess = (timeLine: IAnalyticsMeetingTimeline) => {
  return {
    type: GET_SNIPPET_TIME_LINE_SUCCESS,
    payload: timeLine,
  };
};

export const getSnippetTimeLineError = (mes: String | Object) => {
  return {
    type: GET_SNIPPET_TIME_LINE_ERROR,
    payload: mes,
  };
};

export const postRecordingSnippet = (sessionId: string, data: Object, onSuccess: any) => {
  return {
    type: POST_RECORDING_SNIPPET,
    payload: { sessionId, data, onSuccess },
  };
};

export const postRecordingSnippetSuccess = (mes: String) => {
  return {
    type: POST_RECORDING_SNIPPET_SUCCESS,
    payload: mes,
  };
};

export const getSnippetShareLinksForSnippet = (payload: Object) => {
  return {
    type: GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET,
    payload: { payload },
  };
};

export const getSnippetShareLinksForSnippetSuccess = (mes: String) => {
  return {
    type: GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET_SUCCESS,
    payload: mes,
  };
};

export const getSnippetShareLinksForSnippetError = (error: String | Object) => {
  return {
    type: GET_SNIPPET_SHARE_LINKS_FOR_SNIPPET_ERROR,
    payload: error,
  };
};

export const getSnippetShareLinkByLinkHash = (linkHash: string, onSuccess: any) => {
  return {
    type: GET_SNIPPET_SHARE_LINK_BY_LINK_HASH,
    payload: { linkHash, onSuccess },
  };
};

export const getSnippetShareLinkByLinkHashSuccess = (snippetShareLink: Object) => {
  return {
    type: GET_SNIPPET_SHARE_LINK_BY_LINK_HASH_SUCCESS,
    payload: snippetShareLink,
  };
};

export const getSnippetShareLinkByLinkHashError = (error: String | Object) => {
  return {
    type: GET_SNIPPET_SHARE_LINK_BY_LINK_HASH_ERROR,
    payload: error,
  };
};

export const createSnippetShareLink = (data: Object, onSuccess: any) => {
  return {
    type: CREATE_SNIPPET_SHARE_LINK,
    payload: {
      data,
      onSuccess
    },
  };
};

export const createSnippetShareLinkSuccess = (mes: String) => {
  return {
    type: CREATE_SNIPPET_SHARE_LINK_SUCCESS,
    payload: mes,
  };
};

export const createSnippetShareLinkError = (error: String | Object) => {
  return {
    type: CREATE_SNIPPET_SHARE_LINK_ERROR,
    payload: error,
  };
};

export const updateSnippetShareLink = (id: number, data: Object, onSuccess: any) => {
  return {
    type: UPDATE_SNIPPET_SHARE_LINK,
    payload: {
      id,
      data,
      onSuccess
    },
  }
}

export const updateSnippetShareLinkSuccess = (mes: String) => {
  return {
    type: UPDATE_SNIPPET_SHARE_LINK_SUCCESS,
    payload: mes,
  };
}

export const updateSnippetShareLinkError = (error: String | Object) => {
  return {
    type: UPDATE_SNIPPET_SHARE_LINK_ERROR,
    payload: error,
  };
}

export const updateSnippet = (id: string, data: Object, onSuccess: any) => {
  return {
    type: UPDATE_SNIPPET,
    payload: {
      id,
      data,
      onSuccess
    },
  }
}

export const updateSnippetSuccess = (mes: String) => {
  return {
    type: UPDATE_SNIPPET_SUCCESS,
    payload: mes,
  };
}

export const updateSnippetError = (error: String | Object) => {
  return {
    type: UPDATE_SNIPPET_ERROR,
    payload: error,
  };
}

export const deleteSnippetShareLink = (data: Object, onSuccess: any) => {
  return {
    type: DELETE_SNIPPET_SHARE_LINK,
    payload: {
      id: data,
      onSuccess
    },
  }
}

export const deleteSnippetShareLinkSuccess = (mes: String) => {
  return {
    type: DELETE_SNIPPET_SHARE_LINK_SUCCESS,
    payload: mes,
  };
}

export const deleteSnippetShareLinkError = (error: String | Object) => {
  return {
    type: DELETE_SNIPPET_SHARE_LINK_ERROR,
    payload: error,
  };
}

export const getLogs = (data: Object) => {
  return {
    type: GET_LOGS,
    payload: data,
  };
}

export const getLogsSuccess = (data: IEventLog) => {
  return {
    type: GET_LOGS_SUCCESS,
    payload: data,
  };
}

export const getLogsError = (message: string) => {
  return {
    type: GET_LOGS_ERROR,
    payload: message,
  };
}

export const getUserProfileSetupFinished = (data: Object) => {
  return {
    type: GET_USER_PROFILE_SETUP_FINISHED,
    payload: data,
  };
}

export const getUserProfileSetupFinishedSuccess = (data: boolean) => {
  return {
    type: GET_USER_PROFILE_SETUP_FINISHED_SUCCESS,
    payload: data,
  };
}

export const getUserProfileSetupFinishedError = (message: string) => {
  return {
    type: GET_USER_PROFILE_SETUP_FINISHED_ERROR,
    payload: message,
  };
}

export const getIntegrationSetupFinished = (data: Object) => {
  return {
    type: GET_INTEGRATION_SETUP_FINISHED,
    payload: data,
  };
}

export const getIntegrationSetupFinishedSuccess = (data: boolean) => {
  return {
    type: GET_INTEGRATION_SETUP_FINISHED_SUCCESS,
    payload: data,
  };
}

export const getIntegrationSetupFinishedError = (message: string) => {
  return {
    type: GET_INTEGRATION_SETUP_FINISHED_ERROR,
    payload: message,
  };
}

export const createLog = ({ data, onSuccess }: { data: any, onSuccess?: any }) => {
  return {
    type: CREATE_LOG,
    payload: {
      data,
      onSuccess
    },
  }
}

export const createLogSuccess = (message: string) => {
  return {
    type: CREATE_LOG_SUCCESS,
    payload: message,
  }
}

export const createLogError = (message: string) => {
  return {
    type: CREATE_LOG_ERROR,
    payload: message,
  }
}

export const getRecordingTranscript = (sessionId: String, filters?: IRecordingTranscriptFilters) => {
  return {
    type: GET_RECORDING_TRANSCRIPT,
    payload: { sessionId, filters },
  };
};

export const getRecordingTranscriptSuccess = (transcript: ITranscript[]) => {
  return {
    type: GET_RECORDING_TRANSCRIPT_SUCCESS,
    payload: transcript,
  };
};

export const getRecordingTranscriptError = (mes: String | Object) => {
  return {
    type: GET_RECORDING_TRANSCRIPT_ERROR,
    payload: mes,
  };
};

export const getSnippetRecordingTranscript = (snippetUuid: String) => {
  return {
    type: GET_SNIPPET_RECORDING_TRANSCRIPT,
    payload: { snippetUuid },
  };
};

export const getSnippetRecordingTranscriptSuccess = (transcript: ITranscript[]) => {
  return {
    type: GET_SNIPPET_RECORDING_TRANSCRIPT_SUCCESS,
    payload: transcript,
  };
};

export const getSnippetRecordingTranscriptError = (mes: String | Object) => {
  return {
    type: GET_SNIPPET_RECORDING_TRANSCRIPT_ERROR,
    payload: mes,
  };
};

export const searchRecordingTranscripts = (accountId: String, search: String, params?: String) => {
  return {
    type: SEARCH_RECORDING_TRANSCRIPTS,
    payload: { accountId, search, params },
  }
}

export const searchRecordingTranscriptsSuccess = (transcriptStats: ITranscriptStats) => {
  return {
    type: SEARCH_RECORDING_TRANSCRIPTS_SUCCESS,
    payload: transcriptStats,
  };
};

export const searchRecordingTranscriptsError = (mes: String | Object) => {
  return {
    type: SEARCH_RECORDING_TRANSCRIPTS_ERROR,
    payload: mes,
  };
};

export const searchRecordingTranscriptsEmpty = () => {
  return {
    type: SEARCH_RECORDING_TRANSCRIPTS_EMPTY,
    payload: null,
  }
}

export const getAiCategories = () => {
  return {
    type: GET_AI_CATEGORIES,
    payload: null,
  }
}

export const getAiCategoriesSuccess = (data: IAiCategory[]) => {
  return {
    type: GET_AI_CATEGORIES_SUCCESS,
    payload: data,
  }
}

export const getAiCategoriesError = (mes: String | Object) => {
  return {
    type: GET_AI_CATEGORIES_ERROR,
    payload: mes,
  };
};

export const getAllTagsByAccount = (accountId: String) => {
  return {
    type: GET_ALL_TAGS_BY_ACCOUNT,
    payload: { accountId },
  };
}

export const getAllTagsByAccountSuccess = (tags: TagType[]) => {
  return {
    type: GET_ALL_TAGS_BY_ACCOUNT_SUCCESS,
    payload: tags,
  };
};

export const addRecordingTag = (name: string, sessionId: String, accountId: String) => {
  return {
    type: ADD_RECORDING_TAGS,
    payload: { name, sessionId, accountId },
  }
}

export const addRecordingTagSuccess = (tag: Tag) => {
  return {
    type: ADD_RECORDING_TAG_SUCCESS,
    payload: tag,
  }
}

export const deleteRecordingTagSuccess = (id: string) => {
  return {
    type: DELETE_RECORDING_TAG_SUCCESS,
    payload: id,
  }
}

export const deleteRecordingTag = (id: string, sessionId: String, accountId: String) => {
  return {
    type: DELETE_RECORDING_TAGS,
    payload: { id, sessionId, accountId },
  }
}

export const addSnippetTag = (name: string, snippetUuid: String, accountId: String) => {
  return {
    type: ADD_SNIPPET_TAGS,
    payload: { name, snippetUuid, accountId },
  }
}

export const addSnippetTagSuccess = (tag: SnippetTag[]) => {
  return {
    type: ADD_SNIPPET_TAG_SUCCESS,
    payload: tag,
  }
}

export const deleteSnippetTag = (id: string, snippetUuid: String, accountId: String) => {
  return {
    type: DELETE_SNIPPET_TAG,
    payload: { id, snippetUuid, accountId },
  }
}

export const deleteSnippetTagSuccess = (id: string) => {
  return {
    type: DELETE_SNIPPET_TAG_SUCCESS,
    payload: id,
  }
}

export const getSnippetTagsError = (mes: String | Object) => {
  return {
    type: GET_SNIPPET_TAG_ERROR,
    payload: mes,
  };
};

export const createTag = (name: string, accountId: String) => {
  return {
    type: CREATE_TAG,
    payload: { name, accountId },
  }
}

export const createTagSuccess = (tag: TagType) => {
  return {
    type: CREATE_TAG_SUCCESS,
    payload: tag,
  }
}

export const createTagError = (error: string | Object ) => {
  return {
    type: CREATE_TAG_ERROR,
    payload: error
  }
}

export const deleteTag = (id: string, accountId: String) => {
  return {
    type: DELETE_TAG,
    payload: { id, accountId },
  }
}

export const deleteTagSuccess = (id: string) => {
  return {
    type: DELETE_TAG_SUCCESS,
    payload: id
  }
}

export const deleteTagError = (error: string | Object ) => {
  return {
    type: DELETE_TAG_ERROR,
    payload: error
  }
}

export const editTag = (id: string, name: string, accountId: String) => {
  return {
    type: EDIT_TAG,
    payload: { id, name, accountId },
  }
}

export const editTagSuccess = (tag: TagType) => {
  return {
    type: EDIT_TAG_SUCCESS,
    payload: tag
  }
}

export const editTagError = (error: string | Object ) => {
  return {
    type: EDIT_TAG_ERROR,
    payload: error
  }
}

export const getTagAnalytics = (accountId: String, filters?: string) => {
  return {
    type: GET_TAG_ANALYTICS,
    payload: { accountId, filters },
  }
}

export const getTagAnalyticsSuccess = (tagAnalytics: ITagAnalytics[]) => {
  return {
    type: GET_TAG_ANALYTICS_SUCCESS,
    payload: tagAnalytics,
  }
}

export const getTagAnalyticsError = (error: String | Object) => {
  return {
    type: GET_TAG_ANALYTICS_ERROR,
    payload: error,
  }
}
