import axios, { AxiosResponse } from "axios";

import { ResponseGenerator } from "../userservice";
import { urls } from "../../utilities/urls";
import { endpoints } from "../../utilities/endpoints";
import { commonheaders } from "../../utilities/apiConfig";
import { ISnippetDetails } from "../../store/snippets/interface";

export async function getSnippetDetails(
  accountId: String,
  params: string,
): Promise<AxiosResponse<ISnippetDetails[]>> {
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.recordingDetails}/snippets?${params}`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function deleteSnippet(
  accountId: String,
  snippetId: String,
) {
  try {
    return await axios.delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.snippets}/${snippetId}`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function unshareSnippet(accountId: String, snippetUuid: string) {
  try {
    return await axios.post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.recordingDetails}/${endpoints.snippets}/${snippetUuid}/unshare`,
      commonheaders
    );
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}
