import {
  CREATE_TELAVOX_TOKEN,
  CREATE_TELAVOX_TOKEN_ERROR,
  CREATE_TELAVOX_TOKEN_SUCCESS,
  DELETE_TELAVOX_TOKEN,
  DELETE_TELAVOX_TOKEN_ERROR,
  DELETE_TELAVOX_TOKEN_SUCCESS,
  EDIT_TELAVOX_TOKEN,
  EDIT_TELAVOX_TOKEN_ERROR,
  EDIT_TELAVOX_TOKEN_SUCCESS,
  GET_TELAVOX_TOKEN,
  GET_TELAVOX_TOKEN_ERROR,
  GET_TELAVOX_TOKEN_SUCCESS,
} from './actionTypes';

import { TelavoxAction, TelavoxState, } from './interface';

const initialState: TelavoxState = {
  error: "",
  telavoxToken: null,
  isLoading: false,
}

const TelavoxIntegration = (state = initialState, action: TelavoxAction) => {
  switch (action.type) {
    case GET_TELAVOX_TOKEN:
    case CREATE_TELAVOX_TOKEN:
    case EDIT_TELAVOX_TOKEN:
    case DELETE_TELAVOX_TOKEN:
      state = {
        ...state,
        isLoading: true,
      }

      break;
    case GET_TELAVOX_TOKEN_SUCCESS:
    case CREATE_TELAVOX_TOKEN_SUCCESS:
    case EDIT_TELAVOX_TOKEN_SUCCESS:
    case DELETE_TELAVOX_TOKEN_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        telavoxToken: action.payload,
      }

      break;
    case GET_TELAVOX_TOKEN_ERROR:
    case CREATE_TELAVOX_TOKEN_ERROR:
    case EDIT_TELAVOX_TOKEN_ERROR:
    case DELETE_TELAVOX_TOKEN_ERROR:
      state = {
        ...state,
        isLoading: false,
        error: action.payload
      }

      break;
    default:
      state = {...state};
      break;
  }

  return state;
}

export default TelavoxIntegration;
