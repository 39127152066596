export const GET_DESTINY_TOKEN = 'GET_DESTINY_TOKEN';
export const GET_DESTINY_TOKEN_SUCCESS = 'GET_DESTINY_TOKEN_SUCCESS';
export const GET_DESTINY_TOKEN_ERROR = 'GET_DESTINY_TOKEN_ERROR';
export const CREATE_DESTINY_TOKEN = 'CREATE_DESTINY_TOKEN';
export const CREATE_DESTINY_TOKEN_SUCCESS = 'CREATE_DESTINY_TOKEN_SUCCESS';
export const CREATE_DESTINY_TOKEN_ERROR = 'CREATE_DESTINY_TOKEN_ERROR';
export const EDIT_DESTINY_TOKEN = 'EDIT_DESTINY_TOKEN';
export const EDIT_DESTINY_TOKEN_SUCCESS = 'EDIT_DESTINY_TOKEN_SUCCESS';
export const EDIT_DESTINY_TOKEN_ERROR = 'EDIT_DESTINY_TOKEN_ERROR';
export const DELETE_DESTINY_TOKEN = 'DELETE_DESTINY_TOKEN';
export const DELETE_DESTINY_TOKEN_SUCCESS = 'DELETE_DESTINY_TOKEN_SUCCESS';
export const DELETE_DESTINY_TOKEN_ERROR = 'DELETE_DESTINY_TOKEN_ERROR';