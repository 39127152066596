import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import _ from "lodash";
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";
import { forgotPasswordServices } from "../../../services/auth/authservice";

// //If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { dataObj, callback } }) {
  const response = yield call(forgotPasswordServices, dataObj);
  if (_.has(response, "error") || _.has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(userForgetPasswordError(error));
    } else {
      yield put(userForgetPasswordError("An Error has occured!!"));
    }
  } else {
    yield put(
      userForgetPasswordSuccess("Forgot password email sent successfully!")
    );
    callback(response);
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
