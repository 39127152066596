import { isEmpty } from 'lodash';

import {
  CREATE_ACCOUNT_TOKEN,
  CREATE_ACCOUNT_TOKEN_ERROR,
  CREATE_ACCOUNT_TOKEN_SUCCESS,
  DELETE_ACCOUNT_TOKEN,
  DELETE_ACCOUNT_TOKEN_ERROR,
  DELETE_ACCOUNT_TOKEN_SUCCESS,
  DISABLE_CRM_SERVICE_INTEGRATION_ERROR,
  DISABLE_CRM_SERVICE_INTEGRATION_SUCCESS,
  DISABLE_LYNES_INTEGRATION_ERROR,
  DISABLE_LYNES_INTEGRATION_SUCCESS,
  DISABLE_TELENOR_INTEGRATION_ERROR,
  DISABLE_TELENOR_INTEGRATION_SUCCESS,
  EDIT_ACCOUNT_TOKEN,
  EDIT_ACCOUNT_TOKEN_ERROR,
  EDIT_ACCOUNT_TOKEN_SUCCESS,
  ENABLE_CRM_SERVICE_INTEGRATION_ERROR,
  ENABLE_CRM_SERVICE_INTEGRATION_SUCCESS,
  ENABLE_LYNES_INTEGRATION_ERROR,
  ENABLE_LYNES_INTEGRATION_SUCCESS,
  ENABLE_TELENOR_INTEGRATION_ERROR,
  ENABLE_TELENOR_INTEGRATION_SUCCESS,
  GET_ACCOUNT_TOKENS,
  GET_ACCOUNT_TOKENS_ERROR,
  GET_ACCOUNT_TOKENS_SUCCESS,
  SET_CRM_SERVICE_INIT_STATE,
} from './actionTypes';
import { AccountTokenAction, AccountTokenState } from './interface';

const initialState: AccountTokenState = {
  loading: false,
  deleteLoading: false,
  accountTokens: [],
  error: '',
  authenticationData: null,
  isLynesIntegrationEnabled: false,
  isTelenorIntegrationEnabled: false,
  //TO DO: implement when this condition is not true
  areAllTokensDisabled: false,
  isCRMServiceIntegrationEnabled: false,
  CRMServiceIntegrationError: '',
};

const AccountTokens = (state = initialState, action: AccountTokenAction) => {
  switch (action.type) {
    case GET_ACCOUNT_TOKENS:
      state = {
        ...state,
        loading: true,
        accountTokens: [],
      };
      break;
    case GET_ACCOUNT_TOKENS_SUCCESS:
      const isLynesTokenPresent = action.payload.some(
        (token) => token.type === 'lynes'
      );
      const isTelenorTokenPresent = action.payload.some(
        (token) => token.type === 'telenor'
      );
      const areTokensDisabled = action.payload.every(token => !token.enabled);

      state = {
        ...state,
        loading: false,
        isLynesIntegrationEnabled: isLynesTokenPresent,
        isTelenorIntegrationEnabled: isTelenorTokenPresent,
        accountTokens: action.payload,
        areAllTokensDisabled: areTokensDisabled,
      };
      break;
    case GET_ACCOUNT_TOKENS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case DELETE_ACCOUNT_TOKEN:
      state = {
        ...state,
        deleteLoading: true,
      };
      break;
    case DELETE_ACCOUNT_TOKEN_SUCCESS:
      const updatedAccountTokens = state.accountTokens.filter(
        (token) => token.id !== action.payload
      );
      const isLynesIntegrationEnabled = !isEmpty(updatedAccountTokens)
        && updatedAccountTokens.some(token => token.type === 'lynes');
      const isTelenorIntegrationEnabled = !isEmpty(updatedAccountTokens)
        && updatedAccountTokens.some(token => token.type === 'telenor');

      state = {
        ...state,
        deleteLoading: false,
        isLynesIntegrationEnabled,
        isTelenorIntegrationEnabled,
        accountTokens: updatedAccountTokens,
      };
      break;
    case DELETE_ACCOUNT_TOKEN_ERROR:
      state = {
        ...state,
        deleteLoading: true,
        error: action.payload,
      };
      break;
    case CREATE_ACCOUNT_TOKEN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_ACCOUNT_TOKEN_SUCCESS:
      const newAccountToken = action.payload;
      state = {
        ...state,
        loading: false,
        isLynesIntegrationEnabled: state.isLynesIntegrationEnabled
          || newAccountToken.type === 'lynes',
        isTelenorIntegrationEnabled: state.isTelenorIntegrationEnabled
          || newAccountToken.type === 'telenor',
        areAllTokensDisabled: false,
        accountTokens: [...state.accountTokens, newAccountToken],
      };
      break;
    case CREATE_ACCOUNT_TOKEN_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case EDIT_ACCOUNT_TOKEN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_ACCOUNT_TOKEN_SUCCESS:
      const updatedTokens = state.accountTokens.map((token) => {
        if (token.id === action.payload.id) {
          return action.payload;
        }
        return token;
      });
      const areAccountTokensDisabled = updatedTokens.every(token => !token.enabled);

      state = {
        ...state,
        loading: false,
        areAllTokensDisabled: areAccountTokensDisabled,
        accountTokens: updatedTokens,
      };
      break;
    case EDIT_ACCOUNT_TOKEN_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case ENABLE_LYNES_INTEGRATION_SUCCESS:
      state = {
        ...state,
        isLynesIntegrationEnabled: true,
        authenticationData: action.payload,
      };
      break;
    case ENABLE_LYNES_INTEGRATION_ERROR:
      state = {
        ...state,
        isLynesIntegrationEnabled: false,
        error: action.payload,
      };
      break;
    case DISABLE_LYNES_INTEGRATION_SUCCESS:
      const remainingTokens = state.accountTokens.filter(
        (token) => token.type !== 'lynes'
      );

      state = {
        ...state,
        authenticationData: null,
        isLynesIntegrationEnabled: false,
        accountTokens: remainingTokens,
      };
      break;
    case DISABLE_LYNES_INTEGRATION_ERROR:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case ENABLE_TELENOR_INTEGRATION_SUCCESS:
      state = {
        ...state,
        isTelenorIntegrationEnabled: true,
        authenticationData: action.payload,
      };
      break;
    case ENABLE_TELENOR_INTEGRATION_ERROR:
      state = {
        ...state,
        isTelenorIntegrationEnabled: false,
        error: action.payload,
      };
      break;
    case DISABLE_TELENOR_INTEGRATION_SUCCESS:
      const remainingTelenorTokens = state.accountTokens.filter(
        (token) => token.type !== 'telenor'
      );

      state = {
        ...state,
        authenticationData: null,
        isTelenorIntegrationEnabled: false,
        accountTokens: remainingTelenorTokens,
      };
      break;
    case DISABLE_TELENOR_INTEGRATION_ERROR:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case ENABLE_CRM_SERVICE_INTEGRATION_SUCCESS:
      state = {
        ...state,
        isCRMServiceIntegrationEnabled: action.payload.enabled,
      }
      break;
    case ENABLE_CRM_SERVICE_INTEGRATION_ERROR:
      state = {
        ...state,
        CRMServiceIntegrationError: action.payload,
      }
      break;
    case DISABLE_CRM_SERVICE_INTEGRATION_SUCCESS:
      state = {
        ...state,
        isCRMServiceIntegrationEnabled: !action.payload.disabled,
      }
      break;
    case DISABLE_CRM_SERVICE_INTEGRATION_ERROR:
      state = {
        ...state,
        CRMServiceIntegrationError: action.payload,
      }
      break;
    case SET_CRM_SERVICE_INIT_STATE:
      state = {
        ...state,
        isCRMServiceIntegrationEnabled: true,
      }
    break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default AccountTokens;
