import React, { Component } from "react";
import { Modal, Col, Row, Button } from "reactstrap";

interface DeleteModalProps {
  isOpen: boolean;
  title: string;
  fullTitle?: string;
  confirmText?: string;
  cancelText?: string;
  handleDelete: () => void;
  handleClose: () => void;
}

class DeleteModal extends Component<DeleteModalProps> {
  render() {
    const { title, confirmText, cancelText, fullTitle, isOpen, handleDelete, handleClose } = this.props;

    return (
      <Modal size="md" className="p-5" isOpen={isOpen} centered>
        <div
          className="modal-header user-modal"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {fullTitle ? fullTitle : `Are you sure you want to delete current ${title}?`}
          </h5>
        </div>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Col
            md="8"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "5px",
              gap: "10px",
            }}
          >
            <Button
              color="red"
              type="submit"
              className="btn-danger"
              onClick={handleDelete}
            >
              {confirmText ? confirmText : 'Yes, delete'}
            </Button>
            <Button color="blue" type="submit" onClick={handleClose}>
              {cancelText ? cancelText : 'No, cancel'}
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default DeleteModal;
