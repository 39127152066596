import { getLocalStorage } from "../services/utilities/utilservice";
import { cookies } from '../services/utilities/utilservice'
import { SHARE_TOKEN_ROUTES } from "./constants";

export const mainRequestConfig = (config: any) => {

  const mainAuthToken = cookies.get("JWT_Token");
  const shareAuthToken = getLocalStorage("share-link-token");

  if (mainAuthToken) {
    config.headers!.Authorization = mainAuthToken;
  } else if (shareAuthToken) {
    config.headers!.Authorization = `JWT ${shareAuthToken}`;
  }

  if (shareAuthToken && isShareTokenRoute()) {
    config.headers!.ShareToken = shareAuthToken;
  }

  return config;
}

const isShareTokenRoute = () => {
  return SHARE_TOKEN_ROUTES.some((route: string) => window.location.pathname.startsWith(route));
}
