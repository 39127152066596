import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';

import loaderImg from '../../assets/images/loader.gif';
import pitchflowLogosmall from '../../assets/images/logo-small.svg';
import pitchflowLogo from '../../assets/images/pitchflow_logo_white_trans.svg';
import VideoPlayer from '../../components/Recording/VideoPlayer';
import { loginShareLink } from '../../store/auth/login/actions';
import {
  getMeetingAIAnalytics,
  getRecordingShareLinkByLinkHash,
  getRecordingTranscript,
  getStatsMeetings,
  getStatsRecordings,
  getTimeLine
} from '../../store/Analytics/actions';
import {
  IAnalyticsMeetingAIAnalytics,
  IAnalyticsMeetings,
  IAnalyticsMeetingTimeline,
  IAnalyticsRecordings,
  IRecordingTranscriptFilters,
  ITranscript,
} from '../../store/Analytics/interface';
import { changeSidebarTheme } from '../../store/layout/actions';
import Navbar from '../../components/HorizontalLayout/Navbar';
import AccessDenied from "./AccessDeniedPage";
import {deleteLocalStorage} from "../../services/utilities/utilservice";

const ACCESS_DENIED = {
  title: "You don't have access to this recording",
  description: "Please ask the recording owner for a shared link",
}

interface IRecording {
  account: { id: String, name: String };
  created_at: Date;
  id: string;
  session_id: string;
  url: string;
}

interface IRecordingShareLink {
  created_at: Date;
  emails: string[];
  expires_at: Date;
  id: number;
  include_ai_insights: boolean;
  include_comments: boolean;
  can_comment?: boolean;
  include_enriched_timeline: boolean;
  link_hash: string;
  name: string;
  owner: Object;
  recording: IRecording;
  users: Object[];
}

interface SharedRecordingPageProps {
  currentuser: any;
  match: any;
  leftSideBarTheme: String;
  meeting: IAnalyticsMeetings,
  recordings: IAnalyticsRecordings,
  recordingLoader: boolean,
  getStatsMeetings: (id: String, params?: String) => void;
  meetingAIAnalytics: IAnalyticsMeetingAIAnalytics[];
  getMeetingAIAnalytics: (accountId: String, sessionId: String) => void;
  timeLineData: IAnalyticsMeetingTimeline[];
  getTimeLine: (accountId: String, sessionId: String) => void;
  getStatsRecordings: (id: String, params?: String) => void;
  recordingShareLink: null | IRecordingShareLink;
  recordingShareLinksError?: string | null
  loginShareLink: (shareLink: string, onSuccess: any) => void;
  getRecordingShareLinkByLinkHash: (linkHash: string, onSuccess: any) => void;
  changeSidebarTheme: (type: String) => void;
  recordingTranscript: ITranscript | null;
  getRecordingTranscript: (sessionId: string, filters?: IRecordingTranscriptFilters) => void;
}

class SharedRecordingPage extends Component<SharedRecordingPageProps, {}> {
  meetingsLoaded: boolean = false;
  recordingsLoaded: boolean = false;

  constructor(props: SharedRecordingPageProps) {
    super(props);

    const {
      loginShareLink: loginShareLinkAction,
      getRecordingShareLinkByLinkHash: getRecordingShareLinkByLinkHashAction,
      changeSidebarTheme: changeSidebarThemeAction,
      match,
      leftSideBarTheme,
    } = this.props;

    const linkHash = match.params.linkHash;
    deleteLocalStorage('share-link-token');

    if (leftSideBarTheme) {
      changeSidebarThemeAction(leftSideBarTheme);
    }
    loginShareLinkAction(linkHash, () => {
      getRecordingShareLinkByLinkHashAction(linkHash, () => {});
    });
  }

  componentDidUpdate(prevProps: Readonly<SharedRecordingPageProps>, prevState: Readonly<any>, snapshot?: any) {
    const {
      recordingShareLink,
      getStatsMeetings: getStatsMeetingsAction,
      getStatsRecordings: getStatsRecordingsAction,
    } = this.props;

    if (
      recordingShareLink
      && !this.meetingsLoaded
    ) {
      this.meetingsLoaded = true;
      getStatsMeetingsAction(
        recordingShareLink.recording.account.id,
        'session_id=' + recordingShareLink.recording.session_id,
      );
    }
    if (
      recordingShareLink
      && !this.recordingsLoaded
    ) {
      this.recordingsLoaded = true;
      getStatsRecordingsAction(recordingShareLink.recording.session_id,);
    }
  }

  componentDidMount(){
    document.body.setAttribute('data-layout', 'horizontal');
  }

  renderSideBar() {
    return (
      <>
        <header id="page-topbar" className="custome-horizontal-header">
          <div className="navbar-header">
            <div className="d-flex w-100">
              <div className="navbar-brand-box custome-box logo-box">
                <div
                  className="logo logo-light"
                >
                  <span className="logo-sm">
                    <img
                      style={{ width: "26px", height: "auto" }}
                      src={pitchflowLogosmall}
                      alt="small logo"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      style={{ width: "140px", height: "auto" }}
                      src={pitchflowLogo}
                      alt="big logo"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="vertical-menu custome-vertical-menu">
          <div data-simplebar className="h-100 full-sidebar">
            <div id="sidebar-menu" className={`sidebar-default responsive-menu`}>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const {
      meeting,
      recordings,
      recordingLoader,
      recordingShareLink,
      recordingShareLinksError,
      meetingAIAnalytics,
      timeLineData,
      recordingTranscript,
      getTimeLine: getTimeLineAction,
      getMeetingAIAnalytics: getMeetingAIAnalyticsAction,
      getRecordingTranscript: getRecordingTranscriptAction,
      currentuser,
    } = this.props;

    const content = recordingShareLink
      ? (
        <VideoPlayer
          sessionId={recordingShareLink.recording.session_id}
          recordingSessionId={recordingShareLink.recording.session_id}
          accountId={recordingShareLink.recording.account.id}
          currentuser={currentuser}
          recording={recordings}
          recordingLoader={recordingLoader}
          meeting={meeting}
          timeLineData={timeLineData}
          getTimeLine={getTimeLineAction}
          meetingAIAnalytics={meetingAIAnalytics}
          getMeetingAIAnalytics={getMeetingAIAnalyticsAction}
          showSnippets={false}
          showComments={recordingShareLink.include_comments}
          canComment={recordingShareLink.can_comment}
          showAddSnippet={false}
          showTranscription={true}
          recordingTranscript={recordingTranscript}
          getRecordingTranscript={getRecordingTranscriptAction}
          snippetPostSuccess={""}
          snippets={[]}
          showTabs={['transcript', 'q&a']}
          isSuperAdmin={false}
        />
      ) :
      recordingShareLinksError ?
        <AccessDenied title={ACCESS_DENIED.title} description={ACCESS_DENIED.description}/>
        : (
        <Row className="metting-main m-0 pt-4 pb-0">
          <Container className="text-center">
            <div className="loader-wrapper">
              <img src={loaderImg} alt="" />
            </div>
          </Container>
        </Row>
        );

    return (
      <>
        <div id="layout-wrapper d-flex flex-column">
          <Navbar menuOpen={true} showContent={false}></Navbar>
          <div className="main-content">
            {content}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const {
    leftSideBarTheme,
  } = state.Layout;
  const {
    meetings,
    recordings,
    recordingLoader,
    recordingShareLink,
    recordingShareLinksError,
    meetingAIAnalytics,
    timeLineData,
    recordingTranscript,
  } = state.SalesAnalytics;

  const {
    currentuser,
  } = state.Profile;

  return {
    leftSideBarTheme,
    meetings,
    recordings,
    recordingLoader,
    recordingShareLink,
    recordingShareLinksError,
    meetingAIAnalytics,
    timeLineData,
    recordingTranscript,
    currentuser,
  };
};

export default connect(mapStateToProps, {
  getStatsMeetings,
  getStatsRecordings,
  getTimeLine,
  getMeetingAIAnalytics,
  loginShareLink,
  getRecordingShareLinkByLinkHash,
  changeSidebarTheme,
  getRecordingTranscript,
})(SharedRecordingPage);
