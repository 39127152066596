import React, { Component } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";

import SharedWithMeTable from "./SharedWithMeTable";
import { ISharedWithMeData, IUser } from "../../store/interface";
import { getSharedWithMe } from "../../store/shared-with-me/actions";
import { Icurrentaccountselected } from "../../store/auth/profile/interface";

interface SharedWithMeProps {
  currentaccountselected: Icurrentaccountselected;
  currentuser: IUser;
  globalFilterString: string;
  sharedWithMeData: ISharedWithMeData[];
  isLoading: boolean;
  getSharedWithMe: (accountId: String, globalFilterString: string) => void;
}

class SharedWithMePage extends Component<SharedWithMeProps> {
  componentDidMount() {
    const { currentaccountselected, globalFilterString, getSharedWithMe } =
      this.props;

    if (!isEmpty(currentaccountselected)) {
      getSharedWithMe(currentaccountselected.id, globalFilterString);
    }
  }

  componentDidUpdate(prevProps: SharedWithMeProps) {
    const { currentaccountselected, globalFilterString, getSharedWithMe } =
      this.props;

    if (
      !isEmpty(currentaccountselected) &&
      (currentaccountselected?.id !== prevProps.currentaccountselected?.id ||
        globalFilterString !== prevProps.globalFilterString)
    ) {
      getSharedWithMe(currentaccountselected.id, globalFilterString);
    }
  }

  render() {
    const { isLoading, sharedWithMeData } = this.props;

    return (
      <div className="page-content meeting-page">
        <SharedWithMeTable
          sharedWithMe={sharedWithMeData}
          loading={isLoading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentaccountselected, currentuser } = state.Profile;
  const { sharedWithMe: sharedWithMeData, isLoading } = state.SharedWithMe;

  return {
    currentaccountselected,
    currentuser,
    sharedWithMeData,
    isLoading,
  };
};

export default connect(mapStateToProps, {
  getSharedWithMe,
})(SharedWithMePage);
