import {
  GET_HITRATE_TOKEN,
  GET_HITRATE_TOKEN_SUCCESS,
  GET_HITRATE_TOKEN_ERROR,
  CREATE_HITRATE_TOKEN,
  CREATE_HITRATE_TOKEN_SUCCESS,
  CREATE_HITRATE_TOKEN_ERROR,
  EDIT_HITRATE_TOKEN,
  EDIT_HITRATE_TOKEN_SUCCESS,
  EDIT_HITRATE_TOKEN_ERROR,
  DELETE_HITRATE_TOKEN,
  DELETE_HITRATE_TOKEN_SUCCESS,
  DELETE_HITRATE_TOKEN_ERROR,
} from './actionTypes';

import { IHitrateToken } from './interface';

export const getHitrateToken = () => ({
  type: GET_HITRATE_TOKEN,
  payload: null,
});

export const getHitrateTokenSuccess = (hitrateToken: IHitrateToken|null) => ({
  type: GET_HITRATE_TOKEN_SUCCESS,
  payload: hitrateToken,
});

export const getHitrateTokenError = (error: String | Object) => ({
  type: GET_HITRATE_TOKEN_ERROR,
  payload: error,
});

export const createHitrateToken = (hitrateToken: IHitrateToken) => ({
  type: CREATE_HITRATE_TOKEN,
  payload: hitrateToken,
});

export const createHitrateTokenSuccess = (hitrateToken: IHitrateToken) => ({
  type: CREATE_HITRATE_TOKEN_SUCCESS,
  payload: hitrateToken,
});

export const createHitrateTokenError = (error: String | Object) => ({
  type: CREATE_HITRATE_TOKEN_ERROR,
  payload: error,
});

export const editHitrateToken = (hitrateToken: IHitrateToken) => ({
  type: EDIT_HITRATE_TOKEN,
  payload: hitrateToken,
});

export const editHitrateTokenSuccess = (hitrateToken: IHitrateToken) => ({
  type: EDIT_HITRATE_TOKEN_SUCCESS,
  payload: hitrateToken,
});

export const editHitrateTokenError = (error: String | Object) => ({
  type: EDIT_HITRATE_TOKEN_ERROR,
  payload: error,
});

export const deleteHitrateToken = (id: string) => ({
  type: DELETE_HITRATE_TOKEN,
  payload: id,
});

export const deleteHitrateTokenSuccess = () => ({
  type: DELETE_HITRATE_TOKEN_SUCCESS,
  payload: null,
});

export const deleteHitrateTokenError = (error: String | Object) => ({
  type: DELETE_HITRATE_TOKEN_ERROR,
  payload: error,
});
