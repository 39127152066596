import {
  CHANGE_CURRENT_ACCOUNT,
  CHANGE_MULTIPLE_ACCOUNT_STATE,
  EDIT_PROFILE,
  GET_USER_PROFILE,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  UPDATE_CURRENT_ACCOUNT,
  UPDATE_LYNES_INTEGRATION,
  UPDATE_TELAVOX_INTEGRATION,
  UPDATE_TELENOR_INTEGRATION,
} from "./actionTypes";
import { ProfileAction, ProfileState } from "./interface";

const initialState: ProfileState = {
  error: "",
  currentuser: {},
  currentaccountselected: {},
  hasMultipleAccounts: false,
};

const profile = (state = initialState, action: ProfileAction) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case CHANGE_CURRENT_ACCOUNT:
      state = { ...state, currentaccountselected: action.payload };
      break;
    case CHANGE_MULTIPLE_ACCOUNT_STATE:
      state = {
        ...state,
        hasMultipleAccounts: action.payload.hasMultipleAccounts,
      };
      break;
    case PROFILE_SUCCESS:
      state = {
        ...state,
        currentuser: action.payload.user,
        hasMultipleAccounts: action.payload.hasMultipleAccounts,
      };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload };
      break;
    case GET_USER_PROFILE:
      state = { ...state };
      break;
    case UPDATE_CURRENT_ACCOUNT:
      state = { ...state, currentaccountselected: action.payload };
      break;
    case UPDATE_LYNES_INTEGRATION:
      state = {
        ...state,
        currentaccountselected: {
          ...state.currentaccountselected,
          lynes_min_duration: action.payload,
        }
      };
      break;
    case UPDATE_TELENOR_INTEGRATION:
      state = {
        ...state,
        currentaccountselected: {
          ...state.currentaccountselected,
          telenor_min_duration: action.payload,
        }
      };
      break;
    case UPDATE_TELAVOX_INTEGRATION:
      state = {
        ...state,
        currentaccountselected: {
          ...state.currentaccountselected,
          telavox_min_duration: action.payload,
        }
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
