import React, { Component, Dispatch } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import * as dotenv from "dotenv";
import { withRouter } from "react-router-dom";
// Import Routes
import { authProtectedRoutes, publicRoutes, commonRoutes } from "./routes";
import AppRoute from "./routes/route";
import loaderImg from "./assets/images/loader.gif";
import { cookies } from './services/utilities/utilservice'
// layouts
import VerticalLayout from "./components/VerticalLayout";
import ErrorBoundary from "./components/Common/ErrorBoundary";
import NonAuthLayout from "./components/NonAuthLayout";
import { logoutUser } from "./services/auth/authservice";
import _, { isEmpty, isEqual } from "lodash";
// Import scss
import "./assets/scss/theme.scss";
import { Icurrentaccountselected } from "./store/interface";
import HorizontalLayout from "./components/HorizontalLayout";
import Chat from "./components/AiChat/Chat";
import { getPartner } from './services/partner/partnerApi';
import { getPartnerFromLocal, Partner, setPartnerToLocal } from './services/partner/partnerService';

interface AppProps {
  currentaccountselected: Icurrentaccountselected;
  currentuser: any;
  error: String | Object;
  layout: {
    isMobile: Boolean;
    isPreloader: Boolean;
    layoutType: String;
    layoutWidth: String;
    leftSideBarTheme: String;
    leftSideBarType: String;
    loader: Boolean;
    showRightSidebar: Boolean;
    topbarTheme: String;
  };
}

interface AppState {
  aabc: Number;
}

declare global {
  interface Window {
    api_base: any;
    app_id: string;
  }
}

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      aabc: 1,
    };
    this.getLayout = this.getLayout.bind(this);

    // dotenv.config();
  }

  async componentDidMount() {
    window.parent.postMessage('loaded', "*");

    await this.setPartner();
  }

  async componentDidUpdate(prevProps: AppProps) {
    const { error, currentaccountselected } = this.props;

    if (!_.isEmpty(error) && error !== prevProps.error) {
      logoutUser();
    }

    if (
      !isEmpty(currentaccountselected)
      && prevProps.currentaccountselected !== currentaccountselected
    ) {
      await this.setPartner(true);
    }

    if (
      !_.isEmpty(currentaccountselected) &&
      currentaccountselected !== prevProps.currentaccountselected
    ) {
      this.handleIntercomScript();
    }

    setTimeout(() => {
      let windows = window as any;
      const { currentuser } = this.props;
      if (!_.isEmpty(windows.HubSpotConversations)) {
        var _hsq = (windows._hsq = windows._hsq || []);
        _hsq.push([
          "identify",
          {
            email: currentuser?.email || "",
            firstname: currentuser?.first_name || "",
            lastname: currentuser?.lase_name || "",
            companyname: currentaccountselected?.name || "",
          },
        ]);
        _hsq.push(["trackPageView"]);
        windows.HubSpotConversations.widget.refresh();
      }
    }, 2000);
  }

  handleIntercomScript = (): void => {
    if (window.location.pathname === "/documents_import") return;
    if (!cookies.get("JWT_Token")) return;

    // Define Intercom settings and script
    const intercomSettingsScript = document.createElement("script");
      intercomSettingsScript.type = "text/javascript";
      intercomSettingsScript.text = `
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "sfzdx4co"
      };
    `;

    const intercomScript = document.createElement("script");
      intercomScript.type = "text/javascript";
      intercomScript.text = `
      (function(){
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          var d = document;
          var i = function(){ i.c(arguments); };
          i.q = []; i.c = function(args){ i.q.push(args); };
          w.Intercom = i;
          var l = function(){
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/sfzdx4co';
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === 'complete') { l(); }
          else if (w.attachEvent) { w.attachEvent('onload', l); }
          else { w.addEventListener('load', l, false); }
        }
      })();
    `;

    // Append scripts only if not on restricted paths
    if (
      window.location.pathname.indexOf('rooms_import') < 0 &&
      window.location.pathname.indexOf('documents_import') < 0
    ) {
      document.head.appendChild(intercomSettingsScript);
      document.head.appendChild(intercomScript);
    }
  };

  /**
   * Returns the layout
   */
  getLayout = () => {
    // let layoutCls = VerticalLayout;
    let layoutCls = HorizontalLayout;


    switch (this.props.layout.layoutType) {
      case "horizontal":
        // layoutCls = HorizontalLayout;
        break;
      default:
        // layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  setPartner = async (forceRefetch = false) => {
    const { currentaccountselected } = this.props;

    const exPartner = getPartnerFromLocal();

    let partner: Partner|null = null;
    if (!isEmpty(exPartner) && !forceRefetch) {
      partner = exPartner
    } else if (currentaccountselected && currentaccountselected.partner && currentaccountselected.partner.id) {
      partner = await getPartner(currentaccountselected.partner.id);
    } else {
      partner = await getPartner();
    }

    if (!isEqual(partner, exPartner)) {
      setPartnerToLocal(partner);
    }
    if (partner?.logoFavicon) {
      const link32 = document.querySelector("link[rel='icon'][sizes='32x32']") as HTMLLinkElement;
      if (link32) {
        link32.href = partner.logoFavicon;
      }

      // Update favicon for size 16x16
      const link16 = document.querySelector("link[rel='icon'][sizes='16x16']") as HTMLLinkElement;
      if (link16) {
        link16.href = partner.logoFavicon;
      }

      // Update the apple touch icon
      const appleTouchIcon = document.querySelector("link[rel='apple-touch-icon']") as HTMLLinkElement;
      if (appleTouchIcon) {
        appleTouchIcon.href = partner.logoFavicon;
      }
    }
  }

  render() {
    const Layout = this.getLayout();
    return (
      <React.Fragment>
        <Router>
          <ErrorBoundary>
            {/* <React.Suspense
              fallback={
                <div className="lazyloader">
                  <div className="lazyloader-wraaper">
                    <img src={loaderImg} alt="loader" />
                  </div>
                </div>
              }
            > */}
            <Switch>
              {commonRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isCommon={true}
                  isAuthProtected={null}
                />
              ))}

              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isCommon={false}
                  isAuthProtected={false}
                />
              ))}

              {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isCommon={false}
                  isAuthProtected={true}
                  fromIframe={route.fromIframe}
                />
              ))}
            </Switch>
            <Chat />
            {/* </React.Suspense> */}
          </ErrorBoundary>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    layout: state.Layout,
    error: state.Login.error,
    currentaccountselected: state.Profile.currentaccountselected,
    currentuser: state.Profile.currentuser,
  };
};

export default withRouter<any, any>(connect(mapStateToProps, null)(App));
