import {
  IAdversusRule,
  ICreateAdversusRule,
} from '../../pages/Settings/CallImport/utils/constants';
import {
  CREATE_ADVERSUS_RULE,
  CREATE_ADVERSUS_RULE_ERROR,
  CREATE_ADVERSUS_RULE_SUCCESS,
  CREATE_CALL_TOKEN_SETTINGS,
  CREATE_CALL_TOKEN_SETTINGS_ERROR,
  CREATE_CALL_TOKEN_SETTINGS_SUCCESS,
  DELETE_ADVERSUS_RULE,
  DELETE_ADVERSUS_RULE_ERROR,
  DELETE_ADVERSUS_RULE_SUCCESS,
  DELETE_CALL_TOKEN_SETTINGS,
  DELETE_CALL_TOKEN_SETTINGS_ERROR,
  DELETE_CALL_TOKEN_SETTINGS_SUCCESS,
  EDIT_ADVERSUS_RULE,
  EDIT_ADVERSUS_RULE_ERROR,
  EDIT_ADVERSUS_RULE_SUCCESS,
  GET_ADVERSUS_RULES,
  GET_ADVERSUS_RULES_ERROR,
  GET_ADVERSUS_RULES_SUCCESS,
  GET_CALL_TOKEN,
  GET_CALL_TOKEN_ERROR,
  GET_CALL_TOKEN_SETTINGS,
  GET_CALL_TOKEN_SETTINGS_ERROR,
  GET_CALL_TOKEN_SETTINGS_SUCCESS,
  GET_CALL_TOKEN_SUCCESS,
  UPDATE_CALL_TOKEN_SETTINGS,
  UPDATE_CALL_TOKEN_SETTINGS_ERROR,
  UPDATE_CALL_TOKEN_SETTINGS_SUCCESS,
} from './actionTypes';

import { ICallImportSettings, ICallImportToken } from './interface';

export const getCallTokens = (accountId: String) => {
  return {
    type: GET_CALL_TOKEN,
    payload: { accountId },
  };
};

export const getCallTokensSuccess = (tokens: ICallImportToken[]) => {
  return {
    type: GET_CALL_TOKEN_SUCCESS,
    payload: tokens,
  };
};

export const getCallTokensError = (error: string | Object) => {
  return {
    type: GET_CALL_TOKEN_ERROR,
    payload: error,
  };
};

export const getCallTokenSettings = (accountId: String) => {
  return {
    type: GET_CALL_TOKEN_SETTINGS,
    payload: { accountId },
  };
};

export const getCallTokenSettingsSuccess = (
  tokenSettings: ICallImportSettings[]
) => {
  return {
    type: GET_CALL_TOKEN_SETTINGS_SUCCESS,
    payload: tokenSettings,
  };
};

export const getCallTokenSettingsError = (error: string | Object) => {
  return {
    type: GET_CALL_TOKEN_SETTINGS_ERROR,
    payload: error,
  };
};

export const createCallTokenSettings = (
  accountId: String,
  tokenSettings: ICallImportSettings
) => {
  return {
    type: CREATE_CALL_TOKEN_SETTINGS,
    payload: { accountId, tokenSettings },
  };
};

export const createCallTokenSettingsSuccess = (
  tokenSettings: ICallImportSettings
) => {
  return {
    type: CREATE_CALL_TOKEN_SETTINGS_SUCCESS,
    payload: tokenSettings,
  };
};

export const createCallTokenSettingsError = (error: string | Object) => {
  return {
    type: CREATE_CALL_TOKEN_SETTINGS_ERROR,
    payload: error,
  };
};

export const deleteCallTokenSettings = (
  accountId: String,
  tokenSettingsId: string
) => {
  return {
    type: DELETE_CALL_TOKEN_SETTINGS,
    payload: { accountId, tokenSettingsId },
  };
};

export const deleteCallTokenSettingsSuccess = () => {
  return {
    type: DELETE_CALL_TOKEN_SETTINGS_SUCCESS,
    payload: null,
  };
};

export const deleteCallTokenSettingsError = (error: string | Object) => {
  return {
    type: DELETE_CALL_TOKEN_SETTINGS_ERROR,
    payload: error,
  };
};

export const updateCallTokenSettings = (
  accountId: String,
  tokenSettings: ICallImportSettings
) => {
  return {
    type: UPDATE_CALL_TOKEN_SETTINGS,
    payload: { accountId, tokenSettings },
  };
};

export const updateCallTokenSettingsSuccess = (
  tokenSettings: ICallImportSettings
) => {
  return {
    type: UPDATE_CALL_TOKEN_SETTINGS_SUCCESS,
    payload: tokenSettings,
  };
};

export const updateCallTokenSettingsError = (error: string | Object) => {
  return {
    type: UPDATE_CALL_TOKEN_SETTINGS_ERROR,
    payload: error,
  };
};

export const getAdversusRules = (accountId: String) => ({
  type: GET_ADVERSUS_RULES,
  payload: accountId,
});

export const getAdversusRulesSuccess = (adversusRules: any[]) => ({
  type: GET_ADVERSUS_RULES_SUCCESS,
  payload: adversusRules,
});

export const getAdversusRulesError = (error: string | Object) => ({
  type: GET_ADVERSUS_RULES_ERROR,
  payload: error,
});

export const createAdversusRule = (
  accountId: String,
  rule: ICreateAdversusRule
) => ({
  type: CREATE_ADVERSUS_RULE,
  payload: { accountId, rule },
});

export const createAdversusRuleSuccess = (adversusRule: IAdversusRule) => ({
  type: CREATE_ADVERSUS_RULE_SUCCESS,
  payload: adversusRule,
});

export const createAdversusRuleError = (error: string | Object) => ({
  type: CREATE_ADVERSUS_RULE_ERROR,
  payload: error,
});

export const editAdversusRule = (accountId: String, rule: IAdversusRule) => ({
  type: EDIT_ADVERSUS_RULE,
  payload: { accountId, rule },
});

export const editAdversusRuleSuccess = (adversusRule: IAdversusRule) => ({
  type: EDIT_ADVERSUS_RULE_SUCCESS,
  payload: adversusRule,
});

export const editAdversusRuleError = (error: string | Object) => ({
  type: EDIT_ADVERSUS_RULE_ERROR,
  payload: error,
});

export const deleteAdversusRule = (accountId: String, ruleId: string) => ({
  type: DELETE_ADVERSUS_RULE,
  payload: { accountId, ruleId },
});

export const deleteAdversusRuleSuccess = (ruleId: string) => ({
  type: DELETE_ADVERSUS_RULE_SUCCESS,
  payload: ruleId,
});

export const deleteAdversusRuleError = (error: string | Object) => ({
  type: DELETE_ADVERSUS_RULE_ERROR,
  payload: error,
});
