export interface Options {
  label: String;
  value: String;
  labelChart?: String;
}

const meetings_chart_metric_options: Options[] = [
  {
    label: "Meetings",
    value: "meetings",
    labelChart: "Meetings",
  },
  {
    label: "Meeting time",
    value: "meeting_time",
    labelChart: "Meeting time ( Minutes )",
  },
  {
    label: "Avg. meeting engagement",
    value: "average_meeting_engagement",
    labelChart: "Avg. meeting engagement ( % )",
  },
];

const team_chart_metric_options: Options[] = [
  {
    label: "Meetings",
    value: "meetings",
    labelChart: "Meetings",
  },
  {
    label: "Meeting time",
    value: "meeting_time",
    labelChart: "Meeting time ( Minutes )",
  },
  {
    label: "Avg. meeting engagement",
    value: "average_meeting_engagement",
    labelChart: "Avg. meeting engagement ( % )",
  },
  {
    label: "Documents shown",
    value: "documents_shown",
    labelChart: "Documents shown",
  },
];

const user_chart_metric_options: Options[] = [
  {
    label: "Meetings",
    value: "meetings",
    labelChart: "Meetings",
  },
  {
    label: "Meeting time",
    value: "meeting_time",
    labelChart: "Meeting time ( Minutes )",
  },
  {
    label: "Avg. meeting engagement",
    value: "average_meeting_engagement",
    labelChart: "Avg. meeting engagement ( % )",
  },
  {
    label: "Documents shown",
    value: "documents_shown",
    labelChart: "Documents shown",
  },
];

const rooms_chart_metric_options: Options[] = [
  {
    label: "Rooms created",
    value: "rooms_created",
    labelChart: "Rooms created",
  },
  {
    label: "Room sessions",
    value: "room_sessions",
    labelChart: "Room sessions",
  },
  {
    label: "Documents shown",
    value: "documents_shown",
    labelChart: "Documents shown",
  },
  {
    label: "Pages shown",
    value: "pages_shown",
    labelChart: "Pages shown",
  },
  {
    label: "Document engagement",
    value: "room_engagement_percentage",
    labelChart: "Document engagement ( % )",
  },
];

const room_sessions_chart_metric_options: Options[] = [
  {
    label: "Rooms created",
    value: "rooms_created",
    labelChart: "Rooms created",
  },
  {
    label: "Room sessions",
    value: "room_sessions",
    labelChart: "Room sessions",
  },
  {
    label: "Documents shown",
    value: "documents_shown",
    labelChart: "Documents shown",
  },
  {
    label: "Pages shown",
    value: "pages_shown",
    labelChart: "Pages shown",
  },
  {
    label: "Document engagement",
    value: "room_engagement_percentage",
    labelChart: "Document engagement ( % )",
  },
];

const documents_chart_metric_options: Options[] = [
  {
    label: "Documents shown",
    value: "documents_shown",
    labelChart: "Documents shown",
  },
  {
    label: "View time",
    value: "document_time",
    labelChart: "View time ( Minutes )",
  },
  {
    label: "View time ( Meetings )",
    value: "document_time_meetings",
    labelChart: "View time ( Meetings - Minutes )",
  },
  {
    label: "View time ( Rooms )",
    value: "document_time_rooms",
    labelChart: "View time ( Rooms - Minutes )",
  },
  {
    label: "Avg. view time",
    value: "document_avg_time",
    labelChart: "Avg. view time ( Minutes )",
  },
  {
    label: "Avg. view time ( Meetings )",
    value: "document_avg_time_meetings",
    labelChart: "Avg. view time ( Meetings - Minutes )",
  },
  {
    label: "Avg. view time ( Rooms )",
    value: "document_avg_time_rooms",
    labelChart: "Avg. view time ( Rooms - Minutes )",
  },
  {
    label: "Avg. meeting engagement",
    value: "average_engagement_meetings",
    labelChart: "Avg. meeting engagement ( % )",
  },
  {
    label: "Avg. room engagement",
    value: "average_engagement_rooms",
    labelChart: "Avg. room engagement ( % )",
  },
  {
    label: "Used in meetings",
    value: "amount_used_in_meetings",
    labelChart: "Used in meetings",
  },
  {
    label: "Used in rooms",
    value: "amount_used_in_rooms",
    labelChart: "Used in rooms",
  },
];

const pages_chart_metric_options: Options[] = [
  {
    label: "Pages shown",
    value: "pages_shown",
    labelChart: "Pages shown",
  },
  {
    label: "Page views",
    value: "page_views",
    labelChart: "Page views",
  },
  {
    label: "View time",
    value: "page_time",
    labelChart: "View time ( Minutes )",
  },
  {
    label: "View time ( Meetings )",
    value: "page_time_meetings",
    labelChart: "View time ( Meetings - Minutes )",
  },
  {
    label: "View time ( Rooms )",
    value: "page_time_rooms",
    labelChart: "View time ( Rooms - Minutes )",
  },
  {
    label: "Avg. view time",
    value: "page_avg_time",
    labelChart: "Avg. view time ( Minutes )",
  },
  {
    label: "Avg. view time ( Meetings )",
    value: "page_avg_time_meetings",
    labelChart: "Avg. view time ( Meetings - Minutes )",
  },
  {
    label: "Avg. view time ( Rooms )",
    value: "page_avg_time_rooms",
    labelChart: "Avg. view time ( Rooms - Minutes )",
  },
  {
    label: "Avg. meeting engagement",
    value: "average_engagement_meetings",
    labelChart: "Avg. meeting engagement ( % )",
  },
  {
    label: "Used in meetings",
    value: "amount_used_in_meetings",
    labelChart: "Used in meetings",
  },
  {
    label: "Used in rooms",
    value: "amount_used_in_rooms",
    labelChart: "Used in rooms",
  },
];

const contacts_chart_metric_options: Options[] = [
  {
    label: "New contacts per day",
    value: "new_contacts",
    labelChart: "New contacts per day",
  },
  {
    label: "Interactions per day",
    value: "interactions",
    labelChart: "Interactions per day",
  },
];

const companies_chart_metric_options: Options[] = [
  {
    label: "New companies per day",
    value: "new_companies",
    labelChart: "New companies per day",
  },
  {
    label: "Interactions per day",
    value: "interactions",
    labelChart: "Interactions per day",
  },
];

const participants_chart_metric_options: Options[] = [
  {
    label: "New participants per day",
    value: "new_participants",
    labelChart: "New participants per day",
  },
  {
    label: "Interactions per day",
    value: "interactions",
    labelChart: "Interactions per day",
  },
];

const members_chart_metric_options: Options[] = [
  {
    label: "New members per day",
    value: "new_members",
    labelChart: "New members per day",
  },
  {
    label: "Interactions per day",
    value: "interactions",
    labelChart: "Interactions per day",
  },
];

const time_chart_options: Options[] = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

/**
 * Analytics Sales Time Filter Options
 * Schema : Array[Object]
 * Each Object contains
 * { String } label : label to be displayed on Dropdown
 * { String } value : value of corresponding label
 */
const time_filter_options: Options[] = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "This Month",
    value: "this-month",
  },
  {
    label: "Last Month",
    value: "last-month",
  },
  {
    label: "Last 30 Days",
    value: "last-30",
  },
  {
    label: "Last 90 Days",
    value: "last-90",
  },
  {
    label: "Last 120 Days",
    value: "last-120",
  },
  {
    label: "This Week",
    value: "this-week",
  },
  {
    label: "Last Week",
    value: "last-week",
  },
];

// Apexchart Object

//In Order to resolve the chart rendering issue series data is initialized with 0 zero value. So that the animation to the actual data will be
const sales_chart_data = {
  series: [
    {
      name: "series1",
      data: [100],
    },
  ],
  options: {
    dataLabels: {
      enabled: false,
    },
    chart: {
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 100,
        dynamicAnimation: {
          enabled: true,
          speed: 500,
        },
      },
    },
    colors: ["#556ee6", "#34c38f"],
    xaxis: {
      type: "datetime",
      labels: {
        format: "dd MMM 'yy",
        rotate: -45,
        style: {
          fontSize: "11px",
        },
      },
      title: { text: "Date", offsetY: 15 },
    },
    yaxis: { title: { text: "Meetings" } },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {},
    stroke: {
      width: 3,
      curve: 'straight',
    },
  },
};

export {
  time_filter_options,
  sales_chart_data,
  time_chart_options,
  rooms_chart_metric_options,
  documents_chart_metric_options,
  pages_chart_metric_options,
  meetings_chart_metric_options,
  team_chart_metric_options,
  user_chart_metric_options,
  contacts_chart_metric_options,
  companies_chart_metric_options,
  participants_chart_metric_options,
  members_chart_metric_options,
  room_sessions_chart_metric_options,
};
