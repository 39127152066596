import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { has } from "lodash";

import {
  GET_MEETING_TYPES,
  GET_CAMPAIGN,
  CREATE_MEETING_TYPE,
  GET_DEFAULT_MEETING_TYPES,
  EDIT_MEETING_TYPE,
  EDIT_RECORDING_TYPE,
} from "./actionTypes";
import {
  getMeetingTypesSuccess,
  getMeetingTypesError,
  getDefaultMeetingTypesSuccess,
  getDefaultMeetingTypesError,
  createMeetingTypeSuccess,
  meetingTypeError,
  getMeetingTypes as getMeetingTypesAction,
  editMeetingTypeSuccess,
  getCampaignSuccess,
  getCampaignError,
  editRecordingType as editRecordingTypeAction,
  editRecordingTypeError
} from "./actions";

import {
  ResponseGenerator,
  getMeetingTypes,
  getCampaign as getCampaignService,
  getDefaultMeetingTypes,
  createNewMeetingType as createNewMeetingTypeService, editMeetingType as editMeetingTypeService, editRecordingType,
} from "../../../services/userservice";

function* getMeetingTypesHelper({ payload: { accountId, options = {} } }: any) {
  const response: ResponseGenerator = yield call(getMeetingTypes, accountId, options);
  if (has(response, "data")) {
    yield put(getMeetingTypesSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getMeetingTypesError(error));
    } else {
      yield put(getMeetingTypesError("An Error has occured"));
    }
  }
}

function* getCampaignHelper({ payload: { accountId } }: any) {
  const response: ResponseGenerator = yield call(getCampaignService, accountId);
  if (has(response, "data")) {
    yield put(getCampaignSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getCampaignError(error));
    } else {
      yield put(getCampaignError("An Error has occurred"));
    }
  }
}

function* getDefaultMeetingTypesHelper({ payload: { accountId } }: any) {
  const response: ResponseGenerator = yield call(getDefaultMeetingTypes, accountId);
  if (has(response, "data")) {
    yield put(getDefaultMeetingTypesSuccess(response.data));
  } else {
    const error = response.error || response.errors;
    if (error) {
      yield put(getDefaultMeetingTypesError(error));
    } else {
      yield put(getDefaultMeetingTypesError("An Error has occured"));
    }
  }
}

function* createNewMeetingTypeHelper({ payload: { accountId, meetingType, onSuccess } }: any) {
  const response: ResponseGenerator = yield call(createNewMeetingTypeService, accountId, meetingType);

  onSuccess && onSuccess();

  if (has(response, "data")) {
    yield put(getMeetingTypesAction(accountId));
    yield put(createMeetingTypeSuccess(response.data));
  } else {
    const error = response.error || response.errors || "An Error has occurred";
    yield put(meetingTypeError(error));
  }
}

function* editMeetingTypeHelper({ payload: { accountId, meetingTypeId, meetingType, onSuccess } }: any) {
  const response: ResponseGenerator = yield call(editMeetingTypeService, accountId, meetingTypeId, meetingType);

  onSuccess && onSuccess();

  if (has(response, "data")) {
    yield put(getMeetingTypesAction(accountId));
    yield put(editMeetingTypeSuccess(response.data));
  } else {
    const error = response.error || response.errors || "An Error has occurred";
    yield put(meetingTypeError(error));
  }
}

function* editRecordingTypeHelper({ payload}: ReturnType<typeof editRecordingTypeAction>) {
  const response: ResponseGenerator = yield call(editRecordingType, payload.accountId, payload.meetingTypeId, payload.sessionId );

  if (has(response, "error") || has(response, "errors")) {
    const error = response.error || response.errors;
    if (error) {
      yield put(editRecordingTypeError(error));
    } else {
      yield put(editRecordingTypeError("An error has occurred!!"));
    }
  } else {
    yield put({
      type: GET_MEETING_TYPES,
      payload: {
        accountId: payload.accountId,
      },
    });
  }
}

export function* watchGetMeetingTypesHelper() {
  yield takeEvery(GET_MEETING_TYPES, getMeetingTypesHelper);
}

export function* watchGetCampaignHelper() {
  yield takeEvery(GET_CAMPAIGN, getCampaignHelper);
}

export function* watchGetDefaultMeetingTypesHelper() {
  yield takeEvery(GET_DEFAULT_MEETING_TYPES, getDefaultMeetingTypesHelper);
}

export function* watchCreateNewMeetingType() {
  yield takeEvery(CREATE_MEETING_TYPE, createNewMeetingTypeHelper);
}

export function* watchEditMeetingType() {
  yield takeEvery(EDIT_MEETING_TYPE, editMeetingTypeHelper);
}

export function* watchEditRecordingType() {
  yield takeEvery(EDIT_RECORDING_TYPE, editRecordingTypeHelper)
}

function* getMeetingTypesSaga() {
  yield all([
    fork(watchGetMeetingTypesHelper),
    fork(watchGetDefaultMeetingTypesHelper),
    fork(watchCreateNewMeetingType),
    fork(watchEditMeetingType),
    fork(watchGetCampaignHelper),
    fork(watchEditRecordingType)
  ]);
}

export default getMeetingTypesSaga;
