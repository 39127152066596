import React, { Component } from 'react';

import GptPromptComponent from '../../components/GPTPrompt/GPTPromptComponent';
import { postGPTPrompt } from '../../services/GPTPrompt/GPTPromptService';
import GPTPromptTabComponent from '../../components/GPTPrompt/GPTPromptTabComponent';

interface GptPromptState {
  promptIds: string[];
  error: any;
  isLoading: boolean;
}

class GptPrompt extends Component<{ sessionId?: string }, GptPromptState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      promptIds: [],
      error: null,
      isLoading: false,
    };
  }

  save = async (
    sessionId: string,
    prompt: string,
    promptCount: string,
    aiModel: string,
  ) => {
    this.setState({ isLoading: true, error: null, promptIds: [] });
    const response: any | string[] = await postGPTPrompt(
      sessionId,
      prompt,
      promptCount,
      aiModel,
    );

    if (response?.data?.error || response?.status >= 400) {
      let error = response.data?.error || response.statusText;
      this.setState({ error });
      setTimeout(() => {
        this.setState({ error: null });
      }, 4000);
    } else {
      this.setState({ promptIds: response });
    }
  };

  onLoaded = () => {
    this.setState({ isLoading: false });
  }

  render() {
    const { isLoading, promptIds, error } = this.state;

    return (
      <>
        <GptPromptComponent onSave={this.save} sessionId={this.props.sessionId} isLoading={isLoading} />
        {promptIds.length > 0 && (
          <GPTPromptTabComponent promptIds={promptIds} onLoaded={this.onLoaded} />
        )}
        {error ? <div className="gpt-prompt-error">{error}</div> : ''}
      </>
    );
  }
}

export default GptPrompt;
