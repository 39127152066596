import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

import { ISnippet } from "../../store/Analytics/interface";

interface SnippetsProps {
  snippets: ISnippet[],
  history?: {
    push: any;
  };
  onSnippetClick: (startTime: number, endTime: number) => void,
  onSnippetShare?: (name: string, description: string, uuid: string, startTime: number, endTime: number) => void,
  addNewSnippet: (updateWidth: boolean) => void;
}

class Snippets extends Component<SnippetsProps> {
  render() {
    const { snippets, onSnippetShare } = this.props;

    return (
      <>
        <div className="snippets">
          <div className="snippet-wrapper mt-2 mb-4">
            <Row className='snippet-box'>
              <Col lg='12'>
                    <Button color="blue" onClick={(event: any) => {
                    this.props.addNewSnippet(false);
                  }}> Add new snippet </Button>
              </Col>
            </Row>
          </div>
          {snippets.map((snippet: ISnippet, key: number) => {
            return (
              <div key={key}>
                <div className='snippet-wrapper'>

                  <h3
                    id={'snippet-title-' + snippet.id}
                    className='snippet-title'
                  >
                    <Link
                      to={{
                        pathname: `/snippet/${snippet.uuid}`
                      }}
                      className="waves-effect"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {snippet.name}
                    </Link>
                  </h3>

                  <Row className='snippet-box' data-id={snippet.id}>
                    <Col lg='12'>
                      <p
                        className='snippet-details'
                      >
                        {snippet.description}
                      </p>
                    </Col>
                    <Col lg='12'>
                      <div className="snippet-buttons">
                          <Button
                            color="blue"
                            type="button"
                            onClick={ onSnippetShare ? () => { onSnippetShare(snippet.name, snippet.description, snippet.uuid, snippet.start_time, snippet.end_time) } : () => {}}
                          >
                            Share
                          </Button>
                          <Link
                            to={{
                              pathname: `/snippet/${snippet.uuid}`
                            }}
                            className="btn btn-blue"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                              View
                          </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export { Snippets };
