import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  HIDE_LOADER,
  SHOW_LOADER,
  SETTINGS_MENU,
  RECORDINGS_MENU,
  CREATE_WIDGET_LAYOUT,
  GET_DASHBOARDS,
  GET_DASHBOARDS_ERROR,
  GET_DASHBOARDS_SUCCESS,
  EMPTY_DASHBOARDS,
} from './actionTypes';

import { IWidgetLayout } from '../../components/Widgets/utils/IWidget';

export const changeLayout = (layout: any) => ({
  type: CHANGE_LAYOUT,
  payload: layout,
});

export const changePreloader = (layout: any) => ({
  type: CHANGE_PRELOADER,
  payload: layout,
});

export const changeLayoutWidth = (width: String) => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: width,
});

export const changeSidebarTheme = (theme: String) => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
});

export const changeSidebarType = (sidebarType: any, isMobile?: any) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  };
};

export const changeTopbarTheme = (topbarTheme: String) => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const toggleRightSidebar = () => ({
  type: TOGGLE_RIGHT_SIDEBAR,
  payload: null,
});

export const showRightSidebar = () => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: null,
});

export const hideRightSidebar = () => ({
  type: HIDE_RIGHT_SIDEBAR,
  payload: null,
});

export const showLoader = () => ({
  type: SHOW_LOADER,
  payload: null,
});

export const hideLoader = () => ({
  type: HIDE_LOADER,
  payload: null,
});

export const settingsMenu = (value: any) => ({
  type: SETTINGS_MENU,
  payload: value,
});

export const recordingsMenu = (value: any) => ({
  type: RECORDINGS_MENU,
  payload: value,
});

export const createWidgetLayout = (
  layouts: IWidgetLayout[],
  accountId: String
) => ({
  type: CREATE_WIDGET_LAYOUT,
  payload: { layouts, accountId },
});

export const getDashboards = (accountId: String) => ({
  type: GET_DASHBOARDS,
  payload: { accountId },
});

export const getDashboardsError = (message: object | string) => ({
  type: GET_DASHBOARDS_ERROR,
  payload: { message },
});

export const getDashboardsSuccess = (dashboards: IWidgetLayout[]) => ({
  type: GET_DASHBOARDS_SUCCESS,
  payload: { dashboards },
});

export const emptyDashboards = () => ({
  type: EMPTY_DASHBOARDS,
  payload: null,
});
